import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { toPairs } from 'ramda';
import Moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styled, { css } from 'styled-components';

import Formsy from 'formsy-react-2';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Creators, Selectors } from '../../data/ducks/babiesAndFamilies';
import { Creators as ModalCreators } from '../../data/ducks/modals';

import Modal from '../../components/modals/NeoModal';
import Avatar from '../../components/shared/AvatarDropzone';
import SegmentedButtons from '../../components/shared/SegmentedButtons';
import TextInput from '../../components/shared/TextInput';
import { Neo } from '../../components/themes/TextInput';
import Select from '../../components/shared/NewSelect';
import { Neo as NeoSelect } from '../../components/themes/NewSelect';
import LoadingOverlay from '../../components/shared/LoadingOverlay';

import Button from '../../components/shared/buttons/MainButton';

import Label from '../../components/shared/Label';
import { VALIDATIONS_MESSAGES } from '../../values/validations';

import {
  Genders, YearList, DayList, WeeksList, MonthsList,
} from '../../shared-values';
import { reshapeLiteral, noFutureDatesValidation } from '../../lib/utils';

const InlineInputs = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: auto;
  }
`;

const Form = styled(Formsy.Form)`
  width: 300px;
  margin: auto;

  & > * {
    margin-top: 30px;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 20px 60px 20px;
  box-sizing: border-box;
`;

const GenderOptions = reshapeLiteral(Genders, 'value', 'label');
class AddBaby extends React.Component {
  state = {
    submitEnabled: false,
    birthdayYear: 0,
    birthdayMonth: 0,
    birthdayDay: 0,
  }

  componentDidMount = () => {
    const months = toPairs(MonthsList).map(([value, label]) => ({ label: this.props.t(`common:${label}`), value }));
    this.setState({
      yearList: YearList,
      dayList: DayList,
      weeksList: WeeksList,
      months,
    });
  }

  setBirthdayYear = e => this.setState({ birthdayYear: e.value });

  setBirthdayMonth = e => this.setState({ birthdayMonth: e.value });

  setBirthdayDay = e => this.setState({ birthdayDay: e.value });

  babyAgeChange = () => this.setState({ babyAgeHasChanged: true });

  saveChanges = ({
    name, gender, year, month, day, weeksBeforeBirth, avatar,
  }, closeModal) => {
    const bday = new Date(year, month, day);
    const birthday = Moment(bday).format('YYYY-MM-DD');
    const familyId = this.props.activeFamily.id;
    const babyData = {
      familyId, name, gender, birthday, weeksBeforeBirth, avatar,
    };
    if (localStorage.getItem('CREATE_BABY_AFTER_JOIN')) {
      localStorage.setItem('temporaryClassroomsBaby', JSON.stringify(babyData));
      this.props.openModal({ name: 'ClassroomsInviteCodeModal' });
    } else {
      this.props.addBaby(babyData);
      closeModal();
    }
  }

  toggleSubmit = val => this.setState({ ...this.state, submitEnabled: val });

  render() {
    const { t, loading } = this.props;
    const {
      birthdayDay,
      birthdayMonth,
      birthdayYear,
      dayList,
      months,
      yearList,
      weeksList,
      submitEnabled,
    } = this.state;
    const currentYear = (new Date()).getFullYear();
    const { givenMonth, givenDay } = noFutureDatesValidation(
      birthdayDay,
      birthdayMonth,
      birthdayYear,
    );
    const translatedGenderOptions = GenderOptions.map(op => ({ ...op, label: t(`common:${op.label}`) }));
    return (
      <Modal width={800} title={t('ADD_BABY')} roundedCorners>
        {({ closeModal }) => (
          <Fragment>
            <Content>
              <Form
                onSubmit={values => this.saveChanges(values, closeModal)}
                onValid={() => this.toggleSubmit(true)}
                onInvalid={() => this.toggleSubmit(false)}
              >
                <Avatar name="avatar" label={t('MY_BABYS_PICTURE')} circle />
                <SegmentedButtons name="gender" label={t('GENDER')} options={translatedGenderOptions} required />
                <TextInput
                  name="name"
                  label={t('BABYS_NAME')}
                  validations={{ isSpecialWords: true, minLength: 2 }}
                  theme={Neo}
                  required
                />
                <div>
                  <Label type="neo">{t('BIRTHDAY')}</Label>
                  <InlineInputs>
                    <Select
                      name="month"
                      theme={NeoSelect}
                      containerStyle={css`flex-grow: 0.7;`}
                      options={months}
                      required
                      onChange={(e) => { this.setBirthdayMonth(e); this.babyAgeChange(); }}
                      showErrorMessage
                      validations={{ validRange: [0, givenMonth] }}
                      validationErrors={{
                        validRange: t(`validations:${VALIDATIONS_MESSAGES.NO_FUTURE_DATES}`),
                      }}
                    />
                    <Select
                      name="day"
                      theme={NeoSelect}
                      containerStyle={css`flex-grow: 0.05;`}
                      options={dayList}
                      required
                      onChange={e => this.setBirthdayDay(e)}
                      showErrorMessage
                      validations={{ isNumeric: true, validRange: [1, givenDay] }}
                      validationErrors={{
                        isNumeric: t(`validations:${VALIDATIONS_MESSAGES.IS_NUMERIC}`),
                        validRange: t(`validations:${VALIDATIONS_MESSAGES.VALID_DATE}`),
                      }}
                    />
                    <Select
                      name="year"
                      theme={NeoSelect}
                      containerStyle={css`flex-grow: 0.15;`}
                      options={yearList}
                      required
                      onChange={(e) => { this.setBirthdayYear(e); this.babyAgeChange(); }}
                      validations={{ isNumeric: true, validRange: [1900, currentYear] }}
                      validationErrors={{
                        isNumeric: t(`validations:${VALIDATIONS_MESSAGES.IS_NUMERIC}`),
                        validRange: t(`validations:${VALIDATIONS_MESSAGES.VALID_DATE}`),
                      }}
                    />
                  </InlineInputs>
                </div>
                <Select
                  name="weeksBeforeBirth"
                  label={t('WEEKS_OF_GESTATION')}
                  options={weeksList}
                  theme={NeoSelect}
                  centered
                  openOnTop
                  required
                />
                <Button
                  type="submit"
                  color={submitEnabled ? 'green' : 'disabled'}
                  disabled={!submitEnabled}
                  raised
                >
                  {t('common:NEXT')}
                </Button>
              </Form>
            </Content>
            <LoadingOverlay loading={loading} />
          </Fragment>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  activeFamily: Selectors.activeFamily(state),
  loading: state.families.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addBaby: Creators.addBabyRequested,
  openModal: ModalCreators.openModal,
  closeModal: ModalCreators.closeModal,
}, dispatch);

AddBaby.propTypes = {
  addBaby: PropTypes.func,
  activeFamily: PropTypes.shape({ id: PropTypes.number }),
  loading: PropTypes.bool,
  t: PropTypes.func,
};

AddBaby.defaultProps = {
  addBaby: () => { },
  activeFamily: {},
  loading: false,
  t: () => { },
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation([Namespaces.MENU, Namespaces.COMMON, Namespaces.VALIDATIONS]),
)(AddBaby);
