import BaseApi from './BaseApi';

export const login = encodedCredentials => BaseApi.post('/auth/token', null, {
  headers: { Authorization: `Basic ${encodedCredentials}` },
});

export const thirdParty = ({ accessToken, userId, provider }) => BaseApi.post('/auth/third_party', {
  provider,
  provider_data: {
    user_data: {
      id: userId,
    },
    access_token: accessToken,
  },
});

export const signup = ({ email, password, cohort, lang }) => {
  return BaseApi.post('/auth/signup', { email, password, name: '', lastname: '', api_version: 3, cohort, locale: lang?.value || 'en' });
}
export const createSession = () => BaseApi.post('/sessions');

export const postResetPasswordEmail = email => BaseApi.post('/auth/reset_password', { email });
export const postResetPassword = ({ token, password }) => BaseApi.post('/auth/restore_password', {
  reset_password_token: token,
  password,
  password_confirmation: password,
});
