
// import { Creators as ModalCreators } from '../../ducks/modals';
import { Creators, Types } from '../../ducks/messages';


const InappTypes = {
  SURVEY: 'survey',
};

const MessageHandler = ({ type, ...rest }) => ({
  [InappTypes.SURVEY]: { ...rest, type: 'START_SURVEY' },
}[type]);

// TODO: Split to allow debouncing and DRYNES.
// eslint-disable-next-line
export const attemptMessageFetchOnLocationEnter = (action$, { getState }) =>
  action$.ofType(Types.SET_ACTIVE_MESSAGE_QUEUE)
    // .distinctUntilChanged(null, action => action.location)
    .map(({ location }) => ({ ...getState().messages[location], location }))
    .mergeMap(({ location, messages }) => {
      if (messages && messages[0]) {
        return [
          Creators.popMessageQueue(location),
          MessageHandler(messages[0]),
        ];
      }

      return action$.ofType(Types.FETCH_PENDING_MESSAGES_SUCCESS)
        .filter(({ location: a }) => a === location && location === 'DAPHome')
        .take(1)
        .takeWhile(action => action.messages.length !== 0)
        .mergeMap(action => ([
          Creators.popMessageQueue(location),
          MessageHandler(action.messages[0]),
        ]));
      // .startWith(Creators.fetchPendingMessagesRequested(location));
    });

