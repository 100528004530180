import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import Icon from './Icon';
import { Default, ThemeShape } from '../themes/CheckList';

const Container = styled.div`
  ${({ theme }) => theme.container};
`;

const List = styled.ul`
  ${({ theme }) => theme.list};
`;

const Item = styled.li`
  ${({ theme }) => theme.item};
`;

const Text = styled.span`
  ${({ theme }) => theme.text};
`;

const CheckList = ({
  theme, items, iconSize, textSize, textColor, iconComponent, style,
}) => (
  <ThemeProvider theme={theme}>
    <Container style={style}>
      <List>
        {
          items.map(item => (
            <Item key={item.title} striked={item.striked} textSize={textSize} textColor={textColor}>
              {item.bullet || iconComponent || (
              <Icon
                size={iconSize || 25}
                icon="milestones-completed"
                style={item.striked ? theme.striked : theme.icon}
              />
              )}
              <Text>{item.title}</Text>
            </Item>
          ))
        }
      </List>
    </Container>
  </ThemeProvider>
);

CheckList.propTypes = {
  theme: ThemeShape,
  /* List of items to display */
  items: PropTypes.arrayOf(PropTypes.shape({
    /* Text of the item */
    title: PropTypes.string,
    /* If true, it will be striked through */
    striked: PropTypes.bool,
  })),
  /* Check icon size */
  iconSize: PropTypes.number,
  /* Item text size */
  textSize: PropTypes.string,
  /* Item text color */
  textColor: PropTypes.string,
  /* Container inline styles */
  style: PropTypes.object,
};

CheckList.defaultProps = {
  theme: Default,
  items: [],
  iconSize: 20,
  textSize: '',
  textColor: '',
  style: {},
};

export default CheckList;
