import BabyPlaceholderBoy from '../images/placeholders/BabyPlaceholderBoy.png';
import BabyPlaceholderGirl from '../images/placeholders/BabyPlaceholderGirl.png';

export const BabyAvatar = {
  male: BabyPlaceholderBoy,
  female: BabyPlaceholderGirl,
};

export const PERMISSIONS = {
  PRIMARY_CAREGIVER: [
    'PERMISSION_1',
    'PERMISSION_2',
    'PERMISSION_3',
    'PERMISSION_4',
  ],
  CAREGIVER: [
    'PERMISSION_3',
  ],
};

export const memberRoles = [
  null,
  'ADMIN',
  'PRIMARY_CAREGIVER',
  'CAREGIVER',
];

export const memberRolesForEvents = [
  null,
  'Admin',
  'PrimaryCG',
  'Caregiver',
];
export const memberRolesForInviteEvent = [
  null,
  'Admin',
  'PC',
  'C',
];

export const userRelationshipsForEvents = {
  1: 'mother',
  2: 'father',
  3: 'other',
  4: 'grandma',
  5: 'grandpa',
  6: 'auntie',
  7: 'uncle',
  8: 'nanny',
};

export const FAMILY_MISSING_IMAGE = 'images/family_avatar/original/missing.png';

export const FAKE_BABY = {
  name: 'Johnny',
  age_in_months: 12,
};
