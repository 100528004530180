import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Selectors } from '../../data/ducks/babiesAndFamilies';
import { breakPoints, colors } from '../../styles';
import { ReactComponent as Baby } from '../../images/feed/confirmation_baby.svg';
import NeoModal from './NeoModal';
import { Neo } from '../themes/RoundedButton';
import RoundedButton from '../shared/buttons/RoundedButton';
import { Invites, Modals } from '../../data/ducks';
import { Namespaces } from '../../values/i18n';

const Wrapper = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  padding: 80px 70px 0;
  margin: auto;
  box-sizing: border-box;
  max-width: ${props => props.width};
  height: 100vh;
  border-radius: unset;
  
  ${breakPoints.smallTablet} {
    height: auto;
    max-width: 620px;
    padding: 80px 0px 30px;
    border-radius: 20px;
  }
`;

const Title = styled.span`
  color: ${colors.darkGray};
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.64px;
  margin-bottom: 20px;
  max-width: 270px;
  ${breakPoints.smallTablet} {
    font-size: 16px;    
  }
  `;

const Description = styled.span`
  max-width: 324px;
  color: ${colors.darkGray};
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.5px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly; 
  flex-direction: column-reverse;
  ${breakPoints.smallTablet} {
    flex-direction: row;
  }
  .primaryActionBtn {
    margin-bottom: 0px;
    margin-left: 0px;
    ${breakPoints.smallTablet} {
      margin-left: 15px;
      margin-bottom: 20px;
    }
  }
`;
const ClassroomsConfirmModal = ({
  t, inviteData, isOpen,
}) => {
  const { newUserData, familyId, id: membershipId } = inviteData;
  const dispatch = useDispatch();
  const activeBaby = useSelector(state => Selectors.activeBaby(state));
  return (
    <Wrapper
      isOpen={isOpen}
      showCloseButton={false}
      closeOnBackdrop={false}
    >
      {() => (
        <>
          <Baby />
          <Title>
            {t('CLASSROOMS_CONFIRM_MODAL.TITLE', { centerName: newUserData.centerName })}
          </Title>
          <Description>
            {t('CLASSROOMS_CONFIRM_MODAL.SUBTITLE', { babyName: activeBaby.name, gender: activeBaby.gender })}
          </Description>
          <ButtonWrapper>
            <RoundedButton
              theme={Neo}
              width={160}
              styles={`
              border-color: ${colors.shadeMediumDark};
              color: ${colors.shadeMediumDark};
              height: 40px;
              font-size: 14px;
            `}
              onClick={() => dispatch(Modals.Creators.openModal({
                name: 'ClassroomsInviteModal',
                data: inviteData,
              }))}
            >
              {t('CLASSROOMS_CONFIRM_MODAL.CANCEL')}
            </RoundedButton>
            <RoundedButton
              className="primaryActionBtn"
              theme={Neo}
              width={160}
              styles={`
              height: 40px;
              font-size: 14px;
              maxWidth: 160px;
              border-color: ${colors.health};
              color: ${colors.health};
            `}
              onClick={() => dispatch(Invites.Creators.updateInviteStatusRequested(
                'classrooms_denied', familyId, membershipId,
              ))}
            >
              {t('CLASSROOMS_CONFIRM_MODAL.DECLINE_INVITATION')}
            </RoundedButton>
          </ButtonWrapper>
        </>
      )}
    </Wrapper>
  );
};
ClassroomsConfirmModal.propTypes = {
  inviteData: PropTypes.object,
  isOpen: PropTypes.bool,
};
ClassroomsConfirmModal.defaultProps = {
  inviteData: {},
  isOpen: false,
};


export default withTranslation([Namespaces.DAP])(ClassroomsConfirmModal);
