import { AWS_REGION, FILES_API_URL, S3_BUCKET_IMAGES } from "../../config";

const acceptedExtensions = [
  //Documents
  ".TXT",
  ".DOC",
  ".DOCX",
  ".DOCM",
  ".ODT",
  ".PDF",
  ".RTF",
  ".CSV",
  ".XLS",
  ".XLSM",
  ".ODS",
  ".PPS",
  ".PPT",
  ".PPSX",
  ".PPTX",
  ".PPSM",
  ".PPTM",
  ".POTX",
  ".ODP",
  //Audio
  ".MP3",
  ".WMA",
  ".WAV",
  ".FLAC",
  ".MIDI",
  ".OGG",
  ".M3U",
  //Video
  ".AVI",
  ".DIVX",
  ".MOV",
  ".MP4",
  ".MPG",
  ".MKV",
  ".WMV",
  ".WPL",
  //Images
  ".JPEG",
  ".JPG",
  ".PNG",
  ".BMP",
  ".ICO",
  ".SVG",
  ".WEBP",
  ".GIF",
  ".PSD",
  ".HEIC",
  ".NEF",
  ".CRW",
  ".AI",
  ".ID",
  //Others
  "IMAGE/JPG",
  "IMAGE/PNG",
  "VIDEO/MP4",
  "VIDEO/AVI",
  "VIDEO/MOV",
];

export const messages = {
  fileAttachments: {
    parentsChat: {
      maxMbFileSize: 300,
      accept: `${acceptedExtensions
        .join(",")
        .toLowerCase()},${acceptedExtensions.join(",")}`,
      bucket: S3_BUCKET_IMAGES,
      apiURL: FILES_API_URL,
      awsRegion: AWS_REGION,
      getS3path: (id) =>
        `/${localStorage.ORGANIZATION_ID || 'TEST'}/${localStorage.CLASSROOM_NAME || 'TEST'}/messages/${id}/`,
    },
  },
};
