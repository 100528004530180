import Immutable from 'seamless-immutable';
import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = Immutable({
  planMonthly: {
    plans: []
  },
  planBeastDeal: {
    plans: []
  },
  plans: [],
  isLoading: true
});

export const { Creators, Types } = createActions({
  configRequest: ['isLoading'],
  setConfigInitial: null,
  setConfig: ['premiumProcess']
});


const configRequest = (state, { isLoading }) => state.merge({ isLoading });

const setConfig = (state, { premiumProcess }) =>
  state.merge({ 
    planMonthly: premiumProcess.planMonthly,
    planBeastDeal: premiumProcess.planBeastDeal,
    plans: premiumProcess.plans
  });


export const Reducer = createReducer(INITIAL_STATE, {
  [Types.CONFIG_REQUEST]: configRequest,
  [Types.SET_CONFIG_INITIAL]: (state) => state.replace(INITIAL_STATE),
  [Types.SET_CONFIG]: setConfig
});
