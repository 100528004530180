import { css } from 'styled-components';

export const fade = (show, duration) => css`
  transition: opacity ${duration || 0.35}s ease-in-out;
  opacity: ${show ? '1' : '0'};
`;

export const fadeUp = (show, duration, top) => css`
  position: relative;
  transition: all ${duration || 0.35}s ease-out;
  top: ${show ? '0' : top || '10px'};
  opacity: ${show ? '1' : '0'};
`;
