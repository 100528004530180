import { css } from 'styled-components';

const Dropdown = {
  Title: css` `,
  DropdownButton: css` `,
  Indicator: css` `,
  Content: css` `,
  DropdownContainer: css` `,
  OverlayButton: css` `,
};

export default Dropdown;
