import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import NeoModal from './NeoModal';
import MasterSkillView from '../views/MasterSkillView';
import ProgressContainer from '../../containers/ProgressContainer';

const Modal = styled(NeoModal)`
    display: flex;
    flex-direction: colummn;
    align-items: center;
    justify-content: center;
    max-width: 460px;
    overflow: hidden;
`;

/*
  Dispatch example:

    {
      type: 'MODAL_OPENED',
      name: 'MasterSkillModal',
      data: {
      },
    }
*/

const MasterSkillModal = ({
  skill, baby, milestones, completedMilestones, activeMilestones, showDS, updateAllMilestones,
}) => (
  <Modal roundedCorners>
    {({ closeModal }) => (
      <ProgressContainer>
        {({ setMasterSkill }) => (
          <MasterSkillView
            skill={skill}
            baby={baby}
            milestones={milestones}
            closeModal={closeModal}
            setMasterSkill={setMasterSkill}
            completedMilestones={completedMilestones}
            activeMilestones={activeMilestones}
            showDS={showDS}
            updateAllMilestones={updateAllMilestones}
          />
        )}
      </ProgressContainer>
    )}
  </Modal>
);
MasterSkillModal.propTypes = {
  baby: PropTypes.object,
  milestones: PropTypes.array,
};

MasterSkillModal.defaultProps = {
  baby: {},
  milestones: [],
};

export default withRouter(MasterSkillModal);
