export const PaymentSources = {
  ADD_BABY: 'AddBaby',
  ADD_MEMBER: 'AddMember',
  CAT_ACT: 'CATAct',
  CAT_HOME: 'CATHome',
  DAP_ACTIVITY: 'DAPActivity',
  DAP_CHANGE_ACT: 'DAPChangeAct',
  DAP_NEXT_DAY: 'DAPNextDay',
  DAP_PAST_DAY: 'DAPPastDay',
  EMAIL: 'Email',
  MAIL: 'Mail',
  MY_PROFILE: 'MyProfile',
  PAST_ACT: 'PastActivities',
  PROGRESS_SKILL_GRAPH: 'SkillsGraph',
  PROMO_CODE: 'PromoCode',
  PROMP: 'Promo',
  PR_HOME: 'PRHome',
  SKILLS_MIL_ACT: 'SKILLSMilAct',
  UNDEFINED: 'Undefined',
  LEARN_CARROUSEL: 'learnCarrousel',
  EVENTS: 'events',
};

export default PaymentSources;
