import BaseApi, { localeFlag, setParam, contextSource } from './BaseApi';

// Progress endpoints
export const getBabyProgress = ({ babyId, current }) => BaseApi.get(`/babies/${babyId}/skills?${setParam('current', current)}&${localeFlag()}`);
export const getBabyProgressByArea = ({ babyId, areaId }) => BaseApi.get(`/babies/${babyId}/skills?in_area=${areaId}`);
export const getSkillDetails = ({ babyId, skillId }) => BaseApi.get(`/babies/${babyId}/skills/${skillId}/details?${localeFlag()}`);
export const getSkillMilestones = ({ babyId, skillId }) => BaseApi.get(`/babies/${babyId}/skills/${skillId}/milestones`);
export const getSkillMasterMilestones = ({ skillId }) => BaseApi.get(`/skills/${skillId}/master_milestones?${localeFlag()}`);
export const postSkillMilestones = ({ babyId, skillId, answers }) => BaseApi.post(`/babies/${babyId}/skills/${skillId}/milestones?stats=true&source=${contextSource()}`, { answers });
export const postSkillMastered = ({ babyId, skillId }) => BaseApi.post(`/babies/${babyId}/skills/${skillId}/master?source=${contextSource()}`);
export const getSkill = ({ babyId, skillId }) => BaseApi.get(`/babies/${babyId}/skills/${skillId}/milestones?${localeFlag()}`);
