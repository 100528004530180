import { merge, path } from 'ramda';
import { Reduxsauce, DEEP } from '../redux-sauce';
import { ContentTypes } from '../../shared-values';

// ------- Actions -------- //
export const Types = Reduxsauce.createTypes(`
  fetchPlanRequested
  fetchPlanSuccess
  fetchPlanError
  fetchPlanCanceled

  fetchMaterialsRequested
  fetchMaterialsSuccess
  fetchMaterialsError

  indapDisplayed
  indapClicked
  indapDismissed

  setCardTouched
  setArticleAsRead
  setArticleMarkAsRead

  setActivityUnlockedRequested
  setActivityUnlockedSuccess
  setActivityUnlockedError

  markActivityAsCompleted

  setActivityCompletedRequested
  setActivityCompletedSuccess
  setActivityCompletedError

  answerMilestonesRequested
  answerMilestonesSuccess
  answerMilestonesError

  setIndapDisplayed
  setIndapInactive

  fetchChangeActivitySuggestionsRequested
  fetchChangeActivitySuggestionsSuccess
  fetchChangeActivitySuggestionsError

  changeActivityRequested
  changeActivitySuccess
  changeActivityError

  unlockActivityWithShareRequested
  unlockActivityWithShareSuccess
  unlockActivityWithShareError

  checkIfBabyTurns24
  showBabyTurns24Modal
`);

export const Creators = {
  fetchPlan: id => ({ type: Types.fetchPlanRequested, id }),
  setCardTouched: instanceId => ({ type: Types.setCardTouched, instanceId }),
  setArticleAsRead: ({ articleId, instanceId }) => ({
    type: Types.setArticleAsRead,
    articleId,
    instanceId,
  }),
  setArticleMarkAsRead: ({ articleId, instanceId, babyId }) => ({
    type: Types.setArticleMarkAsRead,
    articleId,
    instanceId,
    babyId,
  }),
  markActivityAsCompleted: ({
    activityId,
    areaId,
    instanceId,
    milestones,
    planId,
    image,
    source,
    index,
    activity_type,
    history
  }) => ({
    type: Types.markActivityAsCompleted,
    instanceId,
    areaId,
    activityId,
    milestones,
    planId,
    image,
    source,
    index,
    activity_type,
    history
  }),
  answerMilestones: ({
    activityId,
    instanceId,
    milestones,
    planId,
    areaId,
    image,
    baby,
    source,
    index,
  }) => ({
    type: Types.answerMilestonesRequested,
    activityId,
    instanceId,
    milestones,
    planId,
    areaId,
    image,
    baby,
    source,
    index,
  }),
  setIndapDisplayed: ({ code }) => ({ type: Types.setIndapDisplayed, code }),
  setIndapInactive: ({ code }) => ({ type: Types.setIndapInactive, code }),
  setActivityUnlocked: ({ token, instanceId, activityId }) => ({
    type: Types.setActivityUnlockedRequested,
    token,
    instanceId,
    activityId,
  }),
  fetchChangeActivitySuggestions: ({ babyId, activityId, instanceId, option }) => ({
    type: Types.fetchChangeActivitySuggestionsRequested,
    babyId,
    activityId,
    instanceId,
    option,
  }),
  requestChangeActivity: ({ babyId, instanceId, newActivityId, option }) => ({
    type: Types.changeActivityRequested,
    babyId,
    instanceId,
    newActivityId,
    option,
  }),
  unlockActivityWithShare: ({ instanceId, activityId, title }) => ({
    type: Types.unlockActivityWithShareRequested,
    instanceId,
    activityId,
    title,
  }),
};

// ------- Selectors -------- //
const formatPlan = (plan) => {
  const { instances, days } = plan.asMutable(DEEP);
  const formatedDayItems = days?.map((day) => {
    let counter = 1;
    const items = day.items.map((item, index) => {
      if (item.instance_id) {
        instances[item.instance_id].index = index;
        if (item.type === ContentTypes.ACTIVITY) {
          instances[item.instance_id].activityIndex = counter;
          counter += 1;
        }
        return instances[item.instance_id];
      }
      return {};
    });
    const newList = merge(day, { items });
    return newList;
  });

  return merge(plan, { days: formatedDayItems });
};

const activePlan = (state) => {
  const { plans, activePlanId } = state.dap;
  const activePlanInstance = plans[activePlanId];

  if (activePlanInstance) {
    return formatPlan(activePlanInstance);
  }
  return false;
};

const currentPlan = (state) => {
  const { plans, currentPlanId } = state.dap;
  const currentPlanInstance = plans[currentPlanId];

  if (currentPlanInstance) {
    return formatPlan(currentPlanInstance);
  }
  return false;
};

/** @param {Number} id */
const materialsForPlan = (state, id) => {
  const { materials } = state.dap;
  return materials[id];
};

const getInstanceUri = (instance, instanceId, planId) => {
  switch (instance.type) {
    case 'activity':
      return `/activity/${instance.id}?context=dap&instanceId=${instanceId}&planId=${planId}`;
    case 'slideshow':
      return `/slideshow/${instance.id}?context=dap&instanceId=${instanceId}`;
    case 'article':
      return `/article/${instance.id}?context=dap&instanceId=${instanceId}`;
    default: return undefined;
  }
};


/**
 * @typedef {Object} InstanceContext
 * @property {String} next - Pagination URL to access next item in Plan.
 * @property {String} prev - Pagination URL to access prev item in Plan.
 * @property {Boolean} completed - Completed status of instance (activities).
 * @property {String} milestoneModalImage - The image used in milestone modal's header.
 */

/**
* Returns the context of a given instance inside a plan.
* @param {Object} state - redux state.
* @param {number} id - instance id.
* @return {InstanceContext} context.
*/
const DAPInstanceContext = (state, id) => {
  const DAP_INSTANCE_MAX = 4;
  const _activePlan = activePlan(state); // eslint-disable-line

  if (!_activePlan) {
    return;
  }

  const { instances, instanceOrder } = _activePlan;
  const instance = instances[id];
  const instanceIndex = instanceOrder.indexOf(id);

  const context = {
    contextFrom: 'dap',
  };

  if (instance.type === 'activity') {
    context.completed = instance.completed || false;
    context.milestoneModalImage = instance.thumbnails.size3;
    context.changed = instance.changed;
    context.isOriginal = instance.is_original;
  }

  const prevInstanceId = instanceOrder[instanceIndex - 1];
  const nextInstanceId = instanceOrder[instanceIndex + 1];
  const prevInstance = prevInstanceId ? instances[prevInstanceId] : null;
  const nextInstance = nextInstanceId ? instances[nextInstanceId] : null;

  if (prevInstance && !prevInstance.locked && instance.index > 0) {
    context.prev = getInstanceUri(prevInstance, prevInstanceId, _activePlan.id);
  }

  if (
    nextInstance &&
    !nextInstance.locked &&
    instance.index < DAP_INSTANCE_MAX
  ) {
    context.next = getInstanceUri(nextInstance, nextInstanceId, _activePlan.id);
  }

  return context; // eslint-disable-line
};

const hasIndapBeenDisplayed = (state, code) => {
  const _activePlan = activePlan(state); // eslint-disable-line
  if (!_activePlan) {
    return;
  }
  return _activePlan.instances[code].displayedBefore || false; // eslint-disable-line
};

const getCurrentDapRangeDates = (state, planId) => {
  const selectedPlan = state.dap.plans[planId];
  if (selectedPlan) {
    return { begins: selectedPlan.begins_at, ends: selectedPlan.ends_at };
  }
  return false;
};

const getDAPContext = (state) => {
  const activePlanId = path(['id'], activePlan(state));
  const currentPlanId = path(['id'], currentPlan(state));

  if (activePlanId === currentPlanId) {
    return 'current_dap';
  }
  return 'past_dap';
};

export const Selectors = {
  activePlan,
  currentPlan,
  DAPInstanceContext,
  materialsForPlan,
  hasIndapBeenDisplayed,
  getCurrentDapRangeDates,
  getDAPContext,
};

// ------- State --------//
const InitialState = Reduxsauce.createState({
  plans: {},
  materials: {},
  activePlanId: null,
  activePlanDay: 0,
  currentPlanId: null,
  loading: false,
  changedActivityInstanceId: null,
});

export const Reducer = Reduxsauce.createReducer(InitialState, {
  RESET: () => InitialState,
  // FETCH_PLAN
  [Types.fetchPlanRequested]: state =>
    state.merge({ plans: { loading: true, error: false } }, DEEP),
  [Types.fetchPlanCanceled]: state =>
    state.merge({ plans: { loading: false, error: false } }, DEEP),
  [Types.fetchPlanSuccess]: (state, action) =>
    state.merge(
      {
        plans: {
          [action.plan.id]: action.plan,
          loading: false,
          error: false,
        },
        activePlanDay: state.activePlanDay || action.plan.current_day_index,
        activePlanId: action.plan.id,
        currentPlanId: action.isCurrent ? action.plan.id : state.currentPlanId,
      },
      DEEP,
    ),
  [Types.fetchPlanError]: state =>
    state.merge({ plans: { loading: false, error: true } }, DEEP),
  /**
   * FETCH_MATERIALS --- Primo si truena eso de state.`slice`,
   *  metele mergeIn. Cuando salga quita esto lol.
   */
  [Types.fetchMaterialsRequested]: state =>
    state.merge({ materials: { loading: true, error: false } }, DEEP),
  [Types.fetchMaterialsSuccess]: (state, action) =>
    state.merge(
      {
        materials: {
          loading: false,
          error: false,
          [action.planId]: action.materials,
        },
      },
      DEEP,
    ),
  // [Types.fetchPlanError]: (state) => state.materials.merge({loading: false, error: true}),
  [Types.setCardTouched]: (state, action) =>
    state.merge(
      {
        plans: {
          [state.activePlanId]: {
            instances: {
              [action.instanceId]: { touched: true },
            },
          },
        },
      },
      DEEP,
    ),
  [Types.unlockActivityWithShareSuccess]: (state, action) =>
    state.merge(
      {
        plans: {
          [state.activePlanId]: {
            instances: {
              [action.instanceId]: { locked: false },
            },
          },
        },
      },
      DEEP,
    ),
  [Types.setActivityCompletedSuccess]: (state, action) =>
    state.merge(
      {
        plans: {
          [state.activePlanId]: {
            instances: {
              [action.instanceId]: { completed: true },
            },
          },
        },
      },
      DEEP,
    ),
  [Types.setIndapDisplayed]: (state, action) =>
    state.merge(
      {
        plans: {
          [state.activePlanId]: {
            instances: {
              [action.code]: {
                displayedBefore: true,
              },
            },
          },
        },
      },
      DEEP,
    ),
  [Types.setIndapInactive]: (state, action) =>
    state.merge(
      {
        plans: {
          [state.activePlanId]: {
            instances: {
              [action.code]: {
                inactive: true,
              },
            },
          },
        },
      },
      DEEP,
    ),
  [Types.fetchChangeActivitySuggestionsRequested]: (state, { option }) =>
    state.merge({ changeActivityOption: option }),
  [Types.fetchChangeActivitySuggestionsSuccess]: (state, { activities, code }) =>
    state.merge({
      activitySuggestions: activities,
      code,
    }),
  [Types.changeActivitySuccess]: (state, action) =>
    state.merge(
      {
        plans: {
          [state.activePlanId]: {
            instances: { [action.instance_id]: action.content },
          },
        },
      },
      DEEP,
    ),
});
