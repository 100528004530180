import React from 'react';
import styled from 'styled-components';

import NeoModal from './NeoModal';
import { colors } from '../../styles';

import IconsGrid from '../shared/IconsGrid';

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  background-color: #E0E0E0;
`;

const Title = styled.h2`
  color: ${colors.darkGray};
  font-size: 25px;
  font-weight: 500;
  text-align: center;
`;

const Content = styled.div`
  width: 100%;
  max-height: 600px;
  padding: 20px 20px 60px;
  box-sizing: border-box;
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'IconsGridModal',
  data: {
  },
}

*/

const IconsGridModal = () => (
  <Modal roundedCorners>
    {() => (
      <Content>
        <Title>All Icons</Title>
        <IconsGrid />
      </Content>
    )}
  </Modal>
);

export default IconsGridModal;
