import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { parse } from 'query-string';

import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import { Form } from 'formsy-react-2';

import { withTranslation } from 'react-i18next';
// import { LOCALSTORAGE_SOURCE_ACTIVITY_COMPLETED, LOCALSTORAGE_TYPE_ACTIVITY_COMPLETED } from 'shared-values';
import { Namespaces } from '../../values/i18n';

import Icon from '../shared/Icon';
import { breakPoints, colors, typography } from '../../styles';
import { applyColorByAreaId } from '../../styles/mixins';
import Modal from './NeoModal';
import Button from '../shared/buttons/MainButton';
import CheckBox from '../shared/CheckBox';
import iconChecked from '../../images/checked.svg';
import iconCheckedWhite from '../../images/checked-white.svg';
import { MARK_AS_COMPLETE_TO_MILESTONES } from 'shared-values';


const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  background-color: #F8F8F8;

  .submit {
    flex-shrink: 0;
    background: ${colors.cognitive};

    color: #FFF;
    font-weight: 500;

    width: 250px;
    height: 50px;
    margin: 0 auto 20px auto;

    border-radius: 4px;
  }
`;

const Header = styled.div`
  display: flex;
  position: relative;

  position: mdfa;
  flex: 0 0 183px;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  &:before {
    content: "";
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
  }

  h1 {
    color: #FFF;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    display: block;
    text-align: center;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, .3);
    text-transform: uppercase;
    width: 100%;
    margin-top: 16px;
    z-index: 1;
  }

  .Icon {
    box-sizing: border-box;
    height: 42px;
    width: 42px;
    padding: 9px;

    background-color: ${colors.cognitive};
    fill: #FFF;
    border-radius: 100%;
    z-index: 1;
  }

  @media screen and (min-width: ${breakPoints.largeTablet}) {
    flex-basis: 136px;
  }
`;

const NameHeading = styled.div`
  flex: 0 0 50px;
  width: 100%;

  background-color: ${applyColorByAreaId};

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 16px;
  box-sizing: border-box;

  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #FFF;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 128px);
  margin: auto;
  width: calc(100%);
`;

const MilestoneList = ({ milestones, handleChange, areaId }) => {
  console.log('%c milestones', 'background: #332167; color: #b3d1f6; font-size: 16px', milestones);
  const List = styled.ul`
    padding: 0;
    margin: 24px;

    @media screen and (min-width: ${breakPoints.largeTablet}) {
      margin: 24px 86px;
    }

    &.StyleAlternative {

      .RowContent {
        position: relative;
        .fake-input {
          opacity: 0;
        }

        span {
          cursor: pointer;
        }

        &:before {
          content: '';
          width: 26px;
          height: 26px;
          z-index: 2;
          position: absolute;
          left: 8px;
          top: 50%;
          background-image: url(${iconChecked});
          background-repeat: no-repeat;
          background-position: center;
          background-size: 24px 24px;
          transform: translateY(-50%);
        }

        &.RowContentChecked {
          background-color: #32AFE0;
          color: white;
          &:before {
            background-image: url(${iconCheckedWhite});
          }
        }
      }
    }
  `;

  const Row = styled.li`
    list-style: none;
    height: auto;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;

    label {
      span:first-child {
        color: ${applyColorByAreaId};
      }
      span {
        font-family: ${typography.gothamRounded} !important;
        font-weight: 400;

        font-size: 14px;
      }
    }
  `;

  return (
    <List className="StyleAlternative">
      {milestones.map((m, i) => {
        let classname = 'RowContent';
        if (m?.answer) {
          classname = 'RowContent RowContentChecked';
        }
        return (
          <Row className={classname} areaId={areaId} key={m.milestone_id}>
            <Form>
              <CheckBox name="milestones" label={m.name} checked={m.answer} onChange={() => handleChange(m, i)} />
            </Form>
          </Row>
      )})}
    </List>
  );
};

MilestoneList.propTypes = {
  handleChange: PropTypes.func,
  areaId: PropTypes.number,
};
MilestoneList.defaultProps = {
  handleChange: () => { },
  areaId: 0,
};

MilestoneList.propTypes = {
  milestones: PropTypes.arrayOf(PropTypes.shape({})),
};

MilestoneList.defaultProps = {
  milestones: [],
};

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'MilestonesModal',
  data: {
    milestones: [
      {
        id: 17,
        skill_id: 5,
        name: 'Crawls',
        area_id: 1,
        age: 10,
        age_group: '10-12',
        answer: true
      },
      {
        id: 230,
        skill_id: 5,
        name: 'Climbs or goes around obstacles when crawling',
        area_id: 1,
        age: 10,
        age_group: '10-12',
        answer: true
      },
      {
        id: 164,
        skill_id: 5,
        name: 'Changes directions easily when crawling',
        area_id: 1,
        age: 10,
        age_group: '10-12',
        answer: true
      }
    ],
    areaId: 1,
    image: '',
    baby: {
          id: 365279,
          name: 'alicia',
          lastname: '',
          age_group: '21-24',
          weeks_before_birth: 33,
          gender: 'male',
    }
  },
}

*/

class MilestonesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { milestones: undefined, milestonesChange: false };
    this.submit = this.submit.bind(this);
    this.handleOnClickNotYet = this.handleOnClickNotYet.bind(this);
    this.changeAnswer = this.changeAnswer.bind(this);
  }

  componentWillMount() {
    const { milestones } = this.props;
    const milestonesChange = milestones.filter(m => m.answer !== true).length === 0;
    if (milestones) {
      this.setState({
        milestonesChange,
        milestones: milestones.asMutable({ deep: true })
          .map(m => ({ milestone_id: m.id, answer: m.answer, name: m.name })),
      });
    }
  }

  componentWillUnmount() {
    // window.localStorage.setItem(LOCALSTORAGE_SOURCE_ACTIVITY_COMPLETED, '');
  }

  changeAnswer(m, i) {
    const items = this.state.milestones;
    items[i].answer = !m.answer;
    this.setState({ milestonesChange: true, newmilestones: items });
  }

  submit() {
    // const { context } = parse(this.props.location.search);
    this.props.onSubmit({ data: this.state.milestones });
    window.localStorage.removeItem(MARK_AS_COMPLETE_TO_MILESTONES);

    // this.props.history.push('/dap/current/');
    const { search, pathname } = window.location;
    const queryParams = parse(search);

    this.props.history.push(
      {
        search: new URLSearchParams({
          ...queryParams,
          isCompleted: true,
          goBack: -2,
        }).toString(),
      },
      { from: `${pathname}?${search}` },
    );



    // window.location.reload();
    //  else {
    //   window.location.reload();
    // }
  }

  handleOnClickNotYet(onCloseModal) {
    const { search, pathname } = window.location;
    const queryParams = parse(search);

    const isOFMilestone = window.localStorage.getItem(MARK_AS_COMPLETE_TO_MILESTONES) === 'true';

    this.props.history.push(
      {
        search: new URLSearchParams({
          ...queryParams,
          isCompleted: !isOFMilestone,
          goBack: -2,
        }).toString(),
      },
      { from: `${pathname}?${search}` },
    );

    if (onCloseModal) {
      onCloseModal();
    }
  }

  render() {
    const {
      image, areaId, baby, t,
    } = this.props;
    return (
      <Wrapper
        width={750}
        roundedCorners
        closeButtonColor="white"
      >
        {({ closeModal }) => (
          <Fragment>
            <Header image={image}>
              <Icon icon="check" size={40} />
              <h1>{t('content:ACTIONS.ACTIVITY_COMPLETED')}</h1>
            </Header>
            <NameHeading areaId={areaId}>
              {t('MODAL_MILESTONES.WHICH_THINGS_CAN_BABY_DO', { babyName: baby.name })}
            </NameHeading>
            {this.state.milestones
              ? (
                <MilestoneList
                  areaId={areaId}
                  handleChange={this.changeAnswer}
                  milestones={this.state.milestones}
                />
              ) : null
            }

            <Button
              color={this.state.milestonesChange ? 'green' : 'disabled'}
              maxWidth={300}
              onClick={this.state.milestonesChange ? this.submit : () => { }}
              style={{ margin: '0 auto 10px auto' }}
            >
              {t('MODAL_MILESTONES.UPDATE_PROGRESS')}
            </Button>
            <Button
              color="clear"
              maxWidth={300}
              onClick={() => this.handleOnClickNotYet(closeModal)}
              // onClick={closeModal}
              style={{ margin: '0 auto 20px auto' }}
            >
              {t('MODAL_MILESTONES.NOT_YET')}
            </Button>
            <Content />
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

MilestonesModal.propTypes = {
  onSubmit: PropTypes.func,
  areaId: PropTypes.number,
  baby: PropTypes.object,
  image: PropTypes.string,
  milestones: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    age: PropTypes.number,
    age_group: PropTypes.string,
    area_id: PropTypes.number,
    answer: PropTypes.bool,
  })),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.object,
  t: PropTypes.func,
};

MilestonesModal.defaultProps = {
  onSubmit: () => { },
  areaId: 0,
  baby: {},
  image: '',
  milestones: [],
  history: {},
  location: {},
  t: () => { },
};

export default compose(
  withTranslation([Namespaces.MODALS, Namespaces.CONTENT, Namespaces.COMMON]),
  withRouter,
)(MilestonesModal);
