import Immutable from 'seamless-immutable';
import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = Immutable({
  feed: [],
  classplan: null,
  loading: false,
});

export const { Creators, Types } = createActions({
  fetchFeedRequested: ['babyId', 'page'],
  fetchFeedSuccess: ['feed'],
  fetchFeedError: null,
  fetchClassplanRequested: ['babyId', 'groupId', 'skillProgrammeId'],
  fetchClassplanSuccess: ['classplan'],
  postEvidenceRequested: ['groupId', 'attachment'],
  postEvidenceSuccess: ['data'],
  postEvidenceError: null,
});

const getFeedList = state => state.feed || [];

const getCurrentClassroomPlan = state => state.classplan;

export const Selectors = {
  getFeedList,
  getCurrentClassroomPlan,
};

export const Reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_FEED_REQUESTED]: state => state.merge({ loading: true }),
  [Types.FETCH_FEED_SUCCESS]: (state, { feed }) => state.merge({ loading: false, feed }),
  [Types.FETCH_FEED_ERROR]: state => state.merge({ loading: false }),
  [Types.FETCH_CLASSPLAN_REQUESTED]: state => state.merge({ loading: true }),
  [Types.FETCH_CLASSPLAN_SUCCESS]: (state, { classplan }) => state.merge({ loading: false, classplan }),
  [Types.POST_EVIDENCE_REQUESTED]: state => state,
  [Types.POST_EVIDENCE_SUCCESS]: state => state,
  [Types.POST_EVIDENCE_ERROR]: state => state,
});
