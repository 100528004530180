import Immutable from 'seamless-immutable';
import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = Immutable({
  chatEduConfig: null,
  loading: false,
  hasUnreadMessages: false,
});

export const { Creators, Types } = createActions({
  fetchChatEduConfigRequested: ['babyId'],
  fetchChatEduConfigSuccess: ['data'],
  fetchChatEduConfigError: null,

  fetchUnreadMessagesRequested: ['groupId', 'personId'],
  fetchUnreadMessagesSuccess: ['hasUnreadMessages'],
  fetchUnreadMessagesError: null,
});

const getEduChatConfig = state => state.chatEduConfig || {};

export const Selectors = {
  getEduChatConfig,
};

export const Reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_CHAT_EDU_CONFIG_REQUESTED]: state => state.merge({ loading: true }),
  [Types.FETCH_CHAT_EDU_CONFIG_SUCCESS]: (state, { data }) => state
    .merge({ loading: false, chatEduConfig: data }),
  [Types.FETCH_CHAT_EDU_CONFIG_ERROR]: state => state.merge({ loading: false }),

  [Types.FETCH_UNREAD_MESSAGES_REQUESTED]: (state => state),
  [Types.FETCH_UNREAD_MESSAGES_SUCCESS]: (state, { hasUnreadMessages }) => state
    .merge({ hasUnreadMessages }),
  [Types.FETCH_UNREAD_MESSAGES_ERROR]: (state => state.merge({ hasUnreadMessages: false })),
});
