import { css } from 'styled-components';

export const fullBleed = position => css`
  position: ${position || 'fixed'};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;