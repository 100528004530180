import { Reduxsauce, DEEP } from '../redux-sauce';

export const Types = Reduxsauce.createTypes(`
  fetchPlanListSuccess
  fetchPlanListRequested
  fetchPlanListError
  fetchPlanListCanceled
`);

export const Creators = {
  fetchPlanList: () => ({ type: Types.fetchPlanListRequested }),
};

// ------- State -------- //
export const InitialState = Reduxsauce.createState({
  plans: [],
  loading: false,
});

export const Reducer = Reduxsauce.createReducer(InitialState, {
  [Types.fetchPlanListRequested]: state => state.merge({ loading: true }, DEEP),
  [Types.fetchPlanListSuccess]: (state, action) =>
    state.merge({ plans: action.plans, loading: false }, DEEP),
  [Types.fetchPlanListError]: state => state.merge({ loading: false }),
});
