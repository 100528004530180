import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { colors } from '../../../styles';
import { Namespaces } from '../../../values/i18n';

const StyledCustomTooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 2px 2px 25px ${colors.kineduShadeDark};
  border: 2px solid ${colors.kineduShadeDark};
  border-radius: 9px;

  color: ${colors.white};
  background: ${colors.kinedu};

  span {
    font-size: 12px;
    font-weight: bold;
  }
`;

const CustomTooltip = ({ payload, t }) => {
  if (!payload) return null;
  const milestones = payload[4];
  if (!milestones) return null;
  return (
    <StyledCustomTooltip className="graph-tooltip">
      <span>
        {t('PROGRESS_GRAPH.MILESTONES', {
          milestonesNum: milestones.value,
        })}

      </span>
    </StyledCustomTooltip>
  );
};

CustomTooltip.propTypes = {
  payload: PropTypes.array,
};

CustomTooltip.defaultProps = {
  payload: [],
};

export default withTranslation([Namespaces.PROGRESS])(CustomTooltip);
