import styled from 'styled-components';
import WebPreview from 'images/authView/web_app_preview.png';

const AuthViewWrapper = styled.div.attrs({
  className: 'AuthViewWrapper',
})`
  * {
    box-sizing: border-box;
  }

  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  max-height: 200vh;
  /* max-height: 100vh; */
  overflow-y: scroll;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
`;

const AuthViewContent = styled.div.attrs({
  className: 'AuthViewContent',
})`
  display: grid;
  width: 100%;
  min-height: 100%;
  grid-gap: 0;
  grid-template-columns: 1fr;
  grid-template-areas: "RightSideContent";

  ${({ theme }) => theme.breakPoints.desktop} {
    /* grid-gap: 10px; */
    grid-template-areas: "LeftSideContent RightSideContent";
    grid-template-columns: 38.89% 1fr;
  }
`;

const LeftSideContent = styled.div.attrs({ className: 'left-side' })`
  grid-area: LeftSideContent;
  display: none;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  box-shadow: 5px 0px 30px 0px rgba(0, 0, 0, 0.10);
  z-index: 1;
  padding: 70px 50px;
  position: relative;
  overflow: hidden;

  .InfoWrapper {
    z-index: 2;
    position: relative;
  }

  ${({ theme }) => theme.breakPoints.desktop} {
    display: block;
  }
`;

const LeftSideSquare = styled.div.attrs({ className: 'LeftSideSquare' })`
  position: absolute;
  top: -40px;
  left: -10px;
  display: block;
  width: 130px;
  height: 160px;
  background-color: rgba(62, 182, 70, 1);
  transform: rotate(105.268deg);
  z-index: 1;
`;
const LeftSideSquare2 = styled(LeftSideSquare).attrs({ className: 'LeftSideSquare2' })`
  background-color: rgba(0, 134, 216, 1);
  transform: rotate(99.809deg);
  top: auto;
  left: auto;
  width: 165px;
  height: 194px;
  bottom: -80px;
  right: -67px;
`;
const LeftSideCircle = styled.div.attrs({ className: 'LeftSideCircle' })`
  display: block;
  position: absolute;
  right: -123px;
  top: -100px;
  width: 227px;
  height: 227px;
  border-radius: 100%;
  background-color: #F9BA48;
  z-index: 1;
`;
const LeftSideCircle2 = styled(LeftSideCircle).attrs({ className: 'LeftSideCircle2' })`
  top: auto;
  right: auto;
  bottom: -200px;
  left: -170px;
  background-color: #D1296D;
  width: 324px;
  height: 324px;
`;



const RightSideContent = styled.div.attrs({ className: 'right-side' })`
  display: block;
  grid-area: RightSideContent;
  padding: 20px 20px 50px 20px;
  z-index: 2;
  height: 100%;
  ${({ theme }) => theme.breakPoints.desktop} {
    padding-top: 35px;
  }
`;

const LeftSideImage = styled.img.attrs({
  src: WebPreview,
})`
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 80%;
  transform: translate(-50%, -50%);
`;

export default AuthViewWrapper;
export {
  AuthViewContent,
  LeftSideContent,
  RightSideContent,
  LeftSideCircle,
  LeftSideCircle2,
  LeftSideSquare,
  LeftSideSquare2,
  LeftSideImage,
};
