import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Creators } from '../../data/ducks/modals';

import NeoModal from './NeoModal';
import PricingButton from '../shared/PricingButton';
import { colors, breakPoints } from '../../styles';
import Icon from '../shared/Icon';
import ProductList from '../../values/prices/products';

const Image = styled.img.attrs({ src: props => props.src })`
  max-width: ${props => props.size};
  display: block;

  ${breakPoints.xLargeTablet} {
    padding: 0 10px 0 0;
    box-sizing: border-box;
  }
`;

const Modal = styled(NeoModal)`
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  ${breakPoints.xLargeTablet} {
    flex-direction: column;
  }
`;

const Title = styled.h2`
  color: ${colors.kinedu};
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  margin: 20px 10px;
`;

const SubTitle = styled.h4`
  color: ${colors.kinedu};
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin: 0;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  padding: 20px;
  box-sizing: border-box;

  @media screen and (max-width: 799px) {
    box-shadow: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 20px 0px;
  box-sizing: border-box;

  ${breakPoints.xLargeTablet} {
    padding: 0px 60px;
    flex-wrap: nowrap;
  }
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  float: left;

  min-height: 100px;

  @media screen and (max-width: 799px) {
    width: 100%;
    float: none;

    img {
      margin: auto;
    }
  }
`;

const RightContent = styled.div`
  min-height: 100px;
  display: inline-block;
  float: left;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 799px) {
    width: 100%;
    float: none;
  }
`;

const List = styled.ul`
  padding-left: 20px;
`;

const ListItem = ({ title, description }) => {
  const Item = styled.li`
    position: relative;
    color: ${colors.dustyGray};
    font-size: 14px;

    text-align: left;
    letter-spacing: -0.5px;
    list-style-type: none;

    margin-bottom: 20px;

    svg {
      position: absolute;
      left: -25px;
      fill: ${colors.cognitive};
    }

    strong {
      font-weight: 500;
    }

    @media screen and (max-width: 900px) {
      margin-bottom: 20px;
    }
  `;

  return (
    <Item>
      <Icon icon="check-circle-full" size={21} />
      <strong>{title}</strong>
      {' '}
      {description}
    </Item>
  );
};

ListItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
ListItem.defaultProps = {
  title: '',
  description: '',
};

const PricingButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 692px;
  margin: 20px auto 40px;

  @media screen and (max-width: 900px) {
    width: 100%;
    display: block;
    background: #FFF;
    padding: 0px 20px 10px;
    box-sizing: border-box;
    margin: 0;
  }
`;

/*
  Dispatch example:

  {
    type: 'MODAL_OPENED',
    name: 'CtaModal',
    data: {
      source: WADapHome,
    }
  }
*/

class CtaModal extends React.Component {
  state = { selectedPricingButton: 4 }

  setSelected = (selectedPricingButton) => {
    this.setState({ selectedPricingButton });
  }

  openPaymentModal = plan => this.props.openModal({
    name: 'PaymentModal',
    data: { ...plan },
  });

  render() {
    const { ctaData: data, t } = this.props;

    return (
      <Modal onRequestClose={() => this.props.onClose()} width="854" isOpen={this.props.isOpen}>
        {() => (
          <Fragment>
            <Title>{t(`MODAL_CTAS.${data.title}`)}</Title>
            <Content>
              <LeftContent>
                <Image src={data.image} size={data.imageSize} />
              </LeftContent>
              <RightContent>
                <InfoContainer>
                  <SubTitle>{t(`MODAL_CTAS.${data.subtitle}`)}</SubTitle>
                  <List>
                    {data.items && data.items.map(item => (
                      <ListItem
                        title={t(`MODAL_CTAS.${item.title}`)}
                        description={t(`MODAL_CTAS.${item.description}`)}
                        key={item.title}
                      />
                    ))}
                  </List>
                </InfoContainer>
              </RightContent>
            </Content>
            <PricingButtonContainer>
              <PricingButton
                subtitle={`$${ProductList.m.price} USD`}
                title={`1 ${t('common:MONTH', { count: 1 })}`}
                selected={this.state.selectedPricingButton === 1}
                onClick={() => { this.openPaymentModal(ProductList.m); }}
              />
              <PricingButton
                subtitle={`$${ProductList.y.price} USD`}
                title={`12 ${t('common:MONTH', { count: 12 })}`}
                selected={this.state.selectedPricingButton === 3}
                onClick={() => { this.openPaymentModal(ProductList.y); }}
              />
              <PricingButton
                subtitle={`$${ProductList.lt.price} USD`}
                title={t('common:LIFETIME')}
                selected={this.state.selectedPricingButton === 4}
                onClick={() => { this.openPaymentModal(ProductList.lt); }}
              />
            </PricingButtonContainer>
          </Fragment>
        )}
      </Modal>
    );
  }
}

CtaModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  openModal: PropTypes.func,
  ctaData: PropTypes.shape({}),
};

CtaModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  openModal: () => {},
  ctaData: {},
};

const mapDispatchToProps = dispatch => bind({
  openModal: Creators.openModal,
}, dispatch);

export default compose(
  connect(() => ({}), mapDispatchToProps),
  withTranslation([Namespaces.MODALS, Namespaces.COMMON]),
)(CtaModal);
