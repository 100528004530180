import Abstract from '../images/skills/1_Skill_AbstractThinking.png';
import Attachment from '../images/skills/2_Skill_SecureAttachment.png';
import Babbling from '../images/skills/3_Skill_Babbling.png';
import Gestures from '../images/skills/4_Skill_Gestures.png';
import Crawling from '../images/skills/5_Skill_Crawling.png';
import DevSenses from '../images/skills/6_Skill_DevelopingSenses.png';
import EarlyExploration from '../images/skills/7_Skill_EarlyExploration.png';
import EarlyMovement from '../images/skills/8_Skill_EarlyMovement.png';
import DevDexterity from '../images/skills/9_Skill_DevelopingDexterity.png';
import FirstWords from '../images/skills/10_Skill_FirstWords.png';
import DevHandCoord from '../images/skills/11_Skill_DevelopingHandCoordination.png';
import HeadControl from '../images/skills/12_Skill_HeadControl.png';
import Imitating from '../images/skills/13_Skill_Imitating.png';
import LangComprehension from '../images/skills/14_Skill_LenguageComprehension.png';
import LangProduction from '../images/skills/15_Skill_LenguageProduction.png';
import LearningShapes from '../images/skills/16_Skill_LearningShapes.png';
import DevMemory from '../images/skills/17_Skill_DevelopingMemoryAtention.png';
import DevMusical from '../images/skills/18_Skill_DevelopingMusicalSkills.png';
import Newborn from '../images/skills/19_Skill_Newborn.png';
import PhysicalCoord from '../images/skills/20_Skill_PhysicalCoordination.png';
import DevSelfAwareness from '../images/skills/21_Skill_DevelopingSelfAwareness.png';
import FirstInteractions from '../images/skills/22_Skill_FirstInteractions.png';
import StandingUp from '../images/skills/23_Skill_StandingUp.png';
import SittingUp from '../images/skills/24_Skill_SittingUp.png';
import FirstSteps from '../images/skills/25_Skill_FirstSteps.png';
import Scribbling from '../images/skills/26_Skill_Scribbling.png';
import Sight from '../images/skills/27_Skill_Sight.png';
import Hearing from '../images/skills/28_Skill_Hearing.png';
import Smell from '../images/skills/29_Skill_Smell.png';
import Taste from '../images/skills/30_Skill_Taste.png';
import Touch from '../images/skills/31_Skill_Touch.png';
import Imagining from '../images/skills/32_Skill_Imagining.png';
import Memory from '../images/skills/33_Skill_MemoryAtention.png';
import Musical from '../images/skills/34_Skill_MusicalSkills.png';
import ProblemSolving from '../images/skills/35_Skill_ProblemSolving.png';
import ConceptLearning from '../images/skills/36_Skill_ConceptLearning.png';
import Conversation from '../images/skills/37_Skill_ConversationSkills.png';
import Grammar from '../images/skills/38_Skill_Grammar.png';
import Pronunciation from '../images/skills/39_Skill_Pronunciation.png';
import Balance from '../images/skills/40_Skill_Balance.png';
import Dexterity from '../images/skills/41_Skill_Dexterity.png';
import HandCoord from '../images/skills/42_Skill_HandCoordination.png';
import Jumping from '../images/skills/43_Skill_Jumping.png';
import Running from '../images/skills/44_Skill_Running.png';
import Kicking from '../images/skills/45_Skill_Kicking.png';
import Throwing from '../images/skills/46_Skill_ThrowingCatching.png';
import Walking from '../images/skills/47_Skill_Walking.png';
import Writting from '../images/skills/48_Skill_WrittingColoring.png';
import EmotionalIntelligence from '../images/skills/49_Skills_EmotionalIntelligence.png';
import DevIndependence from '../images/skills/50_Skill_DevelopingIndependence.png';
import Independence from '../images/skills/51_Skill_Independence.png';
import SelfAwareness from '../images/skills/52_Skill_SelfAwareness.png';
import SelfCare from '../images/skills/53_Skill_SelfCare.png';
import DevRelationships from '../images/skills/54_Skill_DevelopingRelationships.png';

import AbstractGS from '../images/skills/1_Skill_AbstractThinking_GS.png';
import AttachmentGS from '../images/skills/2_Skill_SecureAttachment_GS.png';
import BabblingGS from '../images/skills/3_Skill_Babbling_GS.png';
import GesturesGS from '../images/skills/4_Skill_Gestures_GS.png';
import CrawlingGS from '../images/skills/5_Skill_Crawling_GS.png';
import DevSensesGS from '../images/skills/6_Skill_DevelopingSenses_GS.png';
import EarlyExplorationGS from '../images/skills/7_Skill_EarlyExploration_GS.png';
import EarlyMovementGS from '../images/skills/8_Skill_EarlyMovement_GS.png';
import DevDexterityGS from '../images/skills/9_Skill_DevelopingDexterity_GS.png';
import FirstWordsGS from '../images/skills/10_Skill_FirstWords_GS.png';
import DevHandCoordGS from '../images/skills/11_Skill_DevelopingHandCoordination_GS.png';
import HeadControlGS from '../images/skills/12_Skill_HeadControl_GS.png';
import ImitatingGS from '../images/skills/13_Skill_Imitating_GS.png';
import LangComprehensionGS from '../images/skills/14_Skill_LenguageComprehension_GS.png';
import LangProductionGS from '../images/skills/15_Skill_LenguageProduction_GS.png';
import LearningShapesGS from '../images/skills/16_Skill_LearningShapes_GS.png';
import DevMemoryGS from '../images/skills/17_Skill_DevelopingMemoryAtention_GS.png';
import DevMusicalGS from '../images/skills/18_Skill_DevelopingMusicalSkills_GS.png';
import NewbornGS from '../images/skills/19_Skill_Newborn_GS.png';
import PhysicalCoordGS from '../images/skills/20_Skill_PhysicalCoordination_GS.png';
import DevSelfAwarenessGS from '../images/skills/21_Skill_DevelopingSelfAwareness_GS.png';
import FirstInteractionsGS from '../images/skills/22_Skill_FirstInteractions_GS.png';
import StandingUpGS from '../images/skills/23_Skill_StandingUp_GS.png';
import SittingUpGS from '../images/skills/24_Skill_SittingUp_GS.png';
import FirstStepsGS from '../images/skills/25_Skill_FirstSteps_GS.png';
import ScribblingGS from '../images/skills/26_Skill_Scribbling_GS.png';
import ImaginingGS from '../images/skills/32_Skill_Imagining_GS.png';
import MemoryGS from '../images/skills/33_Skill_MemoryAtention_GS.png';
import MusicalGS from '../images/skills/34_Skill_MusicalSkills_GS.png';
import ProblemSolvingGS from '../images/skills/35_Skill_ProblemSolving_GS.png';
import ConceptLearningGS from '../images/skills/36_Skill_ConceptLearning_GS.png';
import ConversationGS from '../images/skills/37_Skill_ConversationSkills_GS.png';
import GrammarGS from '../images/skills/38_Skill_Grammar_GS.png';
import PronunciationGS from '../images/skills/39_Skill_Pronunciation_GS.png';
import BalanceGS from '../images/skills/40_Skill_Balance_GS.png';
import DexterityGS from '../images/skills/41_Skill_Dexterity_GS.png';
import HandCoordGS from '../images/skills/42_Skill_HandCoordination_GS.png';
import JumpingGS from '../images/skills/43_Skill_Jumping_GS.png';
import RunningGS from '../images/skills/44_Skill_Running_GS.png';
import KickingGS from '../images/skills/45_Skill_Kicking_GS.png';
import ThrowingGS from '../images/skills/46_Skill_ThrowingCatching_GS.png';
import WalkingGS from '../images/skills/47_Skill_Walking_GS.png';
import WrittingGS from '../images/skills/48_Skill_WrittingColoring_GS.png';
import EmotionalIntelligenceGS from '../images/skills/49_Skills_EmotionalIntelligence_GS.png';
import DevIndependenceGS from '../images/skills/50_Skill_DevelopingIndependence_GS.png';
import IndependenceGS from '../images/skills/51_Skill_Independence_GS.png';
import SelfAwarenessGS from '../images/skills/52_Skill_SelfAwareness_GS.png';
import SelfCareGS from '../images/skills/53_Skill_SelfCare_GS.png';
import DevRelationshipsGS from '../images/skills/54_Skill_DevelopingRelationships_GS.png';

export const SkillsImages = {
  1: Abstract,
  2: Attachment,
  3: Babbling,
  4: Gestures,
  5: Crawling,
  6: DevSenses,
  7: EarlyExploration,
  8: EarlyMovement,
  9: DevDexterity,
  10: FirstWords,
  11: DevHandCoord,
  12: HeadControl,
  13: Imitating,
  14: LangComprehension,
  15: LangProduction,
  16: LearningShapes,
  17: DevMemory,
  18: DevMusical,
  19: Newborn,
  20: PhysicalCoord,
  21: DevSelfAwareness,
  22: FirstInteractions,
  23: StandingUp,
  24: SittingUp,
  25: FirstSteps,
  26: Scribbling,
  27: Sight,
  28: Hearing,
  29: Smell,
  30: Taste,
  31: Touch,
  32: Imagining,
  33: Memory,
  34: Musical,
  35: ProblemSolving,
  36: ConceptLearning,
  37: Conversation,
  38: Grammar,
  39: Pronunciation,
  40: Balance,
  41: Dexterity,
  42: HandCoord,
  43: Jumping,
  44: Running,
  45: Kicking,
  46: Throwing,
  47: Walking,
  48: Writting,
  49: EmotionalIntelligence,
  50: DevIndependence,
  51: Independence,
  52: SelfAwareness,
  53: SelfCare,
  54: DevRelationships,
};

export const SkillsImagesGS = {
  1: AbstractGS,
  2: AttachmentGS,
  3: BabblingGS,
  4: GesturesGS,
  5: CrawlingGS,
  6: DevSensesGS,
  7: EarlyExplorationGS,
  8: EarlyMovementGS,
  9: DevDexterityGS,
  10: FirstWordsGS,
  11: DevHandCoordGS,
  12: HeadControlGS,
  13: ImitatingGS,
  14: LangComprehensionGS,
  15: LangProductionGS,
  16: LearningShapesGS,
  17: DevMemoryGS,
  18: DevMusicalGS,
  19: NewbornGS,
  20: PhysicalCoordGS,
  21: DevSelfAwarenessGS,
  22: FirstInteractionsGS,
  23: StandingUpGS,
  24: SittingUpGS,
  25: FirstStepsGS,
  26: ScribblingGS,
  32: ImaginingGS,
  33: MemoryGS,
  34: MusicalGS,
  35: ProblemSolvingGS,
  36: ConceptLearningGS,
  37: ConversationGS,
  38: GrammarGS,
  39: PronunciationGS,
  40: BalanceGS,
  41: DexterityGS,
  42: HandCoordGS,
  43: JumpingGS,
  44: RunningGS,
  45: KickingGS,
  46: ThrowingGS,
  47: WalkingGS,
  48: WrittingGS,
  49: EmotionalIntelligenceGS,
  50: DevIndependenceGS,
  51: IndependenceGS,
  52: SelfAwarenessGS,
  53: SelfCareGS,
  54: DevRelationshipsGS,
};


export const FAKE_PROGRESS_DATA = {
  id: 6,
  area_id: 1,
  age_range: '0 - 9',
  parent_skill_id: null,
  title: 'Desenvolvimento dos Sentidos',
  description: 'Para aprender sobre o ambiente, seu bebê utiliza todos os seus sentidos. Os sentidos servem como um guia que estimula e recompensa as ações do seu filho. As experiências sensoriais o ajudam a aprender. O acúmulo dessas experiências constrói conexões neurais no cérebro do seu bebê que lhe permitem identificar o que ele gosta, quem é e onde está. Seu bebê começará a desenvolver essa habilidade desde o nascimento, assustando-se em resposta a sons altos ou respondendo ao toque, até finalmente completar essa habilidade ao mostrar uma reação diferente em relação a objetos quentes e frios.',
  logo_web: '/logo_webs/original/missing.png',
  logo_mobile: '/logo_mobiles/original/missing.png',
  children: true,
  children_skills: [
    {
      id: 27,
      area_id: 1,
      age_range: '1 - 10',
      parent_skill_id: 6,
      title: 'Vista',
      description: '',
      children: false,
      active_milestones: 4,
      completed_milestones: 0,
    },
    {
      id: 28,
      area_id: 1,
      age_range: '1 - 10',
      parent_skill_id: 6,
      title: 'Oído',
      description: '',
      children: false,
      active_milestones: 1,
      completed_milestones: 0,
    },
    {
      id: 29,
      area_id: 1,
      age_range: '1 - 10',
      parent_skill_id: 6,
      title: 'Olfato',
      description: '',
      children: false,
      active_milestones: 1,
      completed_milestones: 0,
    },
    {
      id: 30,
      area_id: 1,
      age_range: '1 - 10',
      parent_skill_id: 6,
      title: 'Gusto',
      description: '',
      children: false,
      active_milestones: 1,
      completed_milestones: 0,
    },
    {
      id: 31,
      area_id: 1,
      age_range: '1 - 10',
      parent_skill_id: 6,
      title: 'Tacto',
      description: '',
      children: false,
      active_milestones: 5,
      completed_milestones: 0,
    },
  ],
  active_milestones: null,
  completed_milestones: null,
  percentile: 0.04,
  progress: [
    {
      age: 0,
      active_milestones: 12,
      completed_milestones: 0,
      percentage: null,
      percentile: null,
      average_milestone_percentile_90: 7,
      average_milestone_percentile_75: 7,
      average_milestone_percentile_25: 4,
      average_milestone_percentile_10: 3,
    },
    {
      age: 1,
      active_milestones: 12,
      completed_milestones: 3,
      percentage: 0.01,
      percentile: 0.08,
      average_milestone_percentile_90: 7,
      average_milestone_percentile_75: 7,
      average_milestone_percentile_25: 4,
      average_milestone_percentile_10: 3,
    },
    {
      age: 2,
      active_milestones: 12,
      completed_milestones: 4,
      percentage: 0.01,
      percentile: 0.08,
      average_milestone_percentile_90: 7,
      average_milestone_percentile_75: 7,
      average_milestone_percentile_25: 4,
      average_milestone_percentile_10: 3,
    },
    {
      age: 3,
      active_milestones: 12,
      completed_milestones: 7,
      percentage: null,
      percentile: null,
      average_milestone_percentile_90: 10,
      average_milestone_percentile_75: 9,
      average_milestone_percentile_25: 6,
      average_milestone_percentile_10: 5,
    },
    {
      age: 4,
      active_milestones: 12,
      completed_milestones: 9,
      percentage: null,
      percentile: null,
      average_milestone_percentile_90: 11,
      average_milestone_percentile_75: 10,
      average_milestone_percentile_25: 7,
      average_milestone_percentile_10: 5,
    },
    {
      age: 5,
      active_milestones: 12,
      completed_milestones: 9,
      percentage: null,
      percentile: null,
      average_milestone_percentile_90: 11,
      average_milestone_percentile_75: 10,
      average_milestone_percentile_25: 7,
      average_milestone_percentile_10: 6,
    },
    {
      age: 6,
      active_milestones: 12,
      completed_milestones: 9,
      percentage: null,
      percentile: null,
      average_milestone_percentile_90: 11,
      average_milestone_percentile_75: 10,
      average_milestone_percentile_25: 8,
      average_milestone_percentile_10: 6,
    },
    {
      age: 7,
      active_milestones: 12,
      completed_milestones: 10,
      percentage: null,
      percentile: null,
      average_milestone_percentile_90: 11,
      average_milestone_percentile_75: 11,
      average_milestone_percentile_25: 8,
      average_milestone_percentile_10: 6,
    },
    {
      age: 8,
      active_milestones: 12,
      completed_milestones: 10,
      percentage: null,
      percentile: null,
      average_milestone_percentile_90: 12,
      average_milestone_percentile_75: 11,
      average_milestone_percentile_25: 9,
      average_milestone_percentile_10: 7,
    },
    {
      age: 9,
      active_milestones: 12,
      completed_milestones: 11,
      percentage: null,
      percentile: null,
      average_milestone_percentile_90: 12,
      average_milestone_percentile_75: 12,
      average_milestone_percentile_25: 9,
      average_milestone_percentile_10: 7,
    },
    {
      age: 10,
      active_milestones: 12,
      completed_milestones: 12,
      percentage: null,
      percentile: null,
      average_milestone_percentile_90: 12,
      average_milestone_percentile_75: 12,
      average_milestone_percentile_25: 9,
      average_milestone_percentile_10: 7,
    },
    {
      age: 11,
      active_milestones: 12,
      completed_milestones: 12,
      percentage: null,
      percentile: null,
      average_milestone_percentile_90: 12,
      average_milestone_percentile_75: 12,
      average_milestone_percentile_25: 9,
      average_milestone_percentile_10: 7,
    },
  ],
  activities: [
    {
      id: 48,
      name: 'Massagem Expressa',
      description: 'Comece esta atividade rolando uma bola macia sobre as pernas do seu bebê, fazendo uma massagem suave. Mova a bola em movimentos circulares, e observe suas reações. Se ele gostar da sensação, continue massageando suas pernas por mais 5 a 10 minutos. Esta massagem irá relaxar o seu bebê e melhorar seu sentido tátil e sua consciência corporal.',
      purpose: 'Relaxar os músculos.',
      age: 1,
      activity_type: 'activity',
      likes_count: 0,
      thumbnail: 'https://embed-ssl.wistia.com/deliveries/9093b7dc0ddd10b788ed341a26d84733a461c499.jpg?image_crop_resized=570x260&video_still_time=33',
      area_id: 1,
      is_holiday: false,
      domain_id: 20,
      faved: false,
      rating: null,
    },
    {
      id: 42,
      name: 'Seu entorno',
      description: 'Comece esta atividade levantando seu bebê nos braços, segurando-o com segurança para que seu campo visual seja ampliado. Agora, mostre a ele o ambiente, levando-o para passear pela casa ou pela cidade, nomeando objetos encontrados no caminho. Durante o passeio, preste atenção nas reações do seu bebê, e observe se ele fixa o olhar em objetos chamativos. Esta atividade estimulará a visão e o desenvolvimento da linguagem do seu bebê.',
      purpose: 'Ampliar o campo de visão.',
      age: 3,
      activity_type: 'activity',
      likes_count: 0,
      thumbnail: 'https://embed-ssl.wistia.com/deliveries/f1c97417e8c5979c7ebce1f51f671286740fb654.jpg?image_crop_resized=570x260&video_still_time=54',
      area_id: 1,
      is_holiday: false,
      domain_id: 20,
      faved: false,
      rating: null,
    },
    {
      id: 25,
      name: 'Cócegas',
      description: 'Comece esta atividade colocando seu bebê em uma posição confortável, e faça cócegas nos pés dele por cinco minutos. Não espere que seu bebê ria em voz alta por causa das cócegas, mas perceba que ele irá gostar da sua proximidade e do seu toque. Esta atividade irá estimular seu sentido tátil e ajudar no desenvolvimento da autoconsciência.',
      purpose: 'Estimular a percepção de texturas.',
      age: 1,
      activity_type: 'activity',
      likes_count: 0,
      thumbnail: 'https://embed-ssl.wistia.com/deliveries/eb8c9a03b775a56cb9e2afbc1ae4777a90cd5000.jpg?image_crop_resized=570x260&video_still_time=37',
      area_id: 1,
      is_holiday: false,
      domain_id: 20,
      faved: false,
      rating: null,
    },
    {
      id: 8,
      name: 'Que quentinho!',
      description: 'Mergulhe uma toalha pequena em água morna e passe-a sobre o corpo do seu bebê, massageando delicadamente. Repita com a água um pouco mais quente. Esta atividade o ajudará a desenvolver sensibilidade térmica ao perceber as mudanças na temperatura. Atividades sensoriais como esta melhoram o sentido tátil e a consciência corporal do seu bebê.',
      purpose: 'Desenvolver a percepção de mudanças de temperatura.',
      age: 1,
      activity_type: 'activity',
      likes_count: 0,
      thumbnail: 'https://embed-ssl.wistia.com/deliveries/d5e9caa94ddab5fc21fde82529dc2d87f946b971.jpg?image_crop_resized=570x260&video_still_time=32',
      area_id: 1,
      is_holiday: false,
      domain_id: 20,
      faved: false,
      rating: null,
    },
  ],
  articles: [
    {
      id: 3090,
      name: 'Ficar descalço é melhor para os bebês. Entenda!',
      picture: 'https://blog-pt.kinedu.com/wp-content/uploads/2018/06/picture-461-600x400.jpg',
      area_id: 1,
    },
    {
      id: 3047,
      name: '5 coisas que você não sabia sobre o seu bebê!',
      picture: 'https://blog-pt.kinedu.com/wp-content/uploads/2018/06/picture-418-600x400.jpg',
      area_id: 2,
    },
    {
      id: 3111,
      name: 'Por que meu bebê coloca tudo na boca?',
      picture: 'https://blog-pt.kinedu.com/wp-content/uploads/2018/06/picture-476-600x400.jpg',
      area_id: 2,
    },
    {
      id: 3075,
      name: 'Os primeiros anos: desenvolvimento motor',
      picture: 'https://blog-pt.kinedu.com/wp-content/uploads/2018/06/picture-446-600x400.jpg',
      area_id: 1,
    },
  ],
};

export const FAKE_SKILLS = [
  {
    id: 6,
    area_id: 1,
    age_range: '0 - 9',
    parent_skill_id: null,
    title: 'Developing the senses',
    description: "To learn about the environment, your baby uses all of his senses. They serve as a guide that stimulate and reward your little one's actions. Sensory experiences help your baby learn. The accumulation of these experiences builds neural connections in your little one's brain that allow him to identify what he likes, who he is and where. Your baby will start developing this skill as a newborn by startling in response to loud sounds or responding to touch, finally completing this skill when he reacts to different smells.",
    children: true,
    children_skills: [],
    progress_status: 'current',
    completed_milestones: 12,
    active_milestones: 12,
    percentile: 0.99,
    minAge: 0,
    maxAge: 9,
  },
  {
    id: 12,
    area_id: 1,
    age_range: '1 - 4',
    parent_skill_id: null,
    title: 'Head control',
    description: "At birth, your baby's head was too big and heavy for his neck muscles. But as your kid develops strength in his neck and upper body, he will be able to hold his head. At the beginning you’ll have to cradle his head, but soon enough your little one will be able to turn his head both sides when lying face up, lift his head for a few seconds when on his tummy and, finally, hold his head without any help.",
    children: false,
    progress_status: 'current',
    completed_milestones: 3,
    active_milestones: 6,
    percentile: 0.26,
    minAge: 1,
    maxAge: 4,
  },
  {
    id: 8,
    area_id: 1,
    age_range: '1 - 6',
    parent_skill_id: null,
    title: 'Early movements and coordination',
    description: "Early movement and coordination help your little one develop muscle strength and the ability to manipulate objects. As your baby develops this skill, you'll notice him move, kick or stretch his legs, strengthen his muscles to roll over, open and close his hands, reach, hold, shake and even move objects from hand to hand.",
    children: false,
    progress_status: 'current',
    completed_milestones: 4,
    active_milestones: 14,
    percentile: 0.44,
    minAge: 1,
    maxAge: 6,
  },
  {
    id: 7,
    area_id: 2,
    age_range: '1 - 7',
    parent_skill_id: null,
    title: 'Early exploration',
    description: "Early exploration allows your baby to learn how the world works. As he gains mobility, the world becomes a playground full of things that need to be discovered! Your little one's exploration ranges from looking at his hands and feet and placing them in his mouth, to grabbing objects and banging them together. With this skill your little scientist will go from observing to grasping, mouthing, shaking, pushing, throwing and having loads of fun!",
    children: false,
    progress_status: 'current',
    completed_milestones: 12,
    active_milestones: 12,
    percentile: 0.99,
    minAge: 1,
    maxAge: 7,
  },
  {
    id: 17,
    area_id: 2,
    age_range: '2 - 9',
    parent_skill_id: null,
    title: 'Developing memory and attention',
    description: 'Memory and attention are highly intertwined skills -they are not easy to separate. By paying attention to others, directing his attention to his surroundings, and carefully observing your actions, your baby will absorb knowledge and make the necessary connections to learn. Attention paves the way for memory and as it begins to flourish, he will closely observe how you do things, pay attention to conversations, and learn to correctly associate objects to their sounds!',
    children: false,
    progress_status: 'current',
    completed_milestones: 3,
    active_milestones: 7,
    percentile: 0.52,
    minAge: 2,
    maxAge: 9,
  },
  {
    id: 3,
    area_id: 3,
    age_range: '1 - 9',
    parent_skill_id: null,
    title: 'Babbling',
    description: "Learning to talk is no easy feat! To develop language, your baby must go through different babbling stages. At first, you'll hear him coo and gurgle \"ga\", \"gu\". Then his babbles will become more sophisticated as you hear vowels and consonants strung together, like \"ba\", \"da\", even \"ma-ma-ma\" and lots more fun sounds, shrieks and tones.",
    children: false,
    progress_status: 'current',
    completed_milestones: null,
    active_milestones: 9,
    percentile: 0.03,
    minAge: 1,
    maxAge: 9,
  },
  {
    id: 22,
    area_id: 4,
    age_range: '1 - 11',
    parent_skill_id: null,
    title: 'First interactions',
    description: "Babies are hardwired to be social! It's a basic survival skill that helps them build a bond with their caregivers. This skill begins to develop when your baby is just one month old as he pays attention to faces. Then he will develop his social smile, stare at the person speaking to him, laugh out loud and interact with other kids during play. Finally, your little one will complete this skill by pointing to show you what he wants you to see.",
    children: false,
    progress_status: 'current',
    completed_milestones: null,
    active_milestones: 8,
    percentile: 0.08,
    minAge: 1,
    maxAge: 11,
  },
  {
    id: 2,
    area_id: 4,
    age_range: '1 - 16',
    parent_skill_id: null,
    title: 'Secure attachment',
    description: "By being attuned to your baby’s cues and responding quickly and consistently to meet his needs, you help your baby develop a secure attachment. This gives your baby the confidence to explore his environment and has a significant impact on his developing personality, future relationships and wellbeing! You'll notice how your little one's secure attachment blooms when he calms down in your presence, smiles at others, stretches his arms to be carried and shows affection for the people he knows!",
    children: false,
    progress_status: 'future',
    completed_milestones: null,
    active_milestones: 11,
    percentile: 0.05,
    minAge: 1,
    maxAge: 16,
  },
];
