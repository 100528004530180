
// ***** GROUB BY FOR TYPE AND SORT BY NAME *****
// REACT
import React, { useEffect } from 'react';
// import { Redirect } from 'react-router-dom';

// COMPONENTS
// import PremiumProcess from "components/pricings/PremiumProcess";

// COMPONENTS LEGO
import { Spinner } from 'lego-dist';

import { parse } from 'query-string';
import { useHistory } from 'react-router-dom';

// EVENTS
import { APP_SECTIONS_PATHS } from 'router/AuthGuardedRoutes';
import EventReporter, { Events } from 'lib/EventReporter';

// HOOKS
// import usePremiumProcess from 'hooks/usePremiumProcess';
// import useUser from 'hooks/useUser';

// REDUX
import { useDispatch } from 'react-redux';

// REDUX CREATORS
import { Creators } from 'data/ducks/modals';
import { QUERY_PARAM_TO_SHOW_MODAL } from 'components/premiumProcessModal/values';


const PremiumProcessContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // const [fetchPremiumProcess, isLoading] = usePremiumProcess();
  // const { isFree } = useUser();

  useEffect(() => {
    EventReporter.view(Events.CHOOSE_PLAN_PREMIUM_PROCESS());
  }, []);

  useEffect(() => {
    // fetchPremiumProcess();
    /* eslint-disable */

    const queryParams = parse(window.location.search);
    queryParams[QUERY_PARAM_TO_SHOW_MODAL] = true;

    history.push({
      pathname: APP_SECTIONS_PATHS.TAP,
      search: new URLSearchParams({
        ...queryParams,
      }).toString(),
    });
  }, []);


  const openNotReadyModal = (source) => {
    EventReporter.action(Events.DISMISS_PREMIUM_PROCESS(source));
    dispatch(Creators.openModal({ name: 'NotReadyToUpgrateModal' }));
  }

  return (
    <Spinner loading name="threedots" />
  );

  // if (isLoading) {
  //   return <Spinner loading={ isLoading } name="threedots" />;
  // }

  // if (!isLoading && (!isFree)) {
  //   return <Redirect to="/settings" />
  // }

  // return <PremiumProcess onCancel={ openNotReadyModal } />
}

export default PremiumProcessContainer;
