import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import styled from 'styled-components';
import Formsy from 'formsy-react-2';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';
import { Creators } from '../../data/ducks/modals';
import { colors, breakPoints } from '../../styles';

import SegmentedButtons from '../shared/SegmentedButtons';
import { ColoredOptions } from '../themes/SegmentedButtons';
import Promo from './Promo';
import Modal from '../modals/NeoModal';
import Button from '../shared/buttons/MainButton';

import ProductList from '../../values/prices/products';
import EventReporter, { Events } from '../../lib/EventReporter';
import { getCountryNameByInitials } from '../../lib/utils';

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  background-color: ${colors.white};

  ${breakPoints.smallTablet} {

  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 54px;
  background-color: ${colors.kinedu};

  color: ${colors.white};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 24.5px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 20px;

  .label {
    text-transform: capitalize;
  }

  ${breakPoints.smallTablet} {
    padding: 10px 50px 30px;
  }
`;

const Description = styled.span`
  color: ${colors.kinedu};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.3px;
  text-align: center;

  margin: 10px;

  ${breakPoints.smallTablet} {
    color: ${colors.shadeDark};
    letter-spacing: -0.5px;
    margin: 20px;
  }
`;

const Form = styled(Formsy.Form)``;

const Note = styled.span`
  color: ${colors.shadeMediumDark};
  font-size: 12px;
  margin: 20px;
  text-align: center;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class PricingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plan: {},
    };
  }

  setPlan = (plan) => {
    const { source } = this.props;
    const subscriptionType = plan.type.toLowerCase();
    this.setState({ plan });
    EventReporter.action(Events.PP_PAYMENT_STARTED({
      payment_source: source,
      type: plan.code,
      subscription_type: subscriptionType,
    }));
  }

  openPaymentModal = () => {
    const { openModal, source } = this.props;
    const { plan } = this.state;
    const subscriptionType = plan.type.toLowerCase();
    openModal({ name: 'PaymentModal', data: { ...plan, source, subscriptionType } });
  }

  /*
  Dispatch example:

  {
    type: 'MODAL_OPENED',
    name: 'PricingModal',
    data: {
    },
  }

  */

  render() {
    const { user, t } = this.props;
    const { selectedOption, plan } = this.state;
    const userCountry = getCountryNameByInitials(user.country);
    const isBr = userCountry === 'BR';
    return (
      <Wrapper width={620} roundedCorners>
        {() => (
          <Fragment>
            <Header>{t('MODAL_PRICING_PLANS.TITLE')}</Header>
            <Content>
              <Description>
                {t('MODAL_PRICING_PLANS.SUBTITLE')}
              </Description>
              <Form onChange={data => this.setPlan(data.plan)}>
                <SegmentedButtons
                  onChange={values => this.setState({ selectedOption: values.code })}
                  theme={ColoredOptions}
                  name="plan"
                  options={[
                    {
                      label: t('pricings:PLANS_INFO.MONTHLY'),
                      sublabel: `$${ProductList.m.price}/${t('pricings:PLANS_INFO.EVERY_MONTHS', { count: 1 })}`,
                      color: colors.physical,
                      value: ProductList.m,
                      subComponent: (
                        <Promo
                          // freeTrialDays={ProductList.m.freeTrialDays}
                          percentage={!isBr ? ProductList.m.savingsPercent[userCountry] : ''}
                          invertColor={selectedOption === 'm'}
                        />),
                    },
                    // {
                    //   label: t('pricings:PLANS_INFO.SEMESTERLY'),
                    //   sublabel: `$${ProductList.s.price}/${t('pricings:PLANS_INFO.EVERY_SEMESTER')}`,
                    //   color: colors.linguistic,
                    //   value: ProductList.s,
                    //   subComponent: (
                    //     <Promo
                    //       // freeTrialDays={ProductList.s.freeTrialDays}
                    //       percentage={!isBr ? ProductList.s.savingsPercent[userCountry] : ''}
                    //       invertColor={selectedOption === 's'}
                    //     />),
                    // },
                    {
                      label: t('pricings:PLANS_INFO.YEARLY'),
                      sublabel: `$${ProductList.y.price}/${t('pricings:PLANS_INFO.ONCE_A_YEAR')}`,
                      color: colors.socialEmotional,
                      value: ProductList.y,
                      subComponent: (
                        <Promo
                          // freeTrialDays={ProductList.y.freeTrialDays}
                          percentage={ProductList.y.savingsPercent[userCountry]}
                          invertColor={selectedOption === 'y'}
                        />),
                    },
                    {
                      label: t('pricings:PLANS_INFO.LIFETIME'),
                      sublabel: `$${ProductList.lt.price}/${t('pricings:PLANS_INFO.LIFETIME_PAYMENT')}`,
                      color: colors.kinedu,
                      value: ProductList.lt,
                      subComponent: (
                        <Promo
                          // freeTrialDays={ProductList.lt.freeTrialDays}
                          percentage={!isBr ? ProductList.lt.savingsPercent[userCountry] : ''}
                          invertColor={selectedOption === 'lt'}
                        />),
                    },
                  ]}
                />
                <Footer>
                  <Note>
                    {t('MODAL_PRICING_PLANS.NOTE')}
                  </Note>
                  <Button
                    type="submit"
                    color={selectedOption ? 'green' : 'disabled'}
                    maxWidth={300}
                    disabled={!selectedOption}
                    style={{ textTransform: 'capitalize' }}
                    onClick={this.openPaymentModal}
                  >
                    {t('pricings:PLANS_INFO.BUY_SUBSCRIPTION',
                      { plan: plan.type ? t(`pricings:PLANS_INFO.${plan.type}`) : '' })}
                  </Button>
                </Footer>
              </Form>
            </Content>
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

PricingModal.propTypes = {
  user: PropTypes.object,
  source: PropTypes.string,
  openModal: PropTypes.func,
  t: PropTypes.func,
};

PricingModal.defaultProps = {
  user: {},
  source: '',
  openModal: () => { },
  t: () => { },
};

const mapStateToProps = state => ({
  user: state.user,
});
const mapDispatchToProps = dispatch => ({
  openModal: bind(Creators.openModal, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation([Namespaces.MODALS, Namespaces.PRICINGS]),
)(PricingModal);
