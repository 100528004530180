// import { showTerminalLogs } from './lib/utils';
import LEANPLUM_DEFAULT_DATA from './values/leanplum';

export const Environments = {
  PRODUCTION: 'PRODUCTION',
  STAGE: 'STAGE',
  QA: 'QA',
};

/**
 * INFO:NOTE: Use the following semver tags format:
 * X.Y.Z-alpha   for dev branches (works in progress)
 * X.Y.Z-beta.W  for test releases (feature branch)
 * X.Y.Z-rc.W    for pre-prod releases (development branch)
 * X.Y.Z         for prod releases (master branch)
*/

export const APP_ENV = process.env.REACT_APP_ENV || Environments.QA;
export const APP_VERSION = '3.46.0';
export const APP_VERSION_DESCRIPTION = 'Updates:04-07-2024';

/**
 * When creating a build, react set the NODE_ENV to production.
 * In order to create a staging build (for testing) you can force the dev mode from here.
 * You can also, force a production mode on localhost.
 * WARNING:NOTE: SET TO FALSE WHEN BUILDING FOR PRODUCTION
 */
export const FORCED_MODE = /^true$/i.test(process.env.REACT_APP_FORCED_MODE) || false;
export const MODE = Environments[APP_ENV];

export const IS_DEVELOPMENT_MODE = MODE !== Environments.PRODUCTION;

/**
 * Force the API to production
 * NOTE: This left anything else the same
 */
export const FORCE_API_TO_PROD = false;
/**
 * Events are only showed on dev mode, but can be hidden from here
 */
export const DEBUG_EVENTS = true;
/**
 * Show or hide the logger
 * NOTE: The logger is not shown on production
 */
export const SHOW_LOGGER = true;
/**
 * Show or hide the logger
 * NOTE: The logger is not shown on production
 */
export const DEBUG_GOOGLE_ANALYTICS = false;
/**
 * Set other api to test ej. 'TEST'.
 * NOTE: This overwrites all other apis
 */
export const OTHER_API = '';

export const LINK_INSTALL_APPS = {
  APPSTORE: {
    BROWSER: 'https://apps.apple.com/us/app/kinedu-baby-development-plan/id741277284',
    MOBILE: 'https://apps.apple.com/us/app/kinedu-baby-development-app/id741277284',
  },
  PLAYSTORE: {
    BROWSER: 'https://play.google.com/store/apps/details?id=com.kinedu.appkinedu&hl=es_419',
    MOBILE: 'market://details?id=com.kinedu.appkinedu',
  },
};

/**
 * Use another token to emulate a session.
 */
export const EMULATED_SESSION = {
  HARDCODED_TOKEN: '',
  USER_ID: 0,
};

const setEnvironment = () => (process.env.NODE_ENV === 'development' ? MODE : Environments.PRODUCTION);
const forceMode = (isActive, mode) => (isActive ? Environments[mode] : setEnvironment());
/**
 * INFO: This is the variable that controls all environments in the app
 */
export const ENV = forceMode(FORCED_MODE, MODE);
const forceProdMode = isActive => (isActive ? Environments.PRODUCTION : ENV);
const debugEvents = isActive => (isActive ? ENV === Environments[MODE] : false);

export const KINEDU_API_VERSION = 'v3';

export const KINEDU_API = {
  [Environments.PRODUCTION]: 'https://api.kinedu.com',
  [Environments.STAGE]: 'https://staging.kinedu.com/api',
  [Environments.QA]: 'https://qa.kinedu.com/api',
  TEST: 'https://testapi.kinedu.com',
  DEMO: 'https://demo.kinedu.com',
};

export const API_URL = KINEDU_API[OTHER_API || forceProdMode(FORCE_API_TO_PROD)];

export const KINEDU_CALENDAR_API = {
  [Environments.PRODUCTION]: 'https://events-calendar.kinedu.com',
  [Environments.STAGE]: 'https://staging-calendar-api.kinedu.com',
  [Environments.QA]: 'https://service-qa-calendar.kinedu.com',
};

export const CALENDAR_API_URL = KINEDU_CALENDAR_API[forceProdMode(FORCE_API_TO_PROD)];

// export const SENTRY_DNS = 'https://bc324e5634c54b7aa57f8dc57be807db@o1175237.ingest.sentry.io/4504041025175552';
export const SENTRY_DNS = {
  [Environments.PRODUCTION]: 'https://bc324e5634c54b7aa57f8dc57be807db@o1175237.ingest.us.sentry.io/4504041025175552',
  [Environments.STAGE]: '',
  [Environments.QA]: '',
}[ENV];

// export const FB_PIXEL_ID = '1429573994459152';

export const FB_APP_ID = {
  [Environments.PRODUCTION]: '548069311920318',
  [Environments.STAGE]: '548069311920318',
  [Environments.QA]: '548069311920318',
}[ENV];

export const FB_PIXEL_ID = {
  [Environments.PRODUCTION]: '2089634224620932',
  [Environments.STAGE]: '984745516103264',
  [Environments.QA]: '984745516103264',
}[ENV];

export const GA_ID = {
  [Environments.PRODUCTION]: 'UA-117789789-1',
  [Environments.STAGE]: 'UA-117789789-2',
  [Environments.QA]: 'UA-117789789-2',
}[ENV];

export const CONEKTA_API_KEY = {
  [Environments.PRODUCTION]: 'key_dxrKsWTmyTGrAQBa',
  [Environments.STAGE]: 'key_IXka8DMBz8hzWPDs',
  [Environments.QA]: 'key_IXka8DMBz8hzWPDs',
}[ENV];

export const STRIPE_API_KEY = {
  [Environments.PRODUCTION]: 'pk_live_517nvHPHyARnCRniYrQ9AtHgi3DW9QgZ0iacViT1o4ZBNbRRH601GxFJySqqwcw96lkPrMDBhBEp9xbnbSEZ8kwE700W06KMJ2V',
  [Environments.STAGE]: 'pk_test_1YpqkZ0huc6D5JfHRaAoPDwT',
  [Environments.QA]: 'pk_test_1YpqkZ0huc6D5JfHRaAoPDwT',
}[ENV];

export const STRIPE_API_KEY_BRAZIL = {
  [Environments.PRODUCTION]: 'pk_live_51HRiFsDpdTt6cZbI15THgvIIwaruh290E0K9D8uwK1jg0BGeVpdRVd2QfOkfMBoj1LlzjczygSlc5rUOwbKzLiL6003vGOdWuP',
  [Environments.STAGE]: 'pk_test_51HRiFsDpdTt6cZbIoGGU5naTbv2xYxEHOGNnp5Wwa5mYfo8lQcyQkwgZMtoRNBnxsv1B5Bu7MlDfVNjuokA0u5aw00cNElrRbT',
  [Environments.QA]: 'pk_test_51HRiFsDpdTt6cZbIoGGU5naTbv2xYxEHOGNnp5Wwa5mYfo8lQcyQkwgZMtoRNBnxsv1B5Bu7MlDfVNjuokA0u5aw00cNElrRbT',
}[ENV];

export const MIXPANEL = {
  [Environments.PRODUCTION]: '5d05dc28f51a6629a3c1ee557aeb3685',
  [Environments.STAGE]: '91dd8fbd39a6f591f4df13e843fc3379',
  [Environments.QA]: 'd60021dc9bc0a498baadcd26d4c31272',
}[ENV];

export const GOOGLE_TAG_MANAGER = {
  [Environments.PRODUCTION]: {
    ACCOUNT_ID: 'GTM-T4NRDWJ',
    AUTH_KEY: 'tKzuJfSPOy3Av9ei_k6cEw',
    PREVIEW_ENV: 'env-2',
  },
  [Environments.STAGE]: {
    ACCOUNT_ID: 'GTM-PPV76MH',
    AUTH_KEY: 'aoXOUk-MN4C4mSJwzyPeiw',
    PREVIEW_ENV: 'env-2',
  },
  [Environments.QA]: {
    ACCOUNT_ID: 'GTM-PPV76MH',
    AUTH_KEY: 'aoXOUk-MN4C4mSJwzyPeiw',
    PREVIEW_ENV: 'env-2',
  },
}[ENV];

export const GOOGLE_OPTIMIZE_CONTAINER = {
  [Environments.PRODUCTION]: 'GTM-52CS998',
  [Environments.STAGE]: 'GTM-WMFS96G',
  [Environments.QA]: 'GTM-WMFS96G',
}[ENV];

export const AWS_REGION = {
  [Environments.PRODUCTION]: 'us-east-1',
  [Environments.STAGE]: 'us-east-1',
  [Environments.QA]: 'us-east-1',
}[ENV];

export const AWS_KEY = {
  [Environments.PRODUCTION]: 'AKIAJ5R6AKWKZ5XVGFAQ',
  [Environments.STAGE]: 'AKIAJ5R6AKWKZ5XVGFAQ',
  [Environments.QA]: 'AKIAJ5R6AKWKZ5XVGFAQ',
}[ENV];

export const AWS_PREMIUMPROCESS_URL = {
  [Environments.PRODUCTION]: 'https://6qlclw8whg.execute-api.us-west-2.amazonaws.com/api',
  [Environments.STAGE]: 'https://bupdacbh4c.execute-api.us-west-2.amazonaws.com/api',
  [Environments.QA]: 'https://bupdacbh4c.execute-api.us-west-2.amazonaws.com/api',
}[ENV];

export const S3_BUCKET_IMAGES = {
  [Environments.PRODUCTION]: 'classrooms-media-prod-destination-6wqx3m271tx4',
  [Environments.STAGE]: 'classrooms-media-staging-destination-1g3kt6xnf6gqm',
  [Environments.QA]: 'classrooms-media-staging-destination-1g3kt6xnf6gqm',
}[ENV];

export const MESSAGES_API_URL = {
  [Environments.PRODUCTION]: 'https://messages.kinedu.com/',
  [Environments.STAGE]: 'https://staging-messages.kinedu.com/',
  [Environments.QA]: 'https://service-qa-messages.kinedu.com',
}[ENV];

export const MESSAGES_API_TOKEN = {
  [Environments.PRODUCTION]: '1|pSdOgE8CaK5CAcVEUfUY1WpLt8fBwiDtPWe1RRjdGGJ6NWsDUpnbrvAgcCaW50RhXN1bfb2TXRUaf4Zf',
  [Environments.STAGE]: '1|wa6b8zo2gpvet8NggFLNG0i4iFblOqVOkjtHLP3Ijh9RiOeRmFWn7hpGXLPlalIgX1TrLRNan5Kvv1WF',
  [Environments.QA]: '1|wa6b8zo2gpvet8NggFLNG0i4iFblOqVOkjtHLP3Ijh9RiOeRmFWn7hpGXLPlalIgX1TrLRNan5Kvv1WF',
}[ENV];

export const FILES_API_URL = {
  [Environments.PRODUCTION]: 'https://api.kinedu.com/v4',
  [Environments.STAGE]: 'https://staging.kinedu.com/api/v4',
  [Environments.QA]: 'https://qa.kinedu.com/api/v4',
}[ENV];


const PAYWALL_CONFIG_QA = {
  plan: 'play_learn',
  planType: 'paywall_play_learn',
  source: 'paywallPlayLearn',
  skus: {
    en: 'kinedu_learn_12_ht_ft',
    es: 'kinedu_learn_12_ht_ft_mx',
    pt: 'kinedu_learn_12_ht_ft',
  },
  prices: {
    en: {
      text: '$79.99 USD',
      id: 'price_1MoXCVHyARnCRniYcWlwxEpJ',
    },
    es: {
      text: '$1499 MXN',
      id: 'price_1Nf8chHyARnCRniYgmBnP1cE',
    },
    pt: {
      text: '$399.90 BRL',
      id: 'price_1MtFDGDpdTt6cZbITytOat18',
    },
  },
};

const PAYWALL_CONFIG_PRODCTION = {
  plan: 'play_learn',
  planType: 'paywall_play_learn',
  source: 'paywallPlayLearn',
  skus: {
    en: 'kinedu_learn_12_ht_ft',
    es: 'kinedu_learn_12_ht_ft_mx',
    pt: 'kinedu_learn_12_ht_ft',
  },
  prices: {
    en: {
      text: '$79.99 USD',
      id: 'price_1M7mCtHyARnCRniY3FtSjqpO',
    },
    es: {
      text: '$1499 MXN',
      id: 'price_1NfUeBHyARnCRniYeevRismO',
    },
    pt: {
      text: '$399.90 BRL',
      id: 'price_1M7mAxDpdTt6cZbIgTV9Cv2e',
    },
  },
};


export const PAYWALL_CONFIG = {
  [Environments.PRODUCTION]: PAYWALL_CONFIG_PRODCTION,
  [Environments.STAGE]: PAYWALL_CONFIG_QA,
  [Environments.QA]: PAYWALL_CONFIG_QA,
}[ENV];

export const IS_LOCALHOST = window.location.hostname === 'localhost';

export const LOCALES_PATH = `${IS_LOCALHOST ? '/../app/' : '/app/'}locales/{{lng}}/{{ns}}.json?v=${APP_VERSION}`;


export const LEANPLUM_CONFIG = {
  APP_ID: process.env.REACT_APP_LEANPLUM_APP_ID,
  KEY: process.env.REACT_APP_LEANPLUM_KEY,
};

export const LEANPLUM_DEFAULT = LEANPLUM_DEFAULT_DATA;

/**
 * NOTE: This variable change to true if the api is
 * on staging when making a build for production.
 * If this happend some day, and the message shows,
 * then the Web Apocalypse will have begun.
 */
export const API_WARNING = ((ENV === Environments.PRODUCTION)
  && (API_URL === KINEDU_API[Environments.STAGE] || API_URL === KINEDU_API[Environments.QA]));

export const DEBUG_KINEDU_EVENTS = debugEvents(DEBUG_EVENTS);
// showTerminalLogs(true);
