import styled, { css } from 'styled-components';
import { fade } from '../../styles/transitions';

export default styled.div`
  height: 50px;
  width: 50px;
  margin: 10px auto 25px;

  border-radius: 100%;
  background: url(${({ picture }) => picture});
  border-radius: 100px;
  background-size: cover;
  box-shadow: 0 20px 30px rgba(0,0,0,0.11), 0px 10px 10px rgba(0,0,0,0.22);
  background-color: rgba(255,255,255,0.8);

  /* ${({ show }) => fade(show, 1 / 1000)} */
  transition: all 0.35s ease-in-out;

  ${({ show, theme }) => (show === 'exiting' || theme.mobile) && css`
    height: 0;
    margin: 0 auto;
  `}

  ${({ show, theme }) => (show === 'entered' || theme.mobile) && css`
    height: 0;
    margin: 0 auto;
  `}
`;
