import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakPoints, colors } from '../../../styles';
import { fade } from '../../../styles/transitions';

const DAP_CARD_WIDTH = 280;

/* These are the card's highest layer's styles */
const DefinitiveWrapper = styled.div.attrs({
  className: 'card-definitive-wrapper',
})`
  position: relative;
  top: 0px;
  width: 100%;
  max-width: 425px;
  max-width: ${({ maxWidth }) => (Number.isInteger(maxWidth) ? `${maxWidth}px` : maxWidth)};
  margin: ${({ gap }) => gap}px;
  border-radius: 6px;
  box-shadow: 0px 4px 30px -12px ${colors.shadeDark};

  color: #fff;
  background-image: ${props => (props.image ? `url(${props.image})` : '')};
  background-size: cover;
  background-position: ${({ bgPosition }) => bgPosition};

  ${({ show }) => fade(show, 0.4)}
  transition: all 0.4s ease-in-out;

  cursor: pointer;
  overflow: hidden;
  user-select: none;

  :hover {
    top: -2px;
    box-shadow: 0px 22px 80px -30px ${colors.shadeDark};
    :after {
      animation: shine 1.5s ease 0s 1 normal none running;
    }
  }

  ${breakPoints.smallTablet} {
    width: ${DAP_CARD_WIDTH}px;
    width: ${({ width }) => (Number.isInteger(width) ? `${width}px` : width)};
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    transition: all 0.5s ease-in-out;
    background: linear-gradient(311deg, transparent 30%, rgb(255, 255, 255, 0.2), transparent 70%) 0% 0% / 300% 300%;

    @keyframes shine {
      0%  {background-position:0% 50%; opacity: 0;}
      20% {opacity: 1;}
      50% {background-position:100% 50%;}
      70% {opacity: 0;}
      100% {background-position:100% 50%; opacity: 0;}
    }
  }
`;

/* This keeps the aspect ratio */
const MagicWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding: 0;
  padding-bottom: ${({ heightRatio }) => heightRatio}%;

  ${breakPoints.smallTablet} {
    padding-bottom: ${({ mdHeightRatio }) => mdHeightRatio}%;
  }
`;

/* This creates a solid ground to place new elements */
const Foundations = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  ${({ innerStyle }) => innerStyle};
`;

const BaseCard = ({
  children,
  className,
  image,
  onClick,
  style,
  innerStyle,
  width,
  maxWidth,
  heightRatio,
  mdHeightRatio,
  bgPosition,
  gap,
  show,
}) => (
  <DefinitiveWrapper
    image={image}
    className={className}
    onClick={onClick}
    style={style}
    width={width}
    maxWidth={maxWidth}
    bgPosition={bgPosition}
    gap={gap}
    show={show}
  >
    <MagicWrapper heightRatio={heightRatio} mdHeightRatio={mdHeightRatio}>
      <Foundations innerStyle={innerStyle}>
        {children}
      </Foundations>
    </MagicWrapper>
  </DefinitiveWrapper>
);

BaseCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.node,
  width: PropTypes.number,
  show: PropTypes.bool,
  image: PropTypes.string,
  style: PropTypes.object,
  innerStyle: PropTypes.object,
  gap: PropTypes.number,
  maxWidth: PropTypes.number,
  heightRatio: PropTypes.number,
  mdHeightRatio: PropTypes.number,
  bgPosition: PropTypes.string,
  onClick: PropTypes.func,
};

BaseCard.defaultProps = {
  children: null,
  className: null,
  width: null,
  show: false,
  style: {},
  innerStyle: {},
  gap: 10,
  maxWidth: 425,
  heightRatio: 85,
  mdHeightRatio: 85,
  bgPosition: '',
  image: '',
  onClick: () => {},
};

export default BaseCard;
