import Immutable from 'seamless-immutable';
import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = Immutable({
  skus: {},
  loading: false,
});

export const { Creators, Types } = createActions({
  fetchDefaultPricesRequested: null,
  fetchDefaultPricesSuccess: ['skus'],
  fetchDefaultPricesError: ['error'],
});

export const Reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_DEFAULT_PRICES_REQUESTED]: state => state.merge({ loading: true }),
  [Types.FETCH_DEFAULT_PRICES_SUCCESS]: (state, { skus }) => state.merge({ skus, loading: false }),
  [Types.FETCH_DEFAULT_PRICES_ERROR]: state => state.merge({ loading: false }),
});
