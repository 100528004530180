import BaseApi from './BaseApi';

export const getPendingMessages =
  location => BaseApi.get(`/messages/pending${location ? `?location=${location}` : ''}`);

export const postEvent = ({ tag, type, params }) =>
  BaseApi.post('/events', {
    category: type,
    tag,
    attributes: params,
  });

export const postNPS = ({ nps, comment }) =>
  BaseApi.post('/nps', { nps, comment });

export const postSatisfactionSurvey = ({ answers, comment }) =>
  BaseApi.post('/satisfaction_surveys', {
    answers,
    comment,
  });

export const postSurvey = ({ code, answers }) =>
  BaseApi.post('/surveys', { code, answers });
