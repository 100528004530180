import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fillAbsoluteSpace } from '../../../styles';
import SuperBabyImage from '../../../images/superbaby.png';

const StyledSuperBaby = styled.div`
  ${fillAbsoluteSpace}
  display: flex;
  justify-content: center;
`;

const SBaby = styled.img.attrs({ src: SuperBabyImage })`
  position: absolute;
  bottom: -20vh;
  max-width: 120px;
  filter: drop-shadow(12px 100px 11px rgba(0,0,0,0.3));
  z-index: 99999999;

  animation: flying 2s ease 1;

  @keyframes flying {
    from {bottom: -20vh;}
    to {bottom: 110vh;}
  }
`;

const SuperBaby = ({ show }) => (show ? (
  <StyledSuperBaby>
    <SBaby />
  </StyledSuperBaby>
) : null);

SuperBaby.propTypes = {
  show: PropTypes.bool,
};

SuperBaby.defaultProps = {
  show: false,
};

export default SuperBaby;
