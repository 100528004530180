import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { values, mapObjIndexed } from 'ramda';
import Icon, { iconsList } from './Icon';

/*
 * Este componente lo agregué porque es una flojera buscar un icono,
 * y este componente te mapea y renderea todos los iconos en un grid bonito,
 * con su nombre. Claro está que esto es temporal hasta que encontremos un mejor
 * lugar para hacer algo como esto, pero por mientras, aqui se pueden consultar los
 * iconos disponibles y su nombre.
 */


const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const IconBlock = ({ iconName }) => {
  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    width: 100px;
    height: 60px;
    padding: 10px;
    background-color: #E0E0E0;

    span {
      font-size: 14px;
      margin-top: 10px;
    }
  `;

  return (
    <Wrapper className="icon-block">
      <Icon icon={iconName} size={24} color="gray" />
      <span>{iconName}</span>
    </Wrapper>
  );
};

IconBlock.propTypes = {
  iconName: PropTypes.string,
};

IconBlock.defaultProps = {
  iconName: '',
};

const IconsGrid = () => {
  const AllIcons = values(mapObjIndexed((val, key) => key, iconsList));
  return (
    <Grid>
      {AllIcons.map(name => <IconBlock iconName={name} />)}
    </Grid>
  );
};

export default IconsGrid;
