import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import { breakPoints, colors } from '../../../styles';
import Button from '../../shared/buttons/MainButton';

import BabyHeart from '../../../images/BabyHeart.svg';
import BabyNotes from '../../../images/BabyNotes.png';

const Image = styled.img.attrs({ src: props => props.src })`
  width: 146px;

  ${breakPoints.smallTablet} {
    width: 166px;
  }
`;

const ThankYouViewContainer = styled.div`
  height: 100%;
`;

const ThanksContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  background-image: radial-gradient(50% 119%, #619FD0 46%, #1B75BB 100%);
  overflow: auto;

  ${breakPoints.smallTablet} {
    height: 530px;
  }
`;

const ThanksFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 20px 0 40px;

  ${breakPoints.qLargeTablet} {

  }
`;

const Title1 = styled.h1`
  color: ${colors.white};
  font-weight: bold;
  font-size: 31px;
  letter-spacing: -1.11px;
  line-height: 36px;
  text-align: center;
  margin: 50px 0 60px;

  ${breakPoints.smallTablet} {
    font-size: 32px;
    letter-spacing: -0.78px;

    width: 560px;
    margin: 30px 0;
  }
`;

const Title2 = styled.h1`
  color: ${colors.white};
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.78px;
  text-align: center;

  ${breakPoints.qLargeTablet} {
    font-size: 42px;
    letter-spacing: -1.03px;
    margin: 50px 0 50px;
  }
`;

const Message = styled.p`
  color: ${colors.white};
  font-size: 24px;
  letter-spacing: -1.6px;
  line-height: 32.4px;
  text-align: center;

  max-width: 340px;
  margin: 40px 0 50px;

  ${breakPoints.smallTablet} {
    max-width: 100%;
    font-size: 24px;
    margin: 20px 0;
  }
`;


const thankYouViews = [
  {
    message: '',
    image: BabyNotes,
  },
  {
    message: 'YOUR_FEEDBACK',
    image: BabyNotes,
  },
  {
    message: 'GIVE_US_REVIEW',
    image: BabyHeart,
  },
];

const open = (link, e) => {
  e.stopPropagation();
  window.open(link);
};

const ThankYouView = ({ score, t }) => {
  let selectedSlide = 0;
  if (score) selectedSlide = score > 8 ? 2 : 1;
  return (
    <ThankYouViewContainer>
      <ThanksContent>
        {selectedSlide !== 2 && <Title2>{t('THANK_YOU')}</Title2>}
        {selectedSlide === 2 && (
        <Title1>
          {t('YAY')}
          <br />
          {t('LET_OTHER_KNOW')}
        </Title1>
        )}
        <Image src={thankYouViews[selectedSlide].image} />
        <ThanksFooter>
          <Message>
            {selectedSlide !== 0 && t(`${thankYouViews[selectedSlide].message}`)}
          </Message>
          {selectedSlide === 2
            && (
            <Button color="green" maxWidth={300} onClick={e => open(t('common:URL_FB'), e)}>
              {t('common:GO_TO_FACEBOOK')}
            </Button>
            )
          }
        </ThanksFooter>
      </ThanksContent>
    </ThankYouViewContainer>
  );
};

ThankYouView.propTypes = {
  score: PropTypes.number,
};
ThankYouView.defaultProps = {
  score: 0,
};

export default withTranslation([Namespaces.SURVEYS, Namespaces.COMMON])(ThankYouView);
