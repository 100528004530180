import { css } from 'styled-components';

const Calendar = {
  EventCard: css` `,
  EventTitle: css` `,
  EventHour: css` `,
  EventLocation: css` `,
  EventSeeMore: css` `,
  EventHourLabel: css` `,
};

export default Calendar;
