import { Observable } from 'rxjs';
import { toast } from 'react-toastify';
import { path } from 'ramda';
import i18n from 'i18next';
import { Creators, Types } from '../../ducks/payments';

import * as Api from '../../../api/payments';
import * as Conekta from '../../../lib/conekta';
import { subscriptionPaymentSuccess, giftcardRedeemSuccess } from './orchestrators';
import EventReporter, { Events, EventProviders } from '../../../lib/EventReporter';
import { LOCALSTORAGE_GA_PURCHASE_EVENT_SENT } from '../../../shared-values';

const Optimizely = window.optimizely || {};

const getRedeemError = (code) => {
  switch (code) {
    case 'resource_not_found': return 'fail_invalid';
    case 'forbidden_action': return 'fail_expired';
    default: return 'fail_error';
  }
};

export const validateCard = action$ => action$
  .ofType(Types.VALIDATE_CARD_REQUESTED)
  .map(({ cvc, number, expDate }) => Conekta.validateCard({ cvc, number, expDate }))
  .map(valid => Creators.validateCardSuccess(valid));

export const postPayment = (action$, { getState }) => action$
  .ofType(Types.POST_PAYMENT_REQUESTED)
  .mergeMap(({
    price, productType, card, sku, type, subscriptionType, source, code, ...rest
  }) => Observable.fromPromise(Conekta.tokenizeCard(card)
    .then((token) => {
      if (productType === 'product') {
        const {
          email, familyId, paymentSource, testType,
        } = rest;
        return Api.postPayment({
          sku,
          token,
          email,
          familyId,
          paymentSource,
          testType,
        });
      } if (productType === 'giftcard') {
        const {
          forSelf,
          fromEmail,
          toEmail,
          fromName,
          toName,
          message,
        } = rest;
        return Api.postGiftcardPayment({
          sku,
          token,
          forSelf,
          fromEmail,
          toEmail,
          fromName,
          toName,
          message,
          amount: price,
        });
      }

      throw new Error('No product type provided');
    }))
    .map(() => {
      const { freeTrialDays } = rest;
      EventReporter.action(Events.PURCHASE({ sku, userId: getState().user.id, price }),
        [EventProviders.PIXEL]);
      if (Optimizely.clientInstance) {
        Optimizely.clientInstance.track('purchase', window.optimizely.id);
      }
      const params = {
        payment_source: source,
        type: code,
        subscription_type: subscriptionType,
      };

      EventReporter.action(Events.PP_PAYMENT_SUCCESS(params));

      if (!localStorage.getItem(LOCALSTORAGE_GA_PURCHASE_EVENT_SENT)) {
        EventReporter.action(Events.PP_PAYMENT_SUCCESS(params), [EventProviders.GTM]);
        if (freeTrialDays) {
          EventReporter.action(Events.PP_PAYMENT_FREETRIAL(params),
            [EventProviders.GTM, EventProviders.PIXEL]);
        }
        localStorage.setItem(LOCALSTORAGE_GA_PURCHASE_EVENT_SENT, true);
      }
      EventReporter.action(Events.PP_PAYMENT_PROVIDER({
        payment_source: source,
        type: code,
        subscription_type: subscriptionType,
        response: 'success',
        sku,
        price,
      }), [
        EventProviders.MIXPANEL,
        EventProviders.PIXEL,
      ]);

      toast.success(i18n.t('payments:PAYMENT_SUCCESS'));
      return Creators.postPaymentSuccess(productType);
    })
    .catch((err) => {
      console.error('PAYMENT_ERROR:', err);
      const directError = typeof err === 'string' && err;
      const disectedError = directError || path(['response', 'error_data', 'code'], err)
        || path(['response', 'data', 'error_data', 'code'], err)
        || 'generic';

      EventReporter.action(Events.PP_PAYMENT_FAIL({
        payment_source: source,
        type: code,
        subscription_type: subscriptionType,
      }));

      EventReporter.action(Events.PP_PAYMENT_PROVIDER({
        payment_source: source,
        type: code,
        subscription_type: subscriptionType,
        response: 'fail',
        reason: disectedError,
        sku,
        price,
      }), [
        EventProviders.MIXPANEL,
        EventProviders.PIXEL,
      ]);

      toast.error(i18n.t([`payments:${disectedError}`, 'payments:generic']));
      return Observable.of(Creators.postPaymentError(err));
    }));

export const paymentError = action$ => action$.ofType(Types.POST_PAYMENT_ERROR)
  .do(({ err }) => {
    console.error('paymentError', err);
  })?.ignoreElements();

export const redeemGiftcard = action$ => action$.ofType(Types.REDEEM_GIFTCARD_REQUESTED)
  .mergeMap(({ code, familyId }) => Api.postRedeemGiftcard({ code, familyId })
    .then(() => Creators.redeemGiftcardSuccess())
    .catch(() => {
      toast.error('Invalid Code');
      return Creators.redeemGiftcardError(true);
    }));

export const cancelPremium = (action$, { getState }) => action$.ofType(Types.CANCEL_PREMIUM)
  .map(() => getState().families.activeFamilyId)
  .do(id => Api.cancelPremium(id)
    .then(() => toast.success('Account canceled.'))
    .catch(() => toast.error('There was an error with your request')))
  .ignoreElements();

export const manageSubcriptions = (action$, { getState }) => action$.ofType(Types.MANAGE_SUBSCRIPTIONS)
  .map(() => getState().user.id)
  .do(id => Api.manageSubscriptions(id)
    .then(({ data }) => {
      console.log('data', data);
      window.location.href = data.url;
    })
    .catch((err) => {
      console.log("Erro manage stripe", err);
      toast.error('There was an error with your request');
    }))
  .ignoreElements();

export const redeemPromoCode = action$ => action$.ofType(Types.REDEEM_PROMO_CODE_REQUESTED)
  .mergeMap(({ code }) => Api.postRedeemPromoCode({ code })
    .then(res => res.data.data)
    .then(({ sku }) => {
      EventReporter.action(Events.PROMO_CODE_REDEEM({ result: 'success' }));
      return Creators.redeemPromoCodeSuccess(sku);
    })
    .catch((err) => {
      const backError = path(['response', 'data', 'error_data'], err);
      const result = getRedeemError(backError.code);
      EventReporter.action(Events.PROMO_CODE_REDEEM({ result }));
      return Creators.redeemPromoCodeError(backError.code);
    }));


export const postStripePayment = (action$, { getState }) => action$
  .ofType(Types.POST_STRIPE_PAYMENT_REQUESTED)
  .mergeMap(({
    price, productType, card, sku, type, subscriptionType, source, code, ...rest
  }) => {
    // if (productType === 'product') {
    //   const {
    //     email, familyId, paymentSource, testType,
    //   } = rest;
    //   return Api.postPayment({
    //     sku,
    //     token,
    //     email,
    //     familyId,
    //     paymentSource,
    //     testType,
    //   });
    // }
    if (productType === 'giftcard') {
      const {
        forSelf,
        toEmail,
        toName,
        fromEmail,
        fromName,
        message,
      } = rest;

      return Observable.fromPromise(Api.postStripeGiftcardPayment({
        sku,
        token: card,
        forSelf,
        toEmail,
        toName,
        fromEmail,
        fromName,
        message,
      })).map(() => {
        const { freeTrialDays } = rest;
        EventReporter.action(Events.PURCHASE({ sku, userId: getState().user.id, price }),
          [EventProviders.PIXEL]);
        if (Optimizely.clientInstance) {
          Optimizely.clientInstance.track('purchase', window.optimizely.id);
        }
        const params = {
          payment_source: source,
          type: code,
          subscription_type: subscriptionType,
        };

        EventReporter.action(Events.PP_PAYMENT_SUCCESS(params));

        if (!localStorage.getItem(LOCALSTORAGE_GA_PURCHASE_EVENT_SENT)) {
          EventReporter.action(Events.PP_PAYMENT_SUCCESS(params), [EventProviders.GTM]);
          if (freeTrialDays) {
            EventReporter.action(Events.PP_PAYMENT_FREETRIAL(params),
              [EventProviders.GTM, EventProviders.PIXEL]);
          }
          localStorage.setItem(LOCALSTORAGE_GA_PURCHASE_EVENT_SENT, true);
        }

        EventReporter.action(Events.PP_PAYMENT_PROVIDER({
          payment_source: source,
          type: code,
          subscription_type: subscriptionType,
          response: 'success',
          sku,
          price,
        }), [
          EventProviders.MIXPANEL,
          EventProviders.PIXEL,
        ]);

        toast.success(i18n.t('payments:PAYMENT_SUCCESS'));
        return Creators.postPaymentSuccess(productType);
      }).catch((err) => {
        console.error('PAYMENT_ERROR:', err);
        const directError = typeof err === 'string' && err;
        const disectedError = directError || path(['response', 'error_data', 'code'], err)
          || path(['response', 'data', 'error_data', 'code'], err)
          || 'generic';

        EventReporter.action(Events.PP_PAYMENT_FAIL({
          payment_source: source,
          type: code,
          subscription_type: subscriptionType,
        }));

        EventReporter.action(Events.PP_PAYMENT_PROVIDER({
          payment_source: source,
          type: code,
          subscription_type: subscriptionType,
          response: 'fail',
          reason: disectedError,
          sku,
          price,
        }), [
          EventProviders.MIXPANEL,
          EventProviders.PIXEL,
        ]);

        toast.error(i18n.t([`payments:${disectedError}`, 'payments:generic']));
        return Observable.of(Creators.postPaymentError(err));
      });
    }
    throw new Error('No product type provided');
  });

export const paymentStripeError = action$ => action$.ofType(Types.POST_STRIPE_PAYMENT_ERROR)
  .do(({ err }) => {
    console.info('paymentStripeError::Payment error', err);
  })?.ignoreElements();

export {
  subscriptionPaymentSuccess,
  giftcardRedeemSuccess,
};
