import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../styles';

export const Default = {
  container: css`
    .accordion {
        list-style: none;
        margin: 0;
        padding: 0;
    }
  `,
  panel: css`
    .accordion-panel {
        display: block;
        margin: 0 0 10px;
        padding: 0;
        position: relative;
    }

    .accordion-panel:after {
        clear: both;
        content: '';
        display: table;
    }

    .accordion-panel--between,
    .accordion-panel--last {
        border-top: none;
    }
  `,
  title: css`
    .accordion-title {
        position: relative;
        display: block;

        padding: 1rem 3rem 1rem 1rem;
        border-bottom: 1px solid ${colors.shadeLight};
        background: ${colors.shadeLighter};

        color: ${colors.shadeDark};
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -1px;

        cursor: pointer;
        user-select: none;

        a, a:visited {
            color: ${colors.shadeDark};
        }
    }

    /* fix iOS touch not triggering a click from child elements */
    .accordion-title > * {
        pointer-events: none;
    }

    .accordion-title--first {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .accordion-animator--last,
    .accordion-content--last,
    .accordion-title--last.accordion-title--no-content,
    .accordion-title--last.accordion-title--unchecked {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    .accordion-title--checked {
      background: ${colors.shadeLighter};
    }

    .accordion-title--content .tab-arrow {
      transform: rotate(0deg);
      transition: transform 375ms ease-in-out;
    }

    .accordion-title--checked .tab-arrow {
      transform: rotate(180deg);
    }
  `,
  content: css`
    .accordion-content {
        box-sizing: border-box;
        color: ${colors.shadeDark};
        background: ${colors.shadeLighter};
        display: none;
        float: left;
        padding: 0 1em;
        margin: 0 0 10px;
        width: 100%;
        letter-spacing: -0.8px;

        a, a:visited {
            color: ${colors.shadeDark};
        }
    }

    .accordion-content--animated {
        display: block;
        opacity: 0;
        transform: translateY(-50%);
        -webkit-transition: opacity 375ms ease-in-out, transform 375ms ease-in-out;
        transition: opacity 375ms ease-in-out, transform 375ms ease-in-out;
    }

    .accordion-content--marginTop {
        margin-top: -65535px;
        transform: translateY(-50%);
        -webkit-transition: margin-top 375ms ease-in-out, opacity 375ms ease-in-out, transform 375ms ease-in-out;
        transition: margin-top 375ms ease-in-out, opacity 375ms ease-in-out, transform 375ms ease-in-out;
    }

    .accordion-content--checked {
        display: block;
        opacity: 1;
    }

    .accordion-content--checked.accordion-content--animated {
        transform: translateY(0);
    }

    .accordion-content--checked.accordion-content--marginTop {
        margin-top: 0;
    }
  `,
  animator: css`
    .accordion-animator {
        background: ${colors.shadeLighter};
        -webkit-transition: background-color 125ms ease-in-out, height 375ms ease-in-out;
        transition: background-color 125ms ease-in-out, height 375ms ease-in-out;
    }

    .accordion-animator:after {
        clear: both;
        content: '';
        display: block;
    }

    .accordion-animator--checked {
        background: ${colors.shadeLighter};
      padding: 20px;
    }

    .accordion-animator--unchecked {
      background-color: #FAFAFA;
      height: 0;
      padding: 0;
    }
  `,
};

export const Flat = {
  container: css`
      .accordion {
          list-style: none;
          margin: 0;
          padding: 0;
      }
    `,
  panel: css`
      .accordion-panel {
          display: block;
          margin: 0px;
          padding: 0;
          position: relative;
      }

      .accordion-panel:after {
          clear: both;
          content: '';
          display: table;
      }

      .accordion-panel--between,
      .accordion-panel--last {
          border-top: none;
      }
    `,
  title: css`
      .accordion-title {
          position: relative;
          display: block;

          padding: 1rem 3rem 1rem 1rem;
          border-bottom: 1px solid ${colors.shadeLight};
          background: ${colors.shadeLighter};

          color: ${colors.kinedu};
          font-size: 18px;
          font-weight: 500;
          letter-spacing: -1px;

          cursor: pointer;
          user-select: none;

          a, a:visited {
              color: ${colors.shadeDarker};
          }
      }

      /* fix iOS touch not triggering a click from child elements */
      .accordion-title > * {
          pointer-events: none;
      }

      .accordion-title--first {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
      }

      .accordion-animator--last,
      .accordion-content--last,
      .accordion-title--last.accordion-title--no-content,
      .accordion-title--last.accordion-title--unchecked {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }

      .accordion-title--checked {
        background: ${colors.shadeLighter};
      }

      .accordion-title--content .tab-arrow {
        transform: rotate(0deg);
        transition: transform 375ms ease-in-out;
      }

      .accordion-title--checked .tab-arrow {
        transform: rotate(180deg);
      }
    `,
  content: css`
      .accordion-content {
          box-sizing: border-box;
          color: ${colors.shadeDark};
          background: ${colors.shadeLighter};
          display: none;
          float: left;
          padding: 0 1em;
          margin: 0 0 10px;
          width: 100%;
          letter-spacing: -0.8px;
          text-decoration: none;

          a, a:visited {
              color: ${colors.kinedu};
          }
      }

      .accordion-content--animated {
          display: block;
          opacity: 0;
          transform: translateY(-50%);
          -webkit-transition: opacity 375ms ease-in-out, transform 375ms ease-in-out;
          transition: opacity 375ms ease-in-out, transform 375ms ease-in-out;
      }

      .accordion-content--marginTop {
          margin-top: -65535px;
          transform: translateY(-50%);
          -webkit-transition: margin-top 375ms ease-in-out, opacity 375ms ease-in-out, transform 375ms ease-in-out;
          transition: margin-top 375ms ease-in-out, opacity 375ms ease-in-out, transform 375ms ease-in-out;
      }

      .accordion-content--checked {
          display: block;
          opacity: 1;
      }

      .accordion-content--checked.accordion-content--animated {
          transform: translateY(0);
      }

      .accordion-content--checked.accordion-content--marginTop {
          margin-top: 0;
      }
    `,
  animator: css`
      .accordion-animator {
          background: ${colors.shadeLighter};
          -webkit-transition: background-color 125ms ease-in-out, height 375ms ease-in-out;
          transition: background-color 125ms ease-in-out, height 375ms ease-in-out;
      }

      .accordion-animator:after {
          clear: both;
          content: '';
          display: block;
      }

      .accordion-animator--checked {
          background: ${colors.shadeLighter};
        padding: 10px;
      }

      .accordion-animator--unchecked {
        background-color: #FAFAFA;
        height: 0;
        padding: 0;
      }
    `,
};

export const ThemeShape = PropTypes.shape({
  container: PropTypes.array,
});
