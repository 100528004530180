import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Well from '../../shared/Well';
import { colors, breakPoints } from '../../../styles';
import { Namespaces } from '../../../values/i18n';
import Title from '../../shared/Title';
import { genderPronoun } from '../../../lib/utils';

const StyledWell = styled(Well)`
  display: flex;
  flex-direction: row;
  align-content: stretch;
  margin: 20px 0 10px;
  padding: 0;
  width: 100%;
  box-sizing: border-box;

  ${breakPoints.smallTablet} {
    display: block;
    padding: 10px;
    margin: 0 0 20px 0;
    max-width: 380px;
  }
`;

const PercentileWrapper = styled.div`
  width: 100%;
  margin-bottom: 0;
  border-right: 1px solid ${colors.shadeLight};
  flex-basis: 100px;
  flex-shrink: 0;

  ${breakPoints.smallTablet} {
    border-right: 0px solid;
    margin-bottom: 20px;
  }
`;

const Percentile = styled.div`
  align-items: center;
  font-size: 40px;
  color: ${colors.shadeDark};
  font-weight: bold;
  letter-spacing: -0.5px;
  text-align: center;
  line-height: 30px;
  padding: 10px 10px 0;

  sup {
    position: relative;
    top: -14px;
    left: 0px;
    font-size: 14px;
  }

  ${breakPoints.smallTablet} {
      font-size: 60px;
      line-height: 1;
  }
`;

const PercentileTitle = styled.span`
    color: ${colors.shadeDark};
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin: 0 10px 10px;
    padding-right: 10px;

    ${breakPoints.smallTablet} {
        font-size: 16px;
        margin: 0;
    }
`;

const Description = styled.div`
    display: flex;
    justify-content: center;
    align-items: left;
    font-size: 14px;
    align-items: center;

    color: ${colors.shadeMediumDark};
    font-size: 14px;
    letter-spacing: -0.5px;
    text-align: left;
    padding: 0;
    margin-left: 10px;

    ${breakPoints.smallTablet} {
        text-align: center;
        padding: 0;
        margin: auto;
        max-width: 267px;
    }
`;

const getView = ({
  percentile, baby, progressStatus, completedMilestones, t,
}) => {
  if (progressStatus === 'future' && !completedMilestones) {
    return (
      <Title
        color={colors.kinedu}
        size={16}
        spacing="-0.57px"
        style={{ margin: '30px' }}
        bold
      >
        {t('GRAPH_WELL.FUTURE_EMPTY_STATE', { babyName: baby.name })}
      </Title>
    );
  }

  return (
    <Fragment>
      <PercentileWrapper>
        <Percentile>
          <span>
            {percentile}
          </span>
          <sup>
            {t('GRAPH_WELL.SUP', { count: percentile })}
          </sup>
        </Percentile>
        <PercentileTitle>
          {t('GRAPH_WELL.PERCENTILE_TITLE')}
        </PercentileTitle>
      </PercentileWrapper>
      <Description>
        <span>
          {t('GRAPH_WELL.PERCENTILE_DESC', {
            babyName: baby.name,
            pronoun: genderPronoun(baby.gender),
            percentile,
          })}
        </span>
      </Description>
    </Fragment>
  );
};

const PercentileWell = ({
  baby, t, percentile, completedMilestones, progressStatus, style,
}) => (
  <StyledWell style={style}>
    {getView({
      baby, t, percentile, completedMilestones, progressStatus, style,
    })}
  </StyledWell>
);

PercentileWell.propTypes = {
  style: PropTypes.object,
  baby: PropTypes.object,
  percentile: PropTypes.number,
  t: PropTypes.func,
};

PercentileWell.defaultProps = {
  style: {},
  baby: {},
  percentile: 0,
  t: () => {},
};

export default withTranslation([Namespaces.PROGRESS])(PercentileWell);
