import React from 'react';
import styled, { css } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import Icon from '../shared/Icon';
import { colors } from '../../styles';

const Container = styled.div`
  ${({ active }) => active && css`
    background-color: #F0F0F0;
  `}
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 85px;
  height: 150px;
  cursor: pointer;

  ${({ theme }) => theme.mobile && css`
    flex-direction: row;
    width: 100%;
    height: auto;
  `};
`;

const Picture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.kinedu};
  border: 2px dashed ${colors.kineduShade};
  background-size: cover;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  margin: 10px auto;

  ${({ theme }) => theme.mobile && css`
    margin: 8px;
  `};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mobile && css`
    flex-grow: 1;
    align-items: flex-start;
  `};
`;

const Text = styled.span`
  font-size: 14px;
  color: ${colors.kinedu};
  font-weight: bold;
  letter-spacing: -0.68px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 600;

  ${({ theme }) => theme.mobile && css`
    margin: 0;
    font-size: 16px;
  `};
`;

const PlusIcon = styled(Icon).attrs({
  icon: 'plus',
  size: 18,
  color: 'white',
})``;

const AddBaby = ({ onClick, t }) => (
  <Container onClick={onClick}>
    <Picture><PlusIcon /></Picture>
    <Content>
      <Text>{t('ADD_BABY')}</Text>
    </Content>
  </Container>
);

export default withTranslation([Namespaces.MENU])(AddBaby);
