import Immutable from 'seamless-immutable';
import { find, findIndex, path, prop, filter, isEmpty } from 'ramda';
import { createReducer, createActions } from 'reduxsauce';
import * as Babies from './babies';
import * as Families from './families';
import { memberRoles } from '../../../values/families';

const INITIAL_STATE = Immutable({
  activeBabyId: undefined,
  activeFamilyId: undefined,
  families: [],
  memberships: {},
  classroom: {},
  ...Families.INITIAL_STATE,
});

const sharedActionsAndTypes = createActions({
  selectFamilyAndDefaultBaby: ['familyId'],
  checkEmptyFamily: null,
  checkNoFamily: null,
  checkPendingAssessment: null,
  checkNoDefaultFamily: null,
});

export const Reducer = createReducer(INITIAL_STATE, {
  ...Babies.Handlers,
  ...Families.Handlers,
});

export const Creators = {
  ...Babies.Creators,
  ...Families.Creators,
  ...sharedActionsAndTypes.Creators,
};

export const Types = { ...Babies.Types, ...Families.Types, ...sharedActionsAndTypes.Types };

const familyById = (state, id) => {
  const family = find(x => x.id === id, path(['families', 'families'], state));
  if (family) {
    return { ...family, memberships: state.families.memberships[id] };
  }
  return {};
};

const getDefaultFamily = (state) => {
  const id = state.families.activeFamilyId;
  const family = find(fam => path(['membership', 'default_account'], fam), path(['families', 'families'], state));
  if (family) {
    return { ...family, memberships: state.families.memberships[id] };
  }
  return {};
};

const getClassroomStatus = (state) => {
  const allValues = Object.entries(state).length === 4;
  const values = Object.keys(state);

  return allValues && values.find(element => element === 'classrooms_baby') && values.find(element => element === 'chat_data') && values.find(element => element === 'calendar_api');
};

const sortMembers = (members) => {
  const acceptedMembers = [...members].filter(m => m.status !== 'pending');
  const pendingMembers = [...members].filter(m => m.status === 'pending');
  const sortedMembers = [...acceptedMembers, ...pendingMembers];
  return sortedMembers;
};

const getExpectedBabies = (state) => {
  const pregnancies = prop('pregnancies', familyById(state, state.families.activeFamilyId)) || [];
  const expectedBabies = [].concat(...pregnancies.map(p => p.expected_babies));
  return expectedBabies;
};

const getFamilies = state => filter(f => !isEmpty(f.babies), path(['families', 'families'], state));
const activeFamily = state => familyById(state, state.families.activeFamilyId);
const babiesByFamilyId = (state, id) => prop('babies', familyById(state, id));
const prenatalBabiesByFamilyId = state => getExpectedBabies(state);
const babyById = (state, id) => path([id], babiesByFamilyId(state, state.families.activeFamilyId));
const activeBaby = state => babyById(state, state.families.activeBabyId);
const ownFamily = state => find(family => family.self_owner, path(['families', 'families'], state)) || {};
const userOwnsFamily = (state, id) => prop('self_owner', familyById(state, id));
const activeFamilyIsPremium = state => activeFamily(state).is_premium;
const defaultFamily = state => getDefaultFamily(state);
const membersByFamilyId = (state, id) => (state.families.memberships[id] ? sortMembers(state.families.memberships[id]) : []);
const memberById = (state, id) => find(x => x.id === id, membersByFamilyId(state, state.families.activeFamilyId));
const ownMembership = state => path(['membership'], ownFamily(state));
const activeFamilyMembership = state => activeFamily(state).membership || {};
const activeFamilyIsDefault = state => path(['membership', 'default_account'], activeFamily(state));
const iOwnFamily = state => find((family => family.self_owner), state.families.families);
const myRole = state => memberRoles[activeFamilyMembership(state).role];
const getBabyGenderById = (state, id) => prop('gender', babyById(state, id) || {});
const getActiveFamilyIndex = state => findIndex(f => f.id === state.families.activeFamilyId, state.families.families);
const classroomStatus = state => getClassroomStatus(state.families.classroom);

export const Selectors = {
  getFamilies,
  familyById,
  activeFamily,
  babiesByFamilyId,
  prenatalBabiesByFamilyId,
  babyById,
  activeBaby,
  ownFamily,
  activeFamilyIsPremium,
  userOwnsFamily,
  defaultFamily,
  membersByFamilyId,
  memberById,
  ownMembership,
  activeFamilyMembership,
  activeFamilyIsDefault,
  getActiveFamilyIndex,
  iOwnFamily,
  myRole,
  getBabyGenderById,
  classroomStatus
};
