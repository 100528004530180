import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as catalog } from './catalog.svg';
import { ReactComponent as dap } from './dap.svg';
import { ReactComponent as edit } from './edit.svg';
import { ReactComponent as progress } from './progress.svg';
import { ReactComponent as remove } from './remove.svg';
import { ReactComponent as slide } from './slide.svg';
import { ReactComponent as close } from './close.svg';
import { ReactComponent as PaperClip } from './paperclip.svg';
import { ReactComponent as MaterialsIcon } from './materials.svg';
import { ReactComponent as EmptyPhotoIcon } from './emptyPhoto.svg';
import { ReactComponent as OrganizationIcon } from './organization.svg';
import { ReactComponent as HamburgerMenu } from './hamburgerMenu.svg';
import { ReactComponent as Gear } from './gear.svg';
import { ReactComponent as Milestones } from './milestones.svg';
import { ReactComponent as BackArrow } from './backArrow.svg';
import { ReactComponent as MailboxIcon } from './mailbox.svg';
import { ReactComponent as EditPencil } from './edit-icon-pencil.svg';
import { ReactComponent as clock } from './clock.svg';
import { ReactComponent as calendarEvent } from './calendarEvent.svg';
import { ReactComponent as Messages } from './message.svg';
import { ReactComponent as back } from './back.svg';
import { ReactComponent as arrowBackIos } from './arrowBackIos.svg';
import { ReactComponent as emptyForums } from './emptyForums.svg';
import { ReactComponent as joinForumIcon } from './joinForumIcon.svg'; 

export default {
  close,
  calendar,
  catalog,
  dap,
  edit,
  EditPencil,
  progress,
  remove,
  slide,
  PaperClip,
  Gear,
  MailboxIcon,
  BackArrow,
  Milestones,
  MaterialsIcon,
  HamburgerMenu,
  EmptyPhotoIcon,
  OrganizationIcon,
  clock,
  back,
  calendarEvent,
  Messages,
  arrowBackIos,
  emptyForums,
  joinForumIcon,
};
