import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { colors, breakPoints } from '../../styles';
import Sprout from '../../images/BadgeSprout.png';
import Trophy from '../../images/BadgeTrophy.png';
import { SkillsImages, SkillsImagesGS } from '../../values/progress';
import { fadeUp } from '../../styles/transitions';
import { genderPronoun, getSkillType, fixAreaName } from '../../lib/utils';
import EventReporter, { Events } from '../../lib/EventReporter';
import { AreaNameById } from '../../values/areas';

const cardColorByArea = {
  0: colors.shadeMediumLight,
  1: colors.physicalLight70,
  2: colors.cognitiveLight70,
  3: colors.linguisticLight70,
  4: colors.socialEmotionalLight70,
  5: colors.healthLight70,
};

const AVERAGE_PERCENTIL = 0.10;

const StyledProgressSkillCard = styled.div`
  width: 157px;
  height: 250px;
  display: flex;
  align-items: flex-end;
  margin: 5px;
  opacity: 0;

  ${({ show }) => fadeUp(show, 0.2)}

  ${({ enableTab, isPending, isBlurred }) => (((enableTab || isPending) && !isBlurred)) && css`
    cursor: pointer;
  `}

  ${breakPoints.smallTablet} {
    width: 220px;
    margin: 10px;
  }
`;

const Base = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 217px;
  background-color: ${colors.shadeLight};
  border-radius: 4px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 116px;
`;

const Image = styled.div`
  position: absolute;
  top: -33px;
  width: 86%;
  height: 103px;
  border-radius: 4px;
  background-color: ${({ areaId }) => cardColorByArea[areaId]};
  box-shadow: 0px 20px 30px -20px ${colors.shadeMediumDark};
  transition: all 0.2s ease-in-out;
  ${({ src }) => `background-image: url(${src});`}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  filter: ${({ filterBnW }) => filterBnW && 'grayscale(100%)'};

  ${({ enableTab }) => (enableTab) && css`
    ${StyledProgressSkillCard}:hover & {
      top: 0px;
      width: 100%;
      height: 116px;
      border-radius: 0px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      box-shadow: 0px 0px 0px 0px ${colors.shadeDark};
    }
  `}

    ${breakPoints.smallTablet} {
      width: 91%;
    }
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 101px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${colors.shadeLighter};
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  color: ${({ skillType }) => (skillType === 'pending' ? colors.health : colors.shadeMediumDark)};
  font-size: 14px;
  letter-spacing: -0.52px;
  text-align: center;

  &:nth-child(2) {
    border-bottom: 1px solid ${colors.shadeLight};
  }
`;

const Title = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 46px;
  padding: 0 10px;

  color: ${colors.shadeDark};
  font-size: 13px;
  line-height: 14px;
  font-weight: bold;
  letter-spacing: -0.54px;
  text-align: center;
`;

const Overlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 100%;
  height: 100%;
  background-color: ${colors.shadeMediumDark};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;
  box-sizing: border-box;

  color: ${colors.white};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 21px;

  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  ${({ isFutureSkill, enableTab }) => (!isFutureSkill && enableTab) && css`
    ${StyledProgressSkillCard}:hover & {
      opacity: 1;
    }
  `}

  ${({ mastered }) => mastered && css`
    opacity: 1;
    color: ${colors.white};
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    line-height: 18px;
    background-color: ${colors.physical};
    text-transform: uppercase;
    padding: 20px;
    box-sizing: border-box;
  `}
`;

const Badge = styled.div`
  position: absolute;
  top: 0;
  right: -20px;
  width: 40px;
  height: 40px;
  background-color: ${colors.shadeLight};
  border: 1px solid ${colors.white};
  border-radius: 100px;
  ${({ skillType }) => {
    switch (skillType) {
      case 'pending': return css`
          top: -5px;
          right: -5px;
          width: 10px;
          height: 10px;
          background-color: ${colors.health};
        `;
      case 'emerging': return css`
          background-image: url(${Sprout});
          background-repeat: no-repeat;
          background-position: bottom;
          background-size: 20px;
        `;
      case 'master': return css`
          background-image: url(${Trophy});
          background-repeat: no-repeat;
          background-position: 9px 6px;
          background-size: 25px;
          background-color: ${colors.trophyYellow};
        `;
      default: return css`display: none;`;
    }
  }}
  `;

class ProgressSkillCard extends Component {
  state = { val: null };

  handleClick = (isBlurred, areaId, skillId, isFutureSkill, isPending) => {
    const { baby, push, gotToPendingSkills } = this.props;
    const age = baby.age_in_months;
    const area = AreaNameById[areaId].toLowerCase();
    if (isBlurred) return;
    if (isFutureSkill) {
      push({
        path: '/progress',
        search: `?${new URLSearchParams({
          skill_id: skillId,
          section: 'skill_detail',
        }).toString()}`,
      });
    } else if (isPending) {
      gotToPendingSkills(areaId, skillId);
    } else {
      push({
        path: '/progress',
        search: `?${new URLSearchParams({
          skill_id: skillId,
          section: 'skill_detail',
        }).toString()}`,
      });

      EventReporter.view(Events.SKILLS_DETAIL({
        area: fixAreaName(area),
        skill: skillId,
        baby_age: age,
      }));
    }
  }

  showStatus = (percentil, baby, t) => (percentil > AVERAGE_PERCENTIL ? t('ON_TRACK', { article: genderPronoun(baby.gender) }) : t('KEEP_WORKING'));

  showOverlayMessage = skillType => (skillType === 'master' ? 'MASTERED_SKILL' : 'TAP_HERE');

  render() {
    const {
      skill, show, baby, style, isBlurred, t,
    } = this.props;
    const { area_id: areaId, title } = skill;
    const skillType = getSkillType(skill);
    const isFutureSkill = skill?.progress_status === 'future';
    const isPending = skillType === 'pending';
    const localImage = (isFutureSkill ? SkillsImagesGS[skill.id] : SkillsImages[skill.id]);
    const timeOfImages = localImage || skill.logo_web;
    const timeOfArea = isFutureSkill ? 0 : areaId;
    const enableTab = true;
    return (
      <StyledProgressSkillCard
        isFutureSkill={isFutureSkill}
        isPending={isPending}
        enableTab={enableTab}
        isBlurred={isBlurred}
        onClick={() => this.handleClick(isBlurred, areaId, skill.id, isFutureSkill, isPending)}
        show={show}
        style={style}
      >
        <Base>
          <Header>
            <Image
              areaId={timeOfArea}
              src={timeOfImages}
              isFutureSkill={isFutureSkill}
              enableTab={enableTab}
              filterBnW={!!localImage && isFutureSkill}
            >
              {!isFutureSkill && <Badge skillType={skillType} />}
            </Image>
            <Title>{title}</Title>
          </Header>
          <Footer>
            <Overlay mastered={skillType === 'master'} isFutureSkill={isFutureSkill} enableTab={enableTab}>
              {t(this.showOverlayMessage(skillType))}
            </Overlay>
            <InfoRow>
              {t('MILESTONES_COMPLETED', {
                completed: skill.completed_milestones || 0,
                total: skill.active_milestones,
              })}
            </InfoRow>
            <InfoRow skillType={skillType}>
              {!isFutureSkill && (isPending ? t('PENDING') : this.showStatus(skill.percentile, baby, t))}
            </InfoRow>
          </Footer>
        </Base>
      </StyledProgressSkillCard>
    );
  }
}

ProgressSkillCard.propTypes = {
  isBlurred: PropTypes.bool,
  style: PropTypes.object,
  skill: PropTypes.object,
  show: PropTypes.bool,
  baby: PropTypes.object,
  push: PropTypes.func,
  gotToPendingSkills: PropTypes.func,
  t: PropTypes.func,
};

ProgressSkillCard.defaultProps = {
  isBlurred: false,
  style: {},
  skill: {},
  show: false,
  baby: {},
  push: () => {},
  gotToPendingSkills: () => { },
  t: () => { },
};

export default withTranslation([Namespaces.PROGRESS])(ProgressSkillCard);
