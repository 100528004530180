import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Sprout from '../../../images/BadgeSprout.png';
import Trophy from '../../../images/BadgeTrophy.png';
import { colors, breakPoints } from '../../../styles';
import { Namespaces } from '../../../values/i18n';

const StyledBadgeInfoBanner = styled.div`
    width: 100%;
    height: 72px;

    display: flex;
    align-items: center;
    margin: 0px auto 10px;

    background-color: ${colors.shadeLighter};
    border-radius: 4px;
    max-width: 620px;

    ${breakPoints.smallTablet}{
      height: 92px;
      margin: 0 auto 20px;
    }
`;

const InfoContainer = styled.div`
    width: 100%;
    margin: 0 10px;
`;

const BadgeContainer = styled.div`
   width: 25%;
   height: 100%;
   border-right: 1px solid ${colors.shadeLight};
   display: flex;
`;

const Title = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25px;

  color: ${colors.shadeDark};
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-align: center;
  ${breakPoints.smallTablet} {
      font-size: 16px;
  }
`;

const Info = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px;

  color: ${colors.shadeMediumDark};
  font-size: 14px;
  letter-spacing: -0.5px;
  text-align: center;
`;

const Badge = styled.div`
  width: 50px;
  height: 50px;
  margin: auto;
  border: 1px solid ${colors.white};
  border-radius: 100px;
  background-position: center 100%;
  background-repeat: no-repeat;
  background-size: 28px;
  background-color: ${colors.shadeLight};
  background-image: url(${Trophy});
  ${breakPoints.smallTablet} {
   width: 70px;
   height: 70px;
  }

  ${({ type }) => {
    switch (type) {
      case 'master': return css`
         background-image: url(${Trophy});
         background-position: 55% 50%;
         background-size: 40px;
         background-color: ${colors.trophyYellow};
        `;
      case 'emerging': return css`
         background-image: url(${Sprout});
        `;
      default: return css`display: none;`;
    }
  }};
`;

const BadgeInfoBanner = ({ baby, type, t }) => ((type !== '' && type !== 'pending') ? (
  <StyledBadgeInfoBanner>
    <BadgeContainer>
      <Badge type={type} />
    </BadgeContainer>
    {(type === 'master')
      ? (
        <InfoContainer>
          <Title>
            {t('BADGE.MASTER_SKILL_TITLE', {
              babyName: baby.name,
            })}

          </Title>
          <Info>
            {t('BADGE.MASTER_SKILL_SUBTITLE', {
              babyName: baby.name,
            })}

          </Info>
        </InfoContainer>
      )
      : (
        <InfoContainer>
          <Title>
            {t('BADGE.EMERGING_SKILL_TITLE', {
              babyName: baby.name,
            })}

          </Title>
          <Info>{t('BADGE.EMERGING_SKILL_SUBTITLE')}</Info>
        </InfoContainer>
      )}
  </StyledBadgeInfoBanner>
) : null);

BadgeInfoBanner.propTypes = {
  t: PropTypes.func,
  baby: PropTypes.object,
  type: PropTypes.string,
};

BadgeInfoBanner.defaultProps = {
  t: () => {},
  baby: { },
  type: '',
};

export default withTranslation([Namespaces.PROGRESS])(BadgeInfoBanner);
