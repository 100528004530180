import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Creators } from '../../data/ducks/user';
import { Creators as ModalCreators } from '../../data/ducks/modals';
import { Selectors } from '../../data/ducks/babiesAndFamilies';

import EventReporter, { Events } from '../../lib/EventReporter';
import EditUserView from '../../components/views/EditUser';
import Modal from '../../components/modals/NeoModal';

class EditUser extends React.Component {
  componentDidMount() {
    EventReporter.view(Events.MENU_MY_PROFILE());
  }

  saveChanges = ({
    name, lastname, month, day, year, avatar,
  }) => {
    const birthday = new Date(year, month, day);
    this.props.editUser({
      id: this.props.user.id, name, lastname, birthday, avatar,
    });
    this.props.closeModal({ name: 'EditUser' });
  }

  toggleSubmit = val => this.setState({ ...this.state, submitEnabled: val })

  render() {
    const { user, activeFamily, t } = this.props;

    return (
      <Modal
        title={t('MY_PROFILE')}
        roundedCorners
      >
        {({ closeModal }) => (
          <EditUserView
            user={user}
            activeFamily={activeFamily}
            onSubmit={this.saveChanges}
            closeModal={closeModal}
          />
        )}
      </Modal>
    );
  }
}

EditUser.propTypes = {
  editUser: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  activeFamily: PropTypes.object,
  t: PropTypes.func,
};

EditUser.defaultProps = {
  editUser: () => { },
  user: {},
  activeFamily: {},
  t: () => { },
};

const mapStateToProps = state => ({
  user: state.user,
  activeFamily: Selectors.activeFamily(state),
});

const mapDispatchToProps = dispatch => ({
  editUser: bindActionCreators(Creators.editUser, dispatch),
  closeModal: bindActionCreators(ModalCreators.closeModal, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation([Namespaces.MENU, Namespaces.COMMON, Namespaces.VALIDATIONS]),
)(EditUser);
