// eslint-disable-next-line
export const Namespaces = {
  ASSESSMENTS: 'assessments',
  CATALOG: 'catalog',
  COMMON: 'common',
  CONTENT: 'content',
  CLASSROOM_INVITE: 'classroom_invite',
  DAP: 'dap',
  FAMILIES: 'families',
  FAQ: 'faq',
  FEED: 'feed',
  GIFTS: 'gifts',
  LANDING: 'landing',
  MENU: 'menu',
  MILESTONES: 'milestones',
  MODALS: 'modals',
  OB: 'ob',
  PAYMENTS: 'payments',
  PRICINGS: 'pricings',
  PROGRESS: 'progress',
  SURVEYS: 'surveys',
  SHARING: 'sharing',
  VALIDATIONS: 'validations',
  CALENDAR_EVENTS: 'calendar_events',
  CHAT: 'chat',
  MAINTENANCE: 'maintenance',
  TODAY: 'today',
  SMART_BANNER: 'smart_banner',
  PAYWALL: 'paywall',
  PP: 'pp',
};

export const SectionNamespaces = Object.values(Namespaces);
