import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import styled from 'styled-components';

const StyledLangButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  margin: 0 5px;
  width: 36px;
  height: 36px;
  border: 1px solid #0086D8;
  border-radius: 100px;
  color: #0086D8;
  background-color: #FFF;

  ${({ active }) => active && `
    color: #FFF;
    background-color: #0086D8;
  `}
`;

const LangButton = ({ lang = 'en', i18n, onLangClick }) => (
  <StyledLangButton onClick={onLangClick} active={lang === i18n.language}>
    <span>{lang}</span>
  </StyledLangButton>
);

LangButton.propTypes = {
  lang: PropTypes.string,
  i18n: PropTypes.object,
  onLangClick: PropTypes.func,
};

export default compose(
  withTranslation([]),
)(LangButton);
