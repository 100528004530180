import { Reduxsauce } from '../redux-sauce';

export const Types = Reduxsauce.createTypes(`
  checkAuthStatus
  setAuthStatus

  loginRequested
  loginSuccess
  loginError

  logout

  setThirdPartyUserData

  createSessionRequested
  createSessionSuccess
  createSessionError

  signupRequested
  signupSuccess
  signupError

  sendRestoreEmailRequested
  sendRestoreEmailSuccess
  sendRestoreEmailError

  restorePasswordRequested
  restorePasswordSuccess
  restorePasswordError
`);

export const Creators = {
  /**
   * @param provider - [FB, email]
   * @param email?
   * @param password?
   */
  login: ({ provider, email, password, token, userId }) => ({
    type: Types.loginRequested, provider, email, password, token, userId,
  }),
  logout: () => ({ type: Types.logout }),
  checkAuthStatus: () => ({ type: Types.checkAuthStatus }),
  createSession: () => ({ type: Types.createSessionRequested }),
  signup: ({ email, password, cohort, lang }) => ({
    type: Types.signupRequested, email, password, cohort, lang,
  }),
  sendRestoreEmail: email => ({ type: Types.sendRestoreEmailRequested, email }),
  restorePassword: ({ password, token }) => ({ type: Types.restorePasswordRequested, password, token }),
};

export const InitialState = Reduxsauce.createState({
  authenticated: null,
});

export const Reducer = Reduxsauce.createReducer(InitialState, {
  // CHECK_AUTH_STATUS
  [Types.checkAuthStatus]: state => state.merge({ error: '' }),
  [Types.setAuthStatus]: (state, { authenticated, userId }) => state.merge({ authenticated, userId }),
  // LOGIN
  [Types.loginRequested]: state => state.merge({ loading: true }),
  [Types.loginSuccess]: (state, { userId }) => state.merge({
    loading: false, error: false, userId, authenticated: true,
  }),
  [Types.loginError]: (state, action) => state.merge({ loading: false, error: action.error }),
  // CREATE_SESSION TODO: ver que mutar aqui.
  [Types.createSessionRequested]: state => state,
  [Types.createSessionSuccess]: state => state,
  [Types.createSessionError]: state => state,
  // LOGOUT
  [Types.logout]: state => state.merge({ authenticated: false, user: {} }),
  [Types.signupSuccess]: (state, { userId }) => state.merge({
    loading: false, error: false, userId, authenticated: true,
  }),
  [Types.signupError]: (state, { error }) => state.merge({ loading: false, error }),
});
