/* eslint-disable camelcase */
import {
  contains,
  eqBy,
  filter,
  forEach,
  fromPairs,
  isNil,
  join,
  lensPath,
  map,
  pipe,
  reduce,
  split,
  toPairs,
  trim,
  view,
  path as pathRamda,
} from 'ramda';
import moment from 'moment';
import axios from 'axios';
import { UserRoles } from '../values/roles';
import * as Config from '../config';
// https://en.wikipedia.org/wiki/Jackson_Pollock

/**
 *
 * @param {Object} a
 * @param {Object} b
 * @param {Array} path
 */
export const equalAt = (a, b, path) => eqBy(view(lensPath(path)), a, b);

export const buildFormDataFromObject = (params) => {
  const formData = new FormData();
  const paramArray = toPairs(params);

  forEach(([key, val]) => {
    if (!isNil(val)) formData.append(key, val);
  }, paramArray);

  return formData;
};

export const trimNilValues = pipe(
  toPairs,
  filter(([, value]) => !isNil(value)),
  fromPairs,
);

export const toUpperLowerCase = (s) => {
  if (isNil(s)) {
    return null;
  }

  return pipe(
    split(' '),
    filter(x => x.length > 0),
    map(w => w[0].toUpperCase() + w.slice(1)),
    join(' '),
    trim,
  )(s);
};

/*
    Get the number of days base on the given month
    m = month (0-11)
    y = year
  */
export const daysInMonth = (m, y) => {
  switch (m) {
    case 1:
      return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
    case 3: case 5: case 8: case 10:
      return 30;
    default:
      return 31;
  }
};

/** To key - undefined pair object */
export const tU = (...rest) => rest.reduce((obj, v) => ({ [v]: undefined, ...obj }), {});

export const formatPendingSkills = (skills, idName = 'skill_id') => skills.filter(s => s.completed_milestones === null).map(s => s[idName]);

export const formatSkills = (skills, idName = 'skill_id') => skills.map(s => s[idName]);

/**
 * maps [{ milestone_id: answer }] -> { milestone_id: answer }
 *
 */
export const formatAnswers = answers => pipe(toPairs, map(([milestone_id, answer]) => ({ milestone_id, answer })))(answers);

/**
 * maps [{ milestone_id: [ babyIds ]}] -> { babyId: { milestone_id: answer }}
 */
export const formatTwinAnswers = (answers, twins) => {
  const [a, b] = twins.map(x => x.id);
  return pipe(
    toPairs,
    reduce((acc, [milestone_id, babyIds]) => {
      acc[a].push({ milestone_id, answer: contains(a, babyIds) });
      acc[b].push({ milestone_id, answer: contains(b, babyIds) });
      return acc;
    }, { [a]: [], [b]: [] }),
  )(answers);
};

export const truthyAnswer = (answer) => {
  if (answer === 'yes' || answer === true) {
    return true;
  } if (answer === 'no') {
    return false;
  }
  return false;
};

export const formatTruthyAnswers = answers => answers
  .map(({ milestone_id, answer }) => ({ milestone_id, answer: truthyAnswer(answer) }));

export const asyncTypeAndActionCreators = (prefix, actionParams) => ({
  [`fetch${toUpperLowerCase(prefix)}Requested`]: actionParams.requested,
  [`fetch${toUpperLowerCase(prefix)}Success`]: actionParams.success,
  [`fetch${toUpperLowerCase(prefix)}Error`]: actionParams.error,
});

/** converts  { key: value... } to { keyName: key, valueName: val } */
export const reshapeLiteral = (obj, keyName, valName) => toPairs(obj)
  .map(([key, value]) => ({ [keyName]: key, [valName]: value }));

export const genderPronoun = gender => (gender === 'male' ? 'his' : 'her');

export const genderAccusativePronoun = gender => (gender === 'male' ? 'him' : 'her');

export const genderNominativePronoun = gender => (gender === 'male' ? 'HE' : 'SHE');

export const genderChild = gender => (gender === 'male' ? 'hijo' : 'hija');

export const genderNameByValue = gender => (gender === 'male' ? 'BOY' : 'GIRL');

export const genderAdposition = gender => (gender === 'male' ? 'do' : 'da');

export const possessivePronoun = gender => (gender === 'male' ? 'dele' : 'dela');

export const genderArticle = gender => (gender === 'male' ? 'o' : 'a');

export const userIsNewUser = ({ name, lastname, source }, activeFamilyId) => {
  const isKineduPartners = source === 'KINEDU-PARTNERS';
  if (isKineduPartners && !activeFamilyId) return true;
  if (!name && !lastname) return true;
  return false;
};

export const pronounPt = gender => (gender === 'male' ? 'seu filho' : 'sua filha');

export const posssesiveAccusativePt = gender => (gender === 'male' ? 'seu' : 'sua');

export const possessivePronounPt = gender => (gender === 'male' ? 'meu filho' : 'minha filha');

export const childGenderPt = gender => (gender === 'male' ? 'filho' : 'filha');

export const getRandomString = Math.random().toString(36).replace(/[^a-z][0-9]+/g, '').substr(2, 10);

export const isToday = (evalDate) => {
  const currentDate = new Date();

  return evalDate.getDate() === currentDate.getDate()
    && evalDate.getMonth() === currentDate.getMonth()
    && evalDate.getYear() === currentDate.getYear();
};

export const getRandomNumberInRange = (min, max) => Math
  .floor((Math.random() * ((max + 1) - min)) + min);

export const noFutureDatesValidation = (bdDay, bdMonth, bdYear) => {
  const NbdDay = Number(bdDay);
  const NbdMonth = Number(bdMonth);
  const NbdYear = Number(bdYear);
  const currentDay = (new Date()).getDate();
  const currentMonth = (new Date()).getMonth() + 1;
  const currentYear = (new Date()).getFullYear();
  const isCurrentYear = NbdYear >= currentYear;
  const isAfterToday = (NbdMonth >= currentMonth && NbdDay > currentDay);
  const isAfterThisMonth = NbdMonth > currentMonth;

  if ((isCurrentYear && isAfterToday) || (isCurrentYear && isAfterThisMonth)) {
    return {
      givenMonth: currentMonth,
      givenDay: currentDay,
    };
  }
  return {
    givenMonth: NbdMonth,
    givenDay: daysInMonth(NbdMonth - 1, NbdYear),
  };
};

export const formatHealthInterests = i => i
  .map(({ interest_id, selected }) => ({ interest_id, selected }));

/**
  * Return true if the number to check is in a series of numbers
  * separated by an interval.
  *
  * @param {number} number The number to compare
  * @param {number} interval The separation of values (2 by 2, 3 by 3, etc...)
  * @param {number} offset Start the series from another value different from 0
*/
export const seriesOf = (number, interval = 1, offset = 0) => (number % interval) === (0 + offset);

export const getArticleByRole = (role) => {
  switch (role) {
    case 6:
    case 7: return 'an';
    case 3: return '';
    default: return 'a';
  }
};

export const getGenderByRole = (role) => {
  switch (role) {
    case 1:
    case 4:
    case 6:
    case 8: return 'female';
    case 3: return 'other';
    default: return 'male';
  }
};

export const sendEmail = (userId = 'NA', userSource = 'NA', t, subject, body = '') => {
  const userAgent = window.navigator.userAgent;
  const path = window.location.pathname;
  return `
    mailto:${t('common:EMAIL.EMAIL')}?Subject=${t(`common:${subject}`)}
    &body=${t(`common:${body}`)}%0D%0A
    %0D%0A----------------------------------------------%0D%0A
    ${t('common:EMAIL.USER_ID', { userId })}%0D%0A
    ${t('common:EMAIL.USER_AGENT', { userAgent })}%0D%0A
    ${t('common:EMAIL.USER_SOURCE', { userSource })}%0D%0A
    ${t('common:EMAIL.PATH', { path })}
`;
};

export const formatRequest = (params, imagePropertyName) => {
  if (params[imagePropertyName]) {
    const data = buildFormDataFromObject(params);
    return [data, { headers: { 'content-type': 'multipart/form-data' } }];
  }
  return [trimNilValues(params)];
};

export const isString = (variable) => {
  if (typeof variable === 'string') return true;
  return false;
};

export const LabelForMemberType = ({ status, self, relationship }, t) => {
  if (self) {
    return t('common:YOU');
  } if (status === 'pending') {
    return t('common:PENDING');
  }

  return t(`common:${UserRoles[relationship || 3]}`);
};

// Receive a number of months an returns the number of years and remaining months
export const MonthsInYears = (months) => {
  const years = Math.floor(months / 12);
  const remain = ((months / 12) % 1);
  const monthsLeft = Math.round(remain * 12);
  return {
    years,
    months: monthsLeft,
  };
};

export const ascendentOrderByProperty = (a, b, propertyName) => {
  if (a[propertyName] > b[propertyName]) {
    return 1;
  }
  if (a[propertyName] < b[propertyName]) {
    return -1;
  }
  return 0;
};

export const OpenLink = (ev, link) => {
  window.open(link);
};

export const percentileToPercentage = percentile => Math.round(percentile * 100);

export const getPercentage = (completed, total) => Math.round((completed / total) * 100) / 100;

export const getSkillType = (skill) => {
  if (skill.completed_milestones !== null) {
    const percentage = getPercentage(skill.completed_milestones, skill.active_milestones);
    if (percentage === 1.0) {
      return 'master';
    } if (percentage <= 0.3) {
      return 'emerging';
    }
    return '';
  }
  return 'pending';
};

export const getDopamineType = (skill) => {
  if (skill.completed_milestones !== null) {
    const percentage = getPercentage(skill.completed_milestones, skill.active_milestones);
    if (percentage === 1.0) {
      return 'master';
    } if (skill.percentile < 1.0 && skill.percentile >= 0.05) {
      return 'on_track';
    }
    return 'keep_on';
  }
  return 'no_data';
};

export const showTerminalLogs = (show = true) => {
  if (show) {
    /* eslint-disable */
    if (Config.FORCED_MODE) {
      console.log('%cFORCED MODE', 'background: #b10000; color: #ff8576; font-size: 16px');
    }
    if (Config.ENV === Config.Environments.STAGE) {
      console.log('%cSTAGING', 'background: #b15b00; color: #ffa476; font-size: 16px');
      console.log('%cVERSION:', 'background: #1FADDF; color: #FFF; font-size: 16px', Config.APP_VERSION);
      if (!Config.DEBUG_KINEDU_EVENTS) {
        console.log('%cEVENTS HIDDEN', 'background: #1e4628; color: #95d477; font-size: 16px');
      }
      if (Config.API_URL === Config.KINEDU_API[Config.Environments.PRODUCTION]) {
        console.log('%cAPI IS ON PRODUCTION', 'background: #b10000; color: #ff8576; font-size: 16px');
      }
    } else if (Config.ENV === Config.Environments.PRODUCTION) {
      if (Config.API_URL === Config.KINEDU_API[Config.Environments.STAGE]) {
        console.log('%cAPI IS ON STAGING', 'background: #b10000; color: #ff8576; font-size: 16px');
      }
    }
  }
}

export const fixAreaName = (area) => {
  switch (area) {
    case 'social_emotional': return 'social';
    default: return area;
  }
};

export const fixActivityName = (activity_type, area) => {
  if (area != 'health') {
    switch (activity_type) {
      case 'activity': return 'devAct';
      case 'recipe': return 'recipes';
      case 'tip': return 'tips';
      default: return activity_type;
    }
  } else {
    return 'healthAct';
  }
}

export const replaceMaskChar = (input = '', maskChar = '-') => {
  const value = input.replace(new RegExp(maskChar, 'g'), '');
  return value;
}

// t = current time
// b = start value
// c = change in value
// d = duration
Math.easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return c / 2 * t * t + b;
  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
};

function scrollingTo(element, to, duration) {
  const start = element.scrollTop;
  const change = to - start;
  let currentTime = 0;
  const increment = 20;

  const animateScroll = () => {
    currentTime += increment;
    const val = Math.easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}

export const scrollFromTo = (from = '', to, offset = 0, time = 500) => {
  const elem = document.getElementById(to);
  const topPos = elem.offsetTop;
  scrollingTo(document.getElementById(from), topPos - offset, time);
};

export const transformAnswerToBoolean = answer => answer === 'yes' || answer === true;

export const planTypeAdjectiveToNoun = planType => {
  switch (planType) {
    case 'MONTHLY': return 'MONTH';
    case 'SEMESTERLY': return 'SEMESTER';
    case 'YEARLY': return 'YEAR';
    default: planType;
  }
}

export const getCountryNameByInitials = userCountry => {
  switch (userCountry) {
    case 'BR': return 'Brazil';
    default: return 'WorldWide';
  }
}

const ua = window.navigator.userAgent;
const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
const webkit = !!ua.match(/WebKit/i);
const macbook = !!ua.match(/Macintosh/i);
const windows = !!ua.match(/Windows/i);
const linux = !!ua.match(/Linux/i);
const android = !!ua.match(/Android/i);
export const isDesktop = macbook || windows || (linux && !android);
export const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

// These are just examples, only used for reference.
export const userAgents = {
  chromeDesktop: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.100 Safari/537.36',
  safariDesktop: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_5) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1.1 Safari/605.1.15',
  safariMobile: 'Mozilla/5.0 (iPhone; CPU iPhone OS 12_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1.1 Mobile/15E148 Safari/604.1'
}

export const parseDomainFromUrl = (url = '') => {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
    return match[2];
  }
  return null;
}
export const cleanFormattedText = (text = '') => text.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ')

export const checkIsClassroomsInvite = invite => !!(invite.newUserData && invite.newUserData.status === 'classrooms_pending');
/**
 * It gets the value in a nested object in safe manner
 *
 * @param {Object} obj DateSource
 * @param {string} path Path of variable that wants to be accessed
 * @param {any} def default value in case it doesn't exist
 *
 * @return {any}
 */
export const optional = (obj, path, def) => {
  const propNames = path.replace(/\]|\)/, "").split(/\.|\[|\(/);

  return propNames.reduce((acc, prop) => acc[prop] || def, obj);
}

export const formatDate = (date, formatStr, locale = 'es') => {
  if (moment(date).isValid()) {
    const defaultLang = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'es';
    const lang = locale || defaultLang;
    moment.locale(lang || 'en');

    return moment(date).format(formatStr)
  }

  return 'Invalid Date';
}

export const isBrazilianUser = (user) => {
  return user?.country === 'BR';
}

let userCountry = null;
export const getVisitorIPCountry = async () => {
  if (!userCountry) {
    const res = await axios.get('https://ipapi.co/json');
    userCountry = res.data.country;
  }
  return userCountry;
}

/**
 * Options Array of Age ranges
 * @param {function} t
 * @returns Array of Age ranges
 */
export const ageRangeOptions = (t) => ([
  { label: `0 - 6 ${t ? `${t('MONTH_plural')}` : 'months'}`, value: {minAge: 0, maxAge: 6} },
  { label: `7 - 12 ${t ? `${t('MONTH_plural')}` : 'months'}`, value: {minAge: 7, maxAge: 12} },
  { label: `1 ${t ? `${t('catalog:YEAR_OLD')}` : 'years old'}`, value: {minAge: 13, maxAge: 24} },
  { label: `2 ${t ? `${t('catalog:YEARS_OLD')}` : 'years old'}`, value: {minAge: 25, maxAge: 36} },
  { label: `3 ${t ? `${t('catalog:YEARS_OLD')}` : 'years old'}+`, value: {minAge: 37, maxAge: 48} },
]);

/**
 * Finds an option from ageRangeOptions according to maxAgeParam
 * @param {number} maxAgeParam
 * @param {function} t
 * @returns ageRangeOptions in value.maxAge === maxAgeParam
 */
export const getAgeRangeOptionByMaxAge = (maxAgeParam, t) => (
  ageRangeOptions(t).find(({value: {maxAge}}) => maxAge===maxAgeParam));

/**
 * According to baby age in months, returns an age range object
 * @param {number} ageInMonths
 * @returns {Object} i.e. {label:'0 - 6 months', value: { minAge: 0, maxAge: 6 }}
 */
export const getAgeRangeOptionByAgeInMonths = (ageInMonths, t) => {
  // range 7-12 months
  if (6 < ageInMonths && ageInMonths < 13) return getAgeRangeOptionByMaxAge(12, t);
  // range 1 years old
  if (12 < ageInMonths && ageInMonths < 25) return getAgeRangeOptionByMaxAge(24, t);
  // range 2 years old
  if (24 < ageInMonths && ageInMonths < 37) return getAgeRangeOptionByMaxAge(36, t);
  // range 3 years old+
  if (36 < ageInMonths) return getAgeRangeOptionByMaxAge(48, t);
  // Default 0-6 months
  return getAgeRangeOptionByMaxAge(6, t);
}

/**
 * According to baby age in months, returns an age range object
 * @param {number} ageInMonths
 * @returns {Object} i.e. { minAge: 0, maxAge: 6 }
 */
export const getBabyAgeRange = (ageInMonths, t) => getAgeRangeOptionByAgeInMonths(ageInMonths, t).value;

/**
 * Receives an array and return a formated version with just label and value properties.
 * These label/error values are choosen by passing a path to search in each iteration of
 * the original array.
 *
 * @param {array} list The array to iterate
 * @param {array | number | string} [labelPath] The ramda path to find the label or the direct label
 * @param {array | number | string} [valuePath] The ramda path to find the value or the direct value
 * @param {any} [extraFn] A function that can spread data into the current iteration object.
 */
 export const formatOptions = (
  list = [],
  labelPath,
  valuePath,
  extraFn = null,
) => {
  if (list?.length) {
    return list.map((item) => ({
      label: labelPath ? pathRamda(labelPath, item) : item,
      value: valuePath ? pathRamda(valuePath, item) : item,
      ...(extraFn && extraFn(item)),
    }));
  }
  return [];
};

export const randomId = (length = 10) => Math.random().toString(36).replace(/[^a-z][0-9]+/g, '').substring(2, length+2)