/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sizeMe from 'react-sizeme';
import { withRouter } from 'react-router-dom';
import { HotKeys } from 'react-hotkeys';
import { ToastContainer } from 'react-toastify';
import { RendererIf } from 'lego-dist';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from 'react-redux';
import { bindActionCreators as bind, compose } from 'redux';
import { parse } from 'query-string';

import useLeanplum, { LOAD_OPTIONS } from 'hooks/leanplum/useLeanplum';
import PremiumProcessModalComponent from 'components/premiumProcessModal';

import {
  Auth, BabiesAndFamilies, Content, Dap, Modals,
} from '../../data/ducks';

import AuthGuardedRoutes, { APP_SECTIONS_PATHS } from '../../router/AuthGuardedRoutes';

import NoAuthRoutes from '../../router/NoAuthRoutes';
import SplashScreen from '../../components/shared/SplashScreen';
import ModalContainer from '../ModalContainer';
import Nav from '../../components/navbar';

import {
  SIZE_ME_CONFIG,
  MOBILE_VIEWPORT_WIDTH,
  // ModalNames,
  ACTIONS_MAP,
  ACTIONS_NAMES,
} from '../../shared-values';
import { ENV, Environments, FB_APP_ID } from '../../config';
import Logger from '../../components/shared/Logger';
import LoadingOverlay from '../../components/shared/LoadingOverlay';
import '../../lib/analytics';
import SuperBaby from '../../components/shared/SuperBaby/SuperBaby';
import Maintenance from '../../components/shared/Maintenance';
import { getConfigMaintenance } from '../../api/maintenance';

import Container, { Body, MainContainerWrapper } from './styles';


class MainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuperBaby: false,
      maintenanceMode: false,
    };
  }

  validateConfigMaintenaince = async () => {
    try {
      const result = await getConfigMaintenance();
      const { maintenance_platforms: maintenancePlatforms = {} } = result.data.data || {};
      const maintenanceMode = (maintenancePlatforms.web || []).includes('all');
      this.setState({ maintenanceMode });
    } catch (e) {
      console.error('Error', e);
    }
  }

  componentDidMount = async () => {
    await this.validateConfigMaintenaince();
    // /catalog?section=open_article&article_id=2075
    const { article_id: articleId } = parse(this.props.location.search);

    if (articleId) {
      this.props.fetchArticle(articleId);
    }

    this.props.checkAuthStatus();
    /* FIXME: Check how to init FB sdk the right way
      This is temporary, we shouldn't init fb from here
      This happend because of the prerender, the fbAsyncInit is
      not working correctly. */
    if (window.FB) {
      window.FB.init({
        appId: FB_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v2.5',
        cookie: true,
      });
    }
  };

  render() {
    const {
      authenticated, initialized, location, size, loadingUI, link,
    } = this.props;

    const leanplumStarted = window.localStorage.getItem('leanplumStarted') === 'true';

    const menuViews = [
      'tap',
      'dap',
      'wap',
      'past-plans',
      APP_SECTIONS_PATHS.FAMILIES,
      'activity',
      'article',
      'slideshow',
      'add-member',
      'catalog',
      APP_SECTIONS_PATHS.CATALOG,
      APP_SECTIONS_PATHS.CATALOG_SEARCH,
      'progress',
      'milestones',
      APP_SECTIONS_PATHS.CLASSROOMS,
      APP_SECTIONS_PATHS.PRICES,
      APP_SECTIONS_PATHS.PREMIUM_PROCESS,
      'settings',
      '404',
    ];

    const goBackViews = [
      'add-member',
    ];

    const mustHaveMenu = menuViews.some(view => location.pathname.includes(view));
    const mustHaveBack = goBackViews.some(view => location.pathname.includes(view));
    const isStaging = (ENV === Environments.STAGE);
    const isQA = (ENV === Environments.QA);

    const actionHandlers = {
      // [ACTIONS_NAMES.SECRET_MODAL]: () => this.props.openModal(ModalNames.SECRET_MODAL),
      [ACTIONS_NAMES.KONAMI_CODE]: () => this.setState({ showSuperBaby: true }, () => {
        setTimeout(() => { this.setState({ showSuperBaby: false }); }, 1800);
      }),
    };

    const Hooks = () => {
      useLeanplum({
        useForce: false,
        load: LOAD_OPTIONS.NOTHING,
      });
      window.localStorage.setItem('leanplumStarted', 'true');
      return null;
    };

    if (link) {
      window.location.replace(link);
    }

    return (
      <>
        <SplashScreen loading={(!authenticated !== !initialized) /** xor FIXME */} />
        <Maintenance showMaintenance={this.state.maintenanceMode} />
        <RendererIf condition={authenticated && !leanplumStarted}>
          <Hooks />
        </RendererIf>

        <RendererIf condition={!this.state.maintenanceMode}>
          <Container>
            <HotKeys className="hot-keys" keyMap={ACTIONS_MAP} handlers={actionHandlers} focused>
              <LoadingOverlay loading={loadingUI} />
              <RendererIf condition={initialized && authenticated && mustHaveMenu}>
                <Nav
                  mobile={size.width > 0 && size.width < MOBILE_VIEWPORT_WIDTH}
                  contentBodyRef={this.body}
                  mustHaveBack={mustHaveBack}
                />
              </RendererIf>
              <Body ref={(ref) => { this.body = ref; }} id="app-body">
                <RendererIf condition={authenticated && initialized}>
                  <AuthGuardedRoutes />
                </RendererIf>
                <RendererIf condition={!authenticated}>
                  <NoAuthRoutes />
                </RendererIf>
                <SuperBaby show={this.state.showSuperBaby} />
                <ModalContainer />
              </Body>
              <RendererIf condition={initialized && authenticated}>
                <PremiumProcessModalComponent />
              </RendererIf>
            </HotKeys>
          </Container>
        </RendererIf>

        <Logger isStaging={isStaging} isQA={isQA} />
        <MainContainerWrapper>
          <ToastContainer />
        </MainContainerWrapper>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { article_id: articleId, section } = parse(props.location.search);

  return {
    link: section === 'open_article' ? undefined : Content.Selectors.getUnTokenArticleLinkById(state, articleId),
    activeBaby: BabiesAndFamilies.Selectors.activeBaby(state),
    authenticated: state.auth.authenticated,
    initialized: state.appContext.initialized,
    loadingUI: state.families.UI.loading,
    activePlan: Dap.Selectors.activePlan(state),
  };
};

const mapDispatchToProps = dispatch => bind({
  checkAuthStatus: Auth.Creators.checkAuthStatus,
  login: Auth.Creators.login,
  openModal: Modals.Creators.openModal,
  fetchArticle: Content.Creators.fetchUnTokenArticle,
}, dispatch);

MainContainer.propTypes = {
  authenticated: PropTypes.bool,
  initialized: PropTypes.bool,
  checkAuthStatus: PropTypes.func,
  location: PropTypes.object,
  size: PropTypes.object,
  loadingUI: PropTypes.bool,
  openModal: PropTypes.func,
  fetchArticle: PropTypes.func,
};

MainContainer.defaultProps = {
  authenticated: null,
  initialized: false,
  checkAuthStatus: undefined,
  location: {},
  size: {},
  loadingUI: false,
  openModal: () => {},
  fetchArticle: undefined,
};

export default compose(
  sizeMe(SIZE_ME_CONFIG),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MainContainer);
