import { ProductsList } from './products';
import { PaymentSources } from './paymentSources';

export const PlanTypes = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  SEMESTERLY: 'SEMESTERLY',
  YEARLY: 'YEARLY',
  LIFETIME: 'LIFETIME',
};

export const Benefits = {
  [PlanTypes.MONTHLY]: [
    { title: 'MONTHLY_PAYMENT' },
    { title: 'ACCESS_TO_PREMIUM' },
  ],
  [PlanTypes.SEMESTERLY]: [
    { title: 'SEMESTERLY_PAYMENT' },
    { title: 'ACCESS_TO_PREMIUM' },
  ],
  [PlanTypes.YEARLY]: [
    { title: 'YEARLY_PAYMENT' },
    { title: 'ACCESS_TO_PREMIUM' },
  ],
  [PlanTypes.LIFETIME]: [
    { title: 'LIFETIME_PAYMENT' },
    { title: 'ACCESS_TO_PREMIUM' },
  ],
};

export const getFirstBenefit = hasSavings => (hasSavings ? ({ title: 'SAVINGS' }) : ({ title: 'NO_COMMITMENT' }));

export const sourceByPosition = {
  3: PaymentSources.DAP_THIRD_CARD,
  4: PaymentSources.DAP_FOURTH_CARD,
  5: PaymentSources.DAP_FIFTH_CARD,
};

export const getProductBySku = (products = {}, sku) => Object.values(products)
  .find(product => sku === product.SKU);

export const getProductByShortSku = (shortSku) => {

  // If none is found, yearly plan is send by default.
  return ProductsList[shortSku];
};
