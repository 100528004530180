import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

// import NeoModal from '../../components/modals/NeoModal';
import Button from '../../components/shared/buttons/MainButton';
import { colors, breakPoints } from '../../styles';

import babyImage from '../../images/BabySearching_2.png';
import Texture from '../../images/Texture2.png';
import Portal from '../../components/shared/Portal';
import EmptyState from '../../components/views/EmptyState';

// const Modal = styled(NeoModal)`
//   padding: 20px;
//   background-color: ${colors.physical};
//   background-image: url(${Texture});

//   overflow-y: scroll;
//   -webkit-overflow-scrolling: touch;
// `;

const BabyImg = styled.img.attrs({ src: babyImage })`
  display: block;
  height: 120px;
  width: auto;
  margin: 0;
`;

const Title = styled.p`
  color: ${colors.white};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  line-height: 33px;

  margin: 30px 0 50px;
`;

const Subtitle = styled.p`
  color: ${colors.white};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.41px;
  line-height: 21px;
`;

const Body = styled.div`
  max-width: 650px;
  margin: 20px auto;
`;

const List = styled.ul`
  padding-left: 20px;
`;

const Item = styled.li`
  color: ${colors.white};
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 21px;
  margin: 20px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 30px 0;
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'MakeFamilyDefault',
  data: {
    familyName: 'Tovar',
  },
}

*/

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  max-width: 900px;

  padding: 20px;
  box-sizing: border-box;
  background-color: ${colors.physical};
  background-image: url(${Texture});

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${breakPoints.smallTablet} {
    position: relative;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const MakeFamilyDefault = ({
  onClose, onSubmit, familyName, t,
}) => (
  <Portal>
    <EmptyState>
      <Container>
        <Content>
          <Title>{t('MAKE_DEFAULT_INVITATION.TITLE')}</Title>
          <BabyImg />
          <Body>
            <Subtitle>{t('MAKE_DEFAULT_INVITATION.SUBTITLE')}</Subtitle>
            <List>
              <Item>{t('MAKE_DEFAULT_INVITATION.ITEM_1', { familyName })}</Item>
              <Item>{t('MAKE_DEFAULT_INVITATION.ITEM_2')}</Item>
            </List>
          </Body>
          <ButtonContainer>
            <Button
              onClick={onSubmit}
              color="green"
              maxWidth={300}
            >
              {t('MAKE_DEFAULT_INVITATION.ON_ACCEPTED')}
            </Button>
            <Button onClick={onClose} color="clearwhite" maxWidth={300}>
              {t('MAKE_DEFAULT_INVITATION.ON_DECLINED')}
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </EmptyState>
  </Portal>
);

MakeFamilyDefault.propTypes = {
  familyName: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  t: PropTypes.func,
};

MakeFamilyDefault.defaultProps = {
  familyName: '',
  onClose: () => { },
  onSubmit: () => { },
  t: () => { },
};

export default withTranslation([Namespaces.OB, Namespaces.COMMON])(MakeFamilyDefault);
