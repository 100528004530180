/* eslint-disable import/no-cycle */
import { Creators, Types } from '../ducks/account';
import { getAccount } from '../../api/account';


// eslint-disable-next-line
export const fetchAccount = action$ => action$
  .ofType(Types.FETCH_ACCOUNT_REQUESTED)
  .mergeMap(({ familyId }) => getAccount(familyId)
    .then(res => res.data)
    .then((account) => {
      return Creators.fetchAccountSuccess(account);
    })
    .catch(err => Creators.fetchAccountError(err)));
