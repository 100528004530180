import { invertObj } from 'ramda';
import iconCognitive from '../components/shared/LabelAreas/assets/icon-cognitive.svg';
import iconHealth from '../components/shared/LabelAreas/assets/icon-health.svg';
import iconLinguistic from '../components/shared/LabelAreas/assets/icon-linguistic.svg';
import iconPhysical from '../components/shared/LabelAreas/assets/icon-physical.svg';
import iconsSocialEmotional from '../components/shared/LabelAreas/assets/icon-social-emotional.svg';
import { colors } from '../styles';

export const Areas = {
  PHYSICAL: 1,
  COGNITIVE: 2,
  LINGUISTIC: 3,
  SOCIAL_EMOTIONAL: 4,
  HEALTH: 5,
};

export const SPECIAL_AREA_ID = 6;

export const AreaNameById = invertObj(Areas);
export const AreaColorById = {
  [Areas.PHYSICAL]: colors.physical,
  [Areas.COGNITIVE]: colors.cognitive,
  [Areas.LINGUISTIC]: colors.linguistic,
  [Areas.SOCIAL_EMOTIONAL]: colors.socialEmotional,
  [Areas.HEALTH]: colors.health,
  [SPECIAL_AREA_ID]: '#5D5FEF',
};

export const areasById = {
  [Areas.PHYSICAL]: {
    title: 'common:AREAS.PHYSICAL',
    name: 'common:PHYSICAL',
    color: colors.physical,
    icon: iconPhysical,
  },
  [Areas.COGNITIVE]: {
    color: colors.cognitive,
    title: 'common:AREAS.COGNITIVE',
    name: 'common:COGNITIVE',
    icon: iconCognitive,
  },
  [Areas.LINGUISTIC]: {
    color: colors.linguistic,
    title: 'common:AREAS.LINGUISTIC',
    name: 'common:LINGUISTIC',
    icon: iconLinguistic,
  },
  [Areas.SOCIAL_EMOTIONAL]: {
    color: colors.socialEmotional,
    title: 'common:AREAS.SOCIAL_EMOTIONAL',
    name: 'common:SOCIAL_EMOTIONAL',
    icon: iconsSocialEmotional,
  },
  [Areas.HEALTH]: {
    color: colors.health,
    title: 'common:AREAS.HEALTH',
    name: 'common:HEALTH',
    icon: iconHealth,
  },
  [SPECIAL_AREA_ID]: {
    title: '',
    name: '',
    icon: undefined,
    color: '#5D5FEF',
  },
};


export const AreaNamesForEvents = {
  physical: 1,
  cognitive: 2,
  linguistic: 3,
  social: 4,
  health: 5,
};

export const AreaNameByIdForEvents = invertObj(AreaNamesForEvents);

export const ActivityNames = {
  webinar_session: 'Webinar session',
  interactive_session: 'Interactive session',
};

export const ActivityType = {
  WEWBINAR: 'webinar_session',
  INTERACTIVE: 'interactive_session',
};

export const AreaColorByActivityType = {
  [ActivityType.WEWBINAR]: colors.webinar,
  [ActivityType.INTERACTIVE]: colors.interactive,
};
