const QUERY_PARAM_TO_SHOW_MODAL = 'showPP';
const QUERY_PARAM_PP_RESPONSE = 'PPResponse';
const PAYMENT_PATH_PP_KEY = 'PAYMENT_PATH_PP_KEY';
const DATA_FOR_PP_PAYMENT_PROVIDER_PP_KEY = 'DATA_FOR_PP_PAYMENT_PROVIDER_PP';


const QUERY_PARAM_PP_RESPONSE_VALUES = {
  SUCCESS: 'success',
  CANCELED: 'canceled',
};

const PAYMENT_CARD_THEMES = {
  THREE: 'THREE',
  TWO: 'TWO',
  ONE: 'ONE',
  THREE_DESKTOP: 'THREE_DESKTOP',
};

export default {};
export {
  QUERY_PARAM_TO_SHOW_MODAL,
  PAYMENT_CARD_THEMES,
  QUERY_PARAM_PP_RESPONSE,
  QUERY_PARAM_PP_RESPONSE_VALUES,
  PAYMENT_PATH_PP_KEY,
  DATA_FOR_PP_PAYMENT_PROVIDER_PP_KEY,
};
