import React from 'react';
import styled from 'styled-components';

import { breakPoints } from '../../styles';
import kineduLogo from '../../images/KineduLogo.png';

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const KineduTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 2px;
`;

const KineduLogo = styled.img.attrs({ src: kineduLogo })`
  width: 90px;

  ${breakPoints.xLargeTablet} {
    width: 112px;
  }
`;

const Logo = () => (
  <LogoContainer>
    <KineduTextContainer>
      <KineduLogo />
    </KineduTextContainer>
  </LogoContainer>
);

export default Logo;

