// REACT
import React from 'react';
import PaywallComponent from 'components/paywall';
import PaywallViewWrapper, { PaywallViewContent } from './styles';

const Paywall = () => (
  <PaywallViewWrapper>
    <PaywallViewContent>
      <PaywallComponent />
    </PaywallViewContent>
  </PaywallViewWrapper>
);

export default Paywall;
