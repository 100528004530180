import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Default, ThemeShape } from './../themes/Link';

const e = React.createElement;

const LinkStyled = styled(({ tag, children, ...props }) => e(tag, props, children))`
  ${({ theme }) => theme.container};
  text-decoration: ${({ underlined }) => underlined && 'underline'};
  cursor: pointer;
`;

const open = (e, link, onClick, target) => {
  if (link) {
    e.stopPropagation();
    window.open(link, target || '_self');
  } else {
    onClick();
  }
};

const Link = ({ theme, children, href, onClick, target, style, underlined, tag, ...props }) => (
  <ThemeProvider theme={theme}>
    <LinkStyled
      onClick={event => open(event, href, onClick, target)}
      underlined={underlined ? 1 : 0}
      style={style}
      tag={tag}
      {...props}
    >
      {children}
    </LinkStyled>
  </ThemeProvider>
);

Link.propTypes = {
  theme: ThemeShape,
  children: PropTypes.node,
  href: PropTypes.string,
  underlined: PropTypes.bool,
  target: PropTypes.string,
  tag: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  theme: Default,
  children: PropTypes.node,
  href: undefined,
  underlined: false,
  target: '',
  tag: 'a',
  style: {},
  onClick: () => { },
};

export default Link;
