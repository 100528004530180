import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { asyncTypeAndActionCreators as aTC } from '../../lib/utils';

export const { Types, Creators } = createActions({
  ...aTC('healthInterests', { requested: ['babyId'], success: ['interests'] }),
  postHealthInterests: ['babyId', 'interests'],
});


const INITIAL_STATE = Immutable({
  interests: [],
});

export const Selectors = {
  //
};

const Handlers = {
  [Types.FETCH_HEALTH_INTERESTS_SUCCESS]: (state, { interests }) => ({ interests }),
};

export const Reducer = createReducer(INITIAL_STATE, Handlers);
