import { css } from 'styled-components';
import { colors } from '../../colors';

const Wrapper = css`
  &:focus-within{
    input {
      color: ${colors.kinedu};
      border: 1px solid ${colors.kinedu};
    }
  }

  input {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &.has-error {
    input {
      color: #E50000;
      border: 1px solid #E50000;
    }
  }
`;

const InputCode = css`
  font-size: 24px;
  background: #FFFFFF;
  font-weight: 500;
  font-family: inherit;
  border-radius: 4px;
  letter-spacing: -0.68px;
  transition: all 0.3s ease;
  color: ${colors.kinedu};

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 134, 216, 0.4);
  }
`;

export default {
  Wrapper,
  InputCode,
};
