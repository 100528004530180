const RECURRENCES = {
  w: {
    recurrence: 'weekly',
  },
  1: {
    recurrence: 'monthly',
  },
  3: {
    recurrence: 'quarterly',
  },
  6: {
    recurrence: 'semester',
  },
  12: {
    recurrence: 'yearly',
  },
  lifetime: {
    recurrence: 'lifetime',
  },
};

const getSkuDataByFormat = (sku) => {
  const separator = '_';
  const skuSplitted = `${sku}`.split(separator);
  const path = skuSplitted.slice(1).join(separator) || 'unknown';
  const recurrenceKey = +skuSplitted[2] || `${skuSplitted[2]}`.toLocaleLowerCase();
  const recurrence = RECURRENCES[recurrenceKey]?.recurrence || 'unknown';

  const dataByFormat = {
    skuSplitted,
    sku,
    path,
    recurrence,
  };
  return dataByFormat;
};


export default {};
export { getSkuDataByFormat };
