import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import Icon from '../shared/Icon';
import BaseCard from './BaseCard/BaseCard';

import * as SharingService from '../../lib/sharing';

import { breakPoints, colors } from '../../styles';
import { AreaColorById } from '../../values/areas';
import { Namespaces } from '../../values/i18n';

const ContentIcon = styled(Icon)`
  margin-right: 5px;
  background-color: ${({ areaId, completed }) => (completed ? colors.cognitive : AreaColorById[areaId])};
  width: 24px;
  height: 24px;
  padding: 6px;
  border-radius: 100%;
  vertical-align: middle;
  fill: #FFFFFF;
`;

const Mask = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: background 250ms ease;
  width: 100%;

  .content-card:hover & {
    background-color: rgba(64, 64, 64, 0.5);
  }
`;

const Header = styled.div`
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  margin: 25px 0 0 0;
  text-transform: uppercase;
  z-index: 1;
`;

const Content = styled.h1`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  left: 0;
  margin: 0;
  line-height: 1.3;
  padding: 0 30px;
  position: absolute;
  text-align: center;
  width: 100%;
  letter-spacing: 0;
`;

const SharingActions = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: opacity 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  opacity: 1;

  .Icon {
    width: 27px;
    height: 27px;
    margin: 8px 4px;
    padding: 0;
    cursor: pointer;
  }

  .content-card:hover & {
    opacity: 1;
  }

  ${breakPoints.qLargeTablet} {
    opacity: 0;
  }
`;
class ContentCard extends Component {
  static open(link, e) {
    e.stopPropagation();
    window.open(link);
  }

  constructor(props) {
    super(props);

    this.state = { sharingLinks: undefined };
  }

  componentWillMount() {
    const {
      link, title, type, touched, picture,
    } = this.props.item;

    if (type === 'article') {
      const sharingLinks = SharingService.getArticleShareIntents({ link, title, picture });
      this.setState({ sharingLinks, touched });
    }
  }

  getHeading = (type, context) => {
    if (context) {
      return type === 'article' ? 'ARTICLE_OF_THE_DAY' : 'SLIDESHOW_OF_THE_DAY';
    }
    return type === 'article' ? 'ARTICLE' : 'SLIDESHOW';
  }

  render() {
    const {
      item, onOpen, width, show, context, t,
    } = this.props;
    const { sharingLinks } = this.state;
    const isDap = context !== 'catalog' && context !== 'progress'; // FIXME: Cambiar por === 'dap', pero primero ocupas mandarlo desde dap
    const heading = this.getHeading(item.type, isDap);

    let iconShow = '';

    if (item.touched) {
      iconShow = 'check2';
    } else {
      iconShow = item.type === 'article' ? 'article' : 'slideshow';
    }

    return (
      <BaseCard
        image={item.picture}
        onClick={onOpen}
        className="content-card"
        width={width}
        show={show}
      >
        <Mask />
        <Header>
          <ContentIcon icon={iconShow} areaId={item.area_id} type={item.type} completed={item.touched} />
          <span>{t(heading)}</span>
        </Header>
        <Content>{item.title || item.name}</Content>
        {sharingLinks && this.state.touched
          && (
          <SharingActions>
            <Icon
              icon="facebook-outline"
              onClick={e => ContentCard.open(sharingLinks.facebook, e)}
            />
            <Icon
              icon="twitter-outline"
              onClick={e => ContentCard.open(sharingLinks.twitter, e)}
            />
            <Icon
              icon="pinterest-outline"
              onClick={e => ContentCard.open(sharingLinks.pinterest, e)}
            />
          </SharingActions>
          )}
      </BaseCard>
    );
  }
}

ContentCard.propTypes = {
  item: PropTypes.shape({
    link: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    touched: PropTypes.bool,
    picture: PropTypes.string,
  }),
  context: PropTypes.string,
  onOpen: PropTypes.func,
  width: PropTypes.number,
  show: PropTypes.bool,
  t: PropTypes.func,
};

ContentCard.defaultProps = {
  item: null,
  context: '',
  onOpen: undefined,
  width: null,
  show: false,
  t: () => {},
};

export default withTranslation(Namespaces.DAP)(ContentCard);
