import { createActions } from 'reduxsauce';
import { findIndex, map } from 'ramda';

const DEEP = { deep: true };

export const { Creators, Types } = createActions({
  createFamilyRequested: null,
  createFamilySuccess: ['families'],
  createFamilyError: ['err'],

  fetchFamiliesRequested: null,
  fetchFamiliesSuccess: ['families'],
  fetchFamiliesError: ['err'],

  updateFamilyRequested: ['family'],
  updateFamilySuccess: {
    familyId: undefined,
    userId: undefined,
    name: undefined,
    avatar: undefined,
  },
  updateFamilyError: null,

  familySelected: ['familyId'],

  fetchFamilyMembersRequested: ['id'],
  fetchFamilyMembersSuccess: { id: undefined, memberships: undefined },
  fetchFamilyMembersError: null,

  fetchFamilyMemberRequested: ['familyId', 'memberId'],
  fetchFamilyMemberSuccess: ['membership'],
  fetchFamilyMemberError: null,

  fetchFamilyMemberAndBabyPlansRequested: ['familyId', 'memberId', 'babyId'],
  fetchFamilyMemberAndBabyPlansSuccess: ['membership', 'babyId', 'memberId', 'babyId'],
  fetchFamilyMemberAndBabyPlansError: null,

  addMemberRequested: { email: undefined, relationship: undefined, role: undefined },
  addMemberSuccess: ['membership'],
  addMemberError: ['err'],

  fetchFamiliesAndSelect: { babyId: undefined, familyId: undefined, skipBabySelect: false },

  setDefaultFamilyRequested: ['familyId', 'redirect'],
  setDefaultFamilySuccess: ['familyId'],
  setDefaultFamilyError: null,

  deleteFamilyRequested: ['familyId'],
  deleteFamilySuccess: ['familyId'],
  deleteFamilyError: ['err'],

  deleteFamilyMemberRequested: ['familyId', 'memberId'],
  deleteFamilyMemberSuccess: ['familyId', 'memberId'],
  deleteFamilyMemberError: ['err'],

  setMemberPermissionsRequested: ['memberId', 'role'],
  setMemberPermissionsSuccess: ['memberId', 'role'],
  setMemberPermissionsError: null,

  updateSelectedFamily: ['familyId', 'babyId'],
  setInvitationSent: ['invitationSent'],
});

export const INITIAL_STATE = {
  UI: {
    addMember: { loading: false, error: false, invitationSent: false },
    familyPhoto: { loading: false, error: false },
    babyStats: { loading: false, error: false },
  },
};

export const Handlers = {
  /** fetchFamilies */
  [Types.FETCH_FAMILIES_REQUESTED]: state => state.merge({ loading: true }),
  [Types.FETCH_FAMILIES_SUCCESS]: (state, action) =>
    state.merge({ loading: false, families: action.families }),
  [Types.FETCH_FAMILIES_ERROR]: (state, action) =>
    state.merge({ loading: false, error: action.err }),
  [Types.UPDATE_FAMILY_REQUESTED]: state =>
    state.merge({ UI: { ...state.UI, familyPhoto: { loading: true } } }),
  [Types.UPDATE_FAMILY_SUCCESS]: (state, { userId, familyId, name, avatar }) => {
    const familyIndex = Object.values(state.families).findIndex(({ id }) => id === familyId);
    // No families, no need to update.
    if (familyIndex === -1) {
      return state;
    }
    const userIndex =
      Object.values(state.memberships[familyId]).findIndex(({ id }) => id === userId);

    const photo = avatar || state.families[familyIndex].photo;

    return state.merge({
      families: state.families.setIn(
        [familyIndex], { ...state.families[familyIndex], name, photo }),
      memberships: state.memberships && state.memberships.setIn(
        [familyId, userIndex, 'lastname'], name),
      UI: { ...state.UI, familyPhoto: { loading: false } },
    }, DEEP);
  },
  [Types.SET_INVITATION_SENT]: (state, { invitationSent }) =>
    state.merge({ UI: { addMember: { invitationSent } } }, DEEP),
  [Types.FETCH_FAMILY_MEMBERS_SUCCESS]: (state, action) =>
    state.merge({ memberships: { [action.id]: action.memberships } }, DEEP),
  [Types.FETCH_FAMILY_MEMBER_REQUESTED]:
    state => state.merge({ UI: { ...state.UI, babyStats: { loading: true } } }),
  [Types.FETCH_FAMILY_MEMBER_SUCCESS]: (state, { membership }) => {
    const memberList = state.memberships[state.activeFamilyId];
    const memberIndex = findIndex(({ membershipId }) =>
      membershipId === membership.membershipId, memberList);
    return state.merge({
      memberships: state.memberships
        .setIn([state.activeFamilyId, memberIndex], membership),
      UI: { ...state.UI, babyStats: { loading: false } },
    }, DEEP);
  },
  [Types.FAMILY_SELECTED]: (state, action) =>
    state.merge({ activeFamilyId: action.familyId }),
  /** addMember */
  [Types.ADD_MEMBER_REQUESTED]: state =>
    state.merge({ UI: { addMember: { loading: true, error: false } } }, DEEP),
  [Types.ADD_MEMBER_SUCCESS]: (state, { membership = {} }) =>
    state.merge({
      memberships: { [state.activeFamilyId]:
        [...state.memberships[state.activeFamilyId], membership],
      },
      UI: { addMember: { loading: false, error: false, invitationSent: true } },
    }, DEEP),
  [Types.ADD_MEMBER_ERROR]: state =>
    state.merge({ UI: {
      addMember: { loading: false, error: true, invitationSent: false },
    } }, DEEP),
  [Types.SET_MEMBER_PERMISSIONS_SUCCESS]: (state, { memberId, role }) => {
    const memberList = state.memberships[state.activeFamilyId];
    const memberIndex = findIndex(({ membershipId }) =>
      membershipId === memberId, memberList);
    return state.merge({
      memberships: state.memberships.setIn([state.activeFamilyId, memberIndex, 'role'], role),
    }, DEEP);
  },
  [Types.DELETE_FAMILY_MEMBER_SUCCESS]: (state, { familyId, memberId }) => {
    const memberList = state.memberships[familyId];
    const newList = memberList.filter(m => m.membershipId !== memberId);
    return state.merge({
      memberships: { [familyId]: newList },
    }, DEEP);
  },
  [Types.DELETE_FAMILY_SUCCESS]: (state, { familyId }) => {
    const newList = state.families.filter(f => f.id !== familyId);
    return state.merge({
      families: newList,
    }, DEEP);
  },
  [Types.SET_DEFAULT_FAMILY_SUCCESS]: (state, { familyId }) => {
    const newFamilies = map((family) => {
      if (family.id === familyId) {
        return family.setIn(['membership', 'default_account'], true);
      }
      return family.setIn(['membership', 'default_account'], false);
    }, state.families);
    return state.merge({
      families: newFamilies,
    }, DEEP);
  },
  [Types.UPDATE_SELECTED_FAMILY]: (state, { familyId, babyId }) =>
    state.merge({ activeFamilyId: familyId, activeBabyId: babyId }),
};
