import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import BabyWithFlag from '../../images/BabyWithFlag2.png';
import { genderPronoun, genderAccusativePronoun } from '../../lib/utils';
import { colors, breakPoints } from '../../styles';

const StyledPrematureBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  
  width: 100%;
  height: 150px;
  background-color: ${colors.kineduShade};
  margin: 30px 0 0;
  overflow: hidden;

  ${breakPoints.smallTablet} {
    height: 93px;
    justify-content: space-between;
  }
`;

const Image = styled.img.attrs(({ src }) => src)`
  width: 118px;

  ${breakPoints.smallTablet} {
    margin: 0 0 0 30px;
    top: 25px;
    position: relative;
  }
`;

const Text = styled.div`
  color: ${colors.white};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  padding: 20px;

  ${breakPoints.smallTablet} {
    text-align: center;
    max-width: 550px;
    margin: auto;
  }
`;

const PrematureBanner = ({ baby, t }) => (
  <StyledPrematureBanner>
    <Image src={BabyWithFlag} />
    <Text>
      {t('PREMATURE_BANNER', {
        babyName: baby.name,
        months: t('common:X_MONTH', {
          months: baby.developmental_age_in_months,
          count: baby.developmental_age_in_months,
        }),
        genitive: genderPronoun(baby.gender),
        accusative: genderAccusativePronoun(baby.gender),
      })}

    </Text>
  </StyledPrematureBanner>
);

PrematureBanner.propTypes = {
  baby: PropTypes.object,
  t: PropTypes.func,
};

PrematureBanner.defaultProps = {
  baby: {},
  t: () => {},
};

export default withTranslation([Namespaces.PROGRESS, Namespaces.COMMON])(PrematureBanner);
