import React from 'react';
import PropTypes from 'prop-types';
import { find, findIndex, curry, map, when, propEq, assoc } from 'ramda';
import styled, { css, ThemeProvider } from 'styled-components';
import Formsy from 'formsy-react-2';

import { ThemeShape, CheckListBoxes, Default } from './../themes/SegmentedBoxes';

import { colors } from '../../styles';
import Icon from './Icon';
import { equalAt } from '../../lib/utils';

const Check = styled(Icon).attrs({
  size: 30,
  color: ({ color }) => color,
  icon: ({ selectedItem }) => (selectedItem ? 'check-circle-full' : 'check-circle-o'),
})`
  flex-shrink: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.container};
  ${({ containerStyle }) => containerStyle};
`;

const CheckboxGroup = styled.div`
  ${({ theme }) => theme.checkboxGroup};
  ${({ column }) => column && css`
    flex-direction: column;
  `};
`;

const Box = styled.label`
  ${({ theme }) => theme.box};
  ${({ theme, column }) => column && theme.boxColumn};

  input {
    ${({ theme }) => theme.input};
  }

  ${props => props.selected && css`
    ${({ theme }) => theme.selected};
  `};
`;

const Label = styled.label`
  color: ${props => props.labelColor};
  ${({ theme }) => theme.label};
`;

const Text = styled.span`
  ${({ theme }) => theme.text};
`;

const TextContainer = styled.div`
  ${({ theme }) => theme.textContainer};
`;

const Image = styled.img`
  ${({ theme }) => theme.image};
`;

class SegmentedBoxes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
    };
  }

  componentWillMount() {
    if (this.props.radio) {
      /* Set the default value if radio mode is enabled */
      this.setState({ selectedItem: this.props.value, list: this.props.boxes },
        () => {
          this.updateList(this.state.selectedItem, this.props.multiple, this.props.radio);
          this.props.setValue(this.state.selectedItem);
        });
    } else {
      /* Set the default list of values */
      this.setState({ list: this.props.boxes },
        () => this.props.setValue(this.state.list));
    }
  }

  componentWillReceiveProps(newProps) {
    /* If there are new values, set them */
    if (!equalAt(this.props, newProps, ['boxes'])) {
      const atLeastOneIsSelected = find(box => box.selected, newProps.boxes);
      this.setState({ list: newProps.boxes },
        () => {
          if (atLeastOneIsSelected) {
            this.props.setValue(this.state.list);
          }
        });
    }
    if (!equalAt(this.props, newProps, ['value'])) {
      this.setState({ list: newProps.boxes, selectedItem: newProps.value },
        () => {
          this.updateList(this.state.selectedItem, newProps.multiple, newProps.radio);
          this.props.setValue(this.state.selectedItem);
        });
    }
  }

  updateList(val, multiple, radio, callback) {
    /* Get the item based on the given value */
    // eslint-disable-next-line
    const selectedItem = find(i => i.value == val, this.state.list);
    /* Get the item index based on the given value */
    // eslint-disable-next-line
    const selectedItemIndex = findIndex(i => i.value == val, this.state.list);
    /* If item doesn't exist, return a friendly error */
    if (!selectedItem) return console.error('SegmentedBoxes: No item found.'); // eslint-disable-line

    /* If multiple option is disabled, the radio mode is enabled and the selectedItem is the same as
    the previous click, then just give back the current list */
    if (!multiple && radio) {
      if (selectedItem.selected) {
        if (callback) {
          return callback(this.state.list, selectedItem.value, selectedItemIndex);
        }
      }
    }

    /* Toggle the selected property */
    const newItem = { ...selectedItem };
    newItem.selected = newItem.selected ? !newItem.selected : true;

    /* Function to update the given list with the new item */
    const update = curry((selected, value, items) => map(
      when(propEq('value', value), assoc('selected', selected)),
      items,
    ));
    let prepareList = [...this.state.list];
    /* If multiple options is false, then reset the list */
    if (!multiple) prepareList = prepareList.map(item => ({ ...item, selected: false }));
    /* Update and return the new list */
    const newList = update(newItem.selected, newItem.value, prepareList);


    /* Update the state list with the new list */
    return this.setState({ list: newList, selectedItem: selectedItem.value }, () => {
      /* If radio mode is enabled, just return the selectedItem as the value */
      if (radio) {
        this.props.setValue(this.state.selectedItem);
      } else {
        this.props.setValue(this.state.list);
      }
      if (callback) {
        callback(this.state.list, selectedItem.value, selectedItemIndex);
      }
    });
  }

  render() {
    const {
      theme,
      align,
      style,
      containerStyle,
      label,
      labelColor,
      boxColor,
      name,
      onChange,
      multiple,
      radio,
      column,
    } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Container align={align} style={style} containerStyle={containerStyle}>
          {label && <Label labelColor={labelColor}>{label}</Label>}
          <CheckboxGroup column={column}>
            {this.state.list &&
              this.state.list.map((item, index) => (
                <Box selected={item.selected} column={column} key={index} className="box">
                  <Image src={item.image} />
                  <input
                    type="checkbox"
                    name={name}
                    value={item.value}
                    // defaultChecked={item.selected}
                    checked={item.selected || false}
                    onChange={e => this.updateList(e.target.value, multiple, radio, onChange)}
                  />
                  <TextContainer>
                    {theme === CheckListBoxes &&
                      <Check color={boxColor} selectedItem={item.selected} />
                    }
                    <Text>{item.label}</Text>
                  </TextContainer>
                </Box>),
              )}
          </CheckboxGroup>
        </Container>
      </ThemeProvider>
    );
  }
}


SegmentedBoxes.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.object,
  ]),
  label: PropTypes.string,
  labelColor: PropTypes.string,
  boxColor: PropTypes.string,
  align: PropTypes.string,
  style: PropTypes.object,
  multiple: PropTypes.bool,
  /* This is the name of the input
      also the name required by formsy */
  name: PropTypes.string,
  /* These are the checkboxes to render
      in the form of an array of objects.
      This is the way SegmentedBoxes works,
      it needs this 3 properties in each object. */
  boxes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selected: PropTypes.bool,
  })),
  radio: PropTypes.bool,
  containerStyle: PropTypes.array,
  column: PropTypes.bool,
  onChange: PropTypes.func,
  setValue: PropTypes.func,
  theme: ThemeShape,
};

SegmentedBoxes.defaultProps = {
  value: undefined,
  label: '',
  labelColor: '',
  boxColor: colors.physical,
  align: '',
  style: null,
  multiple: false,
  name: '',
  boxes: [],
  radio: false,
  containerStyle: [],
  column: false,
  onChange: () => { },
  setValue: () => { },
  theme: Default,
};

export default Formsy.HOC(SegmentedBoxes);
