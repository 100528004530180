import { findIndex, propEq, merge, indexBy, prop, path } from 'ramda';
import { Observable } from 'rxjs';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';

import { Types, Creators, Selectors } from '../../ducks/babiesAndFamilies';
import * as Api from '../../../api/families';
import * as BabiesApi from '../../../api/babies';
import { fetchFamilies$ } from './helperObservables';
import { OBTypes, startOB2IAFlow } from '../orchestrators/OB2IA';
import { Modals } from '../../ducks';
import EventReporter, { Events } from '../../../lib/EventReporter';
import { APP_SECTIONS_PATHS } from '../../../router/AuthGuardedRoutes';

const getBabyIndex = (id, babyList) => findIndex(propEq('id', id), babyList);

export const fetchFamilies = action$ => action$.ofType(Types.FETCH_FAMILIES_REQUESTED)
  .mergeMap(() => Api.getFamilies()
    .then(response => response.data.data.families)
    // Turn array into id-indexed object.
    .then(families => families.map(f => merge(f, { babies: indexBy(prop('id'), f.babies) })))
    .then(families => Creators.fetchFamiliesSuccess(families))
    .catch(({ res }) => {
      const error = path(['data', 'error'], res);
      toast.error(error);
      return Creators.fetchFamiliesError(error);
    }));

export const fetchFamilyMembers = action$ => action$.ofType(
  Types.FETCH_FAMILY_MEMBERS_REQUESTED,
).mergeMap(action => Api.getFamilyMembers(action.id)
  .then(res => res.data.data.memberships)
  .then(memberships => memberships.map(({
    id, status, relationship, role, user,
  }) => ({
    membershipId: id, status, relationship, role, ...user,
  })))
  .then(memberships => Creators.fetchFamilyMembersSuccess({ id: action.id, memberships }))
  .catch(() => Creators.fetchFamilyMembersError()));

export const fetchFamilyMember = action$ =>
  action$.ofType(Types.FETCH_FAMILY_MEMBER_REQUESTED)
    .mergeMap(({ familyId, memberId }) =>
      Api.getFamilyMember(familyId, memberId)
        .then(res => res.data.data.membership)
        .then(({ id, status, relationship, user, babies, role }) =>
          ({ membershipId: id, status, relationship, ...user, babies, role }))
        .then(membership => Creators.fetchFamilyMemberSuccess(membership))
        .catch(() => Creators.fetchFamilyMemberError()));

export const fetchFamilyMemberAndBabyPlans = action$ =>
  action$.ofType(Types.FETCH_FAMILY_MEMBER_AND_BABY_PLANS_REQUESTED)
    .mergeMap(({ familyId, memberId, babyId }) => Observable.forkJoin(
      Observable.fromPromise(
        Api.getFamilyMember(familyId, memberId)
          .then(res => res.data.data.membership)
          .then(({ id, status, relationship, user, babies, role }) =>
            ({ membershipId: id, status, relationship, ...user, babies, role }))
          // .then(membership => Creators.fetchFamilyMemberSuccess(membership))
          .catch(() => Creators.fetchFamilyMemberError()),
      ),
      Observable.fromPromise(
        BabiesApi.getBabyActivityPlans(babyId)
          .then(res => res.data.data.activity_plans)
          // .then(babyPlans => Creators.fetchBabyPlansSuccess(babyPlans, babyId, memberId))
          .catch(err => Creators.fetchBabyPlansError(err)),
      ),
      Observable.of(babyId),
    )).mergeMap(([membership, babyPlans, babyId]) => {
      const babies = membership.babies;
      const babyIndex = getBabyIndex(babyId, babies);
      let baby = babies[babyIndex];
      baby = { ...baby, babyPlans };
      membership.babies[babyIndex] = { ...baby, babyPlans };
      return [Creators.fetchFamilyMemberSuccess(membership)];
    });

export const addFamilyMember = (action$, { getState }) =>
  action$.ofType(Types.ADD_MEMBER_REQUESTED)
    .mergeMap(({ email, relationship, role }) =>
      Api.postFamilyMembers({
        familyId: getState().families.activeFamilyId,
        email,
        relationship,
        role,
      })
        .then(res => res.data.data)
        .then(({ membership: { user, ...membership } }) => Observable.concat([
          Creators.addMemberSuccess({ ...membership, ...user }),
        ]))
        .catch(() => {
          toast.error('An error has occurred');
          return Creators.addMemberError();
        }))
    .flatMap(actions => actions);

export const updateFamily = (action$, { getState }) =>
  action$.ofType(Types.UPDATE_FAMILY_REQUESTED)
    .mergeMap(({ family }) =>
      Api.putFamilies(family)
        .then(res => res.data.data.account)
        .then(account => Creators.updateFamilySuccess({
          ...account,
          familyId: account.id,
          userId: getState().user.id,
        }))
        .catch(() => Creators.updateFamilyError()),
    );

export const deleteFamilyMember = action$ =>
  action$.ofType(Types.DELETE_FAMILY_MEMBER_REQUESTED)
    .mergeMap(({ familyId, memberId }) => Observable.concat(
      Api.deleteFamilyMember(familyId, memberId)
        .then(() =>
          // toast.success('Member remove correctly');
          Creators.deleteFamilyMemberSuccess(familyId, memberId),
        ).catch((err) => {
          toast.error('Error: member not removed');
          return Creators.deleteFamilyMemberError(err);
        }),
      fetchFamilies$(action$, ([defaultFamily]) => {
        if (!defaultFamily) {
          return [startOB2IAFlow({ OBType: OBTypes.PARTIAL })];
        }
        return ([
          Creators.selectFamilyAndDefaultBaby(defaultFamily.id),
          Creators.checkEmptyFamily(),
        ]);
      }),
    ));

export const setDefaultFamily = action$ =>
  action$.ofType(Types.SET_DEFAULT_FAMILY_REQUESTED)
    .mergeMap(({ familyId, redirect }) => Api.postDefaultFamily(familyId)
      .then(() => {
        EventReporter.action(Events.MENU_FAMILY_DEFAULT({
          source: 'Menu',
        }));
        // toast.success('This is now your default family.');
        return Observable.concat([
          Creators.setDefaultFamilySuccess(familyId),
          redirect ? push(`${APP_SECTIONS_PATHS.FAMILIES}/${familyId}`) : { type: '' },
        ]);
      }).catch(() => {
        toast.error('Can\'t set this family as your default family');
        return Creators.setDefaultFamilyError();
      })).flatMap(flating => flating);


export const deleteFamily = action$ => action$.ofType(Types.DELETE_FAMILY_REQUESTED)
  .mergeMap(({ familyId }) => Api.deleteFamily(familyId)
    .then(() => Creators.deleteFamilySuccess(familyId))
    .catch(err => Creators.deleteFamilyError(err)));

export const setMemberPermissions = (action$, { getState }) =>
  action$.ofType(Types.SET_MEMBER_PERMISSIONS_REQUESTED)
    .mergeMap(({ memberId, role }) =>
      Api.putMembershipPermission(getState().families.activeFamilyId, memberId, role)
        .then(() =>
          // toast.success('User permission changed correctly');
          Creators.setMemberPermissionsSuccess(memberId, role),
        )
        .catch(() => {
          toast.error('Error: permissions not changed');
          return Creators.setMemberPermissionsError();
        }));


export const createFamily = action$ =>
  action$.ofType(Types.CREATE_FAMILY_REQUESTED)
    .mergeMap(() => Api.postFamily()
      .then(() =>
        Creators.createFamilySuccess(),
      ).catch((err) => {
        toast.error('Error creating family');
        return Creators.createFamilyError(err);
      }));

export const deleteFamilySuccess = (action$, { getState }) =>
  action$.ofType(Types.DELETE_FAMILY_SUCCESS)
    .mergeMap(() => {
      const firstFamily = getState().families.families[0];
      if (!firstFamily) {
        return [startOB2IAFlow({ OBType: OBTypes.PARTIAL })];
      }
      return [Creators.selectFamilyAndDefaultBaby(firstFamily.id)];
    });

const showAwesomeModal = familyName =>
  Modals.Creators.openModal({
    name: 'NewDefaultFamilyModal',
    data: {
      familyName,
    },
  });

const onSetDefaultFamilySuccess = stream$ =>
  stream$
    .ofType(Types.SET_DEFAULT_FAMILY_SUCCESS)
    .take(1);

const onModalClosed = stream$ => stream$
  .filter(({ type, name }) => (type === 'MODAL_CLOSED' && name === 'confirmDefaultFamilyModal'))
  .take(1)
  .map(action => action);

export const waitToShowAwesomeModal = (action$, { getState }) => action$
  .ofType(Types.SET_DEFAULT_FAMILY_REQUESTED)
  .mergeMap(() => Observable.forkJoin(
    onSetDefaultFamilySuccess(action$),
    onModalClosed(action$),
  ).mergeMap(([{ familyId }]) => {
    const family = Selectors.familyById(getState(), familyId);
    return Observable.concat(
      [
        Creators.selectFamilyAndDefaultBaby(familyId),
        showAwesomeModal(family.name),
      ],
      Observable.of('')
        .delay(3000)
        .mergeMap(() => [Modals.Creators.closeModal()]),
    );
  }));

