import styled from 'styled-components';
import Formsy from 'formsy-react-2';
import { colors } from 'styles';

export const View = styled.div.attrs({ className: "auth-view" })`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row: 2;

  width: 100%;
  max-width: 410px;
  padding: 20px;
  box-sizing: border-box;
  margin: auto auto 30% auto;
  z-index: 1;

  .new-input {
    label {
      display: block;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 5px;
    }

    input {
      border: 1px solid #979797;
      border-radius: 4px;
      height: 50px;

      &::placeholder {
        font-size: 14px;
        color: #979797;
        letter-spacing: 0;
        line-height: 21px;
      }
    }

    .input-error {
      background: no-repeat;
      color: red;
      margin: 10px 0px 0;
      padding: 0;
      font-size: 12px;
    }
  }
`;

export const Title = styled.span`
  font-size: 28px;
  color: #0086d8;
  letter-spacing: 0;
  text-align: right;
  line-height: 47px;
`;

export const Subtitle = styled.span`
  font-family: Helvetica;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  text-align: right;
`;

export const Form = styled(Formsy.Form)`
  width: 100%;
  margin-top: 20px;
`;

export const InlineButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;
`;

export const Note = styled.span`
  display: block;
  margin: 10px;

  color: ${colors.shadeMediumDark};
  font-size: 15px;
  letter-spacing: -0.33px;
  text-align: center;

  a {
    font-size: 15px;
  }
`;

export const OptionButton = styled.button`
  font-size: 14px;
  color: #0086d8;
  letter-spacing: 0;
  border: none;
  background: none;
  text-decoration-line: underline;
  margin-top: 20px;
  cursor: pointer;
`;

export const Link = styled.button`
  font-size: 14px;
  color: #0086d8;
  letter-spacing: 0;
  border: none;
  background: none;
  text-decoration-line: underline;
  margin-top: 20px;
`;

export const Separator = styled.hr`
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
  border: 0;
  margin: 30px 0 10px;
`;
