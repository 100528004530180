
import { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bindActionCreators as bind } from 'redux';
import { compose } from 'ramda';
import { Creators } from '../data/ducks/auth';


export const propTypes = {
  onLogin: PropTypes.func,
  onSignup: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

const withAuth = WrappedComponent =>
  class AuthLogic extends Component {
    componentWillMount() {
      this.ClonedAuth = createElement(WrappedComponent);
    }

    extraProps = () => {
      const { loading, login, signup, error, ...rest } = this.props;
      return {
        ...rest,
        onLogin: login,
        onSignup: signup,
        // onFacebookSignUpRequested: null,
        // onRecoverPasswordRequeste d: null,
        loading,
        error,
      };
    }

    render = () => createElement(WrappedComponent, this.extraProps());

  // checkLoginStatus = () => {
  //   const that = this;
  //   if (window.FB) {
  //     window.FB.getLoginStatus((response) => {
  //       that.statusChangeCallback(response);
  //     });
  //   } else {
  //     console.log('%c There is no FB SKD: ', 'background: #332167; color: #b3d1f6; font-size: 24px');
  //   }
  // }

  // statusChangeCallback = (response) => {
  //   this.setState({ status: response.status });
  //   console.log('%c FB STATUS: ' + this.state.status, 'background: #332167; color: #b3d1f6; font-size: 24px');

  //   if (this.state.status === 'connected') {
  //     // Redirect to DAP
  //   } else if (this.state.status === 'not_authorized') {
  //     console.log('User is logged in on fb but not in kinedu');
  //   } else {
  //     console.log('User is not logged in');
  //   }
  // }

  // onFacebookSignUpRequested = () => {
  //   console.log('onFacebookSignUpRequested');
  // }

  // onFacebookLogout = () => {
  //   console.log('onFacebookLogout');
  //   window.FB.logout((res) => {
  //     console.log('res', res);
  //   });
  // }
  };

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error,
  isAuthenticated: state.auth.authenticated,
});

const mapDispatchToProps = dispatch => bind({
  login: Creators.login,
  signup: Creators.signup,
}, dispatch);


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuth,
);
