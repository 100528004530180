import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Selecter } from 'lego-dist';

import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from 'values/i18n';

import { Creators } from 'data/ducks/modals';

import TextInput from 'components/shared/TextInput';
import { Flat } from 'components/themes/TextInput';
import Button from 'components/shared/buttons/MainButton';

import { Providers, Types as AuthTypes } from 'values/auth';
import withAuth, { propTypes as withAuthProptypes } from 'hoc/withAuth';
import { VALIDATIONS_MESSAGES } from 'values/validations';
import EventReporter, { Events } from 'lib/EventReporter';
import { KEY_LOCALE } from 'shared-values';
import { colors } from 'styles';
import {
  Form, InlineButtons, OptionButton, Note, Separator, Subtitle, Title, View, Label,
} from './styles/form';
import { WrapperSelecter } from './styles';
import i18n from '../../../../i18n';


i18n.changeLanguage(window.localStorage.getItem(KEY_LOCALE) || 'en');


const authTypes = {
  [AuthTypes.SIGNUP]: {
    title: 'WELCOME_TO_KINEDU',
    subtitle: 'LETS_BEGIN',
    buttonLabel: 'SIGNUP',
  },
  [AuthTypes.LOGIN]: {
    title: 'WELCOME_BACK',
    subtitle: 'LOGIN_EXISTING_ACC',
    buttonLabel: 'LOGIN',
  },
};

class FormSignupComponent extends Component {
  constructor(props) {
    super(props);
    this.handleOnValid = this.handleOnValid.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnChangeLang = this.handleOnChangeLang.bind(this);
    this.state = {
        values: {
        password: '',
        confirmPassword: '',
        email: '',
        lang: {
          id: window.localStorage.getItem(KEY_LOCALE) || 'en',
          value: window.localStorage.getItem(KEY_LOCALE) || 'en'
        }
      },
      formIsValid: false
    };
  }

  // setLang = lang => i18n.changeLanguage(lang);

  handleOnValid(formIsValid) {
    this.setState({ formIsValid });
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.keyPress, false);
    EventReporter.view(Events.SIGNUP_SCREEN());
  }

  componentDidUpdate = (prevProps, prevState) => {
    // EventReporter.view(Events.SIGNUP_SCREEN());
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.keyPress, false);
  }

  onForgotPasswordClicked = () => {
    this.props.openModal({ name: 'ForgotPasswordModal' });
    EventReporter.action(Events.FORGOT_PASSWORD());
  }

  handleOnSubmit = () => {
    const variant = localStorage.getItem('exp_variant');

    if(this.props.onSignup)
      this.props.onSignup({ provider: Providers.EMAIL, ...this.state.values, cohort: variant });
  };

  toggleSubmit = val => this.setState({ submitEnabled: val });

  handleOnClickGoLogin = () => {
    const { onAuthTypeChange } = this.props;
    if(onAuthTypeChange)
      onAuthTypeChange(AuthTypes.LOGIN);
  }

  onChangeValues = (values) => {
    // this.setState({ values });

    this.setState({ values: { ...values, lang: this.state.values.lang } });
  }

  handleOnChangeLang = (value) => {
    EventReporter.action(Events.SELECT_LANGUAGE(value.value || 'en'));
    i18n.changeLanguage(value.value || 'en');
    window.localStorage.setItem(KEY_LOCALE, value.value || 'en')
    this.setState({ values: { ...this.state.values, ...{ lang: value } } })
  }

  render() {
    const { t, error } = this.props;
    const lngs = [{
      label: t('MODAL_SETTINGS.SPA'),
      id: 'es',
      value: 'es',
      name: t('MODAL_SETTINGS.SPA'),
    },
    {
      label: t('MODAL_SETTINGS.ENG'),
      id: 'en',
      value: 'en',
      name: t('MODAL_SETTINGS.ENG'),
    },
    {
      label: t('MODAL_SETTINGS.POR'),
      id: 'pt',
      value: 'pt',
      name: t('MODAL_SETTINGS.POR'),
    }];

    return (
      <View>
        <Title
          size={30}
          color={colors.kinedu}
          align="center"
          bold
        >
          {t(`common:AUTH.${authTypes.signup.title}`)}
        </Title>
        <Subtitle>{t(`common:AUTH.${authTypes.signup.subtitle}`)}</Subtitle>
        <Separator />
        <Form
          onValid={() => this.handleOnValid(true)}
          onInvalid={() => this.handleOnValid(false)}
          onChange={this.onChangeValues}
          onValidSubmit={this.handleOnSubmit}
        >
          <TextInput
            className="new-input"
            type="email"
            name="email"
            label={t('common:PLACEHOLDERS.EMAIL')}
            placeholder={t('common:PLACEHOLDERS.EXAMPLE_EMAIL')}
            theme={Flat}
            errorText={error && t(`validations:${error}`)}
            validationErrors={{
              isDefaultRequiredValue: t(`validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`),
              isEmail: t(`validations:${VALIDATIONS_MESSAGES.EMAIL_INVALID}`),
            }}
            validations="isEmail"
            value={this.state.values.email}
            required
          />
          <TextInput
            className="new-input"
            type="password"
            name="password"
            label={t('common:PLACEHOLDERS.PASSWORD')}
            placeholder={t('common:PLACEHOLDERS.TYPE_YOUR_PASSWORD')}
            theme={Flat}
            validationErrors={{
              isDefaultRequiredValue: t(`validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`),
            }}
            autoComplete="new-password"
            value={this.state.values.password}
            required
          />

          <WrapperSelecter>
            <Label>
            {t('MODAL_SETTINGS.LANG')}
            </Label>
            <Selecter
              label={t('MODAL_SETTINGS.LANG')}
              name="lngs"
              placeholder={t('MODAL_SETTINGS.LANG')}
              autoOpen={false}
              multiple={false}
              closeClickOutside={false}
              data={lngs}
              value={this.state.values.lang}
              checkbox={false}
              onChange={this.handleOnChangeLang}
              required
            />
          </WrapperSelecter>

          <InlineButtons>
            <Button
              type="submit"
              color="green"
              disabled={!this.state.formIsValid}
              rounded
              small
              maxWidth={300}
            >
              {t(`common:AUTH.${authTypes.signup.buttonLabel}`)}
            </Button>
          </InlineButtons>
          <Note>
            <span style={{ marginRight: '5px' }}>{t('ob:AUTH.ALREADY_HAVE_ACC')}</span>
            <OptionButton type="button" onClick={() => this.handleOnClickGoLogin()} underlined>{t('ob:AUTH.LOGIN')}</OptionButton>
          </Note>
        </Form>
      </View>
    );
  }
}

FormSignupComponent.propTypes = {
  ...withAuthProptypes,
  onAuthTypeChange: PropTypes.func,
};

// TODO: Check why is making this value undefined first
FormSignupComponent.defaultProps = {
  formIsValid: false,
  values: {
    password: '',
    confirmPassword: '',
    email: '',
    lang: {
      id: window.localStorage.getItem(KEY_LOCALE) || 'en',
      value: window.localStorage.getItem(KEY_LOCALE) || 'en'
    }
  },
  onAuthTypeChange: () => {},
};



const mapDispatchToProps = dispatch => bind({
  openModal: Creators.openModal,
}, dispatch);

export default compose(
  connect(() => ({}), mapDispatchToProps),
  withTranslation([Namespaces.MODALS, Namespaces.COMMON, Namespaces.VALIDATIONS]),
  withAuth,
)(FormSignupComponent);
