import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { path } from 'ramda';

export const { Types, Creators } = createActions({
  fetchUserDetailRequested: null,
  fetchUserDetailSuccess: ['user'],
  fetchPendingInvitesRequested: null,
  fetchPendingInvitesSuccess: ['invites'],
  editUser: {
    id: undefined,
    name: undefined,
    lastname: undefined,
    locale: undefined,
    birthday: undefined,
    gender: undefined,
    avatar: undefined,
    relationship: undefined,
  },
  updateOnlyUser: {
    id: undefined,
    name: undefined,
    lastname: undefined,
    locale: undefined,
    birthday: undefined,
    gender: undefined,
    avatar: undefined,
    relationship: undefined,
  },
  createFamilyAndUpdateUser: {
    id: undefined,
    name: undefined,
    lastname: undefined,
    locale: undefined,
    birthday: undefined,
    gender: undefined,
    avatar: undefined,
    relationship: undefined,
  },
  updateUserSuccess: ['user'],
  updateUserError: null,

  setDeleteFamilyFlag: null,
  deleteUserAccountRequested: null,
});

const INITIAL_STATE = Immutable({ name: null, age: null });

const getUserData = state => path(['user'], state) || {};

export const Selectors = {
  getUserData,
};

const Handlers = {
  [Types.FETCH_USER_DETAIL_SUCCESS]: (state, { user }) => state.replace(user),
  [Types.UPDATE_USER_SUCCESS]: (state, { user }) => state.replace(user),
  [Types.SET_DELETE_FAMILY_FLAG]: state => state.merge({ myFamily: null }),
  // [Types.DELETE_USER_ACCOUNT_REQUESTED]: state => state.merge({ })
};

export const Reducer = createReducer(INITIAL_STATE, Handlers);
