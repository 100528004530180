import { css } from 'styled-components';
import { breakPoints } from '../../breakPoints';

const CalendarHeader = {
  DropdownWrapper: css` `,
  Header: css` `,
  WeeksCounterText: css` `,
  NavigationButton: css` `,
  Padding: css` `,
  CalendarHeaderNavigationButton: css`
  color: #90A2B0;
  `,
  CalendarHeaderCurrentDate: css`
  color: #0086D8;
  text-transform: capitalize;
  `,
  Wrapper: css` `,
  ActionButtons: css` `,
  SymbologyTitle: css` `,
  SymbologyList: css` `,
  HelperButton: css` `,
  TooltipItem: css` `,
  SymbolHeader: css` `,
  CloseIcon: css` `,
  CalendarHeaderWrapper: css`
    display: none;
    ${breakPoints.tablet} {
      display: block;
  }
  `,
};

export default CalendarHeader;
