import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Title from '../../shared/Title';
import { colors, breakPoints } from '../../../styles';
import { SkillsImages } from '../../../values/progress';
import EventReporter, { Events } from '../../../lib/EventReporter';

const SKILL_DEV_MEMORY = 17;

const StyledMilestonesCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 20px 0;
  padding: 0 0 20px;
  background-color: ${colors.cognitive};
  border-radius: 4px;
  box-shadow: 0px 30px 40px -30px ${colors.shadeDark};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  
  ${breakPoints.smallTablet} {
    width: 100%;
    flex-direction: column;
  }

  :hover {
    background-color: ${colors.cognitiveDark20};
  }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${breakPoints.smallTablet} {
      text-align: center;
    }
`;
const Image = styled.img.attrs(({ src }) => src)`
  width: 250px;
  margin: -6px -60px;

  ${breakPoints.smallTablet} {
    margin: -6px 0;
  }
`;

const Subtitle = styled.span`
  color: white;
`;

const MilestonesCard = ({
  style, history, skillId, t,
}) => (
  <StyledMilestonesCard
    style={style}
    onClick={() => {
      EventReporter.action(Events.MILESTONES_SKILLS({
        source: 'progress',
      }));
      history.push(`/milestones?skill_id=${skillId}&context=milestones`);
    }}
  >
    <Image src={SkillsImages[SKILL_DEV_MEMORY]} />
    <TitleContainer>
      <Title color="white" size={16} bold>{t('SKILL_DETAIL.UPDATE_MILES_MILESTONES')}</Title>
      <Subtitle>{t('SKILL_DETAIL.TAP_HERE_TO_UPDATE')}</Subtitle>
    </TitleContainer>
  </StyledMilestonesCard>
);

export default withRouter(MilestonesCard);
