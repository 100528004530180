import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors, breakPoints } from '../../styles';

export const Default = {
  container: css`
        display: flex;
    `,
  list: css`
    list-style: none;
    padding: 0;
    margin: 0;
  `,
  item: css`
    display: flex;;
    margin: 10px 0;

    color: ${colors.white};
    color: ${({ textColor }) => textColor};
    font-size: 16px;
    font-size: ${({ textSize }) => textSize};
    letter-spacing: -0.79px;

    ${({ striked }) => striked && css`
      text-decoration: line-through;
    `};
  `,
  text: css`
    margin-left: 10px;
    display: flex;
    align-items: center;
  `,
  icon: {
    flexShrink: 0,
    fill: colors.cognitive,
    height: 25,
    width: 25,
  },
  striked: {
    opacity: 0.5,
    fill: colors.cognitive,
    flexShrink: 0,
    height: 25,
    width: 25,
  },
};

export const Progress = {
  container: css`
    display: flex;
    margin: 10px 20px;
  `,
  list: css`
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
  `,
  item: css`
    display: flex;
    margin: 25px 0;

    color: ${colors.shadeDark};
    font-size: 16px;
    font-size: ${({ textSize }) => textSize};
    letter-spacing: -0.79px;

    ${({ striked }) => striked && css`
      text-decoration: line-through;
    `};

    &:first-child {
      margin: 0;
    }
  `,
  text: css`
    margin-left: 10px;
    display: flex;
    align-items: center;
  `,
  icon: {
    flexShrink: 0,
    fill: colors.physical,
    height: 31,
    width: 31,
  },
  striked: {
    opacity: 0.5,
    fill: colors.physical,
    flexShrink: 0,
    height: 31,
    width: 31,
  },
};

export const Milestones = {
  container: css`
    display: flex;
  `,
  list: css`
    list-style: none;
    padding: 0;
    margin: 5px 0 15px;
  `,
  item: css`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 20px 0;

    color: ${colors.shadeDark};
    font-size: ${({ textSize }) => textSize};
    text-align: left;

    ${({ striked }) => striked && css`
      text-decoration: line-through;
    `};

    &:first-child, &:last-child {
      margin: 0;
    }

    ${breakPoints.smallTablet} {
      margin: 40px 0;
    }
  `,
  text: css`
    margin: 0px 0px 0px 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    color: ${colors.shadeDark};
    letter-spacing: -0.47px;
    line-height: 16px;

    ${breakPoints.smallTablet} {
      font-size: 16px;
      letter-spacing: -0.7px;
      line-height: 24px;
    }
  `,
  icon: {
    flexShrink: 0,
    fill: colors.kinedu,
    height: 31,
    width: 31,
  },
  striked: {
    opacity: 0.5,
    fill: colors.physical,
    flexShrink: 0,
    height: 31,
    width: 31,
  },
};

export const PricesBenefits = {
  container: css`
    display: flex;
  `,
  list: css`
    list-style: none;
    padding: 0;
    margin: 5px 0 15px;
  `,
  item: css`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 20px 0;

    color: ${colors.shadeDark};
    font-size: ${({ textSize }) => textSize};
    text-align: left;

    ${({ striked }) => striked && css`
      text-decoration: line-through;
    `};

    &:first-child, &:last-child {
      margin: 0;
    }

    ${breakPoints.smallTablet} {
      margin: 20px 0;
    }
  `,
  text: css`
    margin: 0px 0px 0px 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    color: ${colors.shadeDark};
    letter-spacing: -0.47px;
    line-height: 16px;

    ${breakPoints.smallTablet} {
      font-size: 14px;
      letter-spacing: -0.7px;
      line-height: 24px;
    }
  `,
  icon: {
    flexShrink: 0,
    fill: colors.kinedu,
    height: 31,
    width: 31,
  },
  striked: {
    opacity: 0.5,
    fill: colors.physical,
    flexShrink: 0,
    height: 31,
    width: 31,
  },
};

export const ThemeShape = PropTypes.shape({
  container: PropTypes.array,
});
