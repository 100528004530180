import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators as bind, compose } from 'redux';
import { push } from 'connected-react-router';
import { path } from 'ramda';
import { parse } from 'query-string';
import { withTranslation } from 'react-i18next';

import { PAYMENT_PATH_PP_KEY, QUERY_PARAM_TO_SHOW_MODAL } from 'components/premiumProcessModal/values';
import { Namespaces } from '../values/i18n';

import { Creators, Selectors } from '../data/ducks/progress';
import { Families, Modals } from '../data/ducks';
import EventReporter, { Events, EventProviders } from '../lib/EventReporter';
import { formatTruthyAnswers, equalAt, getSkillType } from '../lib/utils';
import { REGEX_FIRST_FOLDER_OF_URL, ModalNames } from '../shared-values';

const MAX_AGE = 48;

class ProgressContainer extends Component {
  state = {
    selectedMonth: null,
    activeArea: 0,
    activeSkill: 0,
    showLearnMore: false,
  };

  componentDidMount = () => {
    this.setBabyAge(this.props);
    this.getBabyProgress(this.props);
    this.getSkillDetails(this.props);
    this.getMilestones(this.props);
    this.setEvents(this.props);
    this.showPendingSkillMessage();
    this.tirggerEvents();
  }

  componentWillReceiveProps = (newProps) => {
    if (!equalAt(this.props, newProps, ['skillId'])) {
      this.getMilestones(newProps);
      this.getSkillDetails(newProps);
    }
  }

  tirggerEvents = () => {
    const getSource = ({ state }) => {
      const { from } = state || {};
      if (from?.includes('/tap')) {
        return 'babyPhase';
      }
      if (from?.includes('section=play')) {
        return 'playDap';
      }
      return 'navigationBar';
    };

    const source = getSource(this.props.history.location);
    EventReporter.view(Events.S_PROGRESS_HOME(source));
  }

  onMilestonesChanged = (answer) => {
    this.setState({ milestonesChange: true, answer });
  }

  getBabyProgress = ({ activeBaby, fetchBabyProgress, skillId, urlContext }) => {
    if (urlContext !== 'feed' && skillId) return;
    fetchBabyProgress(activeBaby.id);
    EventReporter.view(Events.VIEW_PROGRESS(), [EventProviders.GA]);
  }

  getSkillDetails = ({
    activeBaby = {}, fetchSkillDetail, skillId, fetchSkill,
  }) => {
    if (!skillId) return;
    const { id: babyId } = activeBaby;
    fetchSkillDetail(babyId, skillId);
    fetchSkill({ babyId, skillId });
  }

  getMilestones = ({ fetchMasterSkillMilestones, skillId, activeBaby }) => {
    if (!skillId) return;
    fetchMasterSkillMilestones(skillId, activeBaby.id);
  }

  getBabyAge = (devAge, age, isPremature, maxAge) => {
    if (isPremature) {
      if (devAge < 0) {
        return 0;
      }
      return devAge;
    } if (age > maxAge) {
      return maxAge;
    }
    return age;
  }

  setBabyAge = ({ activeBaby }) => {
    const {
      age_in_months: age,
      developmental_age_in_months: devAge,
      is_premature: isPremature,
    } = activeBaby;
    const babyAge = this.getBabyAge(devAge, age, isPremature, MAX_AGE);
    this.setState({ selectedMonth: babyAge, progressBabyAge: babyAge });
  }

  setMasterSkill = ({
    baby = {}, skill, activeMilestones, completedMilestones, showDS,
  }) => {
    this.props.setSkillMastered({
      skill,
      baby,
      showDS,
      activeMilestones,
      completedMilestones,
    });
  }

  setSelectedMonth = selectedMonth => this.setState({ selectedMonth });

  setSelectedArea = activeArea => this.setState({ activeArea });

  setEvents = (props) => {
    const planType = props?.isPremium === true ? 'premium' : 'freemium';
    if (!props.skillId && props.context === 'progress') {
      EventReporter.view(Events.SKILLS_HOME({ plan_type: planType }));
    } else if (props.context === 'milestones') {
      EventReporter.view(Events.MILESTONES_HOME());
    }
  }

  filterSkillsByAge = (list = [], age) => list.filter((item) => {
    const isInRange = age >= item.minAge && age <= item.maxAge;
    if (isInRange || age === '') return item;
    return false;
  });

  filterSkillsByArea = (list = [], areaId) => {
    if (areaId > 0) {
      return list.filter(s => s.area_id === areaId) || [];
    }
    return list.filter(s => s?.progress_status === 'current') || [];
  };

  goToPrices = ({ source }) => {
    EventReporter.action(Events.TAP_CALL_TO_ACTION({ source }));

    window.localStorage.setItem(PAYMENT_PATH_PP_KEY, source);

    const queryParams = parse(window.location.search);
    queryParams[QUERY_PARAM_TO_SHOW_MODAL] = true;

    this.props.history.push({
      search: new URLSearchParams({
        ...queryParams,
      }).toString(),
    }, { source });
  }

  gotToPendingSkills = (areaId, skillId) => {
    const { activeBaby, fetchProgressPendingSkill, context } = this.props;
    fetchProgressPendingSkill({
      babyId: activeBaby.id,
      areaId,
      skillId,
      context,
    });
  }

  goToCatalog = (areaId, skillId, type) => {
    this.props.history.push(`/catalog?area_id=${areaId}&skill_id=${skillId}&section=${type}`);
  }

  openContent = ({ type, id, source }) => {
    const { context } = this.props;
    this.props.push(`/${type}/${id}?context=${context}`);
  }

  showPendingSkillMessage = () => {
    const notNow = sessionStorage.getItem('hidePendingSkillsMessage');
    this.setState({ notNow });
  }

  hidePendingSkillsMessage = () => {
    sessionStorage.setItem('hidePendingSkillsMessage', true);
    this.setState({ notNow: true });
  }

  goToSkillMilestones = (skillId) => {
    this.props.push(`/milestones?skill_id=${skillId}&context=milestones`);
    this.setState({ activeSkill: skillId }, () => {
      const { id: babyId } = this.props.activeBaby;
      this.props.fetchSkill({ babyId, skillId });
    });
  }

  updatePendingSkills = () => {
    const { id: skillId, area_id: areaId } = this.props.firstCurrentSkill;
    this.gotToPendingSkills(areaId, skillId);
  }

  answerMilestones = ({ skill, values, showDS = true }) => {
    const { answerAssessmentMilestones, activeBaby } = this.props;
    answerAssessmentMilestones({
      answers: formatTruthyAnswers(values),
      baby: activeBaby,
      skill,
      showDS,
    });
    this.resetMilestonesChanges();
  }

  toggleLearnMore = () => {
    this.setState({ showLearnMore: !this.state.showLearnMore });
  }

  shouldBeforeYouGoTrigger = () => {
    const { milestonesChange } = this.state;
    return milestonesChange;
  }

  showBeforeYouGoModal = (action) => {
    const { answer } = this.state;
    this.props.openModal({
      ...ModalNames.BEFORE_YOU_GO_MODAL,
      data: {
        action,
        saveAnswers: () => this.answerMilestones(answer),
        baby: this.props.activeBaby,
      },
    });
    this.resetMilestonesChanges();
  }

  resetMilestonesChanges = () => {
    this.setState({ milestonesChange: false });
  }

  render() {
    return (
      <Fragment>
        {this.props.children({
          ...this.state,
          ...this.props,
          filterSkillsByAge: this.filterSkillsByAge,
          setSelectedMonth: this.setSelectedMonth,
          goToPrices: this.goToPrices,
          gotToPendingSkills: this.gotToPendingSkills,
          openContent: this.openContent,
          goToCatalog: this.goToCatalog,
          setMasterSkill: this.setMasterSkill,
          filterSkillsByArea: this.filterSkillsByArea,
          setSelectedArea: this.setSelectedArea,
          hidePendingSkillsMessage: this.hidePendingSkillsMessage,
          goToSkillMilestones: this.goToSkillMilestones,
          updatePendingSkills: this.updatePendingSkills,
          answerMilestones: this.answerMilestones,
          toggleLearnMore: this.toggleLearnMore,
          showBeforeYouGoModal: this.showBeforeYouGoModal,
          onMilestonesChanged: this.onMilestonesChanged,
          shouldBeforeYouGoTrigger: this.shouldBeforeYouGoTrigger,
        })}
      </Fragment>
    );
  }
}

ProgressContainer.propTypes = {
  children: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  activeBaby: PropTypes.object,
  push: PropTypes.func,
  setSkillMastered: PropTypes.func,
  fetchProgressPendingSkill: PropTypes.func,
  firstCurrentSkill: PropTypes.object,
  context: PropTypes.string,
};

ProgressContainer.defaultProps = {
  history: {},
  activeBaby: {},
  firstCurrentSkill: {},
  push: () => {},
  setSkillMastered: () => {},
  fetchProgressPendingSkill: () => {},
  context: '',
};

const mapStateToProps = (state, props) => {
  const pathname = path(['location', 'pathname'], props);
  const { skill_id: skillId, context: urlContext } = parse(path(['location', 'search'], props));
  const baby = Families.Selectors.activeBaby(state) || {};
  const mainSection = (pathname.match(REGEX_FIRST_FOLDER_OF_URL) || ['', ''])[1];
  const skills = Selectors.getSkills(state);
  const firstCurrentSkill = skills.filter(skill => skill?.progress_status === 'current' && getSkillType(skill) === 'pending')[0];

  return ({
    skills,
    skillId,
    firstCurrentSkill,
    activeBaby: Families.Selectors.activeBaby(state),
    activeSkill: Selectors.getActiveSkill(state),
    milestonesSkills: path(['progress', baby.id, 'skills'], state) || {},
    numberOfPendingSkills: Selectors.getPendingSkillsByArea(state, 0).length,
    activeSkillDetail: Selectors.getSkillById(state, skillId),
    isPremium: Families.Selectors.activeFamilyIsPremium(state),
    loading: Selectors.getLoading(state),
    masterMilestones: Selectors.getMilestones(state, skillId),
    context: mainSection,
    urlContext,
  });
};

const mapDispatchToProps = dispatch => bind({
  openModal: Modals.Creators.openModal,
  fetchBabyProgress: Creators.fetchBabyProgressRequested,
  fetchSkill: Creators.fetchSkillRequested,
  fetchSkillDetail: Creators.fetchSkillDetailRequested,
  fetchProgressPendingSkill: Creators.progressPendingSkill,
  fetchMasterSkillMilestones: Creators.fetchMasterMilestonesRequested,
  answerAssessmentMilestones: Creators.answerAssessmentMilestonesRequested,
  setSkillMastered: Creators.setSkillMastered,
  push,
}, dispatch);

export default compose(
  withRouter,
  withTranslation([Namespaces.PROGRESS, Namespaces.COMMON]),
  connect(mapStateToProps, mapDispatchToProps),
)(ProgressContainer);
