import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { colors, breakPoints } from '../../styles';

import Notes from '../../images/Notes.svg';
import Gears from '../../images/Gears.svg';
import Calendar from '../../images/Calendar.svg';
import Milestones from '../../images/Milestones.svg';
import Blocks from '../../images/Blocks.svg';
import Icon from '../shared/Icon';
import { Namespaces } from '../../values/i18n';
import Button from '../shared/buttons/MainButton';

const Image = styled.img`
  display: block;
  width: auto;
  height: 110px;
  margin: auto;
`;

const Container = styled.div`
  max-width: 900px;
  background-color: ${colors.white};

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${breakPoints.smallTablet} {
    margin: auto;
  }
`;

const Header = styled.div`
  display: block;
  color: ${colors.white};
  background-color: ${colors.physical};
  padding: 10px 30px;

  font-size: 22px;
  font-weight: 500;

  ${breakPoints.smallTablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 10px 90px;
  }
`;

const Title = styled.h2`
  color: ${colors.white};
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  letter-spacing: -1.68px;

  margin: 20px 0 10px;

  ${breakPoints.smallTablet} {
    font-size: 35px;
  }

`;

const SubTitle = styled.h4`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 0 0 10px;

  ${breakPoints.smallTablet} {
    font-size: 18px;
  }
`;

const sub2 = {
  color: colors.kinedu,
  fontSize: '18px',
  fontWeight: 500,
  margin: '10px 0 30px',
  letterSpacing: '-0.96px',
};

const Content = styled.div`
  display: block;
  padding: 30px;

  ${breakPoints.smallTablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ItemsContainer = styled.div`
  display: block;

  ${breakPoints.smallTablet} {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const InfoBlock = styled.div`
  display: block;
  max-width: 170px;
  margin: 20px auto;

  ${breakPoints.smallTablet} {
    margin: 20px;
  }
`;

const Description = styled.p`
  color: ${colors.darkGray};
  font-size: 14px;
  text-align: center;
`;

const Arrow = () => {
  const Wrapper = styled.span`
    display: flex;
    align-items: center;
    width: 35px;
    margin: auto;

    .right {
      display: none;
    }

    ${breakPoints.smallTablet} {
      padding: 20px;
      margin: 0;

      .right {
        display: block;
      }
      .down {
        display: none;
      }

      &:nth-of-type(3) {
        display: none;
      }
    }
  `;

  return (
    <Wrapper className="green-arrow">
      <Icon icon="arrow-right" size={33} color={colors.cognitive} className="right" />
      <Icon icon="arrow-down" size={33} color={colors.cognitive} className="down" />
    </Wrapper>
  );
};

const WelcomeToKinedu = ({
  baby, twins, onClick, t,
}) => {
  window.scrollTo(0, 0);
  return (
    <Container contentLabel="asdf" width="854" isOpen fullscreenBreakpoint="600">
      <Header>
        <Title>{t('WELCOME.HEADER_TITLE')}</Title>
        <SubTitle>{t('WELCOME.HEADER_SUBTITLE')}</SubTitle>
      </Header>
      <Content>
        <SubTitle style={sub2}>
          {t('WELCOME.BODY_TITLE')}
        </SubTitle>
        <ItemsContainer>
          <InfoBlock>
            <Image src={Notes} />
            <Description>
              {t('WELCOME.POINT_1', { context: twins ? 'twins' : null })}

            </Description>
          </InfoBlock>
          <Arrow />
          <InfoBlock>
            <Image src={Gears} />
            <Description>
              {t('WELCOME.POINT_2', { context: twins ? 'twins' : null })}

            </Description>
          </InfoBlock>
          <Arrow />
          <InfoBlock>
            <Image src={Calendar} />
            <Description>
              {t('WELCOME.POINT_3', { context: twins ? 'twins' : null })}

            </Description>
          </InfoBlock>
          <Arrow />
          <InfoBlock>
            <Image src={Milestones} />
            <Description>
              {t('WELCOME.POINT_4', { context: twins ? 'twins' : null })}

            </Description>
          </InfoBlock>
          <Arrow />
          <InfoBlock>
            <Image src={Blocks} />
            <Description>
              {t('WELCOME.POINT_5', {
                context: twins ? 'twins' : null,
                name: baby.name,
              })}
            </Description>
          </InfoBlock>
        </ItemsContainer>
        <Button
          color="green"
          style={{ maxWidth: '400px', margin: '20px auto 0' }}
          onClick={onClick}
        >
          {t('WELCOME.BUTTON')}
        </Button>
      </Content>
    </Container>
  );
};

WelcomeToKinedu.propTypes = {
  onClick: PropTypes.func,
};

WelcomeToKinedu.defaultProps = {
  onClick: () => { },
};

export default withTranslation(Namespaces.ASSESSMENTS)(WelcomeToKinedu);
