import { css } from 'styled-components';

const EventDescriptionModal = {
  EventDetailsModal: css`
  overflow-y: hidden;
  height: 100%;

  ${({ theme }) => theme.breakPoints.tablet} {
    height: 100%;
  }

  ${({ theme }) => theme.breakPoints.desktop} {
    height: 721px;
  }


  `,
  ModalContainer: css` 
  padding: 20px;
  overflow-y: auto;
  height: calc(100% - 85px);

  /* FIREFOX */
  scrollbar-color: #acc2d3 #ed005e1a;
  scrollbar-width: thin;
  /* CHROME */
  &::-webkit-scrollbar {
    width: 7px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #acc2d3;
    border-radius: 10px;
  }
  `,
};

export default EventDescriptionModal;
