import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors, breakPoints } from '../../styles';

export const Default = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  option: css`
    display: inline-block;
    font-size: 18px;
    padding: 9px 0;
    width: 96px;
    color: #E1E1E1;
    background-color: #FFF;
    border: 2px solid #E1E1E1;
    text-align: center;

    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:nth-last-of-type(1) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &.selected {
      color: #FFF;
      background-color: #1EACDF;
      border-color: #157A9F;
    }
  `,
  input: css`
    display: none;
  `,
};

export const Kinedu5 = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  option: css`
    display: inline-block;
    padding: 9px 0;
    width: 96px;


    color: ${colors.kinedu};
    background-color: ${colors.shadeLight};
    border: 1px solid ${colors.shadeMedium};

    font-weight: 500;
    font-size: 20px;
    letter-spacing: -0.45px;
    text-align: center;

    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:nth-last-of-type(1) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.selected {
      color: ${colors.shadeLight};
      background-color: ${colors.kinedu};
      border-color: ${colors.kinedu};
    }
  `,
  input: css`
    display: none;
  `,
};

export const OBCircularOptions = {
  container: css``,
  option: css`
    position: relative;
    display: inline-block;
    box-sizing: border-box;

    margin: 0 auto 60px;
    width: 122px;
    height: 122px;
    background-position-y: unset;
    background-size: 130px;
    background-repeat: no-repeat;
    background-position-x: center;

    color: #FFFF;
    background-color: #E2E8EC;
    border-radius: 100px;
    
    transition: opacity 0.5s;
    cursor: pointer;
    box-shadow: 0px 3px 20px rgba(0,0,0,0.5);
    border: none;

    
    &.selected {
      background-color: #98D7EF;
    }
    
    ${breakPoints.smallTablet} {
      width: 145px;
      height: 145px;
      margin: 0 40px 60px; 
      background-position-x: right;
      background-size: auto;
    }

    span {
      position: absolute;
      bottom: -50px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      height: 30px;

      ${breakPoints.smallTablet} {
        font-size: 14px;
      }
    }
  `,
  input: css`
    display: none;
  `,
};
export const CircularOptions = {
  container: css``,
  option: css`
    position: relative;
    display: inline-block;
    box-sizing: border-box;

    width: 145px;
    height: 145px;
    margin: 0 40px 60px;

    color: #FFF;
    background-color: ${colors.silver};
    border-radius: 100px;
    
    transition: opacity 0.5s;
    cursor: pointer;
    opacity: 0.5;
    
    &.selected {
      opacity: 1;
      box-shadow: 0px 3px 20px rgba(0,0,0,0.5);
      background-color: ${colors.silver};
    }

    span {
      position: absolute;
      bottom: -50px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      height: 30px;
    }
  `,
  input: css`
    display: none;
  `,
};

export const Ghost = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  label: css`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
  `,
  option: css`
    display: inline-block;
    padding: 4px 10px;
    margin: 5px;

    height: 40px;
    box-sizing: border-box;

    background-color: rgba(255,255,255,0.2);
    border: 1px solid #FFF;
    border-radius: 4px;

    color: rgba(255,255,255,0.7);
    font-size: 24px;
    font-weight: bold;
    text-align: center;

    transition: background-color 0.5s;
    cursor: pointer;

    &.selected {
      color: #000;
      background-color: #FFF;
      border-color: #FFF;
      mix-blend-mode: lighten;
    }
  `,
  input: css`
    display: none;
  `,
};

export const RadioChecks = {
  container: css`
  `,
  option: css`
    display: flex;
    align-items: center;

    padding: 4px 10px;
    margin: 5px;

    height: 40px;
    box-sizing: border-box;

    background-color: transparent;
    border: 1px solid #FFF;
    border-radius: 4px;

    color: ${colors.dustyGray};
    font-size: 16px;
    font-weight: 500;

    transition: background-color 0.5s;
    cursor: pointer;

    span {
      margin-left: 10px;
    }
  `,
  input: css`
    display: none;
  `,
};

export const ColoredOptions = {
  container: css`
    .label-secondary {
      width: 100%;
      padding-right: 0;
    }
  `,
  option: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 11px 10px;
    margin: 5px;

    width: 100%;
    /* height: 68px; */
    box-sizing: border-box;

    background-color: transparent;
    border-radius: 4px;

    transition: background-color 0.5s;
    cursor: pointer;

    span {
      margin-left: 10px;
    }

    .label-primary {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -0.88px;
    }

    .label-secondary {
      color: ${colors.shadeMediumDark};
      font-size: 13px;
      line-height: 20px;
      padding-right: 50%;
    }

    &.selected {
      .label-primary, .label-secondary, .sub-component {
        color: ${colors.white};
      }
    }

    ${breakPoints.smallTablet} {
      .label-secondary {
        font-size: 14px;
      }
    }
  `,
  input: css`
    display: none;
  `,
};

export const ThemeShape = PropTypes.shape({
  container: PropTypes.array,
  label: PropTypes.array,
  input: PropTypes.array,
});
