import { Observable } from 'rxjs';
import { Families, Modals } from '../../ducks';
import * as Api from '../../../api/events-and-messages';
import EventReporter, { Events } from '../../../lib/EventReporter';

import { NPS_SURVEY, SATISFACTION_SURVEY } from '../../../values/surveys';

// const error = (type, err) => Observable.of({ type, err });

const surveyFlow = (stream$, {
  modalName, form, code, onSubmit, onClose,
}) => Observable.concat(
  [Modals.Creators.openModal({ name: modalName, data: { questions: form, code } })],
  Observable.race(
    stream$
      .ofType(Modals.Types.modalSubmited)
      .filter(({ name }) => name === modalName)
      .take(1)
      .mergeMap(onSubmit),
    stream$
      .ofType(Modals.Types.modalClosed)
      .filter(({ name }) => name === modalName)
      .take(1)
      .mergeMap(onClose),
  ),
);

export const answerSurvey = action$ => action$
  .ofType('START_SURVEY')
  .mergeMap(({ questions, creative, code }) => {
    EventReporter.view(Events.SURVEY_EVENT(code, creative));
    return surveyFlow(action$, {
      modalName: 'SurveyModal',
      form: questions,
      code,
      onSubmit: async ({ data }) => {
        await Api.postSurvey({ code, answers: data });
        EventReporter.action(Events.CLICK_SURVEY_ANSWER(code, creative));
        return { type: 'END_SURVEY' };
      },
      onClose: () => {
        EventReporter.action(Events.CLICK_SURVEY_DISMISS(code, creative));
        return [{ type: 'END_SURVEY' }];
      },
    });
  });

export const answerNPS = (action$, { getState }) => action$
  .ofType('START_NPS')
  .mergeMap(() => surveyFlow(action$, {
    modalName: 'NpsModal',
    form: NPS_SURVEY,
    onSubmit: async ({ data }) => {
      const { nps, comment } = data;
      const membershipType = Families.Selectors.activeFamilyIsPremium(getState()) ? 'premium' : 'freemium';
      await Api.postNPS({ nps, comment });
      EventReporter.action(Events.NPS_SUBMIT(membershipType));
      return { type: 'END_NPS' };
    },
    onClose: () => ([{ type: 'END_NPS' }]),
  }));

export const answerSatisfaction = action$ => action$
  .ofType('START_SATISFACTION_SURVEY')
  .mergeMap(() => surveyFlow(action$, {
    modalName: 'SurveyModal',
    form: SATISFACTION_SURVEY,
    onSubmit: async ({ data }) => {
      const { answers, comment } = data;
      await Api.postSatisfactionSurvey({ answers, comment });
      return { type: 'END_SATISFACTION_SURVEY' };
    },
    onClose: () => ([{ type: 'END_SATISFACTION_SURVEY' }]),
  }));
