import { path } from 'ramda';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { Creators, Types } from '../ducks/feed';
import * as FeedApi from '../../api/feed';

// eslint-disable-next-line import/prefer-default-export
export const fetchListFeed = (action$, { getState }) => action$.ofType(Types.FETCH_FEED_REQUESTED)
  .mergeMap(({ babyId, page }) => FeedApi.getListBabyFeed({ babyId, page })
    .then((res) => {
      if (page > 1) {
        const { feed } = getState().feed;
        const currentData = feed.data;
        const newData = [...currentData, ...res.data.data];

        return {
          ...res.data,
          data: newData,
        };
      }

      return res.data;
    })
    .then(feed => Creators.fetchFeedSuccess(feed))
    .catch((err) => {
      const directError = typeof err === 'string' && err;
      const disectedError = directError || path(['response', 'error_data', 'code'], err)
        || path(['response', 'data', 'error_data', 'code'], err)
        || 'generic';
      toast.error(i18n.t('feed:generic'));
      toast.error(i18n.t(`feed:${disectedError}`));
      return Creators.fetchFeedError(err);
    }));

export const fetchClassroomDap = action$ => action$.ofType(Types.FETCH_CLASSPLAN_REQUESTED)
  .mergeMap(({ babyId, groupId, skillProgrammeId }) => FeedApi.getClassroomsDap({ babyId, groupId, skillProgrammeId })
    .then(res => res.data)
    .then(classplan => Creators.fetchClassplanSuccess(classplan))
    .catch(err => Creators.fetchFeedError(err)));

export const postEvidence = action$ => action$.ofType(Types.POST_EVIDENCE_REQUESTED)
  .mergeMap(({ groupId, attachment }) => FeedApi.postClassroomsEvidence({ groupId, attachment })
    .then(res => res.data)
    .then((res) => {
      toast.success(i18n.t('chat:SUBMISSIONS_SUCCESS'));
      return Creators.postEvidenceSuccess(res.data);
    })
    .catch((err) => {
      toast.error(i18n.t('chat:SUBMISSIONS_ERROR'));
      return Creators.postEvidenceError();
    }));
