import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'lego-dist';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { breakPoints, colors } from '../../styles';
import { Namespaces } from '../../values/i18n';
import NeoModal from './NeoModal';
import { Selectors as UserSelectors } from '../../data/ducks/user';
import { User } from '../../data/ducks';

const Title = styled.span`
  color: ${colors.darkGray};
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 50px;
  max-width: 228px;

  ${breakPoints.smallTablet} {
    margin-bottom: 20px;
    max-width: 332px;
  }
`;
const Text = styled.span`
  max-width: 360px;
  color: ${colors.darkGray};
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;

  .primary {
    margin-bottom: 20px;
  }

  ${breakPoints.smallTablet} {
    flex-direction: row;
    button {
      max-width: 142px;
    }
    .secondary {
      margin-right: 30px;
    }
    .primary {
      margin-bottom: 0
    }
  }
`;

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: #FFFF;

  padding: 80px 37px 110px;
  margin: auto;
  box-sizing: border-box;
  max-width: ${props => props.width};
  height: 100vh;
  border-radius: unset;

  ${breakPoints.smallTablet} {
    height: auto;
    max-width: 620px;
    padding: 50px 0px 30px;
    border-radius: 4px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    text-align: center;
  }
`;

/*
{
  type: 'MODAL_OPENED',
  name: 'DeleteAccountModal',
}
*/

const DeleteAccountModal = ({
  t,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector(state => UserSelectors.getUserData(state));
  const { myFamily } = userData;
  return (
    <Modal>
      {({ closeModal }) => (myFamily?.isPremium ? (
        <>
          <Content>
            <Title>{t('MODAL_DELETE_ACCOUNT.PREMIUM.TITLE')}</Title>
            <Text>{t('MODAL_DELETE_ACCOUNT.PREMIUM.DESCRIPTION_1')}</Text>
            <Text>{t('MODAL_DELETE_ACCOUNT.PREMIUM.DESCRIPTION_2')}</Text>
          </Content>
          <ButtonWrapper>
            <Button onClick={closeModal} color="#75B753" maxWidth={300} rounded flat fill>
              {t('MODAL_DELETE_ACCOUNT.PREMIUM.GOT_IT')}
            </Button>
          </ButtonWrapper>
        </>
      )
        : (
          <>
            <Content>
              <Title>{t('MODAL_DELETE_ACCOUNT.FREEMIUM.TITLE')}</Title>
              <Text>{t('MODAL_DELETE_ACCOUNT.FREEMIUM.DESCRIPTION_1')}</Text>
              <Text>{t('MODAL_DELETE_ACCOUNT.FREEMIUM.DESCRIPTION_2')}</Text>
            </Content>
            <ButtonWrapper>
              <Button onClick={closeModal} className="secondary" color="default" maxWidth={300} rounded flat fill>
                {t('MODAL_DELETE_ACCOUNT.FREEMIUM.CANCEL')}
              </Button>
              <Button onClick={() => dispatch(User.Creators.deleteUserAccountRequested())} className="primary" color="#E02020" maxWidth={300} rounded flat fill>
                {t('MODAL_DELETE_ACCOUNT.FREEMIUM.DELETE')}
              </Button>
            </ButtonWrapper>
          </>
        )
      )}
    </Modal>
  );
};

export default withTranslation([Namespaces.MODALS])(DeleteAccountModal);
