import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, breakPoints } from '../../../styles';
import SafeAreaPlaceholder from '../../../images/SafeAreaPlaceholder.svg';

const FeedbackViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${colors.kinedu};
  background-color: ${({ bgColor }) => bgColor};
`;

const Title = styled.h1`
  color: ${colors.white};
  color: ${({ textColor }) => textColor};
  font-weight: bold;
  font-size: 32px;
  letter-spacing: -1.82px;
  line-height: 38.4px;
  text-align: center;

  max-width: 536px;

  ${breakPoints.smallTablet} {
    font-size: 50px;
    letter-spacing: -2.84px;
    line-height: 57.4px;
  }
`;

const Image = styled.img.attrs({ src: props => props.src })`
  width: 375px;
  height: 273px;
`;

const Subtitle = styled.p`
  color: ${colors.white};
  color: ${({ textColor }) => textColor};
  font-size: 24px;
  letter-spacing: -1.6px;
  line-height: 32.4px;
  text-align: center;

  max-width: 536px;

  ${breakPoints.smallTablet} {
    font-size: 32px;
    letter-spacing: -2.13px;
    line-height: 47.4px;
  }
`;

const FeedbackView = ({ title, subtitle, image, textColor, bgColor }) => (
  <FeedbackViewContainer bgColor={bgColor}>
    <Title textColor={textColor}>
      {title}
    </Title>
    <Image src={image} />
    <Subtitle textColor={textColor}>
      {subtitle}
    </Subtitle>
  </FeedbackViewContainer>
);

FeedbackView.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
};
FeedbackView.defaultProps = {
  title: 'Thank you for your information!',
  subtitle: 'Some more info here.',
  image: SafeAreaPlaceholder,
  textColor: colors.white,
  bgColor: colors.kinedu,
};

export default FeedbackView;
