import 'rxjs';
import { createEpicMiddleware } from 'redux-observable';
import { applyMiddleware, createStore } from 'redux';
import * as Sentry from '@sentry/react';
// import createSentryMiddleware from 'redux-sentry-middleware';
import { composeWithDevTools } from 'remote-redux-devtools';
import { persistStore } from 'redux-persist';
import { ENV, Environments } from '../config';
import Reducer from './ducks';
import Epics from './epics';

const epicMiddleware = createEpicMiddleware(Epics);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const configStore = (history) => {
  const store = createStore(
    Reducer,
    composeWithDevTools({
      realtime: ENV === Environments.STAGE,
      port: 8000,
      name: 'kinedu',
      suppressConnectErrors: ENV === Environments.PRODUCTION,
    })(
      applyMiddleware(
        epicMiddleware,
        history,
        // createSentryMiddleware(Sentry),
      ),
      sentryReduxEnhancer,
    ),
  );

  const persistor = persistStore(store);
  // TODO: VER COMO EXPORTAR ESTO CON UN FA
  window.persistor = persistor;
  return {
    store,
    persistor,
  };
};

export default configStore;
