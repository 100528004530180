import React from 'react';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import Title from '../../shared/Title';
import { colors } from '../../../styles';
import MainButton from '../../shared/buttons/MainButton';
import Trophy from '../../../images/MasteredTrophy.png';
import Texture from '../../../images/BgStars.svg';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${colors.kineduShade};
  background-image: url(${Texture});
  background-size: contain;
`;

const ImageContainer = styled.div`
    display: block;
    width: 150px;
    height: 150px;
    margin: 30px auto;
    border-radius: 100px;

    background-repeat: no-repeat;
    background-size: 28px;
    background-image: url(${Trophy});
    background-position: 26px;
    background-size: 110px;
    background-color: #FFFF;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: ${colors.white};
  font-size: 24px;
  margin: 30px 0 100px;

  p {
    margin: 0;
    font-weight: bold;
  }
`;

const Description = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 300px;
  margin: 20px auto;

  color: ${colors.white};
  font-size: 20px;
  text-align: center;
`;

const MasteredSkillView = ({
  baby, closeModal, activeMilestones, completedMilestones, skillName, t,
}) => (
  <Wrapper>
    <ImageContainer />
    <Title align="center" style={{ marginTop: '10px' }} size={36} color={colors.white} bold>
      {t('DOPAMINE.MASTERED_SKILL.TITLE')}
    </Title>
    <Description>
      {t('DOPAMINE.MASTERED_SKILL.DESCRIPTION', { babyName: baby.name, skillName })}
    </Description>
    <Info>
      <p>{t('DOPAMINE.MILESTONE_STATUS', { current: completedMilestones, total: activeMilestones })}</p>
      <p>{t('DOPAMINE.MILESTONES_COMPLETED')}</p>
    </Info>
    <MainButton color="green" onClick={closeModal} maxWidth={300}>{t('common:CONTINUE')}</MainButton>
  </Wrapper>
);

export default withTranslation([Namespaces.MILESTONES, Namespaces.COMMON])(MasteredSkillView);
