import { Creators, Types } from '../../ducks/messages';
import * as Api from '../../../api/events-and-messages';
import { attemptMessageFetchOnLocationEnter } from './orchestrators';

export { attemptMessageFetchOnLocationEnter };

// const MESSAGE_EXPIRATION_MILLISECONDS = 60000;

export const checkPendingMessages = (action$, { getState }) =>
  action$.ofType(Types.FETCH_PENDING_MESSAGES_REQUESTED)
    .map(({ location }) => ({ ...getState().messages[location], location }))
    .filter(({ messages }) => !messages || messages.length === 0)
    // .debounceTime(MESSAGE_EXPIRATION_MILLISECONDS)
    .pluck('location')
    .switchMap(location =>
      Api.getPendingMessages(location)
        .then(res => res.data.data.messages)
        .then(messages => Creators.fetchPendingMessagesSuccess({ location, messages })));

