/* eslint-disable camelcase */
// Chat
export const CHAT_VIEW = ({ individualChat }) => ({
  tag: 'S_Chat',
  params: { individualChat },
});
export const ChatIdNull = () => ({ tag: 'ChatIdNull' });
export const CHAT_DELETE_MESSAGE = () => ({ tag: 'DeleteMessage' });
export const CHAT_MESSAGE_DELETED = () => ({ tag: 'MessageDeleted' });
export const CHAT_SEND_MESSAGE = () => ({
  tag: 'SendChatMessage',
});

export const CHAT_VIEW_ATTACHMENT = () => ({
  tag: 'ViewResource',
  params: {
    filetype: localStorage.getItem('fileTypesChat')
      ? localStorage
        .getItem('fileTypesChat')
        .replace(new RegExp('application', 'g'), 'file')
      : '',
  },
});
export const CHAT_SEND_ATTACHMENT = () => ({
  tag: 'SendAttachment',
  params: {
    filetype: localStorage.getItem('fileTypesChat')
      ? localStorage
        .getItem('fileTypesChat')
        .replace(new RegExp('application', 'g'), 'file')
      : '',
  },
});

export const JOIN_GROUP = ({ groupName }) => ({
  tag: 'JoinGroup',
  params: { groupName },
});

export const JOIN_GROUP_VIEW = ({ groupName }) => ({
  tag: 'S_JoinConfirmation',
  params: { groupName },
});

export const JOIN_GROUP_CONFIRMATION = ({ groupName }) => ({
  tag: 'JoinConfirmation',
  params: { groupName },
});

export const EXIT_GROUP = ({ groupName }) => ({
  tag: 'ExitGroup',
  params: { groupName },
});

export const EXIT_GROUP_VIEW = ({ groupName }) => ({
  tag: 'S_ExitConfirmation',
  params: { groupName },
});

export const EXIT_CONFIRMATION = ({ groupName }) => ({
  tag: 'ExitConfirmation',
  params: { groupName },
});

export const REPORT_MESSAGE = () => ({ tag: 'ReportMessage' });

export const REPLY_MESSAGE = () => ({ tag: 'ReplyMessage' });

// Section: Landing
export const LANDING_A = () => ({ tag: 'LandingA' });
export const LANDING_B = () => ({ tag: 'LandingB' });
export const LANDING_GET_STARTED = ({ source } = {}) => ({
  tag: 'GetStarted',
  params: { source },
});
export const LANDING_VIDEO = ({ source } = {}) => ({
  tag: 'LandingVideo',
  params: { source },
});
export const LANDING_HOME = ({ testType } = {}) => ({
  tag: 'S_LandingHome',
  params: { testType },
});

// Google Tag Manager unique events
export const CLICK_ON_APPSTORE = () => ({ tag: 'ClickOnAppStore' });
export const CLICK_ON_PLAYSTORE = () => ({ tag: 'ClickOnPlayStore' });
export const CLICK_ON_WEBAPP = () => ({ tag: 'ClickOnWebApp' });

// Google Analytics events
export const VIEW_LANDING = () => ({ tag: '/landing' });
export const VIEW_DAP = () => ({ tag: '/dap' });
export const VIEW_CATALOG = () => ({ tag: '/catalog' });
export const VIEW_FAMILIES = () => ({ tag: '/families' });
export const VIEW_PROGRESS = () => ({ tag: '/progress' });
export const VIEW_MILESTONES = () => ({ tag: '/milestones' });
export const VIEW_SETTINGS = () => ({ tag: '/settings' });

// FB pixel events
export const VIEW_CONTENT = ({ content_type } = {}) => ({
  tag: 'ViewContent',
  params: { content_type },
});
export const COMPLETE_REGISTRATION = () => ({ tag: 'CompleteRegistration' });
export const PURCHASE = ({
  currency = 'USD',
  sku,
  userId,
  price = 0,
} = {}) => ({
  tag: 'Purchase',
  params: {
    currency,
    type: sku,
    app_user_id: userId,
    value: price,
  },
});

export const LOG_OUT = () => ({ tag: 'LogOut' });
export const SURVEY_EVENT = (code, creative) => ({
  tag: 'Survey',
  params: { code, creative },
});
export const CLICK_SURVEY_ANSWER = (code, creative) => ({
  tag: 'ClickSurveyAnswer',
  params: { code, creative },
});
export const CLICK_SURVEY_DISMISS = (code, creative) => ({
  tag: 'ClickSurveyDismiss',
  params: { code, creative },
});

// EVENT EXECED WHEN THE USER CLICKS IN BTN THAT REDIRECT TO PP
export const TAP_CALL_TO_ACTION = ({ source, payment_path } = {}) => ({
  tag: 'TAPCallToAction',
  params: { payment_path: payment_path || source },
});

// Premium Process Stripe
export const S_PREMIUM_PROCESS = ({ source } = {}) => {
  const params = {};
  if (source) {
    params.source = source;
  }
  return { tag: 'S_PremiumProcess', params };
};
export const CHOOSE_PLAN_PREMIUM_PROCESS = () => ({ tag: 'S_ChoosePlan' });
export const DISMISS_PREMIUM_PROCESS = (source) => {
  const params = {};
  if (source) {
    params.source = source;
  }
  return {
    tag: 'PPDismiss',
    params,
  };
};
export const CLICK_PLAN_PREMIUM_PROCESS = subscription => ({
  tag: 'BuySubscription',
  params: {
    subscription_type: subscription.plan,
    billing_recurrency: subscription.recurrency,
  },
});

// EVENT IS TRIGGERED WHEN THE DEFAULT PREMIUM PROCESS IS SHOW TO THE USER
export const VIEW_PP_BACKUP = reason => ({
  tag: 'loadDefaultPPKeDebug',
  params: { reason },
});

// Section: Sign up
export const FORGOT_PASSWORD = () => ({ tag: 'SPForgotPass' });
export const LOGIN_SCREEN = () => ({ tag: 'S_SPLogin' });
export const LOGIN = () => ({ tag: 'Login' }); // Web unique event
export const SIGNUP_SCREEN = () => ({ tag: 'S_SPSignup' });
export const SIGNUP = () => ({ tag: 'Signup' }); // Web unique event

// Section: On boarding
export const OB_USER_ROLE = () => ({ tag: 'S_OBUserRole' });
export const OB_CREATE_USER = () => ({ tag: 'OBCreateUser' });
export const OB_BABY_NAME = () => ({ tag: 'S_OBBabyName' });
export const OB_WEIGHT_LENGTH = () => ({ tag: 'S_OBWeightLength' });
export const UPDATE_WEIGHT = ({ source } = {}) => ({
  tag: 'UpdateWeight',
  params: { source },
});
export const UPDATE_LENGTH = ({ source } = {}) => ({
  tag: 'UpdateLength',
  params: { source },
});
export const OB_PREMATURE = () => ({ tag: 'S_OBPremature' });
export const OB_CREATE_TWINS = () => ({ tag: 'OBCreateTwins' });
export const OB_CREATE_BABY = () => ({ tag: 'CreateBaby' });
export const CREATE_BABY = ({ source, kineduType } = {}) => ({
  tag: 'CreateBaby',
  params: { source, kinedu_type: kineduType },
});

export const PPPAYMENT_STARTED = ({ source, pricingInfo }) => ({
  tag: 'PPPaymentStarted',
  params: {
    payment_source: source,
    subscription_type: pricingInfo.billing.text,
    type: pricingInfo.priceId,
  },
});

export const CLICK_PLAY_WELCOME_VIDEO = () => ({
  tag: 'OBWelcomeVideo',
});

export const CLICK_DISMISS_WELCOME_VIDEO = () => ({
  tag: 'OBWelcomeVideoDismiss',
});

export const VIEW_PAYWALL_BACKUP = reason => ({
  tag: 'loadDefaultPaywallKeDebug',
  params: { reason },
});

export const VIEW_PAYWALL_PLAY_LEARN = () => ({ tag: 'S_Paywall_PlayLearn' });
export const VIEW_PAYWALL_PLAY = () => ({ tag: 'S_Paywall_Play' });
export const VIEW_PAYWALL_LEARN = () => ({ tag: 'S_Paywall_Learn' });

// Section: Initial Assessment
export const IA_INTRO = () => ({ tag: 'S_IAIntro' });
export const IA_HOME = () => ({ tag: 'S_IAHome' });
export const IA_START_ASSESSMENT = () => ({ tag: 'IAStartAssessment' });
export const MASTER_SKILL = ({ skill, age, source } = {}) => ({
  tag: 'MasterSkill',
  params: { skill, "baby's age": age, source },
});
export const IA_FINISH_ASSESSMENT = () => ({ tag: 'IAFinishAssessment' });
export const HEALTH_INTEREST = ({ source } = {}) => ({
  tag: 'S_HealthInterest',
  params: { source },
});
export const IA_SKIP_ASSESSMENT = ({ source } = {}) => ({
  tag: 'IASkipAssessment',
  params: { source },
});
export const IA_FINISH_SKILL = ({ skill } = {}) => ({
  tag: 'IAFinishSkill',
  params: { skill },
});
export const IA_STRAIGHT_TO_ACTIVITIES = () => ({
  tag: 'IAStraightToActivities',
});

// Section: Today Activity Plan
export const S_TAP_HOME = () => ({ tag: 'S_TAPHome' });
export const ASK_AN_EXPERT = source => ({
  tag: 'AskAnExpert',
  params: {
    source,
  },
});
export const ALL_EVENTS = () => ({ tag: 'AllEvents' });
export const S_PLAY_DAP = () => ({ tag: 'S_PlayDAP' });
export const PAST_PLANS = source => ({
  tag: 'PastPlans',
  params: { source },
});
export const S_LEARN_DAP = () => ({ tag: 'S_LearnDAP' });
export const CUSTOM_ACT_VIEW = ({ type, id }) => ({
  tag: 'CustomActView',
  params: { type, custom_act_id: id },
});
export const CUSTOM_ACT_COMPLETED = ({ type, id }) => ({
  tag: 'CustomActCompleted',
  params: { type, custom_act_id: id },
});
export const LEARN_PAST_PLANS = () => ({ tag: 'LearnPastPlans' });
// Section: Daily Activity Plan
export const DAP_HOME = () => ({ tag: 'S_DAPHome' });
export const DAP_PAST_PLANS = () => ({ tag: 'DAPPastPlans' });
export const DAP_PAST_PLANS_PREMIUM = () => ({ tag: 'S_DAPPastPlansPremium' });
export const DAP_MATERIALS = ({ source } = {}) => ({
  tag: 'S_DAPMaterials',
  params: { source },
});
export const ARTICLE_VIEW = ({ id, source, type } = {}) => ({
  tag: 'ArticleView',
  params: { id, source, type },
});
export const SLIDESHOW_VIEW = ({ source, id }) => ({
  tag: 'SlideshowView',
  params: { source, id },
});
export const MILESTONES_UPDATE = ({ source, index } = {}) => ({
  tag: 'MilestonesUpdate',
  params: { source, index },
});
export const ACTIVITY_VIEW = ({
  id, source, area, activity_type,
} = {}) => ({
  tag: 'ActivityView',
  params: {
    // id,
    activity_id: id,
    source,
    area,
    activity_type,
  },
});
export const DAP_NEW_SKILLS = () => ({ tag: 'DAPNewSkills' });
export const DAP_ACTIVITY_COMPLETED = ({
  source,
  index,
  area,
  activity_type,
  baby_id,
  id,
} = {}) => ({
  tag: 'DAPActivityCompleted',
  params: {
    source,
    index,
    area,
    activity_type,
    baby_id,
    activity_id: id,
  },
});
export const DAP_FULLSCREEN_ACTIVITY = ({
  act_id,
  source,
  index,
  area,
  activity_type,
} = {}) => ({
  tag: 'DAPFullScreenActivity',
  params: {
    act_id,
    source,
    index,
    area,
    activity_type,
  },
});
export const DAP_CHANGE_ACTIVITY = ({ source, area, why_type }) => ({
  tag: 'DAPChangeActivity',
  params: { source, area, why_type },
});
export const DAP_CHANGE_ACT_DISMISS = ({ source, area } = {}) => ({
  tag: 'DAPChangeActDismiss',
  params: { source, area },
});
export const DAP_SELECT_ACTIVITY = ({
  previous_activity,
  selected_activity,
} = {}) => ({
  tag: 'DAPSelectActivity',
  params: { previous_activity, selected_activity },
});
export const DAP_PREVIOUS_ACTIVITY = () => ({ tag: 'DAPPreviousActivity' });
export const SHARE = ({ type, source } = {}) => ({
  tag: 'Share',
  params: { type, source },
});
export const FAVORITE = ({ type, source, area } = {}) => ({
  tag: 'Favorite',
  params: { type, source, area },
});

// Section: Pagos
export const PP_POPUP = () => ({ tag: 'PPPopup' });
export const PP_PAYMENT_STARTED = ({
  payment_source,
  type,
  subscription_type,
  payment_path,
  sku,
}) => ({
  tag: 'PPPaymentStarted',
  params: {
    payment_source,
    type,
    subscription_type,
    payment_path,
    sku,
  },
});
export const PP_PAYMENT_SUCCESS = ({
  payment_source,
  type,
  subscription_type,
}) => ({
  tag: 'PPPaymentSuccess',
  params: { payment_source, type, subscription_type },
});
export const PP_PAYMENT_FREETRIAL = ({
  payment_source,
  type,
  subscription_type,
}) => ({
  tag: 'FreeTrialStart',
  params: { payment_source, type, subscription_type },
});
export const PP_PAYMENT_FAIL = ({
  payment_source,
  type,
  subscription_type,
}) => ({
  tag: 'PPPaymentFail',
  params: { payment_source, type, subscription_type },
});
export const PP_PAYMENT_PROVIDER = ({
  payment_source,
  type,
  subscription_type,
  payment_path,
  response,
  reason,
  sku,
  price,
}) => ({
  tag: 'PPPaymentProvider',
  params: {
    payment_source,
    type,
    subscription_type,
    payment_path,
    response,
    sku,
    amount: price,
    reason: reason || 'it was successful',
  },
});
export const PP_FREEMIUM_VS_PREMIUM = () => ({ tag: 'PPFreemiumVsPremium' });
export const PP_WHY_INVEST = () => ({ tag: 'PPWhyInvest' });
export const PP_PRICING_PLANS = () => ({ tag: 'PPPricingPlans' });
export const PROMO_CODE = () => ({ tag: 'S_PromoCode' });
export const PROMO_CODE_REDEEM = ({ result }) => ({
  tag: 'PromoCodeRedeem',
  params: { result },
});

// Section: Menú
export const MENU_FAMILY = () => ({ tag: 'MenuFamily' });
export const MENU_BABY = () => ({ tag: 'MenuBaby' });
export const MENU_MY_PROFILE = () => ({ tag: 'MenuMyProfile' });
export const MENU_MANAGE_FAMILIES = () => ({ tag: 'MenuManageFamilies' });
export const MENU_ADD_MEMBER = ({ source }) => ({
  tag: 'MenuAddMember',
  params: { source },
});
export const MENU_SETTINGS_HOME = () => ({ tag: 'MenuSettingsHome' });
export const MENU_FAMILY_INVITE = ({ role, permissions }) => ({
  tag: 'MenuFamilyInvite',
  params: { role, permissions },
});
export const MENU_EDIT_BABY = () => ({ tag: 'S_MenuEditBaby' });
export const MENU_USER_PROFILE = () => ({ tag: 'S_MenuUserProfile' });
export const MENU_FAMILY_DEFAULT = ({ source } = {}) => ({
  tag: 'MenuFamilyDefault',
  params: { source },
});
export const MENU_FAMILY_PROFILE = () => ({ tag: 'S_MenuFamilyProfile' });
export const MENU_HELP = () => ({ tag: 'MenuHelp' });
export const MENU_MEMBER_STATS = () => ({ tag: 'MenuMemberStats' });
export const MENU_FAMILY_HOME = () => ({ tag: 'S_MenuFamilyHome' });
export const MENU_CHANGE_BIRTHDAY = ({ action }) => ({
  tag: 'MenuChangeBirthday',
  params: { action },
});
export const MENU_MANAGE_SUBS = ({ payment_processor }) => ({
  tag: 'ManageSubStripe',
  params: { payment_processor },
});

// Section: invites
export const S_INVITE_RECEIVED = ({ source, permissions }) => ({
  tag: 'S_InviteReceived',
  params: { source, permissions },
});
export const INVITE_RECEIVED = ({ source, permissions, response }) => ({
  tag: 'InviteReceived',
  params: { source, permissions, response },
});

// Section: NPS
export const NPS_SUBMIT = ({ membership_type, score }) => ({
  tag: 'NPSSubmit',
  params: { membership_type, score },
});
export const NPS_SCORE = () => ({ tag: 'S_NPSScore' });
export const NPS_FEEDBACK = () => ({ tag: 'NPSFeedback' });
export const NPS_GIVE_REVIEW = () => ({ tag: 'NPSGiveReview' });

// Indaps:
export const INDAP_VIEWED = ({ code, creative } = {}) => ({
  tag: 'IndappMessage',
  params: { code, creative },
});
export const INDAP_CLICK = ({ code, creative } = {}) => ({
  tag: 'ClickIndappCta',
  params: { code, creative },
});
export const INDAP_DISMISSED = ({ code, creative } = {}) => ({
  tag: 'ClickIndappDismiss',
  params: { code, creative },
});

// Section: Catalog
export const CAT_HOME_P = () => ({ tag: 'S_CATHome_P' });
export const CAT_HOME_F = () => ({ tag: 'S_CATHome_F' });
export const CAT_SKILL = () => ({ tag: 'S_CATSkill' });
export const CAT_SKILL_DETAIL = ({ skill }) => ({
  tag: 'CATSkillDetail',
  params: { skill },
});
export const S_CATALOG = () => ({ tag: 'S_Catalog' });
export const CATALOG = () => ({ tag: 'Catalog' });
export const S_CAT_SEARCH_BAR = ({
  area,
  age_range,
  search_name,
  collection_type,
}) => ({
  tag: 'S_CATSearchBar',
  params: {
    area,
    age_range,
    search_name,
    collection_type,
  },
});

// Section: Progress
export const SKILLS_HOME = ({ plan_type } = {}) => ({
  tag: 'S_SkillsHome',
  params: { plan_type },
});
export const SKILLS_DETAIL = ({ area, skill, baby_age }) => ({
  tag: 'SkillsDetail',
  params: { area, skill, baby_age },
});
export const S_PROGRESS_HOME = source => ({
  tag: 'S_ProgressHome',
  params: { source },
});

// Section: Milestones
export const MILESTONES_HOME = () => ({ tag: 'S_MilestonesHome' });
export const MILESTONES_SKILLS = ({ source }) => ({
  tag: 'S_SkillsMilestones',
  params: { source },
});
export const MILESTONES_SKILLS_NOT_PERSONALIZED = () => ({
  tag: 'SkillsCardNotPersonalized',
});
export const MILESTONES_LEARN_MORE = ({ id, age, subscription_type }) => ({
  tag: 'SkillsMilestoneLearnMore',
  params: { skill: id, baby_age: age, membership_type: subscription_type },
});

// Section: Feed
export const FEED_HOME = source => ({
  tag: 'S_FeedHome',
  params: { source },
});
export const INVITE_CLASSROOMS = () => ({ tag: 'InviteClassrooms' });
export const FEED_ENTRY = () => ({ tag: 'FeedEntry' });
export const FEED_ENGAGEMENT = ({ action, area }) => ({
  tag: 'FeedEngagement',
  params: { action, area },
});
export const WEEKLY_PLAN = () => ({ tag: 'S_WeeklyPlan' });

// Section: Feed, Chat
export const VIEW_RESOURCE = ({ type }) => ({
  tag: 'ViewResource',
  params: { type },
});
// Section: Calendar
export const CALENDAR_HOME = () => ({ tag: 'S_CalendarHome' });
export const DAILY_PREVIEW = () => ({ tag: 'DailyPreview' });
export const EVENT_DETAIL = () => ({ tag: 'EventDetail' });
export const CALENDAR_ENGAGEMENT = ({ engagement_type }) => ({
  tag: 'CalendarEngagement',
  params: { engagement_type },
});
export const CALENDAR_ID_NULL = () => ({ tag: 'CalendarIdNull' });
export const SAVE_SPOT = () => ({ tag: 'SaveSpot' });
export const S_MY_EVENTS = () => ({ tag: 'S_MyEvents' });
export const MY_EVENTS = () => ({ tag: 'MyEvents' });
export const S_MY_PAST_EVENTS = () => ({ tag: 'S_MyPastEvents' });
export const MY_PAST_EVENTS = () => ({ tag: 'MyPastEvents' });
export const OPEN_ZOOM = ({
  eventId, eventTime, eventDate, source,
}) => ({
  tag: 'OpenZoom',
  params: {
    eventId,
    eventTime,
    eventDate,
    source,
  },
});
export const S_EVENT_DETAIL = ({
  eventType,
  eventDate,
  eventTime,
  eventId,
  source,
}) => ({
  tag: 'S_EventDetail',
  params: {
    event_type: eventType,
    event_date: eventDate,
    event_time: eventTime,
    event_id: eventId,
    source,
  },
});
export const SHARE_CALENDAR = ({ channel, type, id }) => ({
  tag: 'Share',
  params: {
    channel,
    type,
    event_id: id,
  },
});
export const REMOVE_SAVED_EVENT = () => ({ tag: 'RemoveSavedEvent' });

// Section: Classrooms Code Invite
export const FEED_LEAD_GENERATOR = () => ({
  tag: 'S_FeedLeadGenerator_EmptyState',
});
export const CLASSROOM_CODE = () => ({ tag: 'S_ClassrooomCode' });
export const DO_NOT_JOIN_SCHOOL_CLICKED = () => ({ tag: 'DoNotJoinSchool' });
export const VERIFY_CODE = (Code, ValidCode) => ({
  tag: 'VerifyCode',
  params: { Code, ValidCode },
});
export const CODE_CONFIRMATION = () => ({ tag: 'S_CodeConfirmation' });
export const CODE_CONFIRMATION_CLICKED = () => ({ tag: 'CodeConfirmation' });
export const EDIT_CODE_CLICKED = () => ({ tag: 'EditCode' });

// Section: Collection View
export const S_COLLECTION_VIEW = ({ collection_type, collection_id }) => ({
  tag: 'S_CollectionView',
  params: { collection_type, collection_id },
});

// Section:  All Collections View
export const S_ALL_COLLECTIONS = ({ age_range, source }) => ({
  tag: 'S_AllCollections',
  params: { age_range, source },
});

export const VIEW_ALL_COLLECTIONS = source => ({
  tag: 'ViewAllCollections',
  params: { source },
});

// Search Bar Event

export const S_OB_CHOOSE_EXPERIENCE = () => ({ tag: 'S_OBChooseExperience' });
export const OB_CHOOSE_EXPERIENCE = isClassroomsUser => ({
  tag: 'OBChooseExperience',
  params: {
    experience: isClassroomsUser ? 'classroomsUser' : 'kineduUser',
  },
});
export const SELECT_LANGUAGE = language => ({
  tag: 'SelectLanguage',
  params: {
    language,
  },
});
export const S_OB_CREATE_USER = () => ({ tag: 'S_OBCreateUser' });
export const S_OB_CREATE_BABY = () => ({ tag: 'S_OBCreateBaby' });
export const S_CLASSROOM_CODE = () => ({ tag: 'S_ClassroomCode' });
export const INDIVIDUAL_TOY = params => ({
  tag: 'IndividualToy',
  params,
});
export const TOYS = source => ({
  tag: 'toys',
  params: {
    source,
  },
});
export const TOYS_CATALOG = () => ({ tag: 'ToysCatalog' });
export const ALL_COACHES = () => ({ tag: 'AllCoaches' });
export const COACHING_CARD_CLICK = (coachingId, provider) => ({
  tag: 'CoachingCardClick',
  params: {
    coaching_id: coachingId,
    checkout_provider: provider,
  },
});
export const S_COACHING_DETAIL = (coachingId, source) => ({
  tag: 'S_CoachingDetail',
  params: {
    coaching_id: coachingId,
    source,
  },
});
export const COACHING_DETAIL_CTA = coachingId => ({
  tag: 'CoachingDetailCTA',
  params: {
    coaching_id: coachingId,
  },
});
export const S_COACHING_HOME = () => ({
  tag: 'S_CoachingHome',
});
export const DISMISS_FEEDBACK_RATEACTIVITY = () => ({
  tag: 'Dismiss_Feedback_RateActivity',
});
export const RATE_ACTIVITY_DAP = () => ({
  tag: 'RateActivityDAP',
});
export const FEEDBACK_RATEACTIVITY = (params = {}) => ({
  tag: 'Feedback_RateActivity',
  params: {
    ...params,
    answer: params.answer ? 'positive' : 'negative',
  },
});
export const MENU_FAQ = () => ({
  tag: 'MenuFAQ',
});
export const S_CATALOG_ACTIVITIES = () => ({ tag: 'S_CatalogActivities' });
export const S_CATALOG_CLASSES = () => ({ tag: 'S_CatalogClasses' });
export const S_CATALOG_MASTERCLASS = () => ({ tag: 'S_CatalogMasterClass' });

export const BROWSE_BY_SKILL = () => ({ tag: 'BrowseBySkill' });
export const BROWSE_BY_SUBJECT = ({ source }) => ({
  tag: 'BrowseBySubject',
  params: { source },
});
export const S_SEARCH_EMPTY_STATE = () => ({ tag: 'S_SearchEmptyState' });
export const SEARCH = (params = {}) => ({ tag: 'Search', params });
export const RESET_SEARCH = () => ({ tag: 'ResetSearch' });
export const S_SEARCH_RESULTS = () => ({ tag: 'S_SearchResults' });
export const S_SEARCHER = ({ source }) => ({
  tag: 'S_Searcher',
  params: { source },
});
export const S_BROWSE_BY_SKILL = () => ({ tag: 'S_BrowseBySkill' });
export const SKILL_COLLECTION = ({ collectionId }) => ({
  tag: 'SkillCollection',
  params: { collection_id: collectionId },
});
export const S_COLLECTION_VIEW_NEW = ({
  type = 'skill',
  id,
  source = 'catalog',
}) => ({
  tag: 'S_CollectionView',
  params: { collection_type: type, collection_id: id, source },
});
export const S_BROWSE_BY_SUBJECT = () => ({
  tag: 'S_BrowseBySubject',
});
export const SUBJECT_COLLECTION = ({ collectionId }) => ({
  tag: 'SubjectCollection',
  params: { collection_id: collectionId },
});
export const SEARCH_SATISFACTION = ({ isLike = false, source }) => ({
  tag: 'SearchSatisfaction',
  params: {
    response: isLike ? 'yes' : 'no',
    source,
  },
});
export const AGE_FILTER = age_range => ({
  tag: 'AgeFilter',
  params: {
    age_range,
  },
});
export const S_BROWSE_BY_INTEREST = () => ({ tag: 'S_BrowseByInterest' });
export const BROWSE_BY_INTEREST = ({ source = '' }) => ({
  tag: 'BrowseByInterest',
  params: {
    source,
  },
});
export const INTEREST_COLLECTION = ({ collectionId }) => ({
  tag: 'InterestCollection',
  params: {
    collection_id: collectionId,
  },
});
export const ARTICLES = () => ({
  tag: 'Articles',
});
export const S_ARTICLES = () => ({
  tag: 'S_Articles',
});
export const ARTICLES_COLLECTION = collectionId => ({
  tag: 'ArticlesCollection',
  params: {
    collection_id: collectionId,
  },
});
export const CATALOG_CARD = () => ({
  tag: 'CatalogCard',
});
export const BROWSE_BY_AGE = () => ({
  tag: 'BrowseByAge',
});
export const S_BROWSE_BY_AGE = () => ({
  tag: 'S_BrowseByAge',
});
export const AGE_COLLECTION = collctionId => ({
  tag: 'AgeCollection',
  params: { collection_id: collctionId },
});
export const BROWSE_BY_EXPERT = ({ source }) => ({
  tag: 'BrowseByExpert',
  params: { source },
});
export const S_BROWSE_BY_EXPERT = () => ({
  tag: 'S_BrowseByExpert',
});
export const EXPERT_COLLECTION = collctionId => ({
  tag: 'ExpertCollection',
  params: { collection_id: collctionId },
});

export const FAVORITES_SECTION = source => ({
  tag: 'FavoritesSection',
  params: { source },
});
export const S_FAVORITES = () => ({
  tag: 'S_Favorites',
});
export const FAVORITES = (type, source) => ({
  tag: 'Favorite',
  params: { type, source },
});

export const S_CHOOSE_INTERESTS = () => ({ tag: 'S_ChooseInterests' });
export const CHOOSE_INTERESTS = () => ({ tag: 'ChooseInterests' });
export const SKIP_INTERESTS = () => ({ tag: 'SkipInterests' });
export const S_IA_PREVIEW_INTRO = () => ({ tag: 'S_IAPreviewIntro' });
export const S_IAPREVIEW_MILESTONE = (selected = false) => ({
  tag: 'S_IAPreviewMilestone',
  params: { answer: selected ? 'yes' : 'no' },
});
export const S_IAPREVIEW_FEEDBACK = (source = 'average') => ({
  tag: 'S_IAPreviewFeedback',
  params: { source },
});
export const SKIP_ASSESMENT = () => ({ tag: 'SkipAssesment' });
export const FREE_TRIAL_START_WEB = () => ({ tag: 'FreeTrialStartWeb' });
export const ADD_TO_CART = () => ({ tag: 'addToCart' });

export const PURCHASE_PAYWALL = () => ({ tag: 'purchase' });

export const MASTER_CLASS = id => ({
  tag: 'MasterClass',
  params: { masterclass_id: id },
});

export const S_MASTER_CLASS_DETAIL = id => ({
  tag: 'S_MasterClassDetail',
  params: { masterclass_id: id },
});

export const MASTER_CLASS_WORKBOOK = ({ masterClassId, workbookId }) => ({
  tag: 'MasterClassWorkbook',
  params: {
    masterclass_id: masterClassId,
    workbook_id: workbookId,
  },
});

export const TRAILER = masterClassId => ({
  tag: 'Trailer',
  params: {
    masterclass_id: masterClassId,
  },
});

export const LESSON = ({ masterClassId, lessonId }) => ({
  tag: 'Lesson',
  params: {
    masterclass_id: masterClassId,
    lesson_id: lessonId,
  },
});

export const S_LESSON_DETAIL = ({ masterClassId, lessonId }) => ({
  tag: 'S_LessonDetail',
  params: {
    masterclass_id: masterClassId,
    lesson_id: lessonId,
  },
});

export const LOAD_DEFAULT_PAYWALL = attrs => ({
  tag: 'LoadDefaultPaywall',
  params: {
    variable: attrs,
  },
});

export const S_PAYWALL = () => ({ tag: 'S_Paywall' });

export const PAYWALL_DISMISS = () => ({ tag: 'PaywallDismiss' });

export const LOAD_DEFAULT_PP = attrs => ({
  tag: 'LoadDefaultPP',
  params: {
    variable: attrs,
  },
});

export const PP_PAYMENT_CANCELED = () => ({ tag: 'PPPaymentCanceled' });
