import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Modal from '../modals/NeoModal';

const Wrapper = styled(Modal) `
  max-width: 100vw;
  margin: 0;
`;

const FullModal = ({ children, className, closeButtonColor, showCloseButton }) => (
  <Wrapper
    className={className}
    closeButtonColor={closeButtonColor}
    showCloseButton={showCloseButton}
  >
    {children}
  </Wrapper>
);

FullModal.propTypes = {
  children: PropTypes.func,
  className: PropTypes.string,
  closeButtonColor: PropTypes.string,
  showCloseButton: PropTypes.bool,
};

FullModal.defaultProps = {
  children: [],
  className: '',
  closeButtonColor: '',
  showCloseButton: false,
};

export default FullModal;
