import React from 'react';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import Modal from './NeoModal';
import { breakPoints } from '../../styles';

import GiftCardsView from '../views/GiftCards_';

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;

  ${breakPoints.smallTablet} {
    margin: auto;
    height: auto;
  }
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'GiftCardsModal',
  data: {
  },
}

*/

const GiftCardsModal = () => (
  <Wrapper width={900} closeButtonColor="white" closeOnBackdrop={false} roundedCorners>
    {({ closeModal }) => (<GiftCardsView onClose={closeModal} />)}
  </Wrapper>
);

export default withTranslation([Namespaces.MODALS])(GiftCardsModal);
