import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import Button from '../../shared/buttons/MainButton';
import GiftParachute from '../../../images/GiftParachute.svg';
import { breakPoints, colors } from '../../../styles';


const Image = styled.img.attrs({ src: GiftParachute })`
  width: 133px;
  margin-bottom: 20px;
  position: relative;

  @keyframes gift {
    from { top: -100px; opacity: 0; }
    to {   top: 0px; opacity: 1; }
  }

  animation: gift 1s 1;
  animation-timing-function: ease-out;

  ${breakPoints.smallTablet} {
    width: 189px;
    margin-bottom: 33px;
  }
`;

const SendGiftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  padding: 40px 0 47px 0;
  box-sizing: border-box;

  ${breakPoints.smallTablet} {
    padding: 36px 48px;
  }
`;

const Description = styled.p`
  color: #4A4A4A;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: -0.5px;
  line-height: 30px;
  text-align: center;
  margin: 0;

  max-width: 300px;

  ${breakPoints.smallTablet} {
    font-size: 28px;
    letter-spacing: -0.58px;
    line-height: 33px;

    max-width: none;
  }
  
  .highlight-text {
    color: ${colors.newKinedu};
  }
`;

const Note = styled.p`
  color: #4A4A4A;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;

  ${breakPoints.smallTablet} {
    font-size: 22px;
    letter-spacing: -1.1px;
    line-height: 25px;
    margin-top: 18px;
    margin-bottom: 43px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 0;
  .btn {
    margin: 10px;
  }

  ${breakPoints.smallTablet} {
    flex-direction: row;
    width: 620px;
    justify-content: center;
    .btn {
      margin: 0;
    }
  }
`;

const GiftcardType = ({ onSubmit, t }) => (
  <SendGiftContainer>
    <Image />
    <Description>
      {t('SEND_GIFTCARD.DESCRIPTION')}
      {/* <span className="highlight-text">{t('SEND_GIFTCARD.DESCRIPTION_PT2')}</span> */}
      {/* {t('SEND_GIFTCARD.DESCRIPTION_PT3')} */}
    </Description>
    <Note>
      {/* {t('SEND_GIFTCARD.NOTE')} */}
    </Note>
    <ButtonsContainer>
      {/* <Button className="btn" onClick={() => onSubmit({ isForMe: true })} color="kinedu" maxWidth={300}>{t('SEND_GIFTCARD.FOR_ME')}</Button> */}
      <Button className="btn" onClick={() => onSubmit({ isForMe: false })} color="green" maxWidth={300}>{t('SEND_GIFTCARD.PURCHASE_BTN')}</Button>
    </ButtonsContainer>
  </SendGiftContainer>
);

GiftcardType.propTypes = {
  /* callback when form is submited */
  onSubmit: PropTypes.func,
  t: PropTypes.func,
};
GiftcardType.defaultProps = {
  onSubmit: () => {},
  t: () => {},
};

export default withTranslation([Namespaces.MODALS])(GiftcardType);
