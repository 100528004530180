export const Providers = {
  FB: 'Facebook',
  EMAIL: 'email',
  EMULATED: 'emulated',
};

export const Types = {
  SIGNUP: 'signup',
  LOGIN: 'login',
  SET_NEW_PASSWORD: 'newPassword',
};
