import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import Button from '../../shared/buttons/MainButton';
import { colors, breakPoints } from '../../../styles';

import Dr from '../../../images/Dr2.png';
import { genderArticle } from '../../../lib/utils';

const ImageContainer = styled.div`
  width: 125px;
  height: 135px;

  background-image: url(${Dr});
  background-size: 130px 140px;
  background-repeat: no-repeat;
  background-position: center -83%;
  border-radius: 100%;

  margin: 0 20px;
`;

const Title = styled.h2`
  color: ${colors.kinedu};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  letter-spacing: -0.55px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  ${breakPoints.smallTablet} {
    padding: 30px 50px;
  }
`;

const Description = styled.div`
  color: ${colors.dustyGray};
  font-size: 16px;
  margin-bottom: 16px;
  text-align: center;
  line-height: 21px;

  ${breakPoints.smallTablet} {
    margin: 20px 35px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UnmarkedMilestonesView = ({
  t, closeModal, onSubmit, baby,
}) => (
  <Content>
    <Title>{t('MODAL_UNMARKED_MILESTONES.TITLE')}</Title>
    <Body>
      <ImageContainer />
      <Description>
        {t('MODAL_UNMARKED_MILESTONES.DESCRIPTION', { babyName: baby.name, article: genderArticle(baby.gender) })}
      </Description>
    </Body>
    <Button color="green" style={{ maxWidth: '280px' }} onClick={() => { onSubmit(); closeModal(); }}>
      {t('common:GOT_IT')}
    </Button>
    <Button color="clear" style={{ maxWidth: '213px' }} onClick={closeModal}>{t('MODAL_UNMARKED_MILESTONES.MADE_MISTAKE')}</Button>
  </Content>
);

UnmarkedMilestonesView.propTypes = {
  t: PropTypes.func,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
};

UnmarkedMilestonesView.defaultProps = {
  t: () => {},
  closeModal: () => {},
  onSubmit: () => {},

};

export default withTranslation([Namespaces.MODALS, Namespaces.COMMON])(UnmarkedMilestonesView);
