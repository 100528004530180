/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import AboutAssessment from '../assesmentsComponents/AboutAssessment';
import AboutCalculationsModal from './AboutCalculationsModal';
import AuthModal from '../landing/AuthModal';
import BabyAgeChangedModal from './BabyAgeChangedModal';
import BabyTurns24Modal from './BabyTurns24Modal';
import CtaModal from './CtaModal';
import DopamineModal from './DopamineModal';
import FatalErrorModal from './FatalErrorModal';
import FeedbackModal from './FeedbackModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import GDPRModal from './GDPRModal';
import GiftCardsModal from './GiftCardsModal';
import HealthInterestsModal from './HealthInterestsModal';
import IconsGridModal from './IconsGridModal';
import InviteSentModal from './InviteSentModal';
import MasterSkillModal from './MasterSkillModal';
import MaterialsModal from './MaterialsModal';
import MilestonesModal from './MilestonesModal';
import NewDefaultFamilyModal from './NewDefaultFamilyModal';
import NoFamilyModal from './NoFamilyModal';
import NotReadyToUpgrateModal from '../pricings/NotReadyToUpgrateModal';
import NpsModal from './NpsModal';
import PassExpiredModal from './PassExpiredModal';
import PaymentModal from '../pricings/PaymentModal';
import PrematureModal from './PrematureModal';
import PremiumBrokeModal from './PremiumBrokeModal';
import PricingModal from '../pricings/PricingModal';
import PromoCodeModal from './PromoCodeModal';
import ReadyToStart from '../assesmentsComponents/ReadyToStart';
import RedeemModal from './RedeemModal';
import ScienceBehindModal from './ScienceBehindModal';
import SkillScienceModal from './SkillScienceModal';
import SurveyModal from './SurveyModal';
import ThankYouPurchaseModal from '../pricings/ThankYouPurchaseModal';
import VideoModal from './VideoModal';
import WelcomeToKinedu from '../assesmentsComponents/WelcomeToKinedu';
import MilestonesScienceModal from './MilestonesScienceModal';
import BeforeYouGoModal from './BeforeYouGoModal';
import UnmarkedMilestonesModal from './UnmarkedMilestonesModal';
import DopamineSkillModal from './DopamineSkillModal';
import ClassroomsInviteModal from './ClassroomsInviteModal';
import ClassroomsConfirmModal from './ClassroomsConfirmModal';
import ClassroomsInviteCodeModal from './ClassroomsInviteCodeModal';
import FeedbackSelectionNewBabyModal from './FeedbackSelectionNewBabyModal';
import DeleteAccountModal from './DeleteAccountModal';
import WelcomePlayModal from './WelcomePlayModal';
import WelcomeLearnModal from './WelcomeLearnModal';


export default {
  AboutAssessment,
  AboutCalculationsModal,
  AuthModal,
  BabyAgeChangedModal,
  BabyTurns24Modal,
  CtaModal,
  ClassroomsConfirmModal,
  ClassroomsInviteCodeModal,
  ClassroomsInviteModal,
  DopamineModal,
  FatalErrorModal,
  FeedbackModal,
  FeedbackSelectionNewBabyModal,
  ForgotPasswordModal,
  GDPRModal,
  GiftCardsModal,
  HealthInterestsModal,
  IconsGridModal,
  InviteSentModal,
  MasterSkillModal,
  MaterialsModal,
  MilestonesModal,
  NewDefaultFamilyModal,
  NoFamilyModal,
  NotReadyToUpgrateModal,
  NpsModal,
  PassExpiredModal,
  PaymentModal,
  PrematureModal,
  PremiumBrokeModal,
  PricingModal,
  PromoCodeModal,
  ReadyToStart,
  RedeemModal,
  ScienceBehindModal,
  SkillScienceModal,
  SurveyModal,
  ThankYouPurchaseModal,
  VideoModal,
  WelcomeToKinedu,
  MilestonesScienceModal,
  BeforeYouGoModal,
  UnmarkedMilestonesModal,
  DopamineSkillModal,
  DeleteAccountModal,
  WelcomePlayModal,
  WelcomeLearnModal  
};
