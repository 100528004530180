import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators as bind, compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { Namespaces } from '../../../values/i18n';
import { Creators } from '../../../data/ducks/modals';
import AuthView2 from '../NewDesign2021/AuthView2';
import SetupPasswordView from '../SetupPasswordView';
import AuthLeftSide from '../NewDesign2021/AuthLeftSide';
import NewKineduLogo from '../../../images/authView/new_kinedu_logo.png';
import NewKineduLogoColor from '../../../images/authView/new_kinedu_logo_color.png';
import LangButton from '../NewDesign2021/LangButton';
import { Types as AuthTypes } from '../../../values/auth';

const StyledHome = styled.div.attrs({ className: 'home' })`
  width: 100%;
  background-color: #f8f8f8;
`;

// const FakeLink = styled.span`
//   cursor: pointer;
// `;
const LeftSide = styled.div.attrs({ className: 'left-side' })`
  display: none;
  background-color: #0086D8;

  ${({ theme }) => theme.breakPoints.desktop} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    z-index: 0;
  }
`;

const RightSide = styled.div.attrs({ className: 'right-side' })`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  background-color: #FFF;

  ${({ theme }) => theme.breakPoints.desktop} {
    width: 50%;
    left: auto;
  }
`;

const Container = styled.div.attrs({ className: 'container' })`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 70px 1fr;
  height: 100%;

  ${({ theme }) => theme.breakPoints.desktop} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Navbar = styled.div`
  grid-column: 1 / span2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 1;
`;

const Logo = styled.div`
  width: 100px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${NewKineduLogoColor});

  ${({ theme }) => theme.breakPoints.desktop} {
    width: 160px;
    height: 32px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${NewKineduLogo});
  }
`;

const LangContainer = styled.div`
  display: flex;
`;

const defineSection = (location) => {
  let sectionName = '';
  if (location?.pathname?.includes('signup')) {
    sectionName = AuthTypes.SIGNUP;
  } else if (location?.pathname?.includes('login')) {
    sectionName = AuthTypes.LOGIN;
  } else if (location?.pathname?.includes('restore')) {
    sectionName = AuthTypes.SET_NEW_PASSWORD;
  }

  return sectionName;
};

const Home = ({
  history = {},
  location = {},
  i18n = {},
  openModal = () => {},
}) => {
  // const isLogin = location?.pathname?.includes('login');
  // const isSignup = location?.pathname?.includes('signup');
  // const section = isSignup ? 'signup' : 'login';
  const section = defineSection(location);
  const [authType, setAuthType] = useState(section || AuthTypes.SIGNUP);
  const setLang = useCallback(lang => i18n.changeLanguage(lang), [i18n]);


  const setAuthParam = (type) => {
    history.push({
      pathname: `/${type}`,
    });
  };

  const onAuthTypeChange = (type) => {
    setAuthType(type);
    setAuthParam(type);
  };

  const onForgotPasswordClicked = () => {
    openModal({ name: 'ForgotPasswordModal' });
    // EventReporter.action(Events.FORGOT_PASSWORD());
  };

  useEffect(() => {
    if (section === 'forgot_password') onForgotPasswordClicked();
    if (section === 'login') setAuthType(AuthTypes.LOGIN);
    if (section === AuthTypes.SET_NEW_PASSWORD) setAuthType(AuthTypes.SET_NEW_PASSWORD);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { // sets language same as navigator language
    const { language } = navigator;
    setLang('en');
    if (language.includes('es')) setLang('es');
    if (language.includes('pt')) setLang('pt');
  }, [setLang]);

  return (
    <StyledHome>
      <LeftSide />
      <RightSide />
      <Container>
        <Navbar>
          <Logo />
          <LangContainer>
            <LangButton lang="es" onLangClick={() => setLang('es')} />
            <LangButton lang="en" onLangClick={() => setLang('en')} />
            <LangButton lang="pt" onLangClick={() => setLang('pt')} />
          </LangContainer>
        </Navbar>
        <AuthLeftSide authType={authType} />

        {authType === AuthTypes.SET_NEW_PASSWORD
          ? <SetupPasswordView />
          : <AuthView2 type={authType} onAuthTypeChange={onAuthTypeChange} />
        }
      </Container>
      {/* <FakeLink onClick={() => history.push('/legacy-landing?section=buy_giftcard')}>Old landing</FakeLink> */}
    </StyledHome>
  );
};

Home.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  i18n: PropTypes.object,
  openModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => bind({ openModal: Creators.openModal }, dispatch);

export default compose(
  withTranslation([Namespaces.LANDING, Namespaces.COMMON]),
  connect(() => ({}), mapDispatchToProps),
  withRouter,
)(Home);
