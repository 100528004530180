import BaseApi, { BaseApiV4, localeFlag } from './BaseApi';

export const getMaterialsForPlan = ({ planId, babyId }) =>
  BaseApi.get(`/babies/${babyId}/activity_plans/${planId}/materials?${localeFlag()}`);

export const getPlan = ({ planId, babyId, timeZone }) =>
  BaseApi.get(`/babies/${babyId}/activity_plans/${planId}?${localeFlag()}&time_zone=${timeZone}`);

export const createPlan = (babyId, forced) =>
  BaseApi.post(`/babies/${babyId}/activity_plans?${localeFlag()}${forced ? '&force_create=true' : ''}`);

export const postActivityCompleted = ({ planId, babyId, activityId, instanceId }) => {
  const paramIdParam = planId ? `&programme_id=${planId}` : '';
  const instanceIdParam = instanceId ? `&instance_id=${instanceId}` : '';
  return BaseApi.post(`/activities/${activityId}/done?baby_id=${babyId}${paramIdParam}${instanceIdParam}`);
};

export const postCusomActivityCompleted = (params) => {
  const { babyId, activityId } = params;
  return BaseApiV4.post(`/custom_activities/custom_activities_completes?custom_activity_id=${activityId}&baby_id=${babyId}&source=playDap`);
};

export function postMilestonesAnswers({
  planId,
  babyId,
  activityId,
  answers,
  instanceId,
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const updateSource = urlParams.get('context');
  const isSourceWap = updateSource === 'wap';
  const source = updateSource === 'dap' || updateSource === 'catalog' || isSourceWap ? 'playDap' : updateSource;
  const body = {
    baby_id: babyId,
    programme_id: planId,
    activity_id: activityId,
    instance_id: instanceId,
    done_through: 'Programme',
    answers,
    force_stats: 1,
    source,
  };

  if (isSourceWap) {
    delete body.programme_id;
    delete body.instance_id;
  }

  return BaseApi.post('/milestones/save_answers', body);
}

export const getChangeActivitySuggestions = ({ babyId, activityId, option }) =>
  BaseApi.get(`/babies/${babyId}/activity_plans/suggestions?` +
    `activity_id=${activityId}&` +
    `change_option=${option}&` +
    `${localeFlag()}`);

export const postChangeActivity = ({ babyId, instanceId, newActivityId, option }) => {
  const url =
    `/babies/${babyId}/activity_plans/change?instance_id=${instanceId}${
      newActivityId ? `&new_activity=${newActivityId}}` : ''}
      ${option ? `&change_option=${option}` : ''}`;

  return BaseApi.post(url);
};

