import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../data/ducks/babiesAndFamilies';
import { Families } from '../data/ducks';
import { useCallback, useMemo } from 'react';
import { getAgeRangeOptionByAgeInMonths } from '../lib/utils';
import { updateUserLocale } from '../api/users';
import { Creators } from '../data/ducks/user';
import { LOCALSTORAGE_CALENDAR_BEARER_TOKEN } from '../shared-values';

/**
 * Hook created in order to expose user info
 * @returns
 */
function useUser(t) {
  const dispatch = useDispatch();

  const storeAccessTokenCalendar = (token) => {
    const currentToken = localStorage.getItem(LOCALSTORAGE_CALENDAR_BEARER_TOKEN);
    if (currentToken !== token) localStorage.setItem(LOCALSTORAGE_CALENDAR_BEARER_TOKEN, token);
  }
  const isClassroomUser = ({account, activeBaby}) => {
    const plans = ['classrooms_premium', 'classrooms_freemium'];

    if(plans.includes(`${account?.plan}`.toLowerCase()) && activeBaby?.classrooms_baby?.classrooms_baby_id) {
      return true;
    };

    return false;
  }

  /**
   * Redux selector
   */
  const reduxData = useSelector((state) => {
    const {
      families: {
        activeFamilyId,
        families,
        classroom,
      },
      user,
      account,
    } = state;

    const calendarAccessToken = classroom?.calendar_api?.access_token;
    storeAccessTokenCalendar(calendarAccessToken);

    // retrieves membershiplan from activeAccount
    const activeFamilyAccount = families
      ?.filter((({ id }) => `${activeFamilyId}` === `${id}`)) || [];

    const { membership_plan: membershipPlan, expires_on } = activeFamilyAccount[0] || {};
    const expiresOn = expires_on?.length === 10 ? `${expires_on} 23:59:59` : expires_on;

    // There is the possibility for a null value at expires_on. This way we take that case into account.
    const accountExpired = expiresOn ? new Date().getTime() > new Date(expiresOn.replace(/-/g, '/')).getTime() : true;

    const activeBaby = Selectors.activeBaby(state) || {};
    const isPremium = Families.Selectors.activeFamilyIsPremium(state) || false;
    const currentBabyAgeRange = getAgeRangeOptionByAgeInMonths(activeBaby?.age_in_months, t);

    const isFree = !!['free', 'lifetime', 'play'].find((v) => v === `${membershipPlan}`.toLowerCase());
    const isLearn = !!['learn', 'premium'].find((v) => v === `${membershipPlan}`.toLowerCase());
    const isPremiumUser = isPremium || `${membershipPlan}`.toLowerCase() === 'basic';
    const isBasic = `${account?.plan}`?.toLowerCase() === 'play' || `${membershipPlan}`.toLowerCase() === 'basic';

    const userData = {
      account,
      membershipPlan,
      activeBaby,
      isPremium: isPremiumUser,
      isFree: (isFree && !isBasic),
      isLearn,
      currentBabyAgeRange,
      user,
      accountExpired,
      calendarAccessToken,
      isClassroom:  isClassroomUser({ account, activeBaby }),
      isClassroomPremium:  `${account?.plan}`?.toLowerCase() === 'classrooms_premium',
      isBasic,
    };

    window.localStorage.setItem('useUser', JSON.stringify(userData));

    return userData;
  });

  const isUserLearn = useMemo(() => {
    const { membershipPlan } = reduxData;
    return membershipPlan?.includes('classroom') || membershipPlan?.includes('learn');
  }, [reduxData]);

  // useMemo(() => {
  //   if(reduxData?.activeBaby?.family_id) {
  //     dispatch(Account.Creators.fetchAccountRequested(reduxData?.activeBaby?.family_id));
  //     // dispatch(Account.Creators.fetchAccountRequested({
  //     //   familyId: reduxData?.activeBaby?.family_id
  //     // }))
  //   }

  // }, [reduxData?.activeBaby?.family_id]);

  /**
   * Makes request to update user locale in backend
   * @param {String} locale
   */
  const updateRemoteLocale = useCallback(( locale ) => {
    if (reduxData.user?.id && locale && locale !== reduxData.user?.locale) {
      updateUserLocale({ id:reduxData.user.id, locale })
      .then(() => dispatch(Creators.fetchUserDetailRequested())); // retrieves new user info
    };
  }, [reduxData.user, dispatch]);

  return {
    ...reduxData,
    isUserLearn,
    updateRemoteLocale,
  };
}

export default useUser;
