import { css } from 'styled-components';
import { breakPoints } from '../../breakPoints';

const EventCalendar = css`
* {
  font-family: 'Gotham rounded';
}

.mbsc-event-list {
  background-color: #FFFFFF;
}

.mbsc-ios.mbsc-list-item:before {
  border-top: 0;
}

.mbsc-material.mbsc-event.mbsc-list-item {
  background: #ffffff;
  color: #fff;
  margin: 0;
  border-bottom: 1px solid #E6E7E8;
  border-radius: 0;
}
.mbsc-calendar-cell {
  background-color: white;
}
.mbsc-calendar-title-wrapper {
  justify-content: center;
}

.mbsc-calendar-width-md .mbsc-calendar-day-text {
  font-size: 14px;
}

.mbsc-calendar-cell-text {
  font-size: 14px;
  font-family: 'Gotham rounded';
}

.mbsc-calendar-week-days {
  div {
    height: 37px;
    margin: 0;
    line-height: 36px;
    box-sizing: border-box;
    color: #90A2B0;
    font-weight: bold;
    font-size: 16px;
  }
}

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
  border-color: #BEE0F5;
  background: #BEE0F5;
  color: #2C343A;
}

.mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
  background: #BEE0F5;
}

.mbsc-cal-day {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  &.mbsc-selected {
    border-bottom: 1px solid #F0005C;
  }
}

.mbsc-mobiscroll .mbsc-cal-today {
  position: relative;
  &::before {
    content: 'Hoy';
    position: absolute;
    background-color: #333;
    height: 10px;
    width: 100%;
    color: white;
    font-weight: 500;
    font-size: 8px;
    left: 0;
  }
}

.mbsc-mobiscroll .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
  background-color: red;
  color: red;
}

.mbsc-mobiscroll .mbsc-cal-mark {
  background-color: #BBBDBF;
}

.mbsc-mobiscroll .mbsc-cal-today {
  color: #111;
  font-weight: 500;
}

.mbsc-ios.mbsc-calendar-mark {
  background: #45C94E;
}

.mbsc-calendar-today {
  color: #007bff;
  background-color: #0086D8;
  color: #fff !important;
}

.mbsc-ios.mbsc-calendar-today {
  color: #fff;
  font-weight: 500;
}

.mbsc-mobiscroll.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt, .mbsc-mobiscroll.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover, .mbsc-mobiscroll .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
  background:transparent;
}

.single-day-event-color {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 4px;
  border-radius: 6px;
}

.mbsc-calendar-label {
  border-radius: 9px;
}

/* Customize Calendar header */
.mbsc-calendar-header {
  background: #fff;
  border-color: #cccccc;
}
/* Customizing Next/Prev Buttons */
.mbsc-ios.mbsc-button-flat {
  color: #F0005C;
}

.mbsc-calendar-button.mbsc-calendar-button-today {
  color: #F0005C;
}

/* Customize MonthName in the calendar header to be centered instead of left aligned.*/
.mbsc-calendar-title-wrapper{
  display: flex;
  justify-content: center;
  .mbsc-calendar-button {
    color: #49494A !important;
    font-weight: 500;
  }
}

/* Customize Text {n} More when one cell has more than 3 events */
.mbsc-calendar-text-more {
  transition: background-color 0.2s;
  color: #0086D8;
  font-weight: 600;
  text-align: right !important;
  text-decoration: underline;
  font-family: 'Gotham rounded';
}

/* Customize Background in Week days row */
.mbsc-calendar-table .mbsc-calendar-week-days, .mbsc-calendar-row {
  background-color: #fff;
  color: #404040;
}

.mbsc-schedule-date-header {
  border-color: transparent;
  font-weight: 400;
  font-size: 14px;
}

.mbsc-ios-dark.mbsc-calendar-cell {
    background: white;
    border-color: #cccccc;
}

.mbsc-ios-dark.mbsc-calendar-today {
  background: rgb(0, 134, 216, 0.2);
  color: #000;
}

.mbsc-ios-dark.mbsc-eventcalendar {
  background: #000;
  color: #fff;
}

.mbsc-ios-dark.mbsc-calendar-wrapper {
    border-color: #cccccc;
}

.mbsc-schedule-date-header {
  display: block;
  color: transparent !important;
  background: #F0F0F0 !important;
  height: 10px !important;
}

.mbsc-material.mbsc-selected .mbsc-calendar-cell-text {
  background: #0086D8;
  border-color: #CCE7F7;
  color: #4A4A4A
}

.mbsc-material.mbsc-calendar-cell-text:not(.mbsc-selected) {
  font-weight: 500;
}

@media screen and (min-width: 862px) {
  .mbsc-calendar-day-text {
    font-weight: 500;
  }
  .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    font-weight: 500;
  }
}

.mbsc-calendar-day-inner {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  ${breakPoints.tablet} {
    text-align: right;
}
}

.mbsc-material.mbsc-calendar-mark {
  background: #45C94E;
}

.mbsc-schedule-date-header ,mbsc-material {
  display: none;
}
`;

export default EventCalendar;
