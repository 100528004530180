import { ofType } from 'redux-observable';
import { take, startWith, pluck, mergeMap } from 'rxjs/operators';
import { Creators, Types } from '../../ducks/babiesAndFamilies';

// eslint-disable-next-line
export const fetchFamilies$ = (stream$, afterFetch) =>
  stream$.pipe(
    ofType(Types.FETCH_FAMILIES_SUCCESS),
    take(1),
    pluck('families'),
    mergeMap(afterFetch),
    startWith(Creators.fetchFamiliesRequested()),
  );
