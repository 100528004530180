import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import CreditCardType from 'credit-card-type';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { colors } from '../../styles';
import TextInput from './TextInput';

import Visa from '../../images/payment-methods/visa.png';
import MasterCard from '../../images/payment-methods/master-card.png';
import Maestro from '../../images/payment-methods/maestro.png';
import AmericanExpress from '../../images/payment-methods/american-express.png';
import { VALIDATIONS_MESSAGES } from '../../values/validations';
import { replaceMaskChar } from '../../lib/utils';

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  box-sizing: border-box;

  .input-error {
    position: absolute;
    color: #ffffff;
    text-shadow: 1px 1px 4px red;
    font-weight: 400;
    font-size: 12px;
    margin: 3px 0;
  }
`;

const Card = styled.div`
  display: inline-block;
  background-color: gray;
  width: 340px;
  height: 212px;

  padding: 25px 16px;
  box-sizing: border-box;

  border-radius: 8px;

  label {
    font-size: 11px;
    font-weight: 500;
    text-align: left;
    margin: 8px 0 2px 0;
    letter-spacing: -0.38px;
  }

  input {
    color: #657480;
    background-color: #FFF;

    font-size: 12px;
    font-weight: 500;

    text-align: left;
    padding: 10px;
    height: 28px;

    direction: ltr;

    &::placeholder {
      color: #657480;
    }
  }

  .date {
    width: 30%;
    display: inline-block;
    input {
      padding: 0 0 0 5px;
    }

    &:nth-child(2) {
      input {
        border-left: 0;
        border-right: 0;
        border-radius: 0;
      }
    }

    &:last-child {
      input {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3.6px;
        border-bottom-right-radius: 3.6px;
      }
    }
  }

  .number {
    input {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .name {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 900px) {
    width: 433px;
    height: 270px;
    padding: 25px;

    input {
      font-size: 16px;
      height: 32px;
    }

  }
`;

const FrontCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;

  direction: rtl;
  left: 0;

  color: ${colors.white};
  background: ${colors.newKinedu};
  box-shadow: 0px 2px 10px rgba(0,0,0,0.3);
  z-index: 1;

  label {
    color: #FFF;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 37px;
    height: 26px;
    top: 18px;

    border: 1px dashed #fff;
    border-radius: 3px;

    ${props => props.cardType === 'visa' && css`
      background-image: url(${Visa});
      border: none;
    `};

    ${props => props.cardType === 'american-express' && css`
      background-image: url(${AmericanExpress});
      border: none;
    `};

    ${props => props.cardType === 'maestro' && css`
      background-image: url(${Maestro});
      border: none;
    `};

    ${props => props.cardType === 'master-card' && css`
      background-image: url(${MasterCard});
      border: none;
    `};

    background-size: cover;
    background-position: center;
  }

  @media screen and (min-width: 900px) {
    box-shadow: 8px 0px 10px rgba(0,0,0,0.2);

    &::before {
      width: 46px;
      height: 31px;
    }
  }
`;

const CardTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  text-align: left;

  margin-bottom: 25px;

  @media screen and (min-width: 900px) {
    font-size: 20px;
  }
`;

const DateInputs = styled.div`
  display: inline-flex;
  flex-direction: row-reverse;
`;

class CreditCard extends Component {
  state = { cardType: '' }

  cardNumberChanged = (e) => {
    let cardType;
    const cardNumber = replaceMaskChar(e.target.value);
    if (cardNumber.length > 0) {
      const [card] = CreditCardType(cardNumber);
      cardType = card ? card.type : undefined;
    }

    this.setState({ cardType });
  }

  render() {
    const { t } = this.props;
    return (
      <CardWrapper>
        <FrontCard cardType={this.state.cardType}>
          <CardTitle>{t('PAYMENT_DETAILS')}</CardTitle>
          <TextInput
            name="name"
            className="name"
            placeholder={t('payments:NAME_OF_CARD')}
            validations={{ isSpecialWords: true, minLength: 4 }}
            validationErrors={{
              isDefaultRequiredValue: t(`validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`),
            }}
            required
          />
          <DateInputs>
            <TextInput
              name="number"
              className="number"
              type="credit-card"
              placeholder={t('CARD_NUMBER')}
              mask="9999-9999-9999-99999999"
              validations={{ minLength: 12 }}
              validationErrors={{
                isNumeric: t(`validations:${VALIDATIONS_MESSAGES.IS_NUMERIC}`),
              }}
              maxLength={24}
              onChange={this.cardNumberChanged}
              removeMaskChar
              required
            />
            <TextInput
              name="expDate"
              className="date"
              placeholder={t('payments:CARD_EXPIRATION_DATE')}
              mask="99/99"
              removeMaskChar
              maxLength={5}
              validations={{ matchRegexp: /^(0?[1-9]|1[012])\/[0-9]{2}$/ }}
              required
            />
            <TextInput
              className="date"
              name="cvc"
              placeholder="CVV"
              validations={{ isNumeric: true, minLength: 3, maxLength: 4 }}
              validationError=""
              maxLength="4"
              required
            />
          </DateInputs>
        </FrontCard>
      </CardWrapper>
    );
  }
}

CreditCard.propTypes = {
  t: PropTypes.func,
};
CreditCard.defaultProps = {
  t: () => {},
};

export default withTranslation([Namespaces.MENU, Namespaces.COMMON])(CreditCard);
