import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NeoModal from './NeoModal';
import { breakPoints } from '../../styles';
import BeforeYouGoView from '../views/BeforeYouGoView/BeforeYouGoView';

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  margin: auto 20px;

  ${breakPoints.smallTablet} {
    margin: auto;
  }
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'BeforeYouGoModal',
  data: {
    baby: {
      name: 'Johnny',
      gender: 'male',
    }
  },
}

*/

const BeforeYouGoModal = ({ action, baby, saveAnswers }) => (
  <Modal width={460} closeOnBackdrop={false} roundedCorners>
    {({ closeModal }) => (
      <Fragment>
        <BeforeYouGoView
          closeModal={closeModal}
          continueAction={action}
          saveProgress={saveAnswers}
          baby={baby}
        />
      </Fragment>
    )}
  </Modal>
);

BeforeYouGoModal.propTypes = {
  action: PropTypes.func,
  saveAnswers: PropTypes.func,
  baby: PropTypes.object,
};

BeforeYouGoModal.defaultProps = {
  action: () => {},
  saveAnswers: () => {},
  baby: {},
};

export default BeforeYouGoModal;
