import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../styles';

const Types = {
  flat: css`
  color: ${colors.darkGray};
  text-transform: uppercase;
  text-align: center;

  font-size: 12px;
  font-weight: 400;
  letter-spacing: 3.6px;

  display: block;
  margin-bottom: 10px;
`,
  neo: css`
    color: ${colors.shadeMediumDark};
    text-transform: uppercase;
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4.57px;

    display: block;
    margin-bottom: 10px;
`,
};

const Label = styled.label`
  ${({ type }) => Types[type]};
  ${({ labelStyle }) => labelStyle};
`;

Label.propTypes = {
  type: PropTypes.string,
};
Label.defaultProps = {
  type: 'flat',
};

export default Label;
