import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakPoints } from '../../styles';
import Plan1MonthImg from '../../images/PremiumGiftPlan_1Month.png';
import Plan12MonthImg from '../../images/PremiumGiftPlan_12Month.svg';
import PlanLifetimeImg from '../../images/PremiumGiftPlan_Lifetime.svg';
import ProductList, { COUNTRIES, ProductsList } from '../../values/prices/products';
import { getCountryNameByInitials, getVisitorIPCountry } from '../../lib/utils';

const PlanButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 299px;
  height: 140px;
  background-size: cover;
  background-position-y: center;
  box-shadow: 0 4px 15px -5px rgba(0,0,0,.2);
  border-radius: 16px;
  font-weight: bold;
  padding: 20px 18px;

  position: relative;
  box-sizing: border-box;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  margin-bottom: 10px;
  background-image: url(${({ contentInfo }) => contentInfo.image});
  color: ${({ contentInfo }) => contentInfo.color};

  .plan-price {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;
  }
  .money-sign {
    font-size: 30px;
  }
  .quantity {
    font-size: 44px;
    letter-spacing: -0.88px;
    line-height: 29.94px;
    margin-left: 2px;
  }
  .currency {
    font-size: 12px;
    margin-left: 4px;
    letter-spacing: 0;;
    line-height: 29.94px;
  }
  .plan-duration {
    font-weight: normal;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 10px;
    font-size: 18px;
    letter-spacing: 2.25px;
    text-align: right;
  }

  ${breakPoints.smallTablet} {
    padding: 29px 17px;
    height: 200px;
    width: 246px;
    background-position-y: bottom;
    margin-bottom: 0;
    justify-content: end;

    .plan-price {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 8px;
    }
    .quantity {
      /* font-size: 50px; */
    }
    .currency {
      font-size: 14px;
    }
    .plan-duration {
      /* font-size: 24px; */
    }
  }
`;


const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
  box-sizing: border-box;
`;

const PremiumPlanButton = ({ giftcardSKU, isSelected = false, onClick = null, lang }) => {
  const { t } = useTranslation();
  const [localizedPrice, setLocalizedPrice] = useState(null);
  const [buttonContent, setButtonContent] = useState({ color: '', image: null, title: '' });

  useEffect(() => {
    let planType = '';
    let buttonContentValues = {};
    // eslint-disable-next-line default-case
    switch (giftcardSKU) {
      case ProductsList.gc_monthly?.localizedSKU?.[COUNTRIES.BR]:
      case ProductsList.gc_monthly.SKU:
        planType = 'gc_monthly';
        buttonContentValues = {
          title: `1 ${t('common:MONTH', { count: 1 })}`,
          color: '#D1296D',
          image: Plan1MonthImg,
        };
        break;
      case ProductsList.gc_yearly?.localizedSKU?.[COUNTRIES.BR]:
      case ProductsList.gc_yearly.SKU:
        planType = 'gc_yearly';
        buttonContentValues = {
          title: `12 ${t('common:MONTH', { count: 12 })}`,
          color: '#0086D8',
          image: Plan12MonthImg,
        };
        break;
      case ProductsList.gc_lifetime?.localizedSKU?.[COUNTRIES.BR]:
      case ProductsList.gc_lifetime.SKU:
        planType = 'gc_lifetime';
        buttonContentValues = {
          title: t('common:LIFETIME'),
          color: '#3EB646',
          image: PlanLifetimeImg,
        };
        break;
    }

    setButtonContent(buttonContentValues);

    // const price = ProductList[planType].localizedPrice[getCountryNameByInitials('WW')];
    // const formattedAmount = new Intl.NumberFormat(
    //   price?.currency?.preferredLocaleFormat,
    //   { minimumFractionDigits: 2 },
    // ).format(price?.amount);
    // setLocalizedPrice({ ...price, formattedAmount });

    setButtonContent(buttonContentValues);

    // Uncomment for regionalized prices, and remove the above code block

    getVisitorIPCountry().then((code) => {
      console.log('%c DEBUG: code', 'background: #332167; color: #b3d1f6; font-size: 14px', code);
      const countryScope = lang === 'pt' ? COUNTRIES.BR : COUNTRIES.WW;
      const country = lang ? countryScope : getCountryNameByInitials(code);
      const price = ProductList[planType].localizedPrice[country];
      const formattedAmount = new Intl.NumberFormat(
        price?.currency?.preferredLocaleFormat,
        { minimumFractionDigits: 2 },
      ).format(price?.amount);
      setLocalizedPrice({ ...price, formattedAmount });
    }).catch(() => {
      const price = ProductList[planType]?.localizedPrice[COUNTRIES.WW];
      const formattedAmount = new Intl.NumberFormat(
        price?.currency?.preferredLocaleFormat,
        { minimumFractionDigits: 2 },
      ).format(price?.amount);
      setLocalizedPrice({ ...price, formattedAmount });
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftcardSKU]);

  return (
    <>
      <PlanButton
        contentInfo={buttonContent}
        onClick={localizedPrice ? onClick : null}
      >
        <Wrapper className={isSelected ? 'plan-selected' : ''} />
        {localizedPrice && (
          <>
            <div className="plan-price">
              <span className="money-sign">
                {localizedPrice?.currency?.displaySymbol && localizedPrice?.currency?.symbol}
              </span>
              <span className="quantity">
                {localizedPrice?.formattedAmount}
              </span>
              <span className="currency">
                {localizedPrice?.currency?.displayCode && localizedPrice?.currency?.code}
              </span>
            </div>
            <div className="plan-duration">
              {buttonContent.title}
            </div>
          </>
        )}
      </PlanButton>
    </>
  );
};

export default PremiumPlanButton;
