import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import NeoModal from './NeoModal';
import PromoCode from '../views/PromoCode/PromoCode';

const Modal = styled(NeoModal)``;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'PromoCodeModal',
  data: {
  },
}

*/

const PromoCodeModal = ({ t }) => (
  <Modal width={600} roundedCorners>
    {({ closeModal }) => (
      <Fragment>
        <PromoCode closeModal={closeModal} t={t} />
      </Fragment>
    )}
  </Modal>
);

PromoCodeModal.propTypes = {
  t: PropTypes.func,
};

PromoCodeModal.defaultProps = {
  t: () => {},
};

export default withTranslation([Namespaces.MODALS])(PromoCodeModal);
