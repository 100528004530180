import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import NeoModal from './NeoModal';
import { breakPoints } from '../../styles';

import FeedBackView from '../views/Feedback_';

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;

  ${breakPoints.smallTablet} {
    margin: auto;
    height: auto;
  }
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'FeedbackModal',
  data: {
    title: 'Thank you for your information!',
    subtitle: 'Bebe2go will send you...',
    image: '',
    textColor: '#FFF',
    bgColor: '#1B75BB',
  },
}

*/

const FeedbackModal = ({
  isOpen,
  title,
  subtitle,
  image,
  textColor,
  bgColor,
}) => (
  <Modal width="700" isOpen={isOpen}>
    {() => (
      <Fragment>
        <FeedBackView
          title={title}
          subtitle={subtitle}
          image={image}
          textColor={textColor}
          bgColor={bgColor}
        />
      </Fragment>
    )}
  </Modal>
);

FeedbackModal.propTypes = {
  isOpen: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
};

FeedbackModal.defaultProps = {
  isOpen: () => {},
  title: undefined,
  subtitle: undefined,
  image: undefined,
  textColor: undefined,
  bgColor: undefined,
};

export default withTranslation([Namespaces.MODALS])(FeedbackModal);
