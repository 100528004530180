import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoWrapper, {
  BenefitsContainer,
  Logo,
  BenefitsTitle,
  BenefitsList,
  BenefitItem,
  Note,
} from './styles';

const LeftInfoContentComponent = () => {
  const { t: translation } = useTranslation();
  return (
    <InfoWrapper>
      <Logo />
      <BenefitsContainer>
        <BenefitsTitle>
          {translation('common:AUTH.KINEDU_BENEFITS_TITLE')}
        </BenefitsTitle>
        <BenefitsList>
          <BenefitItem>
            {translation('common:AUTH.KINEDU_BENEFITS_ITEM_1')}
          </BenefitItem>
          <BenefitItem>
            {translation('common:AUTH.KINEDU_BENEFITS_ITEM_2')}
          </BenefitItem>
          <BenefitItem>
            {translation('common:AUTH.KINEDU_BENEFITS_ITEM_3')}
          </BenefitItem>
        </BenefitsList>
        <Note>{translation('common:AUTH.KINEDU_BENEFITS_NOTE')}</Note>
      </BenefitsContainer>
    </InfoWrapper>
  );
};

LeftInfoContentComponent.propTypes = {};

LeftInfoContentComponent.defaultProps = {};

export default LeftInfoContentComponent;
