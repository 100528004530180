import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react-2';
import { path, toPairs } from 'ramda';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import Avatar from '../../shared/AvatarDropzone';
import TextInput from '../../shared/TextInput';
import Select from '../../shared/NewSelect';
import Button from '../../shared/buttons/MainButton';

import { UserRoles, AvatarPlaceholders } from '../../../values/roles';
import { Neo } from '../../themes/TextInput';
import { Neo as NeoAvatar } from '../../themes/AvatarDropzone';
import { Neo as NeoSelect } from '../../themes/NewSelect';
import Label from '../../shared/Label';
import { MonthsList, YearList, DayList } from '../../../shared-values';

const EditUserViewContainer = styled.div`
  max-width: 300px;
  margin: 10px auto;
  padding: 30px;
`;

const InlineInputs = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: auto;
  }
`;

const Form = styled(Formsy.Form)`
  width: 100%;
  min-width: 300px;
  margin-bottom: 30px;

  padding: 0px;
  box-sizing: border-box;

  > * {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 20px;
  }
`;

class EditUserView extends Component {
  state = { submitEnabled: false };

  componentDidMount = () => {
    this.setState({
      yearList: YearList,
      dayList: DayList,
    });
  }

  toggleSubmit = val => this.setState({ ...this.state, submitEnabled: val });

  render() {
    const { closeModal, user, activeFamily, onSubmit, t } = this.props;
    const relationship = path(['membership', 'relationship'], activeFamily);
    const AvatarPlaceholder = AvatarPlaceholders[UserRoles[relationship]];
    const userAvatar = path(['avatar', 'size1'], user);
    const { name, lastname, birthday } = user;
    const birthdayParts = birthday ? birthday.split('-') : [];
    const [year = 1990, month = 1, day = 1] = birthdayParts.map(b => Number(b));
    const months = toPairs(MonthsList).map(([value, label]) => ({ label: t(`common:${label}`), value }));

    return (
      <EditUserViewContainer>
        <Form
          onSubmit={onSubmit}
          onValid={() => this.toggleSubmit(true)}
          onInvalid={() => this.toggleSubmit(false)}
        >
          <Avatar
            name="avatar"
            label={t('MY_PROFILE_PICTURE')}
            defaultImage={userAvatar === 'missing' ? AvatarPlaceholder : userAvatar}
            theme={NeoAvatar}
            circle
          />
          <TextInput
            name="name"
            label={t('NAME')}
            placeholder={t('NAME')}
            value={name}
            theme={Neo}
            validations={{
              minLength: 2,
            }}
            validationErrors={{
              minLength: t('validations:TEXT_LONGER_THAN', { text: t('NAME'), count: 1 }),
            }}
            required
          />
          <TextInput
            name="lastname"
            label={t('LASTNAME')}
            placeholder={t('LASTNAME')}
            value={lastname}
            theme={Neo}
            validations={{
              minLength: 2,
            }}
            validationErrors={{
              minLength: t('validations:TEXT_LONGER_THAN', { text: t('LASTNAME'), count: 1 }),
            }}
            required
          />
          <div>
            <Label type="neo">{t('BIRTHDAY')}</Label>
            <InlineInputs>
              <Select
                name="month"
                theme={NeoSelect}
                containerStyle={css`flex-grow: 0.7;`}
                options={months}
                value={month - 1}
              />
              <Select
                name="day"
                theme={NeoSelect}
                containerStyle={css`flex-grow: 0.05;`}
                options={this.state.dayList}
                value={day}
              />
              <Select
                name="year"
                theme={NeoSelect}
                containerStyle={css`flex-grow: 0.15;`}
                options={this.state.yearList}
                value={year}
              />
            </InlineInputs>
          </div>
          <div>
            <Button
              type="submit"
              color={this.state.submitEnabled ? 'green' : 'disabled'}
              disabled={!this.state.submitEnabled}
            >
              {t('common:SAVE_CHANGES')}
            </Button>
            <Button
              type="button"
              color="clear"
              onClick={closeModal}
            >
              {t('common:CANCEL')}
            </Button>
          </div>
        </Form>
      </EditUserViewContainer>
    );
  }
}

EditUserView.propTypes = {
  user: PropTypes.object,
  activeFamily: PropTypes.object,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
  t: PropTypes.func,
};

EditUserView.defaultProps = {
  user: {},
  activeFamily: {},
  closeModal: () => {},
  onSubmit: () => {},
  t: () => {},
};

export default withTranslation([
  Namespaces.MENU,
  Namespaces.COMMON,
  Namespaces.VALIDATIONS,
])(EditUserView);
