import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakPoints, colors } from '../../styles';

import Trophy from '../../images/Trophy.svg';

const TrophyRibbonContainer = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: baseline;
  width: 100%;
  max-width: 1em;
  max-height: 0.8em;
  font-size: 100px;
  font-size: ${({ size }) => `${size}px`};

  ${breakPoints.smallTablet} {
    font-size: ${({ smSize }) => `${smSize}px`};
  }
`;

const Image = styled.img.attrs({ src: Trophy })`
  width: 100%;
`;

const ImageText = styled.p`
  position: absolute;
  bottom: 4%;

  width: 100%;
  color: ${colors.white};
  font-size: 0.072em;
  font-weight: 500;
  text-align: center;
  letter-spacing: -1.23px;

  ${breakPoints.smallTablet} {
    font-size: 0.07em;
  }
`;

const TrophyRibbon = ({ children, size, smSize }) => (
  <TrophyRibbonContainer size={size} smSize={smSize}>
    <Image />
    <ImageText>{children}</ImageText>
  </TrophyRibbonContainer>
);

TrophyRibbon.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
  smSize: PropTypes.number,
};
TrophyRibbon.defaultProps = {
  children: null,
  size: 100,
  smSize: 100,
};

export default TrophyRibbon;
