import React from 'react';
import PropTypes from 'prop-types';
import DropzoneComponent from 'react-dropzone-component';
import styled, { css, ThemeProvider } from 'styled-components';
import Formsy from 'formsy-react-2';
import 'react-dropzone-component/styles/filepicker.css';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Default, ThemeShape } from '../themes/AvatarDropzone';
import Icon from './Icon';

const Container = styled.div`
  ${({ theme }) => theme.container};

  .filepicker {
    ${({ theme }) => theme.filepicker};

    ${props => props.circle && css`
      border-radius: 100px;
      overflow: hidden;
    `}

    background-image: url(${props => props.defaultImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .dz-message {
      ${({ theme }) => theme.message};
    }

    .dz-preview {
        border-radius: 0px;
        margin: 0;
        width: 100%;
        height: 100%;

        &.dz-image-preview {
          position: absolute;
          top: 0;
          left: 0;
          background-color: transparent;
        }

        .dz-details {
          opacity: 1;
          padding: 0;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
          }
        }

        ${({ theme }) => theme.preview};

        .dz-error-mark {
          ${({ theme }) => theme.errorMark};
        }

        .dz-success-mark {
          ${({ theme }) => theme.successMark};
        }

        .dz-remove {
          ${({ theme }) => theme.remove};
        }
      }

    ${props => props.fillSpace && css`
      width: 100%;
      height: 100%;
      margin-bottom: 0px;
    `}

  }
`;

const Label = styled.label`
  ${({ theme }) => theme.label};
`;

const IconButton = styled.button.attrs({ type: 'button' })`
  ${({ theme }) => theme.iconButton};
`;

const componentConfig = {
  showFiletypeIcon: false,
  postUrl: '/',
};

const djsConfig = {
  uploadMultiple: false,
  acceptedFiles: 'image/jpg,image/png',
  dictRemoveFile: 'x',
  dictResponseError: 'Server responded with {{statusCode}} code.',
  previewTemplate: `<div class="dz-preview dz-file-preview">
  <div class="dz-details">
    <img data-dz-thumbnail />
  </div>
  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  <div class="dz-success-mark"><span>Success</span></div>
  <div class="dz-error-mark"><span>Error</span></div>
</div>`,
  thumbnailWidth: 120,
  thumbnailHeight: 120,
};

class AvatarDropzone extends React.Component {
  constructor(props) {
    super(props);
    this.dropzone = {};
    this.eventHandlers = {
      addedfile: file => this.onAddedFile(file),
      thumbnail: (file, dataUrl) => this.onThumbnail(file, dataUrl),
      removedfile: this.props.resetValue,
      init: (dropzone) => { this.dropzone = dropzone; },
    };
  }

  onThumbnail = (file, dataUrl) => {
    this.props.onAddedFile(file, dataUrl);
  }

  onAddedFile = (file) => {
    // Aún no hay post para el family picture
    this.props.setValue(file);
  }

  removeFile = () => {
    if (this.dropzone) {
      this.dropzone.removeFile();
    }
  }

  triggerClick = () => {
    if (this.dropzone) {
      this.dropzone.element.click();
    }
  }

  render() {
    const {
      fillSpace,
      transparent,
      defaultImage,
      circle,
      label,
      hideButton,
      children,
      className,
      theme,
      triggerClick,
      config,
      t,
    } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Container
          fillSpace={fillSpace}
          transparent={transparent}
          defaultImage={defaultImage}
          circle={circle}
          className={className}
          triggerClick={triggerClick(this.triggerClick)}
        >
          {label && <Label>{label}</Label>}
          <DropzoneComponent
            config={componentConfig}
            djsConfig={{ ...djsConfig, ...config }}
            eventHandlers={this.eventHandlers}
          >
            <div className="dz-message">{!defaultImage && t('ADD_PICTURE')}</div>
            {children}
          </DropzoneComponent>
          {!hideButton
            && (
            <IconButton onClick={this.triggerClick}>
              <Icon size={13} color="white" icon="camera" />
            </IconButton>
            )
          }
        </Container>
      </ThemeProvider>
    );
  }
}

AvatarDropzone.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  transparent: PropTypes.bool,
  fillSpace: PropTypes.bool,
  circle: PropTypes.bool,
  hideButton: PropTypes.bool,
  defaultImage: PropTypes.string,
  theme: ThemeShape,
  resetValue: PropTypes.func,
  setValue: PropTypes.func,
  triggerClick: PropTypes.func,
  onAddedFile: PropTypes.func,
  t: PropTypes.func,
};

AvatarDropzone.defaultProps = {
  label: '',
  children: '',
  transparent: false,
  fillSpace: false,
  circle: false,
  hideButton: false,
  defaultImage: '',
  theme: Default,
  resetValue: () => { },
  setValue: () => { },
  triggerClick: () => { },
  onAddedFile: () => { },
  t: () => { },
};

export default Formsy.HOC(withTranslation([Namespaces.COMMON])(AvatarDropzone));
