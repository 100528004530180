import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import NeoModal from './NeoModal';
import { breakPoints } from '../../styles';

import ActivityCompleted from '../views/DopamineShot/ActivityCompleted';
import ThreeDaysInARow from '../views/DopamineShot/ThreeDaysInARow';
import FinishDAP from '../views/DopamineShot/FinishDAP';
import AllMilestones from '../views/DopamineShot/AllMilestones';
import { getRandomNumberInRange } from '../../lib/utils';

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;

  max-width: 720px;
  height: 100%;
  margin: auto;

  background-color: transparent;
  overflow: visible;

  ${breakPoints.smallTablet} {
    height: 525px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'DopamineModal',
  data: {
    dsType: 'three_milestones',
    baby: {
      name: 'Johnny',
    }
  },
}

*/

const ViewByDsType = ({
  type, version, baby, onClose, goToPastPlans,
}) => {
  switch (type) {
    case 'activity_completed':
      return (<ActivityCompleted version={version} baby={baby} onClose={onClose} />);
    case 'three_days_in_row':
      return (<ThreeDaysInARow version={version} baby={baby} onClose={onClose} />);
    case 'finished_plan':
      return (
        <FinishDAP
          version={version}
          baby={baby}
          onClose={onClose}
          onPastPlans={goToPastPlans}
        />
      );
    case 'three_milestones':
      return (<AllMilestones version={version} baby={baby} onClose={onClose} />);
    default: return '';
  }
};

ViewByDsType.propTypes = {
  type: PropTypes.string,
  version: PropTypes.number,
  baby: PropTypes.object,
  onClose: PropTypes.func,
  goToPastPlans: PropTypes.func,
};

ViewByDsType.defaultProps = {
  type: 'activity_completed',
  version: 0,
  baby: {},
  onClose: () => { },
  goToPastPlans: () => { },
};

class DopamineModal extends React.Component {
  state = { version: 1 };

  componentDidMount = () => this.setState({ version: getRandomNumberInRange(1, 4) });

  goToPastPlans = (closeModal) => { closeModal(); this.props.history.push('/past-plans'); };

  handleOnClose = (closeModal) => { closeModal(); this.props.history.push('/dap/current/'); }

  handleOnClose = () => {
    this.props.onClose();
    if (!window.location.pathname.includes('/wap')) {
      this.props.history.push('/dap/current/');
    } else {
      this.props.push('/dap/current/');
      setTimeout(() => {
        this.props.history.push('/wap');
      }, 100);
    }
  }

  render() {
    const { dsType, baby } = this.props;
    return (
      <Modal maxWidth={600}>
        {({ closeModal }) => (
          <Content>
            <ViewByDsType
              type={dsType}
              version={this.state.version}
              baby={baby}
              onClose={() => this.handleOnClose(closeModal)}
              goToPastPlans={() => this.goToPastPlans(closeModal)}
            />
          </Content>
        )}
      </Modal>
    );
  }
}

DopamineModal.propTypes = {
  dsType: PropTypes.string,
  baby: PropTypes.object,
  history: PropTypes.object,
};

DopamineModal.defaultProps = {
  dsType: 'activity_completed',
  baby: {},
  history: {},
};

export default withTranslation([Namespaces.MODALS])(withRouter(DopamineModal));
