import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';

import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import { Types, Creators } from '../ducks/onBoarding';
import { Creators as BabyCreators } from '../ducks/babiesAndFamilies';
import { Creators as IACreators } from '../ducks/initialAssessment';

import * as BabiesApi from '../../api/babies';
import { startOB2IAFlow } from './orchestrators/OB2IA';
import { OBTypes } from '../../values/onBoarding';
import EventReporter, { setUserAttributes, Events } from '../../lib/EventReporter';
import { LOCALSTORAGE_BABY_STATUS, LOCALSTORAGE_GA_CREATEBABY_EVENT_SENT } from '../../shared-values';
import { Modals } from '../ducks';


/** Helpers */
const createBabyObservable = action => Observable.fromPromise(
  BabiesApi.postBaby({
    familyId: action.familyId,
    name: action.name,
    lastname: action.lastname,
    gender: action.gender,
    birthday: `${action.birthday.year}-${action.birthday.month}-${action.birthday.day}`,
    weeksBeforeBirth: action.weeksBeforeBirth,
    avatar: action.avatar,
  }).then(res => res.data.data.baby).catch(() => Observable.of({ type: 'CREATE BABY ERROR' })),
);

/** Epics */

const start = action$ => action$.ofType(Types.START).mapTo(push('/ob'));

/** This is needed to start the OB flow but with the IA flow after */
const startOb2ia = action$ => action$.ofType(Types.START_OB2IA)
  .mapTo(startOB2IAFlow({ OBType: OBTypes.PARTIAL }));

/** TODO: ADD DOCS TO THIS, ACTION IS FORM'S BABY, BABY IS RESPONSE BABY LOL MIDNIGHT CODING */
const weightAndHeightActions = (action, baby) => {
  const actions = [];
  const {
    height, heightUnit, weight, weightUnit,
  } = action;

  if (weight) {
    actions.push(BabyCreators.addBabyWeight({ babyId: baby.id, weight, weightUnit }));
  }
  if (height) {
    actions.push(BabyCreators.addBabyHeight({ babyId: baby.id, height, heightUnit }));
  }
  return actions;
};

const createBaby = action$ => action$.ofType(Types.CREATE_BABY)
  .mergeMap(action => createBabyObservable(action)
    .concatMap((babyResponse) => {
      if (!localStorage.getItem(LOCALSTORAGE_GA_CREATEBABY_EVENT_SENT)) {

        localStorage.setItem(LOCALSTORAGE_GA_CREATEBABY_EVENT_SENT, true);
      }
      setUserAttributes({ userHaveTwins: 'No' });
      const actions = [
        ...weightAndHeightActions(action, babyResponse),
        Creators.setPrematureData({
          premature: babyResponse.is_premature,
          developmentalAge: babyResponse.developmental_age_in_months,
        }),
        BabyCreators.fetchFamiliesRequested(),
        BabyCreators.babySelected(babyResponse.id),
        IACreators.startIa({ babyId: babyResponse.id }),
      ];
      if (localStorage.getItem(LOCALSTORAGE_BABY_STATUS) === 'classroomsBaby') {
        localStorage.removeItem('temporaryClassroomsBaby');
        localStorage.removeItem('CREATE_BABY_AFTER_JOIN');
        actions.push(Modals.Creators.openModal({
          name: 'ClassroomsInviteCodeModal',
          data: { babyResponse },
        }));
      }
      return (actions);
    })
    .catch(() => {
      // TODO: Implement specific error messages.
      // const errorList = R.keys(err.response.data.error_data.errors);
      toast.error('There was a problem');
      return [Creators.createBabyError()];
    }));

const createTwins = action$ => action$.ofType(Types.CREATE_TWINS)
  .mergeMap(({ baby1, baby2 }) => Observable.zip(createBabyObservable(baby1), createBabyObservable(baby2))
    .flatMap(([babyRes1, babyRes2]) => {
      EventReporter.action(Events.OB_CREATE_TWINS({ source: 'ob' }));
      setUserAttributes({ userHaveTwins: 'Yes' });
      return ([
        ...weightAndHeightActions(baby1, babyRes1),
        ...weightAndHeightActions(baby2, babyRes2),
        // Pick any baby, as both will have the same birthdate
        Creators.setPrematureData({
          premature: babyRes1.is_premature,
          developmentalAge: babyRes1.developmental_age_in_months,
        }),
        BabyCreators.fetchFamiliesRequested(),
        BabyCreators.babySelected(babyRes1.id),
        IACreators.startIa({ twinIds: [babyRes1.id, babyRes2.id] }),
      ]);
    }).catch(() => {
      // TODO: Implement specific error messages.
      // const errorList = R.keys(err.response.data.error_data.errors);
      toast.error('There was a problem');
      return [Creators.createTwinsError()];
    }));

const endIfNotPremature = action$ => action$.ofType(Types.SET_PREMATURE_DATA)
  .filter(({ premature, developmentalAge }) => (!premature || developmentalAge >= 24))
  .mapTo(Creators.end());


const observers = {
  start,
  createBaby,
  createTwins,
  endIfNotPremature,
  startOb2ia,
};

export default combineEpics(...Object.values(observers));
