export const NPS_SURVEY = [
  {
    header: 'HOW_DO_YOU_RATE_US',
    question: 'ON_A_SCALE',
    question_type: 'nps',
    required: true,
  },
  {
    header: 'TELL_US_MORE',
    question: 'WHAT_CAN_WE_CHANGE',
    question_type: 'open',
  },
];

export const SATISFACTION_SURVEY = [
  {
    header: 'WE_LOVE_FEEDBACK',
    question: 'WHAT_DO_YOU_THINK',
    question_type: 'choice',
    options: [
      'SATISF_A1',
      'SATISF_A2',
      'SATISF_A3',
      'SATISF_A4',
      'SATISF_A5',
    ],
    multiple: true,
  },
  {
    question: 'WHAT_CAN_WE_CHANGE',
    question_type: 'open',
  },
];
