import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StaggeredMotion, spring, presets } from 'react-motion';
import { range } from 'ramda';

import { colors, breakPoints } from '../../styles';
import BabyOverBooks from '../../images/BabyOverBooks.png';
import Button from '../shared/buttons/MainButton';
// import BlurredSkillCard from './BlurredSkillCard';
import { genderAdposition } from '../../lib/utils';
import ProgressSkillCard from './ProgressSkillCard';
import { PaymentSources } from '../../values/prices/paymentSources';

const BlurredSkillContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;

  padding-top: 30px;
  max-height: calc(100vh - 200px);
  overflow: hidden;

  transition: all 0.5s ease-in-out;

  ${breakPoints.smallTablet} {
    max-height: calc(100vh - 230px);
  }

  @keyframes appears {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  animation-name: appears;
  animation-duration: 2s;

`;

const BlurredConntainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  pointer-events: none;
  z-index: 1;
`;

const BlurredOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.multiplyBlue};
  mix-blend-mode: multiply;
  pointer-events: none;
  z-index: 1;
`;

const Image = styled.img.attrs(({ src }) => src)`
  position: relative;
  width: 136px;
  z-index: 1;
`;

const OvarlayTitle = styled.span`
  position: relative;

  max-width: 260px;
  margin: 10px 0 0;

  color: ${colors.white};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  line-height: 21px;

  z-index: 1;
`;

const OverlaySubtitle = styled.span`
  position: relative;

  max-width: 320px;
  margin: 10px 0 20px;

  color: ${colors.white};
  font-size: 16px;
  letter-spacing: 0;
  text-align: center;
  line-height: 21px;

  z-index: 1;
`;

const FreemiumOverlay = ({
  baby, skills, goToPrices, t,
}) => (
  <BlurredSkillContainer>
    <BlurredOverlay />
    <BlurredConntainer>
      <Image src={BabyOverBooks} />
      <OvarlayTitle>
        {t('FREEMIUM_TITLE', {
          babyName: baby.name,
          adpos: genderAdposition(baby.gender),
        })}

      </OvarlayTitle>
      <OverlaySubtitle>{t('FREEMIUM_SUBTITLE')}</OverlaySubtitle>
      <Button
        maxWidth={300}
        color="green"
        onClick={() => goToPrices({ source: PaymentSources.PR_HOME })}
        style={{ pointerEvents: 'all' }}
      >
        {t('common:GO_PREMIUM')}
      </Button>
    </BlurredConntainer>
    <StaggeredMotion
      // FIXME: Check why is receiving one element in the first iteration
      defaultStyles={range(0, 16).map(() => ({ x: 0 }))}
      styles={prevStyles => prevStyles.map((_, i) => (i === 0
        ? { x: spring(1) }
        : { x: spring(prevStyles[i - 1].x, presets.stiff) }))}
    >
      {styles => (
        <Fragment>
          {skills.map((skill, i) => (
            <ProgressSkillCard
              key={skill.id}
              show={styles[i] ? styles[i].x === 1 : true}
              skill={skill}
              style={{ filter: 'blur(5px)' }}
              isBlurred
            />
          ))}
        </Fragment>
      )}
    </StaggeredMotion>
  </BlurredSkillContainer>
);

FreemiumOverlay.propTypes = {
  baby: PropTypes.object,
  skills: PropTypes.array,
  goToPrices: PropTypes.func,
  t: PropTypes.func,
};

FreemiumOverlay.defaultProps = {
  baby: {},
  skills: [],
  goToPrices: () => { },
  t: () => { },
};

export default FreemiumOverlay;
