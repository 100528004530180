import Loadable from 'react-loadable-visibility/react-loadable';
import LoadingOverlay from '../components/shared/LoadingOverlay';

export default function WithLoadable(opts) {
  return Loadable(Object.assign({
    loading: LoadingOverlay,
    delay: 200,
    timeout: 10000,
  }, opts));
}
