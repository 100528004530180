import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Modal from '../../components/modals/NeoModal';
import { colors, breakPoints } from '../../styles';

import Survey from '../views/Survey_/Survey';
import ThankYouView from '../views/Survey_/ThankYouView';
import EventReporter, { Events } from '../../lib/EventReporter';

const Wrapper = styled(Modal) `
  display: flex;
  flex-direction: column;

  margin: 0;

  fill: ${colors.white};

  #icon {
    height: 24px;
    width: 24px;
    box-sizing: border-box;
    margin-right: 6px;
    vertical-align: middle;
  }

  ${breakPoints.smallTablet} {
    margin: auto;
  }
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'SurveyModal',
  data: {
    questions: [
      {
        header: 'WE_LOVE_FEEDBACK',
        question: 'WHAT_DO_YOU_THINK',
        question_type: 'choice',
        options: [
          'SATISF_A1',
          'SATISF_A2',
          'SATISF_A3',
          'SATISF_A4',
          'SATISF_A5',
        ],
        multiple: false,
      },
      {
        question: 'WHAT_CAN_WE_CHANGE',
        question_type: 'open',
      },
    ]
  },
}

*/

class SurveyModal extends React.Component {
  state = {
    submited: false,
  };

  componentDidMount = () => {
    EventReporter.action(Events.SURVEY_EVENT());
  }

  formSubmited = (answers) => {
    this.setState({ submited: true, score: answers.nps });
    EventReporter.action(Events.CLICK_SURVEY_ANSWER());
    setTimeout(() => this.props.onSubmit({ data: answers }), 2000);
  }

  modalDissmised = () => {
    EventReporter.action(Events.CLICK_SURVEY_DISMISS());
  }

  render() {
    const { questions, code } = this.props;
    return (
      <Wrapper width={800} onCloseModal={this.modalDissmised}>
        {() => (
          this.state.submited ?
            <ThankYouView />
            : <Survey
              questions={questions}
              onSubmit={this.formSubmited}
              code={code}
            />
        )}
      </Wrapper>
    );
  }
}

SurveyModal.propTypes = {
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  questions: PropTypes.array,
  code: PropTypes.string,
};

SurveyModal.defaultProps = {
  isOpen: () => {},
  onClose: () => {},
  onSubmit: () => {},
  questions: [],
  code: '',
};

export default SurveyModal;
