import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Button from '../shared/buttons/MainButton';

import { colors, breakPoints } from '../../styles';
import BabySearching from '../../images/BabySearching.svg';
import BabyWorried from '../../images/BabyWorried.svg';
import { Namespaces } from '../../values/i18n';
import { genderPronoun, genderAdposition } from '../../lib/utils';

const Image = styled.img`
  width: 123px;

  ${breakPoints.smallTablet} {
    margin-right: 30px;
  }
`;

// const GreenButton = {
//   backgroundColor: colors.cognitive,
//   color: colors.white,
// };

// const Button = styled(MUIButton) `
//   width: 100%;
//   height: 50px;
//   text-transform: capitalize !important;
//   border-radius: 4px !important;

//   ${breakPoints.smallTablet} {
//     width: 418px;
//   }
// `;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Title = styled.h2`
  color: ${colors.white};
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin: 0;

  ${breakPoints.smallTablet} {
    font-size: 50px;
  }
`;

const Note = styled.h2`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 10px 0 50px 0;

  ${breakPoints.smallTablet} {
    font-size: 18px;
  }
`;

const SubTitle = styled.h2`
  color: ${colors.white};
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;

  ${breakPoints.smallTablet} {
    font-size: 24px;
  }
`;

const Description = styled.p`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  opacity: 0.7;

  ${breakPoints.smallTablet} {
    font-size: 18px;
  }
`;

const TipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 850px;
  margin-bottom: 40px;

  ${breakPoints.smallTablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const Tip = styled.div`

`;

const Content = styled.div`
  display: block;
  background-color: ${colors.kinedu};
  padding: 20px;
  height: auto;

  ${breakPoints.smallTablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: calc(100vh - 55px);
    box-sizing: border-box;
  }
`;

const ReadyToStart = ({ onClick, baby, twins, t }) => {
  window.scrollTo(0, 0);
  return (
    <Container width="900" fullscreenBreakpoint="600" isOpen>
      <Content>
        <Title>{t('READY_TO_START.TITLE')}</Title>
        <Note>{t('READY_TO_START.SUBTITLE')}</Note>
        <TipContainer>
          <Image src={BabySearching} />
          <Tip>
            <SubTitle>
              {t('READY_TO_START.POINT_1.TITLE', {
                context: twins ? 'twins' : null,
                name: baby.name,
              })}
            </SubTitle>
            <Description>
              {t('READY_TO_START.POINT_1.BODY', { context: twins ? 'twins' : null })}
            </Description>
          </Tip>
        </TipContainer>
        <TipContainer>
          <Image src={BabyWorried} />
          <Tip>
            <SubTitle>
              {t('READY_TO_START.POINT_2.TITLE', {
                context: twins ? 'twins' : null,
                name1: twins ? twins[0].name : baby.name,
                name2: twins ? twins[1].name : undefined,
              })}
            </SubTitle>
            <Description>
              {t('READY_TO_START.POINT_2.BODY', {
                context: twins ? 'twins' : null,
                name: baby.name,
                adpos: genderAdposition(baby.gender),
                pronoun: genderPronoun(baby.gender),
                name1: twins ? twins[0].name : baby.name,
                name2: twins ? twins[1].name : undefined,
              })}
            </Description>
          </Tip>
        </TipContainer>
        <Button
          style={{ display: 'block', margin: '0 auto' }}
          maxWidth={300}
          onClick={onClick}
          color="green"
        >
          {t('READY_TO_START.BUTTON')}
        </Button>
      </Content>
    </Container>
  );
};

ReadyToStart.propTypes = {
  onClick: PropTypes.func,
  baby: PropTypes.object,
};

ReadyToStart.defaultProps = {
  onClick: () => { },
  baby: {},
};

export default withTranslation(Namespaces.ASSESSMENTS)(ReadyToStart);
