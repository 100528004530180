import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../styles';

export const Default = {
  container: css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 15px;
  `,
  filepicker: css`
    width: 128px;
    height: 128px;
    border-radius: 0px;

    background-color: #F0F0F0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;;

    position: relative;
    padding: 0;
    margin-bottom: 15px;
    overflow: visible;
  `,
  message: css`
    color: #979797;
  `,
  label: css`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 3.7px;

    margin-bottom: 20px;
  `,
  errorMark: css`
    display: none;
    width: 100%;
    top: 29%;
    left: 0;
    position: relative;
    margin: 0;

    span {
      background-color: rgba(78, 0, 0, 0.66);
      padding: 10px 20px;
      border-radius: 100px;
      color: #ff5555;
    }
  `,
  successMark: css`
    width: 100%;
    top: 29%;
    left: 0;
    position: relative;
    margin: 0;

    span {
      background-color: rgba(0, 78, 53, 0.66);
      padding: 10px 20px;
      border-radius: 100px;
      color: #55ffc3;
    }
  `,
  remove: css`
    position: relative;
    top: -35px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    z-index: 100;

    color: #FFF;
    background-color: #00000080;
    text-decoration: none !important;
    border: none;


    width: auto;
    min-width: 29px;
    height: 33px;

    margin: 0 auto;
    outline: none;
  `,
  iconButton: css`
    background-color: ${colors.physical};
    border: none;
    border-radius: 100px;

    position: absolute;
    bottom: 0px;

    width: 29px;
    height: 29px;
    padding-top: 5px;

    box-shadow: 0 2px 3px ${colors.blackAlpha};
    outline: none;

    cursor: pointer;
    z-index: 1010;
  `,
};

export const Neo = {
  container: css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  filepicker: css`
    width: 90px;
    height: 90px;
    border-radius: 0px;
    border: none;

    background-color: #A5C2DB;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;;

    position: relative;
    padding: 0;
    margin-bottom: 20px;
    overflow: visible;

    box-shadow: 0px 5px 40px -10px ${colors.shadeMediumDark};
  `,
  message: css`
    color: #FFF;
    font-size: 14px;
  `,
  label: css`
    color: ${colors.shadeMediumDark};
    text-transform: uppercase;
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4.57px;

    display: block;
    margin-bottom: 10px;
  `,
  errorMark: css`
    display: none;
    width: 100%;
    top: 29%;
    left: 0;
    position: relative;
    margin: 0;

    span {
      background-color: rgba(78, 0, 0, 0.66);
      padding: 10px 20px;
      border-radius: 100px;
      color: #ff5555;
    }
  `,
  successMark: css`
    width: 100%;
    top: 29%;
    left: 0;
    position: relative;
    margin: 0;

    span {
      background-color: rgba(0, 78, 53, 0.66);
      padding: 10px 20px;
      border-radius: 100px;
      color: #55ffc3;
    }
  `,
  remove: css`
    position: relative;
    top: -35px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    z-index: 100;

    color: #FFF;
    background-color: #00000080;
    text-decoration: none !important;
    border: none;


    width: auto;
    min-width: 29px;
    height: 33px;

    margin: 0 auto;
    outline: none;
  `,
  iconButton: css`
    background-color: ${colors.physical};
    border: none;
    border-radius: 100px;

    position: absolute;
    bottom: 0px;

    width: 44px;
    height: 44px;
    padding-top: 5px;

    box-shadow: 0 2px 3px ${colors.blackAlpha};
    outline: none;

    cursor: pointer;
    z-index: 1010;

    svg {
      height: 20px !important;
      width: 20px !important;
    }
  `,
};

export const FullSize = {
  container: css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  `,
  filepicker: css`
    width: 100%;
    height: 100%;
    border-radius: 0px;
    border: none;

    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;;

    position: relative;
    padding: 0;
    overflow: visible;
  `,
  message: css`
    color: #FFF;
    font-size: 14px;
    display: none;
  `,
  label: css`
    color: ${colors.shadeMediumDark};
    text-transform: uppercase;
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4.57px;

    display: block;
    margin-bottom: 10px;
  `,
  preview: css`
    display: none;
    &.dz-image-preview {
      overflow: hidden;
    }
    img {
        width: 100%;
        height: 100%;
        border-radius: 0px !important;
      }
  `,
  errorMark: css`
    display: none;
    width: 100%;
    top: 29%;
    left: 0;
    position: relative;
    margin: 0;

    span {
      background-color: rgba(78, 0, 0, 0.66);
      padding: 10px 20px;
      border-radius: 100px;
      color: #ff5555;
    }
  `,
  successMark: css`
    width: 100%;
    top: 29%;
    left: 0;
    position: relative;
    margin: 0;

    span {
      background-color: rgba(0, 78, 53, 0.66);
      padding: 10px 20px;
      border-radius: 100px;
      color: #55ffc3;
    }
  `,
  remove: css`
    position: relative;
    top: -35px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    z-index: 100;

    color: #FFF;
    background-color: #00000080;
    text-decoration: none !important;
    border: none;


    width: auto;
    min-width: 29px;
    height: 33px;

    margin: 0 auto;
    outline: none;
  `,
  iconButton: css`
    background-color: transparent;
    border: none;
    border-radius: 100px;

    position: absolute;
    top: 0px;
    right: 30px;

    width: 44px;
    height: 44px;
    padding-top: 5px;
    outline: none;

    opacity: 0.5;
    cursor: pointer;
    z-index: 1010;

    svg {
      height: 25px !important;
      width: 25px !important;
    }
  `,
};

export const ThemeShape = PropTypes.shape({
  container: PropTypes.array,
  box: PropTypes.array,
  filepicker: PropTypes.array,
  message: PropTypes.array,
  label: PropTypes.array,
  preview: PropTypes.array,
  errorMark: PropTypes.array,
  successMark: PropTypes.array,
  remove: PropTypes.array,
  iconButton: PropTypes.array,
});
