import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../styles';

export const Default = {
  container: css``,
  input: css`
    color: ${colors.kinedu};
    background-color: #EEE;
    border: 1px solid #C8C8C8;
    box-shadow: none;
    border-radius: 3.6px;
    height: 45px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    transition: all .20s ease-in-out;

    &:focus {
      outline-color: ${colors.physical};
      outline-width: 2px;
    }
  `,
  inputError: css`
    border: 2px solid ${colors.error};
    box-shadow: 0 0 10px ${colors.error};
    &:focus {
      outline-color: ${colors.error};
      outline-width: 2px;
    }
  `,
  placeholder: css`
    color: ${colors.dustyGray};
  `,
  label: css`
    color: ${colors.darkGray};
    text-transform: uppercase;
    text-align: center;

    font-size: 12px;
    font-weight: 400;
    letter-spacing: 3.6px;

    display: block;
    margin-bottom: 10px;
  `,
  error: css`
    display: block;
    margin: 8px 0;
    color: ${colors.error};
    font-weight: 500;
    font-size: 16px;
  `,
};

export const Underlined = {
  container: css``,
  input: css`
    color: ${colors.white};
    background-color: transparent;
    box-shadow: none;

    border: none;
    border-bottom: 2px solid #FFF;
    border-radius: 0px;
    height: 45px;
    padding: 0;

    font-size: 36px;
    font-weight: bold;
    text-align: left;

    transition: all .20s ease-in-out;

    outline: none;
  `,
  inputError: css`
    background-color: rgba(255,255,255,0.3);
  `,
  placeholder: css`
    color: rgba(255,255,255,0.5);
  `,
  label: css`
    color: ${colors.white};
    text-transform: uppercase;
    text-align: left;

    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;

    display: block;
    margin-bottom: 10px;
  `,
  error: css`
    position: absolute;
    bottom: -35px;
    display: block;
    margin-top: 8px;
    color: rgba(0,0,0,0.4);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
  `,
  subLabel: css`
    color: #FFF;
    display: block;
    float: left;
    font-size: 36px;
    font-weight: bold;
    margin-right: 10px;
  `,
};

export const Ghost = {
  container: css`
    margin-bottom: 10px;
    margin-right: 10px;
  `,
  input: css`
    color: ${colors.white};
    background-color: transparent;
    box-shadow: none;
    box-sizing: content-box;

    border: none;
    border: 1px solid transparent;
    border-radius: 0px;
    padding: 2px 0px;

    font-size: 24px;
    font-weight: bold;
    text-align: left;

    transition: all .20s ease-in-out;

    outline: none;

    &:focus {
      background-color: rgba(255,255,255,0.3);
      border-radius: 4px;
      border: 1px solid rgba(255,255,255,0.9);
    }
  `,
  inputError: css`
    background-color: rgba(255,255,255,0.3);
  `,
  placeholder: css`
    color: rgba(255,255,255,0.5);
  `,
  label: css`
    color: ${colors.white};
    text-transform: uppercase;
    text-align: left;

    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;

    display: block;
  `,
  error: css`
    position: absolute;
    bottom: -45px;
    display: none;
    margin-top: 8px;
    color: rgba(0,0,0,0.4);
    font-weight: 500;
    font-size: 16px;
  `,
  subLabel: css`
    color: #FFF;
    display: block;
    float: left;
    font-size: 24px;
    font-weight: bold;
  `,
};

export const Flat = {
  container: css``,
  input: css`
    position: relative;
    height: 40px;
    margin: 0 0 20px;
    padding: 0 20px;
    border: none;
    border-radius: 2px;
    outline: none;
    background-color: ${colors.white};

    color: ${colors.shadeDark};
    font-size: 16px;

    &:focus {
      box-shadow: 0 0 5px ${colors.physicalLight30};
    }
  `,
  inputError: css`
    color: ${colors.health};
  `,
  placeholder: css`
    color: ${colors.shadeMediumDark};
  `,
  label: css`
  `,
  error: css`
    position: relative;
    top: -22px;
    display: flex;
    align-items: center;

    padding: 2px 20px;
    border-radius: 2px;

    color: ${colors.white};
    background-color: ${colors.shadeMedium};

    z-index: 1;
  `,
  subLabel: css`
  `,
};

export const Neo = {
  container: css``,
  input: css`
    color: ${colors.kinedu};
    background-color: ${colors.shadeLighter};
    border: 1px solid ${colors.shadeMedium};
    box-shadow: none;
    border-radius: 4px;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.36px;
    transition: all .20s ease-in-out;
    outline: none;

    &:focus {
      box-shadow: 0 0 8px ${colors.shadeMedium};
    }
  `,
  inputError: css`
    border: 1px solid ${colors.errorNeo};
    &:focus {
      border: 1px solid ${colors.errorNeo};
    }
  `,
  placeholder: css`
    color: ${colors.shadeMedium};
  `,
  label: css`
    color: ${colors.shadeMediumDark};
    text-transform: uppercase;
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4.57px;

    display: block;
    margin-bottom: 10px;
  `,
  error: css`
    display: block;
    margin: 8px 0;
    color: ${colors.errorNeo};
    font-weight: 400;
    font-size: 16px;
  `,
};

export const ThemeShape = PropTypes.shape({
  container: PropTypes.arrayOf(PropTypes.string),
  input: PropTypes.arrayOf(PropTypes.string),
  inputError: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.arrayOf(PropTypes.string),
});
