import BaseApi, { BaseApiV4 } from './BaseApi';

export const getDefaultPrices = () => BaseApi.get('/default_prices');

export const getPrices = (skus = [], params) => BaseApiV4.post(
  '/handle_stripe_prices',
  {
    sku: skus,
  },
  {
    params,
  },
);
