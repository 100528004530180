import { css } from 'styled-components';
import { colors } from '../../styles';

export const Default = {
  button: css`
    border-radius: 75px;
    background-color: rgba(0, 0, 0, 0.2);
    width: auto;
    height: 35px;
    padding: 10px 24px;
    box-sizing: border-box;

    border: none;
    color: white;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
  `,
};

export const Flat = {
  button: css`
    border-radius: 75px;
    background-color: ${colors.whiteBluish};
    width: auto;
    height: 30px;
    padding: 0px 20px;
    box-sizing: border-box;

    border: none;
    color: ${colors.dustyGray};
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
  `,
};

export const Neo = {
  button: css`
    display: block;
    border-radius: 75px;
    background-color: transparent;
    width: auto;
    margin: 20px auto;
    padding: 10px 24px;
    box-sizing: border-box;
    border: 1px solid ${colors.shadeMedium};

    color: ${colors.shadeDark};
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  `,
};

export const NeoFilled = {
  button: css`
    display: block;
    border-radius: 75px;
    background-color: ${colors.cognitive};
    width: auto;
    margin: 20px auto;
    padding: 10px 24px;
    box-sizing: border-box;
    border: none;

    color: ${colors.white};
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  `,
};
