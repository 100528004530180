import AddBaby from './AddBaby';
import AddMember from './AddMember';
import ChangePassword from './ChangePassword';
import EditBaby from './EditBaby';
import EditUser from './EditUser';
import Payment from './Payment';
import Invite from './BeenInvited';
import MakeFamilyDefault from './MakeFamilyDefault';

export default {
  AddBaby,
  AddMember,
  ChangePassword,
  EditBaby,
  EditUser,
  Payment,
  Invite,
  MakeFamilyDefault,
};
