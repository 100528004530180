import React, { useMemo, useState } from 'react';
import { parse } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, RendererIf } from 'lego-dist';
import { parseToBool } from 'helpers/common';
import { sizeInt } from 'themes/breakPoints';
import EventReporter, { EventProviders, Events } from 'lib/EventReporter';
import useUser from 'hooks/useUser';
import useWindowDimensions from 'hooks/useWindowDimensions';
import PremiumProcessComponent from './premiumProcessComponent';
import {
  DATA_FOR_PP_PAYMENT_PROVIDER_PP_KEY,
  QUERY_PARAM_PP_RESPONSE,
  QUERY_PARAM_PP_RESPONSE_VALUES,
  QUERY_PARAM_TO_SHOW_MODAL,
} from './values';
// import 'styles/styles.scss';

const PremiumProcessModalComponent = () => {
  const { width } = useWindowDimensions();
  const params = useLocation();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const { isPremium, isFree } = useUser();

  const handleOnClosedModal = () => {
    const queryParams = parse(window.location.search);
    delete queryParams[QUERY_PARAM_TO_SHOW_MODAL];
    delete queryParams[QUERY_PARAM_PP_RESPONSE];
    EventReporter.action(Events.DISMISS_PREMIUM_PROCESS());
    history.push({
      search: new URLSearchParams({
        ...queryParams,
      }).toString(),
    });
    setOpenModal(false);
  };

  const queryParams = useMemo(() => parse(params.search), [params.search]);

  useMemo(() => {
    setOpenModal(parseToBool(queryParams[QUERY_PARAM_TO_SHOW_MODAL]) && (isFree && !isPremium));

    if (
      queryParams[QUERY_PARAM_PP_RESPONSE]
      === QUERY_PARAM_PP_RESPONSE_VALUES.CANCELED
    ) {
      EventReporter.action(Events.PP_PAYMENT_CANCELED());
    }
    if (
      queryParams[QUERY_PARAM_PP_RESPONSE]
      === QUERY_PARAM_PP_RESPONSE_VALUES.SUCCESS
      && isPremium
    ) {
      let dataForEvent = window.localStorage.getItem(
        DATA_FOR_PP_PAYMENT_PROVIDER_PP_KEY,
      );

      if (dataForEvent) {
        dataForEvent = JSON.parse(dataForEvent) || {};
        if (dataForEvent?.sku) {
          try {
            EventReporter.action(Events.PP_PAYMENT_PROVIDER(dataForEvent), [
              EventProviders.MIXPANEL,
              EventProviders.PIXEL,
            ]);
          } catch (error) {
            console.error('ERROR::PP_PAYMENT_PROVIDER', dataForEvent, error);
          }
        }

        window.localStorage.removeItem(DATA_FOR_PP_PAYMENT_PROVIDER_PP_KEY);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, isPremium]);

  return (
    <RendererIf condition={openModal}>
      <Modal
        className="modal-not-scroll modal-bg-transparent"
        width={width < sizeInt.desktop ? '100%' : 920}
        maxWidthMobile="360px"
        openModal={openModal}
        showCloseButton={false}
        onClosedModal={() => handleOnClosedModal()}
        roundedCorners
        persistent
      >
        {({ closeModal }) => (
          <PremiumProcessComponent onClose={handleOnClosedModal} />
        )}
      </Modal>
    </RendererIf>
  );
};

export default PremiumProcessModalComponent;
