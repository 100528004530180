import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import Modal from './NeoModal';
import { colors, breakPoints } from '../../styles';

import BabyCalc from '../../images/BabyCalculation.svg';
import { genderChild } from '../../lib/utils';

const Image = styled.img.attrs({ src: BabyCalc }) `
  width: 123px;
  margin-bottom: 10px;

  ${breakPoints.smallTablet} {
    margin-right: 30px;
    margin-bottom: 0px;
  }
`;

const Wrapper = styled(Modal) `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${breakPoints.smallTablet} {
    margin: auto;
  }
`;

const Header = styled.div`
  width: 100%;
  background-color: ${colors.kinedu};
  padding: 10px;
`;

const Title = styled.h2`
  color: ${colors.white};
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 18px;

  ${breakPoints.smallTablet} {
    font-size: 28px;
  }
`;

const SubTitle = styled.h2`
  color: ${colors.kinedu};
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;

  ${breakPoints.smallTablet} {
    font-size: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const Description = styled.div`
  color: ${colors.dustyGray};
  font-size: 14px;
  font-weight: 400;

  ${breakPoints.smallTablet} {
    font-size: 16px;
    font-weight: 400;
  }
`;

const TipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 850px;
  margin-bottom: 10px;

  ${breakPoints.smallTablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 850px;
  margin-bottom: 10px;

  ${breakPoints.smallTablet} {
  }
`;

/*
  Dispatch example:

  {
    type: 'MODAL_OPENED',
    name: 'AboutCalculationsModal',
    data: {
    },
  }

  */

const AboutCalculationsModal = ({ baby, twins, onClose, t }) => (
  <Wrapper width={900} roundedCorners>
    {() => (
      <Fragment>
        <Header>
          <Title>{t('MODAL_ABOUT_CALC.TITLE', { phrase: twins ? t('MODAL_ABOUT_CALC.MY_BABIES') : `${baby.name}` })}</Title>
        </Header>
        <Content>
          <TipContainer>
            <Image />
            <Description>
              {t('MODAL_ABOUT_CALC.FIRST_PARAGRAPH')}
            </Description>
          </TipContainer>

          <InfoContainer>
            <SubTitle>{t('MODAL_ABOUT_CALC.SUBTITLE1', { child: genderChild(baby.gender) })}</SubTitle>
            <Description>
              {t('MODAL_ABOUT_CALC.SECOND_PARAGRAPH', { child: genderChild(baby.gender) })}
            </Description>
          </InfoContainer>

          <InfoContainer>
            <SubTitle>{t('MODAL_ABOUT_CALC.SUBTITLE2', { child: genderChild(baby.gender) })}</SubTitle>
            <Description>
              {t('MODAL_ABOUT_CALC.THIRD_PARAGRAPH')}
            </Description>
          </InfoContainer>
        </Content>
      </Fragment>
    )}
  </Wrapper>
);

AboutCalculationsModal.propTypes = {
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
  baby: PropTypes.shape({
    gender: PropTypes.string,
  }),
  twins: PropTypes.bool,
  t: PropTypes.func,
};

AboutCalculationsModal.defaultProps = {
  isOpen: () => { },
  onClose: () => { },
  baby: {},
  twins: false,
  t: () => { },
};

export default withTranslation([Namespaces.MODALS, Namespaces.COMMON])(AboutCalculationsModal);
