import Immutable from 'seamless-immutable';
import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = Immutable({
  /** @property{string} sku - the SKU of the user's monthly product. */
  postInitRedirect: null,
  initialized: false,
  events: [],
  attributes: {},
});

export const { Creators, Types } = createActions({
  setPostInitRedirect: ['route'],
  setInitialized: ['initialized'],
  fetchConfigRequested: null,
  fetchConfigSuccess: ['data'],
  fetchConfigError: ['error'],
  event: ['event'],
  attributes: ['attrs'],
});

export const Reducer = createReducer(INITIAL_STATE, {
  [Types.SET_POST_INIT_REDIRECT]: (state, { route }) => state.merge({ postInitRedirect: route }),
  [Types.SET_INITIALIZED]: (state, { initialized }) => state.merge({ initialized }),
  [Types.FETCH_CONFIG_SUCCESS]: state => state.merge({ /* used to contain nes */ }),
  [Types.EVENT]: (state, { type, ...event }) => state.merge({ events: [event, ...state.events] }),
  [Types.ATTRIBUTES]: (state, { infoType, attrs }) => state.merge({
    attributes: { ...state.attributes, [infoType]: { ...state.attributes[infoType], ...attrs } },
  }),
});
