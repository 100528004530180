import Immutable from 'seamless-immutable';
import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = Immutable({
  loading: false,
  classrooms_data: {},
  is_on_grace_period: null,
  is_on_hold: null,
  is_premium: null,
  plan: null,
  status: null,
});

export const { Creators, Types } = createActions({
  fetchAccountRequested: ['familyId'],
  fetchAccountSuccess: ['account'],
  fetchAccountError: null,
});

const getAccount = state => state?.account || {};

export const Selectors = {
  getAccount,
};

export const Reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_ACCOUNT_REQUESTED]: state => state.merge({ loading: true }),
  [Types.FETCH_ACCOUNT_SUCCESS]: (state, { account }) => state.merge({ ...account, loading: false }),
  [Types.FETCH_ACCOUNT_ERROR]: state => state.merge({ loading: false }),
});
