import React from 'react';
import styled, { css } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { MonthsInYears } from '../../lib/utils';
import Icon from '../shared/Icon';
import { BabyAvatar } from '../../values/families';

const Container = styled.div`
  ${({ active }) => active && css`
    background-color: #F0F0F0;
  `}
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 85px;
  height: 150px;
  cursor: pointer;

  ${({ theme }) => theme.mobile && css`
    flex-direction: row;
    width: 100%;
    height: auto;
  `};
`;

const Picture = styled.div`
  background: url(${({ url }) => url});
  border: 2px solid #F0F0F0;
  ${({ active }) => active && css`border: 2px solid #1B75BB;`};
  background-size: cover;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  margin: 10px auto;

  ${({ theme }) => theme.mobile && css`
    margin: 8px;
  `};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mobile && css`
    flex-grow: 1;
    align-items: flex-start;
  `};
`;

const Name = styled.span`
  font-size: 14px;
  color: #657480;
  letter-spacing: -0.68px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 600;

  ${({ theme }) => theme.mobile && css`
    margin: 0;
    font-size: 16px;
  `};
`;

const Age = styled.span`
  font-size: 12px;
  color: #1B75BB;
  text-align: center;

  ${({ theme }) => theme.mobile && css`
    font-size: 14px;

    p {
      display: inline-block;
      margin: 0;
    }

    p:first-child {
      margin-right: 5px;
    }
  `};

  p {
    margin: 0;
  }
`;

const EditIcon = styled(Icon).attrs({
  icon: 'crayon',
  size: 18,
  color: 'white',
})``;

const EditButton = styled.div`
  background-color: #1FADDF;
  height: 30px;
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ theme }) => theme.mobile && css`
    width: 30px;
    border-radius: 30px;
    margin: 0 10px;
  `};
`;

const handleEditClick = (e, callback) => {
  callback();
  e.stopPropagation();
};

const Baby = ({ name, picture, age, active, onEditClicked, onBabySelected, myRole, gender, t }) => {
  const { years, months } = MonthsInYears(age);
  const isAtLeastOneYear = years > 0;
  return (
    <Container active={active} onClick={onBabySelected}>
      <Picture
        url={picture === 'missing' ? BabyAvatar[gender] : picture}
        active={active}
      />
      <Content>
        <Name>{name}</Name>
        <Age>
          {isAtLeastOneYear && <p>{t('X_YEAR', { years, count: years })},</p>}
          <p>{t('X_MONTH', { months, count: months })}</p>
        </Age>
      </Content>
      {(active && myRole !== 'CAREGIVER') && <EditButton onClick={e => handleEditClick(e, onEditClicked)}><EditIcon /></EditButton>}
    </Container>
  );
};

export default withTranslation([Namespaces.COMMON])(Baby);
