import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { colors, breakPoints } from '../../styles';
import { NPS_SURVEY } from '../../values/surveys';

import Modal from './NeoModal';
import Survey from '../views/Survey_/Survey';
import ThankYouView from '../views/Survey_/ThankYouView';
import { Families } from '../../data/ducks';
import EventReporter, { Events, setUserAttributes } from '../../lib/EventReporter';

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;

  margin: 0;

  fill: ${colors.white};

  #icon {
    height: 24px;
    width: 24px;
    box-sizing: border-box;
    margin-right: 6px;
    vertical-align: middle;
  }

  ${breakPoints.smallTablet} {
    margin: auto;
  }
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'NpsModal',
  data: {
  },
}

*/

class NpsModal extends React.Component {
  state = {
    submited: false,
  };

  componentDidMount = () => {
    EventReporter.view(Events.NPS_SCORE());
  }

  formSubmited = (answers) => {
    const membershipType = this.props?.isPremium ? 'premium' : 'freemium';
    this.setState({ submited: true, score: answers.nps });
    EventReporter.action(Events.NPS_SUBMIT({
      membership_type: membershipType,
      score: answers.nps,
    }));
    setTimeout(() => this.props.onSubmit({ data: answers }), 5000);
    setUserAttributes({ npsScore: answers.nps });
  }

  render() {
    const { code } = this.props;
    return (
      <Wrapper width={800} showCloseButton={false} roundedCorners>
        {() => (
          <Fragment>
            {this.state.submited ?
              <ThankYouView score={this.state.score} />
              :
              <Survey questions={NPS_SURVEY} onSubmit={this.formSubmited} code={code} />
            }
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

NpsModal.propTypes = {
  code: PropTypes.string,
  isPremium: PropTypes.bool,
  onSubmit: PropTypes.func,
};
NpsModal.defaultProps = {
  code: '',
  isPremium: false,
  onSubmit: () => { },
};

const mapStateToProps = state => ({
  isPremium: Families.Selectors.activeFamilyIsPremium(state),
});

export default connect(mapStateToProps, () => ({}))(NpsModal);
