import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { colors, breakPoints } from '../../styles';
import Icon from '../shared/Icon';

const IconStyled = styled(Icon)`
  ${({ invertColor }) => invertColor && css`
    fill: #FFF !important;
  `}
`;

const Container = styled.div`
  display: flex;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.44px;

  ${breakPoints.smallTablet} {
    font-size: 16px;
  }
`;

const Subtitle = styled.span`
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.39px;

  ${breakPoints.smallTablet} {
    font-size: 14px;
  }
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
`;

const Promo = ({
  percentage, freeTrialDays, invertColor, t,
}) => (
  <Fragment>
    {(percentage || freeTrialDays) ? (
      <Container>
        <LeftSide>
          <IconStyled icon="check-circle-full-2" size={25} color={colors.kinedu} invertColor={invertColor} />
        </LeftSide>
        <RightSide>
          <Title>{freeTrialDays ? t('PLANS_INFO.FREE_TRIAL', { days: freeTrialDays }) : t('PLANS_INFO.BEST_VALUE')}</Title>
          {percentage && <Subtitle>{t('PLANS_INFO.SAVINGS', { percentage })}</Subtitle>}
        </RightSide>
      </Container>
    ) : null}
  </Fragment>
);

Promo.propTypes = {
  percentage: PropTypes.number,
  freeTrialDays: PropTypes.number,
  invertColor: PropTypes.bool,
  t: PropTypes.func,
};
Promo.defaultProps = {
  percentage: 0,
  freeTrialDays: 0,
  invertColor: false,
  t: () => { },
};

export default withTranslation([Namespaces.PRICINGS])(Promo);
