const LEANPLUM_PP_DATA = {
  webSkuPremiumFirst: process.env.REACT_APP_LEANPLUM_PP_WEB_SKU_PREMIUM_FIRST,
  webSkuPremiumSecond: process.env.REACT_APP_LEANPLUM_PP_WEB_SKU_PREMIUM_SECOND,
  webSkuPremiumThird: process.env.REACT_APP_LEANPLUM_PP_WEB_SKU_PREMIUM_THIRD,

  webMonthlyPricePremiumFirst: process.env.REACT_APP_LEANPLUM_PP_WEB_MONTHLY_PRICE_PREMIUM_FIRST,
  webMonthlyPricePremiumThird: process.env.REACT_APP_LEANPLUM_PP_WEB_MONTHLY_PRICE_PREMIUM_SECOND,
  webMonthlyPricePremiumSecond: process.env.REACT_APP_LEANPLUM_PP_WEB_MONTHLY_PRICE_PREMIUM_THIRD,
};

export default LEANPLUM_PP_DATA;
