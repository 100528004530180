import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors, breakPoints } from '../../styles';

export const Default = {
  container: css`

  `,
  box: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    min-width: 96px;
    height: 40px;
    background-color: #FFF;
    border: 2px solid #E1E1E1;

    color: #E1E1E1;
    font-size: 18px;
    text-align: center;

    transition: all 0.5s ease-out;
    user-select: none;

    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:nth-last-of-type(1) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  `,
  input: css`
    display: none;
  `,
  label: css`
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3.7px;

    margin-bottom: 10px;
  `,
  text: css`

  `,
  textContainer: css`

  `,
  selected: css`
    color: #FFF;
    background-color: #1EACDF;
    border-color: #157A9F;
  `,
};

export const CheckListBoxes = {
  container: css`

  `,
  checkboxGroup: css`
    display: flex;
    flex-direction: column;
`,
  box: css`
    margin: 10px 0;
  `,
  input: css`
    display: none;
  `,
  text: css`
    color: #4A4A4A;
    font-size: 16px;
    letter-spacing: -0.34px;
    line-height: 20px;
    margin-left: 10px;
  `,
  textContainer: css`
    display: flex;
    align-items: center;
  `,
  selected: css`

  `,
  image: css`
    display: block;
  `,
};

export const CircularOptions = {
  container: css`

  `,
  checkboxGroup: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    ${breakPoints.smallTablet} {
      justify-content: baseline;
    }
  `,
  box: css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 103px;
    height: 103px;
    margin: 0 10px 60px;

    border-radius: 100px;
    background-color: #DADADA;

    transition: background-color 0.5s;

    color: ${colors.darkGray};
    font-size: 16px;
    text-align: center;
  `,
  input: css`
    display: none;
  `,
  text: css`
    position: absolute;
    left: 0;
    bottom: -50px;
    width: 100%;
    height: 40px;
  `,
  textContainer: css`
  `,
  selected: css`
    background-color: ${colors.health};
  `,
  image: css`
    width: 35px;
`,
};

export const Neo = {
  container: css`
  `,
  checkboxGroup: css`
    display: flex;
    width: 100%;
  `,
  box: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    padding: 0;
    min-width: 96px;
    height: 50px;
    background-color: ${colors.shadeLighter};
    border: 1px solid ${colors.shadeMedium};

    color: ${colors.kinedu};
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    transition: all 0.3s ease-out;
    user-select: none;

    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:nth-last-of-type(1) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  `,
  boxColumn: css`
    &:first-of-type {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0px;
    }

    &:nth-last-of-type(1) {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 0px;
    }
`,
  input: css`
    display: none;
  `,
  label: css`
    color: ${colors.shadeMediumDark};
    text-transform: uppercase;
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4.57px;

    display: block;
    margin-bottom: 10px;
  `,
  text: css`

  `,
  textContainer: css`

  `,
  selected: css`
    color: #FFF;
    background-color: ${colors.kinedu};
    border-color: ${colors.kinedu};
  `,
};

export const Neo2 = {
  container: css`
  `,
  checkboxGroup: css`
    display: flex;
    width: 100%;
    border: 1px solid ${colors.shadeMediumDark};
    border-radius: 4px;
  `,
  box: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    padding: 0;
    min-width: 96px;
    height: 40px;
    background-color: ${colors.shadeLighter};
    border: none;

    color: ${colors.shadeDark};
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    transition: all 0.3s ease-out;
    user-select: none;

    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:nth-last-of-type(1) {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  `,
  input: css`
    display: none;
  `,
  label: css`
    color: ${colors.shadeDark};
    text-transform: uppercase;
    text-align: left;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4.57px;

    display: block;
    margin-bottom: 10px;
  `,
  text: css`

  `,
  textContainer: css`

  `,
  selected: css`
    color: #FFF;
    background-color: ${colors.shadeMedium};
    border-color: ${colors.shadeMedium};
    border-radius: 4px;
  `,
};

export const Capsules = {
  container: css`
  `,
  checkboxGroup: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  `,
  box: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    padding: 0;
    margin: 10px;
    height: 40px;
    background-color: ${colors.shadeLighter};
    border: 1px solid ${colors.shadeMedium};
    border-radius: 100px;

    color: ${colors.shadeDark};
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    transition: all 0.3s ease-out;
    user-select: none;

    cursor: pointer;
  `,
  boxColumn: css``,
  input: css`
    display: none;
  `,
  label: css`
    color: ${colors.shadeMediumDark};
    text-transform: uppercase;
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4.57px;

    display: block;
    margin-bottom: 10px;
  `,
  text: css`

  `,
  textContainer: css`

  `,
  selected: css`
    color: #FFF;
    background-color: ${colors.kinedu};
    border-color: ${colors.kinedu};
  `,
};

export const ThemeShape = PropTypes.shape({
  container: PropTypes.array,
  checkboxGroup: PropTypes.array,
  box: PropTypes.array,
  input: PropTypes.array,
  label: PropTypes.array,
  text: PropTypes.array,
  textContainer: PropTypes.array,
  selected: PropTypes.array,
});
