import { css } from 'styled-components';

const EventTypeSelect = {
  EventTypeSelectContainer: css`
    > h4 {
      font-size: 14px;
    }
  `,
  CardMessageTypeWrapper: css` `,
};

export default EventTypeSelect;
