import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Formsy from 'formsy-react-2';

import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Payments, Modals } from '../../data/ducks';
import Modal from '../modals/NeoModal';
import { colors, breakPoints } from '../../styles';
import { Benefits, PlanTypes } from '../../values/prices';

import CreditCard from '../shared/CreditCard';
import PricingBanner from './PricingBanner';
import LoadingOverlay from '../shared/LoadingOverlay';
import Title from '../shared/Title';
import { sendEmail, getCountryNameByInitials } from '../../lib/utils';

import Confetti from '../../images/Confetti.png';
import Button from '../shared/buttons/MainButton/MainButton';
import Icon from '../shared/Icon';
import EventReporter, { Events } from '../../lib/EventReporter';

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  background-color: ${colors.white};
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 54px;
  background-color: ${colors.kinedu};

  color: ${colors.white};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 24.5px;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  ${breakPoints.smallTablet} {
    padding: 0;
    box-sizing: border-box;
  }
`;

const Description = styled.span`
  color: ${colors.shadeDark};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.3px;
  text-align: center;

  margin: 10px;

  ${breakPoints.smallTablet} {
    color: ${colors.shadeDark};
    letter-spacing: -0.5px;
    margin: 20px;
  }
`;

const Form = styled(Formsy.Form)`
  width: 100%;
`;

const Note = styled.span`
  display: block;
  color: ${colors.shadeMediumDark};
  font-size: 14px;
  letter-spacing: -0.38px;
  text-align: center;
  margin: 20px auto;

  .fake-link {
    color: ${colors.shadeMediumDark};
    text-decoration: none;
    margin-left: 5px;
    font-weight: bold;
  }

  ${breakPoints.smallTablet} {
  }
`;

const TopArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-image: radial-gradient(57% 75%, ${colors.physical} 5%, ${colors.kinedu} 100%);
`;

const Texture = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: 670px;
  background-image: url(${Confetti});

  ${breakPoints.smallTablet} {
    background-size: 570px;
  }
`;

const Plan = styled.span`
  position: relative;
  font-size: 16px;
  color: ${colors.white};
  letter-spacing: 3.2px;
  text-align: center;
  text-transform: uppercase;
  margin: 15px 0 0;
`;

const PriceText = styled.div`
  position: relative;
  font-size: 48px;
  font-weight: bold;
  color: ${colors.linguisticLight50};
  letter-spacing: -1.09px;
  text-align: center;

  sup {
    font-size: 23px;
  }
`;

const ExtraInfo = styled.span`
  position: relative;
  color: ${colors.white};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 3.5px;
  text-align: center;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'PaymentModal',
  data: {
    plan: 'monthly',
    price: '8.99',
    benefits: [
      { title: 'something' },
      { title: 'something' },
      { title: 'something' },
    ]
  },
}
todo: homologar todos los plans/types etc.
*/

class PaymentModal extends React.Component {
  state = { card: undefined, valid: false }

  onSubmit = (card) => {
    const {
      inDap,
      familyId,
      email,
      SKU,
      paymentSource,
      testType,
      source,
      code,
      subscriptionType,
      freeTrialDays,
      price,
      postPaymentRequested,
    } = this.props;

    postPaymentRequested({
      productType: 'product',
      sku: SKU,
      card,
      familyId,
      email,
      paymentSource: inDap.source || paymentSource,
      testType: inDap.test_type || testType,
      source,
      code,
      subscriptionType,
      freeTrialDays,
      price,
      // TODO: Send these to backend
      // isIntro,
      // endPrice,
    });
  }

  setFormValid = valid => this.setState({ ...this.state, valid })

  getTimeByPlanType = (planType) => {
    switch (planType) {
      case PlanTypes.MONTHLY: return 'MONTH';
      case PlanTypes.YEARLY: return 'YEAR';
      default: return '';
    }
  }

  render() {
    const {
      loading,
      openModal,
      price,
      promo,
      savingsPercent,
      showBackButton,
      t,
      type,
      user,
      freeTrialDays,
      endPrice,
      code,
    } = this.props;
    const showFT = freeTrialDays > 0;
    const time = this.getTimeByPlanType(type);
    const isLifetime = type === PlanTypes.LIFETIME;
    const userCountry = getCountryNameByInitials(user.country);

    if (promo) {
      EventReporter.action(Events.PP_PAYMENT_STARTED({
        payment_source: 'PromoCode',
        type: code,
        subscription_type: type.toLowerCase(),
      }));
    }
    return (
      <Wrapper width={620} roundedCorners>
        {() => (
          <Fragment>
            <LoadingOverlay loading={loading} />
            {!promo && <Header>{t('MODAL_PAYMENT.TITLE')}</Header>}
            <Content>
              {promo && (
                <TopArea>
                  <Texture />
                  <Title size={22} color="white" align="center" style={{ position: 'relative' }} bold>
                    {t('pricings:PLANS_INFO.PROMO_VALID_FOR')}
                  </Title>
                  <Plan>{t('pricings:PLANS_INFO.PLAN_SUBSCRIPTION', { planType: t(`pricings:PLANS_INFO.${type}`) })}</Plan>
                  <PriceText>
$
                    {price}
                    <sup>USD</sup>
                    {' '}
                  </PriceText>
                  {showFT && <ExtraInfo>{t('pricings:PLANS_INFO.FREE_TRIAL', { days: freeTrialDays })}</ExtraInfo>}
                  {endPrice && <ExtraInfo>{t('pricings:PLANS_INFO.INTRO_PRICING', { plan: t(`common:${time}`), endPrice })}</ExtraInfo>}
                  {isLifetime && <ExtraInfo>{t('pricings:PLANS_INFO.PAY_ONCE')}</ExtraInfo>}
                </TopArea>
              )}
              {showBackButton && (
                <Icon
                  color={colors.shadeMediumDark}
                  icon="arrow-left"
                  onClick={() => openModal({ name: 'PricingModal' })}
                  style={{ position: 'absolute', top: '20px', left: '20px' }}
                />
              )}
              <Description>
                {t('MODAL_PAYMENT.SUBTITLE')}
              </Description>
              <Form onSubmit={card => this.onSubmit(card)}>
                <CreditCard style={{ margin: '20px' }} />
                <Note>
                  {t('MODAL_PAYMENT.NOTE')}
                  <a
                    href={sendEmail(user.id, user.source, t, 'EMAIL.SUBJECT', 'EMAIL.BODY')}
                    target="_self"
                    className="fake-link"
                  >
                    {t('common:EMAIL.EMAIL')}
                  </a>
                </Note>
                {promo && (
                  <ButtonContainer>
                    <Button
                      type="submit"
                      color="green"
                      maxWidth={300}
                    >
                      {t('pricings:PLANS_INFO.PURCHASE')}
                    </Button>
                  </ButtonContainer>
                )}
                <PricingBanner
                  plan={type}
                  price={price}
                  benefits={Benefits[type]}
                  submitButtonEnabled={this.state.valid}
                  savings={savingsPercent[userCountry]}
                  show={!promo}
                />
              </Form>
            </Content>
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

PaymentModal.propTypes = {
  /* Plan's price */
  price: PropTypes.number,
  familyId: PropTypes.number,
  email: PropTypes.string,
  SKU: PropTypes.string,
  paymentSource: PropTypes.string,
  type: PropTypes.string,
  testType: PropTypes.string,
  showBackButton: PropTypes.bool,
  loading: PropTypes.bool,
  inDap: PropTypes.object,
  source: PropTypes.string,
  code: PropTypes.string,
  subscriptionType: PropTypes.string,
  savingsPercent: PropTypes.object,
  promo: PropTypes.bool,
  user: PropTypes.object,
  freeTrialDays: PropTypes.object,
  endPrice: PropTypes.number,
  postPaymentRequested: PropTypes.func,
  openModal: PropTypes.func,
  t: PropTypes.func,
};

PaymentModal.defaultProps = {
  price: 0,
  familyId: 0,
  email: '',
  SKU: '',
  paymentSource: '',
  type: '',
  testType: '',
  showBackButton: true,
  loading: false,
  inDap: {},
  source: '',
  code: '',
  subscriptionType: '',
  savingsPercent: {},
  promo: false,
  user: {},
  freeTrialDays: {},
  endPrice: undefined,
  postPaymentRequested: () => { },
  openModal: () => { },
  t: () => { },
};

const mapStateToProps = state => ({
  user: state.user,
  familyId: state.families.activeFamilyId,
  email: state.user.email,
  loading: state.payments.loading,
});
const mapDispatchToProps = dispatch => bind({
  postPaymentRequested: Payments.Creators.postPaymentRequested,
  openModal: Modals.Creators.openModal,
}, dispatch);

export default compose(
  withTranslation([Namespaces.MODALS, Namespaces.COMMON]),
  connect(mapStateToProps, mapDispatchToProps),
)(PaymentModal);
