import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../styles';
import { SkillsImages } from '../../../values/progress';

const StyledSpecialActivityCard = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.div`
  width: 140px;
  height: 103px;
  background-color: ${colors.shadeLighter};
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: center;
  box-shadow: 0px 22px 60px -30px ${colors.shadeDark};
  border-radius: 4px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  height: 83px;
  padding: 10px;
  box-sizing: border-box;
  background-color: ${colors.shadeLight};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const SkillName = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.shadeDark};
  letter-spacing: -0.54px;
  text-align: center;
  line-height: 18px;
`;

const Description = styled.span`
  font-size: 14px;
  color: ${colors.shadeMediumDark};
  letter-spacing: -0.52px;
  text-align: center;
  line-height: 18px;
`;

const SpecialActivityCard = ({ id, title, currentMilestones, totalMilestones, t }) => (
  <StyledSpecialActivityCard>
    <Image src={SkillsImages[id]} />
    <Info>
      <SkillName>{title}</SkillName>
      <Description>
        {t('SKILL_DETAIL.MILESTONES_STATUS', { current: currentMilestones, total: totalMilestones })}
      </Description>
    </Info>
  </StyledSpecialActivityCard>
);

SpecialActivityCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  currentMilestones: PropTypes.number,
  totalMilestones: PropTypes.number,
  t: PropTypes.func,
};

SpecialActivityCard.defaultProps = {
  id: 0,
  title: '',
  currentMilestones: 0,
  totalMilestones: 0,
  t: () => {},
};

export default SpecialActivityCard;
