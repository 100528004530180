import styled, { css } from 'styled-components';
import { colors, typography } from '../../../../styles';

const Colors = {
  green: css`
    color: #FFF;
    fill: #FFF;
    background-color: ${colors.cognitive};

    :hover {
      background-color: #5C9E24;
    }
  `,
  disabled: css`
    color: #FFF;
    background-color: ${colors.shadeMedium};
  `,
  kinedu: css`
    color: #FFF;
    fill: #FFF;
    background-color: ${colors.newKinedu};

    :hover {
      background-color: ${colors.kineduShade};
    }
  `,
  white: css`
    color: ${colors.shadeDark};
    fill: ${colors.shadeDark};
    background-color: ${colors.shadeLighter};

    :hover {
      background-color: ${colors.shadeLight};
    }
  `,
  whiteBluish: css`
    color: ${colors.darkGray};
    fill: ${colors.darkGray};
    background-color: ${colors.whiteBluish};

    :hover {
      background-color: #cacfd2;
    }
  `,
  fb: css`
    color: #FFF;
    fill: #FFF;
    background-color: ${colors.fbBlue};

    :hover {
      background-color: #2a4886;
    }
  `,
  clear: css`
    color: ${colors.shadeDark};
    fill: ${colors.shadeDark};
    background-color: transparent;

    :hover {
      background-color: transparent;
    }
  `,
  clearwhite: css`
    color: ${colors.white};
    fill: ${colors.white};
    background-color: transparent;

    :hover {
      background-color: transparent;
    }
  `,
  danger: css`
    color: ${colors.health};
    fill: ${colors.health};
    background-color: transparent;

    :hover {
      background-color: transparent;
    }
  `,
  traslucid: css`
    color: ${colors.white};
    fill: ${colors.white};
    background-color: rgba(0,0,0,0.2);

    :hover {
      background-color: rgba(0,0,0,0.3);
    }
  `,
  outlined: css`
    border: 1px solid ${colors.kinedu};
    color: ${colors.kinedu};
    background-color: transparent;
    font-weight: bold;

    :hover {

    }
`,
  outlinedAlt: css`
    background-color: transparent;
    border: 1px solid #FFF;
    color: #FFF;
    mix-blend-mode: lighten;
`,
  outlinedGray: css`
    background-color: transparent;
    border: 1px solid ${colors.shadeMedium};
    color: ${colors.shadeDark};
`,
  whiteBlended: css`
    color: #000;
    background-color: #FFF;
    border-color: #FFF;
    mix-blend-mode: lighten;
  `,
  greenverse: css`
    color: ${colors.cognitive};
    fill: ${colors.cognitive};
    background-color: ${colors.shadeLight};

    :hover {
      background-color: ${colors.shadeLighter};
    }
  `,
};

const Button = styled.button.attrs({
  className: 'ripple',
})`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    min-height: 50px;
    ${({ small }) => small && 'min-height: 40px;'}
    ${({ big }) => big && 'min-height: 60px;'}

    font-family: ${typography.gothamRounded};
    font-size: 16px;
    font-weight: 500;
    text-transform: unset;

    padding: 0;
    margin: 0;
    border: none;
    border-radius: 4px;
    outline: none;
    overflow: hidden;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

    ${({ disabled }) => disabled && css`
      cursor: default;
      pointer-events: none;
      opacity: .5;
    `}

    /* Ripple effect */
    &.ripple:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10,10);
      opacity: 0;
      transition: transform .5s,opacity 1s;
    }

    &.ripple:active:after {
      transform: scale(0,0);
      opacity: .3;
      transition: 0s;
    }

    ${({ color }) => Colors[color]}
    ${({ maxWidth }) => maxWidth && (Number.isInteger(maxWidth) ? `max-width: ${maxWidth}px;` : `max-width: ${maxWidth}`)}
    ${({ shadow }) => shadow && 'box-shadow: 0 3px 0 0 rgba(0,0,0,0.20);'}
    ${({ rounded }) => rounded && 'border-radius: 100px;'}
    ${({ autoWidth }) => autoWidth && css`
      width: auto;
      padding: 0 20px;
    `}
    ${({ style }) => style}
`;

export default Button;
