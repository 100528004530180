import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../styles';

export const Flat = {
  container: css`
  `,
  input: css`
    width: 100%;
    height: 100%;
    background-color: #EEE;
    border: 1px solid #C8C8C8;
    border-radius: 3.6px;

    color: ${colors.kinedu};
    font-size: 20px;
    font-weight: 500;
    padding: 10px;
    box-sizing: border-box;

    outline: none;
    resize: none;
  `,
  inputError: css`
  `,
  placeholder: css`
    color: ${colors.dustyGray};
  `,
  label: css`
    color: ${colors.darkGray};
    text-transform: uppercase;
    text-align: center;

    font-size: 12px;
    font-weight: 400;
    letter-spacing: 3.6px;

    display: block;
    margin-bottom: 10px;
  `,
  error: css`
    display: block;
    margin-top: 8px;
    color: ${colors.error};
    font-weight: 500;
    font-size: 16px;
  `,
};

export const ThemeShape = PropTypes.shape({
  container: PropTypes.arrayOf(PropTypes.string),
  input: PropTypes.arrayOf(PropTypes.string),
  inputError: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.arrayOf(PropTypes.string),
});
