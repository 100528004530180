import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';
import { fullBleed } from '../../styles/layout';
import { fade } from '../../styles/transitions';
import Spinner from './Spinner';

const LoadingContainer = styled.div`
  ${({ position }) => fullBleed(position)}
  z-index: 10000;
  background-color: rgba(255,255,255,0.8);
  ${({ show, duration }) => fade(show, duration / 1000)}
`;

const Loading = ({ loading, duration, position }) => (
  <Transition in={loading} timeout={duration} unmountOnExit>
    {state => (
      <LoadingContainer
        position={position}
        show={state === 'entered' || state === 'entering'}
        duration={typeof duration === 'number' ? duration : duration.exit}
      >
        <Spinner />
      </LoadingContainer>
    )}
  </Transition>
);

Loading.propTypes = {
  loading: PropTypes.bool,
  duration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
  position: PropTypes.string,
};
Loading.defaultProps = {
  loading: false,
  duration: 400,
  position: 'fixed',
};

export default Loading;
