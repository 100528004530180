import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, css } from 'styled-components';
import LightCarousel from 'react-light-carousel';
import { ThemeShape, NeoSlider } from '../themes/SegmentedSlider';
import { equalAt } from '../../lib/utils';

const SegmentedSliderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ styles }) => styles}
  ${({ theme }) => theme.container}

  .light-carousel-slideshow {
    ${({ theme }) => theme.slideshow}
  }

  .light-carousel-slides-container {
    ${({ theme }) => theme.slidesContainer}
  }

  .light-carousel-buttons-container {
    ${({ theme }) => theme.buttonsContainer}

    .light-carousel-button {
      ${({ theme }) => theme.button}
    }
  }

  .light-carousel-slide {
    scroll-snap-align: none center;
    ${({ theme }) => theme.slide}
  }
`;

const Box = styled.span`
  ${({ boxStyles }) => boxStyles}
  ${({ theme }) => theme.box}

  ${({ selected }) => selected && css`
    ${({ theme }) => theme.selectedBox}
  `}
`;

class SegmentedSlider extends Component {
  state = { selectedItemIndex: 0, initialItemIndex: 0 };

  componentDidMount = () => {
    if (this.props.value) {
      this.setState({ selectedItemIndex: this.props.value, initialItemIndex: this.props.value });
    }
  }

  componentWillReceiveProps = (newProps) => {
    if (!equalAt(this.props, newProps, ['value'])) {
      this.setState({ selectedItemIndex: newProps.value });
    }
  }

  selectItem = (selectedItemIndex, selectedItem) => {
    this.setState({ selectedItemIndex });
    this.props.onItemSelected(selectedItem);
  }

  render() {
    const {
      options,
      styles,
      boxStyles,
      leftButton,
      rightButton,
      maxItems,
      theme,
      showControls,
      ...rest
    } = this.props;
    const limitedItems = options.length > maxItems ? maxItems : options.length;

    return (
      <ThemeProvider theme={theme}>
        <SegmentedSliderContainer styles={styles}>
          <LightCarousel
            ref={(el) => { this.carousel = el; }}
            prevBtn={leftButton}
            nextBtn={rightButton}
            slidesToShow={rest.slidesToShow || limitedItems}
            showControls={showControls}
            setSlide={this.state.initialItemIndex}
            slideshowStyle={{ overflowX: 'scroll', scrollSnapType: 'x mandatory' }}
            {...rest}
          >
            {options.map((option, index) =>
              (<Box
                key={index} // eslint-disable-line
                selected={this.state.selectedItemIndex == index} // eslint-disable-line
                onClick={() => this.selectItem(index, option)}
                boxStyles={boxStyles}
              >
                {option.label}
              </Box>))
            }
          </LightCarousel>
        </SegmentedSliderContainer>
      </ThemeProvider>
    );
  }
}

SegmentedSlider.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  maxItems: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })),
  styles: PropTypes.array,
  boxStyles: PropTypes.array,
  leftButton: PropTypes.node,
  rightButton: PropTypes.node,
  onItemSelected: PropTypes.func,
  showControls: PropTypes.bool,
  theme: ThemeShape,
};
SegmentedSlider.defaultProps = {
  value: null,
  maxItems: 3,
  options: [],
  styles: [],
  boxStyles: [],
  leftButton: null,
  rightButton: null,
  onItemSelected: () => { },
  showControls: true,
  theme: NeoSlider,
};

export default SegmentedSlider;
