import axios from 'axios';
import { MESSAGES_API_URL, MESSAGES_API_TOKEN } from '../config';
import {
  apiResponseInterceptor, apiErrorInterceptor, TIMEOUT, apiRequestInterceptor,
} from './helpers';

const token = localStorage.getItem('tokenMessage');
const MessagesApi = axios.create({
  baseURL: MESSAGES_API_URL,
  timeout: TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token || MESSAGES_API_TOKEN}`,
  },
});

MessagesApi.interceptors.request.use(apiRequestInterceptor);
MessagesApi.interceptors.response.use(apiResponseInterceptor, apiErrorInterceptor);


export default MessagesApi;
