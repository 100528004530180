/**
 * Handles flow when user has a pending invite.
 * Lives alongside index.js (appInit) because even though
 * this is a flow by itself, it is never triggered alone.
 * So it can be safely considered (at this time) part of appInit.
 */

import { Observable } from 'rxjs';
import { push } from 'connected-react-router';
import { Modals, BabiesAndFamilies, AppContext } from '../../../ducks';
import { putFamilyMembershipStatus, postFamily } from '../../../../api/families';
import { OBTypes } from '../../../../values/onBoarding';
import { userIsNewUser } from '../../../../lib/utils';
import { fetchFamilies$ } from '../../babiesAndFamilies/helperObservables';
import { startOB2IAFlow } from '../OB2IA';
import EventReporter, { Events } from '../../../../lib/EventReporter';
import { memberRolesForEvents } from '../../../../values/families';

// #region Types and Action Creators.
export const START_INVITE_FLOW = 'START_INVITE_FLOW';
export const END_INVITE_FLOW = 'END_INVITE_FLOW';

export const startInviteFlow = () => ({ type: START_INVITE_FLOW });
export const endInviteFlow = () => ({ type: END_INVITE_FLOW });
// #endregion

// #region Helper Observables.
const showInviteModal = ({
  familyOwner, familyName, relationship, role,
}) => Observable.of(Modals.Creators.openModal({
  name: 'Invite',
  data: {
    role,
    relationship,
    inviterName: familyOwner.name,
    inviterLastname: familyOwner.lastname,
    familyName,
  },
}));

const showMakeFamilyDefaultModal = ({ familyName }) => Observable.of(Modals.Creators.openModal({
  name: 'MakeFamilyDefault',
  data: {
    familyName,
  },
}));

const afterMakeDefaultModalClosed = (stream$, invite) => {
  EventReporter.action(Events.MENU_FAMILY_DEFAULT({
    source: 'OB',
  }));
  return stream$
    .filter(({ type, name }) => (type === Modals.Types.modalClosed || type === Modals.Types.modalSubmited)
      && name === 'MakeFamilyDefault')
    .take(1)
    .map(({ type }) => type === Modals.Types.modalSubmited)
    .mergeMap(accepted => Observable.of({ accepted, familyId: invite.familyId }));
};

const afterInviteModalClosed = (stream$, invite) => {
  const { familyId, id: membershipId } = invite;
  return stream$
    .filter(({ type, name }) => (type === Modals.Types.modalClosed || (type === Modals.Types.modalSubmited && name === 'Invite')))
    .take(1)
    .map(({ type }) => type === Modals.Types.modalSubmited)
    .mergeMap(accepted => Observable.fromPromise(
      putFamilyMembershipStatus({ status: accepted ? 'accepted' : 'denied', familyId, membershipId })
        .then(() => ({ accepted, familyId }))
        .catch(() => ({ accepted, familyId })),
    ));
};

const onAcceptMakeFamilyDefault = stream$ => stream$
  .ofType('USER_ACCEPT_MAKE_FAMILY_DEFAULT')
  .take(1);

const onUserDeclineInvitation = stream$ => stream$
  .ofType('USER_DECLINE_INVITATION')
  .take(1);

const onUserAlreadyExists = stream$ => stream$
  .ofType('USER_ALREADY_EXISTS')
  .take(1);
// #endregion

const initInviteObservable = (invite, stream$, user) => Observable.concat(
  // Just a debug indicator, does nothing.
  [startInviteFlow()],
  [push('/invites')],
  Observable.merge(
    showInviteModal(invite),
    afterInviteModalClosed(stream$, invite).mergeMap(({ accepted }) => {
      const { role } = invite;

      if (accepted) {
        EventReporter.action(Events.INVITE_RECEIVED({
          source: user.myFamily ? 'DAP' : 'OB',
          permissions: memberRolesForEvents[role],
          response: 'accepted',
        }));
        // IF USER ALREADY HAVE A FAMILY
        if (user.myFamily) {
          return Observable.of({ type: 'USER_ALREADY_EXISTS', accepted });
        }
        return showMakeFamilyDefaultModal({ familyName: invite.familyName });
      }
      EventReporter.action(Events.INVITE_RECEIVED({
        source: user.myFamily ? 'DAP' : 'OB',
        permissions: memberRolesForEvents[role],
        response: 'declined',
      }));
      return Observable.of({ type: 'USER_DECLINE_INVITATION' });
    }),
    onUserDeclineInvitation(stream$).mergeMap(() => {
      if (!userIsNewUser(user) && user.myFamily) {
        return Observable.of({ type: 'USER_ALREADY_EXISTS' });
      }

      return Observable.fromPromise(
        postFamily()
          .then(() => (startOB2IAFlow({ OBType: OBTypes.BABIES })))
          .catch(() => ({ type: 'FAMILY POST ERROR' })),
      );
    }).mergeMap(some => Observable.of(some)),
    onUserAlreadyExists(stream$).mergeMap(({ accepted }) => fetchFamilies$(stream$, ([defaultFamily]) => ([
      BabiesAndFamilies.Creators
        .selectFamilyAndDefaultBaby(accepted ? invite.familyId : defaultFamily.id),
      BabiesAndFamilies.Creators.checkEmptyFamily(),
      BabiesAndFamilies.Creators.checkPendingAssessment(),
      AppContext.Creators.setInitialized(true),
      push('/dap/current/'),
    ]))),
    afterMakeDefaultModalClosed(stream$, invite).mergeMap(({ accepted }) => {
      if (accepted) {
        return Observable.concat([
          [{ type: 'USER_ACCEPT_MAKE_FAMILY_DEFAULT' }],
        ]);
      }
      /** If user decline making the family is being invited as default
       * then create the user a family.
       */
      return Observable.concat([
        [{ type: 'CREATE_FAMILY_REQUESTED' }],
        [AppContext.Creators.setInitialized(true)],
        [startOB2IAFlow({ OBType: OBTypes.PARTIAL })],
      ]);
    }).flatMap(flatting => flatting),
    onAcceptMakeFamilyDefault(stream$, invite).take(1).mergeMap(() => fetchFamilies$(stream$, () => ([
      BabiesAndFamilies.Creators.setDefaultFamilyRequested(invite.familyId),
      BabiesAndFamilies.Creators.selectFamilyAndDefaultBaby(invite.familyId),
      { type: 'RESET_DAP' },
      { type: 'RESET_CONTENT' },
      { type: 'RESET_PAST_PLANS' },
      BabiesAndFamilies.Creators.checkEmptyFamily(),
      BabiesAndFamilies.Creators.checkPendingAssessment(),
      AppContext.Creators.setInitialized(true),
      push('/dap/current/'),
    ]))),
  ),
  [endInviteFlow()],
);

const inviteFlowStream = (stream$, invite, user) => {
  EventReporter.view(Events.S_INVITE_RECEIVED({
    source: (user.myFamily === null) ? 'OB' : 'DAP',
    permissions: memberRolesForEvents[invite.role],
  }));
  return initInviteObservable(invite, stream$, user);
};


export default inviteFlowStream;
