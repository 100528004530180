import { createActions } from 'reduxsauce';
import {
  findIndex, propEq, pipe, pathOr, filter, mergeAll, path,
} from 'ramda';
import { DEEP } from '../../redux-sauce';

export const { Creators, Types } = createActions({
  babySelected: ['babyId'],

  addBabyRequested: {
    name: undefined,
    lastname: undefined,
    gender: undefined,
    birthday: undefined,
    weeksBeforeBirth: undefined,
    avatar: undefined,
    familyId: undefined,
    postAction: () => {},
  },
  addBabySuccess: undefined,
  addBabyError: ['err'],

  editBabyRequested: {
    id: undefined,
    name: undefined,
    gender: undefined,
    avatar: undefined,
    birthday: undefined,
    weeks: undefined,
    pending_reset: undefined,
    is_premature: undefined,
  },
  editBabySuccess: {
    id: undefined,
    name: undefined,
    gender: undefined,
    avatar: undefined,
    birthday: undefined,
    weeks: undefined,
    pending_reset: undefined,
    is_premature: undefined,
    past_age_in_months: undefined,
    age_in_months: undefined,
    developmental_age_in_months: undefined,
  },
  deleteBaby: ['id'],

  addBabyWeight: {
    babyId: undefined,
    weight: undefined,
    weightUnit: undefined,
  },
  addBabyHeight: {
    babyId: undefined,
    height: undefined,
    heightUnit: undefined,
  },

  showBabyAgeModal: ['isPremature', 'baby'],
  showBabyTurnsModal: ['baby'],
  showBabyPrematureModal: ['baby1', 'baby2'],

  updateBabyProgressRequested: ['babyId', 'pastAge', 'actualAge'],
  updateBabyProgressSuccess: ['babyId'],

  resetBabyProgressRequested: ['babyId'],
  resetBabyProgressSuccess: ['babyId'],

  fetchBabyPlansRequested: ['babyId', 'memberId'],
  fetchBabyPlansSuccess: ['babyPlans', 'babyId'],
  fetchBabyPlansError: null,

  getBabyClassroomRequested: ['babyId'],
  getBabyClassroomSuccess: ['data'],
  getBabyClassroomError: null,

  progressPendingSkill: ['babyId', 'areaId', 'skillId'],
  babyTurns24: ['baby'],
});


const getFamilyIndex = (id, state) => findIndex(propEq('id', id), state.families);
// const getMemberIndex = (id, memberList) => findIndex(propEq('membershipId', id), memberList);
// const getBabyIndex = (id, babyList) => findIndex(propEq('id', id), babyList);
// const getBabyById = (id, babyList) => find(propEq('id', id), babyList);


const mutateBabyReducer = (state, baby) => {
  const familyIndex = getFamilyIndex(state.activeFamilyId, state);
  return state.setIn(['families', familyIndex, 'babies', baby.id],
    state.families[familyIndex].babies[baby.id].merge(baby, DEEP));
};

export const Handlers = {
  [Types.ADD_BABY_REQUESTED]: state => state.merge({ loading: true }, DEEP),
  [Types.ADD_BABY_SUCCESS]: state => state.merge({ loading: false }, DEEP),
  [Types.ADD_BABY_ERROR]: state => state.merge({ loading: false }, DEEP),
  [Types.BABY_SELECTED]: (state, { babyId }) => {
    // TODO: CHECK NULL VALUES
    try {
      const familyIndex = getFamilyIndex(state.activeFamilyId, state);
      const defaultBaby = pipe(
        pathOr({}, ['families', familyIndex, 'babies']),
        filter(x => x),
      )(state)[0];

      const familyId = mergeAll(state.families.map(x => x.babies))[babyId].family_id;
      return state.merge({
        activeFamilyId: familyId,
        activeBabyId: babyId || defaultBaby.id,
      });
    } catch (err) {
      console.error('Selected baby id does not exist');
      return state;
    }
  },
  [Types.EDIT_BABY_REQUESTED]: (state, {
    id, name, gender, birthday, weeks,
  }) => mutateBabyReducer(state, {
    id, name, gender, birthday, weeks,
  }),
  [Types.EDIT_BABY_SUCCESS]: (state, {
    id,
    name,
    gender,
    avatar,
    birthday,
    weeks,
    pending_reset,
    age_in_months,
    developmental_age_in_months,
    is_premature,
    pending_premature_notification,
  }) => mutateBabyReducer(state, {
    id,
    name,
    gender,
    avatar,
    birthday,
    weeks,
    pending_reset,
    age_in_months,
    developmental_age_in_months,
    is_premature,
    pending_premature_notification,
  }),
  [Types.DELETE_BABY]: (state, { id }) => state.setIn(['families', getFamilyIndex(state.activeFamilyId, state), 'babies', id], undefined),
  [Types.FETCH_BABY_PLANS_SUCCESS]: (state, { babyPlans, babyId }) => {
    const babies = path(['babies'], state.families[getFamilyIndex(state.activeFamilyId, state)]);
    let baby = babies ? babies[babyId] : {};
    baby = { ...baby, babyPlans };
    const lastPlan = baby?.babyPlans[0] || 0;
    return state.merge({ lastPlan });
  },
  [Types.BABY_TURNS24]: (state, { baby }) => mutateBabyReducer(state, { id: baby.id, pending_premature_notification: false }),
  [Types.UPDATE_BABY_PROGRESS_REQUESTED]: state => state.merge({ UI: { loading: true } }, DEEP),
  [Types.UPDATE_BABY_PROGRESS_SUCCESS]: state => state.merge({ UI: { loading: false } }, DEEP),
  [Types.RESET_BABY_PROGRESS_REQUESTED]: state => state.merge({ UI: { loading: true } }, DEEP),
  [Types.RESET_BABY_PROGRESS_SUCCESS]: state => state.merge({ UI: { loading: false } }, DEEP),

  [Types.GET_BABY_CLASSROOM_REQUESTED]: state => state.merge({ UI: { loading: true } }, DEEP),
  [Types.GET_BABY_CLASSROOM_SUCCESS]: (state, res) => state.merge({
    UI: { loading: false },
    classroom: res.data,
  }, DEEP),
  [Types.GET_BABY_CLASSROOM_ERROR]: state => state.merge({
    UI: { loading: false },
    classroom: {
      classrooms_baby: null,
      chat_data: null,
      calendar_api: null,
    },
  }, DEEP),
};
