import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'lego-dist';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { SOURCE_EVENTS_PAYMENT_PATH } from 'components/catalgoRedesign/values';
import { PAYMENT_PATH_PP_KEY, QUERY_PARAM_TO_SHOW_MODAL } from 'components/premiumProcessModal/values';
import EventReporter, { Events } from 'lib/EventReporter';
import FavoriteBannerUpdatePremiumWrapper, {
  FavoriteBannerUpdatePremiumContent,
  FavoriteBannerUpdatePremiumContentBtn,
  FavoriteBannerUpdatePremiumContentImage,
  FavoriteBannerUpdatePremiumContentText,
  FavoriteBannerUpdatePremiumImage,
  FavoriteBannerUpdatePremiumText,
} from './styles';

const FavoriteBannerUpdatePremiumComponent = () => {
  const { t: translation } = useTranslation();
  const history = useHistory();

  const handleOnClickBtn = () => {
    EventReporter.action(
      Events.TAP_CALL_TO_ACTION({
        payment_path: SOURCE_EVENTS_PAYMENT_PATH.FAVORITES_BANNER,
      }),
    );

    window.localStorage.setItem(PAYMENT_PATH_PP_KEY, SOURCE_EVENTS_PAYMENT_PATH.FAVORITES_BANNER);

    const queryParams = parse(window.location.search);
    queryParams[QUERY_PARAM_TO_SHOW_MODAL] = true;

    history.push({
      search: new URLSearchParams({
        ...queryParams,
      }).toString(),
    });
  };

  return (
    <FavoriteBannerUpdatePremiumWrapper>
      <FavoriteBannerUpdatePremiumContent>
        <FavoriteBannerUpdatePremiumContentImage>
          <FavoriteBannerUpdatePremiumImage />
        </FavoriteBannerUpdatePremiumContentImage>
        <FavoriteBannerUpdatePremiumContentText>
          <FavoriteBannerUpdatePremiumText>
            {translation('catalog:TAB_VIEWS.FAVORITES.BANNER.TITLE')}
          </FavoriteBannerUpdatePremiumText>
        </FavoriteBannerUpdatePremiumContentText>
        <FavoriteBannerUpdatePremiumContentBtn>
          <Button
            className="btn-primary"
            flat
            fill
            rounded
            color="primary"
            onClick={handleOnClickBtn}
          >
            {translation('catalog:TAB_VIEWS.FAVORITES.BANNER.BTN')}
          </Button>
        </FavoriteBannerUpdatePremiumContentBtn>
      </FavoriteBannerUpdatePremiumContent>
    </FavoriteBannerUpdatePremiumWrapper>
  );
};

FavoriteBannerUpdatePremiumComponent.propTypes = {};

FavoriteBannerUpdatePremiumComponent.defaultProps = {};

export default FavoriteBannerUpdatePremiumComponent;
