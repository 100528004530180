import { Component } from 'react';
import ReactDOM from 'react-dom';

const portalRoot = document.getElementById('body');

class Portal extends Component {
  constructor(props) {
    super(props);
    this.elem = document.createElement('div');
  }

  state = { val: null };

  componentDidMount = () => {
    portalRoot.appendChild(this.elem);
  }

  componentWillUnmount = () => {
    portalRoot.removeChild(this.elem);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.elem,
    );
  }
}

export default Portal;
