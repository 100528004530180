import React from 'react';
import styled from 'styled-components';

import ProgressContainer from '../../containers/ProgressContainer';
import Grid from '../shared/Grid';
import PrematureBanner from './PrematureBanner';
import Button from '../shared/buttons/MainButton';

import MonthSelector from './MonthSelector';
import FreemiumOverlay from './FreemiumOverlay';
import ProgressSkillList from './ProgressSkillList';
import { genderAdposition } from '../../lib/utils';
import { FAKE_SKILLS } from '../../values/progress';

const StyledProgressHome = styled.div`
  flex: 1 1 auto;
  width: 100%;
  margin-top: 40px;
`;

const Title = styled.span`
  color: #1B75BB;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -1.39px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px;
  box-sizing: border-box;
`;

const ProgressHome = () => (
  <StyledProgressHome>
    <ProgressContainer>
      {({
        activeBaby,
        isPremium,
        skills,
        filterSkillsByAge,
        selectedMonth,
        setSelectedMonth,
        progressBabyAge,
        goToPrices,
        gotToPendingSkills,
        loading,
        history,
        t,
      }) => {
        const filteredSkills = filterSkillsByAge(skills, selectedMonth);
        const isCurrentMonth = selectedMonth === progressBabyAge;
        return (
          <Grid style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0,
          }}
          >
            <Title align="center">{t('PROGRESS_TITLE', { babyName: activeBaby.name, adpos: genderAdposition(activeBaby.gender) })}</Title>
            <MonthSelector
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              t={t}
            />
            {activeBaby.is_premature && <PrematureBanner baby={activeBaby} />}
            {(isPremium || (selectedMonth <= progressBabyAge))
              ? (
                <ProgressSkillList
                  baby={activeBaby}
                  skills={filteredSkills}
                  loading={loading}
                  gotToPendingSkills={gotToPendingSkills}
                  push={history.push}
                />
              )
              : (
                <FreemiumOverlay
                  baby={activeBaby}
                  skills={FAKE_SKILLS}
                  goToPrices={goToPrices}
                  t={t}
                />
              )
            }
            {((isPremium && !isCurrentMonth) || !isCurrentMonth)
              && (
              <ButtonContainer>
                <Button
                  maxWidth={300}
                  color="green"
                  onClick={() => setSelectedMonth(progressBabyAge)}
                >
                  {t('progress:GO_BACK_TO_CURRENT')}
                </Button>
              </ButtonContainer>
              )}
          </Grid>
        );
      }}
    </ProgressContainer>
  </StyledProgressHome>
);

export default ProgressHome;
