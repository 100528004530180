import { css } from 'styled-components';

const primaryColors = {
  newKinedu: '#0086D8',
  kinedu: '#1B75BB',
  kineduShade: '#165E96',
  kineduShadeDark: '#134F7D',
};

const secondaryColors = {
  physical: '#1FADDF',
  cognitive: '#75B753',
  socialEmotional: '#D43571',
  linguistic: '#F78E21',
  health: '#E50000',
  webinar: '#913FA3',
  interactive: '#0E3687',
};

const complexShades = {
  physicalDark20: '#0689B4',
  physicalDark50: '#085670',
  cognitiveDark20: '#5B933D',
  cognitiveDark50: '#395C26',
  socialEmotionalDark20: '#AB275A',
  socialEmotionalDark50: '#6A1838',
  linguisticDark20: '#C77100',
  linguisticDark50: '#7C4604',
  healthDark20: '#B90000',
  healthDark50: '#730000',
};

const tertiaryColors = {
  physicalLight30: '#5DC5EA',
  physicalLight50: '#8CD6F0',
  physicalLight70: '#BBE6F6',
  cognitiveLight30: '#9DCD83',
  cognitiveLight50: '#B9DCA7',
  cognitiveLight70: '#D4EACA',
  socialEmotionalLight30: '#E26F9B',
  socialEmotionalLight50: '#EB99B8',
  socialEmotionalLight70: '#F3C1D4',
  linguisticLight30: '#FBB15D',
  linguisticLight50: '#FCC78C',
  linguisticLight70: '#FDDDBA',
  healthLight30: '#EF4B48',
  healthLight50: '#F47F7E',
  healthLight70: '#F8B3B2',
};

const neutralShades = {
  shadeLighter: '#F8F8F8',
  shadeLight: '#F0F0F0',
  shadeMediumLight: '#CFD5DA',
  shadeMedium: '#ACC2D3',
  shadeMediumDark: '#8295A3',
  shadeDark: '#657480',
  shadeDarker: '#2C343A',
};

/* This is the new palette,
  everything will eventually end with just this colors */
const kineduColorPalette = {
  ...primaryColors,
  ...secondaryColors,
  ...neutralShades,
  ...complexShades,
  ...tertiaryColors,
};

const brandColors = {
  fbBlue: '#3b5998',
  excella: '#C3A02E',
  excellaShade: '#93742C',
};

const otherColors = {
  white: '#FFFFFF',
  whiteAlpha: 'rgba(255, 255, 255, 0.5)',
  blackAlpha: 'rgba(0, 0, 0, 0.5)',
  darkGray: '#4A4A4A',
  lightGray: '#F4F4F4',
  whiteBluish: '#E2E8EC',
  whiteBluish2: '#D7E3E8',
  dustyGray: '#9B9B9B',
  borderGray: '#b5b5b5',
  borderGrayOpacity: 'rgba(151,151,151,0.5)',
  iconGray: '#D5D5D5',
  lighterGray: '#E2E2E2',
  silver: '#CCCCCC',
  gold: '#F8E71C',
  trophyYellow: '#FFC200',
  error: '#D0021B',
  errorNeo: '#e46f89',
  warmWhite: '#F8F1F3',
  transparent: 'transparent',
  fakeMultiplayBlue: '#17364E',
  multiplyBlue: '#9cb5ca',
};

export const colors = {
  ...brandColors,
  ...otherColors,
  ...kineduColorPalette,
};

export const typography = {
  gothamRounded: 'Gotham Rounded, Arial Rounded MT Bold, Helvetica, sans-serif',
  helvetica: 'Helvetica Neue, Helvetica, Arial, sans-serif',
};


// TODO: Mover todo a mqs
export const breakPoints = {
  largeTablet: '720px',
  small: '750px',
  viewWidth: '900px',
  largePhone: '@media screen and (min-width: 480px)',
  smallTablet: '@media screen and (min-width: 600px)',
  xLargeTablet: '@media screen and (min-width: 800px)',
  largeDesktop: '@media screen and (min-width: 1024px)',
};

export const fillAbsoluteSpace = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
