import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';
import { Creators } from '../../data/ducks/modals';

const mapStateToProps = () => ({
  // ..
});

const mapDispatchToProps = dispatch => bind({
  openModal: Creators.openModal,
  closeModal: Creators.closeModal,
}, dispatch);

const withModals = WrappedComponent =>
  connect(mapStateToProps, mapDispatchToProps)(
    class ModalsLogic extends Component {
      state = {};
      render() {
        return <WrappedComponent {...this.props} />;
      }
    });


export default withModals;
