import AuntPlaceholder from '../images/placeholders/Auntie.svg';
import UnclePlaceholder from '../images/placeholders/Uncle.svg';
import MomPlaceholder from '../images/placeholders/Mom.svg';
import DadPlaceholder from '../images/placeholders/Dad.svg';
import Grandpalaceholder from '../images/placeholders/Grandpa.svg';
import GrandmaPlaceholder from '../images/placeholders/Grandma.svg';
import OtherPlaceholder from '../images/placeholders/Other.svg';
import NannyPlaceholder from '../images/placeholders/Nanny.svg';

import BabyBoyPlaceholder from '../images/placeholders/BabyPlaceholderBoy.png';
import BabyGirlPlaceholder from '../images/placeholders/BabyPlaceholderGirl.png';
import FamilyPlaceholder from '../images/placeholders/UserPlaceholderFamily.png';

export const AvatarPlaceholders = {
  FAMILY_ROLE_MOM: MomPlaceholder,
  FAMILY_ROLE_DAD: DadPlaceholder,
  FAMILY_ROLE_OTHER: OtherPlaceholder,
  FAMILY_ROLE_GRANDMA: GrandmaPlaceholder,
  FAMILY_ROLE_GRANDPA: Grandpalaceholder,
  FAMILY_ROLE_AUNTIE: AuntPlaceholder,
  FAMILY_ROLE_UNCLE: UnclePlaceholder,
  FAMILY_ROLE_NANNY: NannyPlaceholder,
  FAMILY_BABY_BOY: BabyBoyPlaceholder,
  FAMILY_BABY_GIRL: BabyGirlPlaceholder,
  FAMILY_PLACEHOLDER: FamilyPlaceholder,
};

export const UserRoles = {
  1: 'FAMILY_ROLE_MOM',
  2: 'FAMILY_ROLE_DAD',
  3: 'FAMILY_ROLE_OTHER',
  4: 'FAMILY_ROLE_GRANDMA',
  5: 'FAMILY_ROLE_GRANDPA',
  6: 'FAMILY_ROLE_AUNTIE',
  7: 'FAMILY_ROLE_UNCLE',
  8: 'FAMILY_ROLE_NANNY',
};


export const UserRolesByName = {
  FAMILY_ROLE_MOM: 1,
  FAMILY_ROLE_DAD: 2,
  FAMILY_ROLE_OTHER: 3,
  FAMILY_ROLE_GRANDMA: 4,
  FAMILY_ROLE_GRANDPA: 5,
  FAMILY_ROLE_AUNTIE: 6,
  FAMILY_ROLE_UNCLE: 7,
  FAMILY_ROLE_NANNY: 8,
};
