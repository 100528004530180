import { Observable } from 'rxjs/Observable';
import { Modals } from '../../../ducks';

export const START_GDPR = 'START_GDPR_FLOW';
export const END_GDPR = 'END_GDPR_FLOW';

export const startGDPRFlow = () => ({ type: START_GDPR });
export const endGDPRFlow = () => ({ type: END_GDPR });

const showGDPRModal = () =>
  Observable.of(Modals.Creators.openModal({
    name: 'GDPRModal',
  }));

const onGDPRSubmitted = stream$ =>
  stream$
    .ofType('MODAL_SUBMITED')
    .take(1);

export const GDPRFlow = stream$ => Observable.concat(
  [startGDPRFlow()],
  showGDPRModal(),
  onGDPRSubmitted(stream$).mergeMap(() => Observable.of({ type: 'TERMS_ACCEPTED' })),
  [endGDPRFlow()],
);
