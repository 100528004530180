import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Button from '../shared/buttons/MainButton';

import { colors, breakPoints } from '../../styles';

import Dr from '../../images/Dr.svg';
import UnstyledAreaIcon from '../shared/AreaIcon';
import Icon from '../shared/Icon';
import { Namespaces } from '../../values/i18n';
import { genderPronoun } from '../../lib/utils';
import EventReporter, { Events } from '../../lib/EventReporter';

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${breakPoints.smallTablet} {
    display: block;
    margin: auto;
    max-width: 900px;
  };
`;

const DrImage = styled.img.attrs({ src: Dr })`
  width: 121px;
  display: block;
  margin: auto;
`;

const AreaIcon = styled(UnstyledAreaIcon)`
  height: 35px;
  width: 35px;
  padding: 12px;
  overflow: visible;

  ${breakPoints.smallTablet} {
    height: 35px;
    width: 35px;
    padding: 13px;
  }
`;

const SkillsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Skill = ({ areaId, children }) => {
  const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 70px;
  margin: 0;

  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;

  span {
    margin-top: 5px;
  }

  ${breakPoints.smallTablet} {
    margin: 0 10px;
  }
`;

  return (
    <Wrapper>
      <AreaIcon areaId={areaId} />
      <span>{children}</span>
    </Wrapper>
  );
};

Skill.propTypes = {
  areaId: PropTypes.number,
  children: PropTypes.string,
};
Skill.defaultProps = {
  areaId: 0,
  children: '',
};

const Description = styled.p`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 500;
  max-width: 760px;

  ${breakPoints.smallTablet} {
    font-size: 18px;
  }
`;

const Title = styled.h2`
  color: ${colors.white};
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  max-width: 460px;

  margin: 20px 0 10px;

  ${breakPoints.smallTablet} {
    font-size: 35px;
  }
`;

const MainText = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 1px 0;

  ${breakPoints.smallTablet} {
    font-size: 24px;
    margin: 0 0 0px 0;
  }
`;

const SecondaryText = styled.h3`
  color: ${colors.darkGray};
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;
  margin: 0;

  ${breakPoints.smallTablet} {
    font-size: 16px;
  }
`;

const TerciaryText = styled.h3`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;
  margin: 3px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakPoints.smallTablet} {
    font-size: 16px;
    justify-content: flex-start;
  }
`;

const Content = styled.div`
  display: block;

  background-color: ${colors.kinedu};
  padding: 20px;

  ${breakPoints.smallTablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  background-color: ${colors.white};
  padding: 20px;

  .bigButton {
    margin: 10px 0 !important;
    flex-direction: column;
    flex: 1 1 auto !important;
  }

  ${breakPoints.smallTablet} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 0;

    .bigButton {
      flex-direction: column;
      align-items: flex-start;
      width: 418px;
      text-align: left;
      padding: 5px 10px;
      margin: 10px 20px !important;
    }
  }
`;

const AboutAssessment = ({
  baby, twins, onStartAssessment, onSkipAssesment, t,
}) => {
  window.scrollTo(0, 0);
  return (
    <Container contentLabel="asdf" width="900" fullscreenBreakpoint="600" isOpen>
      <Content>
        <Title>{t('ABOUT_ASSESSMENT.TITLE')}</Title>
        <DrImage />
        <Description>
          {t('ABOUT_ASSESSMENT.DESCRIPTION_1', {
            context: twins ? 'twins' : null,
            name: baby.name,
            gender: genderPronoun(baby.gender),
          })}
        </Description>
        <SkillsContainer>
          <Skill areaId={4}>{t('common:AREA_SOCIAL_EMOTIONAL')}</Skill>
          <Skill areaId={3}>{t('common:AREA_LINGUISTIC')}</Skill>
          <Skill areaId={2}>{t('common:AREA_COGNITIVE')}</Skill>
          <Skill areaId={1}>{t('common:AREA_PHYSICAL')}</Skill>
        </SkillsContainer>
        <Description>
          {t('ABOUT_ASSESSMENT.DESCRIPTION_2', {
            context: twins ? 'twins' : null,
            baby1: twins ? twins[0].name : baby.name,
            baby2: twins ? twins[1].name : '',
          })}
        </Description>
      </Content>
      <Footer>
        <Button color="green" onClick={onStartAssessment} className="bigButton">
          <MainText>{t('ABOUT_ASSESSMENT.CONTINUE_ASSESSMENT.TITTLE')}</MainText>
          <TerciaryText>
            <Icon icon="time" size={16} color="white" />
            {t('ABOUT_ASSESSMENT.CONTINUE_ASSESSMENT.FOOTER')}
          </TerciaryText>
        </Button>
        <Button
          color="whiteBluish"
          onClick={() => {
            EventReporter.action(Events.IA_STRAIGHT_TO_ACTIVITIES());
            onSkipAssesment();
          }}
          className="bigButton"
        >
          <MainText>{t('ABOUT_ASSESSMENT.SKIP_ASSESSMENT.TITLE')}</MainText>
          <SecondaryText>
            {t('ABOUT_ASSESSMENT.SKIP_ASSESSMENT.SUBTITLE', {
              context: twins ? 'twins' : null,
              name: baby.name,
            })}
          </SecondaryText>
        </Button>
      </Footer>
    </Container>
  );
};

AboutAssessment.propTypes = {
  baby: PropTypes.object,
  twins: PropTypes.bool,
  t: PropTypes.func,
  onSkipAssesment: PropTypes.func,
  onStartAssessment: PropTypes.func,
};

AboutAssessment.defaultProps = {
  baby: {},
  twins: false,
  t: () => { },
  onSkipAssesment: () => { },
  onStartAssessment: () => { },
};

export default withTranslation(Namespaces.ASSESSMENTS, Namespaces.COMMON)(AboutAssessment);
