import {
  flatten,
  indexBy,
  map,
  path,
  pipe,
  pluck,
  prop,
} from 'ramda';
import { camelizeKeys } from 'humps';

import { Creators, Types, Selectors } from '../ducks/initialAssessment';
import * as BabiesDuck from '../ducks/babiesAndFamilies/babies';
import * as Api from '../../api/assesments';
import * as BabiesApi from '../../api/babies';

import { IASteps } from '../../shared-values';
import EventReporter, { Events } from '../../lib/EventReporter';

const getSkillsFromIA = pipe(
  pluck('skills'),
  flatten,
  indexBy(prop('skill_id')),
);

export const fetchInitialAssessment = action$ =>
  action$.ofType(Types.FETCH_INITIAL_ASSESSMENT_REQUESTED)
    .mergeMap(({ babyId, withPercentiles }) =>
      Api.getIA({ babyId, withPercentiles })
        .then(res => res.data.data)
        .then(({ areas: unformatedAreas, id }) => {
          const areas = map(area =>
            ({ ...area, skills: pluck('skill_id', area.skills) }), unformatedAreas);
          const skills = getSkillsFromIA(unformatedAreas);
          return Creators.fetchInitialAssessmentSuccess({ id, areas, skills, babyId });
        })
        .catch(err => Creators.fetchInitialAssessmentError(err)));

export const fetchIASkill = action$ =>
  action$.ofType(Types.FETCH_SKILL_REQUESTED)
    .mergeMap(({ babyId, skillId }) =>
      Api.getIASkill({ babyId, skillId })
        .then(res => path(['data', 'data', 'areas', 0, 'skills', 0], res))
        .then((skill) => {
          if (skill) {
            return Creators.fetchSkillSuccess({ babyId,
              skill: {
                area_id: skill.area_id,
                id: skill.skill_id,
                milestones: skill.milestones,
                description: skill.skill_description,
                title: skill.skill_name,
              } });
          }
          return ({ type: 'SKILL_DETAIL_NOT FOUND' });
        })
        .catch(err => Creators.fetchSkillError({ err })));

export const answerInitialMilestones = action$ =>
  action$.ofType(Types.ANSWER_INITIAL_MILESTONES)
    .mergeMap(({ skillId, assessmentId, answers, babyId }) =>
      Api.putAnswers({ answers, assessmentId, babyId })
        .then(res => camelizeKeys(res.data.data))
        .then(({ activeMilestones, completedMilestones, percentile }) =>
          Creators.answerInitialMilestonesSuccess({
            skillId,
            activeMilestones,
            completedMilestones,
            percentile,
            babyId,
          }))
        .catch(() => Creators.answerInitialMilestonesError()));

export const answerTwinInitialMilestones = action$ =>
  action$.ofType(Types.ANSWER_TWIN_INITIAL_MILESTONES)
    .mergeMap(({ answers, twins, skillId }) => {
      const [a, b] = twins;
      return Api.postTwinAnswers([
        {
          baby_id: a.id,
          initial_assessment_id: a.assessmentId,
          answers: answers[a.id],
        },
        {
          baby_id: b.id,
          initial_assessment_id: b.assessmentId,
          answers: answers[b.id],
        },
      ]).then(res => camelizeKeys(res.data.progress))
        .then(([twinA, twinB]) =>
          Creators.answerTwinInitialMilestonesSuccess({ twinA, twinB, skillId }));
    });

export const getMasteredMilestones = action$ =>
  action$.ofType(Types.FETCH_MASTERED_MILESTONES_REQUESTED)
    .mergeMap(({ skillId }) =>
      Api.getMasterMilestones(skillId)
        .then(res => res.data.milestones)
        .then(milestones => Creators.fetchMasteredMilestonesSuccess(milestones))
        .catch(err => Creators.fetchMasteredMilestonesError(err)),
    );

export const setInitialSkillMastered = action$ =>
  action$.ofType(Types.SET_INITIAL_SKILL_MASTERED)
    .mergeMap(({ skillId, assessmentId, baby }) =>
      Api.postSkillMastered({ skillId, assessmentId })
        .then(res => camelizeKeys(res.data.data))
        .then(({ activeMilestones, completedMilestones, percentile }) => {
          EventReporter.action(Events.MASTER_SKILL({
            skill: skillId,
            age: baby.age_in_months,
            source: 'initial_assessment', // TODO: Get source by context
          }));

          return Creators.answerInitialMilestonesSuccess({
            skillId,
            activeMilestones,
            completedMilestones,
            percentile,
            babyId: baby.id,
          });
        }),
    );

// Fetch assessment when user firsts starts ia, when changing baby or when
// submiting skill. USE VIEW ACTIVE TO TRIGER.
export const startAssessment = (action$, { getState }) =>
  action$.ofType(Types.START_IA)
    .mergeMap(() => {
      if (getState().IA.type === 'twins') {
        const [a, b] = getState().IA.twinIds;
        return [
          Creators.fetchInitialAssessmentRequested({ babyId: a, withPercentiles: true }),
          Creators.fetchInitialAssessmentRequested({ babyId: b, withPercentiles: true }),
        ];
      }
      const { id } = Selectors.activeBaby(getState());
      return [Creators.fetchInitialAssessmentRequested({ babyId: id, withPercentiles: true })];
    });

export const fetchHealthInterests = action$ =>
  action$.ofType(Types.FETCH_HEALTH_INTERESTS_REQUESTED)
    .mergeMap(
      ({ babyId }) => BabiesApi.getHealthInterests(babyId)
        .then(res => res.data.data.interests)
        .then(interests => Creators.fetchHealthInterestsSuccess(interests)));

// Fetch interests when view is active. In the case of twins, fetch any baby as
// both will have the same values.
export const requestInterests = (action$, { getState }) =>
  action$
    .ofType(Types.SET_ASSESSMENT_STEP)
    .filter(action => action.step === IASteps.HEALTH_INTERESTS)
    .map(() => {
      const { id } = Selectors.activeBaby(getState());
      return Creators.fetchHealthInterestsRequested(id);
    });

export const postHealthInterests = action$ =>
  action$.ofType(Types.POST_HEALTH_INTERESTS)
    .do(({ babyId, interests }) => BabiesApi.postHealthInterests(babyId, interests))
    .catch(err => err)
    .ignoreElements();

export const setUpdateFlag = action$ =>
  action$.ofType(BabiesDuck.Types.UPDATE_BABY_PROGRESS_SUCCESS)
    .mapTo(Creators.setUpdateFlag());
