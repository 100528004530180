import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import Button from './MainButton';
import Icon from '../../shared/Icon';

const bigButton = {
  display: 'flex',
  alignItems: 'center',
};

const ButtonContent = styled.div`
  padding: 15px 10px;
`;

const ButtonTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.44px;
  text-align: left;
  margin: 0 0 5px;
`;

const ButtonDescription = styled.p`
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.33px;
  line-height: 18px;
  text-align: left;
  margin: 0;
`;

const BigButton = ({ onClick, title, description, color, maxWidth, style }) => (
  <Button color={color} maxWidth={maxWidth} style={{ ...bigButton, ...style }} onClick={onClick}>
    <ButtonContent>
      <ButtonTitle>{title}</ButtonTitle>
      <ButtonDescription>
        {description}
      </ButtonDescription>
    </ButtonContent>
    <Icon icon="chevron-right" />
  </Button>
);

BigButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  maxWidth: PropTypes.number,
  style: PropTypes.object,
};

BigButton.defaultProps = {
  onClick: () => {},
  title: '',
  description: '',
  color: '',
  maxWidth: 0,
  style: {},
};

export default BigButton;
