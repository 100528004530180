import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import FullModal from './FullModal';
import { colors } from '../../styles';
import Button from '../shared/buttons/MainButton';
import bebeImage from '../../images/BabyAwesome.png';
import Texture from '../../images/Texture2.png';

const ModalContainer = styled(FullModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px;
  background-color: ${colors.physical};
  background-image: url(${Texture});

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Title = styled.span`
  color: ${colors.white};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  line-height: 33px;
  margin: 20px 0;
`;

const Description = styled.p`
  color: ${colors.white};
  font-size: 16px;
  letter-spacing: -0.36px;
  text-align: center;
  line-height: 21px;
  margin: 20px 0;
`;

const BebeImg = styled.img.attrs({ src: bebeImage })`
  width: 193px;
  height: auto;
  margin: 40px 0 0 0;
`;

const ButtonsContainer = styled.div`
  width: 300px;
`;

/*
  Dispatch example:

  {
    type: 'MODAL_OPENED',
    name: 'InviteSentModal',
    data: {
    },
  }

  */

const InviteSentModal = ({ onSubmit, onGoBack, t }) => (
  <ModalContainer showCloseButton={false}>
    {() => (
      <Fragment>
        <Title>{t('common:AWESOME')}</Title>
        <BebeImg />
        <Description>{t('MODAL_INVITE_SENT.DESCRIPTION')}</Description>
        <ButtonsContainer>
          <Button onClick={onSubmit} color="green" maxWidth={300}>
            {t('MODAL_INVITE_SENT.INVITE_ANOTHER')}
          </Button>
          <Button color="clearwhite" maxWidth={300} onClick={onGoBack}>
            {t('common:GO_BACK')}
          </Button>
        </ButtonsContainer>
      </Fragment>
    )}
  </ModalContainer>
);

InviteSentModal.propTypes = {
  onSubmit: PropTypes.func,
  onGoBack: PropTypes.func,
  t: PropTypes.func,
};

InviteSentModal.defaultProps = {
  onSubmit: () => { },
  onGoBack: () => { },
  t: () => { },
};

export default withTranslation([Namespaces.MODALS, Namespaces.COMMON])(InviteSentModal);
