import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';
import Moment from 'moment';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Creators, Selectors } from '../../data/ducks/babiesAndFamilies';
import { Creators as ModalCreators } from '../../data/ducks/modals';

import Modal from '../../components/modals/NeoModal';
import { ModalNames } from '../../shared-values';

import EditBabyView from '../../components/views/EditBaby';
import EventReporter, { Events } from '../../lib/EventReporter';

class EditBaby extends React.Component {
  state = { showSavePopup: false };

  componentDidMount = () => {
    EventReporter.action(Events.MENU_EDIT_BABY());
  }

  close = () => this.props.closeModal(ModalNames.EDIT_BABY);

  handleSaveChanges = (data, closeModal, babyAgeHasChanged, ageInMonths) => {
    const {
      name, gender, avatar, year, month, day, weeks,
    } = data;
    const bday = new Date(year, month - 1, day);
    const birthday = Moment(bday).format('YYYY-MM-DD');
    const { is_premature, id, age_in_months } = this.props.activeBaby;
    const baby = { ...data, id, past_age_in_months: age_in_months };
    this.props.editBaby({
      id, name, gender, avatar, birthday, weeks,
    });
    if (babyAgeHasChanged) {
      this.props.openModal({ name: 'BabyAgeChangedModal', data: { isPremature: is_premature, baby } });
      EventReporter.view(Events.IA_INTRO());
    } else {
      closeModal();
    }
  }

  deleteBaby = (closeModal) => {
    const { id } = this.props.activeBaby;
    this.props.deleteBaby(id);
    closeModal();
  }

  render() {
    const { activeBaby, myRole, t, showDelete } = this.props;
    return (
      <Modal
        title={t('MY_PROFILE')}
        roundedCorners
      >
        {({ closeModal }) => (
          <EditBabyView
            activeBaby={activeBaby}
            deleteBaby={() => this.deleteBaby(closeModal)}
            saveChanges={(data, babyAgeHasChanged) => this.handleSaveChanges(data, closeModal, babyAgeHasChanged)}
            myRole={myRole}
            showDelete={showDelete}
          />
        )}
      </Modal>
    );
  }
}

EditBaby.propTypes = {
  activeBaby: PropTypes.object,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  editBaby: PropTypes.func,
  deleteBaby: PropTypes.func,
  t: PropTypes.func,
};
EditBaby.defaultProps = {
  activeBaby: {},
  closeModal: () => { },
  openModal: () => { },
  editBaby: () => { },
  deleteBaby: () => { },
  t: () => { },
};

const mapStateToProps = state => ({
  activeBaby: Selectors.activeBaby(state),
  myRole: Selectors.myRole(state),
});

const mapDispatchToProps = dispatch => bind({
  editBaby: Creators.editBabyRequested,
  deleteBaby: Creators.deleteBaby,
  closeModal: ModalCreators.closeModal,
  openModal: ModalCreators.openModal,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation([Namespaces.MENU, Namespaces.COMMON]),
)(EditBaby);
