import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EventReporter, { Events } from 'lib/EventReporter';
import TextInput from 'components/shared/TextInput';
import { Flat } from 'components/themes/TextInput';
import Button from 'components/shared/buttons/MainButton';
import OvalSelectorComponent from 'components/OvalSelector';
import { VALIDATIONS_MESSAGES } from 'values/validations';
import { RendererIf } from 'lego-dist';
import { KEY_LOCALE } from 'shared-values';
import { colors } from 'styles';

import ViewWrapper, {
  Form,
  InlineButtons,
  Separator,
  Subtitle,
  Title,
  FormContent,
  FormContentBody,
  FormContentFoot,
  LogoImage,
} from '../../styles';

import i18n from '../../../../i18n';
import useRestorePassword from './hooks/useRestorePassword';

const lngs = [
  {
    label: 'EN',
    value: 'en',
  },
  {
    label: 'ES',
    value: 'es',
  },
  {
    label: 'PT',
    value: 'pt',
  },
];

const RestorePasswordComponent = () => {
  const params = useParams();
  const { t: translation } = useTranslation();
  const { isRestoring, restore, succesfull, finished } = useRestorePassword();

  const [currentLang, setCurrentLang] = useState({
    id: window.localStorage.getItem(KEY_LOCALE) || 'en',
    value: window.localStorage.getItem(KEY_LOCALE) || 'en',
  });

  const [formIsValid, setFormIsValid] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [formValues, setFormValues] = useState(false);
  const [isSumited, setIsSumited] = useState(false);

  const { token = '' } = params || {};

  const handleOnChangeLang = (value) => {
    EventReporter.action(Events.SELECT_LANGUAGE(value.value || 'en'));
    i18n.changeLanguage(value.value || 'en');
    window.localStorage.setItem(KEY_LOCALE, value.value || 'en');
    setCurrentLang(value);
  };

  const onChangeFormValues = (values) => {
    setFormValues(values);
  };

  const handleOnSubmit = ({ password }) => {
    setIsSumited(true);
    restore(password, token);
  };

  useEffect(() => {
    if (finished) {
      setTimeout(() => {
        if (succesfull && !isRestoring) {
          window.location.reload();
        }
        setIsSumited(false);
      }, 2000);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRestoring, succesfull, finished]);

  return (
    <RendererIf condition={token}>
      <ViewWrapper>
        <Form
          onValid={() => setFormIsValid(true)}
          onInvalid={() => setFormIsValid(false)}
          onChange={onChangeFormValues}
          onValidSubmit={handleOnSubmit}
        >
          <FormContent>
            <FormContentBody>
              <LogoImage />
              <Title size={30} color={colors.kinedu} align="center" bold>
                {translation('common:AUTH.WELCOME_BACK')}
              </Title>
              <Subtitle>
                {translation('common:SETUP_PASSWORD.SETUP_NEW_PASS')}
              </Subtitle>
              <Separator />
              <TextInput
                className="new-input"
                type="password"
                name="password"
                label={translation('common:SETUP_PASSWORD.NEW_PASSWORD')}
                placeholder="**********"
                theme={Flat}
                validationErrors={{
                  isDefaultRequiredValue: translation(
                    `validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`,
                  ),
                }}
                required
              />
              <TextInput
                className="new-input"
                type="password"
                name="confirm_password"
                label={translation('common:SETUP_PASSWORD.CONFIRM_PASSWORD')}
                placeholder="**********"
                validations="equalsField:password"
                theme={Flat}
                validationErrors={{
                  isDefaultRequiredValue: translation(
                    `validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`,
                  ),
                  equalsField: translation(`validations:${VALIDATIONS_MESSAGES.PASS_DONT_MATCH}`),
                }}
                required
              />

              <OvalSelectorComponent
                label={translation('modals:MODAL_SETTINGS.LANG')}
                name="lngs"
                data={lngs}
                keys={{
                  label: 'label',
                  id: 'value',
                }}
                value={currentLang}
                onChange={handleOnChangeLang}
                columns="1fr 1fr 1fr"
                mobileColumns="1fr 1fr 1fr"
              />
            </FormContentBody>
            <FormContentFoot>
              <InlineButtons>
                <Button
                  type="submit"
                  color="green"
                  rounded
                  small
                  maxWidth={300}
                  disabled={!formIsValid || isRestoring || !token || isSumited}
                >
                  {translation('common:SETUP_PASSWORD.CHANGE_PASSWORD')}
                </Button>
              </InlineButtons>
            </FormContentFoot>
          </FormContent>
        </Form>
      </ViewWrapper>
    </RendererIf>
  );
};

RestorePasswordComponent.propTypes = {};
export default RestorePasswordComponent;
