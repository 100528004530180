import { Creators, Types } from '../ducks/catalog';
import * as CatalogApi from '../../api/catalog';
import { localeFlag } from '../../api/BaseApi';

// const onFetchActivitiesSuccess = stream$ =>
//   stream$
//     .ofType(Types.FETCH_ACTIVITIES_SUCCESS)
//     .take(1)
//     .map(({ activities }) => activities);

export const fetchTags = action$ =>
  action$.ofType(Types.FETCH_TAGS_REQUESTED)
    .mergeMap((({ areaId, forAge }) => CatalogApi.getTagsByArea({ areaId, forAge })
      .then(res => res.data.data)
      .then(tags => Creators.fetchTagsSuccess(tags))
      .catch(err => Creators.fetchTagsError(err))
    ));

export const fetchActivities = action$ =>
  action$.ofType(Types.FETCH_ACTIVITIES_REQUESTED)
    .mergeMap((({ tagId: { skillId, interestId }, areaId, forAge, fromAge }) =>
      CatalogApi.getActivities({ skillId, interestId, areaId, forAge, fromAge })
        .then(res => res.data.data)
        .then(tags => Creators.fetchActivitiesSuccess(tags))
        .catch(err => Creators.fetchActivitiesError(err))));

export const fetchArticles = action$ =>
  action$.ofType(Types.FETCH_ARTICLES_REQUESTED)
    .mergeMap((({ tagId: { skillId, interestId }, areaId, forAge, fromAge }) =>
      CatalogApi.getArticles({ skillId, interestId, areaId, forAge, fromAge })
        .then(res => res.data.data)
        .then(tags => Creators.fetchArticlesSuccess(tags))
        .catch(err => Creators.fetchArticlesError(err))
    ));

// export const fetchContent = action$ =>
//   action$.ofType(Types.FETCH_CONTENT_REQUESTED)
//     .mergeMap(({ tagId, forAge, fromAge }) => Observable.concat(
//       [
//         Creators.fetchActivitiesRequested(tagId, forAge, fromAge),
//         Creators.fetchArticlesRequested(tagId, forAge, fromAge),
//       ],
//     ));

export const fetchContent = (action$, { getState }) =>
  action$.ofType(Types.FETCH_CONTENT_REQUESTED)
    .mergeMap(({ tagId: { skillId, interestId }, forAge, fromAge, page, perPage }) =>
      CatalogApi.getContent({ skillId, interestId, forAge, fromAge, page, perPage })
        .then(res => res.data)
        .then(({ data: { articles }, meta }) => {
          let content = articles;
          if (meta.page > 1) {
            content = [...getState().catalog.content, ...articles];
          }
          return Creators.fetchContentSuccess(content, meta);
        })
        .catch(err => Creators.fetchContentError(err)));

export const fetchCatalogFilters = action$ => (
  action$.ofType(Types.FETCH_CATALOG_FILTERS_REQUESTED)
    .mergeMap(() => CatalogApi.getCatalogFilters()
      .then(res => res.data)
      .then(({ data }) => Creators.fetchCatalogFiltersSuccess({ ...data, locale: localeFlag() }))
      .catch(err => Creators.fetchCatalogFiltersError(err))));
