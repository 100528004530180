import * as ChatApi from '../../api/chat';
import { Creators, Types } from '../ducks/chat';

// eslint-disable-next-line import/prefer-default-export
export const fetchChatEduConfig = action$ =>
  action$.ofType(Types.FETCH_CHAT_EDU_CONFIG_REQUESTED)
    .mergeMap(({ babyId }) => ChatApi.getEduChatConfig({ babyId })
      .then(res => res.data)
      .then(data => Creators.fetchChatEduConfigSuccess(data))
      .catch((err) => {
        // toast.error('There was an error fetching the chat configuration.');
        return Creators.fetchChatEduConfigError(err);
      }));

/**
 * Fetch all chat groups until finds unreadMessages
 * @param {*} groupId
 * @param {*} personId
 * @returns
 */
const lookingForUnreadMessages = (groupId, personId) => new Promise(async (resolve, reject) => {
  try {
    let hasUnreadMessages = false;
    let currentPage = 1;
    const { data, meta } = await ChatApi.getChatsInfo({ groupId, personId, page: currentPage });

    hasUnreadMessages = data
      ?.find(groupChat => (groupChat?.total_pending_messages_to_read > 0));

    let fetchNextPage = !hasUnreadMessages && meta?.last_page > currentPage;

    while (fetchNextPage) {
      currentPage += 1;
      // eslint-disable-next-line no-await-in-loop
      const { data: dataNextPage, meta: metaNextPage } = await ChatApi
        .getChatsInfo({ groupId, personId, page: currentPage });

      hasUnreadMessages = dataNextPage
        ?.find(groupChat => (groupChat?.total_pending_messages_to_read > 0));

      fetchNextPage = !hasUnreadMessages && metaNextPage?.last_page > currentPage;
    }
    resolve(!!hasUnreadMessages);
  } catch (error) {
    reject(error);
  }
});

export const fetchUnreadMessagesRequested = action$ => action$
  .ofType(Types.FETCH_UNREAD_MESSAGES_REQUESTED)
  .mergeMap(({ groupId, personId }) => lookingForUnreadMessages(groupId, personId)
    .then(hasUnreadMessages => Creators.fetchUnreadMessagesSuccess(hasUnreadMessages))
    .catch(err => Creators.fetchUnreadMessagesError(err)));
