import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react-2';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators as bind, compose, bindActionCreators } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';
import { Creators } from '../../../data/ducks/payments';

import { colors, breakPoints } from '../../../styles';
import Button from '../../shared/buttons/MainButton';
import TextInput from '../../shared/TextInput';
import BabyPointing from '../../../images/BabyPointing.svg';
import LoadingOverlay from '../../shared/LoadingOverlay';
import Link from '../../shared/Link';
import { Underlined } from '../../themes/Link';
import { Modals } from '../../../data/ducks';
import { ModalNames } from '../../../shared-values';

const RedeemGiftContainer = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${colors.white};
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0;
  text-align: center;

  height: 55px;
  background: ${colors.physical};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: calc(100vh - 55px);
  padding: 70px 0 10px;
  box-sizing: border-box;
  overflow: auto;

  ${breakPoints.smallTablet} {
    height: auto;
  }
`;

const Description = styled.p`
  color: #4A4A4A;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.41px;
  line-height: 24px;
  max-width: 300px;
  text-align: center;

  ${breakPoints.smallTablet} {
    max-width: none;
    font-size: 22px;
    letter-spacing: -0.5px;
    line-height: 25px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 0 60px;
`;

const Form = styled(Formsy.Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Note = styled.p`
  color: #9B9B9B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin: auto 10px 10px;

  ${breakPoints.smallTablet} {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
`;

const InputContainer = styled.div`
  width: 260px;
  margin: 20px 0 110px;

  ${breakPoints.smallTablet} {
    width: 360px;
    margin: 20px 0 60px;
  }
`;

const Image = styled.img.attrs({ src: BabyPointing })`
  position: absolute;
  left: 0;
  top: 260px;
  width: 80px;

  ${breakPoints.smallTablet} {
    top: 160px;
    width: 200px;
  }
`;

class RedeemGiftView extends React.Component {
  state = {
    recipient: '',
    recipientData: {
      name: '',
      email: '',
      message: '',
    },
    currentStep: 0,
  };

  redeem = ({ code }) => {
    this.props.redeemGiftcardRequested({ familyId: this.props.familyId, code });
  }

  openGiftCards = () => {
    this.props.openModal(ModalNames.BUY_GIFTCARD);
  }

  handleGoBack = () => {
    this.props.closeModal();
  }

  render() {
    const { loading, t } = this.props;
    return (
      <RedeemGiftContainer>
        <Header>{t('REDEEM_GIFT.HEADER')}</Header>
        <Content>
          <Image />
          <Description>{t('REDEEM_GIFT.DESCRIPTION')}</Description>
          <Form onSubmit={this.redeem}>
            <InputContainer>
              <TextInput name="code" placeholder="0000-0000-0000-000" mask="****-****-****-****" maskChar="-" />
            </InputContainer>
            <ButtonsContainer>
              <Button color="green" maxWidth={300} type="submit">{t('REDEEM_GIFT.REDEEM_NOW')}</Button>
              <Button color="clear" maxWidth={300} onClick={this.handleGoBack}>{t('common:GO_BACK')}</Button>
            </ButtonsContainer>
          </Form>
          <Note>
            {t('REDEEM_GIFT.NOTE')}
            <Link theme={Underlined} onClick={this.openGiftCards}>
              {' '}
              {t('REDEEM_GIFT.LINK_TEXT')}
            </Link>
!
          </Note>
        </Content>
        <LoadingOverlay loading={loading} />
      </RedeemGiftContainer>
    );
  }
}

RedeemGiftView.propTypes = {
  t: PropTypes.func,
};

RedeemGiftView.defaultProps = {
  t: () => {},
};


const mapStateToProps = state => ({
  familyId: state.families.activeFamilyId,
  loading: state.payments.loading,
});

const mapDispatchToProps = dispatch => bind({
  openModal: bindActionCreators(Modals.Creators.openModal, dispatch),
  redeemGiftcardRequested: Creators.redeemGiftcardRequested,
}, dispatch);

export default compose(
  withTranslation([Namespaces.MODALS, Namespaces.COMMON]),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(RedeemGiftView);
