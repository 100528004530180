/**
 * This module handles the transition from OnBoarding to the Initial Assessment.
 * Because it is not connected to the main epic stream, it is used by merging it to another stream.
 */
import { Observable } from 'rxjs';
import { push } from 'connected-react-router';
import { OnBoarding as OB, InitialAssesment as IA } from '../../ducks';
import * as BabiesAndFamilies from '../../ducks/babiesAndFamilies';
import { fetchFamilies$ } from '../babiesAndFamilies/helperObservables';
import { OBTypes as types } from '../../../values/onBoarding';
import inviteFlowStream from './appInit/inviteFlowStream';

export const OBTypes = types;
export const START_OB2IA_FLOW = 'START_OB2IA_FLOW';
export const END_OB2IA_FLOW = 'END_OB2IA_FLOW';

// #region Types and Action Creators.
/**
 * @param {*} type - one of [BABIES, INVITE, FULL].
 * @param {number} inviteFamilyId - the id of the family to be selected when invite OB ends.
 */
export const startOB2IAFlow = ({ OBType, inviteFamilyId }) => ({ type: START_OB2IA_FLOW, OBType, inviteFamilyId });
export const endOB2IAFlow = () => ({ type: END_OB2IA_FLOW });
// #endregion

// #region Helper Observables.
export const onOBEnd = stream$ => stream$.ofType(OB.Types.END)
  .take(1);

export const onIAEnd = stream$ => stream$.ofType(IA.Types.END_IA)
  .take(1);

export const onFetchIASuccess = stream$ => stream$.ofType(IA.Types.FETCH_INITIAL_ASSESSMENT_SUCCESS)
  .take(1);
// #endregion

const OB2IA = (action$, { getState }) => action$.ofType(START_OB2IA_FLOW)
  .mergeMap(({ inviteFamilyId, OBType }) => Observable.concat(
    [OB.Creators.start(OBType)],
    // REPLACE WITH ES2018 DO ON EJECT OR AVAILABILITY.
    onOBEnd(action$)
      .delay(2000)
      .mergeMap(() => {
        const invite = getState().invites.invites[0];
        const isClassroomsInvite = !!(invite && invite.newUserData && invite.newUserData.status === 'classrooms_pending');
        return (
          (OBType === OBTypes.INVITE && !isClassroomsInvite)
            ? inviteFlowStream(action$, invite, getState().user)
            : Observable.concat(
              [push('/ia')],
              fetchFamilies$(action$, ([defaultFamily]) => ([
                BabiesAndFamilies.Creators.selectFamilyAndDefaultBaby(defaultFamily.id),
                BabiesAndFamilies.Creators.checkEmptyFamily(),
              ])),
              onIAEnd(action$).map(() => push(getState().appContext.postInitRedirect || '/dap/current')),
            )
        );
      }),
    [endOB2IAFlow()],
  ));

export default OB2IA;
