import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Formsy from 'formsy-react-2';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Creators } from '../../data/ducks/auth';
import Title from '../shared/Title';
import TextInput from '../shared/TextInput';
import { Flat } from '../themes/TextInput';
import Button from '../shared/buttons/MainButton';
import { colors, breakPoints } from '../../styles';

import BabyPlaying from '../../images/BabyPlaying.svg';
import BabyHappy from '../../images/BabyHappy.svg';
import Texture from '../../images/Texture3.png';
import { VALIDATIONS_MESSAGES } from '../../values/validations';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  background-image: url(${Texture});
  width: 100%;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
`;

const View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 620px;
  background-color: ${colors.shadeLight};
`;

const Footer = styled.div`
  width: 100%;
  background: ${colors.kinedu};
  padding: 60px 40px 30px;
  box-sizing: border-box;
  height: 100vh;

  ${breakPoints.smallTablet} {
    padding: 60px 80px 30px;
    height: auto;
  }
`;

const Form = styled(Formsy.Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakPoints.smallTablet} {
    button {
      max-width: 300px;
    }
  }

  .new-input {
    label {
      display: block;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 5px;
    }

    input {
      border: 1px solid #979797;
      border-radius: 4px;
      height: 50px;

    &::placeholder {
        font-size: 14px;
        color: #979797;
        letter-spacing: 0;
        line-height: 21px;
      }
    }

    .input-error{
      background: no-repeat;
      color: red;
      margin: 10px 0px 0;
      padding: 0;
      font-size: 12px;
    }
  }
`;

const Header = styled.div`
  /* margin-top: 30px; */
  position: relative;

  span {
    font-size: 28px;
    color: #0086d8;
    letter-spacing: 0;
    line-height: 47px;
    font-weight: 100;
  }
`;

const Image = styled.img.attrs({ src: props => props.src })`
  position: relative;
  display: block;
  margin: 20px auto -40px;
  z-index: 100;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled.p`
  color: ${colors.white};
  font-size: 14px;
  letter-spacing: -0.39px;
  line-height: 18px;
  text-align: center;
`;

const SetupPasswordView = (props) => {
  // state = { sent: false };
  const sent = false;
  const params = useParams();
  const history = useHistory();
  const { t } = props;
  const { token } = params;

  const restorePassword = ({ password }) => {
    props.restorePassword({ password, token });
  };


  useEffect(() => {
    if (!token) {
      history.push('/login');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <View>
        <Header>
          <Title size={30} color={colors.kinedu} align="center" bold>
            {sent ? t('SETUP_PASSWORD.WELL_DONE') : t('SETUP_PASSWORD.SETUP_NEW_PASS')}
          </Title>
          <Image src={sent ? BabyHappy : BabyPlaying} />
        </Header>
        <Footer>
          {sent
            ? (
              <Message>
                <Description>{t('SETUP_PASSWORD.PASSWORD_CHANGED')}</Description>
              </Message>
            )
            : (
              <Form onValidSubmit={restorePassword}>
                <TextInput
                  name="password"
                  type="password"
                  placeholder={t('SETUP_PASSWORD.NEW_PASSWORD')}
                  label={t('SETUP_PASSWORD.NEW_PASSWORD')}
                  required
                  theme={Flat}
                  className="new-input"
                />
                <TextInput
                  name="confirm_password"
                  type="password"
                  className="new-input"
                  validations="equalsField:password"
                  validationErrors={{
                    equalsField: t(`validations:${VALIDATIONS_MESSAGES.PASS_DONT_MATCH}`),
                  }}
                  placeholder={t('SETUP_PASSWORD.CONFIRM_PASSWORD')}
                  label={t('SETUP_PASSWORD.CONFIRM_PASSWORD')}
                  required
                  theme={Flat}
                />
                <Button
                  color="green"
                  type="submit"
                  rounded
                  small
                  maxWidth={300}
                >
                  {t('SETUP_PASSWORD.CHANGE_PASSWORD')}
                </Button>
              </Form>
            )
          }
        </Footer>
      </View>
    </Container>
  );
};

SetupPasswordView.propTypes = {
  restorePassword: PropTypes.func,
  t: PropTypes.func,
};
SetupPasswordView.defaultProps = {
  restorePassword: () => {},
  t: () => {},
};

const mapDispatchToProps = dispatch => bind({
  restorePassword: Creators.restorePassword,
}, dispatch);

export default compose(
  connect(() => ({}), mapDispatchToProps),
  withTranslation([Namespaces.COMMON]),
)(SetupPasswordView);
