import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { camelizeKeys } from 'humps';
import { toast } from 'react-toastify';
import { path } from 'ramda';

import { Types, Creators } from '../ducks/user';
import { Creators as FamilyCreators, Types as FamiliesTypes } from '../ducks/babiesAndFamilies';

import * as UserApi from '../../api/users';
import { putFamilies, postFamily } from '../../api/families';
import { getGenderByRole } from '../../lib/utils';
import { Auth } from '../ducks';

const BROWSER_DEFAULT_LANGUAGE = navigator.language;

const fetchUserDetail = (action$, { getState }) => action$.ofType(Types.FETCH_USER_DETAIL_REQUESTED)
  .mergeMap(() => {
    const id = getState().auth.userId;
    return UserApi.getUser(id)
      .then(res => camelizeKeys(res.data.data.user))
      .then(user => Creators.fetchUserDetailSuccess(user));
  });

const fetchPendingInvites = action$ => action$.ofType(Types.FETCH_PENDING_INVITES_REQUESTED)
  .mergeMap(() => UserApi.getPendingInvites()
    .then(res => camelizeKeys(res.data.data.memberships))
    .then(invites => Creators.fetchPendingInvitesSuccess(invites)));

const updateOnlyUser = (action$, { getState }) => action$.ofType(Types.UPDATE_ONLY_USER)
  .mergeMap(({
    id = getState().auth.userId, name, lastname, birthday, avatar, relationship,
  }) => Observable.from(Promise.all([
    UserApi.updateUser({
      id,
      name,
      lastname,
      locale: BROWSER_DEFAULT_LANGUAGE,
      birthday,
      gender: getGenderByRole(relationship),
      avatar,
      relationship,
    })
      .then(() => UserApi.getUser(id))
      .then(res => res.data.data.user),
  ]))
    .mergeMap(([user]) => Observable.concat([
      Creators.updateUserSuccess(user),
    ]))
    .catch((err) => {
      toast.error('There was an error.');
      return [Creators.updateUserError(err)];
    }));

const updateUser = (action$, { getState }) => action$.ofType(Types.EDIT_USER)
  .mergeMap(({
    id = getState().auth.userId, name, lastname, birthday, avatar, relationship,
  }) => {
    const familyId = path(['user', 'myFamily', 'id'], getState());
    return Observable.from(Promise.all([
      UserApi.updateUser({
        id,
        name,
        lastname,
        locale: BROWSER_DEFAULT_LANGUAGE,
        birthday,
        gender: getGenderByRole(relationship),
        avatar,
        relationship,
      })
        .then(() => UserApi.getUser(id))
        .then(res => res.data.data.user),
      putFamilies({ id: familyId, name: lastname }),
    ]))
      .mergeMap(([user]) => Observable.concat([
        Creators.updateUserSuccess(camelizeKeys(user)),
        FamilyCreators.updateFamilySuccess({ userId: id, familyId, name: lastname }),
      ])).catch((err) => {
        toast.error('There was an error.');
        return [Creators.updateUserError(err)];
      });
  });

const createFamilyAndUpdateUser = (action$, { getState }) => action$.ofType(Types.CREATE_FAMILY_AND_UPDATE_USER)
  .mergeMap(({
    id = getState().auth.userId, name, lastname, birthday, avatar, relationship,
  }) => {
    const familyId = path(['user', 'myFamily', 'id'], getState());
    if (!familyId) {
      // return Observable.merge(
      //   [{ type: 'CREATE_FAMILY_REQUESTED' }],
      //   action$.ofType('CREATE_FAMILY_SUCCESS')
      //     .mergeMap(() => [{ type: 'FETCH_USER_DETAIL_REQUESTED' }]),
      //   action$.ofType('FETCH_USER_DETAIL_SUCCESS')
      //     .mergeMap(() => [{
      //       type: 'EDIT_USER',
      //       id,
      //       name,
      //       lastname,
      //       birthday,
      //       avatar,
      //       relationship,
      //     }]),
      // );

      // FIXME: Refactor this, there must be a better way to do this
      return Observable.fromPromise(
        postFamily()
          .then(() => ({ type: 'FAMILY_CREATED' }))
          .catch(err => ({ type: 'FAMILY_NOT_CREATED', err })),
      ).mergeMap(() => Observable.fromPromise(
        UserApi.getUser(id)
          .then(res => camelizeKeys(res.data.data.user)),
      )).mergeMap(user => Observable.from(Promise.all([
        UserApi.updateUser({
          id,
          name,
          lastname,
          locale: BROWSER_DEFAULT_LANGUAGE,
          birthday,
          gender: getGenderByRole(relationship),
          avatar,
          relationship,
        })
          .then(() => UserApi.getUser(id))
          .then(res => res.data.data.user)
          .catch((err) => {
            toast.error('There was an error.');
            return [Creators.updateUserError(err)];
          }),
        putFamilies({ id: user.myFamily.id, name: lastname }),
      ]))
        .mergeMap(([newUser]) =>
          // toast.success('User updated correctly');
          Observable.concat([
            Creators.updateUserSuccess(camelizeKeys(newUser)),
            FamilyCreators.updateFamilySuccess({ userId: id, familyId, name: lastname }),
          ])));
    }

    return Observable.from(Promise.all([
      UserApi.updateUser({
        id,
        name,
        lastname,
        locale: BROWSER_DEFAULT_LANGUAGE,
        birthday,
        gender: getGenderByRole(relationship),
        avatar,
        relationship,
      })
        .then(() => UserApi.getUser(id))
        .then(res => res.data.data.user),
      putFamilies({ id: familyId, name: lastname }),
    ]))
      .mergeMap(([user]) =>
        // toast.success('User updated correctly');
        Observable.concat([
          Creators.updateUserSuccess(camelizeKeys(user)),
          FamilyCreators.updateFamilySuccess({ userId: id, familyId, name: lastname }),
        ]))
      .catch((err) => {
        toast.error('There was an error.');
        return [Creators.updateUserError(err)];
      });
  });

const setDeleteFamilyFlag = action$ => action$.ofType(FamiliesTypes.DELETE_FAMILY_SUCCESS)
  .mapTo(Creators.setDeleteFamilyFlag());

const deleteUserAccount = (
  action$,
  { getState },
) => action$.ofType(Types.DELETE_USER_ACCOUNT_REQUESTED)
  .mergeMap(() => {
    const id = getState().auth.userId;
    return UserApi.deleteUser(id)
      .then(res => Auth.Creators.logout())
      .catch((err) => {
        toast.error(`There was an error: ${err}`);
      });
  });

const observers = {
  fetchUserDetail,
  fetchPendingInvites,
  updateUser,
  updateOnlyUser,
  createFamilyAndUpdateUser,
  setDeleteFamilyFlag,
  deleteUserAccount,
};

export default combineEpics(...Object.values(observers));
