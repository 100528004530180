
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import NeoModal from '../modals/NeoModal';
import { colors, breakPoints } from '../../styles';

import BabyHappy from '../../images/BabyHappy.svg';

const Image = styled.img.attrs({ src: props => props.src })`
  width: 132px;
  margin: 20px;

  ${breakPoints.smallTablet} {
    width: 160px;
  }
`;

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  background-color: ${colors.white};
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 54px;
  background-color: ${colors.kinedu};

  color: ${colors.white};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 24.5px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 40px;
  box-sizing: border-box;

  ${breakPoints.smallTablet} {
    padding: 20px;
  }
`;

const Title = styled.span`
  color: ${colors.cognitive};
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -1.4px;
  text-align: center;

  ${breakPoints.smallTablet} {
    margin: 10px 0;
    color: ${colors.kinedu};
    font-size: 28px;
    letter-spacing: -1.23px;
  }
`;

const Description = styled.span`
  color: ${colors.shadeMediumDark};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.42px;
  line-height: 25px;
  text-align: center;

  margin: 20px 0;

  ${breakPoints.smallTablet} {
    color: ${colors.shadeMediumDark};
    font-size: 18px;
  }
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'ThankYouPurchaseModal',
  data: {
  },
}

*/

class ThankYouPurchaseModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plan: null,
    };
  }

  setPlan = (plan) => {
    this.setState({ plan });
  }

  render() {
    const { isOpen, t } = this.props;
    return (
      <Modal width="620" isOpen={isOpen} roundedCorners>
        {() => (
          <Fragment>
            <Header>{t('MODAL_THANK_YOU_PURCHASE.TITLE')}</Header>
            <Content>
              <Title>{t('MODAL_THANK_YOU_PURCHASE.SUBTITLE')}</Title>
              <Image src={BabyHappy} />
              <Description>
                {t('MODAL_THANK_YOU_PURCHASE.DESCRIPTION')}
              </Description>
            </Content>
          </Fragment>
        )}
      </Modal>
    );
  }
}

ThankYouPurchaseModal.propTypes = {
  isOpen: PropTypes.func,
  t: PropTypes.func,
};

ThankYouPurchaseModal.defaultProps = {
  isOpen: undefined,
  t: () => {},
};

export default withTranslation([Namespaces.MODALS, Namespaces.COMMON])(ThankYouPurchaseModal);
