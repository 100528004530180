import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import i18n from 'i18next';
import styled from 'styled-components';
import Formsy from 'formsy-react-2';
import { withTranslation } from 'react-i18next';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// import { STRIPE_API_KEY } from '../../../config';

import { Namespaces } from '../../../values/i18n';
// import CreditCard from '../../shared/CreditCard';
// import StripeCardForm from '../../shared/StripeCardForm';
import { breakPoints } from '../../../styles';
import Button from '../../shared/buttons/MainButton';

const Form = styled(Formsy.Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0px 15px 30px;
  box-sizing: border-box;

  ${breakPoints.smallTablet} {
    padding: 50px 100px;
  }
  button {
    font-size: 16px;
  }
`;

const Description = styled.span`
  color: #4A4A4A;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.5px;
  line-height: 30px;
  text-align: center;
  margin: 40px 0 20px;

  ${breakPoints.smallTablet} {
    margin: 0 0 30px;
    font-size: 26px;
    letter-spacing: -0.58px;
    line-height: 33px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: auto;
  .next-btn {
    margin-left: 8px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 40px;
`;

// const stripePromise = loadStripe(STRIPE_API_KEY);
// const stripePromise = '';

const PaymentForm = (props) => {
  const [paymentState, setPaymentState] = useState({ card: undefined, valid: false, isSubmitting: false });
  const {
    onSubmit, onPrevious, onPurchase, t,
  } = props;

  const setStateData = newState => setPaymentState(prevState => ({ ...prevState, ...newState }));

  const handleSubmit = () => {
    onSubmit({ card: paymentState.card }, onPurchase);
  };

  useEffect(() => {
    if (paymentState?.card) {
      handleSubmit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentState]);


  return (
    <Form>
      <Description>{t('MODAL_PAYMENT.SUBTITLE')}</Description>
      <Content>
        {/* <Elements
          stripe={stripePromise}
          options={{ locale: (i18n.language).substring(0, 2) }}
        >
          <StripeCardForm setData={setStateData} isSubmitting={paymentState.isSubmitting} />
        </Elements> */}
      </Content>
      <ButtonsContainer>
        <Button
          onClick={() => onPrevious()}
          color="outlinedGray"
          maxWidth={300}
          type="button"
        >
          {t('common:GO_BACK')}
        </Button>

        <Button
          type="submit"
          color={paymentState.valid ? 'green' : 'disabled'}
          disabled={!paymentState.valid}
          style={{ textTransform: 'capitalize' }}
          maxWidth={300}
          onClick={() => setStateData({ isSubmitting: true })}
        >
          {t('common:NEXT')}
        </Button>
      </ButtonsContainer>
    </Form>
  );
};

PaymentForm.propTypes = {
  /* callback when form is submited */
  onSubmit: PropTypes.func,
  /* callback when "go back" button is clicked */
  onPrevious: PropTypes.func,
  onPurchase: PropTypes.func,
  t: PropTypes.func,
};
PaymentForm.defaultProps = {
  onSubmit: () => { },
  onPrevious: () => { },
  onPurchase: () => { },
  t: () => { },
};

export default withTranslation([Namespaces.MODALS, Namespaces.COMMON])(PaymentForm);
