import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Formsy from 'formsy-react-2';
import { compose } from 'ramda';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import { colors, breakPoints } from '../../../styles';

import Button from '../../shared/buttons/MainButton';
import TextArea from '../../shared/TextArea';
import SegmentedBoxes from '../../shared/SegmentedBoxes';
import { CheckListBoxes } from '../../themes/SegmentedBoxes';
import NpsTachometer from './NpsTachometer';

const SurveyContainer = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.kinedu};
  min-height: 65px;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;

  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: -0.71px;
  line-height: 30px;
  text-align: center;

  ${breakPoints.smallTablet} {
    font-size: 18px;
    letter-spacing: -0.54px;
  }
`;

const Question = styled.h3`
  color: ${colors.physical};
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.75px;
  line-height: 30px;
  text-align: center;
  margin: 10px auto;

  max-width: 600px;

  ${breakPoints.smallTablet} {
    font-size: 21px;
    letter-spacing: -0.79px;
    line-height: 25.2px;
  }
`;

const Description = styled.div`
  color: ${colors.shadeMediumDark};
  font-size: 18px;
  letter-spacing: -1.17px;
  line-height: 25px;
  text-align: center;

  ${breakPoints.smallTablet} {
    font-size: 14px;
    letter-spacing: -0.93px;
    line-height: 25px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  max-width: 850px;
  box-sizing: border-box;
  padding: 20px;
  margin: auto;

  overflow-y: scroll;

  ${breakPoints.smallTablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;

  margin: 40px 0;

  ${breakPoints.qLargeTablet} {

  }
`;

const Form = styled(Formsy.Form)`
  width: 100%;
  max-width: 620px;

  ${breakPoints.smallTablet} {
    width: 586px;
  }
`;

class Survey extends React.Component {
  state = {
    questions: null,
    sendEnabled: false,
    questionIndex: 0,
    questionCount: 0,
    jumpToLast: false,
    results: {
      comment: '',
      answers: [],
      nps: null,
    },
  };

  componentWillMount = () => {
    this.setState({
      questions: this.props.questions,
      questionCount: this.props.questions.length,
    });
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyPress, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress, false);
  }

  onSubmit = (values) => {
    /* Pass the submited form values to the state */
    this.setState({ results: { ...this.state.results, ...values } }, () => {
      this.nextStep();
    });
  }

  keyPress = (ev) => {
    if (ev.keyCode === 13) {
      this.surveyForm.submit();
    }
  }

  nextStep = () => {
    /* If is not the last question, move to the next */
    if (this.state.questionIndex + 1 !== this.state.questionCount && !this.state.jumpToLast) {
      this.setState({
        questionIndex: this.state.questionIndex + 1,
      });
    } else {
      this.saveAnswers();
    }
  }

  saveAnswers = () => {
    /* Map array to get only the value of the selected answers */
    const answers = this.state.results.answers.filter(item => item.selected === true)
      .map(item => item.value);
    /* Create object with the new answers */
    const results = { ...this.state.results, answers };
    this.props.onSubmit(results);
  }

  validateAnswers = (values) => {
    const { nps = 0, answers = [] } = values;
    const { value } = answers.filter(a => a.selected)[0] || {};

    if (nps > 8 || value === 1) {
      this.setState({ jumpToLast: true });
    } else {
      this.setState({ jumpToLast: false });
    }
  }

  render() {
    const { code, t } = this.props;
    /* Get the current question of the array */
    let currentQuestion = this.state.questions[this.state.questionIndex];
    /* If the question doesn't exists return an empty object literal */
    currentQuestion = currentQuestion || {};
    /* If the question doesn't have an option list return an empty array */
    let options = currentQuestion.options || [];
    /* Map options to in the form: { label: '', value: '' } */
    options = options.map((o, i) => ({ label: t(o), value: i + 1 }));


    return (
      <SurveyContainer>
        <Header className="flex-center">
          {currentQuestion.header ? t(`${currentQuestion.header}`) : t('WE_LOVE_FEEDBACK')}
        </Header>
        <Content>
          <Question>
            {t(`${currentQuestion.question}`)}
          </Question>
          {currentQuestion.question_type === 'choice' && code !== 'NES'
            && (
            <Description>
              {t('DESCRIPTION')}
            </Description>
            )
          }
          <Form
            ref={(ref) => { this.surveyForm = ref; }}
            onValid={() => this.setState({ sendEnabled: true })}
            onInvalid={() => this.setState({ sendEnabled: false })}
            onChange={val => this.validateAnswers(val)}
            onSubmit={values => this.onSubmit(values)}
          >
            {currentQuestion.question_type === 'nps' && <NpsTachometer name="nps" required />}
            {currentQuestion.question_type === 'open' && <TextArea name="comment" required autoFocus placeholder={t('common:PLACEHOLDERS.TAB_HERE_TO_WRITE')} style={{ height: '200px' }} />}
            {currentQuestion.question_type === 'choice' && (
            <SegmentedBoxes
              multiple={currentQuestion.multiple}
              theme={CheckListBoxes}
              name="answers"
              boxes={options}
              required
            />
            )}
            <Footer>
              <Button
                type="submit"
                color={this.state.sendEnabled ? 'green' : 'disabled'}
                maxWidth={300}
                disabled={!this.state.sendEnabled}
              >
                {((this.state.questionIndex + 1 === this.state.questionCount) || this.state.jumpToLast)
                  ? t('common:SEND') : t('common:NEXT')}
              </Button>
            </Footer>
          </Form>
        </Content>
      </SurveyContainer>
    );
  }
}

Survey.propTypes = {
  code: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.shape({})),
  onSubmit: PropTypes.func,
  t: PropTypes.func,
};
Survey.defaultProps = {
  code: PropTypes.string,
  questions: [],
  onSubmit: () => { },
  t: () => { },
};

export default compose(
  withTranslation([Namespaces.SURVEYS, Namespaces.COMMON]),
)(Survey);
