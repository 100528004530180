import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors } from '../../../styles';
import { EventProviders } from '../../../lib/EventReporter';

const ColorProviders = {
  [EventProviders.KINEDU]: colors.kinedu,
  // [EventProviders.LEANPLUM]: colors.socialEmotional,
  [EventProviders.MIXPANEL]: colors.physical,
  [EventProviders.GTM]: '#922cff',
  [EventProviders.GA]: '#fbbc04',
  [EventProviders.PIXEL]: colors.fbBlue,
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledExpandableBadge = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 3px 8px;
  margin: 3px 0 3px 5px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: bold;
  text-align: right;
  user-select: none;
  transition: all 0.5s ease-out;

  cursor: pointer;
  pointer-events: all;

  ${({ top }) => `top: ${top}px;`}
  ${({ styles }) => styles}

  ${({ open }) => open && css`
    padding: 3px 12px 12px 8px;
    background-color: #000;
  `}
`;

const ExpandableContent = styled.div`
  display: none;
  transition: all 0.5s ease-out;

  ${({ open }) => open && css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const ExtraInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 0 5px;
  height: 100%;
  background: #000;
  border-radius: 4px;

  opacity: 0;
  transition: all 0.5s ease-out;

  ${({ open }) => open && css`
    opacity: 1;
  `}
`;

const ExtraBlock = styled.div`
  display: flex;
  padding: 0px 3px;
  margin: 3px 4px 3px 0px;
  border-radius: 2px;
  font-size: 11px;
  font-weight: bold;
  color: #FFF;
  background-color: ${({ provider }) => ColorProviders[provider]};

  &:last-child {
    margin: 3px 0px;
  }

  &:only-child {
    margin: 3px 0px;
  }
`;

const Item = styled.span`
  display: block;
  font-weight: 500;
  strong {
    margin-right: 5px;
  }
`;

class ExpandableBadge extends Component {
  state = { open: false };

  toggleBadge = () => this.setState({ open: !this.state.open });

  render() {
    const { title, params, list, styles } = this.props;
    const listValues = Object.values(params);
    const listKeys = Object.keys(params);
    const hasItems = listValues.length > 0;
    const { providers = [] } = list || {};
    return (
      <Wrapper>
        <ExtraInfo open={this.state.open}>
          {providers.map((p, i) => (
            <ExtraBlock
              key={i} // eslint-disable-line
              provider={p}
            >
              {p.substring(0, 2)}
            </ExtraBlock>
          ))}
        </ExtraInfo>
        <StyledExpandableBadge
          styles={styles}
          onClick={this.toggleBadge}
          open={this.state.open}
          hasItems={hasItems}
        >
          {title}
          <ExpandableContent
            open={this.state.open}
            hasItems={hasItems}
          >
            {listKeys.map((param, i) => (
              <Item
                key={i} // eslint-disable-line
              >
                <strong>{param}:</strong> {listValues[i]}
              </Item>
            ))}
          </ExpandableContent>
        </StyledExpandableBadge>
      </Wrapper>
    );
  }
}

ExpandableBadge.propTypes = {
  list: PropTypes.object,
  title: PropTypes.string,
  styles: PropTypes.array,
  params: PropTypes.object,
};

ExpandableBadge.defaultProps = {
  list: {},
  title: '',
  styles: [],
  params: {},
};

export default ExpandableBadge;
