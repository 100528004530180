import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, css } from 'styled-components';
import { Default } from '../../themes/RoundedButton';
import { typography } from '../../../styles';

const RoundedButtonWrapper = styled.button`
  ${({ theme }) => theme.button};
  width: ${({ width }) => width && (Number.isInteger(width) ? `${width}px` : width)};
  max-width: ${({ maxWidth }) => maxWidth && (Number.isInteger(maxWidth) ? `${maxWidth}px` : maxWidth)};
  font-family: ${typography.gothamRounded};
  cursor: pointer;
  outline: none;
  font-weight: bold;
  ${({ styles }) => styles}
  ${({ show }) => !show && css`
    display: none;
  `}
`;

const RoundedButton = ({ theme, maxWidth, className, children, onClick, width, show, style, styles }) => (
  <ThemeProvider theme={theme}>
    <RoundedButtonWrapper
      onClick={onClick}
      width={width}
      maxWidth={maxWidth}
      className={className}
      style={style}
      styles={styles}
      show={show}
    >
      {children}
    </RoundedButtonWrapper>
  </ThemeProvider>
);

RoundedButton.propTypes = {
  theme: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  style: PropTypes.object,
  styles: PropTypes.array,
  show: PropTypes.bool,
};
RoundedButton.defaultProps = {
  theme: Default,
  className: '',
  children: '',
  onClick: () => {},
  width: 'auto',
  style: {},
  styles: [],
  show: true,
};

export default RoundedButton;
