import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Selectors } from '../../data/ducks/babiesAndFamilies';
import Button from '../shared/buttons/MainButton';
import RoundedButton from '../shared/buttons/RoundedButton';
import { ReactComponent as Email } from '../../images/feed/mail.svg';
import Checkmark from '../../images/feed/checkmark.svg';
import { breakPoints } from '../../styles';
import NeoModal from './NeoModal';
import { NeoFilled } from '../themes/RoundedButton';
import { Invites, Modals } from '../../data/ducks';
import { Namespaces } from '../../values/i18n';
import { genderAdposition, posssesiveAccusativePt, possessivePronoun, genderPronoun } from '../../lib/utils';

const Wrapper = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  padding: 40px;
  margin: auto;
  box-sizing: border-box;
  max-width: ${props => props.width};
  height: 100vh;
  border-radius: unset;
  
  ${breakPoints.smallTablet} {
    height: auto;
    max-width: 620px;
    padding: 30px 0px;
    border-radius: 20px;
  }

  .primaryActionBtn {
    margin-bottom: 0px;
  }
`;

const Title = styled.span`
  color: #008FD3;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  max-width: 270px;
  line-height: 26px;
  .centerName {
    color: #005081;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Subtitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #4A4A4A;
  margin: 10px 0px;
`;

const BulletList = styled.ol`
  list-style: none;
  color: #4A4A4A;
  font-size: 14px;
  margin-bottom: 20px;

  li {
    flex: 1 1 100%;
    display: flex;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const Bullet = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-right: 10px;
  line-height: 27px;

  background-color: #75B753;
  background-position: center;
  background-size: 12px;
  background-image: url(${Checkmark});
`;

const ClassroomsInviteModal = ({
  t, inviteData, isOpen,
}) => {
  const { newUserData, familyId, id: membershipId } = inviteData;
  const dispatch = useDispatch();
  const activeBaby = useSelector(state => Selectors.activeBaby(state));
  const premiumItems = [
    t('CLASSROOMS_INVITE.UPDATES_ON', { babyName: activeBaby.name, gender: genderAdposition(activeBaby.gender) }),
    t('CLASSROOMS_INVITE.ACTIVITY_PLAN'),
    t('CLASSROOMS_INVITE.PROGRESS', { genderPronoun: genderPronoun(activeBaby.gender), gender: genderAdposition(activeBaby.gender), possesive: posssesiveAccusativePt(activeBaby.gender) }),
    t('CLASSROOMS_INVITE.PREMIUM_PREMIUM_CONTENT'),
  ];
  const freemiumItems = [
    t('CLASSROOMS_INVITE.UPDATES_ON', { babyName: activeBaby.name, gender: genderAdposition(activeBaby.gender) }),
    t('CLASSROOMS_INVITE.ACTIVITY_PLAN'),
    t('CLASSROOMS_INVITE.FREEMIUM_MILESTONES', { genderPronoun: genderPronoun(activeBaby.gender), gender: possessivePronoun(activeBaby.gender) }),
    t('CLASSROOMS_INVITE.PROGRESS', { genderPronoun: genderPronoun(activeBaby.gender), gender: genderAdposition(activeBaby.gender), possesive: posssesiveAccusativePt(activeBaby.gender) }),
  ];
  const isPremium = !!(newUserData.subscriptionType === 'Plus' || newUserData.subscriptionType === 'Premium');
  return (
    <Wrapper
      isOpen={isOpen}
      showCloseButton={false}
      closeOnBackdrop={false}
    >
      {() => (
        <>
          <Email className="emailIcon" />
          <Title>
            {t('CLASSROOMS_INVITE.INVITE_RECEIVED')}
            <span className="centerName">
              {` ${newUserData.centerName}`}
            </span>
            !
          </Title>
          <Content>
            <Subtitle>{t('CLASSROOMS_INVITE.SUBTITLE')}</Subtitle>
            <BulletList>
              {isPremium ? premiumItems.map(item => (
                <li>
                  <Bullet />
                  {item}
                </li>
              ))
                : freemiumItems.map(item => (
                  <li>
                    <Bullet />
                    {item}
                  </li>
                ))}
            </BulletList>
            <RoundedButton
              className="primaryActionBtn"
              theme={NeoFilled}
              width={300}
              onClick={() => dispatch(Invites.Creators.updateInviteStatusRequested(
                'classrooms_accepted', familyId, membershipId,
              ))}
            >
              {t('CLASSROOMS_INVITE.ACCEPT_INVITE')}
            </RoundedButton>
            <Button
              color="clear"
              maxWidth={300}
              onClick={() => dispatch(Modals.Creators.openModal({
                name: 'ClassroomsConfirmModal',
                data: inviteData,
              }))}
            >
              {t('CLASSROOMS_INVITE.NOT_RECOGNIZED')}
            </Button>
          </Content>
        </>
      )}
    </Wrapper>
  );
};
ClassroomsInviteModal.propTypes = {
  inviteData: PropTypes.object,
  isOpen: PropTypes.bool,
};
ClassroomsInviteModal.defaultProps = {
  inviteData: {},
  isOpen: false,
};

export default withTranslation([Namespaces.DAP])(ClassroomsInviteModal);
