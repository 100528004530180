import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import NeoModal from './NeoModal';
import { breakPoints } from '../../styles';

import PassExpiredView from '../views/PassExpired';

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 8px;
  height: 100%;

  ${breakPoints.smallTablet} {
    margin: auto;
    height: auto;
  }
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'PassExpiredModal',
  data: {
  },
}

*/

const PassExpiredModal = props => (
  <Modal width="800" isOpen={props.isOpen}>
    {() => (
      <Fragment>
        <PassExpiredView onClose={props.onClose} />
      </Fragment>
    )}
  </Modal>
);

PassExpiredModal.propTypes = {
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
};

PassExpiredModal.defaultProps = {
  isOpen: () => {},
  onClose: () => {},
};

export default withTranslation([Namespaces.MODALS])(PassExpiredModal);
