import { AWS_REGION, FILES_API_URL, S3_BUCKET_IMAGES } from '../../config';

export const s3Config = {
  apiURL: FILES_API_URL,
  evaporateOptions: {
    awsRegion: AWS_REGION,
    bucket: S3_BUCKET_IMAGES,
  },
  processAwsConfig: (config, file, s3Path) => {
    const name = `${s3Path}${config.name}`;
    return { ...config, name };
  },
};
