import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Markdown from 'react-remarkable';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import Modal from './NeoModal';
import { colors } from '../../styles';
import Collapsible from '../shared/Collapsible';
import Button from '../shared/buttons/MainButton';
import BabyWithIphone from '../../images/BabyWithIphone2.png';
import { Flat } from '../themes/Collapsible';

const Image = styled.img.attrs({ src: BabyWithIphone })`
  width: 135px;
  margin: 20px auto 0px;
`;

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 0px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.white};
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;

  background: ${colors.physical};
  padding: 15px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  padding: 20px 20px 60px;
  box-sizing: border-box;
`;

const Description = styled.div`
  font-size: 16px;
  color: ${colors.shadeDarker};
  letter-spacing: -0.44px;
  text-align: center;
  line-height: 21px;

  margin-bottom: 20px;
`;

const P = styled.p`
  margin: 0 0 20px;
`;

const Footer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding: 20px;
  background: #CFD5DA;
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'GDPRModal',
  data: {
  },
}

*/

const Answer1 = t => (
  <div>
    <P><Markdown container="span">{t('MODAL_GDPR.Q1.A1')}</Markdown></P>
    <P><Markdown container="span">{t('MODAL_GDPR.Q1.A2')}</Markdown></P>
    <P><Markdown container="span">{t('MODAL_GDPR.Q1.A3')}</Markdown></P>
    <P><Markdown container="span">{t('MODAL_GDPR.Q1.A4')}</Markdown></P>
    <P><Markdown container="span">{t('MODAL_GDPR.Q1.A5')}</Markdown></P>
  </div>
);

const GDPRModal = ({ t }) => (
  <Wrapper width={854} showCloseButton={false} closeOnBackdrop={false}>
    {({ submitModal }) => (
      <Fragment>
        <Title>{t('MODAL_GDPR.TITLE')}</Title>
        <Content>
          <Image />
          <Description>
            {t('MODAL_GDPR.DESC1')}
          </Description>
          <Description>
            {t('MODAL_GDPR.DESC2')}
          </Description>
        </Content>
        <Collapsible
          theme={Flat}
          items={[
            {
              title: t('MODAL_GDPR.Q1.TITLE'),
              body: Answer1(t),
            },
            {
              title: t('MODAL_GDPR.Q2.TITLE'),
              body: <P><Markdown container="span">{t('MODAL_GDPR.Q2.A1')}</Markdown></P>,
            },
            {
              title: t('MODAL_GDPR.Q3.TITLE'),
              body: <P><Markdown container="span">{t('MODAL_GDPR.Q3.A1')}</Markdown></P>,
            },
            {
              title: t('MODAL_GDPR.Q4.TITLE'),
              body: <P><Markdown container="span">{t('MODAL_GDPR.Q4.A1')}</Markdown></P>,
            },
          ]}
        />
        <Footer>
          <Button color="kinedu" maxWidth={300} onClick={submitModal}>{t('MODAL_GDPR.I_ACCEPT')}</Button>
        </Footer>
      </Fragment>
    )}
  </Wrapper>
);

GDPRModal.propTypes = {
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  closeOnBackdrop: PropTypes.bool,
};

GDPRModal.defaultProps = {
  onClose: () => { },
  showClosebutton: false,
  closeOnBackdrop: true,
};

export default withTranslation([Namespaces.MODALS])(GDPRModal);
