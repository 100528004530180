import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { colors, breakPoints } from '../../styles';
import PermissionsList from '../../components/families/PermissionsList';

import babyImage from '../../images/invite_baby.png';
import { PERMISSIONS, memberRoles } from '../../values/families';
import Portal from '../../components/shared/Portal';
import EmptyState from '../../components/views/EmptyState';
import Button from '../../components/shared/buttons/MainButton';

const BabyImg = styled.img.attrs({ src: babyImage })`
  display: block;
  height: 126px;
  width: auto;
  margin: 0;
`;

const ImagesContainer = styled.div`
  display: block;

  height: 126px;
  width: auto;

  margin: 0;
`;

const Title = styled.h2`
  color: ${colors.kinedu};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.62px;
  text-align: center;
  line-height: 30px;

  max-width: 300px;
`;

const Description = styled.p`
  color: ${colors.shadeDark};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.8px;
  text-align: center;
  line-height: 23px;

  max-width: 300px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'Invite',
  data: {
    role: 2,
    relationship: 7,
    inviterName: 'Cristy',
    inviterLastname: 'Tovar',
    familyName: 'Tovar',
    babyName: 'Diego'
  },
}

*/

const Invitation = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;

  width: 100%;
  max-width: 900px;

  ${breakPoints.smallTablet} {
    position: relative;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${colors.white};
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 70px;
  background-color: ${colors.kinedu};

  ${({ roundedCorners }) => roundedCorners && css`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `}

  color: ${colors.white};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 24.5px;
`;

const BeenInvited = ({
  onClose, onSubmit, role, inviterName, relationship, familyName, t,
}) => (
  <Portal>
    <EmptyState>
      <Invitation>
        <Header>{t('OB_BEEN_INVITED')}</Header>
        <Content>
          <Title>
            {t('OB_INVITATION_MESSAGE', { inviterName, familyName })}
          </Title>
          <ImagesContainer>
            <BabyImg />
          </ImagesContainer>
          <Description>
            {t('OB_INVITATION_DESCRIPTION', { context: role === 2 ? 'primary' : '', familyName })}
          </Description>
          <PermissionsList list={PERMISSIONS[memberRoles[role]]} showTitle={false} />
          <ButtonContainer>
            <Button color="green" maxWidth={300} onClick={() => onSubmit({ data: { relationship } })}>
              {t('OB_ACCEPT_INVITATION')}
            </Button>
            <Button color="clear" maxWidth={300} onClick={onClose}>
              {t('OB_DECLINE_INVITATION')}
            </Button>
          </ButtonContainer>
        </Content>
      </Invitation>
    </EmptyState>
  </Portal>
);

BeenInvited.propTypes = {
  onClose: PropTypes.func,
  /* Rol del invitado */
  role: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  inviterName: PropTypes.string,
  /* El familyName puede ser diferente
    del lastname de quién invita */
  familyName: PropTypes.string,
  relationship: PropTypes.number,
  onSubmit: PropTypes.func,
  t: PropTypes.func,
};

BeenInvited.defaultProps = {
  onClose: () => { },
  role: '',
  inviterName: '',
  familyName: '',
  babyName: '',
  relationship: 3,
  onSubmit: () => { },
  t: () => { },
};

export default withTranslation([Namespaces.OB, Namespaces.COMMON])(BeenInvited);
