import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter, values } from 'ramda';
import { bindActionCreators as bind } from 'redux';
import { Creators, Selectors } from '../../data/ducks/babiesAndFamilies';
import { Creators as ModalCreators } from '../../data/ducks/modals';

const BabiesContainer = ({
  familyId,
  activeBaby = {},
  getBabies,
  prenatalBabies,
  selectBaby,
  editBaby,
  addBaby,
  render,
}) => (
  <Fragment>
    {render({
      babies: getBabies(familyId),
      prenatalBabies,
      selectBaby,
      activeBaby,
      editBaby,
      addBaby,
    })}
  </Fragment>
);

const mapStateToProps = state => ({
  getBabies: id => filter(b => b, values(Selectors.babiesByFamilyId(state, id))),
  prenatalBabies: Selectors.prenatalBabiesByFamilyId(state),
  activeBaby: Selectors.activeBaby(state),
});

const mapDispatchToProps = dispatch => bind({
  selectBaby: Creators.babySelected,
  editBaby: (showDelete = false) => ModalCreators.openModal({ name: 'EditBaby', data: { showDelete } }),
  addBaby: () => ModalCreators.openModal({ name: 'FeedbackSelectionNewBabyModal' }),
  premiumBroke: familyName => ModalCreators.openModal({ name: 'PremiumBrokeModal', familyName }),
}, dispatch);

BabiesContainer.propTypes = {
  familyId: PropTypes.number,
  activeBaby: PropTypes.object,
  getBabies: PropTypes.func,
  prenatalBabies: PropTypes.array,
  selectBaby: PropTypes.func,
  editBaby: PropTypes.func,
  addBaby: PropTypes.func,
  render: PropTypes.func,
};

BabiesContainer.defaultProps = {
  familyId: 0,
  activeBaby: {},
  getBabies: () => {},
  prenatalBabies: [],
  selectBaby: () => {},
  editBaby: () => {},
  addBaby: () => {},
  render: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(BabiesContainer);
