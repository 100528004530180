import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '../../shared/buttons/MainButton';
import CheckList from '../../shared/CheckList';
import Trophy from '../../../images/BadgeTrophy.png';
import { colors } from '../../../styles';
import { Namespaces } from '../../../values/i18n';
import { Progress } from '../../themes/CheckList';
import { genderArticle } from '../../../lib/utils';

const MasterSkillViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const TopContainer = styled.div`
    width: 100%;
    height: 20%;
    display: block;
    background: ${colors.white};
    text-align: center;
    padding: 30px 0 45px;
    max-height: 120px;
`;

const BottomContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 80%;
    background-color: ${colors.shadeLighter};
`;

const ImageContainer = styled.div`
    position: absolute;
    top: -35px;

    width: 70px;
    height: 70px;
    margin: auto;
    border-radius: 100px;

    background-repeat: no-repeat;
    background-size: 28px;
    background-image: url(${Trophy});
    background-position: 55% 50%;
    background-size: 40px;
    background-color: ${colors.trophyYellow};
`;

const Title = styled.h1`
    font-size: 24px;
    color: ${colors.kinedu};
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;
`;

const Descripton = styled.div`
    max-width: 267px;
    margin: auto;
    font-size: 16px;
    color: ${colors.shadeDark};
    letter-spacing: -0.44px;
    text-align: center;
    line-height: 22px;
`;

const Subtitle = styled.h1`
    font-size: 20px;
    color: ${colors.shadeDark};
    letter-spacing: -0.56px;
    text-align: center;
    line-height: 24px;
    max-width: 267px;
    margin: 50px 0 20px;
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
`;

const MasterSkillView = ({
  milestones,
  baby,
  closeModal,
  setMasterSkill,
  skill,
  activeMilestones,
  completedMilestones,
  showDS,
  updateAllMilestones,
  t,
}) => (
  <MasterSkillViewContainer>
    <TopContainer>
      <Title>{t('common:AWESOME')}</Title>
      <Descripton>
        {t('MASTER_SKILL_MODAL.DESCRIPTION', {
          babyName: baby.name,
          article: genderArticle(baby.gender),
        })}
      </Descripton>
    </TopContainer>
    <BottomContainer>
      <ImageContainer />
      <ContentWrapper>
        <Subtitle>
          {t('MASTER_SKILL_MODAL.SUBTITLE', {
            babyName: baby.name,
          })}

        </Subtitle>
        <CheckList
          theme={Progress}
          style={{ maxWidth: '300px' }}
          textSize="14px"
          items={milestones}
        />
      </ContentWrapper>
      <ButtonWrapper>
        <Button
          maxWidth={300}
          color="green"
          onClick={() => {
            updateAllMilestones();
            setMasterSkill({
              baby, skill, activeMilestones, completedMilestones, showDS,
            });
            closeModal();
          }}
        >
          {t('MASTER_SKILL_MODAL.MARK_AS_COMPLETED')}
        </Button>
        <Button maxWidth={300} color="clear" onClick={closeModal}>
          {t('common:GO_BACK')}
        </Button>
      </ButtonWrapper>
    </BottomContainer>
  </MasterSkillViewContainer>
);
MasterSkillView.propTypes = {
  skillId: PropTypes.number,
  milestones: PropTypes.array,
  baby: PropTypes.object,
  setMasterSkill: PropTypes.func,
  closeModal: PropTypes.func,
  updateAllMilestones: PropTypes.func,
  t: PropTypes.func,
};

MasterSkillView.defaultProps = {
  skillId: 0,
  milestones: [],
  baby: {},
  setMasterSkill: () => {},
  updateAllMilestones: () => {},
  closeModal: () => {},
  t: () => { },
};

export default withTranslation([Namespaces.MODALS, Namespaces.COMMON])(MasterSkillView);
