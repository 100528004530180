import BaseApi, { BaseApiV3, BaseApiV4, localeFlag } from './BaseApi';
import { formatRequest } from '../lib/utils';
import i18n from 'i18next';

export const getBabyActivityPlans = babyId => BaseApi.get(`/babies/${babyId}/activity_plans`);
export const getHealthInterests = babyId => BaseApi.get(`/babies/${babyId}/interests?${localeFlag()}`);
export const postHealthInterests = (babyId, interests) => BaseApi.post(`/babies/${babyId}/interests`, { interests });

export const postBaby =
  ({ familyId, name, lastname, gender, birthday, weeksBeforeBirth, avatar }) =>
    BaseApi.post('/babies/', ...formatRequest({
      family_id: familyId,
      name,
      lastname,
      gender,
      birthday,
      weeks_before_birth: weeksBeforeBirth,
      avatar,
    }, 'avatar'));

export const getClassroomBaby = id => BaseApi.get(`/babies/${id}/classroom_feeds/classrooms_baby`);

export const getBabies = id => BaseApi.get(`/babies`);

export const updateBaby = ({ id, name, gender, avatar = null, birthday, weeks_before_birth }) =>
  BaseApi.put(`/babies/${id}`, ...formatRequest({ id, name, gender, avatar, birthday, weeks_before_birth }, 'avatar'));

export const deleteBaby = id => BaseApi.delete(`/babies/${id}`);

export const postWeight = ({ babyId, weight }) =>
  BaseApi.post(`/babies/${babyId}/weights/`, { weight });

export const postHeight = ({ babyId, height }) =>
  BaseApi.post(`/babies/${babyId}/heights/`, { height });

export const postBabyUpdateProgress = ({ babyId, pastAge, actualAge }) =>
  BaseApi.post(`/babies/${babyId}/update_progress`, { pastAge, actualAge });

export const postBabyResetProgress = ({ babyId }) => BaseApi.post(`/babies/${babyId}/reset_progress`);

export const postBabyTurns24 = ({ babyId }) => BaseApi.post(`/babies/${babyId}/premature_notification`);

export const getBabyPhase = babyId => BaseApiV4.get(`/babies/${babyId}/phase?${localeFlag()}`);
export const getBabyPhaseToys = async babyId => BaseApiV4.get(`/babies/${babyId}/phase/toys?${localeFlag()}`);
export const getAgeRanges = () => BaseApiV4.get('/age_ranges', {
  params: {
    locale: i18n.language,
  },
});

export const getAllInterests = (babyId, params = { area_id: 6 }) => BaseApiV3.get(`/babies/${babyId}/interests`, {
  params: {
    ...params,
    ...{ locale: params?.locale || i18n.language },
  },
});

export const saveInterests = (babyId, interests, params = { area_id: 6 }) => BaseApiV3.post(`/babies/${babyId}/interests`, {
  interests,
}, {
  params: {
    ...params,
    ...{ locale: params?.locale || i18n.language },
  },
});
