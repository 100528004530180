import BaseApi from './BaseApi';

export const getShareLink = ({ instanceId, type, activityId }) =>
  BaseApi.post('/shares', {
    instance_id: instanceId,
    shareable_type: type,
    shareable_id: activityId,
  }).then(res => res.data.data);

export const confirmLinkShared = ({ token, instanceId, type, activityId }) =>
  BaseApi.put(`/shares/${token}`, {
    instance_id: instanceId,
    shareable_type: type,
    shareable_id: activityId,
  });
