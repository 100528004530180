import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../styles';

export const Default = {
  container: css`
    color: ${colors.shadeDark};
    font-size: 12px;
    font-weight: 500;
  `,
};

export const Underlined = {
  container: css`
    color: ${colors.physical};
    font-weight: normal;
    font-size: 15.55px;
    letter-spacing: -0.5px;
    line-height: 29.16px;
    text-decoration: underline;
  `,
};

export const Landing = {
  container: css`
    color: ${colors.kinedu};
    font-size: 16px;
    font-weight: normal;
    text-decoration: none;
  `,
};

export const ThemeShape = PropTypes.shape({
  container: PropTypes.array,
});
