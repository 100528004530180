import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaggeredMotion, spring, presets } from 'react-motion';
import { range } from 'ramda';

import ProgressSkillCard from './ProgressSkillCard';
import Loading from '../shared/LoadingOverlay';

const SkillContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
`;

const ProgressSkillList = ({ skills, gotToPendingSkills, baby, loading, push }) => (
  <SkillContainer>
    <Loading loading={loading} position="absolute" />
    <StaggeredMotion
      // FIXME: Check why is receiving just one element in the first iteration
      defaultStyles={range(0, 16).map(() => ({ x: 0 }))}
      styles={prevStyles => prevStyles.map((_, i) => (i === 0
        ? { x: spring(1) }
        : { x: spring(prevStyles[i - 1].x, presets.stiff) }))}
    >
      {styles => (
        <Fragment>
          {skills.map((skill, i) => (
            <ProgressSkillCard
              baby={baby}
              key={skill.id}
              show={styles[i] ? styles[i].x === 1 : true}
              skill={skill}
              gotToPendingSkills={gotToPendingSkills}
              push={push}
            />))}
        </Fragment>
      )}
    </StaggeredMotion>
  </SkillContainer>
);

ProgressSkillList.propTypes = {
  skills: PropTypes.array,
  baby: PropTypes.object,
  gotToPendingSkills: PropTypes.func,
  loading: PropTypes.bool,
};

ProgressSkillList.defaultProps = {
  skills: [],
  baby: {},
  gotToPendingSkills: () => {},
  loading: false,
};

export default ProgressSkillList;
