import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { InputOTP } from 'lego-dist';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import BabyClassroomId from '../../images/invites/BabyClassroomId.svg';
import { Families } from '../../data/ducks';
import { Creators } from '../../data/ducks/invites';
import { genderAdposition, genderPronoun } from '../../lib/utils';
import EventReporter, { Events } from '../../lib/EventReporter';

const ModalContent = styled.div`
  width: 100%;
  text-align: center;

  .spinner {
    margin-top: 10px;
    margin-bottom: 28px;
  }
`;

const Title = styled.div`
  color: #0B86DB;
  font-weight: bold;

  font-size: 20px;
  line-height: 26px;
  margin: 21px auto 0 auto;

  ${({ theme }) => theme.breakPoints.tablet} {
    font-size: 24px;
    line-height: 31px;
    margin: 41px auto 0 auto;
    max-width: 300px;
  }
`;

const BabyIllustration = styled.img`
  width: 110.32px;
  height: 100px;
  margin: 13px auto 23px auto;

  ${({ theme }) => theme.breakPoints.tablet} {
    width: 169px;
    height: 155px;
    margin: 30px auto 23px auto;
  }
`;

const Subtitle = styled.div`
  color: #535354;
  font-size: 16px;
  line-height: 21px;
  margin: 0 auto 19px auto;
  width: 280px;

  ${({ theme }) => theme.breakPoints.tablet} {
    width: 380px;
  }
`;

const OTPWrapper = styled.div`
  margin: auto;
  width: 300px;
  input {
    -moz-box-sizing:    border-box;
    -webkit-box-sizing: border-box;
    box-sizing:        border-box;
    width: 45px !important; // 6 digits OTP
  }
  input:not(:last-child) {
    margin-right: 6px !important; // 6 digits OTP
  }

  .legoInputOtpWrapper input {
    ${({ hasError }) => (hasError && 'border-color: red;')}
    ${({ hasError }) => (hasError && 'color: red;')}
  }
`;

const OTPInstructions = styled.div`
   font-size: 12px;
   color: #8C9DAB;
   text-align: left;
   margin-bottom: 7px;
`;

const InviteTagline = styled.div`
   font-size: 12px;
   color: #404041;
   width: 298px;
   margin: 35px auto 25px auto;
`;

const OTPError = styled.div`
  color: red;
  font-size: 12px;
  text-align: left;
  margin-top: 6px;
`;

const EnterClassroomCodeView = ({
  formData, renderButtons, handleContinue, t,
}) => {
  const tempBaby = JSON.parse(localStorage.getItem('temporaryClassroomsBaby'));
  const activeBaby = useSelector(state => Families.Selectors.activeBaby(state)) || { name: undefined };
  const baby = tempBaby || activeBaby;

  const invitesState = useSelector(state => state.invites);
  const [classroomCode, setClassroomCode] = useState(formData.classroomCode);
  const dispatch = useDispatch();
  const {
    handleSubmit,
  } = useForm();

  const onSubmit = () => {
    dispatch(Creators.setInvitedClassroomLoading(true));
    dispatch(Creators.fetchInvitedClassroomRequested(classroomCode, () => handleContinue({ classroomCode })));
  };

  useEffect(() => {
    dispatch(Creators.clearInvitedClassroom());
    EventReporter.view(Events.CLASSROOM_CODE());
  }, [dispatch]);

  return (
    <ModalContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>
          {t('CLASSROOM_CODE.TITLE', { gender: genderAdposition(baby.gender), baby: baby.name })}
        </Title>
        <BabyIllustration src={BabyClassroomId} />
        <Subtitle>
          {t('CLASSROOM_CODE.SUBTITLE', { pronoun: genderPronoun(baby.gender) })}
        </Subtitle>
        <OTPWrapper hasError={invitesState.invitedClassroomError !== ''}>
          <OTPInstructions>
            {t('CLASSROOM_CODE.OTP_INSTRUCTIONS')}
          </OTPInstructions>
          <InputOTP value={classroomCode} size={6} onChange={code => setClassroomCode(code)} type="tel" />
          <OTPError>{t(invitesState.invitedClassroomError)}</OTPError>
        </OTPWrapper>
        <InviteTagline>
          {t('CLASSROOM_CODE.DETAILED_INFO')}
        </InviteTagline>
        {renderButtons(invitesState.invitedClassroomLoading)}
      </form>
    </ModalContent>
  );
};

export default withTranslation(['classroom_invite', 'common'])(EnterClassroomCodeView);
