import BaseApi, { EmulatedApi } from './BaseApi';
import { buildFormDataFromObject, trimNilValues } from '../lib/utils';

const formatUserRequest = (params) => {
  if (params.avatar) {
    const data = buildFormDataFromObject(params);

    return [data, { headers: { 'content-type': 'multipart/form-data' } }];
  }

  // Sent as an array to be destructured.
  return [trimNilValues(params)];
};

export const getUser = id => BaseApi.get(`/users/${id}`);
export const setAlias = userId => BaseApi.post(`/users/${userId}/set_alias`);

export const getUserWithToken = (id, token) => EmulatedApi(token).get(`/users/${id}`);

export const updateUser =
  ({ id, name, lastname, locale, birthday, gender, avatar = null, relationship }) =>
    BaseApi.put(`/users/${id}`, ...formatUserRequest({ name, lastname, locale, birthday, gender, avatar, relationship }));

export const getEmailAvailability = email => BaseApi.get(`/users/email_availability?q=${email}`);

export const getPendingInvites = () => BaseApi.get('/families/pending_requests');

export const deleteUser = userId => BaseApi.delete(`/users/${userId}`);

export const updateUserLocale = ({ id, locale }) => BaseApi.put(`/users/${id}`, ...formatUserRequest({ locale }));
