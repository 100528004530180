import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ReactPlayer from 'react-player';
import Modal from './NeoModal';
import { ModalNames } from '../../shared-values';
import withModals from '../../containers/HOCs/ModalsHOC';

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  background-color: #E0E0E0;
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'VideoModal',
  data: {
    videoUrl: "http://embed.wistia.com/deliveries/a2adbbe7b596402c0db0885bdc5eb9cb8a23174e.bin"
  },
}

*/


class VideoModal extends Component {
  state = {};

  handleOpenModal = () => {
    this.props.openModal(ModalNames.SIGNUP_MODAL);
  }

  render() {
    const { videoUrl } = this.props;
    return (
      <StyledModal onCloseModal={this.handleOpenModal} roundedCorners preventFromClosing>
        {() => (
          <ReactPlayer
            playing
            controls
            url={videoUrl}
            height="100%"
            width="100%"
            config={{ file: { attributes: { controlsList: 'nodownload', poster: '' } } }}
            style={{ display: 'flex' }}
            onEnded={this.handleOpenModal}
          />
        )}
      </StyledModal>
    );
  }
}

VideoModal.propTypes = {
  videoUrl: PropTypes.string,
};

VideoModal.defaultProps = {
  videoUrl: '',
};

export default withModals(VideoModal);
