import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { toPairs } from 'ramda';

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styled, { css } from 'styled-components';
import Formsy from 'formsy-react-2';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Creators, Selectors } from '../../data/ducks/babiesAndFamilies';
import { Creators as ModalCreators } from '../../data/ducks/modals';
import { Families } from '../../data/ducks';

import TextInput from '../../components/shared/TextInput';
import { colors, breakPoints } from '../../styles';
import { UserRoles } from '../../values/roles';
import { ModalNames } from '../../shared-values';
import EventReporter, { Events } from '../../lib/EventReporter';

import Select from '../../components/shared/NewSelect';
import { Neo as NeoSelect } from '../../components/themes/NewSelect';
import { Neo } from '../../components/themes/TextInput';
import { Neo as NeoBoxes } from '../../components/themes/SegmentedBoxes';
import SegmentedBoxes from '../../components/shared/SegmentedBoxes';
import Button from '../../components/shared/buttons/MainButton';
import PermissionsList from '../../components/families/PermissionsList';
import {
  PERMISSIONS, memberRoles, userRelationshipsForEvents, memberRolesForEvents,
} from '../../values/families';
import InviteSentModal from '../../components/modals/InviteSentModal';

const Form = styled(Formsy.Form)`
  max-width: 667px;
  margin-bottom: 60px;

  & > * {
    margin: 20px auto;
  }

  ${breakPoints.smallTablet} {
    margin: 0 auto 60px;
  }
`;

const Content = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h2`
  color: ${colors.kinedu};
  text-align: center;
  font-size: 38px;
  letter-spacing: -0.91px;
  margin: 0px;
`;

const SubTitle = styled.h4`
  color: ${colors.shadeDark};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.45px;
  text-align: center;
  line-height: 30px;
  margin: 10px 0 30px;
`;

const Description = styled.p`
  font-size: 16px;
  color: ${colors.shadeDark};
  letter-spacing: -0.36px;
  text-align: center;
  line-height: 24px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 300px;
`;

class AddMember extends React.Component {
  state = {
    sent: false,
    submitEnabled: false,
    selectedRole: undefined,
    selectedRoleValue: undefined,
    selectedRelationship: 2,
    boxes: [],
  }

  componentDidMount = () => {
    this.setInitialBoxes();
    if (!this.props.isPremium) this.props.history.replace('/family');
  }

  setInitialBoxes = () => {
    const boxes = [
      { label: this.props.t('families:PRIMARY_CAREGIVER'), value: 2, selected: false },
      { label: this.props.t('families:CAREGIVER'), value: 3, selected: false },
    ];

    /* If user is a Primary Caregiver (PC), he can just add others caregivers */
    if (this.props.myRole === 'PRIMARY_CAREGIVER') {
      /* Remove the PC option and select it */
      boxes.shift();
      this.setRolePermissions([], 3);
      this.setState({ selectedRoleValue: 3 });
    }
    this.setState({ boxes });
  }

  setRolePermissions = (list, value) => {
    this.setState({ selectedRole: value });
  }

  roles = toPairs(UserRoles).map(([value, label]) => ({ label: this.props.t(`common:${label}`), value }));

  toggleSubmit = val => this.setState({ ...this.state, submitEnabled: val });

  sendInvite = ({ email, relationship, role }) => {
    const relation = Number(relationship);
    this.props.addMember({ email, relationship: relation, role });
    EventReporter.action(Events.MENU_ADD_MEMBER({ source: 'invitation' }));
    EventReporter.action(Events.MENU_FAMILY_INVITE({
      role: userRelationshipsForEvents[relation],
      permissions: memberRolesForEvents[role],
    }));
    this.setState({ sent: true });
  }

  openInviteModal = () => {
    this.props.openModal(ModalNames.INVITE_SENT);
  }

  handleModalSubmit = () => {
    this.form.reset({ email: '', relationship: 2, role: null });
    this.setState({
      sent: false,
      selectedRole: undefined,
      selectedRelationship: 2,
      selectedRoleValue: undefined,
    });
    this.setInitialBoxes();
    this.textInput.focus();
    this.props.setInvitationSent(false);
  }

  handleGoBack = () => {
    this.props.history.goBack();
    this.props.setInvitationSent(false);
  }

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <Content>
          <Title>{t('ADD_A_MEMBER')}</Title>
          <SubTitle>{t('INVITE_SOMEONE')}</SubTitle>
          <Form
            onSubmit={val => this.sendInvite(val)}
            onValid={() => this.toggleSubmit(true)}
            onInvalid={() => this.toggleSubmit(false)}
            ref={(node) => { this.form = node; }}
          >
            <Description>{t('ENTER_EMAIL')}</Description>
            <TextInput
              name="email"
              type="email"
              theme={Neo}
              maxWidth="300px"
              placeholder={t('common:EMAIL_PLACEHOLDER')}
              validations="isEmail"
              validationError={t('validations:IS_EMAIL')}
              getRef={(node) => { this.textInput = node; }}
              required
            />
            <Description>{t('WHAT_ROLE')}</Description>
            <Select
              options={this.roles}
              containerStyle={css`max-width: 300px;`}
              name="relationship"
              value={this.state.selectedRelationship}
              required
              theme={NeoSelect}
              centered
            />
            <Description>{t('WOULD_YOU_LIKE')}</Description>
            <SegmentedBoxes
              name="role"
              theme={NeoBoxes}
              containerStyle={css`max-width: 300px;`}
              boxes={this.state.boxes}
              value={this.state.selectedRoleValue}
              onChange={(list, value) => this.setRolePermissions(list, value)}
              radio
              column
              required
            />
            {this.state.selectedRole
              && <PermissionsList list={PERMISSIONS[memberRoles[this.state.selectedRole]]} />
            }
            <Footer>
              <Button
                type="submit"
                maxWidth={300}
                color={(!this.state.submitEnabled && !this.state.sent) ? 'disabled' : 'green'}
                disabled={!this.state.submitEnabled && !this.state.sent}
              >
                {t('SEND_INVITE')}
              </Button>
              <Button
                type="button"
                maxWidth={300}
                color="clear"
                onClick={this.handleGoBack}
              >
                {t('common:GO_BACK')}
              </Button>
            </Footer>
          </Form>
          {/* TODO: Buscar la manera de que las modales regresen callbacks para evitar renderearlas
            directamente desde el componente padre. */}
          {(this.state.sent && this.props.invitationSent)
            && (
            <InviteSentModal
              onSubmit={this.handleModalSubmit}
              onGoBack={this.handleGoBack}
            />
            )}
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ...state.families.UI.addMember,
  myRole: Selectors.myRole(state),
  isPremium: !!Families.Selectors.activeFamilyIsPremium(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addMember: Creators.addMemberRequested,
  setInvitationSent: Creators.setInvitationSent,
  openModal: ModalCreators.openModal,
}, dispatch);

AddMember.propTypes = {
  myRole: PropTypes.string,
  invitationSent: PropTypes.bool,
  setInvitationSent: PropTypes.func,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  addMember: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  t: PropTypes.func,
};

AddMember.defaultProps = {
  myRole: PropTypes.string,
  invitationSent: false,
  setInvitationSent: () => {},
  history: {},
  openModal: () => { },
  error: {},
  t: () => { },
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation([Namespaces.MENU, Namespaces.COMMON, Namespaces.VALIDATIONS, Namespaces.FAMILIES]),
)(AddMember);
