import { css } from 'styled-components';

const BodyModal = {
  DescriptionWrapper: css`
    border: 1px solid #ACC2D3;
    margin-bottom: 20px;
    border-radius: 8px;
    padding: 10px 20px;
    
    `,
  DescriptionHeader: css` `,
  DescriptionContent: css` `,
  DescriptionTitle: css`
    color: #404040;
    font-size: 14px;
    `,
  Footer: css` `,
  DescriptionEvent: css` `,
  DateDescription: css` `,
  DateWrapper: css`
    border: 1px solid #ACC2D3;
    `,
  DateContainer: css` `,
  HourWrapper: css`
    border: 1px solid #ACC2D3;
    `,
  SelectedUsers: css`
    border: 1px solid #ACC2D3;
    `,
  TitleDescription: css`
    color: #404040;
    border-bottom: 1px solid #ACC2D3;
    `,
  TotalParticipes: css`
    font-size: 14px;
    color: #8295A3;
    `,
  ListWrapper: css`
    /* FIREFOX */
    scrollbar-color: #ACC2D3 #ed005e1a;
    scrollbar-width: thin;
    /* CHROME */
    &::-webkit-scrollbar {
        width: 7px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: #fff;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #ACC2D3;
        border-radius: 10px;
    }
    `,
  TagSeparator: css` `,
  Content: css`
    font-size: 16px;
    font-weight: normal;
    color: #657480;
    `,
};

export default BodyModal;
