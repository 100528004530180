import React, { Fragment } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import SkillScienceView from '../views/SkillScienceView';
import { colors } from '../../styles';
import NeoModal from './NeoModal';

const Modal = styled(NeoModal)`
    display: flex;
`;
/*
  Dispatch example:

  {
    type: 'MODAL_OPENED',
    name: 'SkillScienceModal',
    data: {
    },
  }

*/

const SkillScienceModal = ({ baby, children }) => (
  <Modal width={960} closeButtonColor={colors.shadeMediumLight} showCloseButton>
    {({ closeModal }) => (
      <Fragment>
        <SkillScienceView baby={baby} closeModal={closeModal}>
          {children}
        </SkillScienceView>
      </Fragment>
    )}
  </Modal>
);

SkillScienceModal.propTypes = {
  baby: PropTypes.object,
  children: PropTypes.func,
};

SkillScienceModal.defaultProps = {
  baby: {},
  children: () => { },
};


export default SkillScienceModal;
