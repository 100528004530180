import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { RendererIf } from 'lego-dist';
import { randomId } from 'lib/utils';
import OvalSelectorWrapper, {
  OvalSelectorContent,
  OvalSelectorInput,
  OvalSelectorLabel,
  OvalSelectorContentOptions,
  OvalSelectorOption,
} from './styles';

const keysDefault = {
  id: 'id',
  label: 'label',
};

const OvalSelectorComponent = ({
  className,
  label,
  name,
  required,
  value,
  data,
  keys,
  columns,
  mobileColumns,
  onChange,
}) => {
  const [selected, setSelected] = useState(value || undefined);

  const handleOnChanged = (valueSelected) => {
    setSelected(valueSelected);
    if (onChange) {
      onChange(valueSelected);
    }
  };

  const items = useMemo(() => {
    if (data?.length) {
      return data.map(item => ({
        ...item,
        id: item[keys?.id || 'id'],
        label: item[keys?.label || 'label'],
      }));
    }
    return [];
  }, [data, keys]);

  useMemo(() => {
    if (value?.id && items?.length) {
      setSelected(items.find(item => item.id === value.id));
    }
  }, [value, items]);

  return (
    <OvalSelectorWrapper lassName={className}>
      <OvalSelectorInput
        readOnly
        name={name || `OvalSelectorInput_${randomId()}`}
        required={required}
        value={selected || value || ''}
      />
      <OvalSelectorContent>
        <RendererIf condition={label}>
          <OvalSelectorLabel>{label}</OvalSelectorLabel>
        </RendererIf>
        <OvalSelectorContentOptions
          columns={columns}
          mobileColumns={mobileColumns}
        >
          <RendererIf condition={items?.length}>
            {items.map(itemValue => (
              <OvalSelectorOption
                key={`OvalSelectorOption-${items?.id || randomId()}`}
                selected={selected?.id === itemValue?.id}
                onClick={() => handleOnChanged(itemValue)}
              >
                {itemValue.label}
              </OvalSelectorOption>
            ))}
          </RendererIf>
        </OvalSelectorContentOptions>
      </OvalSelectorContent>
    </OvalSelectorWrapper>
  );
};

OvalSelectorComponent.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  data: PropTypes.arrayOf(PropTypes.object),
  keys: PropTypes.object,
  columns: PropTypes.string,
  mobileColumns: PropTypes.string,
  onChange: PropTypes.func,
};

OvalSelectorComponent.defaultProps = {
  className: undefined,
  label: undefined,
  name: undefined,
  required: false,
  value: '',
  data: [],
  keys: keysDefault,
  columns: '',
  mobileColumns: '',
  onChange: () => {},
};

export default OvalSelectorComponent;
