import styled from 'styled-components';

const PaywallWrapper = styled.div.attrs({
  className: 'PaywallWrapper',
})`
  display: block;
  width: 100%;
  margin: 0 auto;
`;

const PaywallContent = styled.div.attrs({
  className: 'PaywallContent',
})`
  display: block;
  width: 100%;
  max-width: 1070px;
  margin: 0 auto;

  ${({ theme }) => theme.breakPoints.desktop} {}
`;

const PaywallContentImage = styled.div.attrs({
  className: 'PaywallContentImage',
})`
  display: block;
  width: 100%;
  margin: 0 auto;
`;

const PaywallContentBtns = styled.div.attrs({
  className: 'PaywallContentBtns',
})`
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  .btn {
    padding: 11px 20px;
    display: block;
    color: #FFF;
    font-family: 'Proxima Nova';
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.356px;
    margin: 0 auto;
    min-height: auto;

    &.btn-secondary {
      color: #404040;
      margin-top: 20px;
      padding-top: 0;
      padding-bottom: 0;
      font-weight: 500;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  ${({ theme }) => theme.breakPoints.largeTablet} {
    .btn {
      font-size: 18px;
      font-weight: 700;
      padding: 13px 70px;

      &.btn-secondary {
        font-size: 16px;
      }
    }
  }
`;

const InfoPrice = styled.p.attrs({
  className: 'InfoPrice',
})`

  margin: 30px auto 0 auto;
  color: #657480;
  text-align: center;
  font-family: 'Proxima Nova';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  ${({ theme }) => theme.breakPoints.desktop} {
    font-size: 12px;
  }
`;

const PaywallContentLinks = styled.div.attrs({
  className: 'PaywallContentLinks',
})`
  display: block;
  width: 100%;
  margin: 15px auto;
  text-align: center;
`;

const PaywallLink = styled.a.attrs({
  className: 'PaywallLink',
})`
  display: inline-block;
  margin: 0 15px;

  color: #657480;
  text-align: center;
  font-family: 'Proxima Nova';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.429px;

  ${({ theme }) => theme.breakPoints.desktop} {
    font-size: 14px;
    margin: 0 38px;
  }
`;


const PaywallImage = styled.img.attrs(({ className }) => ({
  className: `PaywallImage ${className}`,
}))`
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;

  &.desktop {
    display: none;
  }

  ${({ theme }) => theme.breakPoints.largeTablet} {
    &.mobile {
      display: none;
    }
    &.desktop {
      display: block;
    }
  }
`;


export default PaywallWrapper;
export { PaywallContent, PaywallContentImage, PaywallContentBtns, PaywallContentLinks, PaywallLink, PaywallImage, InfoPrice };
