import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { DEEP } from '../redux-sauce';
import { Locations } from '../../values/messages';

export const { Creators, Types } = createActions({
  fetchPendingMessagesRequested: ['location'],
  fetchPendingMessagesSuccess: { location: null, messages: null },
  fetchPendingMessagesError: ['error'],
  // TODO: Rename to more describing names.
  setActiveMessageQueue: ['location'],
  popMessageQueue: ['location'],
});

export const InitialState = Immutable({
  [Locations.OPEN_APP]: {},
  [Locations.DAP]: {},
});

export const Selectors = {
  messagesPendingForLocation: (state, location) => !state.messages.visitedLocations[location],
};

export const Reducer = createReducer(InitialState, {
  [Types.FETCH_PENDING_MESSAGES_SUCCESS]: (state, { location, messages = [] }) =>
    state.merge({
      [location]: {
        messages: [...(state[location].messages || []), ...messages],
      } }, DEEP),
  [Types.POP_MESSAGE_QUEUE]: (state, { location }) => {
    const { messages } = Immutable.asMutable(state[location], DEEP);
    if (messages.length < 0) {
      return state;
    }

    return state.merge({
      [location]: { messages: messages.splice(1) } }, DEEP);
  },
});
