import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import NeoModal from './NeoModal';
import MasteredSkillView from '../views/MasteredSkillView';
import OnTrack from '../views/OnTrackView';
import KeepOn from '../views/KeepOnView';
import { breakPoints } from '../../styles';

const Modal = styled(NeoModal) `
  display: flex;
  flex-direction: column;

  max-width: 460px;
  height: 100%;
  margin: auto;

  ${breakPoints.smallTablet} {
    height: 650px;
  }
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'DopamineSkillModal',
  data: {
    dsType: 'keep_on',
    skillName: 'Crawling',
    baby: {
      name: 'Johnny',
    },
    completedMilestones: 2,
    activeMilestones: 12,
  },
}

*/

const DpSkillView = ({ dsType, ...rest }) => {
  switch (dsType) {
    case 'master':
      return (
        <MasteredSkillView {...rest} />);
    case 'on_track':
      return (
        <OnTrack {...rest} />);
    case 'keep_on':
      return (
        <KeepOn {...rest} />);
    default: return '';
  }
};

DpSkillView.propTypes = {
  skill: PropTypes.object,
};

DpSkillView.defaultProps = {
  skill: {},
};

const DopamineSkillModal = ({ dsType, baby = {}, activeMilestones, completedMilestones, skillName }) => (
  <Modal width={460} showCloseButton={false} roundedCorners>
    {({ closeModal }) => (
      <DpSkillView
        dsType={dsType}
        activeMilestones={activeMilestones}
        completedMilestones={completedMilestones}
        closeModal={closeModal}
        skillName={skillName}
        baby={baby}
      />
    )}
  </Modal>
);

DopamineSkillModal.propTypes = {
  activeMilestones: PropTypes.number,
  completedMilestones: PropTypes.number,
};
DopamineSkillModal.defaultProps = {
  activeMilestones: 0,
  completedMilestones: 0,
};

export default DopamineSkillModal;
