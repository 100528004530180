import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import PremiumPlanButton from '../../shared/PremiumPlanButton';
import Button from '../../shared/buttons/MainButton';

const Form = styled.form`
  flex: 1 1 0;
  display: flex;
  height: 527px;

  align-items: center;
  padding: 20px 15px 18px;
  box-sizing: border-box;

  flex-direction: column;
  @media screen and (min-width: 900px) {
    padding: 70px 60px 55px;
  }
  button {
    font-size: 16px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: auto;
  .next-btn {
    margin-left: 8px;
  }
`;

const Input = styled.input`
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;
  padding: 0 10px;
  height: 50px;
  font-family: inherit;
  background-color: #F0F0F0;
  border: 1px solid ${({ hasError }) => (hasError ? 'red' : ' #ACC2D3')};
  border-radius: 5px;
  font-size: 16px;

  margin-bottom: 10px;
  @media screen and (min-width: 900px) {
    margin-bottom: 20px;
  }
  ::placeholder {
    color: #8296A1;
    font-style: italic;
    text-transform: lowercase;
  }

  :focus {
    outline: none !important;
    border-color: ${({ hasError }) => (hasError ? 'red' : ' #00A0E5')};
  }
`;

const InputError = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: red;
  margin-top: 5px;
`;

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-basis: 50%;
  max-width: 380px;
  flex-direction: column;
  margin-top: 10px;
  @media screen and (min-width: 900px) {
    margin-top: 0px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ToLabel = styled.div`
  color: #404040;
  font-size: 20px;
  text-align: left;
  margin-bottom: 20px;
  letter-spacing: 5.56px;
  font-weight: 600;
`;

const MailInputWrapper = styled.div`
  margin-bottom: 20px;

  .email {
    margin-bottom: 0;
  }
`;

const ForSomeoneElseFromView = ({
  onSubmit, onPrevious, t, lang, recipientData,
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const { handleSubmit, register } = useForm();

  function emailParser(string) {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(string)) {
      setErrors({ ...errors, fromEmail: '' });
      return true;
    }
    setErrors({ ...errors, fromEmail: t('validations:IS_EMAIL') });
    return false;
  }

  const selectedGiftCardKey = recipientData?.sku;

  return (
    <Form onSubmit={handleSubmit(values => onSubmit(values))}>
      <Content>
        <PremiumPlanButton
          giftcardSKU={selectedGiftCardKey}
          lang={lang}
        />
        <FormWrapper>
          <ToLabel>{`${t('common:FROM')}:`}</ToLabel>
          <Input
            ref={register({ required: true })}
            name="fromName"
            placeholder={t('common:PLACEHOLDERS.NAME')}
            onChange={e => setName(e.target.value)}
            value={name}
          />
          <MailInputWrapper>
            <Input
              ref={register({ required: true })}
              name="fromEmail"
              className="email"
              type="email"
              placeholder={t('common:PLACEHOLDERS.EMAIL')}
              onChange={(e) => { emailParser(e.target.value); setEmail(e.target.value); }}
              hasError={errors.fromEmail && errors.fromEmail !== ''}
              value={email}
            />
            {errors.fromEmail && (
              <InputError>{`* ${errors.fromEmail}`}</InputError>
            )}
          </MailInputWrapper>
        </FormWrapper>
      </Content>
      <ButtonsContainer>
        <Button
          onClick={() => onPrevious()}
          color="outlinedGray"
          maxWidth={300}
          type="button"
        >
          {t('common:GO_BACK')}
        </Button>

        <Button
          type="submit"
          className="next-btn"
          maxWidth={300}
          color={(name === '' || email === '' || errors.fromEmail) ? 'disabled' : 'green'}
          disabled={name === '' || email === '' || errors.fromEmail}
        >
          {t('common:NEXT')}
        </Button>
      </ButtonsContainer>
    </Form>
  );
};
export default ForSomeoneElseFromView;
