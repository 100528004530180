// eslint-disable-next-line
export const VALIDATIONS_MESSAGES = {
  IS_REQUIRED: 'IS_REQUIRED',
  EMAIL_INVALID: 'EMAIL_INVALID',
  IS_EMAIL: 'IS_EMAIL',
  IS_NUMERIC: 'IS_NUMERIC',
  VALID_DATE: 'VALID_DATE',
  VALID_WEEKS: 'VALID_WEEKS',
  NO_FUTURE_DATES: 'NO_FUTURE_DATES',
  NO_RESULTS: 'NO_RESULTS',
  PASS_DONT_MATCH: 'PASS_DONT_MATCH',
};
