import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import Formsy from 'formsy-react-2';
import { RadioGroup, Radio } from 'react-radio-group';

import { RadioChecks, ColoredOptions, ThemeShape, Kinedu5 } from './../themes/SegmentedButtons';
import Icon from './../shared/Icon';

import { colors } from '../../styles';

const Container = styled.div`
  ${({ theme }) => theme.container};
  label {
    ${({ theme }) => theme.option};

    input {
      ${({ theme }) => theme.input};
    }
  }
  .radio-group {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Option = styled.label`
  position: relative;
  background-image: url(${props => props.image});
  border: 1px solid ${props => props.color || colors.dustyGray};

  .label-primary, .sub-component {
    color: ${props => props.color};
  }

  &.selected {
    background-color: ${props => props.color || 'transparent'};
  }
`;

const Label = styled.p`
  color: ${props => props.labelColor};
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3.7px;

  margin: 0;
  margin-bottom: 10px;

  ${({ theme }) => theme.label};
`;

const SubComponent = styled.div`
  position: absolute;
  right: 20px;
  height: calc(100% - 20px);
  display: flex;
  align-items: center;
`;

class SegmentedButtons extends React.Component {
  state = { selectedItem: null };

  componentWillMount = () => {
    /* If there is a default value, is automatically selected */
    if (this.props.defaultValue) {
      this.props.setValue(this.props.defaultValue);
    }
    this.setState({ selectedItem: this.props.defaultValue });
  };

  render() {
    const {
      theme,
      label,
      labelColor,
      name,
      options,
      onChange,
      setValue,
      style,
      className = '',
    } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Container style={style}>
          <Label labelColor={labelColor}>{label}</Label>
          <RadioGroup
            name={name}
            selectedValue={this.state.selectedItem}
            className={`radio-group ${className}`}
            onChange={(e) => {
              this.setState({ selectedItem: e }, () => {
                setValue(e);
                if (onChange) onChange(e);
              });
            }}
          >
            {options &&
              options.map(({ label, sublabel, value, color, image, subComponent }, i) => (
                <Option key={i} className={this.state.selectedItem === value ? 'selected' : ''} image={image} color={color}>
                  <Radio value={value} />
                  {/* TODO: change this to be independent of themes */}
                  {theme === RadioChecks && <Icon icon={this.state.selectedItem === value ? 'check-circle-full' : 'check-circle-o'} size={30} color={colors.cognitive} />}
                  <span className="label label-primary">{label}</span>
                  {theme === ColoredOptions && <span className="label label-secondary">{sublabel}</span>}
                  <SubComponent className="sub-component">{subComponent}</SubComponent>
                </Option>
              ))
            }
          </RadioGroup>
        </Container>
      </ThemeProvider>
    );
  }
}


SegmentedButtons.propTypes = {
  /* Optional label above component */
  label: PropTypes.string,
  /* Optional label color */
  labelColor: PropTypes.string,
  /* Name required by formsy */
  name: PropTypes.string,
  /* Default value */
  defaultValue: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]),
  /* List of options to display */
  options: PropTypes.arrayOf(PropTypes.shape({
    /* Main label */
    label: PropTypes.string,
    /* Secondary label */
    sublabel: PropTypes.string,
    /* Actual value that will be saved in the component */
    value: PropTypes.any,
    /* Option color (optional) */
    color: PropTypes.string,
    /* Option background image (optional) */
    image: PropTypes.string,
  })),
  /* On change event */
  onChange: PropTypes.func,
  /* Programmatically set a value */
  setValue: PropTypes.func,
  /* To pass classNames to components */
  className: PropTypes.string,
  /* To pass inline styles */
  style: PropTypes.object,
  /* Component's theme */
  theme: ThemeShape,
};

SegmentedButtons.defaultProps = {
  label: '',
  labelColor: '',
  name: '',
  defaultValue: '',
  options: [],
  onChange: () => {},
  setValue: () => {},
  className: '',
  style: {},
  theme: Kinedu5,
  t: PropTypes.object,
};

export default Formsy.HOC(SegmentedButtons);
