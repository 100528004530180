/* Settings */
export const Langs = {
  EN: 'en',
  ES: 'es',
};

export const DEFAULT_LANG = Langs.EN;

export const Settings = {
  AUTOPLAY_ENABLED: 'autoplayEnabled',
  TUTORIALS_ENABLED: 'tutorialsEnabled',
  LANG: 'lang',
};
