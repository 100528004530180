/* eslint-disable import/no-cycle */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { parse } from 'query-string';
import { Button, RendererIf, Spinner } from 'lego-dist';
import EventReporter, { Events } from 'lib/EventReporter';
import useLeanplum, { LOAD_OPTIONS } from 'hooks/leanplum/useLeanplum';
import usePrices from 'hooks/usePrices';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useUser from 'hooks/useUser';
import { sizeInt } from 'themes/breakPoints';
import PremiumProcessWrapper, {
  PremiumProcessContent,
  PremiumProcessContentImage,
  PremiumProcessContentInfo,
  PremiumProcessImageWrapper,
  PremiumProcessImage,
  PremiumProcessIconClose,
  PremiumProcessContentPlanCards,
  PremiumProcessContentActions,
  PremiumProcessContentLinks,
  PremiumProcessLink,
} from './styles';
import PlanCardComponent from './planCard';
import { DATA_FOR_PP_PAYMENT_PROVIDER_PP_KEY, PAYMENT_CARD_THEMES, PAYMENT_PATH_PP_KEY, QUERY_PARAM_PP_RESPONSE, QUERY_PARAM_PP_RESPONSE_VALUES, QUERY_PARAM_TO_SHOW_MODAL } from '../values';
import { getSkuDataByFormat } from 'helpers/skus';
import { createSesionSubscription } from 'helpers/stripe-helper';

let timeoutRetriCreateSession;

const PremiumProcessComponent = ({ onClose }) => {
  const { t: translation } = useTranslation();
  const { width } = useWindowDimensions();
  const { user } = useUser();
  const { prices, isSearched: isSearchedPrices, fetch } = usePrices();
  const {
    PPData, PPDataAlreadyVerified,
  } = useLeanplum({
    useForce: true,
    load: LOAD_OPTIONS.PP,
  });

  const [skus, setSkus] = useState([]);
  const [skuSelected, setSkusSelected] = useState();
  const [isSubmit, setIsSubmit] = useState(false);

  const validateVarLeanplum = val => !!val && !/^null$/i.test(val);

  const handleOnClickClose = () => {
    if (onClose) onClose();
  };

  const handleOnSkuSelected = (value) => {
    if(!isSubmit && skus.find((item) => item.complete)) {
      setSkusSelected(value);
    }
  };

  const handleSubscription = async () => {
    setIsSubmit(true);
    const plan = 'play_learn';
    const source = 'ppPayLearn';
    const path = window.localStorage.getItem(PAYMENT_PATH_PP_KEY) || '';
    const skuInfo = getSkuDataByFormat(skuSelected?.price?.nickname);

    const dataEvent = {
      payment_source: source,
      payment_path: path,
      type: skuInfo.path,
      subscription_type: skuInfo.recurrence,
      sku: skuSelected?.price.nickname,
    };

    EventReporter.action(Events.PP_PAYMENT_STARTED(dataEvent));

    window.localStorage.setItem(DATA_FOR_PP_PAYMENT_PROVIDER_PP_KEY, JSON.stringify({
      ...dataEvent,
      response: 'success',
      price: skuSelected?.price.amount_usd || skuSelected?.price.base_price_text,
    }));

    const queryParams = parse(window.location.search);
    queryParams[QUERY_PARAM_PP_RESPONSE] = QUERY_PARAM_PP_RESPONSE_VALUES.CANCELED;


    const cancelUrl = `${window.location.origin}${window.location.pathname}?${ new URLSearchParams(queryParams).toString()}`;
    delete queryParams[QUERY_PARAM_TO_SHOW_MODAL];
    queryParams.upgrade = plan;
    queryParams[QUERY_PARAM_PP_RESPONSE] = QUERY_PARAM_PP_RESPONSE_VALUES.SUCCESS;

    const successUrl = `${window.location.origin}${window.location.pathname}?${ new URLSearchParams(queryParams).toString()}`;

    const payload = {
      user,
      plan,
      priceId: skuSelected?.price?.id,
      source: 'Paywall',
      routes: {
        successUrl,
        cancelUrl,
      },
    };

    const stripeSession = await createSesionSubscription(payload);
    if(stripeSession?.error) {
      timeoutRetriCreateSession = setTimeout(() => {
        handleSubscription();
      }, 3000);
    } else {
      window.location.href = stripeSession?.url;
    }
  };

  useMemo(() => {
    const currentSkus = [];

    if (PPDataAlreadyVerified) {
      if (validateVarLeanplum(PPData.webSkuPremiumFirst)) {
        currentSkus.push({
          id: 'first',
          sku: PPData.webSkuPremiumFirst,
          discount: validateVarLeanplum(PPData.webDiscountPremiumFirst)
            ? PPData.webDiscountPremiumFirst
            : '',
          monthlyPrice: parseInt(PPData.webMonthlyPricePremiumFirst),
          title: PPData.webTitlePremiumFirst,
          order: 1,
          complete: false,
        });
      }
      if (validateVarLeanplum(PPData.webSkuPremiumSecond)) {
        currentSkus.push({
          id: 'second',
          sku: PPData.webSkuPremiumSecond,
          discount: validateVarLeanplum(PPData.webDiscountPremiumSecond)
            ? PPData.webDiscountPremiumSecond
            : '',
          monthlyPrice: parseInt(PPData.webMonthlyPricePremiumSecond),
          title: PPData.webTitlePremiumSecond,
          order: 2,
          complete: false,
        });
      }
      if (validateVarLeanplum(PPData.webSkuPremiumThird)) {
        currentSkus.push({
          id: 'third',
          sku: PPData.webSkuPremiumThird,
          discount: validateVarLeanplum(PPData.webDiscountPremiumThird)
            ? PPData.webDiscountPremiumThird
            : '',
          monthlyPrice: parseInt(PPData.webMonthlyPricePremiumThird),
          title: PPData.webTitlePremiumThird,
          order: 3,
          complete: false,
        });
      }

      setSkus(currentSkus);

      if (currentSkus.length) {
        fetch(currentSkus.map(item => item.sku));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PPData, PPDataAlreadyVerified]);

  useMemo(() => {
    if (isSearchedPrices && prices) {
      const currentSkus = [...skus].map(item => ({
        ...item,
        complete: true,
        price: prices.find(price => item.sku === price.nickname),
      })).filter((item) => item?.price?.id) || [];

      if(currentSkus.length) {
        setSkus(currentSkus);
        setSkusSelected(currentSkus[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices, isSearchedPrices]);


  const themePlanCard = useMemo(() => {
    let theme = PAYMENT_CARD_THEMES.THREE;
    switch (skus?.length) {
      case 1:
        theme = PAYMENT_CARD_THEMES.ONE;
        break;
      case 2:
        theme = PAYMENT_CARD_THEMES.TWO;
        break;

      default: {
        theme = width > sizeInt.largeTablet ? PAYMENT_CARD_THEMES.THREE_DESKTOP : PAYMENT_CARD_THEMES.THREE;
        break;
      }
    }

    return theme;
  }, [skus, width]);

  useEffect(() => {
    EventReporter.view(Events.S_PREMIUM_PROCESS());


    return () => {
      if(timeoutRetriCreateSession) {
        clearTimeout(timeoutRetriCreateSession);
      }
    }

    /* eslint-disable */
  }, []);

  return (
    <RendererIf
      condition={PPDataAlreadyVerified}
      alternative={(
        <Spinner
          loading
          name="threedots"
          position="relative"
          bgColor="transparent"
        />
      )}
    >
      <PremiumProcessWrapper>
        <PremiumProcessIconClose onClick={handleOnClickClose} />
        <PremiumProcessContent>
          <PremiumProcessContentImage>
            <PremiumProcessImageWrapper>
              <PremiumProcessImage
                image={PPData?.webImagePP}
                imageMobile={PPData?.webImagePPResp}
              />
            </PremiumProcessImageWrapper>
          </PremiumProcessContentImage>
          <PremiumProcessContentInfo>
            <PremiumProcessContentPlanCards>
              {skus?.map(skuItem => (
                <PlanCardComponent
                  key={`PlanCardComponent-${skuItem.id}`}
                  isSelected={skuItem.id === skuSelected?.id}
                  data={skuItem}
                  onSelected={handleOnSkuSelected}
                  theme={themePlanCard}
                />
              ))}
            </PremiumProcessContentPlanCards>
            <PremiumProcessContentActions>
              <Button
                className="btn btn-primary pp_button_primary"
                color="#3EB646"
                rounded
                flat
                fill
                small
                outline
                type="button"
                disabled={!skuSelected?.price?.id}
                loading={isSubmit}
                onClick={handleSubscription}
              >
                {PPData?.webPPCopyButton}
              </Button>
              <PremiumProcessContentLinks>
                <PremiumProcessLink>
                  {translation('paywall:RECURRING_BILLING.TEXT')}
                </PremiumProcessLink>
                <PremiumProcessLink
                  target="_blank"
                  href={translation('paywall:TERMS_CONDITIONS.URL')}
                >
                  {translation('paywall:TERMS_CONDITIONS.TEXT')}
                </PremiumProcessLink>
                <PremiumProcessLink
                  target="_blank"
                  href={translation('paywall:PRIVACY_POLICY.URL')}
                >
                  {translation('paywall:PRIVACY_POLICY.TEXT')}
                </PremiumProcessLink>
              </PremiumProcessContentLinks>
            </PremiumProcessContentActions>
          </PremiumProcessContentInfo>
        </PremiumProcessContent>
      </PremiumProcessWrapper>
    </RendererIf>
  );
};

PremiumProcessComponent.propTypes = {
  onClose: PropTypes.func,
};
PremiumProcessComponent.defaultProps = {
  onClose: () => {},
};

export default PremiumProcessComponent;
