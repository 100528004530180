import Immutable from 'seamless-immutable';
import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = Immutable({
  validCard: false,
  loading: false,
  success: false,
  error: undefined,
});

export const { Creators, Types } = createActions({
  validateCardRequested: {
    cvc: undefined,
    number: undefined,
    expDate: { month: undefined, year: undefined },
  },
  validateCardSuccess: ['valid'],
  postPaymentRequested: {
    /** @param{product|giftcard} */
    productType: 'product',
    sku: undefined,
    paymentSource: undefined,
    card: {
      name: undefined,
      cvc: undefined,
      number: undefined,
      expDate: undefined,
    },
    /** Marketing campaign tracking */
    testType: undefined,
    /** Subscription params */
    email: undefined,
    familyId: undefined,
    /** Gift card params */
    toEmail: undefined,
    toName: undefined,
    fromEmail: undefined,
    fromName: undefined,
    message: undefined,
    forSelf: false,
    code: undefined,
    source: undefined,
    subscriptionType: undefined,
    freeTrialDays: undefined,
    price: undefined,
  },
  postPaymentSuccess: ['productType'],
  postPaymentError: ['err'],
  postStripePaymentRequested: {
    /** @param{product|giftcard} */
    productType: 'product',
    sku: undefined,
    paymentSource: undefined,
    card: {
      name: undefined,
      cvc: undefined,
      number: undefined,
      expDate: undefined,
    },
    /** Marketing campaign tracking */
    testType: undefined,
    /** Subscription params */
    email: undefined,
    familyId: undefined,
    /** Gift card params */
    toEmail: undefined,
    toName: undefined,
    fromEmail: undefined,
    fromName: undefined,
    message: undefined,
    forSelf: false,
    code: undefined,
    source: undefined,
    subscriptionType: undefined,
    freeTrialDays: undefined,
    price: undefined,
  },
  postStripePaymentSuccess: ['productType'],
  postStripePaymentError: ['err'],
  startGiftcardFlow: undefined,

  redeemGiftcardRequested: { familyId: undefined, code: undefined },
  redeemGiftcardSuccess: undefined,
  redeemGiftcardError: ['err'],

  redeemPromoCodeRequested: ['code'],
  redeemPromoCodeSuccess: ['sku'],
  redeemPromoCodeError: ['error'],
  redeemPromoCodeReset: undefined,

  cancelPremium: undefined,
  manageSubscriptions: ['userId'],
  manageSubscriptionsRedirected: ['url'],
});

export const Reducer = createReducer(INITIAL_STATE, {
  [Types.VALIDATE_CARD_SUCCESS]: (state, { valid }) => state.merge({ valid }),
  [Types.POST_PAYMENT_REQUESTED]: state => state.merge({ loading: true, success: false }),
  [Types.POST_PAYMENT_SUCCESS]: state => state.merge({ loading: false, success: true }),
  [Types.START_GIFTCARD_FLOW]: state => state.merge({ loading: false, success: false }),
  [Types.POST_PAYMENT_ERROR]: (state, { err }) =>
    state.merge({ err, loading: false, success: false }),
  [Types.REDEEM_GIFTCARD_REQUESTED]: state => state.merge({ loading: true }),
  [Types.REDEEM_GIFTCARD_SUCCESS]: state => state.merge({ loading: false }),
  [Types.REDEEM_GIFTCARD_ERROR]: (state, { err }) => state.merge({ err, loading: false }),
  [Types.REDEEM_PROMO_CODE_REQUESTED]: state => state.merge({ error: '', loading: true }),
  [Types.REDEEM_PROMO_CODE_SUCCESS]: (state, { sku }) => state.merge({ sku, loading: false }),
  [Types.REDEEM_PROMO_CODE_ERROR]: (state, { error }) => state.merge({ error, loading: false }),
  [Types.REDEEM_PROMO_CODE_RESET]: state => state.merge({ sku: undefined, loading: false }),
  [Types.POST_STRIPE_PAYMENT_REQUESTED]: state => state.merge({ loading: true, success: false }),
  [Types.POST_STRIPE_PAYMENT_SUCCESS]: state => state.merge({ loading: false, success: true }),
  [Types.POST_STRIPE_PAYMENT_ERROR]: (state, { err }) => state.merge({ err, loading: false, success: false }),
});
