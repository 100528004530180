/* eslint-disable no-console */
import axios from 'axios';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { path, prop } from 'ramda';
import { LOCALSTORAGE_API_KEY, ModalNames } from '../shared-values';
import { API_URL, KINEDU_API_VERSION } from '../config';
// import { Auth } from '../data/ducks';
import { Modals } from '../data/ducks';
import { store } from '../App';

// const TOKEN_DEMO = '09d23abf0c1d10e37592819dd8157ee06f22c0d308a8906d21e25c0de4f838859e0d5c1337aca40103b028ec81e948c6be382fce7c82d6ad273ad4fcd16e8f58';


/**
 * Create a new axios instance
 */
const BaseApi = axios.create({
  baseURL: `${API_URL}/${KINEDU_API_VERSION}`,
  timeout: 30000,
});
BaseApi.defaults.params = {};

export const BaseApiV4 = axios.create({
  baseURL: `${API_URL}/v4`,
  timeout: 30000,
});
BaseApiV4.defaults.params = {};


export const BaseApiV3 = axios.create({
  baseURL: `${API_URL}/v3`,
  timeout: 30000,
});

export const BaseV3WithoutApi = axios.create({
  baseURL: `${API_URL.replace('/api', '')}/v3`,
  timeout: 30000,
});

export const ExperimentalApi = axios.create({
  baseURL: 'https://staging.kinedu.com/api/v4',
  timeout: 30000,
})

export const EmulatedApi = token => axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    'X-Kinedu-Web': 'v3',
    Authorization: `Token token=${token}`,
  },
});

BaseApi.interceptors.request.use((res) => {
  const token = localStorage.getItem(LOCALSTORAGE_API_KEY);
  res.headers.common.Authorization = `Token token=${token}`;
  res.headers.common['X-Kinedu-Web'] = 'v3';

  if (!res?.params?.locale) {
    res.params.locale = i18n?.language || 'es';
  }

  return res;
}, error => error);

BaseApi.interceptors.response.use(res => res, (error) => {
  const { config } = error || {};
  const { headers } = config || {};

  if (error.response) {
    const backError = path(['response', 'data', 'error_data'], error) || {};
    console.error(backError);
    switch (error.response.status) {
      // TODO: Find a difference between wrong data and invalid token
      // case 401: store.dispatch(Auth.Creators.logout());
      //   break;
      case 422: console.error(backError.code);
        break;
      case 500:

        if (headers?.isNotFatal) {
          console.error('backError', backError.code, error);
        } else {
          store.dispatch(Modals.Creators.openModal(ModalNames.FATAL_ERROR));
        }

        // toast.error(i18n.t('validations:RESPONSE_ERRORS.INTERNAL_SERVER'),
        //   { autoClose: 5000 });
        break;
      default:
        console.error(prop('code', backError));
    }
  }
  if (error.code === 'ECONNABORTED') {
    toast.error(i18n.t('validations:RESPONSE_ERRORS.TIMEOUT'), { autoClose: 5000 });
  }
  return Promise.reject(error);
});


BaseApiV4.interceptors.request.use((res) => {
  const token = localStorage.getItem(LOCALSTORAGE_API_KEY);
  res.headers.common.Authorization = `Token token=${token}`;
  res.headers.common['X-Kinedu-Web'] = 'v4';

  if (!res?.params?.locale) {
    res.params.locale = i18n?.language || 'es';
  }

  return res;
}, error => Promise.reject(error));

BaseApiV4.interceptors.response.use(res => res, (error) => {
  const { config } = error || {};
  const { headers } = config || {};

  if (error.response) {
    const backError = path(['response', 'data', 'error_data'], error) || {};
    console.error(backError);
    switch (error.response.status) {
      // TODO: Find a difference between wrong data and invalid token
      // case 401: store.dispatch(Auth.Creators.logout());
      //   break;
      case 422: console.error(backError.code);
        break;
      case 500:
        if (headers?.isNotFatal) {
          console.error('backError', backError.code, error);
        } else {
          store.dispatch(Modals.Creators.openModal(ModalNames.FATAL_ERROR));
        }
        // toast.error(i18n.t('validations:RESPONSE_ERRORS.INTERNAL_SERVER'),
        //   { autoClose: 5000 });
        break;
      default:
        console.error(prop('code', backError));
    }
  }
  if (error.code === 'ECONNABORTED') {
    toast.error(i18n.t('validations:RESPONSE_ERRORS.TIMEOUT'), { autoClose: 5000 });
  }
  return Promise.reject(error);
});

BaseApiV3.interceptors.request.use((res) => {
  const token = localStorage.getItem(LOCALSTORAGE_API_KEY);
  res.headers.common.Authorization = `Token token=${token}`;
  res.headers.common['X-Kinedu-Web'] = 'v4';

  return res;
}, error => error);

BaseApiV3.interceptors.response.use(res => res, (error) => {
  if (error.response) {
    const backError = path(['response', 'data', 'error_data'], error) || {};
    console.error(backError);
    switch (error.response.status) {
      // TODO: Find a difference between wrong data and invalid token
      // case 401: store.dispatch(Auth.Creators.logout());
      //   break;
      case 422: console.error(backError.code);
        break;
      case 500:
        store.dispatch(Modals.Creators.openModal(ModalNames.FATAL_ERROR));
        // toast.error(i18n.t('validations:RESPONSE_ERRORS.INTERNAL_SERVER'),
        //   { autoClose: 5000 });
        break;
      default:
        console.error(prop('code', backError));
    }
  }
  if (error.code === 'ECONNABORTED') {
    toast.error(i18n.t('validations:RESPONSE_ERRORS.TIMEOUT'), { autoClose: 5000 });
  }
  return Promise.reject(error);
});

BaseV3WithoutApi.interceptors.request.use((res) => {
  const token = localStorage.getItem(LOCALSTORAGE_API_KEY);
  res.headers.common.Authorization = `Token token=${token}`;
  res.headers.common['X-Kinedu-Web'] = 'v4';

  return res;
}, error => error);

BaseV3WithoutApi.interceptors.response.use(res => res, (error) => {
  if (error.response) {
    const backError = path(['response', 'data', 'error_data'], error) || {};
    console.error(backError);
    switch (error.response.status) {
      // TODO: Find a difference between wrong data and invalid token
      // case 401: store.dispatch(Auth.Creators.logout());
      //   break;
      case 422: console.error(backError.code);
        break;
      case 500:
        store.dispatch(Modals.Creators.openModal(ModalNames.FATAL_ERROR));
        // toast.error(i18n.t('validations:RESPONSE_ERRORS.INTERNAL_SERVER'),
        //   { autoClose: 5000 });
        break;
      default:
        console.error(prop('code', backError));
    }
  }
  if (error.code === 'ECONNABORTED') {
    toast.error(i18n.t('validations:RESPONSE_ERRORS.TIMEOUT'), { autoClose: 5000 });
  }
  return Promise.reject(error);
});

/**
 * Set authentication, saves it to localStorage.
 */
export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Token token=${token}`;
  }
};

export const setSession = (session) => {
  axios.defaults.headers.common['X-Kinedu-Session'] = session;
};

setAuthToken(localStorage.getItem(LOCALSTORAGE_API_KEY));

export const contextSource = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const updateSource = urlParams.get('context');

    return updateSource;
};
export const localeFlag = () => `locale=${i18n.language}`;
export const setParam = (name, value) => (value ? `&${name}=${value}` : '');
export { axios };
export default BaseApi;
