import styled from 'styled-components';
import NewKineduLogo from 'images/authView/new_kinedu_logo.png';
import NewKineduLogoColor from 'images/authView/new_kinedu_logo_color.png';
import { colors } from 'styles';

export const StyledHome = styled.div.attrs({ className: 'home' })`
  width: 100%;
  background-color: #f8f8f8;
`;

// const FakeLink = styled.span`
//   cursor: pointer;
// `;
export const LeftSide = styled.div.attrs({ className: 'left-side' })`
  display: none;
  background-color: #0086D8;

  ${({ theme }) => theme.breakPoints.desktop} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    z-index: 0;
  }
`;

export const RightSide = styled.div.attrs({ className: 'right-side' })`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  background-color: #FFF;

  ${({ theme }) => theme.breakPoints.desktop} {
    width: 50%;
    left: auto;
  }
`;

export const Container = styled.div.attrs({ className: 'container' })`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 70px 1fr;
  height: 100%;

  ${({ theme }) => theme.breakPoints.desktop} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Navbar = styled.div`
  grid-column: 1 / span2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 1;
`;

export const Logo = styled.div`
  width: 100px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${NewKineduLogoColor});

  ${({ theme }) => theme.breakPoints.desktop} {
    width: 160px;
    height: 32px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${NewKineduLogo});
  }
`;

export const LangContainer = styled.div`
  display: flex;
`;

export const WrapperSelecter = styled.div.attrs(() => ({
  className: 'WrapperSelecter',
}))`
  display: block;
  position: relative;
  margin-top: 5px;

  .legoSelecterContainer  {
    border-radius: 4px;
    min-height: 50px;

    .legoSelecterOptionIndicator {
      top: 15px;
    }

    .legoSelecterOptionItem  {
      .legoSelecterItemLabel {
        &.legoSelecterItemLabel-selected {
          background-color: #F0F0F0;
          color: ${colors.shadeDarker};
        }
      }

    }
  }



  .legoSelecterOptionsContainer {
    max-height: 200px;
    overflow-y: auto;
    padding: 0;
    left: -1px;
    border-radius: 4px;
  }
`;