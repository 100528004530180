import React, { useReducer } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner } from 'lego-dist';
import NeoModal from './NeoModal';
import RoundedButton from '../shared/buttons/RoundedButton';
import { NeoFilled } from '../themes/RoundedButton';
import { colors } from '../../styles';
import EnterClassroomCodeView from '../views/EnterClassroomCodeView';
import ConfirmClassroomView from '../views/ConfirmClassroomView';
import { Namespaces } from '../../values/i18n';
import { LOCALSTORAGE_BABY_STATUS } from '../../shared-values';
import useRouter from '../../hooks/useRouter';
import EventReporter, { Events } from '../../lib/EventReporter';

const Wrapper = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 460px !important;
  overflow: hidden;
  border-radius: 4px;

  margin: auto;
  box-sizing: border-box;
  max-width: ${props => props.width};
  `;

const DontLink = styled.div`
  color: ${colors.darkGray};
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 58px;
`;

const ClassroomsInviteCode = ({
  t, isOpen, babyResponse,
}) => {
  const reducerInitialState = () => ({
    currentStepIndex: 0,
    steps: [
      {
        view: EnterClassroomCodeView,
        index: 0,
      },
      {
        view: ConfirmClassroomView,
        index: 1,
      },
    ],
    formData: { classroomCode: '' },
    isCompleted: false,
  });
  const reducer = (state, action) => {
    switch (action.type) {
      case 'INIT_FLOW':
        return { ...state, steps: action.steps };
      case 'PREV_STEP':
        return { ...state, currentStepIndex: state.currentStepIndex - 1 };
      case 'NEXT_STEP':
        return { ...state, currentStepIndex: state.currentStepIndex + 1 };
      case 'SET_FORM_DATA':
        return { ...state, formData: { ...state.formData, ...action.formData } };
      case 'FINISH_FLOW':
        return { ...state, isCompleted: true };
      case 'ERROR_STATE':
        return { ...state, isCompleted: false };
      default:
        throw new Error('Unexpected action');
    }
  };
  const [state, dispatch] = useReducer(reducer, (reducerInitialState()));
  const removeFlags = () => {
    localStorage.removeItem(LOCALSTORAGE_BABY_STATUS);
    localStorage.removeItem('REDIRECT_TO_MY_CLASSROOM_AFTER_JOIN');
    localStorage.removeItem('temporaryClassroomsBaby');
    localStorage.removeItem('CREATE_BABY_AFTER_JOIN');
  };
  const { history } = useRouter();

  const CurrentStep = state.steps[state.currentStepIndex];
  if (!CurrentStep) return null;

  const nextStep = () => {
    dispatch({ type: 'NEXT_STEP' });
  };
  const onFinishFlow = () => true;

  const handleContinue = (values, closeModal) => {
    dispatch({ type: 'SET_FORM_DATA', formData: values });
    if (state.steps[state.currentStepIndex + 1]) {
      nextStep();
    } else if (onFinishFlow()) {
      dispatch({ type: 'FINISH_FLOW' });
      closeModal();
      if (localStorage.getItem('REDIRECT_TO_MY_CLASSROOM_AFTER_JOIN')) {
        history.push('/classrooms');
      }
      removeFlags();
    }
    return false;
  };

  const handleDontLink = (closeModal) => {
    closeModal();
    EventReporter.action(Events.DO_NOT_JOIN_SCHOOL_CLICKED());
  };

  const handleEditCode = () => {
    dispatch({ type: 'PREV_STEP' });
    EventReporter.action(Events.EDIT_CODE_CLICKED());
  };

  return (
    <Wrapper
      isOpen={isOpen}
      onCloseModal={removeFlags}
      closeOnBackdrop={false}
      showCloseButton={false}
    >
      {({ closeModal }) => (
        <>
          <CurrentStep.view
            t={t}
            formData={state.formData}
            babyResponse={babyResponse}
            handleContinue={values => handleContinue(values, closeModal)}
            renderButtons={(submitting, callback = () => {}) => (
              <>
                {submitting ? (
                  <Spinner
                    loading
                    name="threedots"
                    color="#0B86DB"
                    position="relative"
                    bgColor="transparent"
                  />
                ) : (
                  <RoundedButton
                    className="primaryActionBtn"
                    theme={NeoFilled}
                    width={255}
                    type={submitting ? 'button' : 'submit'}
                  >
                    {!submitting && (
                      state.currentStepIndex === 0 ? t('common:CONTINUE') : t('classroom_invite:CONFIRM_CLASSROOM.JOIN')
                    )}
                  </RoundedButton>
                )}
                <DontLink
                  onClick={() => {
                    if (state.currentStepIndex === 0) {
                      handleDontLink(closeModal);
                    } else {
                      handleEditCode();
                    }
                  }}
                >
                  {state.currentStepIndex === 0 ? t('classroom_invite:CLASSROOM_CODE.DONT_LINK') : t('classroom_invite:CONFIRM_CLASSROOM.EDIT')}
                </DontLink>
              </>
            )}
          />
        </>
      )}
    </Wrapper>
  );
};
ClassroomsInviteCode.propTypes = {
  isOpen: PropTypes.bool,
  babyResponse: PropTypes.object,
};
ClassroomsInviteCode.defaultProps = {
  isOpen: false,
  babyResponse: null,
};
export default withTranslation(
  [Namespaces.COMMON, Namespaces.CLASSROOMS_INVITE],
)(ClassroomsInviteCode);
