import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'formsy-react-2';

import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import { colors, breakPoints } from '../../../styles';
import Title from '../../shared/Title';
import TextInput from '../../shared/TextInput';

import BabyWithGift from '../../../images/BabyWithGift.png';
import { Neo } from '../../themes/TextInput';
import Button from '../../shared/buttons/MainButton/MainButton';
import { Payments, Modals } from '../../../data/ducks';
import { equalAt } from '../../../lib/utils';
import { ModalNames } from '../../../shared-values';
import { getProductBySku } from '../../../values/prices';
import { PaymentSources } from '../../../values/prices/paymentSources';
import EventReporter, { Events } from '../../../lib/EventReporter';
import { ProductsList } from '../../../values/prices/products';

const StyledPromoCode = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TopArea = styled.div`
  display: flex;
  align-items: flex-end;
  background-image: radial-gradient(57% 75%, ${colors.physical} 5%, ${colors.kinedu} 100%);
  margin-bottom: 20px;
`;

const Image = styled.img.attrs({ src: BabyWithGift })`
  position: relative;
  bottom: -20px;
  max-width: 164px;
  margin: 40px auto 0px;
`;

const BottomArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: 20px;
    max-width: 240px;
  }

  ${breakPoints.smallTablet} {
    span {
      max-width: 300px;
    }
  }
`;

const StyledForm = styled(Form)`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  margin: 20px 0 40px;
`;

class PromoCode extends Component {
  state = { buttonEnabled: false };

  componentDidMount = () => {
    EventReporter.view(Events.PROMO_CODE());
    this.props.resetPromoCode();
  }

  componentWillReceiveProps = (newProps) => {
    if (!equalAt(this.props, newProps, ['errorCode'])) {
      this.setState({ errorCode: newProps.errorCode });
    }

    if (!equalAt(this.props, newProps, ['sku'])) {
      if (newProps.sku) {
        const product = getProductBySku(ProductsList, newProps.sku) || ProductsList.y;
        newProps.openModal({
          ...ModalNames.PAYMENT_MODAL,
          data: {
            promo: true,
            showBackButton: false,
            ...product,
            source: PaymentSources.PROMO_CODE,
          },
        });
      }
    }
  }

  onSubmit = ({ code }) => {
    this.props.redeemPromoCode(code);
  };

  toggleSubmit = val => this.setState({ buttonEnabled: val });

  resetErrors = () => this.setState({ errorCode: '' });

  render() {
    const { t } = this.props;
    const { errorCode } = this.state;
    const errorText = errorCode ? t(`validations:PROMO_CODE.${errorCode}`) : '';
    return (
      <StyledPromoCode>
        <TopArea>
          <Image />
        </TopArea>
        <BottomArea>
          <Title size={18} mdsize={24} color={colors.kinedu} align="center" bold>
            {t('pricings:PLANS_INFO.ENTER_PROMO_CODE')}
          </Title>
          <StyledForm
            onValid={() => this.toggleSubmit(true)}
            onInvalid={() => {
              this.toggleSubmit(false);
              this.resetErrors();
            }}
            onSubmit={values => this.onSubmit(values)}
          >
            <TextInput
              theme={Neo}
              name="code"
              placeholder={t('pricings:PLANS_INFO.CODE')}
              maxWidth="300px"
              icon="label"
              iconSize={22}
              iconColor={colors.shadeMediumLight}
              errorText={errorText}
              required
            />
            <Button
              type="submit"
              color={this.state.buttonEnabled ? 'green' : 'disabled'}
              maxWidth={300}
              disabled={!this.state.buttonEnabled}
            >
              {t('pricings:PLANS_INFO.REDEEM')}
            </Button>
          </StyledForm>
        </BottomArea>
      </StyledPromoCode>
    );
  }
}

PromoCode.propTypes = {
  redeemPromoCode: PropTypes.func,
  resetPromoCode: PropTypes.func,
  t: PropTypes.func,
};

PromoCode.defaultProps = {
  redeemPromoCode: () => {},
  resetPromoCode: () => {},
  t: () => {},
};

const mapStateToProps = state => ({
  errorCode: state.payments.error,
  sku: state.payments.sku,
});

const mapDispatchToProps = dispatch => bind({
  redeemPromoCode: Payments.Creators.redeemPromoCodeRequested,
  resetPromoCode: Payments.Creators.redeemPromoCodeReset,
  openModal: Modals.Creators.openModal,
}, dispatch);

export default compose(
  withTranslation([Namespaces.MODALS, Namespaces.COMMON]),
  connect(mapStateToProps, mapDispatchToProps),
)(PromoCode);
