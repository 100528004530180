import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Button } from 'lego-dist';

import { Namespaces } from '../../../values/i18n';
import BabyGift from '../../../images/BabyGift.svg';
import { breakPoints } from '../../../styles';

const BabyGiftImg = styled.img.attrs({ src: BabyGift })`
  position: absolute;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;

  ${breakPoints.smallTablet} {
    width: 450px;
  }
`;

const CongratulationsViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 50px;
  box-sizing: border-box;
`;

const Description = styled.p`
  color: #4A4A4A;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 30px;
  text-align: center;

  ${breakPoints.smallTablet} {
    font-size: 28px;
    letter-spacing: -0.58px;
    line-height: 33px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  button {
    font-size: 16px;
  }
`;

const Note = styled.p`
  color: #4A4A4A;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;

  ${breakPoints.smallTablet} {
    font-size: 22px;
    letter-spacing: -1.1px;
    line-height: 25px;
    margin-top: 18px;
    margin-bottom: 43px;
  }
`;

const CongratulationsView = ({ t, onClose }) => (
  <CongratulationsViewContainer>
    <Description>{t('CONGRATS_GIFT.DESCRIPTION')}</Description>
    <ImageContainer>
      <BabyGiftImg />
    </ImageContainer>
    <Note>{t('CONGRATS_GIFT.NOTE')}</Note>
    <ButtonsContainer>
      <Button color="#75B753" rounded flat fill maxWidth={300} onClick={onClose}>{t('CONGRATS_GIFT.CTA')}</Button>
    </ButtonsContainer>
  </CongratulationsViewContainer>
);

CongratulationsView.propTypes = {
  onClose: PropTypes.func,
  t: PropTypes.func,
};

CongratulationsView.defaultProps = {
  onClose: () => {},
  t: () => {},
};

export default withTranslation([Namespaces.MODALS])(CongratulationsView);
