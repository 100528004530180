import React from 'react';
import LeftInfoContentComponent from 'components/Auth/components/LeftInfoContent';
import SignupComponent from 'components/Auth/components/Signup';
import AuthViewWrapper, {
  AuthViewContent,
  LeftSideCircle,
  LeftSideCircle2,
  LeftSideContent,
  LeftSideSquare,
  LeftSideSquare2,
  RightSideContent,
} from '../styles';

const AuthSignupView = () => (
  <AuthViewWrapper>
    <AuthViewContent>
      <LeftSideContent>
        <LeftInfoContentComponent />
        <LeftSideCircle />
        <LeftSideCircle2 />
        <LeftSideSquare />
        <LeftSideSquare2 />
      </LeftSideContent>
      <RightSideContent>
        <SignupComponent />
      </RightSideContent>
    </AuthViewContent>
  </AuthViewWrapper>
);

AuthSignupView.propTypes = {};

AuthSignupView.defaultProps = {};

export default AuthSignupView;
