import i18n from 'i18next';
import ICU from 'i18next-icu';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';
import { initReactI18next } from 'react-i18next';
// import { APP_VERSION } from 'config';
import { toUpperLowerCase } from '../../../../../lib/utils';
import { SectionNamespaces } from './namespaces';
import { LOCALES_PATH } from '../../../../../config';

const userLocale = localStorage.getItem('user_locale') || '';

const LOCALE = (userLocale || i18n.language || localStorage.getItem('i18nextLng') || 'en').substring(0, 2);
moment.locale(LOCALE);
const i18nInstance = i18n.createInstance();
i18nInstance
  .use(new ICU())
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: LOCALES_PATH,
    },
    fallbackLng: LOCALE,
    debug: false,
    ns: SectionNamespaces,
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
      format(value, format) {
        if (format === 'upperLower') {
          return toUpperLowerCase(value);
        }
        if (value instanceof Date) return moment(value).format(format);

        return value;
      },
    },
    react: {
      useSuspense: false,
    },
  });

i18nInstance.on('languageChanged', lng => moment.locale(lng));
export default i18nInstance;
