import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { colors, breakPoints } from '../../../styles';
import Grid, { Row, Col } from '../../shared/Grid';
import BabyWithGraph from '../../../images/BabyWithGraph.png';
import { Namespaces } from '../../../values/i18n';
import Button from '../../shared/buttons/MainButton';
import {
  genderAdposition, genderChild, possessivePronounPt, pronounPt,
} from '../../../lib/utils';

const SkillScienceWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    text-align: center;
    h1 {
      letter-spacing: -0.92px;
    }
`;
const TopContainer = styled.div`
    width: 100%;
    flex-basis: 100%;
`;

const BottomContainer = styled.div`
    width: 100%;
    background-color: ${colors.kinedu};
    flex-basis: 70%;
`;

const TitleWrapper = styled.div`
    width: 100%;
    padding: 10px 0;
    p {
        margin-top: 10px;
    };
`;

const SubtitleWrapper = styled.div`
  width: 100%;

  p {
    color: ${colors.white}
  }
`;

const ModalTitle = styled.h1`
    color: ${colors.kinedu};
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0 10px 0;

    ${breakPoints.smallTablet} {
      font-size: 24px;
    }
`;

const SubTitle = styled.h1`
    color: ${colors.kinedu};
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;

    ${breakPoints.smallTablet} {
      font-size: 24px;
    }
`;

const Description = styled.p`
    color: ${colors.dustyGray};
    font-size: 14px;
    font-weight: 400;
    text-align: left;

    ${breakPoints.smallTablet} {
      font-size: 16px;
    }
`;

const ImageContainer = styled.div`
  width: 136px;
  height: 136px;

  margin: 20px auto;

  box-shadow: 0px 16px 52px -10px #ACC2D3;

  border-radius: 100%;
  background-image: url(${BabyWithGraph});
  background-size: 136px;
  background-repeat: no-repeat;
  background-position: center -83%;
  background-color: ${colors.shadeLighter};

  ${breakPoints.smallTablet} {
    width: 156px;
    height: 156px;
  }
`;

const SkillScienceView = ({ baby, t, closeModal }) => (
  <SkillScienceWrapper>
    <TopContainer>
      <Grid>
        <Row>
          <Col mdOffset={1} md={10} sm={12} xs={12}>
            <TitleWrapper>
              <ModalTitle>
                {t('MODAL_SKILL_SCIENCE.TITLE', {
                  babyName: baby.name,
                  adpos: genderAdposition(baby.gender),
                })}
              </ModalTitle>
              <ImageContainer />
              <Description>
                {t('MODAL_SKILL_SCIENCE.DESC_1', {
                  adpos: genderAdposition(baby.gender),
                  pronoun: pronounPt(baby.gender),
                })}
              </Description>
              <SubTitle>
                {t('MODAL_SKILL_SCIENCE.SUBTITLE_1', {
                  possessive: possessivePronounPt(baby.gender),
                  adpos: genderAdposition(baby.gender),
                })}
              </SubTitle>
              <Description>
                {t('MODAL_SKILL_SCIENCE.DESC_2', {
                  child: genderChild(baby.gender),
                  pronoun: pronounPt(baby.gender),
                  childPt: possessivePronounPt(baby.gender),
                  adpos: genderAdposition(baby.gender),
                })}
              </Description>
            </TitleWrapper>
          </Col>
        </Row>
      </Grid>
    </TopContainer>
    <BottomContainer>
      <Grid>
        <Row>
          <Col mdOffset={1} md={10} sm={12} xs={12}>
            <SubtitleWrapper>
              <SubTitle style={{ color: colors.white }}>
                {t('MODAL_SKILL_SCIENCE.SUBTITLE_2', {
                  possessive: possessivePronounPt(baby.gender),
                  adpos: genderAdposition(baby.gender),
                  child: genderChild(baby.gender),
                })}
              </SubTitle>
              <Description>
                {t('MODAL_SKILL_SCIENCE.DESC_3', {
                  possessive: pronounPt(baby.gender),
                })}
              </Description>
              <Description>
                {t('MODAL_SKILL_SCIENCE.DESC_4')}
              </Description>
            </SubtitleWrapper>
            <Button
              onClick={closeModal}
              maxWidth={300}
              color="green"
              type="submit"
              style={{ margin: '20px auto' }}
            >
              {t('common:GOT_IT')}
            </Button>
          </Col>
        </Row>
      </Grid>
    </BottomContainer>
  </SkillScienceWrapper>
);

SkillScienceView.propTypes = {
  baby: PropTypes.object,
  closeModal: PropTypes.func,
  t: PropTypes.func,
};
SkillScienceView.defaultProps = {
  baby: {},
  closeModal: () => {},
  t: () => { },
};

export default withTranslation([Namespaces.MODALS, Namespaces.COMMON])(SkillScienceView);
