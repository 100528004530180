import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fillAbsoluteSpace, colors } from '../../../styles';

import DrExplaining from '../../../images/DrExplaining.png';
// import DrExplainingWP from '../../../images/DrExplaining.webp';
import Title from '../../shared/Title';
import RoundedButton from '../../shared/buttons/RoundedButton';
import { NeoFilled } from '../../themes/RoundedButton';
import { PaymentSources } from '../../../values/prices/paymentSources';

const StyledFreemiumOverlay = styled.div``;

const Overlay = styled.div`
  ${fillAbsoluteSpace}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 1;
`;

const ColorEffect = styled.div`
  ${fillAbsoluteSpace}
  background-color: ${colors.multiplyBlue};
  mix-blend-mode: multiply;
  z-index: 1;
`;

const Image = styled.img.attrs({ src: DrExplaining })`
  width: 140px;
  height: 140px;
  margin: 20px 0;
  z-index: 1;
`;


const FreemiumOverlay = ({ activeBaby, show, goToPrices, t }) => (show ? (
  <StyledFreemiumOverlay>
    <ColorEffect />
    <Overlay>
      <Title
        color="white"
        size={16}
        spacing="-0.57px"
        style={{ zIndex: 1, maxWidth: '300px' }}
        align="center"
        bold
      >
        {t('SKILL_DETAIL.FREEMIUM_TITLE', { babyName: activeBaby.name })}
      </Title>
      <Image />
      <RoundedButton
        onClick={() => goToPrices({ source: PaymentSources.PROGRESS_SKILL_GRAPH })}
        theme={NeoFilled}
        style={{ width: '300px' }}
      >
        {t('common:GO_PREMIUM')}
      </RoundedButton>
    </Overlay>
  </StyledFreemiumOverlay>
) : null);

FreemiumOverlay.propTypes = {
  activeBaby: PropTypes.object,
  show: PropTypes.bool,
  goToPrices: PropTypes.func,
  t: () => {},
};

FreemiumOverlay.defaultProps = {
  activeBaby: {},
  show: false,
  goToPrices: () => {},
  t: () => {},
};

export default FreemiumOverlay;
