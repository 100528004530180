import { useState, useEffect } from 'react';
import Leanplum from 'leanplum-sdk';
import { LEANPLUM_DEFAULT } from 'config';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/useUser';
import EventReporter, { Events } from 'lib/EventReporter';
import {
  LOAD_OPTIONS,
  PP_SCHEMA,
  PAYWALL_SCHEMA,
  VARIABLES_DEFAULT,
} from './values';

function useLeanplum({
  load = LOAD_OPTIONS.ALL,
  variables = VARIABLES_DEFAULT,
  useForce = false,
}) {
  const { user } = useUser();
  const { t: translate } = useTranslation();
  const [data, setData] = useState(null);
  const [paywallData, setPaywallData] = useState(null);
  const [PPData, setPPData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [errorPaywall, setErrorPaywall] = useState(null);
  const [errorPP, setErrorPP] = useState(null);
  const [alreadyExecuted, setAlreadyExecuted] = useState(null);
  const [paywallDataAlreadyVerified, setPaywallDataAlreadyVerified] = useState(null);
  const [PPDataAlreadyVerified, setPPDataAlreadyVerified] = useState(null);

  const reportFails = (type, attrs) => {
    if ([LOAD_OPTIONS.ALL, LOAD_OPTIONS.PAYWALL].includes(load) && type === LOAD_OPTIONS.PAYWALL) {
      EventReporter.action(
        Events.LOAD_DEFAULT_PAYWALL(attrs),
      );
    }

    if ([LOAD_OPTIONS.ALL, LOAD_OPTIONS.PP].includes(load) && type === LOAD_OPTIONS.PP) {
      EventReporter.action(
        Events.LOAD_DEFAULT_PP(attrs),
      );
    }
  };

  const getPaywallDefaultData = () => {
    const { PAYWALL } = LEANPLUM_DEFAULT;
    return {
      isDefault: true,
      webCopyButton: translate('paywall:LEANPLUM.WEB_COPY_BUTTON'),
      webPromoText: translate('paywall:LEANPLUM.WEB_PROMO_TEXT'),
      webImage: translate('paywall:LEANPLUM.WEB_IMAGE'),
      webImageResp: translate('paywall:LEANPLUM.WEB_IMAGE_RESP'),
      webSku: PAYWALL.webSku,
    };
  };

  const getPPDefaultData = () => {
    const { PP } = LEANPLUM_DEFAULT;
    return {
      isDefault: true,
      webImagePP: translate('pp:LEANPLUM.WEB_IMAGE'),
      webImagePPResp: translate('pp:LEANPLUM.WEB_IMAGE_RESP'),
      webPPCopyButton: translate('pp:LEANPLUM.WEB_COPY_BUTTON'),

      webDiscountPremiumFirst: translate('pp:LEANPLUM.WEB_DISCOUNT_PREMIUM_FIRST'),
      webDiscountPremiumSecond: translate('pp:LEANPLUM.WEB_DISCOUNT_PREMIUM_SECOND'),
      webDiscountPremiumThird: translate('pp:LEANPLUM.WEB_DISCOUNT_PREMIUM_THIRD'),

      webTitlePremiumFirst: translate('pp:LEANPLUM.WEB_TITLE_PREMIUM_FIRST'),
      webTitlePremiumSecond: translate('pp:LEANPLUM.WEB_TITLE_PREMIUM_SECOND'),
      webTitlePremiumThird: translate('pp:LEANPLUM.WEB_TITLE_PREMIUM_THIRD'),

      webMonthlyPricePremiumFirst: PP.webMonthlyPricePremiumFirst,
      webMonthlyPricePremiumThird: PP.webMonthlyPricePremiumThird,
      webMonthlyPricePremiumSecond: PP.webMonthlyPricePremiumSecond,

      webSkuPremiumFirst: PP.webSkuPremiumFirst,
      // webSkuPremiumSecond: PP.webSkuPremiumSecond,
      webSkuPremiumSecond: translate('pp:LEANPLUM.WEB_SKU_PREMIUM_SECOND'),
      webSkuPremiumThird: PP.webSkuPremiumThird,
    };
  };

  const verifyData = (values) => {
    if ([LOAD_OPTIONS.ALL, LOAD_OPTIONS.PAYWALL].includes(load)) {
      PAYWALL_SCHEMA.validate(values?.paywall_web, { abortEarly: false })
        .then(value => setPaywallData({ ...value, ...{ isDefault: false } }))
        .catch((error) => {
          setPaywallData(getPaywallDefaultData());
          const fails = error?.inner?.map(item => item?.path).join(',');
          reportFails(LOAD_OPTIONS.PAYWALL, fails);
          setErrorPaywall({ ...error });
        })
        .finally(() => {
          setPaywallDataAlreadyVerified(true);
        });
    }

    if ([LOAD_OPTIONS.ALL, LOAD_OPTIONS.PP].includes(load)) {
      PP_SCHEMA.validate(values?.pp_web, { abortEarly: false })
        .then(value => setPPData({ ...value, ...{ isDefault: false } }))
        .catch((error) => {
          setPPData(getPPDefaultData());
          const fails = error?.inner?.map(item => item?.path).join(',');
          reportFails(LOAD_OPTIONS.PP, fails);
          setErrorPP({ ...error });
        })
        .finally(() => {
          setPPDataAlreadyVerified(true);
        });
    }
  };

  const onInit = (userId) => {
    setIsLoading(true);
    try {
      if (`${Leanplum.getUserId()}` !== `${userId}`) {
        Leanplum.setUserId(`${userId}`);
        Leanplum.setVariables(variables);
      }

      if (useForce) {
        Leanplum.forceContentUpdate(() => {
          if (load !== LOAD_OPTIONS.NOTHING) {
            const allData = Leanplum.getVariables();
            setData(allData);
            setIsLoading(false);
            setAlreadyExecuted(true);
            verifyData(allData);
          }
        });
      } else if (load !== LOAD_OPTIONS.NOTHING) {
        const allData = Leanplum.getVariables();
        setData(allData);
        setIsLoading(false);
        setAlreadyExecuted(true);
        verifyData(allData);
      }
      // eslint-disable-next-line padded-blocks
    } catch (error) {
      setErrors(error);
      setIsLoading(false);
      setAlreadyExecuted(true);
      reportFails('loadLeanplum');

      if ([LOAD_OPTIONS.ALL, LOAD_OPTIONS.PAYWALL].includes(load)) {
        setPaywallData(getPaywallDefaultData());
      }
      if ([LOAD_OPTIONS.ALL, LOAD_OPTIONS.PP].includes(load)) {
        setPPData(getPPDefaultData());
      }
    }
  };

  useEffect(() => {
    if (user?.id) {
      onInit(user?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return {
    Leanplum,
    data,
    paywallData,
    PPData,
    isLoading,
    alreadyExecuted,
    paywallDataAlreadyVerified,
    PPDataAlreadyVerified,
    errors,
    errorPaywall,
    errorPP,
  };
}

export default useLeanplum;
export {
  LOAD_OPTIONS, PP_SCHEMA, PAYWALL_SCHEMA, VARIABLES_DEFAULT,
};
