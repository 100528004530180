import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { bindActionCreators as bind, compose } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import FullModal from './FullModal';
import { colors, breakPoints } from '../../styles';
import BabyExtension from '../../images/BabyExtension.svg';
import Texture from '../../images/Texture3.png';
import { sendEmail } from '../../lib/utils';

const ModalContainer = styled(FullModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-direction: row;
  flex-wrap: wrap;

  padding: 20px;
  background-color: ${colors.physical};
  background-image: url(${Texture});

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${breakPoints.smallTablet} {
    flex-direction: column;
  }
`;

const ErrorFatalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: calc(100vh - 60px);
  padding: 0;
  box-sizing: border-box;
  background-image: url(${Texture});

  ${breakPoints.smallTablet} {
    padding: 0 0 50px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 0;
  width: 100%;
  height: auto;

  .fake-link {
    color: ${colors.kinedu};
    text-decoration: none;
    font-weight: 500;
  }

  ${breakPoints.smallTablet} {
    height: auto;
  }
`;

const Image = styled.img.attrs({ src: BabyExtension })`
  width: 250px;
  margin: -60px 0 -30px 0;

  ${breakPoints.smallTablet} {
    width: 450px;
    margin: -90px 0 -50px 0;
  }
`;

const Title = styled.h1`
  color: ${colors.kinedu};
  font-size: 40px;
  letter-spacing: -3.5px;
  text-align: center;
  margin: 30px 0;

  ${breakPoints.smallTablet} {
    font-size: 60px;
  }
`;

const Subtitle = styled.p`
  color: ${colors.shadeDark};
  font-weight: bold;
  font-size: 21px;
  margin: 10px 0;
  letter-spacing: -1.05px;
  line-height: 25px;
  text-align: center;

  ${breakPoints.smallTablet} {
    font-size: 25px;
  }
`;

const Description = styled.p`
  color: ${colors.shadeDark};
  font-size: 16px;
  letter-spacing: -1.05px;
  line-height: 25px;
  text-align: center;
  max-width: 400px;

  ${breakPoints.smallTablet} {
    font-size: 18px;
  }
`;

/*
  Dispatch example:

  {
    type: 'MODAL_OPENED',
    name: 'FatalErrorModal',
    data: {
    },
  }

  */

const FatalErrorModal = ({ user, t }) => (
  <ModalContainer showCloseButton={false}>
    {() => (
      <Fragment>
        <ErrorFatalContainer>
          <Content>
            <Title>{t('MODAL_FATAL_ERROR.TITLE')}</Title>
            <Image />
            <Subtitle>{t('MODAL_FATAL_ERROR.SUBTITLE')}</Subtitle>
            <Description>{t('MODAL_FATAL_ERROR.DESCRIPTION')}</Description>
            <a
              href={sendEmail(user.id, user.source, t, 'EMAIL.SUBJECT', 'EMAIL.BODY')}
              target="_self"
              className="fake-link"
            >
              {t('common:EMAIL.EMAIL')}
            </a>
          </Content>
        </ErrorFatalContainer>
      </Fragment>
    )}
  </ModalContainer>
);

FatalErrorModal.propTypes = {
  user: PropTypes.object,
  t: PropTypes.func,
};

FatalErrorModal.defaultProps = {
  user: {},
  t: () => { },
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => bind({
  // ...
}, dispatch);

export default compose(
  withTranslation([Namespaces.MODALS, Namespaces.COMMON]),
  connect(mapStateToProps, mapDispatchToProps),
)(FatalErrorModal);
