import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators as bind, compose } from 'redux';
import Formsy from 'formsy-react-2';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Modals } from '../../data/ducks';
import { Creators } from '../../data/ducks/auth';

import Title from '../shared/Title';
import TextInput from '../shared/TextInput';
import { Flat } from '../themes/TextInput';
import Button from '../shared/buttons/MainButton';
import { colors, breakPoints } from '../../styles';
import { VALIDATIONS_MESSAGES } from '../../values/validations';
import Buzon from '../../images/authView/buzon.png';

import { Types as AuthTypes } from '../../values/auth';

const View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${colors.white};

  .new-input {
    label {
      display: block;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 5px;
    }

    input {
      border: 1px solid #979797;
      border-radius: 4px;
      height: 50px;

    &::placeholder {
        font-size: 14px;
        color: #979797;
        letter-spacing: 0;
        line-height: 21px;
      }
    }

    .input-error{
      background: no-repeat;
      color: red;
      margin: 10px 0px 0;
      padding: 0;
      font-size: 12px;
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  padding: 60px 40px 30px;
  box-sizing: border-box;
  height: 100vh;

  ${breakPoints.smallTablet} {
    padding: 22px 80px 30px;
    height: auto;
  }
`;

const Form = styled(Formsy.Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakPoints.smallTablet} {
    button {
      max-width: 300px;
    }
  }
`;

const Header = styled.div`
  margin-top: 30px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 40px;
`;

const Subtitle = styled.span`
  font-size: 24px;
  line-height: 47px;

  text-align: right;
  letter-spacing: -0.4px;

  color: #0093DD;
  font-weight: bold;
`;

const Description = styled.p`
  max-width: 418px;
  font-size: 16px;
  line-height: 21px;

  text-align: center;
  letter-spacing: -0.266667px;

  color: #333333;
`;

const Note = styled.p`
  max-width: 418px;
  text-align: center;
  font-size: 16px;
  line-height: 21px;

  text-align: center;
  letter-spacing: -0.266667px;
  margin: 22px 0 0px;

  color: #333333;
`;

const Image = styled.img.attrs({ src: Buzon })`
  width: 202px;
`;

class ForgotPasswordView extends React.Component {
  state = { sent: false, submitEnabled: false };

  // TODO: Bind errors.
  sendEmail = ({ email }) => {
    this.props.sendRestoreEmail(email);
    this.setState({ sent: true });
  }

  openLoginModal = () => this.props.openModal({ name: 'AuthModal', data: { authType: AuthTypes.LOGIN } });

  toggleSubmit = val => this.setState({ submitEnabled: val });

  render() {
    const { t } = this.props;
    const { sent, submitEnabled } = this.state;

    return (
      <View>
        {sent ? (
          <Message>
            <Image />
            <Subtitle>{t('MODAL_FORGOT_PASS.YOU_GOT_MAIL')}</Subtitle>
            <Description>
              {t('MODAL_FORGOT_PASS.WE_SENT_EMAIL')}
            </Description>
          </Message>
        ) : (
          <>
            <Header>
              <Title level="4" size={24} color="#0093DD" align="center" bold>{t('MODAL_FORGOT_PASS.TITLE')}</Title>
              <Note>{t('MODAL_FORGOT_PASS.DESCRIPTION')}</Note>
            </Header>
            <Footer>
              <Form
                onValid={() => this.toggleSubmit(true)}
                onInvalid={() => this.toggleSubmit(false)}
                onSubmit={this.sendEmail}
              >
                <TextInput
                  className="new-input"
                  name="email"
                  type="email"
                  label={t('MODAL_FORGOT_PASS.LABEL')}
                  placeholder={t('MODAL_FORGOT_PASS.ENTER_EMAIL')}
                  theme={Flat}
                  validations={{ isEmail: true }}
                  validationErrors={{
                    isDefaultRequiredValue: t(`validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`),
                    isEmail: t(`validations:${VALIDATIONS_MESSAGES.IS_EMAIL}`),
                  }}
                  required
                />
                <Button
                  type="submit"
                  color={submitEnabled ? 'green' : 'disabled'}
                  disabled={!submitEnabled}
                  rounded
                  small
                >
                  {t('MODAL_FORGOT_PASS.RESET_PASS')}

                </Button>
              </Form>
            </Footer>
          </>
        )}
      </View>
    );
  }
}

ForgotPasswordView.propTypes = {
  sendRestoreEmail: PropTypes.func,
  openModal: PropTypes.func,
  t: PropTypes.func,
};
ForgotPasswordView.defaultProps = {
  sendRestoreEmail: () => { },
  openModal: () => { },
  t: () => { },
};

const mapDispatchToProps = dispatch => bind({
  openModal: Modals.Creators.openModal,
  sendRestoreEmail: Creators.sendRestoreEmail,
}, dispatch);

export default compose(
  connect(() => ({}), mapDispatchToProps),
  withTranslation([Namespaces.MODALS, Namespaces.VALIDATIONS]),
)(ForgotPasswordView);
