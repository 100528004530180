import mixpanel from 'mixpanel-browser';
import EventReporter, { Events } from '../../../lib/EventReporter';
import {
  LOCALSTORAGE_API_KEY,
  LOCALSTORAGE_ACTIVE_FAMILY_KEY,
  LOCALSTORAGE_ACTIVE_BABY_KEY,
} from '../../../shared-values';

const logout = action$ =>
  action$.ofType('LOGOUT')
    .do(async () => {
      EventReporter.action(Events.LOG_OUT());
      await window.persistor.purge();
      window.localStorage.removeItem('leanplumStarted');
      mixpanel.reset();

      [
        LOCALSTORAGE_API_KEY,
        LOCALSTORAGE_ACTIVE_FAMILY_KEY,
        LOCALSTORAGE_ACTIVE_BABY_KEY,
      ].forEach(key => window.localStorage.removeItem(key));
    }).ignoreElements();

export default logout;
