import localForage from 'localforage';
import { combineReducers } from 'redux';
import { resettableReducer } from 'reduxsauce';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable';

import * as Auth from './auth';
import * as Content from './content';
import * as Dap from './dap';
import * as Messages from './messages';
import * as InitialAssesment from './initialAssessment';
import * as Modals from './modals';
import * as PastPlans from './past-plans';
import * as Settings from './settings';
import * as User from './user';
import * as OnBoarding from './onBoarding';
import * as Prices from './prices';
import * as AppContext from './appContext';
import * as BabiesAndFamilies from './babiesAndFamilies';
import * as Payments from './payments';
import * as HealthInterests from './health-interests';
import * as Invites from './invites';
import * as Catalog from './catalog';
import * as Progress from './progress';
import * as Feed from './feed';
import * as Account from './account';
import * as Chat from './chat';
import * as PremiumProcess from './stripe/premium-process';
import history from '../../history';

const Families = BabiesAndFamilies;

const transformerConfig = {
  whitelistPerReducer: {
    // reducerA: ['keyA', 'keyB'],
  },
  blacklistPerReducer: {
    // reducerB: ['keyC', 'keyD'],
  },
};

export {
  AppContext,
  Auth,
  Content,
  Dap,
  Messages,
  Families,
  BabiesAndFamilies,
  InitialAssesment,
  Modals,
  PastPlans,
  Settings,
  User,
  OnBoarding,
  Prices,
  Payments,
  HealthInterests,
  Catalog,
  Progress,
  Feed,
  Invites,
  Chat,
  PremiumProcess,
  Account,
};

const config = {
  key: 'root',
  storage: localForage,
  blacklist: ['router', 'OB', 'IA', 'messages', 'appContext', 'modals', 'payments'],
  stateReconciler: seamlessImmutableReconciler,
  transforms: [seamlessImmutableTransformCreator(transformerConfig)],
};

const Reducer = persistReducer(
  config,
  resettableReducer('LOGOUT')(
    combineReducers({
      auth: Auth.Reducer,
      user: User.Reducer,
      router: connectRouter(history),
      appContext: AppContext.Reducer,
      dap: resettableReducer('RESET_DAP')(Dap.Reducer),
      content: resettableReducer('RESET_CONTENT')(Content.Reducer),
      pastPlans: resettableReducer('RESET_PAST_PLANS')(PastPlans.Reducer),
      families: BabiesAndFamilies.Reducer,
      catalog: Catalog.Reducer,
      progress: Progress.Reducer,
      OB: OnBoarding.Reducer,
      IA: InitialAssesment.Reducer,
      prices: Prices.Reducer,
      payments: Payments.Reducer,
      healthInterests: HealthInterests.Reducer,
      messages: Messages.Reducer,
      invites: Invites.Reducer,
      modals: Modals.Reducer,
      settings: Settings.Reducer,
      feed: Feed.Reducer,
      account: Account.Reducer,
      chatEduConfig: Chat.Reducer,
      premiumProcess: PremiumProcess.Reducer,
    }),
  ),
);

export default Reducer;
