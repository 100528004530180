import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators as bind, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


import { Namespaces } from 'values/i18n';
import LeftContentComponent from './LeftContent';
import { Types as AuthTypes } from 'values/auth';

import FormSignupComponent from './form';

import { Container, LeftSide, RightSide, StyledHome } from './styles';



const SignupPageComponent = ({
  history = {},
  i18n = {}
}) => {

  const [authType, setAuthType] = useState(AuthTypes.SIGNUP);


  const setAuthParam = (type) => {
    history.push({
      pathname: `/${type}`,
    });
  };

  const onAuthTypeChange = (type) => {
    setAuthType(type);
    setAuthParam(type);
  };


  return (
    <StyledHome>
      <LeftSide />
      <RightSide />
      <Container>
        <LeftContentComponent authType={authType} />
        <FormSignupComponent onAuthTypeChange={onAuthTypeChange} />
      </Container>
    </StyledHome>
  );
};

SignupPageComponent.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  i18n: PropTypes.object,
  openModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => bind({ }, dispatch);

export default compose(
  withTranslation([Namespaces.LANDING, Namespaces.COMMON]),
  connect(() => ({}), mapDispatchToProps),
  withRouter,
)(SignupPageComponent);
