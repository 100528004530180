// TODO: Cambiar a tener varios modals al mismo tiempo, manejar
// Cosas como cuando se abre otro con uno ya activo, etc.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Modals } from '../data/ducks';

import ModalList from '../components/modals';
import MenusList from './menus/menus';

class ModalContainer extends Component {
  constructor() {
    super();

    this.state = {
      activeModal: null,
    };

    this.requestClose = this.requestClose.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const newModal = newProps.modals.name;
    const currentModal = this.props.modals.name;
    if (!this.state.activeModal || newModal !== currentModal) {
      this.setState({ activeModal: newModal });
    }
  }

  requestClose() {
    this.props.closeModal({ name: this.props.modals.name });
  }

  requestSubmit({ data }) {
    this.props.submitModal({ name: this.props.modals.name, data });
  }

  render() {
    const RequestedModal = { ...ModalList, ...MenusList }[this.state.activeModal];
    if (this.state.activeModal) {
      // document.body.style.setProperty('overflow', 'hidden');
      // document.body.style.setProperty('position', 'fixed');
    } else {
      // document.body.style.setProperty('overflow', 'visible');
      // document.body.style.setProperty('position', 'static');
    }

    if (RequestedModal) {
      return (
        <RequestedModal
          isOpen
          onClose={this.requestClose}
          onSubmit={this.requestSubmit}
          {...this.props.modals.data}
        />
      );
    }

    return null;
  }
}

const mapStateToProps = state => ({ modals: { ...state.modals } });
const dispatchToProps = dispatch => ({
  ...bindActionCreators(Modals.Creators, dispatch),
});

ModalContainer.propTypes = {
  modals: PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.shape({}),
  }),
  closeModal: PropTypes.func,
  submitModal: PropTypes.func,
};

ModalContainer.defaultProps = {
  modals: {},
  closeModal: undefined,
  submitModal: undefined,
};

export default connect(mapStateToProps, dispatchToProps)(ModalContainer);
