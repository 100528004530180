/* eslint-disable camelcase */
import 'rxjs/add/operator/pairwise';
import { Observable } from 'rxjs/Observable';
import { values, filter, complement, isNil } from 'ramda';
import { push, createMatchSelector  } from 'connected-react-router';
import { Creators, Selectors, Types } from '../../ducks/babiesAndFamilies';
import { User } from 'data/ducks';
import { Creators as IA } from '../../ducks/initialAssessment';
import { startOB2IAFlow, OBTypes, onIAEnd } from '../orchestrators/OB2IA';
import { IASteps } from '../../../shared-values';
import { postNullReminder } from '../../../api/assesments';
import { userIsNewUser } from '../../../lib/utils';

export const fetchMembersWhenFamilySelected = (action$, { getState }) =>
  action$.ofType(Types.FAMILY_SELECTED)
    .map(({ familyId }) => {
      if (getState().families.memberships[familyId]) {
        return ({ type: 'FAMILY_MEMBERS_ALREADY_FETCHED' });
      }
      return Creators.fetchFamilyMembersRequested(familyId);
    });

export const fetchMembersWhenMemberIsAdded = action$ =>
  action$.ofType(Types.ADD_MEMBER_SUCCESS)
    .map(({ membership }) => Creators.fetchFamilyMembersRequested(membership.family_id));


export const selectFamilyAndDefaultBaby = (action$, { getState }) => action$.ofType(Types.SELECT_FAMILY_AND_DEFAULT_BABY)
  .mergeMap(({ familyId }) => {
    // Pre-selected baby
    const defaultBaby = values(Selectors.babiesByFamilyId(getState(), familyId))[0];
    const activeBabyId = getState().families.activeBabyId;
    // finds an already selected baby if not, changes to the default baby.
    const babyId = activeBabyId || defaultBaby?.id;
    return [
      Creators.familySelected(familyId),
      Creators.babySelected(babyId),
    ];
  });

export const checkNoFamily = (action$, { getState }) =>
  action$.ofType(Types.CHECK_NO_FAMILY)
    .map(() => getState().families.families)
    .filter(families => families.length === 0)
    .mergeMap(() => {
      const user = getState().user;
      if (userIsNewUser(user)) {
        return [
          // @TODO: NewOnBoarding - code commented
          // Modals.Creators.openModal({
          //   name: 'NoFamilyModal',
          // }),
          startOB2IAFlow({ OBType: OBTypes.FULL }),
        ];
      }
      return [
        // @TODO: NewOnBoarding - code commented
        // Modals.Creators.openModal({
        //   name: 'NoFamilyModal',
        // }),
        startOB2IAFlow({ OBType: OBTypes.PARTIAL }),
      ];
    });

export const checkNoDefaultFamily = (action$, { getState }) =>
  action$.ofType(Types.CHECK_NO_DEFAULT_FAMILY)
    .map(() => getState().families.families)
    .filter(families => families.length === 1)
    .filter(families => families[0])
    .mergeMap(([{ id }]) => [Creators.setDefaultFamilyRequested(id)]);

export const checkEmptyFamily = (action$, { getState }) =>
  action$.ofType(Types.CHECK_EMPTY_FAMILY)
    .map(() => Selectors.activeFamily(getState()))
    .filter(({ self_owner }) => self_owner)
    .map(({ babies }) => filter(complement(isNil), values(babies)))
    .filter(babies => !babies || babies.length === 0)
    .mapTo(startOB2IAFlow({ OBType: OBTypes.BABIES }));

export const checkPendingAssessment = (action$, { getState }) =>
  action$.ofType(Types.CHECK_PENDING_ASSESSMENT)
    .map(() => Selectors.activeBaby(getState()))
    .filter(baby => baby)
    .filter(({ pending_initial_assessment, pending_ia_reminder }) =>
      pending_initial_assessment || pending_ia_reminder)
    .mergeMap(({
      pending_initial_assessment,
      pending_ia_reminder,
      initial_assessment_id,
      id: babyId,
    } = {}) => {
      const { params } = createMatchSelector({ path: "/ia/:section" })(getState()) || {};
      localStorage.removeItem('sectionPaywall');
      if (params?.section) {
        localStorage.setItem('sectionPaywall', params.section);
      }

      if (pending_initial_assessment) {
        // No previous assessment - show whats new screen.
        return Observable.concat([
          IA.startIa({ babyId }),
          IA.setAssessmentStep(IASteps.ABOUT),
          push('/ia'),
        ],
        onIAEnd(action$).mapTo(push('/tap')),
        Observable.of(User.Creators.fetchUserDetailRequested()).delay(1000));

      } else if (pending_ia_reminder) {
        // Coming from a reminder
        postNullReminder(initial_assessment_id).catch(() => { });
        return Observable.concat([
          IA.startIa({ babyId }),
          IA.setAssessmentStep(IASteps.REMINDER),
          push('/ia'),
        ],
        onIAEnd(action$).mapTo(push('/tap')));
      }

      return false;
    });
