import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import Icon from '../shared/Icon';
import { colors } from '../../styles';

const styles = css`
  display: flex;
  font-size: 12px;
  height: 75px;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  flex-direction: column;

  color: ${colors.kineduShade};
  fill: ${colors.kineduShade};
  background-color: ${colors.kinedu};
  :hover {
    background-color: #2e89d0;
  }

  font-weight: 600;
  opacity: 1;

  &.selected {
    background-color: ${colors.kineduShade};
    fill: #FFF;
    color: #FFF;
  }

  .fill-class {
    fill: ${colors.kinedu};
  }

  &.selected .fill-class {
    fill: ${colors.kinedu};
  }
  &.selected .fill-class-bulb {
    fill: white;
  }

  transition: all .35s ease-in-out;

  ${({ state }) => (state === 'entering' || state === 'exiting') && css`
    opacity: 0;
  `};

  ${({ state, theme }) => (state === 'entered' || theme.mobile) && css`
    fill: ${colors.shadeDark};
    color: ${colors.shadeDark};
    font-weight: 400;
    flex-direction: row;
    text-transform: uppercase;
    border-bottom: 1px solid #CFD5DA;
    letter-spacing: 2.67px;
    justify-content: flex-start;
    padding-right: 20px;
    opacity: 1;

    background-color: ${colors.shadeLight};
    :hover {
      background-color: ${colors.shadeLighter};
    }

    .fill-class-bulb {
      fill: #657480;
    }


    &.selected {
      fill: ${colors.kinedu};
      color: ${colors.kinedu};

      background-color: ${colors.shadeLight};
      :hover {
        background-color: ${colors.shadeLighter};
      }

      .fill-class-bulb {
        fill: ${colors.kinedu};
      }
    }

    .fill-class {
      fill: ${colors.shadeLight};
    }

    &.selected .fill-class {
      fill: ${colors.shadeLight};
      color: ${colors.kinedu};
    }
  `}

  ${({ theme }) => theme.mobile && css`
    font-size: 14px;
    height: 52px;
  `};
`;

const Link = styled(NavLink)`
  position: relative;
  ${styles};
  padding: 0;
  ${({ state }) => state === 'entered' && css`
    padding: 0 20px;
  `};
`;

const Button = styled.div`
  position: relative;
  ${styles};
  padding: 0;
  ${({ state }) => state === 'entered' && css`
    padding: 0 20px;
  `};
`;

const IconContainer = styled.div`
  ${({ state }) => state === 'entered' && css`
    margin-right: 10px;
  `};
`;

const TextContainer = styled.div`
  ${({ badge }) => badge && css`
    :after {
      content: '${badge}';
      position: absolute;
      top: 5px;
      right: 5px;
      color: #675700;
      background: #e0b500;
      padding: 3px;
      border-radius: 2px;
    }
  `}
`;

const NavItem = ({ text, icon, iconSize, iconWidth, to, onClick, state, badge, iconStyle = {} }) => {
  const Container = to ? Link : Button;

  return (
    <Container to={to} activeClassName="selected" onClick={onClick} state={state}>
      <IconContainer state={state}>
        <Icon icon={icon} size={iconSize} style={iconStyle} />
      </IconContainer>
      <TextContainer badge={badge}>{text}</TextContainer>
    </Container>
  );
};

NavItem.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  to: PropTypes.string,
  state: PropTypes.string,
  iconStyle: PropTypes.object,
  onClick: PropTypes.func,
};
NavItem.defaultProps = {
  text: '',
  icon: '',
  iconSize: 45,
  to: '',
  state: '',
  iconStyle: {},
  onClick: () => { },
};

export default NavItem;
