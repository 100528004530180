import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import Button from '../../shared/buttons/MainButton';
import { colors, breakPoints } from '../../../styles';

import Baby from '../../../images/BabyNotes.png';

const ImageContainer = styled.div`
  width: 120px;
  height: 129px;

  background-image: url(${Baby});
  background-size: 104px 129px;
  background-repeat: no-repeat;
  background-position: center -83%;
  background-color: ${colors.shadeLight};
  border-radius: 100%;

  margin: 20px;
`;

const Title = styled.h2`
  color: ${colors.kinedu};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  letter-spacing: -0.55px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  ${breakPoints.smallTablet} {
    padding: 20px 50px;
  }
`;

const Description = styled.div`
  color: ${colors.dustyGray};
  font-size: 16px;
  margin: 20px 0;
  text-align: center;

  ${breakPoints.smallTablet} {
    margin: 20px 35px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MilestonesScience = ({ t, closeModal }) => (
  <Content>
    <Title>{t('MODAL_SCIENCE_BEHIND.TITLE')}</Title>
    <Body>
      <ImageContainer />
      <Description>
        {t('MODAL_SCIENCE_BEHIND.DESCRIPTION')}
      </Description>
    </Body>
    <Button color="green" style={{ maxWidth: '280px' }} onClick={closeModal}>
      {t('MODAL_SCIENCE_BEHIND.OK_GOT_IT')}
    </Button>
  </Content>
);

MilestonesScience.propTypes = {
  t: PropTypes.func,
  closeModal: PropTypes.func,
};

MilestonesScience.defaultProps = {
  t: () => {},
  closeModal: () => {},

};

export default withTranslation([Namespaces.MODALS])(MilestonesScience);
