import styled from 'styled-components';
import { ReactComponent  as IconCloseSvg } from '../assets/icon-close.svg';
// import IconCose from '../assets/icon-close.svg';

const PremiumProcessWrapper = styled.div.attrs({
  className: 'PremiumProcessWrapper',
})`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  display: block;
  width: calc(100% - 0px);
  max-width: 360px;
  margin: 0 auto;
  /* border: 1px solid red; */
  box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.18);
  position: relative;
  overflow: hidden;
  user-select: none;

  ${({ theme }) => theme.breakPoints.largeTablet} {
    width: calc(100% - 4px);
    max-width: 100%;
    border-radius: 8px;
  }
`;

const PremiumProcessIconClose = styled(IconCloseSvg).attrs({
  className: 'PremiumProcessIconClose',
})`
  width: 100%;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 99;

  path {
    fill: #2C343A;
  }

  ${({ theme }) => theme.breakPoints.largeTablet} {

    top: 10px;
    right: 10px;

    path {
      fill: #E6E7E8;
    };
  }
`;

const PremiumProcessContent = styled.div.attrs({
  className: 'PremiumProcessContent',
})`
  display: block;


  ${({ theme }) => theme.breakPoints.largeTablet} {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

const PremiumProcessContentImage = styled.div.attrs({
  className: 'PremiumProcessContentImage',
})`
  display: block;
  overflow: hidden;
  background: #fff;
`;

const PremiumProcessImageWrapper = styled.div.attrs({
  className: 'PremiumProcessImageWrapper',
})`
  display: block;
  position: relative;
  width: 100%;
`;

const PremiumProcessImage = styled.div.attrs({
  className: 'PremiumProcessImage',
})`
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  left: 0;
  padding-top: 94%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ imageMobile }) => imageMobile});

  ${({ theme }) => theme.breakPoints.largeTablet} {
    background-image: url(${({ image }) => image});
    padding-top: 105%;
    width: 102%;
    left: -1%;
  }
`;

const PremiumProcessContentInfo = styled.div.attrs({
  className: 'PremiumProcessContentInfo',
})`
  display: block;
  background-color: #fff;
  padding: 30px 20px 0 20px;

  ${({ theme }) => theme.breakPoints.largeTablet} {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr max-content;
    grid-template-areas: "PremiumProcessContentPlanCards"
                         "PremiumProcessContentActions";
    padding: 83px 27px 20px 37px;
  }
`;

const PremiumProcessContentPlanCards = styled.div.attrs({
  className: 'PremiumProcessContentPlanCards',
})`
  display: block;
  ${({ theme }) => theme.breakPoints.largeTablet} {
    grid-area: PremiumProcessContentPlanCards;
  }
`;

const PremiumProcessContentActions = styled.div.attrs({
  className: 'PremiumProcessContentActions',
})`
  display: block;
  background-color: #fff;
  padding: 30px 20px;

  .pp_button_primary {
    display: block;
    margin: 0 auto;
    color: #FFF;
    text-align: center;
    font-family: 'Proxima Nova';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  ${({ theme }) => theme.breakPoints.largeTablet} {
    grid-area: PremiumProcessContentActions;
    padding: 20px 18px 0 18px;

    .pp_button_primary {
      font-size: 16px;
      padding: 14px 10px;
    }
  }
`;

const PremiumProcessContentLinks = styled.div.attrs({
  className: 'PremiumProcessContentLinks',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "top top"
                       "left right";
  justify-content: center;
  grid-gap: 8px 15px;
  width: 100%;
  margin: 20px auto 0 auto;
  text-align: center;

  a:nth-child(1) {
    grid-area: top;
  }
  a:nth-child(2) {
    grid-area: left;
  }
  a:nth-child(3) {
    grid-area: right;
  }

  ${({ theme }) => theme.breakPoints.largeTablet} {
    margin-top: 14px;
  }
`;

const PremiumProcessLink = styled.a.attrs({
  className: 'PremiumProcessLink',
})`
  display: inline-block;
  margin: 0;

  color: #657480;
  text-align: center;
  font-family: 'Proxima Nova';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.429px;

  ${({ theme }) => theme.breakPoints.largeTablet} {
    font-size: 12px;
    margin: 0;
  }
`;

export default PremiumProcessWrapper;
export {
  PremiumProcessIconClose,
  PremiumProcessContent,
  PremiumProcessContentImage,
  PremiumProcessImageWrapper,
  PremiumProcessImage,
  PremiumProcessContentInfo,
  PremiumProcessContentPlanCards,
  PremiumProcessContentActions,
  PremiumProcessContentLinks,
  PremiumProcessLink,
};
