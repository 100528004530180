import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import { colors, breakPoints } from '../../../styles';
import Button from '../../shared/buttons/MainButton';
import cakeImage from '../../../images/BirthdayCake.png';
import { genderAdposition } from '../../../lib/utils';

const StyledBabyTurns24 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 743px;
  padding: 10px;

  overflow: hidden;
  background-image: radial-gradient(circle, #FFA2C4,#FF1486);
  `;

const WhiteBackground = styled.div`
  position: absolute;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60%;
  background-color: ${colors.white};

  ${breakPoints.smallTablet}{
    height: 50%;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  justify-content: center;

  width: 295px;
  height: 280px;
  border-radius: 8px;
  background: #FFFF;

  ${breakPoints.smallTablet}{
    width: 585px;
    height: 240px;
    margin: 170px 0 0;
  }
`;

const Title = styled.span`
  color: ${colors.linguistic};
  font-size: 24px;
  letter-spacing: -1.16px;
  text-align: center;
  font-weight: bold;
  margin: 130px 0 0;

  ${breakPoints.smallTablet}{
    font-size: 28px;
  }
`;

const SubTitle = styled.span`
  color: ${colors.shadeDark};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.9px;
  text-align: center;
  line-height: 23px;
  margin: 10px;

  ${breakPoints.smallTablet}{
    font-size: 20px;
  }
`;

const Description = styled.p`
  color: #9B9B9B;
  font-size: 14px;
  letter-spacing: -0.36px;
  text-align: center;
  line-height: 21px;
  margin: 10px 0;
  max-width: 465px;

  ${breakPoints.smallTablet}{
    font-size: 16px;
  }
`;

const CakeImg = styled.img.attrs({ src: cakeImage })`
  position: absolute;
  top: 50px;
  width: 370px;

  ${breakPoints.smallTablet}{
    width: 594px;
  }

`;
const BabyTurns24 = ({ baby, closeModal, t }) => (
  <StyledBabyTurns24>
    <CakeImg />
    <WhiteBackground />
    <Wrapper>
      <Title>{t('BABY_TURNS_24_MODAL.TITLE')}</Title>
      <SubTitle>
        {t('BABY_TURNS_24_MODAL.SUBTITLE')}
      </SubTitle>
      <Description>
        {t('BABY_TURNS_24_MODAL.DESC_1')}
      </Description>
      <Description>
        {t('BABY_TURNS_24_MODAL.DESC_2', { babyName: baby.name, adPos: genderAdposition(baby.gender) })}
      </Description>
      <Button maxWidth={255} color="green" type="submit" onClick={closeModal} style={{ margin: '20px 0' }}>
        {t('BABY_TURNS_24_MODAL.BUTTON')}
      </Button>
    </Wrapper>
  </StyledBabyTurns24>
);

BabyTurns24.propTypes = {
  baby: PropTypes.object,
  closeModal: PropTypes.func,
  t: PropTypes.func,

};
BabyTurns24.defaultProps = {
  baby: {},
  closeModal: () => {},
  t: () => {},
};

export default withTranslation(Namespaces.MODALS)(BabyTurns24);
