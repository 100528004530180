import styled from 'styled-components';

const Light = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
  background-image: ${({ disabled }) => (disabled ? 'none' :
    'radial-gradient(50% 75%,rgba(255,255,255,0.4) 20%,rgba(255,255,255,0.0) 100%)')};
`;

export default Light;
