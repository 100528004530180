import ReactPixel from 'react-facebook-pixel';
import { ENV, Environments, FB_APP_ID, FB_PIXEL_ID } from '../config';
import { Providers } from '../values/auth';

/** AUTH  */
window.fbAsyncInit = () => {
  return window.FB.init({
    appId: FB_APP_ID,
    autoLogAppEvents: true,
    version: 'v2.5',
    xfbml: true,
    cookie: true,
  });
};


(function(d, s, id){
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {return;}
  js = d.createElement(s); js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

const Errors = {
  DENIED_BY_USER: new Error('denied_by_user'),
};

// eslint-disable-next-line
export const signIn = () =>
  new Promise((resolve, reject) => window.FB.login(({ authResponse, status }) => {
    if (!authResponse) {
      return reject(Errors.DENIED_BY_USER);
    }
    const { accessToken, userID } = authResponse;

    window.FB.api('/me?fields=first_name,last_name,birthday', ({ first_name, last_name, birthdate }) => {
      resolve({
        thirdPartyToken: accessToken,
        thirdPartyuserId: userID,
        userData: { firstname: first_name, lastname: last_name, birthdate },
        provider: Providers.FB,
      });
    }, { scope: 'user_birthday' });
  }));
/** Pixel */

ReactPixel.init(FB_PIXEL_ID, null, { autoConfig: true, debug: ENV !== Environments.PRODUCTION });
