/* eslint-disable no-unused-vars */
import FavoriteBannerUpdatePremiumComponent from 'components/catalgoRedesign/components/Favorites/FavoriteBannerUpdatePremium';
import { FavoritesContentBanner } from 'components/catalgoRedesign/components/Favorites/FavoritesView/styles';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const SandboxViewWrapper = styled.div.attrs({
  className: 'SandboxViewWrapper',
})`
  display: block;
  width: 100%;
  margin: 0 auto;
  height: auto;
`;

const SandboxViewContent = styled.div.attrs({
  className: 'SandboxViewContent',
})`
  display: block;
  width: 80%;
  margin: 0 auto;
  border: 2px solid red;
`;

const SandboxView = () => {
  return (
    <SandboxViewWrapper>
      <h2>SandboxView</h2>
      <SandboxViewContent>
        <FavoritesContentBanner>
          <FavoriteBannerUpdatePremiumComponent />
        </FavoritesContentBanner>
      </SandboxViewContent>

    </SandboxViewWrapper>
  );
}
export default SandboxView;
