import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemeProvider } from 'styled-components';
import { Default, ThemeShape } from './../themes/Title';
import { breakPoints } from '../../styles';

const e = React.createElement;

const TitleStyled = styled(({ tag, children, ...props }) => e(tag, props, children)) `
  display: block;
  margin: 0;
  font-weight: normal;
  ${({ theme }) => theme.container};

  ${({ level }) => level === '1' && css`
    ${({ theme }) => theme.level1};
  `};

  ${({ level }) => level === '2' && css`
    ${({ theme }) => theme.level2};
  `};

  ${({ level }) => level === '3' && css`
    ${({ theme }) => theme.level3};
  `};

  ${({ level }) => level === '4' && css`
    ${({ theme }) => theme.level4};
  `};

  ${({ level }) => level === '5' && css`
    ${({ theme }) => theme.level5};
  `};

  color: ${({ color }) => color};
  font-size: ${({ size }) => (Number.isInteger(size) ? `${size}px` : size)};
  font-weight: ${({ medium }) => medium && '500'};
  font-weight: ${({ bold }) => bold && 'bold'};
  letter-spacing: ${({ spacing }) => spacing};
  text-align: ${({ align }) => align};

  ${breakPoints.smallTablet} {
    font-size: ${({ mdsize }) => (Number.isInteger(mdsize) ? `${mdsize}px` : mdsize)};
  }
`;

TitleStyled.propTypes = {
  tag: PropTypes.string,
};

TitleStyled.defaultProps = {
  tag: 'span',
};

const Title = ({
  theme,
  children,
  level,
  size,
  mdsize,
  color,
  bold,
  medium,
  spacing,
  align,
  style,
  className,
  tag,
}) => (
  <ThemeProvider theme={theme}>
    <TitleStyled
      level={level}
      size={size}
      mdsize={mdsize}
      color={color}
      bold={bold ? 1 : 0}
      medium={medium ? 1 : 0}
      spacing={spacing}
      align={align}
      style={style}
      className={className}
      tag={tag}
    >{children}</TitleStyled>
  </ThemeProvider>
);

Title.propTypes = {
  /* Theme object styles */
  theme: ThemeShape,
  /* Children elements */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  /* This is the hierarchy of the titles from 1 to 5 */
  level: PropTypes.string,
  /* This is the default size, for mobile or larger devices */
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /* This is the size for larger devices (tablet/desktop) */
  mdsize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /* Custom color */
  color: PropTypes.string,
  /* Set weight to bold */
  bold: PropTypes.bool,
  /* Set weight to medium */
  medium: PropTypes.bool,
  /* Set letter-spacing */
  spacing: PropTypes.string,
  /* Set text alignment */
  align: PropTypes.string,
  /* Inline styles */
  style: PropTypes.object,
  /* To add a className to this component */
  className: PropTypes.string,
  /* Tag element to use */
  tag: PropTypes.string,
};

Title.defaultProps = {
  theme: Default,
  children: null,
  level: '',
  size: '',
  mdsize: null,
  color: '',
  bold: false,
  medium: false,
  spacing: '',
  align: '',
  style: {},
  className: '',
  tag: 'span',
};

export default Title;
