import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles';
import KineduLogo from '../../landing/KineduLogo';

const Container = styled.div`
  z-index: 999999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 65px;
  background-color: ${colors.kinedu};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: calc(100% - 65px);
  background-color: ${colors.shadeLighter};
`;

const EmptyState = ({ children }) => (
  <Container>
    <Header><KineduLogo /></Header>
    <Content>
      {children}
    </Content>
  </Container>
);

export default EmptyState;
