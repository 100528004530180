import { css } from 'styled-components';
import { colors } from '../../colors';
import { breakPoints } from '../../breakPoints';
import physicalIcon from '../../../images/physical.svg';
import cognitiveIcon from '../../../images/cognitive.svg';
import linguisticIcon from '../../../images/linguistic.svg';
import socialEmotionalIcon from '../../../images/socialEmotional.svg';
import healthIcon from '../../../images/health.svg';


const FeedEvent = css`
  h5 {
    font-size: 16px;
    color: ${colors.shadeDark};
    letter-spacing: 0;

    ${breakPoints.tablet} {
      font-size: 20px;
      letter-spacing: -0.5px;
    }
  }
`;

const FeedEventRow = css`
  align-items: inherit;

  ${breakPoints.tablet} {
    align-items: center;
  }
`;

const FeedEventItem = css`
  flex-direction: column;
  padding: 10px;

  ${breakPoints.tablet} {
    flex-direction: inherit;
    padding: 26px 22px;
  }

  &[class$="area-feed-top-icon"] {
    position: relative;

    &::after {
      position: absolute;
      width: 16px;
      height: 16px;
      content: '';
      top: 10px;
      right: 10px;
    }
  }

  &.physical-area-feed-top-icon {
    &::after {
      content: url(${physicalIcon});
    }
  }

  &.cognitive-area-feed-top-icon {
    &::after {
      content: url(${cognitiveIcon});
    }
  }

  &.linguistic-area-feed-top-icon {
    &::after {
      content: url(${linguisticIcon});
    }
  }

  &.social_emotional-area-feed-top-icon {
    &::after {
      content: url(${socialEmotionalIcon});
    }
  }

  &.health-area-feed-top-icon {
    &::after {
      content: url(${healthIcon});
    }
  }

  .legoFeedEventItemContent {
    div {
      &:first-child {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 10px;

        ${breakPoints.tablet} {
          font-size: 18px;
        }
      }

      &:last-child {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        color: ${colors.shadeLight1};

        ${breakPoints.tablet} {
          font-size: 16px;
          letter-spacing: -0.57px;
          line-height: 21px;
        }
      }
    }
  }

  .legoButton {
    width: 84px;
    font-size: 10px;
    min-height: 20px;
    color: ${colors.oldKinedu};
    border: 1px solid ${colors.oldKinedu};
    border-radius: 20px;
    margin: 10px 0 0 auto;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    font-family: inherit;

    ${breakPoints.tablet} {
      width: 120px;
      font-size: 14px;
      min-width: 120px;
      min-height: 40px;
      margin: 0 0 0 16px;
    }
  }
`;


export default {
  FeedEvent,
  FeedEventRow,
  FeedEventItem,
};
