/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from 'values/i18n';
import { colors, breakPoints } from 'styles';

import BgStars from 'images/BgStars.svg';
import TrophySimple from 'images/TrophySimple.svg';
import Ribbon from 'images/Ribbon.svg';

import Button from '../../shared/buttons/MainButton';
import Badge from './Badge';
import Light from './Light';
import { useEffect } from 'react';
// import useRouter from 'hooks/useRouter';

const ActivityCompletedContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;

  ${({ type }) => type === 'A' && css`
    background-color: ${colors.kinedu};
    background-image: url(${BgStars});
    background-position-y: 20px;
    background-repeat: no-repeat;
  `}
`;

const Message = styled.div`
  margin-top: 300px;

  ${breakPoints.smallTablet} {
    margin-top: 200px;
  }
`;

const TextUp = styled.p`
  color: rgba(0,0,0,0.4);
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0;

  text-transform: uppercase;
  text-align: center;
  opacity: 0.6;

  ${({ type }) => type === 'B' && css`
    color: ${colors.white};
    font-size: 36px;
    text-shadow: 0 2px 3px rgba(0,0,0,0.30);
    opacity: 1;
  `}
`;

const TextDown = styled.p`
  color: ${colors.white};
  font-weight: bold;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 40.5px;
  text-shadow: 0 2px 3px rgba(0,0,0,0.3);
  text-align: center;

  width: 375px;

  ${({ type }) => type === 'B' && css`
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    width: 100%;
    max-width: 315px;

    ${breakPoints.smallTablet} {
      max-width: none;
    }
  `}
`;

const versions = [
  null,
  {
    type: 'A',
    image: TrophySimple,
    textUp: 'DOPAMINE.GREAT_JOB',
    textDown: 'DOPAMINE.COMPLETED_ACTIVITY',
  },
  {
    type: 'A',
    image: Ribbon,
    textUp: 'DOPAMINE.GREAT_JOB',
    textDown: 'DOPAMINE.COMPLETED_ACTIVITY',
  },
  {
    type: 'B',
    image: TrophySimple,
    textUp: 'DOPAMINE.AWESOME',
    textDown: 'DOPAMINE.BABY_IS_LEARNING',
  },
  {
    type: 'B',
    image: Ribbon,
    textUp: 'DOPAMINE.GREAT_JOB',
    textDown: 'DOPAMINE.COMPLETED_ACTIVITY_TODAY',
  },
];



const ActivityCompleted = ({
  version, baby, onClose, t,
}) => {

  // const { history } = useRouter();

  useEffect(() => {
    return () => {
      if (!window.location.pathname.includes('/wap')) {
        // history.push('/dap/current/');
      } else {
        // history.push('/dap/current/');
        setTimeout(() => {
          // history.push('/wap');
        }, 100);
      }
    };
  }, []);


  return (
    <ActivityCompletedContainer type={versions[version].type}>
      <Light disabled={versions[version].type === 'B'}>
        <Badge image={versions[version].image} />
        <Message>
          <TextUp type={versions[version].type}>{t(versions[version].textUp)}</TextUp>
          <TextDown type={versions[version].type}>
            {t(versions[version].textDown, { name: baby.name })}
          </TextDown>
        </Message>
        <Button
          onClick={onClose}
          color="green"
          maxWidth={260}
          style={{ margin: 'auto 0 40px' }}
        >
          {t('DOPAMINE.KEEP_LEARNING')}
        </Button>
      </Light>
    </ActivityCompletedContainer>
  )
};

ActivityCompleted.propTypes = {
  /* Number between 1 and 4, versions of the view */
  version: PropTypes.number,
  /* Baby's name to display */
  baby: PropTypes.string,
  /* Translation function */
  onClose: PropTypes.func,
  t: PropTypes.func,
};

ActivityCompleted.defaultProps = {
  version: 1,
  baby: '',
  onClose: () => {},
  t: () => {},
};

export default withTranslation([Namespaces.DAP])(ActivityCompleted);
