import { css } from 'styled-components';

const ConfirmationModal = {
  ModalContainer: css` `,
  P: css` `,
  WrapperButtons: css` `,
  ConfirmationButton: css` `,
  CancelButton: css` `,
};

export default ConfirmationModal;
