import { lego } from './lego';
import { breakPoints } from './breakPoints';
import { colors } from './colors';
import icons from '../images/icons';

export default {
  breakPoints,
  colors,
  lego,
  icons,
};
