import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import {
  Grid as GridComponent,
  Row as RowComponent,
  Col as ColComponent,
} from 'react-styled-flexboxgrid';
import { breakPoints } from '../../styles';

const theme = {
  flexboxgrid: {
    // Defaults // 16px = 1em (example)
    gridSize: 12, // rem
    gutterWidth: 1.25, // rem = 20px;
    outerMargin: 1.25, // rem
    mediaQuery: 'only screen',
    container: { // These are overriden by the inline-style width: 100%
      sm: 46, // rem
      md: 61, // rem
      lg: 76, // rem
    },
    breakpoints: { // 16px = 1em (example)
      xs: 0, // initial
      sm: 37.5, // em  = 600px
      md: 50, // em = 800px
      lg: 64, // em = 1024px
    },
  },
};

const GridStyled = styled(GridComponent)`
  width: 100%;
  height: 100%;
  max-width: 960px;
  box-sizing: border-box;

  .Col {
    position: relative;
    width: 100%;
    ${({ columnsStyle }) => columnsStyle}
  }

  .Col {
    ${({ rowStyle }) => rowStyle}
  }

  ${breakPoints.smallTablet} {
    .Col {
      ${({ mdColumnsStyle }) => mdColumnsStyle}
    }
  }

  ${({ style }) => style}
`;


const Grid = ({ children, className, style, columnsStyle, mdColumnsStyle, rowStyle }) => (
  <ThemeProvider theme={theme}>
    <GridStyled
      className={`grid ${className}`}
      style={style}
      columnsStyle={columnsStyle}
      mdColumnsStyle={mdColumnsStyle}
      rowStyle={rowStyle}
    >
      {children}
    </GridStyled>
  </ThemeProvider>
);

Grid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  columnsStyle: PropTypes.array,
  mdColumnsStyle: PropTypes.array,
  rowStyle: PropTypes.array,
};

Grid.defaultProps = {
  children: null,
  className: '',
  style: {},
  columnsStyle: [],
  mdColumnsStyle: [],
  rowStyle: [],
};

export const Row = props => <RowComponent {...props} className={`Row ${props.className}`} />;
export const Col = props => <ColComponent {...props} className={`Col ${props.className}`} />;

Row.propTypes = {
  className: PropTypes.string,
};

Row.defaultProps = {
  className: '',
};

Col.propTypes = {
  className: PropTypes.string,
};

Col.defaultProps = {
  className: '',
};

export default Grid;
