import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import Button from '../shared/buttons/MainButton';
import CheckList from '../shared/CheckList';
import { colors, breakPoints } from '../../styles';
import { PlanTypes, getFirstBenefit } from '../../values/prices';

const BannerContainer = styled.div`
  padding: 20px;
  background: ${({ plan }) => {
    if (plan === PlanTypes.MONTHLY) {
      return css`linear-gradient(89deg, #1FADDF 0%, #42D6F1 50%);`;
    } if (plan === PlanTypes.SEMESTERLY) {
      return css`linear-gradient(90deg, #F78E21 0%, #FCC145 94%);`;
    } if (plan === PlanTypes.YEARLY) {
      return css`linear-gradient(89deg, #D43571 0%, #EC65AA 50%);`;
    } if (plan === PlanTypes.LIFETIME) {
      return css`linear-gradient(90deg, #1B75BB 0%, #3AADDF 50%)`;
    }
    return css`linear-gradient(90deg, #1B75BB 0%, #3AADDF 50%)`;
  }};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${breakPoints.smallTablet} {
    flex-direction: row;
  }
`;

const LeftSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  ${breakPoints.smallTablet} {
    width: 40%;
    align-items: flex-start;
  }
`;

const RightSide = styled.div`
  width: 100%;

  ${breakPoints.smallTablet} {
    width: 60%;
  }
`;

const Title = styled.p`
  color: ${colors.white};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -1.05px;

  margin: 0;
`;

const Well = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 56px;
  background: #00000020;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const Text = styled.span`
  color: ${colors.white};
  font-size: 16px;
  letter-spacing: -0.72px;
`;

const Price = styled.span`
  color: ${colors.white};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -1.05px;
  text-align: center;

  ${breakPoints.smallTablet} {
    font-size: 24px;
  }
`;

const PricingBanner = ({
  plan, price, benefits, savings, show, t,
}) => {
  const newBenefits = [...benefits];
  if (plan !== PlanTypes.SEMESTERLY) {
    newBenefits.unshift(getFirstBenefit(savings));
  }
  const translatedBenefits = newBenefits.map(item => ({
    ...item,
    title: t(`PLANS_INFO.${item.title}`, { percentage: savings }),
  }));
  return show ? (
    <BannerContainer plan={plan}>
      <Content>
        <LeftSide>
          <Title>
Kinedu
            {' '}
            { plan ? t(`PLANS_INFO.${plan}`) : '' }
          </Title>
          <CheckList
            iconSize={20}
            textSize="14px"
            items={translatedBenefits}
            style={{ textTransform: 'capitalize' }}
          />
        </LeftSide>
        <RightSide>
          <Well>
            <Text>{t('PLANS_INFO.CARD_CHARGED')}</Text>
            <Price>
$
              {price}
              {' '}
              {t('PLANS_INFO.USD')}
            </Price>
          </Well>
          <Button
            type="submit"
            color="green"
            style={{ textTransform: 'capitalize' }}
          >
            {t('PLANS_INFO.PURCHASE')}
          </Button>
        </RightSide>
      </Content>
    </BannerContainer>
  ) : null;
};

PricingBanner.propTypes = {
  /* Plan's name */
  plan: PropTypes.string,
  /* Plan's price */
  price: PropTypes.number,
  /* List of plan's benefits */
  benefits: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    striked: PropTypes.bool,
  })),
  savings: PropTypes.number,
  show: PropTypes.bool,
  t: PropTypes.func,
};

PricingBanner.defaultProps = {
  plan: 0,
  price: '',
  benefits: [],
  savings: 0,
  show: false,
  t: () => {},
};

export default withTranslation([Namespaces.PRICINGS, Namespaces.COMMON])(PricingBanner);
