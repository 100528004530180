import { combineEpics } from 'redux-observable';

import Auth from './auth';
import Content from './content';
import Dap from './dap';
import BabiesAndFamilies from './babiesAndFamilies';
import * as InitialAssesment from './initial-assesment';
import PastPlans from './past-plans';
import User from './users';
import * as AppContext from './appContext';
import OnBoarding from './onBoarding';
import HealthInterests from './health-interests';
import * as Messages from './messages';
import * as Settings from './settings';
import * as Prices from './prices';
import * as Payments from './payments';
import * as Catalog from './catalog';
import * as Progress from './progress';
import * as Feed from './feed';
import * as Invites from './invites';
import * as Chat from './chat';
import * as Account from './account';

import Orchestrators from './orchestrators';

export default combineEpics(
  // New kid in town
  ...Object.values({
    ...AppContext,
    ...Prices,
    ...BabiesAndFamilies,
    ...InitialAssesment,
    ...Payments,
    ...Messages,
    ...Settings,
    ...Catalog,
    ...Progress,
    ...Feed,
    ...Invites,
    ...Chat,
    ...Account,
  }),
  Orchestrators,
  Auth,
  Content,
  Dap,
  PastPlans,
  User,
  OnBoarding,
  HealthInterests,
);
