import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Burst from '../../../images/Burst.svg';
import { breakPoints } from '../../../styles';

const BurstImg = styled.img.attrs({ src: Burst }) `
  position: absolute;

  @keyframes sunburst {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  animation-name: sunburst;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

// const Image = styled.img.attrs({ ({ src }) => src })`
//   z-index: 99;
// `;

const Image = styled.img.attrs(({ src }) => src)`
  z-index: 99;
`;

const BadgeContainer = styled.div`
  position: absolute;
  top: -100px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 420px;
  height: 420px;

  overflow: hidden;

  ${breakPoints.smallTablet} {
    top: -150px;
  }
`;

const Badge = ({ image, component }) => (
  <BadgeContainer>
    {component || <Image src={image} />}
    <BurstImg style={{ animationDuration: '16s' }} />
    <BurstImg style={{ animationDuration: '32s' }} />
  </BadgeContainer>
);

Badge.propTypes = {
  image: PropTypes.string,
  component: PropTypes.node,
};
Badge.defaultProps = {
  image: '',
  component: undefined,
};

export default Badge;
