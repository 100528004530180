import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Formsy from 'formsy-react-2';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import { NpsBabies, NpsColorByRate, NpsSlices } from '../../../values/nps';
import { colors } from '../../../styles';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BabyReaction = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding-top: 30px;
`;

const Image = styled.img.attrs({ src: props => props.src })`
  width: 100px;
  height: auto;
`;

const Phrase = styled.p`
  color: ${colors.darkGray};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  max-width: 150px;
`;

const Note = styled.p`
  position: absolute;
  bottom: 0;

  color: #4A4A4A;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  letter-spacing: -0.29px;
  line-height: 14px;
  text-align: center;

  width: 220px;
  opacity: 0.4;
`;

const Tachometer = styled.svg.attrs({ xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 450 400' })`
  display: block;
  max-width: 330px;
  width: 100%;
  margin: 20px auto;
  z-index: 1000;

  .slice {
    cursor: pointer;
  }

  .fill {
    fill: #E4E4E4;
    transition: fill .05s ease-in-out;

    &:hover {
      fill: #ECECEC;
    }
  }

  .number {
    fill: #5C5C5C;
  }

  .border {
    fill: #D3D3D3;
    transition: fill .05s ease-in-out;
  }
`;

class NpsTachometer extends React.Component {
  state = { rate: null };

  setRate = (rate) => {
    this.setState({ rate });
    this.props.setValue(rate);
  };

  render() {
    const { name, t } = this.props;
    return (
      <Container name={name}>
        <BabyReaction>
          {this.state.rate !== null && <Image src={NpsBabies[this.state.rate].image} />}
          {this.state.rate !== null && <Phrase>{t(NpsBabies[this.state.rate].phrase)}</Phrase>}
        </BabyReaction>
        <Tachometer>
          <g fill="none" id="wheel">
            {
              NpsSlices.map((slice, index) => (
                <g className="slice" onClick={() => this.setRate(index)}>
                  <path className="fill" style={this.state.rate === index ? { fill: NpsColorByRate[this.state.rate] } : {}} d={slice.fill} />
                  <path className="border" d={slice.border} />
                  <path className="crust" fill={NpsColorByRate[index]} d={slice.crust} />
                  <path className="number" style={this.state.rate === index ? { fill: '#FFF' } : {}} d={slice.number} />
                </g>
              ))
            }
          </g>
        </Tachometer>
        {this.state.rate === null
          && <Note>{t('NPS_NOTE')}</Note>}
      </Container>
    );
  }
}


NpsTachometer.propTypes = {
  name: PropTypes.string,
  setValue: PropTypes.func,
};

NpsTachometer.defaultProps = {
  name: '',
  setValue: () => {},
};

export default Formsy.HOC(withTranslation([Namespaces.SURVEYS])(NpsTachometer));
