import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Modal from './NeoModal';
import Button from '../shared/buttons/MainButton';

import { colors, breakPoints } from '../../styles';

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  max-width: ${props => props.width};

  ${breakPoints.smallTablet} {
    max-width: 500px;
  }

  ${({ styles }) => styles}
`;

const Title = styled.h1`
  color: ${colors.kinedu};
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-align: center;
  line-height: 26px;
`;

const Body = styled.div`
  color: ${colors.shadeDark};
  font-size: 16px;
  letter-spacing: -0.75px;
  text-align: center;
  line-height: 21px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

class ConfirmModal extends Component {
  state = { val: null };

  handleCallback = (callback, closeModal) => {
    callback();
    closeModal();
    this.props.onCloseModal();
  };

  render() {
    const {
      title,
      body,
      acceptText,
      cancelText,
      onAccept,
      onCancel,
      onCloseModal,
      width,
      styles,
    } = this.props;
    return (
      <Wrapper
        roundedCorners
        width={width}
        onCloseModal={onCloseModal}
        styles={styles}
        customModalName={this.props.modalName}
      >
        {({ closeModal }) => (
          <Fragment>
            {typeof title === 'string' ?
              <Title>{title}</Title> :
              title
            }
            <Body>{body}</Body>
            <Footer>
              <Button
                color="green"
                maxWidth={300}
                onClick={() => this.handleCallback(onAccept, closeModal)}
              >
                {acceptText}
              </Button>
              <Button
                color="clear"
                maxWidth={300}
                onClick={() => this.handleCallback(onCancel, closeModal)}
              >
                {cancelText}
              </Button>
            </Footer>
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

ConfirmModal.propTypes = {
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object,
  ]),
  /** An element that will be placed on top or a string that will be formated acordingly */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  acceptText: PropTypes.string,
  cancelText: PropTypes.string,
  width: PropTypes.string,
  modalName: PropTypes.string,
  styles: PropTypes.array,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  onCloseModal: PropTypes.func,
};

ConfirmModal.defaultProps = {
  body: '',
  title: '',
  acceptText: '',
  cancelText: '',
  width: '620px',
  modalName: 'confirmModal',
  styles: [],
  onAccept: () => { },
  onCancel: () => { },
  onCloseModal: () => { },
};

export default ConfirmModal;
