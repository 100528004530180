/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from 'values/i18n';
import EventReporter, { Events } from 'lib/EventReporter';

import { Creators } from 'data/ducks/modals';

import TextInput from 'components/shared/TextInput';
import { Flat } from 'components/themes/TextInput';
import Button from 'components/shared/buttons/MainButton';
import OvalSelectorComponent from 'components/OvalSelector';

import { Providers, Types as AuthTypes } from 'values/auth';
import withAuth, { propTypes as withAuthProptypes } from 'hoc/withAuth';
import { VALIDATIONS_MESSAGES } from 'values/validations';
import { KEY_LOCALE } from 'shared-values';
import { colors } from 'styles';
import ViewWrapper, {
  Form,
  InlineButtons,
  OptionButton,
  Note,
  Separator,
  Subtitle,
  Title,
  FormContent,
  FormContentBody,
  FormContentFoot,
  LogoImage,
} from '../../styles';
import i18n from '../../../../i18n';

i18n.changeLanguage(window.localStorage.getItem(KEY_LOCALE) || 'en');

const authTypes = {
  [AuthTypes.SIGNUP]: {
    title: 'WELCOME_TO_KINEDU',
    subtitle: 'LETS_BEGIN',
    buttonLabel: 'SIGNUP',
  },
  [AuthTypes.LOGIN]: {
    title: 'WELCOME_BACK',
    subtitle: 'LOGIN_EXISTING_ACC',
    buttonLabel: 'LOGIN',
  },
};

class FormSignupComponent extends Component {
  constructor(props) {
    super(props);
    this.handleOnValid = this.handleOnValid.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnChangeLang = this.handleOnChangeLang.bind(this);
    this.state = {
      values: {
        password: '',
        confirmPassword: '',
        email: '',
        lang: {
          id: window.localStorage.getItem(KEY_LOCALE) || 'en',
          value: window.localStorage.getItem(KEY_LOCALE) || 'en',
        },
      },
      formIsValid: false,
    };
  }

  // setLang = lang => i18n.changeLanguage(lang);

  handleOnValid(formIsValid) {
    this.setState({ formIsValid });
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.keyPress, false);
    EventReporter.view(Events.SIGNUP_SCREEN());
  };

  componentDidUpdate = (prevProps, prevState) => {
    // EventReporter.view(Events.SIGNUP_SCREEN());
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.keyPress, false);
  };

  onForgotPasswordClicked = () => {
    this.props.openModal({ name: 'ForgotPasswordModal' });
    EventReporter.action(Events.FORGOT_PASSWORD());
  };

  handleOnSubmit = () => {
    const variant = localStorage.getItem('exp_variant');

    if (this.props.onSignup) {
      this.props.onSignup({
        provider: Providers.EMAIL,
        ...this.state.values,
        cohort: variant,
      });
    }
  };

  toggleSubmit = val => this.setState({ submitEnabled: val });

  handleOnClickGoLogin = () => {
    const { onAuthTypeChange } = this.props;
    if (onAuthTypeChange) onAuthTypeChange(AuthTypes.LOGIN);
  };

  onChangeValues = (values) => {
    // this.setState({ values });

    this.setState({ values: { ...values, lang: this.state.values.lang } });
  };

  handleOnChangeLang = (value) => {
    EventReporter.action(Events.SELECT_LANGUAGE(value.value || 'en'));
    i18n.changeLanguage(value.value || 'en');
    window.localStorage.setItem(KEY_LOCALE, value.value || 'en');
    this.setState({ values: { ...this.state.values, ...{ lang: value } } });
  };

  render() {
    const { t: translation, error } = this.props;
    const lngs = [
      {
        label: 'EN',
        value: 'en',
      },
      {
        label: 'ES',
        value: 'es',
      },
      {
        label: 'PT',
        value: 'pt',
      },
    ];

    return (
      <ViewWrapper>
        <Form
          onValid={() => this.handleOnValid(true)}
          onInvalid={() => this.handleOnValid(false)}
          onChange={this.onChangeValues}
          onValidSubmit={this.handleOnSubmit}
        >
          <FormContent>
            <FormContentBody>
              <LogoImage />
              <Title size={30} color={colors.kinedu} align="center" bold>
                {translation(`common:AUTH.${authTypes.signup.title}`)}
              </Title>
              <Subtitle>
                {translation(`common:AUTH.${authTypes.signup.subtitle}`)}
              </Subtitle>
              <Separator />
              <TextInput
                className="new-input"
                type="email"
                name="email"
                label={translation('common:PLACEHOLDERS.EMAIL')}
                placeholder={translation('common:PLACEHOLDERS.EXAMPLE_EMAIL')}
                theme={Flat}
                errorText={error && translation(`validations:${error}`)}
                validationErrors={{
                  isDefaultRequiredValue: translation(
                    `validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`,
                  ),
                  isEmail: translation(
                    `validations:${VALIDATIONS_MESSAGES.EMAIL_INVALID}`,
                  ),
                }}
                validations="isEmail"
                value={this.state.values.email}
                required
              />
              <TextInput
                className="new-input"
                type="password"
                name="password"
                label={translation('common:PLACEHOLDERS.PASSWORD')}
                placeholder={translation(
                  'common:PLACEHOLDERS.TYPE_YOUR_PASSWORD',
                )}
                theme={Flat}
                validationErrors={{
                  isDefaultRequiredValue: translation(
                    `validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`,
                  ),
                }}
                autoComplete="new-password"
                value={this.state.values.password}
                required
              />

              <OvalSelectorComponent
                label={translation('MODAL_SETTINGS.LANG')}
                name="lngs"
                data={lngs}
                keys={{
                  label: 'label',
                  id: 'value',
                }}
                value={this.state.values.lang}
                onChange={this.handleOnChangeLang}
                required
                columns="1fr 1fr 1fr"
                mobileColumns="1fr 1fr 1fr"
              />
            </FormContentBody>
            <FormContentFoot>
              <InlineButtons>
                <Button
                  type="submit"
                  color="green"
                  disabled={!this.state.formIsValid}
                  rounded
                  small
                  maxWidth={300}
                >
                  {translation(`common:AUTH.${authTypes.signup.buttonLabel}`)}
                </Button>
              </InlineButtons>
              <Note>
                <span style={{ marginRight: '5px' }}>
                  {translation('ob:AUTH.ALREADY_HAVE_ACC')}
                </span>
                <OptionButton
                  type="button"
                  onClick={() => this.handleOnClickGoLogin()}
                  underlined
                >
                  {translation('ob:AUTH.LOGIN')}
                </OptionButton>
              </Note>
            </FormContentFoot>
          </FormContent>
        </Form>
      </ViewWrapper>
    );
  }
}

FormSignupComponent.propTypes = {
  ...withAuthProptypes,
  onAuthTypeChange: PropTypes.func,
};

// TODO: Check why is making this value undefined first
FormSignupComponent.defaultProps = {
  formIsValid: false,
  values: {
    password: '',
    confirmPassword: '',
    email: '',
    lang: {
      id: window.localStorage.getItem(KEY_LOCALE) || 'en',
      value: window.localStorage.getItem(KEY_LOCALE) || 'en',
    },
  },
  onAuthTypeChange: () => {},
};

const mapDispatchToProps = dispatch => bind(
  {
    openModal: Creators.openModal,
  },
  dispatch,
);

export default compose(
  connect(() => ({}), mapDispatchToProps),
  withTranslation([
    Namespaces.MODALS,
    Namespaces.COMMON,
    Namespaces.VALIDATIONS,
  ]),
  withAuth,
)(FormSignupComponent);
