import React from 'react';
import * as Sentry from '@sentry/react';
// import ReactDOM from 'react-dom';
import { hydrate, render } from 'react-dom';
// import Leanplum from 'leanplum-sdk';
import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';
import '../node_modules/react-smartbanner/dist/main.css';

import App from './App';
import * as config from './config';

(async () => {
  await import('./index.css');
  await import('./fonts/gothamrounded/gothamrounded.css');
  await import('./fonts/proximanova/proximanova.css');
})();

window.Kinedu = {
  appVersion: config.APP_VERSION,
  appVersionDescription: config.APP_VERSION_DESCRIPTION,
  environment: config.ENV,
  apiUrl: config.API_URL,
};

// if (config.ENV === config.Environments.STAGE) {
//   Leanplum.setAppIdForDevelopmentMode(config.LEANPLUM.APP_ID, config.LEANPLUM.KEY);
// } else {
//   Leanplum.setAppIdForProductionMode(config.LEANPLUM.APP_ID, config.LEANPLUM.KEY);
// }

// eslint-disable-next-line
export const LEANPLUM_VARIABLES = {
  ONE: {},
  TWO: {},
  THREE: {},
  FOUR: {},
};

// export const getLeanplumFile = (filename) => {
//   const { KEY, APP_ID } = config.LEANPLUM;
//   return `https://www.leanplum.com/api?action=downloadFile&appId=${APP_ID}&clientKey=${KEY}&apiVersion=1.3.0&filename=${filename}`;
// };

// Leanplum.start(() => {
//   const { InDaps = {} } = Leanplum.getVariables() || {};
//   LEANPLUM_VARIABLES.ONE = InDaps.one;
//   LEANPLUM_VARIABLES.TWO = InDaps.two;
//   LEANPLUM_VARIABLES.THREE = InDaps.three;
//   LEANPLUM_VARIABLES.FOUR = InDaps.four;
// });

mixpanel.init(config.MIXPANEL);

const tagManagerValues = {
  gtmId: config.GOOGLE_TAG_MANAGER.ACCOUNT_ID,
  auth: config.GOOGLE_TAG_MANAGER.AUTH_KEY,
  preview: config.GOOGLE_TAG_MANAGER.PREVIEW_ENV,
};

TagManager.initialize(tagManagerValues);

// ReactDOM.render(<App />, document.getElementById('root'));

Sentry.init({
  dsn: config.SENTRY_DNS,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: config.ENV,
  release: config.APP_VERSION,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: [/api.kinedu.com/],
  normalizeDepth: 3,
});

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  // eslint-disable-next-line
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
