import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import Formsy from 'formsy-react-2';
import { ThemeShape, Default } from '../themes/CheckBox';
import { colors } from '../../styles';
import Icon from './Icon';

const StyledCheckBox = styled.div`
  ${({ theme }) => theme.container};
`;

const Label = styled.label`
  ${({ theme }) => theme.label};
`;

const Input = styled.input.attrs({ type: 'checkbox' })`
  display: none;
  &:checked ~ .fake-input {
    ${({ theme }) => theme.checked};
    background-color: ${({ color }) => color};
  }
`;

const FakeInput = styled.div`
  flex-shrink: 0;
  border: 2px solid ${({ color }) => color};
  ${({ theme }) => theme.input};
`;

const Text = styled.span`
  ${({ theme }) => theme.text};
`;

class CheckBox extends Component {
  state = { val: null };

  render() {
    const { name, label, color, checked, theme, ...rest } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <StyledCheckBox>
          <Label>
            <Input name={name} color={color} defaultChecked={checked} {...rest} />
            <FakeInput className="fake-input" color={color}>
              <Icon icon="check" color="white" size={12} />
            </FakeInput>
            <Text>{label}</Text>
          </Label>
        </StyledCheckBox>
      </ThemeProvider>
    );
  }
}

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  theme: ThemeShape,
  color: PropTypes.string,
};

CheckBox.defaultProps = {
  label: '',
  checked: PropTypes.bool,
  theme: Default,
  color: colors.physical,
};

export default Formsy.HOC(CheckBox);
