/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
import { COUNTRY_CODES } from 'dictionary/country';
import { STRIPE_API_KEY, STRIPE_API_KEY_BRAZIL } from 'config';
import { createSession } from 'api/stripe';

export const getPublicApiKeyStripe = (country) => {
  return country === COUNTRY_CODES.brazil
    ? STRIPE_API_KEY_BRAZIL
    : STRIPE_API_KEY;
};

export const createSesionSubscription = async ({ priceId, user, plan, routes = {}, source = 'Paywall' }) => {
  try {
    const { data: { data: { session } } } = await createSession({
      priceId,
      email: user.email,
      successUrl: routes?.successUrl || (`${window.location.origin}/app/ia/${plan}?ft=true`),
      cancelUrl: routes?.cancelUrl || (`${window.location.origin}/app/ia`),
      source,
    });
    return session;
  } catch (error) {
    return {
      error: true,
      data: { ...error },
    };
  }
};
