import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Formsy from 'formsy-react-2';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Creators } from '../../data/ducks/modals';

import TextInput from '../shared/TextInput';
import { Flat } from '../themes/TextInput';
import Button from '../shared/buttons/MainButton';
import { colors, breakPoints } from '../../styles';
import Title from '../shared/Title';
import { Providers, Types as AuthTypes } from '../../values/auth';

import FamilyAuth from '../../images/FamilyAuth.svg';
import MomAndBaby from '../../images/MomAndBaby.svg';
import withAuth, { propTypes as withAuthProptypes } from '../../hoc/withAuth';
import { VALIDATIONS_MESSAGES } from '../../values/validations';
import Icon from '../shared/Icon';
import Link from '../shared/Link';
import EventReporter, { Events } from '../../lib/EventReporter';

const View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  box-sizing: border-box;
`;

const Image = styled.img`
  margin: 10px 0 0;
`;

const Form = styled(Formsy.Form)`
  width: 100%;
  margin-top: 20px;
`;

const InlineButtons = styled.div`
  display: flex;
  flex-direction: column;

  button:first-of-type {
    margin-bottom: 10px;
  }

  ${breakPoints.smallTablet} {
    flex-direction: row;

    button:first-of-type {
      margin-bottom: 0px;
      margin-right: 3%;
    }
  }
`;

const Note = styled.span`
  display: block;
  margin: 10px;

  color: ${colors.shadeMediumDark};
  font-size: 15px;
  letter-spacing: -0.33px;
  text-align: center;

  a {
    font-size: 15px;
  }
`;

const authTypes = {
  [AuthTypes.SIGNUP]: {
    title: 'WELCOME_TO_KINEDU',
    buttonLabel: 'SIGNUP',
    image: <Image src={FamilyAuth} style={{ marginBottom: '-60px', minHeight: '144px' }} />,
  },
  [AuthTypes.LOGIN]: {
    title: 'WELCOME_BACK',
    buttonLabel: 'LOGIN',
    image: <Image src={MomAndBaby} style={{ marginBottom: '-25px', minHeight: '116px' }} />,
  },
};

class AuthView extends Component {
  state = { val: null, type: '' };

  componentWillMount = () => {
    this.setState({ type: this.props.type });
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.keyPress, false);
    if (this.state.type === 'login') {
      EventReporter.view(Events.LOGIN_SCREEN());
    } else if (this.state.type === 'signup') {
      EventReporter.view(Events.SIGNUP_SCREEN());
    }
  }

  // NOTE: This is now closed in the appInit flow
  // componentWillReceiveProps = (newProps) => {
  //   if (newProps.isAuthenticated) {
  //     this.props.onClose();
  //   }
  // }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.keyPress, false);
  }

  onForgotPasswordClicked = () => {
    this.props.openModal({ name: 'ForgotPasswordModal' });
    EventReporter.action(Events.FORGOT_PASSWORD());
  }

  onSubmit = () => this.setState({ isSubmited: true });

  toggleSubmit = val => this.setState({ submitEnabled: val });

  toggleForm = () => {
    if (this.state.type === AuthTypes.LOGIN) {
      this.setState({ type: AuthTypes.SIGNUP });
    } else {
      this.setState({ type: AuthTypes.LOGIN });
    }
  }

  handleAuth = (provider, params) => {
    const variant = localStorage.getItem('exp_variant');
    if (this.state.type === AuthTypes.LOGIN || provider === Providers.FB) {
      this.props.onLogin({ provider, ...params });
    } else {
      this.props.onSignup({ provider, ...params, cohort: variant });
    }
  }

  render() {
    const { t, error } = this.props;
    const { type } = this.state;
    return (
      <View>
        <Title size={30} color={colors.kinedu} align="center" bold>{t(`common:AUTH.${authTypes[type].title}`)}</Title>
        {authTypes[type].image}
        <Form
          onChange={val => this.setState({ val })}
          onValid={() => this.toggleSubmit(true)}
          onInvalid={() => this.toggleSubmit(false)}
          onSubmit={() => this.onSubmit()}
          ref={(form) => { this.authForm = form; }}
        >
          <TextInput
            type="email"
            name="email"
            placeholder={t('common:PLACEHOLDERS.EMAIL')}
            theme={Flat}
            errorText={error && t(`validations:${error}`)}
            validations={{ isEmail: true }}
            validationErrors={{
              isDefaultRequiredValue: t(`validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`),
              isEmail: t(`validations:${VALIDATIONS_MESSAGES.IS_EMAIL}`),
            }}
            required
          />
          <TextInput
            type="password"
            name="password"
            placeholder={t('common:PLACEHOLDERS.PASSWORD')}
            theme={Flat}
            validationErrors={{
              isDefaultRequiredValue: t(`validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`),
            }}
            autoComplete="new-password"
            required
          />
          {type === AuthTypes.LOGIN
            && (
            <Link
              style={{ margin: '0 20px 20px', display: 'block' }}
              onClick={this.onForgotPasswordClicked}
              underlined
            >
              {t('common:AUTH.FORGOT_PASSWORD')}
            </Link>
            )}
          <InlineButtons>
            <Button
              type="submit"
              color="green"
              onClick={this.state.submitEnabled
                ? () => this.handleAuth(Providers.EMAIL, this.state.val)
                : () => {}}
            >
              {t(`common:AUTH.${authTypes[type].buttonLabel}`)}
            </Button>
            <Button
              color="fb"
              onClick={() => this.handleAuth(Providers.FB)}
              style={{ padding: '8px 12px' }}
            >
              <Icon icon="socialFacebook" size={20} color="white" style={{ marginRight: 'auto' }} />
              <span style={{ flex: '1' }}>
                {t('common:AUTH.WITH_FACEBOOK', { type: t(`common:AUTH.${authTypes[type].buttonLabel}`) })}
              </span>
            </Button>
          </InlineButtons>
          {type === AuthTypes.LOGIN
            ? (
              <Note>
                <span style={{ marginRight: '5px' }}>{t('common:AUTH.DONT_HAVE_ACC_YET')}</span>
                <Link onClick={() => this.toggleForm()} underlined>{t('common:AUTH.SIGNUP')}</Link>
              </Note>
            )
            : (
              <Note>
                <span style={{ marginRight: '5px' }}>{t('common:AUTH.ALREADY_HAVE_ACC')}</span>
                <Link onClick={() => this.toggleForm()} underlined>{t('common:AUTH.LOGIN')}</Link>
              </Note>
            )
          }
        </Form>
      </View>
    );
  }
}

AuthView.propTypes = {
  type: PropTypes.oneOf([AuthTypes.SIGNUP, AuthTypes.LOGIN]),
  ...withAuthProptypes,
};

// TODO: Check why is making this value undefined first
AuthView.defaultProps = {
  type: AuthTypes.LOGIN,
};

const mapDispatchToProps = dispatch => bind({
  openModal: Creators.openModal,
}, dispatch);

export default compose(
  connect(() => ({}), mapDispatchToProps),
  withTranslation([Namespaces.MODALS, Namespaces.COMMON, Namespaces.VALIDATIONS]),
  withAuth,
)(AuthView);
