import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ResponsiveContainer, ComposedChart, Line, Area, CartesianGrid, XAxis, YAxis, Tooltip,
} from 'recharts';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import { colors, fillAbsoluteSpace } from '../../../styles';
import Title from '../../shared/Title';
import Loading from '../../shared/LoadingOverlay';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

const StyledProgressGraph = styled.div`
  height: 290px;

  .recharts-text.recharts-cartesian-axis-tick-value {
    font-size: 11px;
    font-weight: bold;
    fill: #BBB;
  }
`;

const GraphContainer = styled.div`
  position: relative;
  width: 100%;
  height: 220px;
  box-sizing: border-box;
`;

const AxisWrapper = styled.div`
  ${fillAbsoluteSpace}
  pointer-events: none;
`;

const AxisName = styled.span`
  position: absolute;
  font-size: 11px;
  font-weight: bold;
  color: ${colors.kinedu};
  letter-spacing: 0;
  text-align: center;
  line-height: 15.82px;
`;

const XAxisName = styled(AxisName)`
  display: block;
  bottom: 0;
  left: 40%;

`;

const YAxisName = styled(AxisName)`
  transform: rotate(-90deg);
  left: 5px;
  top: 83%;
  transform-origin: 0 0;
`;

const Note = styled.span`
  position: absolute;
  right: 10px;
  bottom: -8px;
  font-size: 9px;
  font-style: italic;
  color: #9B9B9B;
  letter-spacing: -0.32px;
`;

const ProgressGraph = ({
  data, baby, progressStatus, completedMilestones, loading, t,
}) => {
  const isFutureSkill = progressStatus === 'future';
  const filteredData = data.map((item) => {
    if (isFutureSkill && !completedMilestones) {
      const { completed_milestones, ...averageData } = item;
      return averageData;
    } if (item.age <= baby.developmental_age_in_months) {
      return item;
    }
    const { completed_milestones, ...newItem } = item;
    return newItem;
  });
  return (
    <StyledProgressGraph>
      <Loading loading={loading} position="absolute" />
      <Title size={16} mdsize={18} color={colors.kinedu} style={{ margin: '10px 0' }} align="center" bold>{t('PROGRESS_GRAPH.TITLE', { babyName: baby.name })}</Title>
      <GraphContainer>
        <ResponsiveContainer>
          <ComposedChart width={600} height={300} data={filteredData}>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="age" axisLine={false} tickLine={false} tickSize={11} padding={{ left: 0, right: 0 }} />
            <YAxis axisLine={false} tickLine={false} tickSize={11} />
            <Area type="monotone" dataKey="average_milestone_percentile_90" stroke={colors.cognitiveLight50} fill="#00000000" strokeDasharray="5 10" strokeWidth={3} strokeLinecap="round" />
            <Area type="monotone" dataKey="average_milestone_percentile_75" stroke={colors.physicalLight50} fill="#00000000" strokeDasharray="5 10" strokeWidth={3} strokeLinecap="round" />
            <Area type="monotone" dataKey="average_milestone_percentile_25" stroke={colors.linguisticLight50} fill="#00000000" strokeDasharray="5 10" strokeWidth={3} strokeLinecap="round" />
            <Area type="monotone" dataKey="average_milestone_percentile_10" stroke="#00000000" fill="#00000020" />
            <Line
              type="monotone"
              dataKey="completed_milestones"
              stroke={colors.kinedu}
              strokeWidth={2.7}
              dot={{
                stroke: colors.kinedu, fill: colors.kinedu, strokeWidth: 2, r: 4,
              }}
              activeDot={{ strokeWidth: 1 }}
            />
            <Tooltip content={<CustomTooltip />} />
          </ComposedChart>
        </ResponsiveContainer>
        <AxisWrapper>
          <XAxisName>{t('PROGRESS_GRAPH.XAXIS_NAME')}</XAxisName>
          <YAxisName>{t('PROGRESS_GRAPH.YAXIS_NAME')}</YAxisName>
        </AxisWrapper>
        <Note>{t('PROGRESS_GRAPH.BASED_ON')}</Note>
      </GraphContainer>
    </StyledProgressGraph>
  );
};

ProgressGraph.propTypes = {
  data: PropTypes.array,
  baby: PropTypes.object,
  loading: PropTypes.bool,
  t: PropTypes.func,
};

ProgressGraph.defaultProps = {
  data: [],
  baby: {},
  loading: false,
  t: () => {},
};

export default withTranslation([Namespaces.PROGRESS])(ProgressGraph);
