import { css } from 'styled-components';

const List = {
  ListItemWrapper: css`
    border-bottom: 0px;
    `,
  Item: css``,
};

export default List;
