import styled, { css } from 'styled-components';
import WebPreview from 'images/authView/web_app_preview.png';
import LogoKineduComplete from 'images/logo-kinedu-complete.svg';
import YellowHalfCircle from 'images/authView/half_circle.svg';
import BlueRectangle from 'images/authView/horizontal_rectangle.svg';
import GreenSquare from 'images/authView/green_square.svg';

export const StyledAuthLeftSide = styled.div`
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  grid-row: 2;

  ${({ theme }) => theme.breakPoints.desktop} {
    display: block;
  }
`;

export const Image = styled.img.attrs({
  src: WebPreview,
})`
  position: absolute;
  top: calc(50% - (445px / 2));
  right: -40px;
  height: 445px;
`;

export const Logo = styled.img.attrs({
  src: LogoKineduComplete,
})`
  position: absolute;
  width: 160px;
  top: -20px;
  left: 81px;
`;

export const BenefitsContainer = styled.div`
  position: relative;
  top: calc(50% - (445px / 2));
  right: 0px;
  margin: auto;
  width: 550px;
  height: 500px;
`;

export const Title = styled.div`
  font-size: 36px;
  color: #ffffff;
  letter-spacing: 0;
`;

export const BenefitsList = styled.ul``;

export const BenefitItem = styled.li`
  font-size: 18px;
  color: #ffffff;
  letter-spacing: -0.2px;
  line-height: 44px;
`;

export const Note = styled.div`
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 44px;
`;

export const ShapesArea = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const HalfCircle = styled.img.attrs({ src: YellowHalfCircle })``;

export const Rectangle = styled.img.attrs({ src: BlueRectangle })`
  ${({ RectangleStyles }) => RectangleStyles && RectangleStyles};
`;

export const Square = styled.img.attrs({ src: GreenSquare })`
  position: absolute;
  bottom: 30px;
  right: 0;
`;

export const rect1Styles = css`
  position: absolute;
  top: 55px;
  right: 0;
`;

export const rect2Styles = css`
  position: absolute;
  bottom: 220px;
  right: 130px;
`;
