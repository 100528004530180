/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { compose, path } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';

import { withRouter, matchPath } from 'react-router-dom';
// import { matchPath } from 'react-router';


import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Selectors } from '../../data/ducks/dap';
import { Modals } from '../../data/ducks';

import backgroundSmall from '../../images/materials-header-s.jpg';
import backgroundLarge from '../../images/materials-header-l.jpg';

import Modal from './NeoModal';
import Button from '../shared/buttons/MainButton';
import EventReporter, { Events } from '../../lib/EventReporter';
import LoadingOverlay from '../shared/LoadingOverlay';

import { colors, breakPoints } from '../../styles';

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  min-height: 525px;
`;

const Header = styled.div`
  background-image: url(${backgroundSmall});
  background-color: rgba(0, 0, 0, 0.2);
  background-blend-mode: color;
  background-position-y: -84px;
  background-size: cover;
  min-height: 140px;
  overflow: hidden;
  position: relative;

  ${breakPoints.smallTablet} {
    height: 140px;
  }

  h1  {
    color: #FFF;
    font-size: 32px;
    margin: 40px 0 0 0;
    position: relative;
    text-align: center;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, .3);
    text-transform: uppercase;
    width: 100%;
  }

  h2 {
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    position: relative;
    text-align: center;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, .3);
    width: 100%;
  }

  ${breakPoints.smallTablet} {
    background-image: url(${backgroundLarge});

    h1 {
      font-size: 42px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledTabs = styled.div`
  display: flex;
  margin: 0 0 30px 0;
  width: 100%;

  .tab {
    color: ${colors.physical};
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 0;
  }

  ${breakPoints.smallTablet} {
    .tab {
      font-size: 16px;
    }
  }
`;

const List = styled.ul`
  color: #9B9B9B;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin: 0 auto 50px;
  padding-left: 20px;
  vertical-align: top;
  white-space: normal;
  width: 180px;
  text-transform: lowercase;
  text-align: left;

  display: none;

  ${props => props.show && css`
    display: block;
  `};

  li {
    max-width: 180px;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  h2 {
    color: ${colors.physical};
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    padding: 50px 0 10px 0;
    text-align: center;
  }
`;

const ListContainer = styled.div`
  color: #9B9B9B;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;

  height: calc(100vh - 200px);
  min-height: 250px;
  overflow: scroll;

  ${breakPoints.smallTablet} {
    height: auto;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

class MaterialsModal extends React.Component {
  state = { selectedTab: 0 };

  componentDidMount() {
    this.init(this.props);
    this.setEvents(this.props);
  }

  setEvents = ({ source }) => {
    EventReporter.view(Events.DAP_MATERIALS({ source }));
  }

  init = ({ source, history }) => {
    const match = matchPath(history.location.pathname, {
      path: '/dap/:planId/:day?',
      exact: false,
      strict: false,
    });
    const currentDay = Number(path(['params', 'day'], match)) || 1;

    if (source === 'icon') {
      this.setState({ selectedTab: currentDay - 1 });
    } else {
      this.setState({ selectedTab: currentDay });
    }
  }

  tabSelected = selectedTab => this.setState({ selectedTab });

  renderTabs = (materials) => {
    const { t } = this.props;
    return (
      <StyledTabs
        indicatorColor={colors.cognitive}
      >
        {materials.map((day, i) => (
          <Button
            key={i}
            color="white"
            className="tab"
            onClick={() => this.tabSelected(i)}
            style={{
              borderBottom: this.state.selectedTab === i ? `2px solid ${colors.cognitive}` : '',
            }}
          >
            {`${t('common:DAY')} ${i + 1}`}
          </Button>
        ))}
      </StyledTabs>
    );
  }

  renderList = materials => materials.map((day, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <List key={i} show={this.state.selectedTab === i}>
      {day.map((d, j) => <li key={j}>{d}</li>)}
    </List>
  ));

  render() {
    const {
      materials, onClose, loading, t,
    } = this.props;
    return (
      <Wrapper width={750} closeButtonColor="white" roundedCorners>
        {() => (
          <Fragment>
            <Header>
              <h1>{t('MODAL_MATERIAL.TITLE')}</h1>
              <h2>{t('MODAL_MATERIAL.SUBTITLE')}</h2>
            </Header>
            {materials
              && (
              <Content>
                {this.renderTabs(materials)}
                <ListContainer>
                  {materials.length === 0 ? t('MODAL_MATERIAL.EMPTY_STATE') : this.renderList(materials)}
                </ListContainer>
                <ButtonContainer>
                  <Button color="green" maxWidth={300} onClick={onClose}>
                    {t('common:GOT_IT')}
                  </Button>
                </ButtonContainer>
              </Content>
              )
            }
            <LoadingOverlay loading={loading} />
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

MaterialsModal.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.array),
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  t: PropTypes.func,
};

MaterialsModal.defaultProps = {
  materials: null,
  loading: false,
  onClose: undefined,
  t: () => { },
};

const mapStateToProps = (state, props) => {
  const activePlan = Selectors.activePlan(state);
  return {
    activePlan,
    materials: Selectors.materialsForPlan(state, props.planId),
    loading: state.dap.materials.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  openModal: bind(Modals.Creators.openModal, dispatch),
  closeModal: bind(Modals.Creators.closeModal, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation([Namespaces.MODALS, Namespaces.COMMON]),
  withRouter,
)(MaterialsModal);
