import i18n from 'i18next';
// import ICU from 'i18next-icu';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';
import { initReactI18next } from 'react-i18next';
import { KEY_LOCALE } from 'shared-values';
import i18nCalendar from 'components/views/MyClassroom/CalendarTab/i18n';
import { toUpperLowerCase } from './lib/utils';
import { SectionNamespaces } from './values/i18n';
import { LOCALES_PATH } from './config';

// i18n with ICU for Calendar

const currentLocale = window.localStorage.getItem(KEY_LOCALE) || 'en';

const LOCALE = 'en';
moment.locale(LOCALE);
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: LOCALES_PATH,
    },
    lng: currentLocale,

    fallbackLng: LOCALE,
    debug: false,
    ns: SectionNamespaces,
    defaultNS: 'common',

    interpolation: {
      escapeValue: false,
      format(value, format) {
        if (format === 'upperLower') {
          return toUpperLowerCase(value);
        }
        if (value instanceof Date) return moment(value).format(format);

        return value;
      },
    },
    // react i18next special options (optional)
    react: {
      // wait: false, // set to true if you like to wait for loaded in every translated hoc
      // nsMode: 'default', // set it to fallback to let passed namespaces to translated hoc act as fallbacks
      useSuspense: false, // FIXME: This should not disabled
    },
  });

i18n.changeLanguage(currentLocale);

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
  // Change language in i18n Calendar Instance.
  i18nCalendar.changeLanguage(lng);
});

export default i18n;
