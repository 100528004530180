import ReactGA from 'react-ga';
import { GA_ID, DEBUG_GOOGLE_ANALYTICS, GOOGLE_OPTIMIZE_CONTAINER } from '../config';

ReactGA.initialize(GA_ID, { debug: DEBUG_GOOGLE_ANALYTICS });
/**
 * The following line is used to add the Google Optimize complement in the Analytics snippet.
 */
ReactGA.ga('require', GOOGLE_OPTIMIZE_CONTAINER);

export default ReactGA;
