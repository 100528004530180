import React from 'react';
import styled, { css } from 'styled-components';
import { breakPoints, colors } from '../../styles';
import Plan1MonthImg from '../../images/PremiumGiftPlan_1Month.svg';
import Plan12MonthImg from '../../images/PremiumGiftPlan_12Month.svg';

export const PremiumPlanButton = styled.div`
  width: 300px;
  height: 162px;
  background-size: cover;
  background-position-y: center;
  box-shadow: 0 4px 15px -5px rgba(0,0,0,.2);
  border-radius: 16px;
  font-weight: bold;
  padding: 20px 18px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 10px;
  .plan-price {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;
  }
  .money-sign {
    font-size: 30px;
  }
  .quantity {
    font-size: 40px;
    line-height: 0;
    margin-left: 2px;
    letter-spacing: -3px;
  }
  .currency {
    font-size: 12px;
    margin-left: 4px;
  }
  .plan-duration {
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 34px;
  }
  
  ${breakPoints.smallTablet} {
    padding: 29px 17px;
    height: 200px;
    background-position-y: bottom;
    margin-bottom: 0;
    .plan-price {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 8px;
    }
    .quantity {
      font-size: 50px;
    }
    .currency {
      font-size: 16px;
    }
    .plan-duration {
      font-size: 24px;
    }
  }
`;

export const PremiumPlan1Month = styled(PremiumPlanButton)`
  background-image: url(${Plan1MonthImg});
  color: #D1296D;
`;

export const PremiumPlan12Month = styled(PremiumPlanButton)`
  background-image: url(${Plan12MonthImg});
  color: #0086D8;
`;

const Package = ({ title, subtitle, selected, onClick }) => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 165px;
    height: 62px;

    color: ${colors.white};
    background-color: ${colors.kinedu};
    box-shadow: 0 3px 0 ${colors.kineduShade};

    ${selected && css `
      background-color: ${colors.cognitive};
      box-shadow: 0 3px 0 ${colors.cognitiveDark50};
    `};

    border-radius: 4px;
    cursor: pointer;

    h2 {
      font-size: 21px;
      font-weight: 500;
      margin: 0px 0 5px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0px;
    }

    @media screen and (max-width: 900px) {
      width: 100%;
      margin: 15px auto;

      h2, p {
        font-size: 18px;
      }
    }
  `;

  return (
    <Container onClick={onClick}>
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </Container>
  );
};

export default Package;
