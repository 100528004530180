import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import Button from '../../shared/buttons/MainButton';
import { colors, breakPoints } from '../../../styles';

import Baby from '../../../images/BabySerious.svg';
import { genderAdposition } from '../../../lib/utils';

const Image = styled.img.attrs({ src: Baby }) `
  width: 132px;
  height: 182px;
  margin-bottom: 10px;

  ${breakPoints.smallTablet} {
    margin-bottom: 0px;
  }
`;

const Title = styled.h2`
  color: ${colors.kinedu};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  letter-spacing: -0.55px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  ${breakPoints.smallTablet} {
    padding: 30px 50px;
  }
`;

const Description = styled.div`
  color: ${colors.dustyGray};
  font-size: 16px;
  margin-bottom: 16px;
  text-align: center;
  line-height: 21px;

  ${breakPoints.smallTablet} {
    margin: 20px 35px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BeforeYouGoView = ({ t, closeModal, saveProgress, continueAction, baby }) => {
  const handleClick = (action) => {
    action();
    closeModal();
  };

  return (
    <Content>
      <Title>{t('MODAL_BEFORE_YOU_GO.TITLE')}</Title>
      <Body>
        <Image />
        <Description>
          {t('MODAL_BEFORE_YOU_GO.DESCRIPTION', { babyName: baby.name, adpos: genderAdposition(baby.gender) })}
        </Description>
      </Body>
      <Button color="green" style={{ maxWidth: '280px' }} onClick={() => handleClick(saveProgress)}>
        {t('MODAL_BEFORE_YOU_GO.SAVE_PROGRESS')}
      </Button>
      <Button color="clear" style={{ maxWidth: '213px' }} onClick={() => handleClick(continueAction)}>{t('MODAL_BEFORE_YOU_GO.DONT_SAVE')}</Button>
    </Content>
  );
};

BeforeYouGoView.propTypes = {
  t: PropTypes.func,
  closeModal: PropTypes.func,
  saveProgress: PropTypes.func,
};

BeforeYouGoView.defaultProps = {
  t: () => {},
  closeModal: () => {},
  saveProgress: () => {},

};

export default withTranslation([Namespaces.MODALS])(BeforeYouGoView);
