import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import FullModal from '../modals/FullModal';
import { colors, breakPoints } from '../../styles';
import babyPlaying from '../../images/BabyPlaying.png';
import babyPremature from '../../images/BabyPremature.png';
import Texture from '../../images/BgStars.svg';

import BigButton from '../shared/buttons/BigButton';
import { genderPronoun, genderAdposition } from '../../lib/utils';

import { Creators } from '../../data/ducks/babiesAndFamilies';
import EventReporter, { Events } from '../../lib/EventReporter';

const ModalContainer = styled(FullModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-direction: row;
  flex-wrap: wrap;

  padding: 20px;
  background-color: ${colors.kineduShade};
  background-image: url(${Texture});

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${breakPoints.smallTablet} {
    flex-direction: column;
  }
`;

const Title = styled.span`
  color: ${colors.white};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 26px;
  margin: 20px 0;
  max-width: 333px;
`;

const Description = styled.p`
  color: ${colors.white};
  font-size: 16px;
  letter-spacing: -0.5px;
  text-align: center;
  line-height: 24px;

  margin: 20px 0;
  max-width: 380px;
`;

const BebeImg = styled.img.attrs({ src: ({ src }) => src })`
  width: 82px;
  width: ${({ isPremature }) => (isPremature ? '70px' : '115px')};
  height: auto;
  margin: 0 0 10px 0;
`;

const ButtonsContainer = styled.div`
  width: 100%;
`;

/*
  Dispatch example:

  {
    type: 'MODAL_OPENED',
    name: 'BabyAgeChangedModal',
    data: {
      isPremature: false,
      baby: {}
    },
  }

*/

const BabyAgeChangedModal = ({ isPremature, baby, updateBabyProgress, resetBabyProgress, t }) =>
  (<ModalContainer showCloseButton={false}>
    {({ closeModal }) => (
      <Fragment>
        <Title>{t('MODAL_BABY_AGE_CHANGED.TITLE', {
          context: isPremature ? 'premature' : '',
          babyName: baby.name,
          adpos: genderAdposition(baby.gender),
        })}</Title>
        <BebeImg src={isPremature ? babyPremature : babyPlaying} isPremature={isPremature} />
        <Description>{t('MODAL_BABY_AGE_CHANGED.DESCRIPTION', {
          context: isPremature ? 'premature' : '',
          babyName: baby.name,
          devAge: baby.developmental_age_in_months,
          pronoun: genderPronoun(baby.gender),
          adpos: genderAdposition(baby.gender),
        })}</Description>
        <ButtonsContainer>
          <BigButton
            color="green"
            title={t('MODAL_BABY_AGE_CHANGED.UPDATE_TITLE')}
            description={t('MODAL_BABY_AGE_CHANGED.UPDATE_DESC', {
              pronoun: genderPronoun(baby.gender),
            })}
            maxWidth={300}
            onClick={() => {
              updateBabyProgress(baby.id,
                baby.past_age_in_months,
                baby.age_in_months,
              );
              EventReporter.action(Events.MENU_CHANGE_BIRTHDAY({
                action: 'update_IA',
              }));
              closeModal();
            }}
            style={{ margin: '10px auto' }}
          />
          <BigButton
            color="white"
            title={t('MODAL_BABY_AGE_CHANGED.RESET_TITLE')}
            description={t('MODAL_BABY_AGE_CHANGED.RESET_DESC')}
            maxWidth={300}
            onClick={() => {
              resetBabyProgress(baby.id);
              EventReporter.action(Events.MENU_CHANGE_BIRTHDAY({
                action: 'redo_IA',
              }));
              closeModal();
            }}
            style={{ margin: '10px auto' }}
          />
        </ButtonsContainer>
      </Fragment>
    )}
  </ModalContainer>
  );

BabyAgeChangedModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  t: PropTypes.func,
};

BabyAgeChangedModal.defaultProps = {
  title: '',
  description: '',
  t: () => { },
};

const mapDispatchToProps = dispatch =>
  bind({
    updateBabyProgress: Creators.updateBabyProgressRequested,
    resetBabyProgress: Creators.resetBabyProgressRequested,
  }, dispatch);

export default compose(
  connect(() => ({}), mapDispatchToProps),
  withTranslation([Namespaces.MODALS, Namespaces.COMMON]),
)(BabyAgeChangedModal);
