import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledActionBadge = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 3px 8px;
  margin: 3px 0 3px 5px;
  border-radius: 100px;
  font-size: 11px;
  font-weight: bold;
  text-align: right;
  user-select: none;
  transition: all 0.5s ease-out;

  cursor: pointer;
  pointer-events: all;

  ${({ top }) => `top: ${top}px;`}
  ${({ styles }) => styles}
`;

const ActionBadge = ({ title, action, styles }) => (
  <Wrapper>
    <StyledActionBadge onClick={action} styles={styles}>
      {title}
    </StyledActionBadge>
  </Wrapper>
);

export default ActionBadge;
