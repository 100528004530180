import BaseApi, { localeFlag, setParam, BaseApiV3, BaseApiV4 } from './BaseApi';

// Catalog endpoints
export const getTagsByArea = ({ areaId, forAge = '', page = 1, perPage = 20 }) =>
  BaseApi.get(`/catalogue/tags?in_area=${areaId}&for_age=${forAge}&page=${page}&per_page=${perPage}&${localeFlag()}`);
export const getActivities = ({ skillId = '', interestId, babyId = '', forAge = '', fromAge = '', page = 1, perPage = 20 }) =>
  BaseApi.get(`/catalogue/activities?per_page=${perPage}&page=${page}&skill_id=${skillId}${setParam('interest_id', interestId)}${setParam('for_age', forAge)}${setParam('from_age', fromAge)}&baby_id=${babyId}&${localeFlag()}`);
export const getArticles = ({ skillId = '', interestId, babyId = '', forAge = '', fromAge = '', page = 1, perPage = 20 }) =>
  BaseApi.get(`/catalogue/articles?per_page=${perPage}&page=${page}&skill_id=${skillId}${setParam('interest_id', interestId)}&for_age=${forAge}${setParam('from_age', fromAge)}&baby_id=${babyId}&${localeFlag()}`);
export const searchContent = ({ minAge, maxAge }) =>
  BaseApi.get(`/catalogue/search?text=teething&min_age=${minAge}&max_age=${maxAge}&items=slideshow,article,activity`);
export const getContent = ({ skillId = '', interestId, babyId = '', forAge = '', fromAge = '', page = 1, perPage = 20 }) =>
  BaseApi.get(`/catalogue/contents?${setParam('skill_id', skillId)}${setParam('interest_id', interestId)}${setParam('for_age', forAge)}${setParam('from_age', fromAge)}&baby_id=${babyId}&page=${page}&per_page=${perPage}&${localeFlag()}`);
export const getCatalogFilters = () => BaseApi.get(`/catalogue/catalog_filters?${localeFlag()}`);

export const search = ({
  babyId, text, minAge, maxAge, items, areaIds, skillIds, perPage = 18, page = 1,
}) => BaseApi.get(`/catalogue?baby_id=${babyId}&${localeFlag()}`
  + `${setParam('text', text)}`
  + `${setParam('min_age', minAge)}`
  + `${setParam('max_age', maxAge)}`
  + `${setParam('items', items)}`
  + `${setParam('area_ids', areaIds)}`
  + `${setParam('skill_ids', skillIds)}`
  + `${setParam('per_page', perPage)}`
  + `${setParam('page', page)}`);

// Favorite endpoints
export const getFavoriteActivities = () => BaseApi.get('/activities/favorites');
export const getFavoriteArticles = () => BaseApi.get('/articles/favorites');
export const getFavoriteSlideshows = () => BaseApi.get('/slideshows/favorites');
export const getFavoritePrenatalActivities = ({ page }) => BaseApi.get(`prenatal_activities/favorites?page=${page}`);
export const getFavoritePrenatalArticles = ({ page }) => BaseApi.get(`/prenatal_articles/favorites?page=${page}`);
export const getFavoritePrenatalSlideshows = ({ page }) => BaseApi.get(`/prenatal_slideshows/favorites?page=${page}`);

// Comments endpoints
export const getComments = ({ resource, resourceId }) => BaseApi.get(`/${resource}/${resourceId}/comments`);
export const postComments = ({ resource, resourceId }) => BaseApi.get(`/${resource}/${resourceId}/comments`);
export const postFlagComment = ({ resource, resourceId, commentId }) => BaseApi.get(`/${resource}/${resourceId}/comments/${commentId}/flag`);

export const RESOURCES = {
  ACTIVITIES: 'activities',
  ARTICLES: 'articles',
  SLIDESHOWS: 'slideshows',
  PRE_ACTIVITIES: 'prenatal_activities',
  PRE_ARTICLES: 'prenatal_articles',
  PRE_SLIDESHOWS: 'prenatal_slideshows',
};


export const postFeedbackSearch = (params = {}, data = {}) => BaseApiV3.post('/catalogue/feedback', data, {
  params,
});

// {{url_v4}}/master_class_feedbacks?master_class_id=2&user_id={{user_id}}&value_in_the_content_score=1&format_score=1&recommend_master_class_score=1&comment=Comentario

export const postFeedbackMasterClass = (feedback = {}, params = {}) => BaseApiV4.post('/master_class_feedbacks', feedback, {
  params,
});
