import { combineEpics } from 'redux-observable';

import appInit from './appInit';
import * as surveys from './surveys';
import logout from './logout';
import OB2IA from './OB2IA';

export default combineEpics(
  ...Object.values({
    ...surveys,
  }),
  appInit,
  logout,
  OB2IA,
);
