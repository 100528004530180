import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NeoModal from './NeoModal';
import BabyTurns24 from '../views/BabyTurns24';

/*
Dispatch example:

{
    type: 'MODAL_OPENED',
    name: 'BabyTurns24Modal',
    data: {
    },
}

*/

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BabyTurns24Modal = ({ baby }) => (
  <Modal width={856} showCloseButton={false}closeOnBackdrop={false} roundedCorners>
    {({ closeModal }) => (
      <Fragment>
        <BabyTurns24 baby={baby} closeModal={closeModal} />
      </Fragment>
    )}
  </Modal>
);

BabyTurns24Modal.propTypes = {
  baby: PropTypes.object,
};

BabyTurns24Modal.defaultProps = {
  baby: {},
};

export default BabyTurns24Modal;
