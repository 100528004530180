import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { bindActionCreators as bind, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Transition from 'react-transition-group/Transition';
import { connect } from 'react-redux';

import EventReporter, { Events } from '../../lib/EventReporter';

import { Namespaces } from '../../values/i18n';
import { colors } from '../../styles';
import NavItem from './NavItem';
import BabyImage from './BabyImage';
import BabyPicker from './BabyPicker';

import { Selectors as BabySelectors } from '../../data/ducks/babiesAndFamilies';
import { Creators as ModalCreators } from '../../data/ducks/modals';
import { Creators as ClassroomCreators } from '../../data/ducks/babiesAndFamilies/babies';

import TopBar from './Topbar';
import { BabyAvatar } from '../../values/families';
import { APP_SECTIONS_PATHS } from '../../router/AuthGuardedRoutes';

const root = document.getElementById('root');

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  background-color: ${colors.kinedu};
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.27);
  transition: all .5s ease-in-out;
  z-index: 9999;

  ${({ theme }) => theme.breakPoints.tablet} {
    width: ${props => (props.open ? '256px' : '80px')};
    background-color: ${props => (props.open ? colors.shadeLight : colors.kinedu)};
  }
`;

const NavContainer = styled.div`
`;

const NavContent = styled.div`
  background-color: ${colors.kinedu};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media only screen and (max-width: 600px) {
    position: fixed;
    top: 55px;
    left: ${props => (props.open ? '0' : '-306px')};
    width: 306px;
    height: calc(100% - 55px);
    background-color: ${colors.shadeLighter};
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.27);
    transition: all 0.5s ease;
    z-index: 9;
  };
`;

const Overlay = styled.div`
  display: ${props => (props.open ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  z-index: 0;
  opacity: ${props => (props.open ? '1' : '0')};

  transition: opacity 0.3s ease-out;

  ${({ theme }) => theme.breakPoints.tablet} {
    display: none;
    opacity: 0;
  }
`;

class Nav extends Component {
  state = { open: false, activeFamilyId: null }

  componentDidMount() {
    if (this.props?.activeBaby?.id) {
      this.props.updateBabyClassroom(this.props.activeBaby.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeBaby?.id !== this.props.activeBaby?.id) {
      this.props.updateBabyClassroom(this.props.activeBaby.id);
    }
  }

  toggle = () => this.setState({ open: !this.state.open })

  onClickCatalog = (mobile) => {
    EventReporter.action(Events.CATALOG());
    if (mobile) this.toggle();
    return () => { };
  }

  render() {
    const {
      t, activeBaby, mobile, myRole, contentBodyRef, mustHaveBack, history, activeFamilyIndex,
    } = this.props;

    if (this.state.open && contentBodyRef && mobile) {
      contentBodyRef.style.setProperty('overflow', 'hidden');
      contentBodyRef.style.setProperty('position', 'fixed');
      root.style.setProperty('overflow', 'hidden');
      root.style.setProperty('position', 'fixed');
    } else {
      if (contentBodyRef) {
        contentBodyRef.style.setProperty('position', 'static');
        contentBodyRef.style.setProperty('overflow', 'scroll');
      }

      root.style.setProperty('overflow', 'visible');
      root.style.setProperty('position', 'absolute');
    }

    return (
      <ThemeProvider theme={{ ...this.state, mobile }}>
        <Container open={this.state.open}>
          <Overlay open={this.state.open} mobile={mobile} onClick={this.toggle} />
          <TopBar
            open={this.state.open}
            toggle={this.toggle}
            goBack={history.goBack}
            mustHaveBack={mustHaveBack}
          />
          <NavContent open={this.state.open}>
            <Transition
              in={this.state.open}
              timeout={500}
            >
              {state => (
                <BabyPicker
                  show={state}
                  myRole={myRole}
                  onClick={mobile ? this.toggle : () => { }}
                  defaultFamily={activeFamilyIndex}
                  push={this.props.history.push}
                />
              )
              }
            </Transition>
            <Transition
              in={this.state.open}
              timeout={500}
            >
              {state => (
                <BabyImage
                  show={state}
                  picture={activeBaby.avatar.size1 === 'missing'
                    ? BabyAvatar[activeBaby.gender] : activeBaby.avatar.size1}
                />
              )}
            </Transition>
            <Transition
              in={this.state.open}
              timeout={500}
            >
              {state => (
                <NavContainer>
                  <NavItem state={state} text={t('NAV_TODAY')} icon="activities" iconSize={30} to="/tap" onClick={mobile ? this.toggle : () => { }} />
                  {/* <NavItem state={state} text={t('NAV_CATALOG')} icon="article" iconSize={32} to="/catalog" onClick={() => this.onClickCatalog(mobile)} /> */}
                  <NavItem state={state} text={t('NAV_CATALOG')} badge={t('NEW')} icon="lens" iconSize={32} to={APP_SECTIONS_PATHS.CATALOG} onClick={() => this.onClickCatalog(mobile)} />
                  <NavItem state={state} text={t('NAV_PROGRESS')} icon="progress" iconSize={32} to="/progress" onClick={mobile ? this.toggle : () => { }} />
                  <NavItem
                    state={state}
                    text={t('NAV_MY_CLASSROOM')}
                    icon="bulb"
                    iconSize={32}
                    to="/classrooms"
                    onClick={mobile ? this.toggle : () => { }}
                  />
                  <NavItem state={state} text={t('NAV_FAMILY')} icon="family" to={APP_SECTIONS_PATHS.FAMILIES} iconSize={32} onClick={mobile ? this.toggle : () => { }} />
                  <NavItem state={state} text={t('NAV_MILESTONES')} icon="neo-milestones" iconSize={30} to="/milestones/" onClick={mobile ? this.toggle : () => { }} />
                  <NavItem state={state} text={t('NAV_SETTINGS')} icon="settings" iconSize={35} to="/settings" onClick={mobile ? this.toggle : () => { }} />
                </NavContainer>
              )}
            </Transition>
          </NavContent>
        </Container>
      </ThemeProvider>
    );
  }
}

Nav.propTypes = {
  mobile: PropTypes.bool,
  activeBaby: PropTypes.shape({
    avatar: PropTypes.object,
  }),
};
Nav.defaultProps = {
  mobile: false,
  activeBaby: { avatar: {} },
};

const mapStateToProps = state => ({
  activeBaby: BabySelectors.activeBaby(state),
  myRole: BabySelectors.myRole(state),
  activeFamilyIndex: BabySelectors.getActiveFamilyIndex(state),
  classroomData: BabySelectors.classroomStatus(state),
});

const mapDispatchToProps = dispatch => bind({
  openSettings: () => ModalCreators.openModal({ name: 'SettingsModal' }),
  updateBabyClassroom: ClassroomCreators.getBabyClassroomRequested,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(Namespaces.COMMON),
  withRouter,
)(Nav);
