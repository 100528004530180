
export const TIMEOUT = 30000;
export const DEFAULT_HEADERS = { 'Content-Type': 'application/json' };

export const apiResponseInterceptor = (res) => res.data;

export const apiErrorInterceptor = (error) => {
  if (error.response) {
    switch (error.response.status) {
      // case 401: {
      //   appLogout();
      //   redirectToRoot();
      //   console.error('UNAUTHORIZED', error.response.data);
      //   break;
      // }
      case 422: console.error('UNPROCESSABLE_ENTITY', error.response.data);
        break;
      case 500: console.error('SERVER_ERROR', error.response.data);
        break;
      default:
        console.error(error.response.data);
    }
  }
  if (error.code === 'ECONNABORTED') {
    // toast.error(i18n.t('validations:RESPONSE_ERRORS.TIMEOUT'), { autoClose: 5000 });
  }
  return Promise.reject(error);
};

export const apiRequestInterceptor = (config) => config;
