import { defaultTo, uniq } from 'ramda';
import { Reduxsauce, DEEP } from '../redux-sauce';
import { ContentTypes } from '../../shared-values';

export const Types = Reduxsauce.createTypes(`
    fetchActivityRequested
    fetchActivitySuccess
    fetchActivityError
    
    fetchCustomActivityRequested
    fetchCustomActivitySuccess
    fetchCustomActivityError

    fetchArticleRequested
    fetchArticleSuccess
    fetchArticleError
    
    fetchUnTokenArticleRequested
    fetchUnTokenArticleSuccess
    fetchUnTokenArticleError

    fetchSlideshowRequested
    fetchSlideshowSuccess
    fetchSlideshowError

    toggleFavoriteRequested
    toggleFavoriteSuccess
    toggleFavoriteError
  `);

export const Creators = {
  fetchActivity: ({ activityId, planId }) => ({
    type: Types.fetchActivityRequested,
    activityId,
    planId,
  }),
  fetchCustomActivity: ({ activityId }) => {
    console.log('%c activityId', 'background: #332167; color: #B3D1F6; font-size: 16px', activityId);
    return ({
      type: Types.fetchCustomActivityRequested,
      activityId,
    });
  },
  fetchArticle: id => ({ type: Types.fetchArticleRequested, id }),
  fetchUnTokenArticle: id => ({ type: Types.fetchUnTokenArticleRequested, id }),
  fetchSlideshow: id => ({ type: Types.fetchSlideshowRequested, id }),
  /**
   * @param {number} id - contentId
   * @param {String} contentType - activity, article or slideshow.
   */
  toggleFavorite: ({ id, contentType }) => ({
    type: Types.toggleFavoriteRequested,
    id,
    contentType,
  }),
};

const getActivityById = (state, id) => state.content.activities[id];
const getCustomActivityById = (state, id) => state.content.activities[id];
const getArticleById = (state, id) => state.content.articles[id];
const getUnTokenArticleLinkById = (state, id) => {
  const article = state.content.articles[id];

  if (!article) return undefined;

  return article.link;
};
const getSlideshowById = (state, id) => state.content.slideshows[id];
const contentIdIsAllowed = (state, type, id) => {
  /* All the articles and slideshows must be available for all users
    so, just validate activities
  */
  return true; // FIXME:NOTE: Temporarily show any content
  // eslint-disable-next-line
  if (type === ContentTypes.ACTIVITY) {
    return defaultTo([], state.content.allowedContentIds[type]).includes(id);
  }
  return true;
};

export const Selectors = {
  getActivityById,
  getCustomActivityById,
  getArticleById,
  getUnTokenArticleLinkById,
  getSlideshowById,
  contentIdIsAllowed,
};

// ------- State -------- //
const InitialState = Reduxsauce.createState({
  loading: false,
  error: undefined,
  articles: {},
  activities: {},
  slideshows: {},
  allowedContentIds: {
    activity: [],
    article: [],
    slideshow: [],
  },
});

// todo: cambiar todos los errores lol
export const Reducer = Reduxsauce.createReducer(InitialState, {
  RESET: () => InitialState,
  // Activity ducks. Ugly but merge doesn't work that well with arrays.
  FETCH_PLAN_SUCCESS: (state, { plan }) => state.setIn(['allowedContentIds'], {
    activity: uniq(state.allowedContentIds.activity.concat(plan.contentIds.activity)),
    article: uniq(state.allowedContentIds.article.concat(plan.contentIds.article)),
    slideshow: uniq(state.allowedContentIds.slideshow.concat(plan.contentIds.slideshow)),
  }),
  CHANGE_ACTIVITY_SUCCESS: (state, action) => state.setIn(['allowedContentIds', 'activity'], state.allowedContentIds.activity.concat([action.content.id])),
  // # Activity
  [Types.fetchActivityRequested]: state => state.merge({ activities: { loading: true } }, DEEP),
  [Types.fetchActivitySuccess]: (state, { activity }) => state.merge(
    {
      activities: {
        [activity.id]: activity,
        loading: false,
        error: false,
      },
    },
    DEEP,
  ),
  [Types.fetchActivityError]: (state, err) => state.merge({ activities: { loading: false, error: err } }, DEEP),
  // # Custom Activity
  [Types.fetchCustomActivityRequested]: state => state.merge({ activities: { loading: true } }, DEEP),
  [Types.fetchCustomActivitySuccess]: (state, { activity }) => state.merge(
    {
      activities: {
        [activity.id]: activity,
        loading: false,
        error: false,
      },
    },
    DEEP,
  ),
  [Types.fetchCustomActivityError]: (state, err) => state.merge({ activities: { loading: false, error: err } }, DEEP),
  // # Article
  [Types.fetchArticleRequested]: state => state.merge({ articles: { loading: true } }, DEEP),
  [Types.fetchArticleSuccess]: (state, { article }) => state.merge(
    {
      articles: {
        [article.id]: article,
        loading: false,
        error: false,
      },
    },
    DEEP,
  ),
  [Types.fetchArticleError]: (state, err) => state.merge({ articles: { loading: false, error: err } }, DEEP),
  // # TOKEN
  [Types.fetchUnTokenArticleRequested]: state => state.merge({ articles: { loading: true } }, DEEP),
  [Types.fetchUnTokenArticleSuccess]: (state, { article }) => state.merge(
    {
      articles: {
        [article.id]: article,
        loading: false,
        error: false,
      },
    },
    DEEP,
  ),
  [Types.fetchUnTokenArticleError]: (state, err) => state.merge({ articles: { loading: false, error: err } }, DEEP),
  // # Slideshow
  [Types.fetchSlideshowRequested]: state => state.merge({ slideshows: { loading: true } }, DEEP),
  [Types.fetchSlideshowSuccess]: (state, { slideshow }) => state.merge(
    {
      slideshows: {
        [slideshow.id]: slideshow,
        loading: false,
        error: false,
      },
    },
    DEEP,
  ),
  [Types.fetchSlideshowError]: (state, err) => state.merge({ slideshows: { loading: false, error: err } }, DEEP),
  [Types.toggleFavoriteSuccess]: (state, action) => {
    const favedStatus = state[action.contentType][action.id].faved;
    return state.merge(
      {
        [action.contentType]: {
          [action.id]: {
            faved: !favedStatus,
          },
        },
      },
      DEEP,
    );
  },
});
