import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import Baby from '../../../images/BabyWithRocket.png';
import Texture from '../../../images/Texture3.png';

import MainButton from '../../shared/buttons/MainButton';
import Title from '../../shared/Title';
import { colors } from '../../../styles';
import { genderNominativePronoun } from '../../../lib/utils';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 14px;
  box-sizing: border-box;

  background-image: url(${Texture});
  background-color: ${colors.shadeLight};
`;

const Image = styled.img.attrs({ src: props => props.src })`
  width: 150px;
  height: 162px;
  margin: 10px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: ${colors.kinedu};
  font-size: 24px;
  font-weight: bold;
  margin: 0px 0 40px;

  p {
    margin: 0;
    font-weight: bold;
  }
`;

const Description = styled.p`
  color: ${colors.kinedu};
  font-size: 20px;
  line-height: 26px;
  max-width: 320px;
  text-align: center;
`;

const KeepOn = ({
  t, baby, closeModal, activeMilestones, completedMilestones,
}) => (
  <Wrapper>
    <Title
      align="center"
      size={36}
      color={colors.kinedu}
      style={{ margin: '20px 0', maxWidth: '337px' }}
      spacing="-0.62px"
      bold
    >
      {t('DOPAMINE.KEEP_ON.TITLE')}
    </Title>
    <Image src={Baby} />
    <Description>
      {t('DOPAMINE.KEEP_ON.DESCRIPTION', { nomPronoun: t(`common:${genderNominativePronoun(baby.gender)}`) })}
    </Description>
    <Info>
      <p>{t('DOPAMINE.MILESTONE_STATUS', { current: completedMilestones, total: activeMilestones })}</p>
      <p>{t('DOPAMINE.MILESTONES_COMPLETED')}</p>
    </Info>
    <MainButton color="green" onClick={closeModal} maxWidth={300}>Continue</MainButton>
  </Wrapper>
);

KeepOn.propTypes = {
  baby: PropTypes.object,
  activeMilestones: PropTypes.number,
  completedMilestones: PropTypes.number,
  closeModal: PropTypes.func,
  t: PropTypes.func,
};

KeepOn.defaultProps = {
  baby: {},
  activeMilestones: 0,
  completedMilestones: 0,
  closeModal: () => {},
  t: () => {},
};

export default withTranslation([Namespaces.MILESTONES])(KeepOn);
