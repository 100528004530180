import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import FamiliesContainer from '../../containers/FamiliesContainer';
import BabiesContainer from '../../containers/babiesAndFamilies/BabiesContainer';
import Baby from './Baby';
import AddBaby from './AddBaby';
import Icon from '../shared/Icon';

const FamilyName = styled.h1`
  font-size: 18px;
  color: #1B75BB;
  text-align: center;
  margin: 0;
  max-width: 150px;

  ${({ theme }) => theme.mobile && css`
    height: 55px;
    line-height: initial;
  `};
`;

const SubTitle = styled.h2`
  font-size: 14px;
  color: #657480;
  letter-spacing: 3.11px;
  text-transform: uppercase;
  height: 50px;
  line-height: 50px;
  font-weight: 400;
  text-align: center;
  margin: 0;
`;

const BabyList = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  align-items: flex-start;
  max-height: 300px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${({ theme }) => theme.mobile && css`
    display: block;
    max-height: 270px;
  `};
`;

const Container = styled.div`
  background-color: #F8F8F8;

  transition: all .55s ease-in-out;
  opacity: 0;
  height: 0;
  max-height: 435px;

  ${({ show, theme }) => (show === 'exited' && !theme.mobile) && css`
    display: none;
  `};

  ${({ show, theme }) => (show === 'entered' || theme.mobile) && css`
    opacity: 1;
    height: ${({ rowCount }) => (rowCount * 150) + 50 + 85}px;
  `};

  ${({ theme }) => (theme.mobile) && css`
    height: ${({ rowCount }) => (rowCount * 150) + 50 + 115}px;
  `};

  ${({ theme }) => theme.mobile && css`
    max-height: 405px;
  `};
`;

const FamilyWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 85px;


  #left, #right {
    position: absolute;
    width: 40px;
    height: 86px;
    cursor: pointer;
    fill: #ACC2D3;
  }
  #left {
    left: 20px;
  }

  #right {
    right: 20px;
  }
`;

class BabyPicker extends Component {
  state = { focusedFamily: 0 }

  componentWillMount() {
    this.setState({ focusedFamily: this.props.defaultFamily || 0 });
  }

  setFocusedFamily = (val, familySelected, families) => () => {
    this.setState(({ focusedFamily }) => {
      focusedFamily += val;
      const familyId = families[focusedFamily].id;
      familySelected(familyId);
      return ({ focusedFamily });
    });
    // Everytime the user change his family have to navigate to the current dap
    this.props.push('/dap/current/');
  }

  render() {
    return (
      <FamiliesContainer>
        {({
          families, openPremiumFeature, familySelected, push,
        }) => {
          if (!families.length) return null;
          const {
            t, show, myRole, onClick,
          } = this.props;
          const { focusedFamily } = this.state;
          const selectedFamily = families[focusedFamily] || {};
          return (
            <BabiesContainer
              familyId={selectedFamily.id}
              render={({
                activeBaby, babies, editBaby, addBaby, selectBaby, prenatalBabies,
              }) => {
                const NUMBER_OF_BABIES = babies.length > 5 ? 5 : (babies.length + 1);
                const allBabies = [...babies, ...prenatalBabies];
                if (!activeBaby?.id && babies?.length) {
                  selectBaby(babies[0]?.id);
                  push('/dap/current');
                }
                return (
                  <Container show={show} rowCount={Math.floor(NUMBER_OF_BABIES / 4) + 1}>
                    <FamilyWrapper>
                      {focusedFamily > 0
                        && (
                        <Icon
                          id="left"
                          icon="chevron-thin-left"
                          onClick={this.setFocusedFamily(-1, familySelected, families)}
                        />
                        )}
                      <FamilyName>{t('FAMILY_NAME', { name: selectedFamily.name })}</FamilyName>
                      {focusedFamily < families.length - 1
                        && (
                        <Icon
                          id="right"
                          icon="chevron-thin-right"
                          onClick={this.setFocusedFamily(1, familySelected, families)}
                        />
                        )}
                    </FamilyWrapper>
                    <SubTitle>{t('families:FAMILY_BABIES')}</SubTitle>
                    <BabyList
                      show={show}
                      rowCount={Math.floor(NUMBER_OF_BABIES / 4) + 1}
                      onClick={onClick}
                    >
                      {
                        babies && babies.map(baby => (
                          <Baby
                            key={baby.id}
                            age={baby?.is_premature ? baby?.developmental_age_in_months : baby.age_in_months}
                            name={baby.name}
                            gender={baby.gender}
                            picture={baby.avatar.size1}
                            active={baby.id == activeBaby.id} // eslint-disable-line
                            onEditClicked={() => editBaby(babies?.length >= 2)}
                            onBabySelected={() => {
                              push('/dap/current');
                              selectBaby(baby.id);
                            }}
                            myRole={myRole}
                          />
                        ))
                      }
                      {(allBabies.length < 5 && myRole !== 'CAREGIVER')
                        && (
                        <AddBaby
                          onClick={() => addBaby()}
                        />
                        )
                      }
                    </BabyList>
                  </Container>
                );
              }}
            />
          );
        }}
      </FamiliesContainer>
    );
  }
}

export default withTranslation(Namespaces.MENU, Namespaces.FAMILIES)(BabyPicker);
