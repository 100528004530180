import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import { colors, breakPoints } from '../../../styles';

import Button from '../../shared/buttons/MainButton';
import BgStars from '../../../images/BgStars.svg';
import Calendars from '../../../images/Calendars.svg';
import Stars from '../../../images/Stars.svg';
import Badge from './Badge';
import Light from './Light';

const ThreeDaysInARowContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;

  ${({ type }) => type === 'A' && css`
    background-color: ${colors.kinedu};
    background-image: url(${BgStars});
    background-position-y: 20px;
    background-repeat: no-repeat;
  `}
`;

const Message = styled.div`
  margin-top: 200px;
`;

const TextUp = styled.p`
  color: rgba(0,0,0,0.4);
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0;

  text-transform: uppercase;
  text-align: center;
  opacity: 0.6;

  ${({ type }) => type === 'B' && css`
    color: ${colors.white};
    font-size: 36px;
    text-shadow: 0 2px 3px rgba(0,0,0,0.30);
    opacity: 1;
  `}
`;

const TextDown = styled.p`
  color: ${colors.white};
  font-weight: bold;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 40.5px;
  text-shadow: 0 2px 3px rgba(0,0,0,0.3);
  text-align: center;

  width: 375px;

  ${({ type }) => type === 'B' && css`
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    width: 100%;
    max-width: 315px;

    ${breakPoints.smallTablet} {
      max-width: none;
    }
  `}
`;

const versions = [
  null,
  {
    type: 'B',
    image: Calendars,
    textUp: 'DOPAMINE.THREE_DAYS_IN_A_ROW',
    textDown: 'DOPAMINE.YOU_PLAYED_WITH_BABY',
  },
  {
    type: 'B',
    image: Stars,
    textUp: 'DOPAMINE.THREE_DAYS_IN_A_ROW',
    textDown: 'DOPAMINE.YOU_PLAYED_WITH_BABY',
  },
  {
    type: 'B',
    image: Calendars,
    textUp: 'DOPAMINE.THREE_DAYS_IN_A_ROW',
    textDown: 'DOPAMINE.YOU_PLAYED_WITH_BABY',
  },
  {
    type: 'B',
    image: Stars,
    textUp: 'DOPAMINE.THREE_DAYS_IN_A_ROW',
    textDown: 'DOPAMINE.YOU_PLAYED_WITH_BABY',
  },
];

const ThreeDaysInARow = ({
  version, baby, userRole, onClose, t,
}) => (
  <ThreeDaysInARowContainer type={versions[version].type}>
    <Light disabled={versions[version].type === 'B'}>
      <Badge image={versions[version].image} />
      <Message>
        <TextUp type={versions[version].type}>{t(versions[version].textUp)}</TextUp>
        <TextDown type={versions[version].type}>
          {t(versions[version].textDown, { name: baby.name, role: userRole })}
        </TextDown>
      </Message>
      <Button
        onClick={onClose}
        color="green"
        maxWidth={200}
        style={{ margin: 'auto 0 40px' }}
      >
        {t('DOPAMINE.AWESOME')}
      </Button>
    </Light>
  </ThreeDaysInARowContainer>
);

ThreeDaysInARow.propTypes = {
  /* Number between 1 and 2, versions of the view */
  version: PropTypes.number,
  /* Baby's info */
  baby: PropTypes.object,
  /* User role to display */
  userRole: PropTypes.string,
  /* Translation function */
  onClose: PropTypes.func,
  t: PropTypes.func,
};
ThreeDaysInARow.defaultProps = {
  version: 1,
  baby: {},
  userRole: '',
  onClose: () => {},
  t: () => { },
};

export default withTranslation([Namespaces.DAP])(ThreeDaysInARow);
