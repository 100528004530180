import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../../shared/buttons/MainButton';
import Icon from '../../shared/Icon';
import unlockCardImage from '../../../images/unlock-premium-key.png';
import { colors } from '../../../styles';
import { PaymentSources } from '../../../values/prices/paymentSources';

const OverlayComponents = {
  Separator: styled.div`
    font-size: 16px;
    font-weight: bold;
    margin: 15px;
    color: #fff;
  `,
  Title: styled.h2`
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    margin: 10px;
    max-width: 220px;
    text-align: center;
  `,
  Wrapper: styled.div`
    background-color: rgba(74, 74, 74, 0.7);
    background-color: ${({ bgColor }) => bgColor};
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.25s;
    opacity: ${props => (props.hoverable ? 0 : 1)};

    &.go-premium {
      img {
        height: 60px;
        margin-bottom: -10px;
      }
    }

    :hover {
      opacity: 1;
    }

    .completed-icon {
      fill: #fff;
      background-color: #75b753;
      width: 53px;
      height: 53px;
      padding: 16px;
      border-radius: 100%;
      box-sizing: border-box;
    }

    .button {
      width: 100%;
      min-width: 60%;
      max-width: 230px;

      color: #FFF;
      font-size: 15px;
      font-weight: 500 !important;
import { PaymentSources } from '../../../values/prices/paymentSources';

      flex-basis: 35px;
    }

    .button.white {
      color: #000;
    }
    @media screen and (max-width: 768px) {
      opacity: 1;
    }`,
};

const CardOverlay = ({
  locked,
  completed,
  shareable,
  touched,
  onShareToUnlock,
  onOpen,
  onMarkAsComplete,
  goToPrices,
  context,
  t,
  activityIndex,
}) => {
  const { Separator, Title, Wrapper } = OverlayComponents;
  if (locked && (context === 'catalog' || context === 'skills')) {
    return (
      <Wrapper hoverable className="go-premium">
        <Title>{t('content:UNLOCK_CONTENT')}</Title>
        <Button maxWidth="90%" small color="green" onClick={() => goToPrices()}>
          {t('UPGRADE_NOW')}
        </Button>
      </Wrapper>
    );
  } else if (completed) {
    return (
      <Wrapper onClick={onOpen}>
        <Icon icon="milestones-completed" size={45} color={colors.cognitive} />
        <Title>{t('ACTIVITY_COMPLETED')}</Title>
      </Wrapper>
    );
  } else if (touched) {
    return (
      <Wrapper hoverable>
        <Button maxWidth="90%" small color="green" onClick={onOpen}>
          {t('CONTINUE_ACTIVITY')}
        </Button>
        <Separator>{t('common:OR')}</Separator>
        <Button maxWidth="90%" small color="white" onClick={onMarkAsComplete}>
          {t('MARK_AS_COMPLETED')}
        </Button>
      </Wrapper>
    );
  } else if (locked && shareable) {
    return (
      <Wrapper hoverable>
        <Button maxWidth="90%" small color="green" onClick={() => goToPrices(PaymentSources.DAP_ACTIVITY)}>
          {t('UNLOCK_ACTIVITIES')}
        </Button>
        <Separator>{t('common:OR')}</Separator>
        <Button maxWidth="90%" small color="fb" onClick={onShareToUnlock}>
          {t('SHARE_ON_FB')}
        </Button>
      </Wrapper>
    );
  } else if (locked && !shareable) {
    return (
      <Wrapper hoverable className="go-premium">
        <img
          src={unlockCardImage}
          className="icon"
          alt="go premium"
        />
        <Title>{t('UNLOCK_PREMIUM')}</Title>
        <Button
          maxWidth="90%"
          small
          color="green"
          onClick={() => goToPrices(PaymentSources.DAP_ACTIVITY)}
        >
          {t('UPGRADE_NOW')}
        </Button>
      </Wrapper>
    );
  }

  return null;
};

CardOverlay.propTypes = {
  locked: PropTypes.bool,
  completed: PropTypes.bool,
  shareable: PropTypes.bool,
  touched: PropTypes.bool,
  context: PropTypes.string,
  onOpen: PropTypes.func,
  goToPrices: PropTypes.func,
  onShareToUnlock: PropTypes.func,
  onMarkAsComplete: PropTypes.func,
  t: PropTypes.func,
};

CardOverlay.defaultProps = {
  locked: false,
  completed: false,
  shareable: false,
  touched: false,
  context: '',
  onOpen: () => { },
  goToPrices: () => { },
  onShareToUnlock: () => { },
  onMarkAsComplete: () => { },
  t: () => { },
};

export default CardOverlay;
