import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, css } from 'styled-components';

import ReactSelect from 'react-select';
import 'react-select/dist/react-select.min.css';

import Formsy from 'formsy-react-2';
import { Flat, ThemeShape } from './../themes/NewSelect';

const SelectContainer = styled.div`
  position: relative;
  ${({ theme }) => theme.container}

  ${({ noWrapLabel }) => noWrapLabel && css`
    label {
      white-space: nowrap;
    }
  `};

  ${({ containerStyle }) => containerStyle}

  .Select {
    ${({ styles }) => styles}

    .Select-control {
    ${({ theme }) => theme.control}
    border: 1px solid #F8F8F8;

    .Select-input {
      ${({ theme }) => theme.input}
    }

    .Select-placeholder {
      ${({ theme }) => theme.placeholder}
    }

    .Select-arrow-zone {
      ${({ theme }) => theme.arrow}
    }

    .Select-clear-zone {
      ${({ theme }) => theme.clearZone}
    }
  }
  }

  .has-value.Select--single > .Select-control,
  .has-value.is-pseudo-focused.Select--single > .Select-control {
    .Select-value {
      ${({ theme }) => theme.value}
      ${({ centered }) => centered && css`
        padding-right: 10px !important;
        justify-content: center;
      `}
    }
  }

  .is-focused.Select--single > .Select-control,
  .is-focused:not(.is-open)> .Select-control {
    ${({ theme }) => theme.controlFocused}

    .Select-value {
      ${({ theme }) => theme.valueFocused}
    }
  }

  .Select-menu-outer {
    ${({ theme }) => theme.menu}

    .Select-option {
      ${({ theme }) => theme.option}
      ${({ centered }) => centered && css`text-align: center !important;`}
    }
  }

  .Select-control {
    ${({ theme, valid }) => !valid && theme.inputError}
  }

  .is-open > .Select-control {
    .Select-arrow-zone {
      ${({ theme }) => theme.arrowOpen}
    }
  }

  /*
    The component doesn't have the functionality of opening on top
    of the input when reaches the bottom of its container, so this is a
    workaround to that, but just work by applying the prop not by really
    detecting that it reaches the bottom.
   */
  ${({ openOnTop, theme }) => openOnTop && theme.openOnTopStyle}
`;

const Label = styled.label`${({ theme }) => theme.label}`;
const Error = styled.span`${({ theme }) => theme.error}`;

class Select extends Component {
  state = { value: null };

  updateValue = ({ value }) => {
    this.props.setValue(value);
  }

  render() {
    const {
      theme,
      name,
      options,
      placeholder,
      className,
      label,
      style,
      styles,
      containerStyle,
      centered,
      openOnTop,
      onChange,
      autofocus,
      getValue,
      isPristine,
      isValid,
      errorText,
      showErrorMessage,
      getErrorMessage,
      noResultsText,
      dropdownIcon,
    } = this.props;

    const error = errorText || getErrorMessage();
    return (
      <ThemeProvider theme={theme}>
        <SelectContainer
          containerStyle={containerStyle}
          style={style}
          styles={styles}
          openOnTop={openOnTop}
          valid={isPristine() || isValid()}
          className={className}
          centered={centered}
          noWrapLabel
        >
          {label && <Label>{label}</Label>}
          <ReactSelect
            name={name}
            value={getValue()}
            options={options}
            placeholder={placeholder}
            optionClassName="Select-option"
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
              if (e?.label !== getValue()?.label) {
                this.updateValue(e);
              }
            }}
            autofocus={autofocus}
            matchPos="start"
            noResultsText={noResultsText}
            components={dropdownIcon}
          />
          {
            ((errorText && !isPristine()) ||
              (showErrorMessage && !isPristine() && error && !isValid()))
            && <Error>{error}</Error>
          }
        </SelectContainer>
      </ThemeProvider>
    );
  }
}

Select.propTypes = {
  theme: ThemeShape,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  placeholder: PropTypes.string,
  setValue: PropTypes.func,
  dropdownIcon: PropTypes.node,
};
Select.defaultProps = {
  theme: Flat,
  name: '',
  options: [],
  placeholder: '',
  setValue: () => { },
  dropdownIcon: <></>,
};

export default Formsy.HOC(Select);
