import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemeProvider } from 'styled-components';
import Formsy from 'formsy-react-2';
import InputMask from 'react-input-mask';
// import MaskedInput from 'react-text-mask';
import { Default, ThemeShape } from '../themes/TextInput';
import { typography } from '../../styles';
import Icon from './Icon';
import { replaceMaskChar } from '../../lib/utils';

const Container = styled.div`
  position: relative;
  width: 100%;
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};

  ${({ theme }) => theme.container}

  ${({ noWrapLabel }) => noWrapLabel && css`
    label {
      white-space: nowrap;
    }
  `};
`;

const Label = styled.label`${({ theme }) => theme.label}`;

const Input = styled(InputMask)`
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-family: ${typography.gothamRounded};

  ${({ theme }) => theme.input}
  ${({
    theme, valid, showerrormessage, showfeedbackonblur, errortext, isformsubmitted,
  }) => {
    if ((showerrormessage && showfeedbackonblur && !valid) || errortext
    || (!valid && isformsubmitted)) {
      return theme.inputError;
    }
    return '';
  }}

  ::-webkit-input-placeholder {
    ${({ theme }) => theme.placeholder} }
  ::-moz-placeholder {
    ${({ theme }) => theme.placeholder} }
  ::-ms-placeholder {
    ${({ theme }) => theme.placeholder} }
  ::placeholder {
    ${({ theme }) => theme.placeholder} }

  ${({ inputstyle }) => inputstyle}
`;
const Error = styled.span`${({ theme }) => theme.error}`;
const SubLabel = styled.span`${({ theme }) => theme.subLabel}`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ containerStyle }) => containerStyle}
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 10px;
`;

/* Gets the array with the two elements, then
  checks if the given value is between those elements.
*/
Formsy.addValidationRule('validRange', (values, value, array) => value >= array[0] && value <= array[1]);

class TextInput extends React.Component {

  constructor(props) {
    super(props);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  state = { showFeedbackOnBlur: false };

  updateValue = (event) => {
    const { setValue, removeMaskChar, replaceChar } = this.props;
    if (removeMaskChar) {
      const numberWithoutMask = replaceMaskChar(event.target.value, replaceChar);
      setValue(numberWithoutMask);
    } else {
      setValue(event.target.value);
    }
  }

  handleOnBlur = () => {
    this.setState({ showFeedbackOnBlur: true });
  }

  handleOnFocus = () => {
    this.setState({ showFeedbackOnBlur: false });
  }

  handleOnChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
    this.updateValue(e);
  }

  render() {
    const {
      label,
      type,
      placeholder,
      theme,
      getErrorMessage,
      getValue,
      isValid,
      isPristine,
      isFormSubmitted,
      showErrorMessage,
      errorText,
      width,
      maxWidth,
      minWidth,
      inputStyle,
      containerStyle,
      subLabel,
      noWrapLabel,
      autoFocus,
      className,
      getRef,
      maxLength,
      icon,
      iconSize,
      iconColor,
      mask,
      maskChar,
    } = this.props;
    const error = errorText || getErrorMessage();
    const showError = (errorText && !isPristine())
    || (showErrorMessage && this.state.showFeedbackOnBlur && !isPristine() && error && !isValid())
    || (!isPristine() && !isValid() && isFormSubmitted);
    const isValueValid = (isPristine() || isValid()) ? 1 : 0;
    return (
      <ThemeProvider theme={theme}>
        <Container
          className={className}
          width={width}
          maxWidth={maxWidth}
          noWrapLabel={noWrapLabel}
          minWidth={minWidth}
        >
          {label
            && <Label>{label}</Label>}
          <InputContainer containerStyle={containerStyle}>
            {subLabel && <SubLabel>{subLabel}</SubLabel>}
            {icon && <StyledIcon icon={icon} size={iconSize} color={iconColor} />}
            <Input
              onBlur={() => this.handleOnBlur()}
              onFocus={() => this.handleOnFocus()}
              showfeedbackonblur={this.state.showFeedbackOnBlur ? 1 : 0}
              showerrormessage={showErrorMessage ? 1 : 0}
              type={type}
              placeholder={placeholder}
              value={getValue() || ''}
              inputstyle={inputStyle}
              onChange={e => this.handleOnChange(e)}
              valid={isValueValid}
              isformsubmitted={isFormSubmitted ? 1 : 0}
              ref={node => getRef(node ? node.input : node)}
              autoFocus={autoFocus}
              maxLength={maxLength}
              errortext={errorText || 0}
              mask={mask}
              maskChar={maskChar}
            />
          </InputContainer>
          {
            showError && <Error className="input-error">{error}</Error>
          }
        </Container>
      </ThemeProvider>
    );
  }
}

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  theme: ThemeShape,
  showErrorMessage: PropTypes.bool,
  /* Este sub label es para casos como el del input de semanas de gestación.
    Viene como Week: ___ (ese Week es el sublabel) */
  subLabel: PropTypes.string,
  /* El noWrapLabel es para evitar que se haga el wrap en unos inputs
    que son cortos pero con labels largos */
  noWrapLabel: PropTypes.bool,
  setValue: PropTypes.func,
  getValue: PropTypes.func,
  getErrorMessage: PropTypes.func,
  isValid: PropTypes.func,
  isPristine: PropTypes.func,
  onChange: PropTypes.func,
  getRef: PropTypes.func,
  errorText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  inputStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  containerDirection: PropTypes.string,
  className: PropTypes.string,
  maskChar: PropTypes.string,
  removeMaskChar: PropTypes.bool,
  replaceChar: PropTypes.string,
};

TextInput.defaultProps = {
  label: '',
  placeholder: '',
  type: 'text',
  value: '',
  theme: Default,
  showErrorMessage: true,
  subLabel: '',
  noWrapLabel: false,
  setValue: () => { },
  getValue: () => { },
  getErrorMessage: () => { },
  isValid: () => { },
  isPristine: () => { },
  onChange: () => { },
  getRef: () => {},
  errorText: '',
  width: '',
  maxWidth: '',
  minWidth: '',
  inputStyle: {},
  containerStyle: {},
  containerDirection: '',
  className: '',
  maskChar: null,
  removeMaskChar: false,
  replaceChar: '-',
};

export default Formsy.HOC(TextInput);
