import { mapObjIndexed } from 'ramda';
import { PlanTypes } from './index';

export const COUNTRIES = {
  WW: 'WorldWide',
  BR: 'Brazil',
};

export const COUNTRY_CURRENCIES = {
  [COUNTRIES.WW]: {
    symbol: '$',
    displaySymbol: true,
    code: 'USD',
    displayCode: true,
    preferredLocaleFormat: 'en-US',
  },
  [COUNTRIES.BR]: {
    symbol: 'R$',
    displaySymbol: true,
    code: 'BRL',
    displayCode: false,
    preferredLocaleFormat: 'pt-BR',
  },
};

const ProductsByType = {
  [PlanTypes.WEEKLY]: {
    w_0149: {
      SKU: 'kinedu_premium_w_0149',
      price: 1.49,
    },
    w_0199: {
      SKU: 'kinedu_premium_w_0199',
      price: 1.99,
    },
    w_0249: {
      SKU: 'kinedu_premium_w_0249',
      price: 2.49,
    },
    w_0299: {
      SKU: 'kinedu_premium_w_0299',
      price: 2.99,
    },
  },
  [PlanTypes.MONTHLY]: {
    m_299: {
      SKU: 'kinedu_premium_1_299',
      price: 2.99,
      centsPerDay: 20,
      description: 'PAY_MONTHLY',
      paymentSource: 'WAPromoM399',
      regularPrice: 9.99,
      noDeepLink: false,
    },
    m_399: {
      SKU: 'kinedu_premium_1_399',
      price: 3.99,
      centsPerDay: 20,
      description: 'PAY_MONTHLY',
      paymentSource: 'WAPromoM399',
      regularPrice: 9.99,
      noDeepLink: false,
    },
    m_499: {
      SKU: 'kinedu_premium_1_499',
      price: 4.99,
      savingsPercent: {
        [COUNTRIES.WW]: 50,
        [COUNTRIES.BR]: 0,
      },
      // centsPerDay: 20,
      description: 'PAY_MONTHLY',
      paymentSource: 'WAPromoM499',
      // regularPrice: 9.99,
    },
    m_499_ft_7: {
      SKU: 'kinedu_premium_1_499_ft_7',
      price: 4.99,
      centsPerDay: 20,
      description: 'PAY_MONTHLY',
      paymentSource: 'WAPromoM499',
      regularPrice: 9.99,
      noDeepLink: false,
      freeTrialDays: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 7,
      },
    },
    m_599: {
      SKU: 'kinedu_premium_1_599',
      price: 5.99,
      savingsPercent: {
        [COUNTRIES.WW]: 40,
        [COUNTRIES.BR]: 0,
      },
      centsPerDay: 20,
      description: 'PAY_MONTHLY',
      paymentSource: 'WAPromoM599',
      regularPrice: 9.99,
    },
    m_699: {
      SKU: 'kinedu_premium_1_699',
      price: 6.99,
      savingsPercent: {
        [COUNTRIES.WW]: 30,
        [COUNTRIES.BR]: 30,
      },
      centsPerDay: 25,
      description: 'PAY_MONTHLY',
      paymentSource: 'WAPromoM699',
      regularPrice: 9.99,
    },
    m_799: {
      SKU: 'kinedu_premium_1_799',
      price: 7.99,
      savingsPercent: {
        [COUNTRIES.WW]: 20,
        [COUNTRIES.BR]: 20,
      },
      centsPerDay: 30,
      description: 'PAY_MONTHLY',
      paymentSource: 'WAPromoM799',
      regularPrice: 9.99,
    },
    m_899: {
      SKU: 'kinedu_premium_1',
      price: 8.99,
      savingsPercent: {
        [COUNTRIES.WW]: 10,
        [COUNTRIES.BR]: 10,
      },
      centsPerDay: 30,
      description: 'PAY_MONTHLY',
      regularPrice: 9.99,
    },
    m_899_ft: {
      SKU: 'kinedu_premium_1_ft',
      price: 8.99,
      savingsPercent: {
        [COUNTRIES.WW]: 10,
        [COUNTRIES.BR]: 10,
      },
      centsPerDay: 30,
      description: 'PAY_MONTHLY',
      regularPrice: 9.99,
      noDeepLink: false,
      freeTrialDays: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 7,
      },
    },
    m_899_ft_3: {
      SKU: 'kinedu_premium_1_ft_3',
      price: 8.99,
      centsPerDay: 30,
      description: 'PAY_MONTHLY',
      regularPrice: 9.99,
      noDeepLink: false,
      freeTrialDays: {
        [COUNTRIES.WW]: 3,
        [COUNTRIES.BR]: 3,
      },
    },
    m_999: {
      SKU: 'kinedu_premium_1_999',
      price: 9.99,
      description: 'PAY_MONTHLY',
      savingsPercent: {
        [COUNTRIES.WW]: 0,
        [COUNTRIES.BR]: 0,
      },
      freeTrialDays: {
        [COUNTRIES.WW]: 0,
        [COUNTRIES.BR]: 0,
      },
    },
    m_999_ft_3: {
      SKU: 'kinedu_premium_1_999_ft_3',
      price: 9.99,
      description: 'PAY_MONTHLY',
      noDeepLink: true,
      freeTrialDays: {
        [COUNTRIES.WW]: 3,
        [COUNTRIES.BR]: 3,
      },
    },
    m_1499: {
      SKU: 'kinedu_premium_1_1499',
      price: 14.99,
      noDeepLink: false,
    },
    ht_month: {
      SKU: 'kinedu_premium_1_ht',
      price: 14.99,
    },
    mt_month: {
      SKU: 'kinedu_premium_1_mt',
      price: 9.99,
    },
    it_month: {
      SKU: 'kinedu_premium_1_it',
      price: 3.99,
    },
    lt_month: {
      SKU: 'kinedu_premium_1_lt',
      price: 8.99,
    },
  },
  [PlanTypes.SEMESTERLY]: {
    it_semester: {
      SKU: 'kinedu_premium_6_it',
      price: 18.99,
    },
    s: {
      SKU: 'kinedu_premium_6',
      price: 49.99,
      centsPerDay: 30,
      description: 'PAY_ONCE_NO_FEES',
      paymentSource: 'WAPromoS',
    },
    s_4999: {
      SKU: 'kinedu_premium_6_4999',
      price: 49.99,
      centsPerDay: 30,
      description: 'PAY_ONCE_NO_FEES',
      paymentSource: 'WAPromoS',
      noDeepLink: false,
      savingsPercent: {
        [COUNTRIES.WW]: 58,
        [COUNTRIES.BR]: 0,
      },
      freeTrialDays: {},
    },
    ht_semester: {
      SKU: 'kinedu_premium_6_ht',
      price: 79.99,
      savingsPercent: {
        [COUNTRIES.WW]: 11,
        [COUNTRIES.BR]: 10,
      },
    },
    mt_semester: {
      SKU: 'kinedu_premium_6_mt',
      price: 59.99,
      savingsPercent: {
        [COUNTRIES.WW]: 0,
        [COUNTRIES.BR]: 6,
      },
      freeTrialDays: {
        [COUNTRIES.WW]: 0,
        [COUNTRIES.BR]: 7,
      },
    },
    lt_semester: {
      SKU: 'kinedu_premium_6_lt',
      price: 49.99,
      savingsPercent: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 0,
      },
      freeTrialDays: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 0,
      },
    },
  },
  [PlanTypes.YEARLY]: {
    it_anual: {
      SKU: 'kinedu_premium_12_it',
      price: 19.99,
      savingsPercent: {
        [COUNTRIES.WW]: 85,
        [COUNTRIES.BR]: 85,
      },
    },
    y: {
      SKU: 'kinedu_premium_12_8999',
      price: 89.99,
      savingsPercent: {
        [COUNTRIES.WW]: 25,
        [COUNTRIES.BR]: 25,
      },
      centsPerDay: 24,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY8999',
      regularPrice: 89.99,
    },
    y_1999: {
      SKU: 'kinedu_premium_12_01999',
      price: 19.99,
      centsPerDay: 6,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY01999',
      regularPrice: 89.99,
      noDeepLink: false,
    },
    y_2499: {
      SKU: 'kinedu_premium_12_02499',
      price: 24.99,
      centsPerDay: 7,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY02499',
      regularPrice: 89.99,
      noDeepLink: false,
    },
    y_2999: {
      SKU: 'kinedu_premium_12_02999',
      price: 29.99,
      centsPerDay: 9,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY02999',
      regularPrice: 89.99,
      noDeepLink: false,
    },
    y_3299_ft_7: {
      SKU: 'kinedu_premium_12_3299_ft_7',
      price: 32.99,
      centsPerDay: 9,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY3299',
      regularPrice: 89.99,
      noDeepLink: false,
      savingsPercent: {
        [COUNTRIES.WW]: 45,
        [COUNTRIES.BR]: 55,
      },
      freeTrialDays: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 7,
      },
    },
    y_3499: {
      SKU: 'kinedu_premium_12_3499',
      price: 35.99,
      savingsPercent: {
        [COUNTRIES.WW]: 70,
        [COUNTRIES.BR]: 70,
      },
    },
    y_3699_ft_7: {
      SKU: 'kinedu_premium_12_3699_ft_7',
      price: 36.99,
      savingsPercent: {
        [COUNTRIES.WW]: 48,
        [COUNTRIES.BR]: 50,
      },
      centsPerDay: 9,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY3699',
      regularPrice: 89.99,
      noDeepLink: false,
      freeTrialDays: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 7,
      },
    },
    y_3999: {
      SKU: 'kinedu_premium_12_3999',
      price: 39.99,
      savingsPercent: {
        [COUNTRIES.WW]: 65,
        [COUNTRIES.BR]: 0,
      },
      centsPerDay: 11,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY3999',
      regularPrice: 89.99,
    },
    y_3999_ft_7: {
      SKU: 'kinedu_premium_12_3999_ft_7',
      price: 39.99,
      savingsPercent: {
        [COUNTRIES.WW]: 66,
        [COUNTRIES.BR]: 45,
      },
      centsPerDay: 11,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY3299',
      regularPrice: 89.99,
      noDeepLink: false,
      freeTrialDays: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 7,
      },
    },
    y_4399_ft_7: {
      SKU: 'kinedu_premium_12_4399_ft_7',
      price: 43.99,
      savingsPercent: {
        [COUNTRIES.WW]: 63,
        [COUNTRIES.BR]: 40,
      },
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY4399',
      regularPrice: 89.99,
      noDeepLink: false,
      freeTrialDays: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 7,
      },
    },
    y_4999: {
      SKU: 'kinedu_premium_12_04999',
      price: 49.99,
      savingsPercent: {
        [COUNTRIES.WW]: 58,
        [COUNTRIES.BR]: 44,
      },
      centsPerDay: 14,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY4999',
      regularPrice: 89.99,
    },
    y_4999_ft_7: {
      SKU: 'kinedu_premium_12_4999_ft_7',
      price: 49.99,
      savingsPercent: {
        [COUNTRIES.WW]: 58,
        [COUNTRIES.BR]: 44,
      },
      centsPerDay: 14,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY4999',
      regularPrice: 89.99,
      freeTrialDays: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 7,
      },
    },
    y_4999_intro_6999: {
      SKU: 'kinedu_premium_12_4999_intro_6999',
      price: 59.00,
      endPrice: 999.00,
      monthlyToYearly: true,
    },
    y_5999: {
      SKU: 'kinedu_premium_12_5999',
      price: 59.99,
      savingsPercent: {
        [COUNTRIES.WW]: 50,
        [COUNTRIES.BR]: 33,
      },
      centsPerDay: 17,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY5999',
      regularPrice: 89.99,
    },
    y_5999_ft_7: {
      SKU: 'kinedu_premium_12_5999_ft_7',
      price: 59.99,
      savingsPercent: {
        [COUNTRIES.WW]: 50,
        [COUNTRIES.BR]: 33,
      },
      centsPerDay: 17,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY5999',
      regularPrice: 89.99,
      freeTrialDays: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 7,
      },
    },
    y_5999_intro: {
      SKU: 'kinedu_premium_12_intro_5999',
      price: 39.99,
      endPrice: 59.99,
    },
    y_6999: {
      SKU: 'kinedu_premium_12_6999',
      price: 69.99,
      savingsPercent: {
        [COUNTRIES.WW]: 40,
        [COUNTRIES.BR]: 22,
      },
      centsPerDay: 19,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY6999',
      regularPrice: 89.99,
    },
    y_6999_ft_7: {
      SKU: 'kinedu_premium_12_6999_ft_7',
      price: 69.99,
      savingsPercent: {
        [COUNTRIES.WW]: 40,
        [COUNTRIES.BR]: 22,
      },
      centsPerDay: 19,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY6999',
      regularPrice: 89.99,
      freeTrialDays: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 7,
      },
    },
    y_6999_intro: {
      SKU: 'kinedu_premium_12_intro_6999',
      price: 39.99,
      endPrice: 69.99,
      savingsPercent: {
        [COUNTRIES.WW]: 0,
        [COUNTRIES.BR]: 0,
      },
    },
    y_7999: {
      SKU: 'kinedu_premium_12_7999',
      price: 24.99,
      savingsPercent: {
        [COUNTRIES.WW]: 75,
        [COUNTRIES.BR]: 75,
      },
      centsPerDay: 21,
      description: 'NO_MONTHYL_FEES',
      paymentSource: 'WAPromoY7999',
      regularPrice: 89.99,
    },
    y_8999: {
      SKU: 'kinedu_premium_12_8999',
      price: 89.99,
      savingsPercent: {
        [COUNTRIES.WW]: 25,
        [COUNTRIES.BR]: 0,
      },
    },
    y_8999_ft_7: {
      SKU: 'kinedu_premium_12_8999_ft_7',
      price: 89.99,
      savingsPercent: {
        [COUNTRIES.WW]: 25,
        [COUNTRIES.BR]: 0,
      },
      freeTrialDays: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 7,
      },
    },
    ht_anual: {
      SKU: 'kinedu_premium_12_ht',
      price: 89.99,
      savingsPercent: {
        [COUNTRIES.WW]: 50,
      },
      freeTrialDays: {
        [COUNTRIES.WW]: 7,
        [COUNTRIES.BR]: 7,
      },
    },
    mt_anual: {
      SKU: 'kinedu_premium_12_mt',
      price: 69.99,
      savingsPercent: {
        [COUNTRIES.WW]: 40,
        [COUNTRIES.BR]: 0,
      },
      freeTrialDays: {
        [COUNTRIES.WW]: 0,
        [COUNTRIES.BR]: 7,
      },
    },
    lt_anual: {
      SKU: 'kinedu_premium_12_lt',
      price: 29.99,
      savingsPercent: {
        [COUNTRIES.WW]: 75,
        [COUNTRIES.BR]: 50,
      },
      freeTrialDays: {
        [COUNTRIES.WW]: 0,
        [COUNTRIES.BR]: 0,
      },
    },
    lt_anual_intro: {
      SKU: 'kinedu_premium_12_lt_intro',
      price: 0.99,
      endPrice: 59.99,
      savingsPercent: {
        [COUNTRIES.WW]: 0,
        [COUNTRIES.BR]: 0,
      },
      freeTrialDays: {
        [COUNTRIES.WW]: 0,
        [COUNTRIES.BR]: 0,
      },
    },
    lt_anual_2499: {
      SKU: 'kinedu_premium_12_lt_2499',
      price: 24.99,
      savingsPercent: {
        [COUNTRIES.WW]: 80,
        [COUNTRIES.BR]: 80,
      },
    },
    sp_anual: {
      SKU: 'kinedu_premium_12_sp',
      price: 29.99,
      savingsPercent: {
        [COUNTRIES.WW]: 0,
        [COUNTRIES.BR]: 0,
      },
      freeTrialDays: {
        [COUNTRIES.WW]: 0,
        [COUNTRIES.BR]: 0,
      },
    },
  },
  [PlanTypes.LIFETIME]: {
    lt: {
      SKU: 'kinedu_premium_lifetime',
      price: 149.99,
      description: 'PAY_ONCE_LIFETIME',
      paymentSource: 'WAPromoLT',
    },
    lt_10999: {
      SKU: 'kinedu_premium_lifetime_10999',
      price: 109.99,
      savingsPercent: {
        [COUNTRIES.WW]: 0,
        [COUNTRIES.BR]: 0,
      },
      freeTrialDays: {},
      description: 'PAY_ONCE_LIFETIME',
      paymentSource: 'WAPromoLT',
      regularPrice: 149.99,
    },
    lt_11999: {
      SKU: 'kinedu_premium_lifetime_11999',
      price: 119.99,
      savingsPercent: {
        [COUNTRIES.WW]: 75,
        [COUNTRIES.BR]: 0,
      },
      description: 'PAY_ONCE_LIFETIME',
      paymentSource: 'WAPromoLT2',
      regularPrice: 149.99,
    },
    lt_12999: {
      SKU: 'kinedu_premium_lifetime_12999',
      price: 129.99,
      savingsPercent: {
        [COUNTRIES.WW]: 73,
        [COUNTRIES.BR]: 10,
      },
      description: 'PAY_ONCE_LIFETIME',
      paymentSource: 'WAPromoLT3',
      regularPrice: 149.99,
    },
    lt_13999: {
      SKU: 'kinedu_premium_lifetime_13999',
      price: 139.99,
      savingsPercent: {
        [COUNTRIES.WW]: 70,
        [COUNTRIES.BR]: 0,
      },
      description: 'PAY_ONCE_LIFETIME',
      paymentSource: 'WAPromoLT4',
      regularPrice: 149.99,
    },
    lt_14999: {
      SKU: 'kinedu_premium_lifetime_14999',
      price: 149.99,
      description: 'PAY_ONCE_LIFETIME',
      paymentSource: 'WAPromoLT5',
      savingsPercent: {
        [COUNTRIES.WW]: 68,
      },
    },
    gc_monthly: {
      SKU: 'giftcard_kinedu_premium_1',
      price: 9.99,
      description: 'GIFT_CARD',
      paymentSource: 'WAGiftCard',
      localizedSKU: {
        [COUNTRIES.WW]: 'giftcard_kinedu_premium_1',
        [COUNTRIES.BR]: 'giftcard_kinedu_play_1_real',
      },
      localizedPrice: {
        [COUNTRIES.WW]: { amount: 9.99, currency: COUNTRY_CURRENCIES[COUNTRIES.WW] },
        [COUNTRIES.BR]: { amount: 24.90, currency: COUNTRY_CURRENCIES[COUNTRIES.BR] },
      },
    },
    gc_semesterly: {
      SKU: 'giftcard_kinedu_premium_6',
      price: 0,
      description: 'GIFT_CARD',
      paymentSource: 'WAGiftCard',
    },
    gc_yearly: {
      SKU: 'giftcard_kinedu_premium_12',
      price: 59.99,
      description: 'GIFT_CARD',
      paymentSource: 'WAGiftCard',
      localizedSKU: {
        [COUNTRIES.WW]: 'giftcard_kinedu_play_12',
        [COUNTRIES.BR]: 'giftcard_kinedu_play_12_real',
      },
      localizedPrice: {
        [COUNTRIES.WW]: { amount: 59.99, currency: COUNTRY_CURRENCIES[COUNTRIES.WW] },
        [COUNTRIES.BR]: { amount: 197.90, currency: COUNTRY_CURRENCIES[COUNTRIES.BR] },
      },
    },
    gc_lifetime: {
      SKU: 'giftcard_kinedu_premium_lifetime',
      price: 119.99,
      description: 'GIFT_CARD',
      paymentSource: 'WAGiftCard',
      localizedSKU: {
        [COUNTRIES.WW]: 'giftcard_kinedu_play_lifetime',
        [COUNTRIES.BR]: 'giftcard_kinedu_play_lifetime_real',
      },
      localizedPrice: {
        [COUNTRIES.WW]: { amount: 119.99, currency: COUNTRY_CURRENCIES[COUNTRIES.WW] },
        [COUNTRIES.BR]: { amount: 394.90, currency: COUNTRY_CURRENCIES[COUNTRIES.BR] },
      },
    },
  },
};

/** Flaten Product List, made this way to make reading easier. */
export const ProductsList = {
  ...mapObjIndexed((plan, code) => ({
    ...plan,
    code,
    type: PlanTypes.WEEKLY,
  }), ProductsByType[PlanTypes.WEEKLY]),
  ...mapObjIndexed((plan, code) => ({
    ...plan,
    code,
    type: PlanTypes.MONTHLY,
  }), ProductsByType[PlanTypes.MONTHLY]),
  ...mapObjIndexed((plan, code) => ({
    ...plan,
    code,
    type: PlanTypes.SEMESTERLY,
  }), ProductsByType[PlanTypes.SEMESTERLY]),
  ...mapObjIndexed((plan, code) => ({
    ...plan,
    code,
    type: PlanTypes.YEARLY,
  }), ProductsByType[PlanTypes.YEARLY]),
  ...mapObjIndexed((plan, code) => ({
    ...plan,
    code,
    type: PlanTypes.LIFETIME,
  }), ProductsByType[PlanTypes.LIFETIME]),
};

/** Set default product aliases - used in landing gitftcard's fixed prices */
ProductsList.m = ProductsList.m_999;
// ProductsList.s = ProductsList.s;
ProductsList.y = ProductsList.y_3999;
ProductsList.lt = ProductsList.lt_10999;

export default ProductsList;

/** Updates plans to match user's location price */
export const setDefaultPlans = ({
  weekly, monthly, semesterly, yearly, lifetime,
}) => {
  const findBySku = (sku, type) => Object.values(ProductsByType[type])
    .find(({ SKU }) => SKU === sku);

  const defaultProducts = {
    w: findBySku(weekly, PlanTypes.WEEKLY),
    m: findBySku(monthly, PlanTypes.MONTHLY),
    s: findBySku(semesterly, PlanTypes.SEMESTERLY),
    y: findBySku(yearly, PlanTypes.YEARLY),
    lt: findBySku(lifetime, PlanTypes.LIFETIME),
  };

  ProductsList.w = { ...ProductsList.w, ...defaultProducts.w };
  ProductsList.m = { ...ProductsList.m, ...defaultProducts.m };
  ProductsList.s = { ...ProductsList.s, ...defaultProducts.s };
  ProductsList.y = { ...ProductsList.y, ...defaultProducts.y };
  ProductsList.lt = { ...ProductsList.lt, ...defaultProducts.lt };
};
