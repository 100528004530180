/**
 * Handles app initialization subroutine. Initialization is defined as
 * one of the following situations:
 * 1) User login
 * 2) User signup
 * 3) App rehydration
 */

import { Observable } from 'rxjs';
// import Leanplum from 'leanplum-sdk';
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/browser';

import {
  Auth, AppContext, User, BabiesAndFamilies, Prices, Settings, Modals, Families, Account,
} from '../../../ducks';
import { isBrazilianUser, userIsNewUser } from '../../../../lib/utils';
import { OBTypes, startOB2IAFlow } from '../OB2IA';
import { fetchFamilies$ } from '../../babiesAndFamilies/helperObservables';
import { GDPRFlow } from './GDPRFlow';
import { setFamilyAttributes } from '../../../../lib/EventReporter';
import inviteFlowStream from './inviteFlowStream';
import { store } from '../../../../App';
import { APP_VERSION } from '../../../../config';
import ProductsList, { setDefaultPlans } from '../../../../values/prices/products';

// #region Types and Action Creators.
export const START_APP_INIT = 'START_APP_INIT';
export const END_APP_INIT = 'END_APP_INIT';

export const startAppInit = () => ({ type: START_APP_INIT });
export const endAppInit = () => ({ type: END_APP_INIT });
// #endregion

// #region Helper Observables.
const onFetchUserSuccess = stream$ => stream$
  .ofType(User.Types.FETCH_USER_DETAIL_SUCCESS)
  .take(1)
  .map(({ user }) => user);

const onFetchInvitesSuccess = stream$ => stream$
  .ofType(User.Types.FETCH_PENDING_INVITES_SUCCESS)
  .take(1)
  .map(({ invites }) => invites);

export const onUserDeclineInvitation = stream$ => stream$.ofType('USER_DECLINE_INVITATION')
  .take(1);
// #endregion

// TODO: This function will be use when needed
// const getGenderByDefaultFamily = (defaultFamily) => {
//   const { membership } = defaultFamily;
//   const { relationship } = membership;
//   return getGenderByRole(relationship);
// };

const setUserPlatformsContext = (user) => {
    console.log('%c 🔮: BEFORE: SET_USER_PLATFORM_CONTEXT', 'background: #660079; color: #ffc2f2; font-size: 12px');
  console.log('%c DEBUG: user', 'background: #332167; color: #b3d1f6; font-size: 14px', user);
  let kineduCustomLocale = user?.locale?.toUpperCase() || 'EN';
  if (user?.country === 'BR') {
    kineduCustomLocale = `${user?.locale}_${user?.country}`.toUpperCase();
  }
  const userAttrs = {
    userEmail: user?.email,
    userName: user?.name,
    userLastName: user?.lastname,
    userBirthday: user?.birthday,
    userGender: user?.gender,
    kineduLanguage: user?.locale,
    kineduCountry: user?.country,
    kineduCustomLocale,
    webAppVersion: APP_VERSION,
  };

  const familyAttrs = {
    source: 'KIN',
  };

  const babyAttrs = {
    initialAssessmentCompleted: 'No',
  };

  mixpanel.identify(user?.id);
  mixpanel.people.set({
    $email: user?.email,
    $first_name: user?.name,
    $last_name: user?.lastname,
    ...userAttrs,
    // ...familyAttrs, // This is commented because this will overwrite the Kinnedu Partner source (this source will be trigger just in the signup)
    ...babyAttrs,
  });

  // Leanplum.setUserAttributes(user?.id, {
  //   ...userAttrs,
  // });

  store.dispatch({
    type: 'ATTRIBUTES',
    infoType: 'user',
    attrs: {
      ...userAttrs,
    },
  });

  store.dispatch({
    type: 'ATTRIBUTES',
    infoType: 'family',
    attrs: {
      ...familyAttrs,
    },
  });

  store.dispatch({
    type: 'ATTRIBUTES',
    infoType: 'baby',
    attrs: {
      ...babyAttrs,
    },
  });

  Sentry.setUser({
    id: user?.id?.toString(),
    email: user?.email,
  });
};

/* Instead of concating streams, you could listen to END_X to
* follow the stream chain. This would allow to trigger some sub streams
* such as this one individualy. At this time, this is not usefull enough
* to justify the extra boilerplate because this streams depend on each other.
*/

const checkIsClassroomsInvite = invite => !!(invite.newUserData && invite.newUserData.status === 'classrooms_pending');

const appInit = (action$, { getState }) => action$
  .filter(({ type, authenticated }) => (type === Auth.Types.setAuthStatus && authenticated)
    || type === Auth.Types.loginSuccess || type === Auth.Types.signupSuccess)
  .mergeMap(() => Observable.concat(
    [
      // Dummy debug marker.
      startAppInit(),
      // Request session, user details, user's monthly sku and pending invites.
      User.Creators.fetchUserDetailRequested(),
      User.Creators.fetchPendingInvitesRequested(),
      Prices.Creators.fetchDefaultPricesRequested(),
      AppContext.Creators.fetchConfigRequested(),
      Modals.Creators.closeModal({ name: 'CLOSE_ALL_MODALS' }),
    ],
    // Wait for both user details' and invites' response
    Observable.forkJoin(
      onFetchUserSuccess(action$),
      onFetchInvitesSuccess(action$),
    ).mergeMap(([user, invites]) => {
      // NOTE: This is where we set the user info to third party platforms
      setUserPlatformsContext(user);
      console.log('%c 🔮: AFTER: SET_USER_PLATFORM_CONTEXT', 'background: #660079; color: #ffc2f2; font-size: 12px');
      setDefaultPlans({
        monthly: isBrazilianUser(user) ? ProductsList.m_499.SKU : ProductsList.m.SKU,
        yearly: isBrazilianUser(user) ? ProductsList.lt_anual.SKU : ProductsList.y.SKU,
        lifetime: isBrazilianUser(user) ? ProductsList.lt.SKU : ProductsList.lt.SKU,
      });

      if (invites && invites.length > 0 && !checkIsClassroomsInvite(invites[0])) {
        if (userIsNewUser(user, getState().families.activeFamilyId)) {
          return [startOB2IAFlow({ OBType: OBTypes.INVITE })];
        }
        return inviteFlowStream(action$, invites[0], user);
      }

      if (userIsNewUser(user, getState().families.activeFamilyId)) {
        return Observable.concat(
          GDPRFlow(action$),
          [startOB2IAFlow({ OBType: OBTypes.FULL })],
        );
      }

      if (!getState().families.activeFamilyId) {
        // Enters here when the user logs in for the first time.
        return fetchFamilies$(action$, ([defaultFamily = {}]) => {
          /** TODO: Update user with this gender to fix default gender.
          Consider making some test with other flows like Invite Flow. */
          // const userGender = getGenderByDefaultFamily(defaultFamily);
          setFamilyAttributes(defaultFamily);
          return ([
            BabiesAndFamilies.Creators.checkEmptyFamily(),
            BabiesAndFamilies.Creators.selectFamilyAndDefaultBaby(defaultFamily.id),
            BabiesAndFamilies.Creators.checkNoFamily(),
            BabiesAndFamilies.Creators.checkPendingAssessment(),
            BabiesAndFamilies.Creators.checkNoDefaultFamily(),
            Account.Creators.fetchAccountRequested(defaultFamily?.id),
            Settings.Creators.settingChanged({
              name: 'lang',
              value: user?.locale,
            }),
          ]);
        });
      }

      // Family already in state, select default babys. (When the user refresh the browser)
      return fetchFamilies$(action$, ([defaultFamily = {}]) => {
        const activeBaby = Families.Selectors.activeBaby(getState());
        setFamilyAttributes(defaultFamily, activeBaby);
        return ([
          BabiesAndFamilies.Creators.checkEmptyFamily(),
          BabiesAndFamilies.Creators.babySelected(getState().families.activeBabyId),
          BabiesAndFamilies.Creators.checkNoFamily(),
          BabiesAndFamilies.Creators.checkPendingAssessment(),
          BabiesAndFamilies.Creators.checkNoDefaultFamily(),
          Account.Creators.fetchAccountRequested(defaultFamily?.id),
          Settings.Creators.settingChanged({
            name: 'lang',
            value: getState().settings.lang || user?.locale,
          }),
        ]);
      });
    }),
    [AppContext.Creators.setInitialized(true)],
    [endAppInit()],
  ));

export default appInit;
