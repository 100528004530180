import React from 'react';
import PropTypes from 'prop-types';
import SegmentedBoxes from './SegmentedBoxes';
import { CircularOptions } from './../themes/SegmentedBoxes';


class HealthInterestsGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = { healthInterests: null };
  }

  render() {
    const mappedInterests = this.props.interests.map(obj => ({
      interest_id: obj.id,
      label: obj.name,
      value: obj.id,
      selected: obj.selected,
      image: obj.icon,
    }));
    return (
      <div>
        {this.props.interests.length > 0 ?
          <SegmentedBoxes
            style={this.props.style}
            name="interests"
            boxes={mappedInterests}
            theme={CircularOptions}
            required
            multiple
          /> : null}
      </div>
    );
  }
}

HealthInterestsGrid.propTypes = {
  style: PropTypes.shape({}),
  interests: PropTypes.array,
};

HealthInterestsGrid.defaultProps = {
  style: {},
  interests: [],
};

export default HealthInterestsGrid;
