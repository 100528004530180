import * as Yup from 'yup';

const LOAD_OPTIONS = {
  ALL: 'ALL',
  PP: 'PP',
  PAYWALL: 'PAYWALL',
  NOTHING: 'NOTHING',
};

const VARIABLES_DEFAULT = {
  items: {
    color: 'red',
    size: 20,
    showBadges: true,
  },
  showAds: true,
};


const PAYWALL_SCHEMA = Yup.object().shape({
  webCopyButton: Yup.string().required(),
  webImage: Yup.string().required(),
  webImageResp: Yup.string().required(),
  webPromoText: Yup.string().required(),
  webSku: Yup.string().required(),
});



const PP_SCHEMA = Yup.object().shape({
  webDiscountPremiumFirst: Yup.string().required(),
  webDiscountPremiumSecond: Yup.string().required(),
  webDiscountPremiumThird: Yup.string().required(),
  webImagePP: Yup.string().required(),
  webImagePPResp: Yup.string().required(),
  webMonthlyPricePremiumFirst: Yup.string().required(),
  webMonthlyPricePremiumSecond: Yup.string().required(),
  webMonthlyPricePremiumThird: Yup.string().required(),
  webPPCopyButton: Yup.string().required(),
  webSkuPremiumFirst: Yup.string().required(),
  webSkuPremiumSecond: Yup.string().required(),
  webSkuPremiumThird: Yup.string().required(),
  webTitlePremiumFirst: Yup.string().required(),
  webTitlePremiumSecond: Yup.string().required(),
  webTitlePremiumThird: Yup.string().required(),
  // webTitlePremiumThird2: Yup.string().required(),

});

export default {};
export {
  LOAD_OPTIONS,
  VARIABLES_DEFAULT,
  PAYWALL_SCHEMA,
  PP_SCHEMA,
};
