import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { colors } from '../../styles';

const PermissionsListContainer = styled.div``;

const Title = styled.p`
  font-size: 16px;
  color: ${colors.shadeDark};
  letter-spacing: 3.11px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
`;

const List = styled.ul`
  padding: 0;
`;

const Item = styled.li`
  font-size: 16px;
  color: ${colors.shadeDark};
  letter-spacing: -0.5px;
  line-height: 21px;
  list-style: none;
  max-width: 300px;
  

  margin: 10px auto;

  &::before {
    content: '●';
    color: ${colors.physical};
    margin-right: 5px;
    margin-left: -22px;
    font-size: 28px;
    

  }
`;

const PermissionsList = ({ list, showTitle, t }) => (
  <PermissionsListContainer>
    {showTitle ? <Title>{t('PERMISSIONS')}</Title> : null}
    <List>
      {list.map((item, index) => (<Item key={index}>{t(item)}</Item>))}
    </List>
  </PermissionsListContainer>
);

PermissionsList.propTypes = {
  list: PropTypes.array,
  showTitle: PropTypes.bool,
};
PermissionsList.defaultProps = {
  list: [],
  showTitle: true,
};

export default withTranslation([Namespaces.FAMILIES])(PermissionsList);
