import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Formsy from 'formsy-react-2';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import { Creators } from '../../../data/ducks/modals';

import TextInput from '../../shared/TextInput';
import { Flat } from '../../themes/TextInput';
import Button from '../../shared/buttons/MainButton';
import { colors } from '../../../styles';
import { Providers, Types as AuthTypes } from '../../../values/auth';
import withAuth, { propTypes as withAuthProptypes } from '../../../hoc/withAuth';
import { VALIDATIONS_MESSAGES } from '../../../values/validations';
import EventReporter, { Events } from '../../../lib/EventReporter';

const View = styled.div.attrs({ className: 'auth-view' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row: 2;

  width: 100%;
  max-width: 410px;
  padding: 20px;
  box-sizing: border-box;
  margin: auto auto 30% auto;
  z-index: 1;

  .new-input {
    label {
      display: block;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 5px;
    }

    input {
      border: 1px solid #979797;
      border-radius: 4px;
      height: 50px;

    &::placeholder {
        font-size: 14px;
        color: #979797;
        letter-spacing: 0;
        line-height: 21px;
      }
    }

    .input-error{
      background: no-repeat;
      color: red;
      margin: 10px 0px 0;
      padding: 0;
      font-size: 12px;
    }
  }
`;

const Title = styled.span`
  font-size: 28px;
  color: #0086D8;
  letter-spacing: 0;
  text-align: right;
  line-height: 47px;
`;

const Subtitle = styled.span`
  font-family: Helvetica;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  text-align: right;
`;

const Form = styled(Formsy.Form)`
  width: 100%;
  margin-top: 20px;
`;

const InlineButtons = styled.div`
  display: flex;
  justify-content: center;
`;

const Note = styled.span`
  display: block;
  margin: 10px;

  color: ${colors.shadeMediumDark};
  font-size: 15px;
  letter-spacing: -0.33px;
  text-align: center;

  a {
    font-size: 15px;
  }
`;

const Link = styled.button`
  font-size: 14px;
  color: #0086D8;
  letter-spacing: 0;
  border: none;
  background: none;
  text-decoration-line: underline;
`;

const authTypes = {
  [AuthTypes.SIGNUP]: {
    title: 'WELCOME_TO_KINEDU',
    subtitle: 'LETS_BEGIN',
    buttonLabel: 'SIGNUP',
  },
  [AuthTypes.LOGIN]: {
    title: 'WELCOME_BACK',
    subtitle: 'LOGIN_EXISTING_ACC',
    buttonLabel: 'LOGIN',
  },
};

const Separator = styled.hr`
  width: 100%;
  height: 1px;
  background-color: #E9E9E9;
  border: 0;
  margin: 30px 0 10px;
`;
class AuthView extends Component {
  state = { val: null, type: '' };

  componentWillMount = () => {
    this.setState({ type: this.props.type });
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.keyPress, false);
    if (this.state.type === AuthTypes.LOGIN) {
      EventReporter.view(Events.LOGIN_SCREEN());
    } else if (this.state.type === AuthTypes.SIGNUP) {
      EventReporter.view(Events.SIGNUP_SCREEN());
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { type } = this.props;
    if (prevProps.type !== type) {
      this.setState({ type });
      if (type === AuthTypes.LOGIN) {
        EventReporter.view(Events.LOGIN_SCREEN());
      } else if (type === AuthTypes.SIGNUP) {
        EventReporter.view(Events.SIGNUP_SCREEN());
      }
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.keyPress, false);
  }

  onForgotPasswordClicked = () => {
    this.props.openModal({ name: 'ForgotPasswordModal' });
    EventReporter.action(Events.FORGOT_PASSWORD());
  }

  onSubmit = () => this.setState({ isSubmited: true });

  toggleSubmit = val => this.setState({ submitEnabled: val });

  toggleForm = () => {
    const { onAuthTypeChange } = this.props;
    if (this.state.type === AuthTypes.LOGIN) {
      this.setState({ type: AuthTypes.SIGNUP });
      onAuthTypeChange(AuthTypes.SIGNUP);
    } else {
      this.setState({ type: AuthTypes.LOGIN });
      onAuthTypeChange(AuthTypes.LOGIN);
    }
  }

  handleAuth = (provider, params) => {
    const variant = localStorage.getItem('exp_variant');
    if (this.state.type === AuthTypes.LOGIN || provider === Providers.FB) {
      this.props.onLogin({ provider, ...params });
    } else {
      this.props.onSignup({ provider, ...params, cohort: variant });
    }
  }

  render() {
    const { t, error } = this.props;
    const { type } = this.state;
    return (
      <View>
        <Title
          size={30}
          color={colors.kinedu}
          align="center"
          bold
        >
          {t(`common:AUTH.${authTypes[type].title}`)}
        </Title>
        <Subtitle>{t(`common:AUTH.${authTypes[type].subtitle}`)}</Subtitle>
        <Separator />
        <Form
          onChange={val => this.setState({ val })}
          onValid={() => this.toggleSubmit(true)}
          onInvalid={() => this.toggleSubmit(false)}
          onSubmit={() => this.onSubmit()}
          ref={(form) => { this.authForm = form; }}
        >
          <TextInput
            className="new-input"
            type="email"
            name="email"
            label={t('common:PLACEHOLDERS.EMAIL')}
            placeholder={t('example@email.com')}
            theme={Flat}
            errorText={error && t(`validations:${error}`)}
            validations={{ isEmail: true }}
            validationErrors={{
              isDefaultRequiredValue: t(`validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`),
              isEmail: t(`validations:${VALIDATIONS_MESSAGES.IS_EMAIL}`),
            }}
            required
          />
          <TextInput
            className="new-input"
            type="password"
            name="password"
            label={t('common:PLACEHOLDERS.PASSWORD')}
            placeholder={t('Type your password')}
            theme={Flat}
            validationErrors={{
              isDefaultRequiredValue: t(`validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`),
            }}
            autoComplete="new-password"
            required
          />
          {type === AuthTypes.SIGNUP && (
            <TextInput
              className="new-input"
              type="password"
              name="confirm_password"
              label={t('Confirm password')}
              placeholder={t('Type your password')}
              theme={Flat}
              validationErrors={{
                isDefaultRequiredValue: t(`validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`),
              }}
              autoComplete="new-password"
              required
            />
          )}
          {type === AuthTypes.LOGIN && (
            <Link
              style={{ margin: '0px 0px 20px auto', display: 'block' }}
              onClick={this.onForgotPasswordClicked}
              underlined
            >
              {t('common:AUTH.FORGOT_PASSWORD')}
            </Link>
          )}
          <InlineButtons>
            <Button
              type="submit"
              color="green"
              onClick={this.state.submitEnabled
                ? () => this.handleAuth(Providers.EMAIL, this.state.val)
                : () => {}}
              rounded
              small
              maxWidth={300}
            >
              {t(`common:AUTH.${authTypes[type].buttonLabel}`)}
            </Button>
          </InlineButtons>
          {type === AuthTypes.LOGIN
            ? (
              <Note>
                <span style={{ marginRight: '5px' }}>{t('common:AUTH.DONT_HAVE_ACC_YET')}</span>
                <Link onClick={() => this.toggleForm()} underlined>{t('common:AUTH.SIGNUP')}</Link>
              </Note>
            )
            : (
              <Note>
                <span style={{ marginRight: '5px' }}>{t('common:AUTH.ALREADY_HAVE_ACC')}</span>
                <Link onClick={() => this.toggleForm()} underlined>{t('common:AUTH.LOGIN')}</Link>
              </Note>
            )
          }
        </Form>
      </View>
    );
  }
}

AuthView.propTypes = {
  type: PropTypes.oneOf([AuthTypes.SIGNUP, AuthTypes.LOGIN]),
  ...withAuthProptypes,
};

// TODO: Check why is making this value undefined first
AuthView.defaultProps = {
  type: AuthTypes.LOGIN,
};

const mapDispatchToProps = dispatch => bind({
  openModal: Creators.openModal,
}, dispatch);

export default compose(
  connect(() => ({}), mapDispatchToProps),
  withTranslation([Namespaces.MODALS, Namespaces.COMMON, Namespaces.VALIDATIONS]),
  withAuth,
)(AuthView);
