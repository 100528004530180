import nps_0 from '../images/nps_0.png';
import nps_1 from '../images/nps_1.png';
import nps_2 from '../images/nps_2.png';
import nps_3 from '../images/nps_3.png';
import nps_4 from '../images/nps_4.png';
import nps_5 from '../images/nps_5and6.png';
import nps_6 from '../images/nps_5and6.png';
import nps_7 from '../images/nps_7and8.png';
import nps_8 from '../images/nps_7and8.png';
import nps_9 from '../images/nps_9.png';
import nps_10 from '../images/nps_10.png';

export const NpsBabies = [
  {
    phrase: 'NPS_A0',
    image: nps_0,
  },
  {
    phrase: 'NPS_A1',
    image: nps_1,
  },
  {
    phrase: 'NPS_A2',
    image: nps_2,
  },
  {
    phrase: 'NPS_A3',
    image: nps_3,
  },
  {
    phrase: 'NPS_A4',
    image: nps_4,
  },
  {
    phrase: 'NPS_A5',
    image: nps_5,
  },
  {
    phrase: 'NPS_A6',
    image: nps_6,
  },
  {
    phrase: 'NPS_A7',
    image: nps_7,
  },
  {
    phrase: 'NPS_A8',
    image: nps_8,
  },
  {
    phrase: 'NPS_A9',
    image: nps_9,
  },
  {
    phrase: 'NPS_A10',
    image: nps_10,
  },
];

export const NpsColorByRate = [
  '#961B1E',
  '#AC1F23',
  '#CC2027',
  '#EA1C30',
  '#E06425',
  '#E27D25',
  '#E7A323',
  '#DBD622',
  '#CBDA2A',
  '#80C241',
  '#47B649',
];

export const NpsSlices = [
  {
    fill: 'M10.92 289.646c9.57 30.05 25.368 57.32 45.88 80.29l56.257-50.625c-13.474-15.15-23.843-33.13-30.105-52.94l-72.03 23.28z',
    border: 'M11.55 289.97c9.435 29.402 24.668 56.063 45.288 79.26l55.515-49.96C98.82 303.946 88.823 286.364 82.628 267l-71.08 22.97zm45.21 80.675l-.334-.374c-20.994-23.51-36.465-50.58-45.98-80.47l-.152-.47.474-.15 72.51-23.43.15.48c6.19 19.58 16.283 37.33 30.002 52.76l.33.37-57 51.3z',
    crust: 'M113.056 319.31l5.21-4.688c-12.822-14.434-22.688-31.55-28.643-50.408l-6.67 2.156c6.26 19.805 16.63 37.783 30.103 52.94',
    number: 'M69.202 312.754v-.064c0-4.463-2.296-8.118-5.79-8.118-3.492 0-5.723 3.558-5.723 8.053v.065c0 4.463 2.26 8.117 5.78 8.117s5.72-3.622 5.72-8.053m-15.62 0v-.064c0-6.468 3.94-11.708 9.89-11.708 5.92 0 9.83 5.175 9.83 11.643v.065c0 6.468-3.95 11.707-9.9 11.707s-9.83-5.174-9.83-11.643'
  },
  {
    fill: 'M76.174 222.275c0-5.58.324-11.082.933-16.5l-75.22-8.42C.972 205.535.5 213.85.5 222.275c0 23.49 3.655 46.124 10.42 67.37l72.032-23.275c-4.4-13.914-6.778-28.726-6.778-44.095',
    border: 'M2.33 197.907C1.446 205.96 1 214.157 1 222.275c0 22.768 3.447 45.22 10.246 66.74l71.08-22.968c-4.414-14.13-6.652-28.854-6.652-43.772 0-5.297.297-10.697.88-16.058l-74.225-8.31zm8.267 92.37l-.152-.48C3.515 268.035 0 245.317 0 222.277c0-8.323.468-16.727 1.39-24.977l.056-.498 76.214 8.532-.056.498c-.617 5.487-.93 11.02-.93 16.443 0 14.983 2.273 29.768 6.755 43.944l.15.47-.48.15-72.51 23.43z',
    crust: 'M76.174 222.275c0 15.37 2.38 30.18 6.778 44.095l6.67-2.156c-4.178-13.235-6.438-27.322-6.438-41.94 0-5.315.308-10.558.89-15.718l-6.967-.78c-.61 5.417-.933 10.92-.933 16.5',
    number: 'M41.57 233.504l-2.327.712c-.227.065-.518.097-.712.097-.93 0-1.74-.777-1.74-1.682 0-.84.52-1.48 1.33-1.71l3.43-1.1c.78-.22 1.36-.35 1.94-.35h.07c1.13 0 1.98.88 1.98 1.97v19.15c0 1.1-.87 1.98-1.97 1.98-1.07 0-1.97-.87-1.97-1.97v-17.08z',
  },
  {
    fill: 'M96.453 147.987L30.897 110.14c-15.34 26.125-25.483 55.67-29.01 87.215l75.22 8.42c2.35-20.917 9.117-40.497 19.346-57.788',
    border: 'M2.44 196.913l74.227 8.31c2.347-20.27 8.77-39.462 19.1-57.055l-64.69-37.348C15.56 137.383 5.927 166.343 2.44 196.913zm75.108 9.415l-76.213-8.532.055-.497c3.473-31.06 13.256-60.47 29.076-87.42l.252-.43L97.14 147.8l-.25.436c-10.49 17.733-16.978 37.11-19.28 57.59l-.056.496z',
    crust: 'M77.107 205.776l6.966.78c2.242-19.934 8.695-38.59 18.45-55.064l-6.07-3.505c-10.23 17.29-16.995 36.87-19.346 57.79',
    number: 'M41.693 171.422l6.953-5.918c3.105-2.62 4.237-4.075 4.237-6.113 0-2.23-1.585-3.52-3.655-3.52-1.81 0-3.072.84-4.462 2.53-.292.33-.745.62-1.39.62-1.004 0-1.812-.81-1.812-1.81 0-.42.16-.87.452-1.23 1.844-2.29 3.946-3.65 7.503-3.65 4.43 0 7.47 2.72 7.47 6.76v.07c0 3.56-1.88 5.5-5.89 8.77l-4.66 3.88h9.18c.97 0 1.74.78 1.74 1.75s-.78 1.75-1.75 1.75H42.79c-1.227 0-2.133-.71-2.133-1.873 0-.774.356-1.42 1.035-1.97',
  },
  {
    fill: 'M137.37 103.36L92.974 42.064c-25.14 18.073-46.342 41.273-62.078 68.074l65.556 37.847C106.85 130.41 120.82 115.2 137.37 103.36',
    border: 'M31.584 109.958l64.69 37.35c10.37-17.4 23.957-32.218 40.395-44.062L92.86 42.763C68.2 60.58 47.027 83.796 31.582 109.958zm65.047 38.71L30.21 110.32l.256-.435c15.62-26.6 37.133-50.193 62.217-68.226l.405-.29 44.98 62.1-.408.29c-16.642 11.9-30.36 26.87-40.777 44.47l-.252.42z',
    crust: 'M96.453 147.987l6.07 3.505c9.904-16.72 23.203-31.193 38.957-42.456l-4.11-5.677c-16.55 11.84-30.52 27.05-40.917 44.62',
    number: 'M79 106.98c-.355-.29-.646-.84-.646-1.423 0-1.034.873-1.908 1.907-1.908.59 0 1.01.22 1.33.51 1.43 1.42 3.04 2.16 5.14 2.16 2.24 0 3.92-1.36 3.92-3.43v-.07c0-2.23-2.03-3.5-5.2-3.5h-.81c-.94 0-1.71-.78-1.71-1.72 0-.52.23-1 .84-1.62l5.31-5.66h-8.22c-.97 0-1.74-.777-1.74-1.715 0-.97.78-1.74 1.75-1.74h11.57c1.13 0 1.98.68 1.98 1.745 0 .94-.453 1.49-1.132 2.17l-5.34 5.53c3.43.42 6.66 2.168 6.66 6.34v.06c0 4.178-3.21 7.18-7.93 7.18-3.33 0-5.82-1.16-7.67-2.94',
  },
  {
    fill: 'M176.477 5.22c-30.637 6.436-58.947 19.193-83.502 36.845l44.394 61.294c16.31-11.68 35.12-20.08 55.47-24.24l-16.37-73.9z',
    border: 'M137.483 102.663c16.55-11.76 34.973-19.808 54.767-23.93l-16.154-72.92c-29.816 6.33-57.543 18.565-82.42 36.366l43.807 60.48zm-.227 1.39l-44.98-62.1.407-.294c25.232-18.14 53.39-30.57 83.69-36.93l.485-.1.107.48 16.48 74.39-.497.1c-20.008 4.1-38.608 12.22-55.288 24.16l-.404.29z',
    crust: 'M137.37 103.36l4.11 5.676c15.55-11.117 33.48-19.113 52.884-23.068l-1.516-6.847c-20.356 4.17-39.167 12.57-55.48 24.24',
    number: 'M149.894 56.942v-8.96l-7.44 8.96h7.44zm0 3.266H139.77c-1.067 0-1.94-.776-1.94-1.876 0-.776.29-1.423.873-2.102l10.64-12.613c.712-.84 1.262-1.293 2.426-1.293 1.06 0 1.94.84 1.94 1.907v12.72h1.74c.94 0 1.65.75 1.65 1.65 0 .91-.71 1.62-1.65 1.62h-1.75v3.37c0 1.07-.84 1.88-1.91 1.88-1.07 0-1.91-.81-1.91-1.87v-3.37z',
  },
  {
    fill: 'M222.298 76.152c10.513 0 20.763 1.12 30.646 3.23L268.587 5.32c-14.93-3.17-30.414-4.844-46.29-4.844-15.708 0-31.034 1.637-45.82 4.743l16.37 73.9c9.514-1.95 19.363-2.97 29.45-2.97',
    border: 'M222.298 75.652c10.163 0 20.34 1.055 30.26 3.136l15.436-73.083c-29.73-6.24-61.177-6.275-90.92-.1l16.156 72.928c9.51-1.912 19.286-2.88 29.068-2.88zm31.032 4.323l-.49-.105c-19.642-4.192-40.334-4.26-59.892-.26l-.482.1L175.88 4.834l.494-.104c30.188-6.34 62.14-6.306 92.317.1l.49.103-15.85 75.042z',
    crust: 'M222.298 76.152c-10.088 0-19.937 1.022-29.45 2.97l1.517 6.846c9.024-1.84 18.366-2.807 27.933-2.807 10.016 0 19.782 1.07 29.197 3.08l1.45-6.86c-9.884-2.1-20.134-3.22-30.647-3.22',
    number: 'M213.664 48.292c-.486-.388-.81-.906-.81-1.552 0-1.035.874-1.908 1.91-1.908.484 0 .84.13 1.163.388 1.553 1.26 3.138 1.908 4.916 1.908 2.555 0 4.4-1.52 4.4-3.913v-.065c0-2.328-1.942-3.75-4.69-3.75-2.2 0-3.267.775-3.882.775-.61 0-1.09-.226-1.87-.776-.58-.43-.81-1.01-.77-1.75l.49-8.15c.07-1.04.84-1.78 1.81-1.78h10.26c.97 0 1.78.81 1.78 1.78s-.8 1.74-1.77 1.74h-8.5l-.354 5.3c1.067-.39 2.07-.65 3.59-.65 4.43 0 7.89 2.33 7.89 7.11v.06c0 4.59-3.43 7.66-8.28 7.66-3.073 0-5.4-1.04-7.245-2.46',
  },
  {
    fill: 'M308.22 104.082l44.568-61.177c-24.7-18-53.26-31.017-84.202-37.586l-15.642 74.06c20.31 4.33 39.053 12.89 55.275 24.7',
    border: 'M253.536 78.997c19.766 4.288 38.122 12.49 54.573 24.387l43.98-60.368C327.06 24.86 299.1 12.38 268.97 5.912l-15.436 73.085zm54.793 25.783l-.41-.294c-16.58-12.07-35.11-20.352-55.09-24.616l-.49-.104 15.85-75.04.49.104c30.61 6.5 59 19.174 84.39 37.67l.4.296-45.16 61.984z',
    crust: 'M252.944 79.38l-1.45 6.86c19.326 4.128 37.16 12.267 52.597 23.507l4.13-5.665c-16.22-11.812-34.96-20.366-55.27-24.7',
    number: 'M300.613 60.987v-.064c0-2.264-1.81-3.946-4.625-3.946-2.814 0-4.593 1.81-4.593 3.978v.064c0 2.29 1.876 4.04 4.69 4.04 2.814 0 4.528-1.75 4.528-4.08m-10.738 5.14c-1.713-1.72-2.75-4.08-2.75-8.64v-.07c0-6.92 3.3-12.32 9.704-12.32 2.32 0 4.17.61 5.66 1.61.61.39 1 .9 1 1.71 0 1.03-.88 1.84-1.91 1.84-.36 0-.71-.13-1-.29-1.23-.81-2.36-1.27-3.88-1.27-3.37 0-5.24 3.04-5.44 7.18 1.19-1.16 2.75-2.26 5.43-2.26 4.36 0 7.89 2.59 7.89 7.11v.065c0 4.495-3.66 7.697-8.41 7.697-2.78 0-4.79-.875-6.31-2.395',
  },
  {
    fill: 'M348.67 148.877l65.55-37.845c-15.546-26.76-36.526-49.976-61.433-68.126l-44.568 61.176c16.39 11.937 30.2 27.197 40.45 44.795',
    border: 'M308.917 103.973c16.297 11.95 29.73 26.825 39.933 44.223l64.685-37.346c-15.463-26.486-35.86-49.105-60.638-67.245l-43.98 60.368zm39.57 45.587l-.25-.43c-10.248-17.607-23.81-32.627-40.313-44.644l-.404-.294 45.157-61.985.405.295c25.208 18.37 45.923 41.342 61.57 68.28l.252.433-66.416 38.345z',
    crust: 'M308.22 104.082l-4.13 5.665c15.605 11.362 28.756 25.886 38.51 42.636l6.07-3.506c-10.244-17.598-24.06-32.858-40.45-44.795',
    number: 'M353.457 109.686l8.796-16.3h-9.8c-.97 0-1.745-.776-1.745-1.746s.776-1.747 1.746-1.747h12.71c1.1 0 1.81.647 1.81 1.78 0 .58-.225 1.293-.55 1.907l-9.377 17.788c-.486.905-1.1 1.326-1.91 1.326-1.066 0-2.036-.647-2.036-1.78 0-.322.17-.872.36-1.228',
  },
  {
    fill: 'M367.566 206.45l75.263-7.977c-3.38-31.605-13.39-61.225-28.61-87.44l-65.55 37.845c10.04 17.258 16.65 36.76 18.89 57.572',
    border: 'M349.353 149.06c10.14 17.558 16.416 36.675 18.656 56.84l74.27-7.87c-3.34-30.625-12.84-59.658-28.25-86.315l-64.68 37.345zm17.77 57.94l-.054-.497c-2.2-20.373-8.53-39.676-18.84-57.374l-.26-.44 66.42-38.34.25.43c15.71 27.04 25.35 56.52 28.67 87.64l.05.49-76.26 8.08z',
    crust: 'M348.67 148.877l-6.072 3.505c9.566 16.43 15.86 34.994 17.997 54.806l6.97-.738c-2.24-20.812-8.85-40.314-18.896-57.572',
    number: 'M399.883 159.636v-.064c0-1.747-1.617-3.17-4.042-3.17s-4.04 1.423-4.04 3.137v.06c0 1.97 1.72 3.36 4.05 3.36s4.05-1.39 4.05-3.33m.71 9.99v-.07c0-2.07-2.07-3.43-4.75-3.43s-4.72 1.35-4.72 3.42v.06c0 1.84 1.75 3.39 4.72 3.39 2.98 0 4.76-1.52 4.76-3.397m-13.39.224v-.06c0-2.717 1.52-4.43 4.08-5.5-1.9-1-3.3-2.52-3.3-5.044v-.06c0-3.46 3.33-6.114 7.86-6.114s7.86 2.62 7.86 6.115v.065c0 2.523-1.39 4.043-3.3 5.045 2.49 1.133 4.08 2.75 4.08 5.435v.062c0 4.04-3.68 6.566-8.63 6.566-4.94 0-8.63-2.59-8.63-6.5',
  },
  {
    fill: 'M442.83 198.473l-75.264 7.976c.56 5.2.856 10.47.856 15.82 0 15.47-2.413 30.38-6.87 44.38l71.89 23.72c6.916-21.46 10.654-44.35 10.654-68.11 0-8.04-.432-15.98-1.266-23.8',
    border: 'M362.178 266.334l70.942 23.414c6.95-21.74 10.476-44.44 10.476-67.473 0-7.733-.407-15.554-1.21-23.252l-74.27 7.87c.535 5.128.806 10.3.806 15.382 0 15.017-2.27 29.838-6.744 44.06zm71.585 24.68l-.478-.16-72.36-23.88.15-.472c4.543-14.267 6.847-29.147 6.847-44.227 0-5.21-.287-10.517-.853-15.772l-.06-.498 76.26-8.082.05.497c.84 7.894 1.27 15.92 1.27 23.855 0 23.308-3.6 46.274-10.68 68.26l-.16.478z',
    crust: 'M368.422 222.275c0-5.348-.296-10.626-.856-15.825l-6.97.738c.534 4.957.816 9.99.816 15.087 0 14.706-2.29 28.876-6.518 42.18l6.658 2.2c4.457-13.998 6.87-28.906 6.87-44.38',
    number: 'M408.262 236.53v-.065c0-2.36-1.843-4.172-4.72-4.172-2.815 0-4.497 1.876-4.497 4.204v.065c0 2.36 1.81 4.042 4.625 4.042 2.878 0 4.592-1.875 4.592-4.074m-11.545 13.874c-.55-.356-.906-.906-.906-1.65 0-1.034.85-1.81 1.88-1.81.42 0 .78.13 1.1.323 1.33.938 2.59 1.39 4.14 1.39 3.33 0 5.34-2.943 5.47-7.115-1.13 1.36-2.88 2.394-5.3 2.394-4.82 0-8.02-2.814-8.02-7.18v-.065c0-4.43 3.43-7.82 8.44-7.82 2.85 0 4.69.81 6.31 2.4 1.65 1.68 2.72 4.17 2.72 8.64v.07c0 7.28-3.59 12.32-9.7 12.32-2.55 0-4.43-.74-6.11-1.87',
  },
  {
    fill: 'M331.232 319.657l56.327 50.546c20.45-22.84 36.25-49.947 45.88-79.822l-71.89-23.72c-6.32 19.85-16.76 37.85-30.32 53.01',
    border: 'M331.937 319.618l55.583 49.878c20.564-23.06 35.8-49.566 45.293-78.796l-70.94-23.413c-6.252 19.41-16.32 37.01-29.936 52.33zm55.66 51.29l-57.07-51.213.333-.372c13.8-15.427 23.967-33.2 30.215-52.82l.154-.483.48.16 72.36 23.88-.16.474c-9.58 29.71-25.05 56.626-45.99 80.002l-.34.373z',
    crust: 'M354.894 264.456c-6.01 18.915-15.958 36.074-28.88 50.52l5.218 4.68c13.56-15.156 24-33.158 30.32-53.002l-6.658-2.198z',
    number: 'M369.09 305.62l-2.33.713c-.225.065-.516.097-.71.097-.938 0-1.747-.777-1.747-1.682 0-.84.518-1.488 1.326-1.714l3.42-1.1c.77-.226 1.36-.355 1.94-.355h.06c1.13 0 1.97.87 1.97 1.97v19.14c0 1.1-.88 1.97-1.98 1.97-1.07 0-1.97-.88-1.97-1.98v-17.07zm8.894 7.634v-.065c0-6.47 3.945-11.71 9.896-11.71 5.92 0 9.83 5.17 9.83 11.64l.002.06c0 6.47-3.946 11.7-9.896 11.7-5.95 0-9.832-5.18-9.832-11.65zm15.62 0v-.065c0-4.47-2.296-8.12-5.79-8.12-3.492 0-5.723 3.55-5.723 8.05v.06c0 4.46 2.27 8.11 5.79 8.11 3.53 0 5.73-3.62 5.73-8.06z',
  },
];