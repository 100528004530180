import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import queryString from 'query-string';
import {
  getActivity,
  getCustomActivity,
  getArticle,
  getUnTokenArticle,
  getSlideshow,
  unfav,
  fav,
} from '../../api/content';
import { Types } from '../ducks/content';

const error = (type, err) => {
  console.warn(err);
  return Observable.of({ type, err });
};

const fetchActivity = (action$, { getState }) => action$.ofType(Types.fetchActivityRequested)
  .mergeMap(({ activityId, planId }) => {
    const { activeBabyId } = getState().families;
    const queryParams = queryString.parse(window.location.search);
    if (queryParams?.isCustom === 'true' || queryParams?.isCustom === true) {
      return getCustomActivity({ activityId, babyId: activeBabyId })
        .then((response) => {
          console.log('%c response', 'background: #332167; color: #B3D1F6; font-size: 16px', response);
          return response?.data?.data?.activity || response?.data?.custom_activity;
        })
        .then(activity => ({ type: Types.fetchCustomActivitySuccess, activity: { ...activity, dataForFavorites: activity } }))
        .catch(err => ({ type: Types.fetchCustomActivityError, err }));
    }
    return getActivity({ activityId, planId, babyId: activeBabyId })
      .then(response => response.data.data.activity)
      .then(activity => ({ type: Types.fetchActivitySuccess, activity: { ...activity, dataForFavorites: activity } }))
      .catch(err => ({ type: Types.fetchActivityError, err }));
  });

const fetchCustomActivity = ( action$, { getState } ) => action$.ofType(Types.fetchCustomActivityRequested)
  .mergeMap(({ activityId }) => {
    const { activeBabyId } = getState().families;
    return getCustomActivity({ activityId, babyId: activeBabyId })
      .then((response) => {
        console.log('%c response', 'background: #332167; color: #B3D1F6; font-size: 16px', response);
        return response.data.custom_activity;
      })
      .then(activity => ({ type: Types.fetchCustomActivitySuccess, activity: { ...activity, dataForFavorites: activity } }))
      .catch(err => ({ type: Types.fetchCustomActivityError, err }));
  });

const fetchArticle = ( action$, { getState } ) => action$.ofType(Types.fetchArticleRequested)
  .mergeMap(action => {
    const { activeBabyId } = getState().families;
    return getArticle({ id: action.id, babyId: activeBabyId})
      .then(response => response.data.data.article)
      .then(article => ({ type: Types.fetchArticleSuccess, article: { ...article, dataForFavorites: article } }))
      .catch(err => error(Types.fetchArticleError, err))
  });

const fetchUnTokenArticle = action$ => action$.ofType(Types.fetchUnTokenArticleRequested)
  .mergeMap(action => getUnTokenArticle(action.id)
    .then(response => response.data.data.article)
    .then(article => ({ type: Types.fetchUnTokenArticleSuccess, article: { ...article, dataForFavorites: article } }))
    .catch(err => error(Types.fetchUnTokenArticleError, err)));

const fetchSlideshow = action$ => action$.ofType(Types.fetchSlideshowRequested)
  .mergeMap(action => getSlideshow(action.id)
    .then(response => response.data.data.slideshow)
    .then(slideshow => ({ type: Types.fetchSlideshowSuccess, slideshow }))
    .catch(err => error(Types.fetchSlideshowError, err)));

const toggleFavorite = (action$, { getState }) => action$.ofType(Types.toggleFavoriteRequested)
  .mergeMap(action => Observable.of(action)
    .mergeMap((_action) => {
      const type = _action.contentType;
      const contentObject = getState().content[type][_action.id];
      const contentIsFaved = contentObject.faved;

      if (contentIsFaved) {
        return unfav[type](_action.id);
      }
      return fav[type](_action.id);
    })
    .map(res => ({ type: Types.toggleFavoriteSuccess, id: action.id, contentType: action.contentType }))
    .catch(err => ({ type: Types.toggleFavoriteError, err })));


const observers = {
  fetchActivity,
  fetchCustomActivity,
  fetchArticle,
  fetchUnTokenArticle,
  fetchSlideshow,
  toggleFavorite,
};

export default combineEpics(...Object.values(observers));
