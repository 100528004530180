import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import { Tabbordion, TabPanel, TabLabel, TabContent } from 'react-tabbordion';

import Icon from './Icon';
import { Default, ThemeShape } from './../themes/Collapsible';
import { colors } from '../../styles';

const Container = styled.div`
  [data-state="tabbordion"] {
    clip: rect(0 0 0 0);
    height: 1px;
    position: absolute;
    position: fixed;
    width: 1px;
    z-index: -1;
  }

  ${({ theme }) => theme.container};

  ${({ theme }) => theme.panel};

  ${({ theme }) => theme.title};

  ${({ theme }) => theme.content};

  ${({ theme }) => theme.animator};
`;

const IconContainer = styled.div`
  position: absolute;
  top: 17px;
  right: 20px;
`;

const blockElements = {
  animator: 'accordion-animator',
  content: 'accordion-content',
  panel: 'accordion-panel',
  label: 'accordion-title',
};

class Accordion extends React.Component {
  state = { activeTab: 0 };

  handleChange = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const { theme, items, style } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Container style={style}>
          <Tabbordion
            mode="multiple"
            blockElements={blockElements}
            className="accordion"
            name="accordion"
            animateContent="height"
          >
            {items.map((item, index) => (
              <TabPanel key={index}>
                <TabLabel>
                  {item.title}
                  <IconContainer>
                    <Icon
                      icon="chevron-down"
                      size={20}
                      color={colors.kinedu}
                      className="tab-arrow"
                    />
                  </IconContainer>
                </TabLabel>
                <TabContent>
                  {item.body}
                </TabContent>
              </TabPanel>
            ))}
          </Tabbordion>
        </Container>
      </ThemeProvider>
    );
  }
}

Accordion.propTypes = {
  /* Theme object styles */
  theme: ThemeShape,
  /* Array with the accordion panels object */
  items: PropTypes.arrayOf(PropTypes.shape({
    /* Title of the panel */
    title: PropTypes.string,
    /* Content of the panel to display when clicked */
    body: PropTypes.element,
  })),
  /* Inline styles */
  style: PropTypes.object,
};

Accordion.defaultProps = {
  theme: Default,
  items: [],
  style: {},
};

export default Accordion;
