import { range } from 'ramda';
import { Types as AuthTypes } from './values/auth';

export const LANGS = 'en|es|pt';
export const KEY_LOCALE = 'locale';

export const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DEC'];

export const days = [
  'WEEKDAY_NAME_SUN',
  'WEEKDAY_NAME_MON',
  'WEEKDAY_NAME_TUE',
  'WEEKDAY_NAME_WED',
  'WEEKDAY_NAME_THU',
  'WEEKDAY_NAME_FRI',
  'WEEKDAY_NAME_SAT',
];

export const MonthsList = {
  1: 'MONTH_NAME_JAN',
  2: 'MONTH_NAME_FEB',
  3: 'MONTH_NAME_MAR',
  4: 'MONTH_NAME_APR',
  5: 'MONTH_NAME_MAY',
  6: 'MONTH_NAME_JUN',
  7: 'MONTH_NAME_JUL',
  8: 'MONTH_NAME_AGO',
  9: 'MONTH_NAME_SEP',
  10: 'MONTH_NAME_OCT',
  11: 'MONTH_NAME_NOV',
  12: 'MONTH_NAME_DEC',
};

export const babyMonths = range(0, 49).map(m => ({ label: m, value: m }));

export const getDays = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

export const DayList = getDays.map(d => ({ label: d.toString(), value: d }));

export const getWeeks = () => {
  const list = [];
  for (let i = 22; i <= 45; i += 1) {
    list.push(i);
  }
  return list;
};

export const getYears = (numberOfYears) => {
  let currentYear = new Date().getFullYear();
  const list = [];
  for (let i = 0; i < numberOfYears; i += 1) {
    list.push(currentYear);
    currentYear -= 1;
  }
  return list;
};

export const YearList = getYears(70).map(y => ({ label: y.toString(), value: y }));
export const WeeksList = getWeeks().map(w => ({ label: w.toString(), value: w }));

export const ModalNames = {
  ADD_BABY: { name: 'AddBaby' },
  ADD_MEMBER: { name: 'AddMember' },
  BUY_GIFTCARD: { name: 'GiftCardsModal' },
  CHANGE_PASSWORD: { name: 'ChangePassword' },
  EDIT_BABY: { name: 'EditBaby' },
  EDIT_USER: { name: 'EditUser' },
  FATAL_ERROR: { name: 'FatalErrorModal' },
  ICONS_GRID: { name: 'IconsGridModal' },
  INVITE_SENT: { name: 'InviteSentModal' },
  MANAGE_FAMILIES: { name: 'ManageFamilies' },
  PAYMENT: { name: 'Payment' },
  PAYMENT_MODAL: { name: 'PaymentModal' },
  PREMIUM_BROKE: { name: 'PremiumBrokeModal' },
  PROMO_CODE: { name: 'PromoCodeModal' },
  REDEEM_GIFTCARD: { name: 'RedeemModal' },
  LOGIN_MODAL: { name: 'AuthModal', data: { authType: AuthTypes.LOGIN } },
  SIGNUP_MODAL: { name: 'AuthModal', data: { authType: AuthTypes.SIGNUP } },
  UNAMRKED_MILESTONES_MODAL: { name: 'UnmarkedMilestonesModal' },
  BEFORE_YOU_GO_MODAL: { name: 'BeforeYouGoModal' },
  WELCOME_PLAY_MODAL: { name: 'WelcomePlayModal' },
  WELCOME_LEARN_MODAL: { name: 'WelcomeLearnModal' }
};

export const MAX_ALLOWED_BABIES = 5;
export const LOCALSTORAGE_ACTIVE_BABY_KEY = 'active_baby';
export const LOCALSTORAGE_BABY_STATUS = 'baby_status';
export const LOCALSTORAGE_ACTIVE_FAMILY_KEY = 'active_family';
export const LOCALSTORAGE_API_KEY = 'api_key';
export const LOCALSTORAGE_USER_ID = 'user_id';
export const LOCALSTORAGE_GA_SIGNUP_EVENT_SENT = 'ga_signup_event_sent';
export const LOCALSTORAGE_GA_CREATEBABY_EVENT_SENT = 'ga_createbaby_event_sent';
export const LOCALSTORAGE_GA_ACTVIEW_EVENT_SENT = 'ga_actview_event_sent';
export const LOCALSTORAGE_GA_PURCHASE_EVENT_SENT = 'ga_purchase_event_sent';
export const LOCALSTORAGE_CALENDAR_BEARER_TOKEN = 'calendar:accessToken';
export const LOCALSTORAGE_CALENDAR_EVENTS = 'calendar_events';
export const LOCALSTORAGE_LEARN_ACTIVITIES_TOUCHED = 'learn_activities_touched';
export const LOCALSTORAGE_BABY_PROGRESS_SHOWED = 'baby_progress_showed';
export const LOCALSTORAGE_SOURCE_ACTIVITY_COMPLETED = 'source_activity_completed';
export const LOCALSTORAGE_TYPE_ACTIVITY_COMPLETED = 'type_activity_completed';
export const LOCALSTORAGE_SOURCE_ASK_AN_EXPERT = 'source_ask_an_expert';
export const MARK_AS_COMPLETE_TO_MILESTONES = 'mark_as_complete_to_milestones';

export const OBGenders = {
  male: 'A_BOY',
  female: 'A_GIRL',
  twins: 'TWINS',
};

export const Genders = {
  male: 'BOY',
  female: 'GIRL',
};

export const Units = {
  length: {
    cm: 'cm',
    in: 'in',
  },
  weight: {
    kg: 'kg',
    lb: 'lb',
  },
};

export const KG_PER_LB = 0.453592;
export const CM_PER_IN = 2.54;

export const IASteps = {
  WHATSNEW: 'whatsNew',
  REMINDER: 'reminder',
  WELCOME: 'welcome',
  ABOUT: 'about',
  READY: 'ready',
  SKILLS: 'skills',
  HEALTH_INTERESTS: 'healthInterests',
  DONE: 'done',
};

export const APP_IDS = {
  KINEDU: '548069311920318',
  TEST: '852572944910436',
};

export const OPTIMIZELY_PROJECT_IDS = {
  TEST: '10173611979',
};

export const SOURCE_TYPE = 'NES';

export const SIZE_ME_CONFIG = {
  monitorWidth: true,
  refreshRate: 500,
  refreshMode: 'throttle',
  noPlaceholder: false,
};

export const MOBILE_VIEWPORT_WIDTH = 480;
export const APPBAR_HEIGHT = '55px';
export const APPBAR_WEIGHT = '80px';

export const ContentTypes = {
  ACTIVITY: 'activity',
  ARTICLE: 'article',
  SLIDESHOW: 'slideshow',
  IN_DAP: 'in_dap',
  PAST_PLANS: 'pastplans',
  LAST_DAY: 'lastday',
  TOMORROW: 'tomorrow',
  SPREAD_WORD: 'spreadword',
};

export const hoykeyStyles = { overflowY: 'scroll', flex: '1 1 auto' };

export const ACTIONS_NAMES = {
  KONAMI_CODE: 'konamiCode',
};

export const ACTIONS_MAP = {
  [ACTIONS_NAMES.SECRET_MODAL]: 'left right up down left right up down',
  [ACTIONS_NAMES.KONAMI_CODE]: 'up up down down left right left right b a',
};

export const REGEX_FIRST_FOLDER_OF_URL = /^\/(.+?)([/?]|$)/;
