import BaseApi, { localeFlag, contextSource } from './BaseApi';

export const getIA = ({ babyId, includePercentiles = 'true' }) =>
  BaseApi.get(`/babies/${babyId}/progress/initial_assessments?percentiles=${includePercentiles}&${localeFlag()}`);
export const postAssesmentSkipped = id =>
  BaseApi.post(`/initial_assessments/${id}/set_skipped`);

// Tal vez debe ser sacado a su propio podulo cuando se integre catalogo y progress.
export const getIASkill = ({ babyId, skillId }) =>
  BaseApi.get(`/babies/${babyId}/progress/initial_assessments?in_skill=${skillId}&${localeFlag()}`);
export const getMasterMilestones = skillId =>
  BaseApi.get(`/skills/${skillId}/master_milestones?${localeFlag()}`);
export const postSkillMastered = ({ assessmentId, skillId }) =>
  BaseApi.post(`/initial_assessments/${assessmentId}/master?skill_id=${skillId}&${localeFlag()}&source=${contextSource()}`);
export const putAnswers = ({ answers, assessmentId, babyId }) =>
  BaseApi.put(`/babies/${babyId}/initial_assessments/${assessmentId}?${localeFlag()}&source=${contextSource()}`, { answers });
export const postTwinAnswers = twins =>
  BaseApi.post(`/initial_assessments/save_answers?${localeFlag()}`, { twins });
export const postNullReminder = assessmentId =>
  BaseApi.post(`/initial_assessments/${assessmentId}/set_reminder`, { remind_at: null });
