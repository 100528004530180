import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Well from '../../shared/Well';
import { colors, breakPoints } from '../../../styles';
import { Namespaces } from '../../../values/i18n';
import GraphInfo from '../../shared/GraphInfo/GraphInfo';

const StyledWell = styled(Well)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 10px;
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;

    ${breakPoints.smallTablet} {
      padding: 20px;
    }
`;

const InfoWrapper = styled.div`
  color: ${colors.shadeDark};
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`;

const GraphDot = styled(GraphInfo)`
  margin-right: 10px;

  ${breakPoints.smallTablet} {
      margin-right: 5px;
    }
`;
const GraphWell = ({ baby, style }) => (
  <StyledWell style={style}>
    <InfoWrapper>
      <GraphDot color={colors.cognitiveLight50}>90%</GraphDot>
      <GraphDot color={colors.physicalLight50}>75%</GraphDot>
      <GraphDot color={colors.linguisticLight50}>25%</GraphDot>
      <GraphDot color={colors.shadeMediumLight}>10%</GraphDot>
      <GraphDot color={colors.kinedu}>{baby.name}</GraphDot>
    </InfoWrapper>
  </StyledWell>
);

GraphWell.propTypes = {
  style: PropTypes.object,
  baby: PropTypes.object,
};

GraphWell.defaultProps = {
  style: {},
  baby: {
    name: 'Your baby',
  },
  t: () => { },
  percentile: 0,
};

export default withTranslation([Namespaces.PROGRESS])(GraphWell);
