import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors, breakPoints } from '../../styles';

export const Default = {
  container: css`
    color: ${colors.shadeDark};
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0;
  `,
  level1: css`
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;

    ${breakPoints.smallTablet} {
      font-size: 48px;
      letter-spacing: -1px;
      line-height: 54px;
    }
  `,
  level2: css`
    color: ${colors.kinedu};
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1.4px;

    ${breakPoints.smallTablet} {
      font-size: 40px;
      letter-spacing: -2px;
    }
  `,
  level3: css`
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.75px;

    ${breakPoints.smallTablet} {
      font-size: 24px;
      letter-spacing: -1px;
    }
  `,
  level4: css`
    color: ${colors.kinedu};
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -1.2px;

    ${breakPoints.smallTablet} {
      font-size: 24px;
    }
  `,
  level5: css`
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;

    ${breakPoints.smallTablet} {
      font-size: 20px;
    }
  `,
};

export const ThemeShape = PropTypes.shape({
  container: PropTypes.array,
  level1: PropTypes.array,
  level2: PropTypes.array,
  level3: PropTypes.array,
  level4: PropTypes.array,
  level5: PropTypes.array,
});
