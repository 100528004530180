/* eslint-disable camelcase */
import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { replace } from 'connected-react-router';
import { path } from 'ramda';

import { Types } from '../ducks/dap';
import { Dap, Families, Modals } from '../ducks';

import * as DapApi from '../../api/dap';
import * as ContentApi from '../../api/content';
import * as BabiesApi from '../../api/babies';
import * as ShareApi from '../../api/shares';
import { getActivityShareToUnlockIntents } from '../../lib/sharing';
import EventReporter, { Events } from '../../lib/EventReporter';
import { AreaNameById } from 'values/areas';
import { fixActivityName, fixAreaName } from 'lib/utils';
import { LOCALSTORAGE_SOURCE_ACTIVITY_COMPLETED, LOCALSTORAGE_TYPE_ACTIVITY_COMPLETED, MARK_AS_COMPLETE_TO_MILESTONES } from 'shared-values';
import { parse } from 'query-string';
import { VALUES_PARAMS_BY_SECTION } from 'components/catalgoRedesign/values';

const DAP_FINISHED_ERROR_CODE = 'dap_finished';
const DAP_NOT_FOUND = 'resource_not_found';
// const DAP_NOT_FINISHED = 'existing_dap_not_finished';

const error = (type, err) => {
  console.error(err);
  return Observable.of({ type, err });
};

function normalizePlanSchema(plan) {
  const instances = {};
  const instanceOrder = [];
  // Used to compute the previous and next activity.
  const contentIds = {
    slideshow: [],
    article: [],
    activity: [],
  };
  // Extract the instances for each day of the dap.
  const days = plan.days.map((day) => {
    const dayItems = day.items.filter(i => i.type !== 'in_dap');
    const items = dayItems.map((item, index) => {
      // activities, articles and slideshows.
      if (item.instance_id) {
        instances[item.instance_id] = {
          ...item.content, type: item.type, instance_id: item.instance_id, index,
        };
        instanceOrder.push(item.instance_id);
        contentIds[item.type].push(item.content.id);
        return { type: item.type, instance_id: item.instance_id };
        // indaps
      } if (item.type === 'in_dap') {
        instances[item.content.code] = {
          ...item.content.web,
          creative: item.content.creative,
          type: 'in_dap',
          code: item.content.code,
        };

        return { type: item.type, instance_id: item.content.code };
      }
      return [];
    });

    return { ...day, items };
  });

  return {
    ...plan, instances, instanceOrder, days, contentIds, fetchAt: (new Date()).getTime(),
  };
}


const formatAnswersForRequest = milestones => milestones?.map(m => ({ milestone_id: m?.milestone_id, answer: m?.answer ? 1 : 0 }));

// const PLAN_EXPIRATION_MILISECONDS = 90000;
// const shouldServeCachedPlan = fetchAt =>
//   fetchAt + PLAN_EXPIRATION_MILISECONDS > (new Date()).getTime();

// Epics
const fetchPlan = (action$, { getState }) => action$.ofType(Types.fetchPlanRequested)
// .takeWhile(() => action$.type !== Types.fetchPlanError)
  .take(15) // FIXME: There is a race condition here, so I limited to 15 iterations max.
  .mergeMap((action) => {
    // const { activePlanId, currentPlanId, plans } = getState().dap;
    const { activeBabyId } = getState().families;
    const { timeZone } = getState().user;
    const resolvedPlanId = action.id;
    // // If the plan is already loaded in the view, cancel the request
    // if (Number(resolvedPlanId) === Number(activePlanId) &&
    //   shouldServeCachedPlan(plans[activePlanId].fetchAt)) {
    //   return [{ type: Types.fetchPlanCanceled }];
    //   // If the plan requested is current and already in state
    // } else if (plans[resolvedPlanId] && shouldServeCachedPlan(plans[resolvedPlanId].fetchAt)) {
    //   return [{
    //     type: Types.fetchPlanSuccess,
    //     plan: plans[resolvedPlanId],
    //     isCurrent: false,
    //     cached: true,
    //   }];
    // }
    // Request plan otherwise
    if (activeBabyId) {
      return DapApi.getPlan({ planId: resolvedPlanId, babyId: activeBabyId, timeZone })
        .then(res => res.data.data.activity_plan)
        .then(plan => ({
          type: Types.fetchPlanSuccess,
          plan: normalizePlanSchema(plan),
          isCurrent: action.id === 'current',
        }))
        .catch((err) => {
          // Generate another plan if current plan is finished or not has not been created yet.
          const code = path(['response', 'data', 'error_data', 'code'], err);
          console.error('Fetch plan error code:', code);
          if (code === DAP_FINISHED_ERROR_CODE || code === DAP_NOT_FOUND) {
            return { type: 'CREATE_PLAN' };
          }
          return { type: Types.fetchPlanError, err };
        });
    }

    return new Promise((resolve, reject) => {
      window.location.href = '/login';
      reject({
        type: Types.fetchPlanError,
      });
    });
  });

const createPlan = (action$, { getState }) => action$.ofType('CREATE_PLAN')
  .mergeMap(({ forced = false }) => {
    const { activeBabyId } = getState().families;
    if (activeBabyId) {
      return DapApi.createPlan(activeBabyId, forced)
        .then(res => res.data.data.activity_plan)
        .then(plan => normalizePlanSchema(plan))
        .then(plan => ({ type: Types.fetchPlanSuccess, plan, isCurrent: true }))
        .catch(err => ({ type: Types.fetchPlanError, err }));
    }
    return new Promise((resolve, reject) => {
      window.location.href = '/login';
      reject({
        type: Types.fetchPlanError
      });
    });
  });

const fetchPlanSuccess = action$ => action$.ofType(Types.fetchPlanSuccess)
  .mergeMap(action => [
    { type: Types.fetchMaterialsRequested, id: action.plan.id },
    { type: Types.checkIfBabyTurns24 },
  ]);

export const checkIfBabyTurns24 = (action$, { getState }) => action$.ofType(Types.checkIfBabyTurns24)
  .mergeMap(() => {
    const baby = Families.Selectors.activeBaby(getState());
    if (baby) {
      const isOlderThan24 = baby.pending_premature_notification;
      if (isOlderThan24) {
        return Observable.of({ type: Types.showBabyTurns24Modal, baby });
      }
    }
    return Observable.of({ type: 'DONT_SHOW_MODAL', name: 'BabyTurns24Modal' });
  });

const onBabyTurnsModalClosed = stream$ => stream$
  .filter(({ type, name }) => (type === 'MODAL_CLOSED' && name === 'BabyTurns24Modal'))
  .take(1)
  .map(action => action);

export const showBabyTurns24Modal = action$ => action$
  .ofType(Types.showBabyTurns24Modal)
  .mergeMap(({ baby }) => Observable.concat([
    Modals.Creators.openModal({
      name: 'BabyTurns24Modal',
      data: {
        baby,
      },
    })],
  onBabyTurnsModalClosed(action$)
    .mergeMap(() => BabiesApi.postBabyTurns24({ babyId: baby.id })
      .then(() => ({ type: 'BABY_TURNS24', baby })))));

const fetchMaterialsForPlan = (action$, { getState }) => action$.ofType(Types.fetchMaterialsRequested)
  .mergeMap((action) => {
    const { currentPlanId } = getState().dap;
    const { activeBabyId } = getState().families;

    const normalizedPlanId = action.id !== 'current' ? action.id : currentPlanId;
    const materialsForRequestedPlan = Dap.Selectors.materialsForPlan(getState(), normalizedPlanId);

    if (materialsForRequestedPlan) {
      return [{ materials: materialsForRequestedPlan, planId: normalizedPlanId }];
    }

    return DapApi.getMaterialsForPlan({ planId: normalizedPlanId, babyId: activeBabyId })
      .then(res => res.data.data.materials)
      .then(res => res.map(day => day.materials))
      .then(materials => ({ planId: normalizedPlanId, materials }));
  })
  .map(({ materials, planId }) => ({ type: Types.fetchMaterialsSuccess, planId, materials }))
  .catch(err => error(Types.fetchMaterialsError, err));

const setActivityCompleted = (action$, { getState }) => action$.ofType(Types.setActivityCompletedRequested)
  .mergeMap(({ activityId, instanceId, source } = {}) => {
    try {
      const { activePlanId: planId } = source !== 'wap' ? getState()?.dap || {} : {};
      const { activeBabyId: babyId } = getState()?.families || {};
      const isSourceWap = (source === 'wap' || source === 'catalog');
      if ((instanceId || isSourceWap) && activityId && planId && babyId) {
        return DapApi.postActivityCompleted({
          activityId,
          instanceId,
          planId,
          babyId,
        })
          .then(() => ({ type: Types.setActivityCompletedSuccess, instanceId }))
          .catch(err => ({ type: Types.setActivityCompletedError, err }));
      }
      return { type: Types.setActivityCompletedError };
    } catch (catchError) {
      return { type: Types.setActivityCompletedError, catchError };
    }
  });

const answerMilestones = (action$, { getState }) => action$
  .ofType(Types.answerMilestonesRequested)
// investigar esto, switchmap es lo correcto pero rompe el stream al fallar 1 vez.
  .mergeMap(({
    activityId,
    instanceId,
    milestones,
    planId,
    areaId,
    image,
    baby,
    source,
    index,
    activity_type,
  }) => Observable.concat(
    Observable.of(Modals.Creators.openModal({
      name: 'MilestonesModal',
      data: {
        milestones, areaId, image, baby,
      },
    })),
    action$
      .filter(action => Modals.Types.modalSubmited && action.name === 'MilestonesModal')
      .mergeMap(({ data }) => {
        const { activeBabyId } = getState().families;
        const area = AreaNameById[areaId] ? AreaNameById[areaId].toLowerCase() : '';
        const isOFMilestone = window.localStorage.getItem(MARK_AS_COMPLETE_TO_MILESTONES) === 'true';

        try {
          if (data) {
            return DapApi.postMilestonesAnswers({
              planId,
              babyId: activeBabyId,
              answers: formatAnswersForRequest(data),
              activityId,
            }).then((res) => {
              EventReporter.action(Events.MILESTONES_UPDATE({ source, index }));
              const areaName = AreaNameById[areaId];
              let paramSource = localStorage.getItem(LOCALSTORAGE_SOURCE_ACTIVITY_COMPLETED) || source;
              const { search } = window.location;
              const queryParams = parse(search);
              let activityType = localStorage.getItem(LOCALSTORAGE_TYPE_ACTIVITY_COMPLETED) || fixActivityName(activity_type, area) || activity_type;
              let areaNameParam = fixAreaName(`${areaName}`.toLowerCase());

              if (
                `${queryParams?.from}`.toLowerCase() === VALUES_PARAMS_BY_SECTION.RECENTLY.ID
              ) {
                paramSource = VALUES_PARAMS_BY_SECTION.RECENTLY.SOURCE;

                if (`${queryParams?.type}`.toLowerCase() === VALUES_PARAMS_BY_SECTION.RECENTLY.TYPE) {
                  activityType = (`${activity_type}`.toLowerCase().includes('webinar')) ? 'webinarSession' : 'interactiveSession';
                  areaNameParam = VALUES_PARAMS_BY_SECTION.RECENTLY.AREA_FOR_CLASS;
                }
              }

              EventReporter.action(Events.DAP_ACTIVITY_COMPLETED({
                source: paramSource,
                index,
                area: areaNameParam,
                activity_type: activityType,
                baby_id: baby.id,
                id: activityId,
              }));
              localStorage.setItem(LOCALSTORAGE_SOURCE_ACTIVITY_COMPLETED, '');
              localStorage.setItem(LOCALSTORAGE_TYPE_ACTIVITY_COMPLETED, '');
              return { dopamineShot: res.data.data.dopamine_shot };
            }).catch(err => ({ type: Types.answerMilestonesError, err }));
          } else {
            window.localStorage.removeItem(MARK_AS_COMPLETE_TO_MILESTONES);
            if(!isOFMilestone) {
              return [
                { type: Types.setActivityCompletedRequested, instanceId: instanceId || 0, activityId, source },
              ];
            }
          }
        } catch (error) {
          window.localStorage.removeItem(MARK_AS_COMPLETE_TO_MILESTONES);
          if (!isOFMilestone) {
            return [
              { type: Types.setActivityCompletedRequested, instanceId: instanceId || 0, activityId, source },
            ];
          }
        }
      })
      .take(1)
      .mergeMap(({ dopamineShot }) => {
        window.localStorage.removeItem(MARK_AS_COMPLETE_TO_MILESTONES);
        // TODO: Reivsar que source se mande bien.
        console.log('%c  source', 'background: #332167; color: #b3d1f6; font-size: 16px', source);
        if (source !== 'catalog') {
          return [
            { type: Types.answerMilestonesSuccess, dopamineShot, baby },
            { type: Types.setActivityCompletedRequested, instanceId, activityId, source },
          ];
        }
        return [
          { type: Types.answerMilestonesSuccess, baby },
          { type: Types.setActivityCompletedRequested, instanceId: instanceId || 0, activityId, source },
        ];
      })
      .catch(() => Observable.of({ type: 'error' })),
  ));

const mapDopamineToModal = action$ => action$
  .ofType(Types.answerMilestonesSuccess)
  .map(({ dopamineShot, baby }) => {
    if (!dopamineShot) {
      return { type: 'DONT_SHOW_MODAL', name: 'ActivityCompletedModal' };
    } if (dopamineShot === 'nps_survey' || dopamineShot === 'price_survey') {
      return { type: 'START_NPS' };
    } if (dopamineShot === 'satisfaction_survey') {
      return { type: 'START_SATISFACTION_SURVEY' };
    }

    return Modals.Creators.openModal({ name: 'DopamineModal', data: { dsType: dopamineShot, baby } });
  });

const unlockActivity = (action$, { getState }) => action$.ofType(Types.unlockActivityWithShareRequested)
  .mergeMap(async ({ instanceId, activityId, title }) => {
    const w = window.open('');
    const baby = Families.Selectors.activeBaby(getState());
    try {
      const { facebook, token } = await getActivityShareToUnlockIntents({
        instanceId, activityId, title, baby,
      });
      w.location = facebook;
      await ShareApi.confirmLinkShared({
        token, instanceId, type: 'activity', activityId,
      });
      return { type: Types.unlockActivityWithShareSuccess, instanceId };
    } catch (err) {
      return { type: Types.unlockActivityWithShareError };
    }
  });

const markActivityAsCompleted = (action$, { getState }) => action$.ofType(Types.markActivityAsCompleted)
  .mergeMap((props) => {
    const {
      milestones, activityId, instanceId,
      planId, areaId, image, source, index,
      activity_type, history
    } = props;

    const baby = Families.Selectors.activeBaby(getState());
    if (!milestones || milestones < 1) {
      const areaName = AreaNameById[areaId];
      const area = AreaNameById[areaId] ? AreaNameById[areaId].toLowerCase() : '';
      let activityType = localStorage.getItem(LOCALSTORAGE_TYPE_ACTIVITY_COMPLETED) || fixActivityName(activity_type, area) || activity_type;

      let areaNameParam = fixAreaName(`${areaName}`.toLowerCase());

      const { search, pathname } = window.location;
      const queryParams = parse(search);
      let paramSource = localStorage.getItem(LOCALSTORAGE_SOURCE_ACTIVITY_COMPLETED) || source;

      if (
        `${queryParams?.from}`.toLowerCase() === VALUES_PARAMS_BY_SECTION.RECENTLY.ID
      ) {
        paramSource = VALUES_PARAMS_BY_SECTION.RECENTLY.SOURCE;

        if (`${queryParams?.type}`.toLowerCase() === VALUES_PARAMS_BY_SECTION.RECENTLY.TYPE) {
          activityType = (`${activity_type}`.toLowerCase().includes('webinar')) ? 'webinarSession' : 'interactiveSession';
          areaNameParam = VALUES_PARAMS_BY_SECTION.RECENTLY.AREA_FOR_CLASS;
        }
      }


      EventReporter.action(Events.DAP_ACTIVITY_COMPLETED({
        source: paramSource,
        index,
        area: areaNameParam,
        activity_type: activityType,
        baby_id: baby.id,
        id: activityId,
      }));
      localStorage.setItem(LOCALSTORAGE_SOURCE_ACTIVITY_COMPLETED, '');
      localStorage.setItem(LOCALSTORAGE_TYPE_ACTIVITY_COMPLETED, '');
      // setTimeout(() => {
      //   // window.location.reload();
      // }, 4000);

      if (history) {
        history.push(
          {
            search: new URLSearchParams({
              ...queryParams,
              isCompleted: true,
              goBack: -2,
            }).toString(),
          },
          { from: `${pathname}?${search}` },
        );
      }

      return [{ type: Types.setActivityCompletedRequested, activityId, instanceId: instanceId || 0, source }];
    }

    // const baby = Families.Selectors.activeBaby(getState());

    const typesReturns = [{
      type: Types.answerMilestonesRequested,
      activityId,
      instanceId,
      milestones,
      planId,
      areaId,
      image,
      baby,
      source,
      index,
      activity_type,
    }];

    if (instanceId) {
      typesReturns.push({
        type: Types.setActivityCompletedRequested, activityId, instanceId: instanceId || 0, source
      });
    }

    return typesReturns;
  });

const fetchActivitySuggestions = action$ => action$.ofType(Types.fetchChangeActivitySuggestionsRequested)
  .mergeMap(({ babyId, activityId, option }) => DapApi.getChangeActivitySuggestions({ babyId, activityId, option })
    .then(res => res.data.data)
    .then(({ activities, code }) => ({ type: Dap.Types.fetchChangeActivitySuggestionsSuccess, activities, code })));

const changeActivity = action$ => action$.ofType(Types.changeActivityRequested)
  .mergeMap(({
    babyId, instanceId, newActivityId, option,
  }) => DapApi.postChangeActivity({
    babyId, instanceId, newActivityId, option,
  })
    .then(res => res.data.data)
    .then(({ instance_id, content }) => ({ type: Dap.Types.changeActivitySuccess, instance_id, content }))
    .catch(err => ({ type: Types.changeActivityError, err })));

const goToActivity = action$ => action$.ofType(Types.changeActivitySuccess)
  .map(({ instance_id: insId, content }) => replace(`/activity/${content.id}?context=dap&instanceId=${insId}`));

const setArticleAsRead = action$ => action$.ofType(Types.setArticleAsRead)
  .mergeMap(({ articleId, instanceId }) => ContentApi.postArticle(articleId, instanceId)
    .then(() => ({ type: 'SET_ARTICLE_READ' }))
    .catch(err => console.error(err)));

const setArticleMarkAsRead = action$ => action$.ofType(Types.setArticleMarkAsRead)
  .mergeMap(({ articleId, instanceId, babyId }) => ContentApi.postArticleMarkAsRead({ babyId, articleId, instanceId })
    .then(() => {
      window.location.reload();
      return ({ type: 'SET_ARTICLE_READ' });
    })
    .catch(err => console.error(err)));

const observers = {
  fetchPlan,
  fetchMaterialsForPlan,
  fetchPlanSuccess,
  createPlan,
  markActivityAsCompleted,
  setActivityCompleted,
  answerMilestones,
  unlockActivity,
  fetchActivitySuggestions,
  changeActivity,
  goToActivity,
  mapDopamineToModal,
  setArticleAsRead,
  checkIfBabyTurns24,
  showBabyTurns24Modal,
  setArticleMarkAsRead,
};


export default combineEpics(...Object.values(observers));
