import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import Button from '../shared/buttons/MainButton';

import NeoModal from './NeoModal';
import { colors, breakPoints } from '../../styles';

import Dr from '../../images/DrBlue.svg';

const DrImage = styled.img.attrs({ src: Dr })`
  width: 118px;
  display: block;
  margin: 0 20px;
`;

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  margin: auto 20px;

  ${breakPoints.smallTablet} {
    margin: auto;
  }
`;

const Title = styled.h2`
  color: ${colors.kinedu};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  letter-spacing: -0.55px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const Description = styled.div`
  color: ${colors.dustyGray};
  font-size: 16px;
  margin: 20px 0;

  ${breakPoints.smallTablet} {

  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakPoints.smallTablet} {
    flex-direction: row;
    align-items: center;
  }
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'ScienceBehindModal',
  data: {
  },
}

*/

const ScienceBehindModal = ({ t }) => (
  <Modal width={600} roundedCorners>
    {({ closeModal }) => (
      <Fragment>
        <Content>
          <Title>{t('MODAL_SCIENCE_BEHIND.TITLE')}</Title>
          <Body>
            <DrImage />
            <Description>
              {t('MODAL_SCIENCE_BEHIND.DESCRIPTION')}
            </Description>
          </Body>
          <Button color="green" style={{ maxWidth: '280px' }} onClick={closeModal}>
            {t('MODAL_SCIENCE_BEHIND.OK_GOT_IT')}
          </Button>
        </Content>
      </Fragment>
    )}
  </Modal>
);

ScienceBehindModal.propTypes = {
  t: PropTypes.func,
};

ScienceBehindModal.defaultProps = {
  t: () => {},
};

export default withTranslation([Namespaces.MODALS])(ScienceBehindModal);
