import React from 'react';
import styled from 'styled-components';
import Icon from '../shared/Icon';
import { colors } from '../../styles';

const IconContainer = styled.div``;

const TopBarContainer = styled.div`
  width: 100%;
  height: 55px;
  background-color: ${colors.kinedu};

  display: flex;
  align-items: center;
  padding: 0 25px;
  box-sizing: border-box;
  flex-shrink: 0;

  z-index: 0;
`;

const TopBar = ({ open, toggle, goBack, mustHaveBack }) => (
  <TopBarContainer open={open}>
    <IconContainer>
      {mustHaveBack ?
        <Icon icon="chevron-left" color="white" onClick={goBack} />
        :
        <Icon icon="burger" color="white" onClick={toggle} />
      }
    </IconContainer>
  </TopBarContainer>
);

export default TopBar;
