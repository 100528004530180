import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';
import { Creators } from '../../data/ducks/health-interests';
import { equalAt } from '../../lib/utils';

const mapStateToProps = state => ({
  babyId: state.families.activeBabyId,
  interests: state.healthInterests.interests,
});
const mapDispatchToProps = dispatch => ({
  fetchInterests: bind(Creators.fetchHealthInterestsRequested, dispatch),
  postHealthInterests: bind(Creators.postHealthInterests, dispatch),
});

const withHealthInterests = WrappedComponent => connect(mapStateToProps, mapDispatchToProps)(
  class HealthInterests extends React.Component {
      componentDidMount = () => {
        this.props.fetchInterests(this.props.babyId);
      }

      componentWillReceiveProps = (newProps) => {
        if (!equalAt(this.props, newProps, ['interests'])) {
          this.setState({ interests: newProps.interests });
        }
      }

      render() {
        const { interests, postHealthInterests, babyId } = this.props;
        return (
          <WrappedComponent
            {...this.props}
            interests={interests}
            updateHealthInterest={postHealthInterests}
            babyId={babyId}
          />
        );
      }
  },
);

export default (withHealthInterests);
