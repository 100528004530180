import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SandboxView from 'components/views/SandboxView';
import AuthLoginView from 'components/views/Auth/Login';
import AuthSignupView from 'components/views/Auth/Signup';
import Home from '../components/landing/pages/Home';
import SignupPageComponent from '../components/landing/pages/Signup';
import LoginPageComponent from '../components/landing/pages/Login';
import AuthRestorePasswordView from 'components/views/Auth/RestorePassword';

import { LANGS } from '../shared-values';

const routes = [
  { path: `/:lng(${LANGS})?/login/deprecate`, component: LoginPageComponent },
  { path: `/:lng(${LANGS})?/login`, component: AuthLoginView },
  { path: `/:lng(${LANGS})?/signup/deprecate`, component: SignupPageComponent },
  { path: `/:lng(${LANGS})?/signup`, component: AuthSignupView },
  { path: `/:lng(${LANGS})?/password/restore-deprecated/:token`, component: Home },
  { path: `/:lng(${LANGS})?/password/restore/:token`, component: AuthRestorePasswordView },
  { path: '/sandbox', component: SandboxView },
];

const NoAuthRoutes = () => (
  <Switch>
    {routes.map(r => (
      <Route {...r} key={r.path} />
    ))}
    <Route render={() => <Redirect to="/login" />} />
  </Switch>
);

export default NoAuthRoutes;
