/* eslint-disable react-hooks/exhaustive-deps */
// REACT
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { RendererIf, Button, Spinner } from 'lego-dist';

import { getSkuDataByFormat } from 'helpers/skus';
import EventReporter, { Events } from 'lib/EventReporter';

import usePrices from 'hooks/usePrices';
import useLeanplum, { LOAD_OPTIONS } from 'hooks/leanplum/useLeanplum';
import useUser from 'hooks/useUser';
import { APP_SECTIONS_PATHS } from 'router/AuthGuardedRoutes';
import { createSesionSubscription } from 'helpers/stripe-helper';
import PaywallWrapper, {
  PaywallContent,
  PaywallContentImage,
  PaywallContentBtns,
  PaywallImage,
  PaywallContentLinks,
  InfoPrice,
  PaywallLink,
} from './styles';
import { DATA_FOR_PP_PAYMENT_PROVIDER_PAYWALL_KEY } from './values';

// import { useSelector } from 'react-redux';

let timeoutRetriCreateSession;

const PaywallComponent = () => {
  const { user } = useUser();
  const history = useHistory();
  const { t: translation } = useTranslation();
  const { state: { isOtherRoute = false, fromPath } = {} } = useLocation();
  const { paywallData } = useLeanplum({
    useForce: true,
    load: LOAD_OPTIONS.PAYWALL,
  });
  const { prices, fetch } = usePrices();

  const [isSubmit, setIsSubmit] = useState(false);
  const [price, setPrice] = useState(false);

  const handleOnClickClose = () => {

    EventReporter.action(Events.PAYWALL_DISMISS());

    if (isOtherRoute) {
      history.push(fromPath);
    } else {
      history.push(APP_SECTIONS_PATHS.IA);
    }
  };

  const handleSubscription = async () => {
    setIsSubmit(true);
    const source = 'paywallPlayLearn';
    const path = 'OB';
    const plan = 'play_learn';
    const skuInfo = getSkuDataByFormat(price.nickname);

    const dataEvent = {
      payment_source: source,
      payment_path: path,
      type: skuInfo.path,
      subscription_type: skuInfo.recurrence,
      sku: price.nickname,
    };
    EventReporter.action(Events.PP_PAYMENT_STARTED(dataEvent));

    window.localStorage.setItem(DATA_FOR_PP_PAYMENT_PROVIDER_PAYWALL_KEY, JSON.stringify({
      ...dataEvent,
      response: 'success',
      price: price.amount_usd || price.base_price_text,
    }));

    const routeTap = `${window.location.origin}/app/tap`;
    const payload = {
      user,
      plan,
      priceId: price.id,
      source: 'Paywall',
      ...(isOtherRoute && {
        routes: {
          successUrl: `${routeTap}?upgrade=${plan}`,
          cancelUrl: routeTap,
        },
      }),
    };

    const stripeSession = await createSesionSubscription(payload);
    if (stripeSession?.error) {
      timeoutRetriCreateSession = setTimeout(() => {
        handleSubscription();
      }, 3000);
    } else {
      window.location.href = stripeSession?.url;
    }
  };

  useEffect(() => {
    EventReporter.action(Events.S_PAYWALL());
  }, []);

  useMemo(() => {
    if (paywallData?.webSku) {
      fetch([paywallData.webSku]);
    }
  }, [paywallData]);

  useMemo(() => {
    if (prices?.length) {
      const [priceItem] = prices;
      setPrice(priceItem);
    }
  }, [prices]);

  useEffect(() => {
    return () => {
      if (timeoutRetriCreateSession) {
        clearTimeout(timeoutRetriCreateSession);
      }
    };
  }, []);

  return (
    <RendererIf
      condition={paywallData && price?.id}
      alternative={(
        <Spinner
          loading
          name="threedots"
          position="absolute"
          bgColor="transparent"
        />
      )}
    >
      <PaywallWrapper>
        <PaywallContent>
          <PaywallContentImage>
            <PaywallImage className="desktop" src={paywallData?.webImage} />
            <PaywallImage className="mobile" src={paywallData?.webImageResp} />
          </PaywallContentImage>
          <PaywallContentBtns>
            <Button
              className="btn btn-primary paywall_button_primary"
              color="#73b84e"
              rounded
              flat
              small
              outline
              type="button"
              disabled={isSubmit}
              loading={isSubmit}
              onClick={handleSubscription}
            >
              {paywallData?.webCopyButton}
            </Button>
            <Button
              className="btn btn-secondary paywall_button_secondary"
              color="#73b84e"
              rounded
              small
              type="button"
              disabled={isSubmit}
              onClick={handleOnClickClose}
            >
              {translation('paywall:BTN_SECONDARY')}
            </Button>
            <InfoPrice>
              {`${price.currency_symbol} ${price.unit_amount_decimal} ${paywallData?.webPromoText}`}
            </InfoPrice>
          </PaywallContentBtns>
          <PaywallContentLinks>
            <PaywallLink>
              {translation('paywall:RECURRING_BILLING.TEXT')}
            </PaywallLink>
            <PaywallLink
              target="_blank"
              href={translation('paywall:TERMS_CONDITIONS.URL')}
            >
              {translation('paywall:TERMS_CONDITIONS.TEXT')}
            </PaywallLink>
            <PaywallLink
              target="_blank"
              href={translation('paywall:PRIVACY_POLICY.URL')}
            >
              {translation('paywall:PRIVACY_POLICY.TEXT')}
            </PaywallLink>
          </PaywallContentLinks>
        </PaywallContent>
      </PaywallWrapper>
    </RendererIf>
  );
};

export default PaywallComponent;
