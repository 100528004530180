import { REHYDRATE } from 'redux-persist/lib/constants';
import i18n from '../../i18n';
import { Settings } from '../../values/settings';
import { Types } from '../ducks/settings';

export const changeLang = action$ =>
  action$.ofType(Types.settingChanged)
    .filter(({ name }) => name === Settings.LANG)
    .do(({ value: lang }) => i18n.changeLanguage(lang))
    .mergeMap(() => ([
      { type: 'LANG_CHANGED' },
      { type: 'RESET_DAP' },
      // { type: 'RESET_CONTENT' },
      // { type: 'RESET_PAST_PLANS' },
    ]));

export const setDefaultLang = (action$, { getState }) =>
  action$.ofType(REHYDRATE)
    .take(1)
    .do(() => {
      const lang = getState().settings[Settings.LANG];
      if (lang) {
        i18n.changeLanguage(lang);
      }
    })
    .ignoreElements();
