import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { prop } from 'ramda';
import { parse } from 'query-string';
import SkillDetail from './SkillDetail/SkillDetail';
import ProgressHome from './ProgressHome';

const ProgressMaster = ({ location }) => {
  const { skill_id: skillId } = parse(prop(['search'], location));
  return (
    <Fragment>
      {skillId ? <SkillDetail /> : <ProgressHome />}
    </Fragment>
  );
};

export default withRouter(ProgressMaster);
