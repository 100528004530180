import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Transition from 'react-transition-group/Transition';

import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';

import { Creators } from '../../data/ducks/modals';
import { colors, breakPoints } from '../../styles';
import Portal from '../shared/Portal';
import Icon from '../shared/Icon';

const MODAL_TIMEOUT = 300;

const CloseButton = styled(Icon).attrs(({color}) =>  {
  return {
    icon: 'close2',
    color
  }
})`
  position: absolute;
  top: 15px;
  right: 15px;

  height: 30px;
  width: 30px;
  padding: 4px;

  box-sizing: border-box;
  cursor: pointer;
  z-index: 1;
`;

const NeoModalContainer = styled.div`
`;

const Modal = styled.div`
  position: relative;
  top: 50px;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  margin: auto;
  margin: ${({ mobileMargin }) => (Number.isInteger(mobileMargin) ? `auto ${mobileMargin}px` : mobileMargin)};
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${({ roundedCorners }) => roundedCorners && css`
      border-radius: 4px;
  `}

  ${({ fullMobile }) => fullMobile && css`
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;

    ${breakPoints.smallTablet} {
      height: auto;
      margin: auto;
    }
  `}

  background-color: ${colors.shadeLighter};
  color: ${colors.shadeDark};

  z-index: 10000;
  transition: 0.3s;
  animation: mounting 0.3s 1;
  animation-timing-function: ease-out;

  ${({ state }) => state === 'entered' && css`
    top: 0;
    opacity: 1;
  `}

  ${breakPoints.smallTablet} {
    max-width: ${({ width }) => (Number.isInteger(width) ? `${width}px` : width)};
    margin: auto;
  }
`;

const Overlay = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.7);
  background: ${({ overlayColor }) => overlayColor};
  z-index: 10000;
  opacity: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  transition: opacity 0.3s ease-out;

  ${({ state }) => state === 'entered' && css`
    opacity: 1;
  `}
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 60px;
  background-color: ${colors.kinedu};

  ${({ roundedCorners }) => roundedCorners && css`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `}

  color: ${colors.white};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 24.5px;
`;

class NeoModal extends Component {
  state = { open: true };

  handleClick = (e) => {
    e.stopPropagation();
  }

  handleClose = (param) => {
    let modalName;
    if (typeof param === 'string') {
      modalName = param;
    }
    this.setState({ open: false }, () => {
      setTimeout(() => this.closeModal(modalName), MODAL_TIMEOUT);
    });
  }

  handleOverlayClose = (param) => {
    if (this.props.closeOnBackdrop) {
      this.handleClose(param);
    }
  }

  closeModal = (modalName) => {
    if (this.props.onCloseModal) {
      this.props.onCloseModal();
    }
    if (this.props.preventFromClosing) return;
    this.props.closeModal({
      name: modalName || this.props.customModalName || this.props.modalName,
    });
  }

  render() {
    const {
      children,
      className,
      title,
      roundedCorners,
      width,
      closeButtonColor,
      overlayColor,
      mobileMargin,
      showCloseButton,
      submitModal,
      openModal,
    } = this.props;

    return (
      <NeoModalContainer>
        <Portal>
          <Transition
            in={this.state.open}
            timeout={MODAL_TIMEOUT}
            appear
          >
            {state => (
              <Overlay onClick={this.handleOverlayClose} state={state} overlayColor={overlayColor}>
                <Modal
                  ref={(ref) => { this.modalNode = ref; }}
                  roundedCorners={roundedCorners}
                  width={width}
                  onClick={e => this.handleClick(e)}
                  className={className}
                  state={state}
                  mobileMargin={mobileMargin}
                >
                  {title && <Header roundedCorners={roundedCorners}>{title}</Header>}
                  {showCloseButton &&
                    <CloseButton onClick={this.handleClose} color={closeButtonColor} />
                  }
                  {children({
                    closeModal: this.handleClose,
                    submitModal,
                    openModal,
                  })}
                </Modal>
              </Overlay>
            )}
          </Transition>
        </Portal>
      </NeoModalContainer>
    );
  }
}

NeoModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.func,
  ]),
  closeModal: PropTypes.func,
  onCloseModal: PropTypes.func,
  title: PropTypes.string,
  roundedCorners: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  closeButtonColor: PropTypes.string,
  showCloseButton: PropTypes.bool,
  closeOnBackdrop: PropTypes.bool,
  preventFromClosing: PropTypes.bool,
};
NeoModal.defaultProps = {
  children: [],
  closeModal: () => { },
  onCloseModal: () => { },
  title: undefined,
  roundedCorners: false,
  width: 620,
  closeButtonColor: colors.shadeMediumLight,
  showCloseButton: true,
  closeOnBackdrop: true,
  preventFromClosing: false,
};

const mapStateToProps = state => ({
  user: state.user,
  modalName: state.modals.name,
});

const mapDispatchToProps = dispatch => bind({
  openModal: Creators.openModal,
  closeModal: Creators.closeModal,
  submitModal: Creators.submitModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NeoModal);
