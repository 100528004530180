import { Observable } from 'rxjs/Observable';
import {
  pipe, pluck, flatten, indexBy, prop, map, path,
} from 'ramda';
import { camelizeKeys } from 'humps';
import { push } from 'connected-react-router';

import { Creators, Types } from '../ducks/progress';
import * as ProgressApi from '../../api/progress';
import * as AssessmentApi from '../../api/assesments';
import EventReporter, { Events } from '../../lib/EventReporter';
import { Modals } from '../ducks';
import { getDopamineType } from '../../lib/utils';

const getSkillsFromAreas = pipe(
  pluck('skills'),
  flatten,
  indexBy(prop('id')),
);

export const onFetchAssessmentSuccess = stream$ => stream$.ofType(Types.FETCH_ASSESSMENT_SUCCESS)
  .take(1);

export const onAssessmentEnd = stream$ => stream$.ofType(Types.END_ASSESSMENT)
  .take(1);

export const fetchAssessment = action$ => action$.ofType(Types.FETCH_ASSESSMENT_REQUESTED)
  .mergeMap(({ babyId, areaId }) => ProgressApi.getBabyProgressByArea({ babyId, areaId })
    .then(res => res.data.data)
    .then(({ areas: unformatedAreas, id }) => {
      const areas = map(area => ({ ...area, skills: pluck('id', area.skills) }), unformatedAreas);
      const skills = getSkillsFromAreas(unformatedAreas);
      return Creators.fetchAssessmentSuccess({
        id, areas, skills, babyId,
      });
    })
    .catch(err => Creators.fetchAssessmentError(err)));

export const fetchBabyProgress = action$ => action$.ofType(Types.FETCH_BABY_PROGRESS_REQUESTED)
  .mergeMap((({ babyId }) => ProgressApi.getBabyProgress({ babyId })
    .then(res => res.data.data)
    .then((tags) => {
      const { is_skipped, areas } = tags;
      const { skills } = areas.reduce((prev, curr) => ({ skills: [...prev.skills, ...curr.skills] }));
      const ranges = /(\d+)\s-\s(\d+)/;
      const filteredSkills = skills.map((s) => {
        const groups = s.age_range.match(ranges);
        const minAge = Number(groups[1]);
        const maxAge = Number(groups[2]);
        return ({ ...s, minAge, maxAge });
      });

      return Creators.fetchBabyProgressSuccess(({
        isSkipped: is_skipped,
        skills: filteredSkills,
      }));
    })
    .catch(err => Creators.fetchBabyProgressError(err))
  ));

const startAnAssessment = (babyId, areaId) => ({ type: Types.START_AN_ASSESSMENT, babyId, areaId });

// FIXME: push for milestones or progress
export const pendingSkillsFlow = action$ => action$
  .ofType(Types.PROGRESS_PENDING_SKILL)
  .mergeMap(({
    babyId, areaId, skillId, context = 'progress',
  }) => Observable.concat(
    [startAnAssessment(babyId, areaId)],
    [push(`/assessment/${areaId}?context=${context}`, { skillId, context: 'progress' })],
    onAssessmentEnd(action$)
      .mergeMap(() => Observable.concat(
        [
          Creators.fetchBabyProgressRequested(babyId),
          push(`/${context}`),
        ],
      )),
  ));

export const fetchSkill = action$ => action$.ofType(Types.FETCH_SKILL_REQUESTED)
  .mergeMap(({ babyId, skillId }) => ProgressApi.getSkill({ babyId, skillId })
    .then(res => path(['data', 'data', 'skill'], res))
    .then((skill) => {
      if (skill) {
        return Creators.fetchSkillSuccess({ babyId, skill });
      }
      return ({ type: 'SKILL_DETAIL_NOT FOUND' });
    }).catch(() => Creators.fetchSkillError()));

export const answerAssessmentMilestones = action$ => action$.ofType(Types.ANSWER_ASSESSMENT_MILESTONES_REQUESTED)
  .mergeMap(({
    skill, baby, answers, showDS,
  }) => ProgressApi.postSkillMilestones({ skillId: skill.id, babyId: baby.id, answers })
    .then(res => camelizeKeys(path(['data', 'data'], res)))
    .then(({ activeMilestones, completedMilestones, percentile }) => Creators.answerAssessmentMilestonesSuccess({
      skill,
      activeMilestones,
      completedMilestones,
      percentile,
      baby,
      showDS,
    }))
    .catch(() => Creators.answerAssessmentMilestonesError()));

export const mapDopamineToModal = action$ => action$
  .ofType(Types.ANSWER_ASSESSMENT_MILESTONES_SUCCESS)
  .filter(({ showDS }) => showDS)
  .map(({
    percentile, baby, activeMilestones, completedMilestones, skill,
  }) => {
    const dsType = getDopamineType({
      active_milestones: activeMilestones,
      completed_milestones: completedMilestones,
      percentile,
    });
    const skillName = skill.title;
    return Modals.Creators.openModal({
      name: 'DopamineSkillModal',
      data: {
        dsType, baby, activeMilestones, completedMilestones, skillName,
      },
    });
  });

export const setSkillMastered = action$ => action$.ofType(Types.SET_SKILL_MASTERED)
  .mergeMap(({
    skill, baby, activeMilestones, context, showDS,
  }) => Observable.fromPromise(
    ProgressApi.postSkillMastered({ babyId: baby.id, skillId: skill.id })
      .then(res => camelizeKeys(res.data.data))
      .then(({ percentile }) => {
        EventReporter.action(Events.MASTER_SKILL({
          skill: skill.id,
          age: baby.age_in_months,
          source: 'skill_detail', // TODO: Get source by context
        }));

        return [
          Creators.answerAssessmentMilestonesSuccess({
            skill,
            activeMilestones,
            completedMilestones: activeMilestones,
            percentile: 1.0,
            babyId: baby.id,
            baby,
            showDS,
          }),
          Creators.fetchBabyProgressRequested(baby.id),
          Creators.fetchSkillDetailRequested(baby.id, skill.id),
        ];
      }),
  )
    .catch(() => Creators.answerAssessmentMilestonesError())
    .flatMap(flating => flating));

export const getMasterSkillMilestones = action$ => action$.ofType(Types.FETCH_MASTER_MILESTONES_REQUESTED)
  .mergeMap(({ skillId, babyId }) => AssessmentApi.getMasterMilestones(skillId)
    .then(res => res.data)
    .then(skillInfo => Creators.fetchMasterMilestonesSuccess(skillInfo, babyId))
    .catch(err => Creators.fetchMasterMilestonesError(err)));
export const fetchSkillDetail = action$ => action$.ofType(Types.FETCH_SKILL_DETAIL_REQUESTED)
  .mergeMap(({ babyId, skillId }) => ProgressApi.getSkillDetails({ babyId, skillId })
    .then(res => res.data.data)
    .then(({ skill }) => Creators.fetchSkillDetailSuccess(skill, babyId))
    .catch(err => Creators.fetchSkillDetailError(err)));
