import React from 'react';
import LeftInfoContentComponent from 'components/Auth/components/LeftInfoContent';
import LoginComponent from 'components/Auth/components/Login';
import AuthViewWrapper, {
  AuthViewContent,
  LeftSideCircle,
  LeftSideCircle2,
  LeftSideContent,
  LeftSideSquare,
  LeftSideSquare2,
  RightSideContent,
} from '../styles';

const AuthLoginView = () => (
  <AuthViewWrapper>
    <AuthViewContent>
      <LeftSideContent>
        <LeftInfoContentComponent />
        <LeftSideCircle />
        <LeftSideCircle2 />
        <LeftSideSquare />
        <LeftSideSquare2 />
      </LeftSideContent>
      <RightSideContent>
        <LoginComponent />
      </RightSideContent>
    </AuthViewContent>
  </AuthViewWrapper>
);

AuthLoginView.propTypes = {};

AuthLoginView.defaultProps = {};

export default AuthLoginView;
