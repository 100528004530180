import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import ProgressContainer from '../../../containers/ProgressContainer';
import Grid, { Row, Col } from '../../shared/Grid';
import ProgressGraph from '../Graph/index';
import Title from '../../shared/Title';
import { colors, breakPoints } from '../../../styles';
import RoundedButton from '../../shared/buttons/RoundedButton';
import { Neo } from '../../themes/RoundedButton';
import ActivityCard from '../../dap/ActivityCard';
import ContentCard from '../../dap/ContentCard';
import Button from '../../shared/buttons/MainButton/index';
import ContentBlock from './ContentBlock';
import FreemiumOverlay from './FreemiumOverlay';
import { FAKE_PROGRESS_DATA } from '../../../values/progress';
import { FAKE_BABY } from '../../../values/families';
import PercentileWell from '../PercentileWell/index';
import GraphWell from '../GraphWell/index';
import {
  percentileToPercentage, getSkillType, genderAccusativePronoun, genderArticle,
} from '../../../lib/utils';
import BadgeInfoBanner from '../BadgeInfoBanner';
import { ContentTypes } from '../../../shared-values';
import SpecialCase from '../SpecialCase';
import Loading from '../../shared/LoadingOverlay';
import { PaymentSources } from '../../../values/prices/paymentSources';
import MilestonesCard from './MilestonesCard';

const SKILL_REFLEXES = 19;

const StyledSkillDetail = styled.div`
  flex: 1 1 auto;
  width: 100%;
`;

const Subtitle = styled(Title).attrs({
  size: 16,
  mdsize: 18,
  color: colors.kinedu,
  align: 'center',
  bold: true,
  style: { margin: '0 20px' },
})``;

const Description = styled.p`
  margin: 20px;
  font-size: 16px;
  color: ${colors.shadeDark};
  letter-spacing: -0.57px;
  line-height: 23px;
`;

// const Label = styled.span`
//   display: block;
//   font-size: 16px;
//   font-weight: 500;
//   color: ${colors.shadeMediumDark};
//   text-align: center;
//   margin: 20px 0;
// `;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 30px 0;

  &.full-cards {
    display: none;
  }

  &.short-cards {
    margin: 30px 0;
  }

  ${breakPoints.smallTablet} {
    margin: 20px 0;

    &.full-cards {
      display: flex;
      min-height: 266px;
    }

    &.short-cards {
      display: none;
    }
  }
`;

const Separator = styled.hr`
  height: 1px;
  border: none;
  background: ${colors.shadeMediumLighter};
  margin: 30px 0;
`;

const BlurEffect = { filter: 'blur(4px)' };

const GraphDataWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${breakPoints.smallTablet} {
    flex-direction: column;
  }
`;

const SkillDetail = ({ t }) => (
  <StyledSkillDetail>
    <ProgressContainer>
      {({
        activeBaby,
        activeSkillDetail,
        goToCatalog,
        goToPrices,
        isPremium,
        masterMilestones,
        openContent,
        openModal,
        loading,
      }) => {
        const {
          activities = [],
          articles = [],
          progress_status: progressStatus,
        } = activeSkillDetail;
        const isNewBornSkill = activeSkillDetail.id === SKILL_REFLEXES;
        const hasMasteredSkill = activeSkillDetail.completed_milestones
          === activeSkillDetail.active_milestones;
        return (
          <Grid
            style={{ padding: 0 }}
            columnsStyle={css`padding: 0;`}
            mdColumnsStyle={css`padding: 10px;`}
            rowStyle={css`margin-left: 0; margin-right: 0;`}
          >
            <Row>
              <Col>
                <Title
                  size={24}
                  color={colors.cognitive}
                  spacing="-0.93px"
                  align="center"
                  style={{ margin: '30px 0' }}
                  bold
                >
                  {activeSkillDetail.title}
                </Title>
              </Col>
            </Row>
            <Row>
              <Col>
                <BadgeInfoBanner type={getSkillType(activeSkillDetail)} baby={activeBaby} />
              </Col>
            </Row>
            <Row style={{ position: 'relative', minHeight: '320px' }}>
              <FreemiumOverlay
                activeBaby={activeBaby}
                goToPrices={goToPrices}
                show={!isPremium}
                t={t}
              />
              {
                isNewBornSkill ? null : (
                  <Col xs={12} sm={7} md={7} style={!isPremium ? BlurEffect : {}}>
                    <ProgressGraph
                      data={isPremium ? activeSkillDetail.progress : FAKE_PROGRESS_DATA.progress}
                      baby={isPremium ? activeBaby : FAKE_BABY}
                      progressStatus={progressStatus}
                      completedMilestones={activeSkillDetail.completed_milestones}
                      loading={loading}
                    />
                    {
                      isNewBornSkill ? null : (
                        <GraphWell
                          baby={isPremium ? activeBaby : FAKE_BABY}
                          percentile={isPremium
                            ? percentileToPercentage(activeSkillDetail.percentile) : 0}
                          style={!isPremium ? BlurEffect : {}}
                        />
                      )
                    }
                  </Col>
                )
              }
              <Col xs={12} sm={isNewBornSkill ? 12 : 5} md={isNewBornSkill ? 12 : 5}>
                <GraphDataWrapper>
                  <PercentileWell
                    baby={isPremium ? activeBaby : FAKE_BABY}
                    percentile={isPremium
                      ? percentileToPercentage(activeSkillDetail.percentile) : 0}
                    progressStatus={progressStatus}
                    completedMilestones={activeSkillDetail.completed_milestones}
                    style={!isPremium ? BlurEffect : {}}
                  />
                  <MilestonesCard
                    skillId={activeSkillDetail.id}
                    style={!isPremium ? BlurEffect : {}}
                    t={t}
                  />
                </GraphDataWrapper>
              </Col>
            </Row>
            <Row>
              <Col>
                <RoundedButton
                  onClick={() => {
                    const { completed_milestones, active_milestones } = activeSkillDetail;
                    return openModal({
                      name: 'MasterSkillModal',
                      data: {
                        baby: activeBaby,
                        milestones: masterMilestones,
                        showDS: true,
                        skill: activeSkillDetail,
                        completedMilestones: completed_milestones,
                        activeMilestones: active_milestones,
                      },
                    });
                  }}
                  theme={Neo}
                  style={{ borderColor: colors.cognitive }}
                  show={!hasMasteredSkill}
                >
                  {t('SKILL_DETAIL.HAS_MASTERED_SKILL', { babyName: activeBaby.name })}
                </RoundedButton>
                <Subtitle>
                  {t('SKILL_DETAIL.ABOUT_SKILL', { skillTitle: activeSkillDetail.title })}
                </Subtitle>
                {/* <Label>{t('SKILL_DETAIL.LABEL')}</Label> */}
                <Description>{activeSkillDetail.description}</Description>
                <RoundedButton
                  onClick={() => openModal({
                    name: 'SkillScienceModal',
                    data: { baby: activeBaby },
                  })}
                  theme={Neo}
                  style={{ borderColor: colors.cognitive }}
                >
                  {t('SKILL_DETAIL.WHERE_OUR_DATA')}
                </RoundedButton>
                <SpecialCase
                  show={activeSkillDetail.children}
                  title={activeSkillDetail.title}
                  ageRange={activeSkillDetail.age_range}
                  subSkills={activeSkillDetail.children_skills}
                  t={t}
                />
                <Subtitle>
                  {t('SKILL_DETAIL.EXPLORE_ACTIVITIES', {
                    babyName: activeBaby.name,
                    article: genderAccusativePronoun(activeBaby.gender),
                    articlePt: genderArticle(activeBaby.gender),
                  })}
                </Subtitle>
                <Content className="full-cards">
                  <Loading loading={loading} position="absolute" />
                  {activities.slice(0, 3).map(act => (
                    <ActivityCard
                      show
                      item={{ ...act, type: 'activity' }}
                      key={act.id}
                      onOpen={() => openContent({ type: ContentTypes.ACTIVITY, ...act })}
                      goToPrices={() => goToPrices({ source: PaymentSources.SKILLS_MIL_ACT })}
                      width={290}
                      locked={!isPremium}
                      context="skills"
                    />
                  ))}
                </Content>
                <Content className="short-cards">
                  <Loading loading={loading} position="absolute" />
                  {activities.slice(0, 3).map(act => (
                    <ContentBlock
                      type="act"
                      show
                      item={{ ...act, type: 'activity' }}
                      key={act.id}
                      onOpen={() => openContent({ type: ContentTypes.ACTIVITY, ...act })}
                      goToPrices={goToPrices}
                      width={290}
                      locked={!isPremium}
                    />
                  ))}
                </Content>
                <Content>
                  <Button
                    color="green"
                    onClick={() => goToCatalog(activeSkillDetail.area_id, activeSkillDetail.id, 'activities')}
                    maxWidth={300}
                  >
                    {t('SKILL_DETAIL.MORE_ACTIVITIES')}
                  </Button>
                </Content>
                <Separator />
                <Subtitle>
                  {t('SKILL_DETAIL.EXPLORE_ARTICLES')}
                </Subtitle>
                <Content className="full-cards">
                  <Loading loading={loading} position="absolute" />
                  {articles.slice(0, 3).map(art => (
                    <ContentCard
                      context="progress"
                      show
                      item={{ ...art, type: ContentTypes.ARTICLE }}
                      key={art.id}
                      onOpen={() => openContent({ type: ContentTypes.ARTICLE, ...art })}
                      goToPrices={goToPrices}
                      width={290}
                      locked={!isPremium}
                    />
                  ))}
                </Content>
                <Content className="short-cards">
                  <Loading loading={loading} position="absolute" />
                  {articles.slice(0, 3).map(art => (
                    <ContentBlock
                      type="art"
                      show
                      item={{ ...art, type: ContentTypes.ARTICLE }}
                      key={art.id}
                      onOpen={() => openContent({ type: ContentTypes.ARTICLE, ...art })}
                      goToPrices={goToPrices}
                      width={290}
                      locked={!isPremium}
                    />
                  ))}
                </Content>
                <Content>
                  <Button
                    color="green"
                    onClick={() => goToCatalog(activeSkillDetail.area_id, activeSkillDetail.id, 'articles')}
                    maxWidth={300}
                  >
                    {t('SKILL_DETAIL.MORE_ARTICLES')}
                  </Button>
                </Content>
              </Col>
            </Row>
          </Grid>
        );
      }}
    </ProgressContainer>
  </StyledSkillDetail>
);

SkillDetail.propTypes = {
  t: PropTypes.func,
};

SkillDetail.defaultProps = {
  t: () => {},
};

export default withTranslation([Namespaces.PROGRESS])(SkillDetail);
