import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import { withTranslation } from 'react-i18next';
import { BenefitItem, BenefitsContainer, BenefitsList, HalfCircle, Logo, Note, rect1Styles, rect2Styles, Rectangle, ShapesArea, Square, StyledAuthLeftSide, Title } from './styles/LeftContent';

const LeftContentComponent = ({ t }) => (
  <StyledAuthLeftSide>
    <Logo/>
    <ShapesArea>
      <HalfCircle />
      <Rectangle RectangleStyles={rect1Styles} />
      <Rectangle RectangleStyles={rect2Styles} />
      <Square />
    </ShapesArea>
    <BenefitsContainer>
      <Title>
        {t('common:AUTH.KINEDU_BENEFITS_TITLE')}
      </Title>
      <BenefitsList>
        <BenefitItem>{t('common:AUTH.KINEDU_BENEFITS_ITEM_1')}</BenefitItem>
        <BenefitItem>{t('common:AUTH.KINEDU_BENEFITS_ITEM_2')}</BenefitItem>
        <BenefitItem>{t('common:AUTH.KINEDU_BENEFITS_ITEM_3')}</BenefitItem>
      </BenefitsList>
      <Note>{t('common:AUTH.KINEDU_BENEFITS_NOTE')}</Note>
    </BenefitsContainer>
  </StyledAuthLeftSide>
);

LeftContentComponent.propTypes = {
  t: PropTypes.func,
};

LeftContentComponent.defaultProps = {
  t: () => {},
};

export default compose(
  withTranslation([]),
)(LeftContentComponent);
