import BaseApi, { localeFlag } from './BaseApi';
import MessagesApi from './MessagesApi';

export const getListBabyFeed = ({ babyId, page }) => BaseApi.get(`babies/${babyId}/classroom_feeds?${localeFlag()}&page=${page}&per_page=10`);

export const getActivityDetail = ({ babyId, activityId }) => BaseApi.get(`babies/${babyId}/classroom_feeds/custom_activity?activity_id=${activityId}`);

export const getClassroomsDap = ({ babyId, groupId, skillProgrammeId }) => BaseApi.get(`babies/${babyId}/classroom_feeds/classrooms_dap?group_id=${groupId}&skill_programme_id=${skillProgrammeId}&${localeFlag()}`);

export const postClassroomsEvidence = ({ groupId, attachment }) => MessagesApi.post(`/api/v1/folders/${groupId}/files`, { files: [attachment] })

// https://staging-messages.kinedu.com/api/v1/folders/grp_o6AsIzXgYfsji3GM1610731574/files