import {
  pipe,
  replace,
  toUpper,
  isNil,
  trim,
  split,
  map,
  without,
  fromPairs,
  isEmpty,
  zipObj,
  merge,
  mapObjIndexed,
  is,
  any,
  equals,
  keys,
  has,
} from 'ramda';
import Immutable from 'seamless-immutable';

// matches on capital letters (except at the start & end of the string)
const RX_CAPS = /(?!^)([A-Z])/g;

// converts a camelCaseWord into a SCREAMING_SNAKE_CASE word
const camelToScreamingSnake = pipe(
  replace(RX_CAPS, '_$1'),
  toUpper,
);

export const DEEP = { deep: true };
/**
    Creates an immutable state.
    @param {object} - Object with custom state
    @param {object} config - Configuration for default actions
    @return {object} - An immutable state with merged custom an default properties
  */
const createState = state => Immutable(state);

// //////////////////////////
// /////  CREATE TYPES
// //////////////////////////

/**
  DRY define your types object from a string
  @param {Array<string>} types - String with Action types
  @param {string} prefix - Prefix for action types
  @return {object} A types object.
*/
const createTypes = (types, prefix = '') => {
  if (isNil(types)) throw new Error('valid types are required');
  return pipe(
    trim,
    split(/\s/),
    map(pipe(trim)),
    without([null, '']),
    map(x => [x, prefix + x]),
    fromPairs,
    map(camelToScreamingSnake),
  )(types);
};

// //////////////////////////
// /////  CREATE ACTIONS
// //////////////////////////

// an action creator with additional properties
const createActionCreator = (name, extraPropNames, prefix) => {
  // types are upcase and snakey
  const type = prefix + camelToScreamingSnake(name);

  // do we need extra props for this?
  const noKeys = isNil(extraPropNames) || isEmpty(extraPropNames);

  // a type-only action creator
  if (noKeys) {
    return () => ({
      type,
    });
  }

  // an action creator with type + properties
  return (...values) => {
    const extraProps = zipObj(extraPropNames, values);

    return merge({ type }, extraProps);
  };
};

// build Action Creators out of an object
const convertToCreators = (config, options = {}) => {
  const { prefix = '' } = options;

  const userActions = mapObjIndexed((num, key, value) => {
    if (typeof value[key] === 'function') {
      // the user brought their own action creator
      return value[key];
    }
    // lets make an action creator for them!
    return createActionCreator(key, value[key], prefix);
  })(config);

  // Map default actions with know behavior
  const defaultActionsCreators = {};
  // Merge declared user actions and default actions
  return merge(userActions, defaultActionsCreators);
};

/**
  Builds your Action Types and Action Creators at the same time
  @param {object} config - An object with actions as key and an array of props as value
  @param {object} options - Optional. // See more at https://github.com/skellock/reduxsauce
  @return {object} An object with Action Types and Action Creators
*/
const createActions = (config, options) => {
  if (isNil(config)) {
    throw new Error('an object is required to setup types and creators');
  }
  return convertToCreators(config, options);
};

// //////////////////////////
// /////  CREATE REDUCER
// //////////////////////////


/**
  Creates a reducer.
  @param {object} initialState - The initial state for this reducer.
  @param {object} handlers - Keys are action types (strings), values are reducers (functions).
  @param {object} options - Capable of creating default action reducers (get, getOne, create, update, remove, reset)
  @return {object} A reducer object.
*/
const createReducer = (initialState, handlers, options) => {
  // initial state is required
  if (isNil(initialState)) {
    throw new Error('initial state is required');
  }

  // handlers must be an object
  if (isNil(handlers) || !is(Object, handlers)) {
    throw new Error('handlers must be an object');
  }

  // handlers cannot have an undefined key
  if (any(equals('undefined'))(keys(handlers))) {
    throw new Error('handlers cannot have an undefined key');
  }

  // create the reducer function
  return (state = initialState, action) => {
    // wrong actions, just return state
    if (isNil(action)) return state;
    if (!has('type', action)) return state;

    // look for the handler
    const handler = handlers[action.type];

    // no handler no cry
    if (isNil(handler)) return state;

    // execute the handler
    return handler(state, action);
  };
};

export const Reduxsauce = { createState, createTypes, createActions, createReducer };
