import { css } from 'styled-components';
import PropTypes from 'prop-types';

export const Default = {
  container: css``,
  input: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    box-sizing: border-box;
  `,
  checked: css`
  `,
  label: css`
    display: flex;
  `,
  text: css`
    margin-left: 10px;
    user-select: none;
  `,
};

export const ThemeShape = PropTypes.shape({
  container: PropTypes.arrayOf(PropTypes.string),
  input: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.arrayOf(PropTypes.string),
});
