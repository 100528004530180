import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../styles';

const StyledWell = styled.div`
    display: block;
    padding: 20px;
    background: ${colors.shadeLighter};
    color: ${colors.shadeMediumDark};
    text-align: center;
    box-shadow: 0px 16px 35px -22px #ACC2D3;
    border-radius: 4px;
`;

const Well = ({ children, className, style }) => (
  <StyledWell className={className} style={style}>
    {children}
  </StyledWell>
);

Well.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
};
Well.defaultProps = {
  children: '',
  className: '',
  style: {},
};

export default Well;
