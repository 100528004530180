import Pusher from 'pusher-js';
import { Provider } from 'react-redux';
import { ConfigManager } from 'lego-dist';
import { ThemeProvider } from 'styled-components';
import React, { useEffect } from 'react';
import { I18nextProvider, Trans, useTranslation } from 'react-i18next';
import { PersistGate } from 'redux-persist/es/integration/react';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { toast } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Leanplum from 'leanplum-sdk';

import i18n from './i18n';
import themes from './themes';
import MainContainer from './containers/MainContainer';

import history from './history';
import createStore from './data/redux';

import { messages } from './settings/chat';
import { ENV, IS_DEVELOPMENT_MODE, IS_LOCALHOST, LEANPLUM_CONFIG } from './config';
import { defaultOptions, devToolsInitialIsOpen } from './reactQueryConfig';

import EventReporter, { Events } from './lib/EventReporter';
import { s3Config } from './settings/filer/s3';
import { LOCALSTORAGE_SOURCE_ACTIVITY_COMPLETED, LOCALSTORAGE_TYPE_ACTIVITY_COMPLETED, LOCALSTORAGE_SOURCE_ASK_AN_EXPERT } from 'shared-values';

const userLocale = localStorage.getItem('user_locale') || '';
const historyMiddleware = routerMiddleware(history);

export const { persistor, store } = createStore(historyMiddleware);

i18n.changeLanguage(userLocale);

/* Register global configurations */
ConfigManager.registerConfig('i18n', {
  i18n,
  useTranslation,
  Trans,
});

ConfigManager.registerConfig('ENVConfig', ENV);
ConfigManager.registerConfig('Pusher', Pusher);
ConfigManager.registerConfig('messages', messages);
ConfigManager.registerConfig('S3Config', s3Config);
ConfigManager.registerConfig('toast', toast);

ConfigManager.registerConfig('tracking', {
  Events,
  EventReporter,
});

ConfigManager.registerConfig('localStorageKeys', {
  LOCALSTORAGE_SOURCE_ASK_AN_EXPERT,
});

window.ConfigurationManager = ConfigManager;
window.localStorage.setItem(LOCALSTORAGE_SOURCE_ACTIVITY_COMPLETED, '');
window.localStorage.setItem(LOCALSTORAGE_TYPE_ACTIVITY_COMPLETED, '');

const queryClient = new QueryClient({ defaultOptions });

const App = () => {
  useEffect(() => {
    if (IS_DEVELOPMENT_MODE) {
      Leanplum.setAppIdForDevelopmentMode(
        LEANPLUM_CONFIG.APP_ID,
        LEANPLUM_CONFIG.KEY,
      );
    } else {
      Leanplum.setAppIdForProductionMode(
        LEANPLUM_CONFIG.APP_ID,
        LEANPLUM_CONFIG.KEY,
      );
    }
    Leanplum.start();

    if (IS_DEVELOPMENT_MODE) {
      window.Kinedu = {
        ...(window?.Kinedu || {}),
        Leanplum,
      };
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ConnectedRouter history={history}>
              <ThemeProvider theme={themes}>
                <MainContainer />
              </ThemeProvider>
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </I18nextProvider>
      {IS_LOCALHOST && <ReactQueryDevtools initialIsOpen={devToolsInitialIsOpen} />}
    </QueryClientProvider>
  );
};

export default App;
