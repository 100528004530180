import styled from 'styled-components';
import LogoKineduComplete from '../../../assets/images/logo.svg';

const InfoWrapper = styled.div.attrs({
  className: 'InfoWrapper',
})`
  display: block;
`;

const Logo = styled.img.attrs({
  src: LogoKineduComplete,
})`
  display: block;
  margin: 0 auto;
`;

const BenefitsContainer = styled.div.attrs({
  className: 'BenefitsContainer',
})`
  display: block;
  margin: auto;
`;

const BenefitsTitle = styled.h1.attrs({
  className: 'BenefitsTitle',
})`
  display: block;
  text-align: center;
  padding: 0;
  margin: 80px auto 15px auto;
  color: #0086D8;
  text-align: center;
  font-size: 31px;
  font-family: "Proxima Nova";
  font-weight: 700;
  line-height: 46px;
`;

const BenefitsList = styled.ul.attrs({
  className: 'BenefitsList',
})`
  display: block;
`;

const BenefitItem = styled.li.attrs({
  className: 'BenefitItem',
})`
  color: #404040;
  font-size: 20px;
  font-family: "Proxima Nova";
  line-height: 40px;
`;

const Note = styled.div.attrs({
  className: 'Note',
})`
  color: #000;
  font-size: 20px;
  font-family: "Proxima Nova";
  font-weight: 600;
  line-height: 31px;
  display: block;
  margin-top: 20px;
  text-align: center;
`;


export default InfoWrapper;
export {
  Logo,
  BenefitsContainer,
  BenefitsTitle,
  BenefitsList,
  BenefitItem,
  Note,
};
