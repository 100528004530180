import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'ramda';
import { withRouter } from 'react-router-dom';
import Markdown from 'react-remarkable';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import Modal from '../modals/NeoModal';
import { colors, breakPoints } from '../../styles';

import Button from '../shared/buttons/MainButton';
import BabyHappyBlue from '../../images/BabyHappyBlue.svg';

import Icon from '../shared/Icon';

const Image = styled.img.attrs(({src}) => { return { src } })`
  width: 132px;
  margin: 20px;

  ${breakPoints.smallTablet} {
    width: 160px;
  }
`;

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  background-color: ${colors.white};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
  padding: 40px;
  box-sizing: border-box;

  overflow-y: auto;

  ${breakPoints.smallTablet} {
    padding: 20px;
  }
`;

const Title = styled.span`
  color: ${colors.kinedu};
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.83px;
  text-align: center;

  ${breakPoints.smallTablet} {
    margin: 10px 0;
    font-size: 28px;
    letter-spacing: -1.23px;
  }
`;

const Description = styled.span`
  color: ${colors.shadeMediumDark};
  font-size: 16px;
  letter-spacing: -0.44px;
  text-align: center;

  margin: 20px 0;

  ${breakPoints.smallTablet} {
    color: ${colors.shadeMediumDark};
    font-size: 18px;
    letter-spacing: -0.5px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 20px;

  ${breakPoints.smallTablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const LeftSide = styled.div``;

const RightSide = styled.div``;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 30px;

  li {
    color: ${colors.shadeDark};
    position: relative;
    padding-left: 30px;
    margin: 10px 0;
  }
`;

const Check = styled(Icon).attrs({
  icon: 'milestones-completed',
  color: colors.cognitive,
  size: 22,
})`
  position: absolute;
  left: 0;
`;

const ListElement = ({ children }) => (
  <Markdown container="li">
    <Check />
    {' '}
    {children}
    {' '}
  </Markdown>
);

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'NotReadyToUpgrateModal',
  data: {
  },
}

*/

class NotReadyToUpgrateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plan: null,
    };
  }

  setPlan = (plan) => {
    this.setState({ plan });
  }

  goBack = () => {
    this.props.history.goBack();
    this.props.onClose();
  }

  render() {
    const { t } = this.props;
    return (
      <Wrapper width={620} roundedCorners>
        {() => (
          <Fragment>
            <Content>
              <Title>{t('MODAL_NOT_READY_TO_UPGRADE.TITLE')}</Title>
              <Description>
                {t('MODAL_NOT_READY_TO_UPGRADE.SUBTITLE')}
              </Description>
              <Body>
                <LeftSide>
                  <Image src={BabyHappyBlue} />
                </LeftSide>
                <RightSide>
                  <List>
                    <ListElement>{t('MODAL_NOT_READY_TO_UPGRADE.ENJOY_1')}</ListElement>
                    <ListElement>{t('MODAL_NOT_READY_TO_UPGRADE.ENJOY_3')}</ListElement>
                    <ListElement>{t('MODAL_NOT_READY_TO_UPGRADE.ENJOY_4')}</ListElement>
                    <ListElement>{t('MODAL_NOT_READY_TO_UPGRADE.ENJOY_5')}</ListElement>
                  </List>
                </RightSide>
              </Body>
              <ButtonContainer>
                <Button color="green" maxWidth="250" onClick={this.goBack}>{t('common:OK_GOT_IT')}</Button>
              </ButtonContainer>
            </Content>
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

NotReadyToUpgrateModal.propTypes = {
  onClose: PropTypes.func,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  t: PropTypes.func,
};

NotReadyToUpgrateModal.defaultProps = {
  onClose: () => { },
  history: {},
  t: () => { },
};

export default compose(
  withTranslation([Namespaces.MODALS, Namespaces.COMMON]),
  withRouter,
)(NotReadyToUpgrateModal);
