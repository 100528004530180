import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors, breakPoints } from '../../styles';

export const NeoSlider = {
  container: css`

  `,
  slideshow: css`
    background-color: ${colors.shadeLighter};
    border: 1px solid ${colors.shadeMedium};
    border-radius: 4px;
    width: calc(100% - 70px);
    ${breakPoints.largePhone} {
    width: 100%;
    }
  `,
  slidesContainer: css`
    display: flex;
    justify-content: center;
  `,
  buttonsContainer: css`
    position: absolute !important;
    top: -90px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
  button: css`
    position: absolute;
    top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;

    &.disabled {
      opacity: 0.3;
    }

    &.move-next {
      right: 0;
    }

    &.move-prev {
    }
  `,
  box: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    padding: 0;
    width: 100%;
    height: 40px;
    background-color: ${colors.shadeLighter};
    border: none;

    color: ${colors.shadeDark};
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    transition: all 0.3s ease-out;
    user-select: none;

    cursor: pointer;
  `,
  selectedBox: css`
    color: #FFF;
    background-color: ${colors.shadeMedium};
    border-color: ${colors.shadeMedium};
    border-radius: 4px;
  `,
};

export const Rounded = {
  container: css`
  `,
  slideshow: css`
    background-color: ${colors.shadeLighter};
    border-radius: 100px;
    width: calc(100% - 70px) !important;
    margin: auto;
  `,
  slidesContainer: css`
    display: flex;
    justify-content: center;
  `,
  buttonsContainer: css`
    position: absolute !important;
    top: -92px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
  button: css`
    position: absolute;
    top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;

    &.disabled {
      opacity: 0.3;
    }

    &.move-next {
      right: 0;
    }

    &.move-prev {
    }
  `,
  box: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    padding: 0;
    width: 100%;
    height: 40px;
    background-color: ${colors.shadeLighter};
    border: none;

    color: ${colors.shadeMediumDark};
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;

    transition: all 0.3s ease-out;
    user-select: none;

    cursor: pointer;

    ${breakPoints.smallTablet} {
      font-size: 14px;
    }
  `,
  selectedBox: css`
    color: #FFF;
    background-color: ${colors.kinedu};
    border-color: ${colors.shadeMedium};
    border-radius: 100px;
  `,
};
export const AltRounded = {
  container: css`
  `,
  slideshow: css`
    background-color: ${colors.shadeLighter};
    border-radius: 100px;
    width: calc(100% - 70px) !important;
    margin: auto;

    ${breakPoints.smallTablet} {
      width: 100% !important;
      margin: auto;
    }
  `,
  slidesContainer: css`
    display: flex;
    justify-content: center;
  `,
  buttonsContainer: css`
    position: absolute !important;
    top: -92px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
  button: css`
    position: absolute;
    top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;

    &.disabled {
      opacity: 0.3;
    }

    &.move-next {
      right: 0;
    }

    &.move-prev {
    }
  `,
  box: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    padding: 0;
    width: 100%;
    height: 36px;
    background-color: ${colors.white};
    border: none;

    color: ${colors.kinedu};
    font-size: 12px;
    margin: 0px 0px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0;
    text-transform: capitalize;

    transition: all 0.3s ease-out;
    user-select: none;

    cursor: pointer;

    ${breakPoints.smallTablet} {
      font-size: 14px;
    }
  `,
  selectedBox: css`
    color: #FFF;
    background-color: ${colors.kinedu};
    border-color: ${colors.shadeMedium};
    border-radius: 100px;
  `,
};

export const SoftLight = {
  container: css`
    position: relative;
    top: -10px;
    border-radius: 4px;
    background-color: ${colors.white};
    margin: 0 10px;
    box-shadow: 0px 10px 35px -13px ${colors.shadeMedium};
    z-index: 2;

    ${breakPoints.smallTablet} {
      top: -30px;
    }
  `,
  slideshow: css`
    border-radius: 4px;
    padding: 15px 0;
    box-sizing: border-box;

    ${breakPoints.smallTablet} {
      padding: 15px 0 20px;
    }
  `,
  slidesContainer: css`
    display: flex;
    justify-content: center;
  `,
  buttonsContainer: css`
    position: absolute !important;
    top: -70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
  button: css`
    position: absolute;
    top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;

    &.disabled {
      opacity: 0.3;
    }

    &.move-next {
      right: 0;
    }

    &.move-prev {
    }
  `,
  box: css`
    color: ${colors.shadeMediumLight};
    font-size: 12px;
    text-align: center;

    transition: all 0.3s ease-out;
    user-select: none;
    cursor: pointer;

    .Icon {
      transition: all 0.3s ease-out;
      fill: ${colors.shadeMediumLight};
    }

    :hover {
      color: ${colors.shadeMediumDark};
      .Icon {
        fill: ${colors.shadeMediumDark};
      }
    }
  `,
  selectedBox: css`
    color: ${colors.kinedu};

    .Icon {
      fill: ${colors.kinedu};
    }
  `,
};

export const AltSoftLight = {
  container: css`
    position: relative;
    border-radius: 4px;
    margin: 0 40px;
    padding: 0;
    z-index: 2;
  `,
  slideshow: css`
    border-radius: 4px;
    padding: 0px;
    box-sizing: border-box;
  `,
  slidesContainer: css`
    display: flex;
    justify-content: center;
  `,
  slide: css`
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0px;

    &:hover {
      background-color: ${colors.shadeLighter};
    }

    ${breakPoints.smallTablet} {
      justify-content: center;
      align-items: flex-start;

      span {
        align-items: flex-start;
        padding-bottom: 10px;
      }
    }
  `,
  buttonsContainer: css`
    position: absolute !important;
    top: 0px;
    left: -10%;
    width: 120%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none;

    ${breakPoints.smallTablet} {
      left: -3%;
      width: 106%;
    }
  `,
  button: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    pointer-events: all;

    &.disabled {
      opacity: 0.3;
    }

    &.move-next {
      right: 0;
    }

    &.move-prev {
    }
  `,
  box: css`
    display: flex;
    align-items: center;
    color: ${colors.shadeMediumLight};
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    width: 100%;
    height: 100%;

    transition: all 0.3s ease-out;
    user-select: none;
    cursor: pointer;

    .Icon {
      transition: all 0.3s ease-out;
      fill: ${colors.shadeMediumLight};
    }

    :hover {
      color: ${colors.shadeMediumDark};
      .Icon {
        fill: ${colors.shadeMediumDark};
      }
    }
  `,
  selectedBox: css`
    color: ${colors.kinedu};
    background: ${colors.shadeLight};

    .Icon {
      fill: ${colors.kinedu};
    }
  `,
};

export const ThemeShape = PropTypes.shape({
  container: PropTypes.array,
});
