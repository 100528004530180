import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Modal from '../modals/NeoModal';
import { colors, breakPoints } from '../../styles';

import AuthView from '../../components/landing/AuthView';
import Texture from '../../images/Texture3.png';

const Wrapper = styled(Modal) `
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px 20px 10px;
  box-sizing: border-box;
  background-color: ${colors.shadeLight};
  background-image: url(${Texture});

  ${breakPoints.smallTablet} {
    padding: 30px 70px 10px;
  }
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'AuthModal',
  data: { authType: <string> },
}

*/

const AuthModal = (props) => {
  const { authType } = props;
  return (
    <Wrapper roundedCorners>
      {({ closeModal }) => (
        <AuthView type={authType} onClose={closeModal} />
      )}
    </Wrapper>
  );
};

AuthModal.propTypes = {
  authType: PropTypes.string,
};

AuthModal.defaultProps = {
  authType: '',
};

export default AuthModal;
