import { css } from 'styled-components';

const CardMessageType = {
  CardContainer: css` `,
  ImageProps: css` `,
  ImageContainer: css` `,
  Content: css` `,
  Title: css` `,
  Description: css` `,
};

export default CardMessageType;
