import { Observable } from 'rxjs';
import 'rxjs/add/operator/pairwise';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import { Creators, Types } from '../../ducks/babiesAndFamilies';
import * as IA from '../../ducks/initialAssessment';
import * as Api from '../../../api/babies';

import {
  LOCALSTORAGE_ACTIVE_BABY_KEY,
  CM_PER_IN,
  KG_PER_LB,
  IASteps,
  LOCALSTORAGE_GA_CREATEBABY_EVENT_SENT,
  LOCALSTORAGE_BABY_STATUS,
} from '../../../shared-values';
import { Modals } from '../../ducks';
import { onIAEnd } from '../orchestrators/OB2IA';
// Posible optimization (ahorita no se si sirva) indexar babies por family id.

const validateBabyPremature = (baby1) => {
  if (baby1.is_premature) {
    return ({ type: 'SHOW_BABY_PREMATURE_MODAL', baby1 });
  }
  return ({ type: 'BABY_IS_NOT_PREMATURE' });
};

const getRedirectUrl = () => {
  let url = '/dap/current';
  if (localStorage.getItem('OPEN_CLASSROOM_ID_AFTER_SAVE_BABY')) {
    localStorage.setItem(LOCALSTORAGE_BABY_STATUS, 'classroomsBaby');
    url = '/family';
  }
  if (localStorage.getItem('CREATE_BABY_AFTER_JOIN')) {
    localStorage.removeItem('CREATE_BABY_AFTER_JOIN');
    url = '/classrooms';
  }

  return url;
};

/** Store mutations */
export const createBaby = action$ => action$.ofType(Types.ADD_BABY_REQUESTED).mergeMap(action => Observable.fromPromise(
  Api.postBaby({
    name: action.name,
    lastname: action.lastname,
    gender: action.gender,
    birthday: action.birthday,
    weeksBeforeBirth: action.weeksBeforeBirth,
    avatar: action.avatar,
    familyId: action.familyId,
  }).then((res) => {
    if (!localStorage.getItem(LOCALSTORAGE_GA_CREATEBABY_EVENT_SENT)) {

      localStorage.setItem(LOCALSTORAGE_GA_CREATEBABY_EVENT_SENT, true);
    }
    action.postAction(res.data.data.baby.id);
    return res.data.data.baby;
  }),
).mergeMap(baby => action$
  .ofType(Types.FETCH_FAMILIES_SUCCESS)
  .take(1)
  .mergeMap(() => Observable.concat(
    [Creators.familySelected(baby.family_id)],
    [validateBabyPremature(baby)],
    [Creators.babySelected(baby.id)],
    // IA
    [IA.Creators.startIa({ babyId: baby.id })],
    [Creators.addBabySuccess()],
    [push('/ia')],
    onIAEnd(action$).mapTo(push(getRedirectUrl())),
    [{ type: 'CREATE_PLAN' }],
  ))
  .startWith(Creators.fetchFamiliesRequested()),
).catch((err) => {
  toast.error('There was a problem');
  return Observable.of(Creators.addBabyError(err));
}),
);

export const updateBaby = action$ =>
  action$
    .ofType(Types.EDIT_BABY_REQUESTED)
    // .do(() => toast.info(i18n.t('menu:IMAGE_SENT')))
    .mergeMap(action =>
      Api.updateBaby({
        id: action.id,
        avatar: action.avatar,
        name: action.name,
        gender: action.gender,
        birthday: action.birthday,
        weeks_before_birth: action.weeks,
      }).then(res => res.data.data.baby)
        .then(baby => Creators.editBabySuccess({
          ...baby,
          birthday: action.birthday,
          weeks: action.weeks,
        }))
        .catch((err) => {
          toast.error('Error: Baby not updated');
          return ({ type: '', err });
        }));

export const deleteBaby = (action$, { getState }) =>
  action$
    .ofType(Types.DELETE_BABY)
    .do((action) => {
      localStorage.removeItem(LOCALSTORAGE_ACTIVE_BABY_KEY); // Prevents undefined babies
      Api.deleteBaby(action.id);
    })
    .mergeMap(() => ([
      Creators.selectFamilyAndDefaultBaby(getState().families.activeFamilyId),
      push('/dap/current'),
      Creators.checkEmptyFamily(),
    ]));

export const addBabyHeight = action$ =>
  action$
    .ofType(Types.ADD_BABY_HEIGHT)
    .do(({ babyId, height, heightUnit }) => {
      // height is given in cm, sent as cm.
      const computedHeight = height * (heightUnit === 'cm' ? 1 : CM_PER_IN);
      Api.postHeight({ babyId, height: computedHeight });
    })
    .ignoreElements();

export const addBabyWeight = action$ =>
  action$
    .ofType(Types.ADD_BABY_WEIGHT)
    .do(({ babyId, weight, weightUnit }) => {
      // height is given in kgs, sent as grams.
      const newWeight = weight.replace(',', '.');
      const computedWeight =
        newWeight * (weightUnit === 'kg' ? 1000 : KG_PER_LB * 1000);
      Api.postWeight({ babyId, weight: computedWeight });
    })
    .ignoreElements();


export const selectBabyRequested = action$ =>
  action$
    .ofType(Types.BABY_SELECTED)
    .pluck('babyId')
    .pairwise()
    .filter(([a, b]) => a !== b)
    .mapTo({ type: 'RESET' });

const onEditBabySuccess = stream$ =>
  stream$
    .ofType(Types.EDIT_BABY_SUCCESS)
    .take(1)
    .map(({ type, pending_reset, ...baby }) => ({ baby }));

const onEditBabyModalClosed = stream$ =>
  stream$
    .filter(({ type, name }) => (type === 'MODAL_CLOSED' && name === 'EditBaby'))
    .take(1)
    .map(action => action);

export const waitToShowBabyAgeModal = action$ =>
  action$
    .ofType(Types.EDIT_BABY_REQUESTED)
    .mergeMap(() => Observable.forkJoin(
      onEditBabySuccess(action$),
      onEditBabyModalClosed(action$),
    ).filter(([{ baby }]) => baby.pending_reset)
      .mergeMap(([{ baby }]) => [{ type: 'SHOW_BABY_AGE_MODAL', baby }]));

export const showBabyAgeModal = action$ =>
  action$
    .ofType(Types.SHOW_BABY_AGE_MODAL)
    .mergeMap(({ baby }) => Observable.of(Modals.Creators.openModal({
      name: 'BabyAgeChangedModal',
      data: {
        isPremature: baby.is_premature,
        baby,
      },
    })));

export const showBabyPrematureModal = action$ =>
  action$
    .ofType(Types.SHOW_BABY_PREMATURE_MODAL)
    .mergeMap(({ baby1, baby2 }) => Observable.of(Modals.Creators.openModal({
      name: 'PrematureModal',
      data: {
        baby1,
        baby2,
      },
    })));

export const updateBabyProgress = action$ =>
  action$
    .ofType(Types.UPDATE_BABY_PROGRESS_REQUESTED)
    .mergeMap(({ babyId, pastAge, actualAge }) =>
      Api.postBabyUpdateProgress({ babyId, pastAge, actualAge })
        .then(() => Creators.updateBabyProgressSuccess(babyId)));

export const getBabyClassroom = action$ =>
  action$
    .ofType(Types.GET_BABY_CLASSROOM_REQUESTED)
    .mergeMap(({ babyId }) =>
      Api.getClassroomBaby(babyId)
        .then((res) =>{
          return Creators.getBabyClassroomSuccess(res.data)
        })
        .catch(err => Creators.getBabyClassroomError(err))
    )

export const resetBabyProgress = action$ =>
  action$
    .ofType(Types.RESET_BABY_PROGRESS_REQUESTED)
    .mergeMap(({ babyId }) =>
      Api.postBabyResetProgress({ babyId })
        .then(() => Creators.resetBabyProgressSuccess(babyId)));

export const repeatIAFlow = action$ =>
  action$
    .ofType(Types.RESET_BABY_PROGRESS_SUCCESS)
    .mergeMap(({ babyId }) => Observable.concat(
      [IA.Creators.startIa({ babyId })],
      [IA.Creators.setAssessmentStep(IASteps.READY)],
      [push('/ia')],
      onIAEnd(action$)
        .mergeMap(() => Observable.concat(
          [{ type: 'CREATE_PLAN', forced: true }],
          // TODO: Wait to create plan and then push to dap
          [push('/dap/current')],
        )),
    ));

export const updateIAFlow = action$ =>
  action$
    .ofType(Types.UPDATE_BABY_PROGRESS_SUCCESS)
    .mergeMap(({ babyId }) => Observable.concat(
      [IA.Creators.startIa({ babyId })],
      [IA.Creators.setAssessmentStep(IASteps.READY)],
      [push('/ia')],
      onIAEnd(action$)
        .mergeMap(() => Observable.concat(
          [{ type: 'CREATE_PLAN', forced: true }],
          // TODO: Wait to create plan and then push to dap
          [push('/dap/current')],
        )),
    ));

// export const pendingSkillsIAFlow = action$ =>
//   action$
//     .ofType(Types.PROGRESS_PENDING_SKILL)
//     .mergeMap(({ babyId, areaId, skillId }) => Observable.concat(
//       [IA.Creators.startIa({ babyId })],
//       [IA.Creators.setAssessmentStep(IASteps.SKILLS)],
//       onFetchIASuccess(action$)
//         .mergeMap(action => Observable.concat(
//           [push(`/assessment/${areaId}`, { skillId, context: 'progress' })],
//         )),
//       onIAEnd(action$)
//         .mergeMap(action => Observable.concat(
//           [{ type: 'CREATE_PLAN', forced: true }],
//           // TODO: Wait to create plan and then push to dap
//           [push('/dap/current')],
//         )),
//     ));

export const fetchBabyPlans = action$ =>
  action$
    .ofType(Types.FETCH_BABY_PLANS_REQUESTED)
    .mergeMap(({ babyId, memberId }) =>
      Api.getBabyActivityPlans(babyId)
        .then(res => res.data.data.activity_plans)
        .then(babyPlans => Creators.fetchBabyPlansSuccess(babyPlans, babyId, memberId))
        .catch(err => Creators.fetchBabyPlansError(err)));
