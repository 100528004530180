import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import { colors } from '../../../styles';
import IconPlay from '../../../images/IconPlay.png';
import AreaIcon from '../../shared/AreaIcon';
import { applyColorByAreaId } from '../../../styles/mixins';

const StyledContentBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
  background: ${colors.shadeLighter};
  border-bottom: 1px solid ${colors.shadeMediumLight};
  cursor: pointer;
`;

const Image = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93px;
  height: 93px;
  border-radius: 4px;
  background-color: ${colors.shadeLight};
  background-image: url(${({ src }) => src});
  background-size: cover;
  overflow: hidden;
`;

const ActivityTitle = styled.h1`
  font-size: 16px;
  color: ${applyColorByAreaId};
  letter-spacing: -0.45px;
  margin: 0;
`;

const LeftSide = styled.div``;

const RightSide = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Subtitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.shadeDark};
  letter-spacing: -0.4px;
`;

const Icon = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  background-image: url(${IconPlay});
  background-size: contain;
`;

const Label = styled.span`
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.4);
`;

const ContentAreaIcon = styled(AreaIcon)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 22px;
  height: 22px;
  fill: ${applyColorByAreaId};
  background-color: transparent;
`;

const ContentBlock = ({
  type, item, onOpen, areaId, t,
}) => (
  <StyledContentBlock onClick={onOpen}>
    <LeftSide>
      <Image src={type === 'act' ? item.thumbnail : item.picture}>
        <Overlay />
        {type === 'act' ? <Icon /> : <Label>{t('ARTICLE')}</Label>}
      </Image>
    </LeftSide>
    <RightSide>
      <ActivityTitle areaId={areaId}>{item.name}</ActivityTitle>
      {type === 'act' ? <Subtitle>{t('X_MONTH', { months: item.age, count: item.age })}</Subtitle> : null}
    </RightSide>
    <ContentAreaIcon areaId={item.area_id} />
  </StyledContentBlock>
);

ContentBlock.propTypes = {
  type: PropTypes.string,
  item: PropTypes.object,
  onOpen: PropTypes.func,
  t: PropTypes.func,
};

ContentBlock.defaultProps = {
  type: '',
  item: {},
  onOpen: () => {},
  t: () => {},
};

export default withTranslation([Namespaces.COMMON])(ContentBlock);
