import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Formsy from 'formsy-react-2';
import { compose } from 'ramda';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Creators as ModalCreators } from '../../data/ducks/modals';

import Button from '../../components/shared/buttons/MainButton';
import TextInput from '../../components/shared/TextInput';
import Modal from '../../components/modals/NeoModal';
import { colors } from '../../styles';
import { VALIDATIONS_MESSAGES } from '../../values/validations';

const SubTitle = styled.h4`
   color: ${colors.darkGray};
   font-size: 22px;
   font-weight: 500;
   margin: 25px 0 20px;
 `;

const Form = styled(Formsy.Form)`
  width: 100%;
  max-width: 400px;
  margin: auto;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  button:first-of-type {
    margin-top: 40px;
  }
`;

const CurrentEmail = styled.p`
  color: ${colors.kinedu};
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 15px;

  svg {
    margin-left: 10px;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;


class ChangePassword extends React.Component {
  state = { submitEnabled: true }

  edit = (key, value) => {
    this.setState({ [key]: value, editing: true });
  }

  // saveChanges = () => {
  //   const { email, role } = this.state;
  // }

  toggleSubmit = val => this.setState({ ...this.state, submitEnabled: val })

  render() {
    const {
      closeModal, openModal, user, t,
    } = this.props;
    return (
      <Modal title={t('SECURITY')} width={620} onRequestClose={() => closeModal({ name: 'ChangePassword' })}>
        <Content>
          <Form
            onSubmit={val => console.log(val)}
            onValid={() => this.toggleSubmit(true)}
            onInvalid={() => this.toggleSubmit(false)}
          >
            {/* This is hidden until there is functionality */}
            {false
              && (
              <div>
                <SubTitle>{t('CHANGE_EMAIL')}</SubTitle>
                <CurrentEmail>{user.email}</CurrentEmail>
                <TextInput
                  name="email"
                  type="email"
                  placeholder={t('NEW_EMAIL_PLACEHOLDER')}
                  validations="isEmail"
                  validationError={t(`validations:${VALIDATIONS_MESSAGES.IS_EMAIL}`)}
                  autoComplete="new-password" // hacky
                  required
                />
              </div>
              )}
            <SubTitle>{t('CHANGE_PASSWORD')}</SubTitle>
            <TextInput
              name="password"
              type="password"
              placeholder={t('NEW_PASSWORD_PLACEHOLDER')}
              autoComplete="new-password" // hacky
              required
            />
            <Button
              type="submit"
              color={this.state.submitEnabled ? 'green' : 'disabled'}
              disabled={!this.state.submitEnabled}
              raised
            >
              {t('common:SAVE_CHANGES')}
            </Button>
            <Button
              onClick={() => openModal({ name: 'SettingsModal' })}
              color="clear"
            >
              {t('common:GO_BACK')}
            </Button>
          </Form>
        </Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  closeModal: bindActionCreators(ModalCreators.closeModal, dispatch),
  openModal: bindActionCreators(ModalCreators.openModal, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation([Namespaces.MENU, Namespaces.COMMON, Namespaces.VALIDATIONS]),
)(ChangePassword);
