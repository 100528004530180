import i18n from 'i18next';
import * as ShareApi from '../api/shares';

const defaultPhrase = () => i18n.t('sharing:DEFAULT_PHRASE');
const articlePhrase = title => i18n.t('sharing:ARTICLE_PHRASE', { title });
const activityShareToUnlockPhrase = (title, babyName) => i18n.t('sharing:SHARE_TO_UNLOCK_PHRASE', { title, babyName });
const activityPhrase = title => i18n.t('sharing:ACTIVITY_PHRASE', title);

function facebookShareIntent({ link }) {
  const INTENT_BASE = 'https://www.facebook.com/sharer/sharer.php';
  const encodedLink = encodeURIComponent(link);
  return `${INTENT_BASE}?u=${encodedLink}`;
}

function twitterShareIntent({ link, text }) {
  const INTENT_BASE = 'https://twitter.com/intent/tweet';
  const encodedText = encodeURIComponent(text);
  const encodedLink = encodeURIComponent(link);
  const urlData = `?text=${encodedText}&url=${encodedLink}&hashtags=kinedu`;
  return INTENT_BASE + urlData;
}

function pinterestShareIntent({ link, text, image }) {
  const INTENT_BASE = 'http://pinterest.com/pin/create/button/';
  const encodedLink = encodeURIComponent(link);
  const encodedText = encodeURIComponent(text);
  const encodedImage = encodeURIComponent(image);

  const urlData = `?url=${encodedLink}&media=${encodedImage}&description=${encodedText}`;
  return INTENT_BASE + urlData;
}

export async function getActivityShareIntents({ instanceId, activityId, title, image }) {
  const phrase = activityPhrase(title);
  const activity = { instanceId, activityId, type: 'Activity' };
  const { shareable_link, share_token } = await ShareApi.getShareLink(activity);
  return {
    facebook: facebookShareIntent({ link: shareable_link }),
    twitter: twitterShareIntent({ link: shareable_link, text: phrase }),
    pinterest: pinterestShareIntent({ link: shareable_link, text: phrase, image }),
    token: share_token,
  };
}

export async function getActivityShareToUnlockIntents({ instanceId, activityId, title, baby }) {
  const phrase = activityShareToUnlockPhrase(title, baby.name);
  const activity = { instanceId, activityId, type: 'Activity' };
  const { shareable_link, share_token } = await ShareApi.getShareLink(activity);
  return {
    facebook: facebookShareIntent({ link: shareable_link }),
    twitter: twitterShareIntent({ link: shareable_link, text: phrase }),
    pinterest: pinterestShareIntent({ link: shareable_link, text: phrase }),
    token: share_token,
  };
}

export function getArticleShareIntents({ link, title, picture: image }) {
  const phrase = articlePhrase(title);
  return {
    facebook: facebookShareIntent({ link }),
    twitter: twitterShareIntent({ link, text: phrase }),
    pinterest: pinterestShareIntent({ link, image, text: phrase }),
  };
}

export function getDefaultShareIntents({ link, title, image }) {
  const phrase = defaultPhrase(title);
  return {
    facebook: facebookShareIntent({ link }),
    twitter: twitterShareIntent({ link, text: phrase }),
    pinterest: pinterestShareIntent({ link, image, text: phrase }),
  };
}

