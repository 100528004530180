import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { Types } from '../ducks/past-plans';
import * as PastPlansApi from '../../api/past-plans';

const MAX_PLANS_TO_SHOW = 5;

const error = (type, err) => {
  console.error(err);
  return Observable.of({ type, err });
};

const fetchPlanList = (action$, { getState }) =>
  action$.ofType(Types.fetchPlanListRequested)
    .mergeMap(() => {
      const { activeBabyId } = getState().families;
      return PastPlansApi.getPlanList(activeBabyId)
        .then(response => response.data.data.activity_plans.slice(0, MAX_PLANS_TO_SHOW))
        .then(plans => ({ type: Types.fetchPlanListSuccess, plans }))
        .catch(err => error(Types.fetchPlanListError, err));
    });

const observers = {
  fetchPlanList,
};

export default combineEpics(...Object.values(observers));
