import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Creators, Types } = createActions({
  createBaby: {
    name: undefined,
    lastname: undefined,
    gender: undefined,
    birthday: undefined,
    weeksBeforeBirth: undefined,
    weight: undefined,
    weightUnit: undefined,
    height: undefined,
    heightUnit: undefined,
  },
  createBabyError: ['err'],
  createTwins: ['baby1', 'baby2'],
  createTwinsError: ['err'],
  setPrematureData: {
    premature: false,
    developmentalAge: undefined,
  },
  start: ['OBType'],
  startOb2ia: ['OBType'],
  end: undefined,
}, { prefix: '@OB/' });

// done, probably will need to be changed to a more complex 'flow' map, to track each step.
const INITIAL_STATE = Immutable({
  /** on-boarding type. For Types available see /values/onBoarding */
  OBType: undefined,
  /** when whole ob flow is completed */
  done: false,
  /** true if baby is premature */
  isPremature: false,
  /** developmentalAge of the created baby/twins if premature */
  developmentalAge: undefined,
  /** store the ids of 1 or 2 babies after creation in OB  MISSING */
  createdBabyId: undefined,
  createdTwinyIds: undefined,
  /** from where the user enters to the ob flow */
  context: 'ob',
});


export const Reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PREMATURE_DATA]: (state, { premature, developmentalAge }) =>
    state.merge({ isPremature: premature, developmentalAge }),
  [Types.START]: (state, { OBType, context = 'ob' }) => INITIAL_STATE.merge({ OBType, context }),
  [Types.END]: state => state.merge({ done: true }),
});

