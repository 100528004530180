const AppColor = {
  shadeMediumDark: '#8295A3',
  kinedu: '#0086D8',
  darkGray: '#0086D8',
  oldKinedu: '#1B75BB',
  newKinedu: '#0086d8',
  shadeDark: '#657480',
  shadeLight1: '#8A95A2',
  white: '#FFFFFF',
};

export const colors = { ...AppColor };
