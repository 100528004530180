import BaseApi, { BaseApiV4 } from './BaseApi';
import { APP_VERSION } from '../config';

const WEBAPP_GIFTCARD_SOURCE_NAME = 'WAGiftcard';
const WEBAPP_PRODUCT_SOURCE_NAME = 'WAGiftcard';

export const postGiftcardPayment = ({
  sku, token, forSelf,
  toEmail, toName, fromEmail, fromName, message = 'empty', paymentSource, amount,
}) => BaseApi.post('/payments', {
  sku,
  payment_processor: 'conekta',
  card_token: token,
  payment_source: paymentSource || WEBAPP_GIFTCARD_SOURCE_NAME,
  buying_for: forSelf ? 'me' : 'other',
  to_email: toEmail,
  to_name: toName,
  from_email: fromEmail,
  from_name: fromName,
  message,
  amount,
  app_version: APP_VERSION,
}, { timeout: 25000 });

export const postPayment = ({
  sku, familyId, token, email, paymentSource, testType,
}) => BaseApi.post('/payments', {
  sku,
  card_token: token,
  payment_processor: 'conekta',
  payment_source: paymentSource || WEBAPP_PRODUCT_SOURCE_NAME,
  email,
  family_id: familyId,
  app_version: APP_VERSION,
  message: '',
  test_type: testType,
}, { timeout: 25000 });

export const postStripeGiftcardPayment = ({
  sku, token, paymentSource, forSelf, toEmail, toName, message, fromEmail, fromName,
}) => BaseApiV4.post('/stripe_payments/gift_card', {
  sku,
  card_token: token,
  buying_for: forSelf ? 'me' : 'other',
  to_email: toEmail,
  to_name: toName,
  from_email: fromEmail,
  from_name: fromName,
  message,
  payment_source: paymentSource || WEBAPP_PRODUCT_SOURCE_NAME,
  app_version: APP_VERSION,
}, { timeout: 25000 });


export const postRedeemGiftcard = ({ code, familyId }) => BaseApi.post('/payments', {
  gift_card_code: code,
  family_id: familyId,
});

export const cancelPremium = familyId => BaseApi.post(`/families/${familyId}/cancel_premium`);

export const manageSubscriptions = () => BaseApiV4.get('/stripe_payments/customer_portal');

export const postRedeemPromoCode = ({ code }) => BaseApi.post('/payments/redeem_promo_code', {
  code,
});
