/* eslint-disable import/prefer-default-export */

import EventCalendar from './EventCalendar/EventCalendar';
import CalendarHeader from './EventCalendar/CalendarHeader';
import BodyModal from './EventCalendar/BodyModal';
import Calendar from './EventCalendar/Calendar';
import CardMessageType from './EventCalendar/CardMessageType';
import ConfirmationModal from './EventCalendar/ConfirmationModal';
import Dropdown from './EventCalendar/Dropdown';
import EventTypeSelect from './EventCalendar/EventTypeSelect';
import HeaderModal from './EventCalendar/HeaderModal';
import List from './EventCalendar/List';
import TagLabel from './EventCalendar/Tag';
import EventCalendarContainer from './EventCalendar/EventCalendarContainer';
import EventDescriptionModal from './EventCalendar/EventDescriptionModal';
import FeedEvent from './Feed/Feed';
import InputOTP from './InputOTP/InputOTP';

export const lego = {
  EventCalendar,
  ...CalendarHeader,
  EventDescriptionModal,
  BodyModal,
  Calendar,
  CardMessageType,
  ConfirmationModal,
  Dropdown,
  EventTypeSelect,
  HeaderModal,
  List,
  EventCalendarContainer,
  TagLabel,
  Feed: FeedEvent,
  InputOTP,
};
