import { useState } from 'react';
import { getPrices } from 'api/prices';

function usePrices() {
  const [response, setResponse] = useState(null);
  const [prices, setPrices] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [isSearched, setIsSearched] = useState(null);

  const fetch = (skus) => {
    setIsLoading(true);
    getPrices(skus)
      .then(({ data: responseData }) => {
        setPrices(responseData);
        setResponse(responseData);
      })
      .catch((error) => {
        setPrices(null);
        setResponse(null);
        setErrors(error.response || { message: 'There were an error.' });
      })
      .finally(() => {
        setIsSearched(true);
        setIsLoading(false);
      });
  };

  return {
    isSearched,
    prices,
    response,
    isLoading,
    errors,
    fetch,
  };
}

export default usePrices;
