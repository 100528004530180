import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SegmentedSlider from '../shared/SegmentedSlider';
import { Rounded } from '../themes/SegmentedSlider';
import { colors } from '../../styles';
import Icon from '../shared/Icon';
import { babyMonths } from '../../shared-values';

const StyledMonthSelector = styled.div`
  width: 100%;
  margin: 20px 0 0;
`;

const LeftArrow = styled(Icon).attrs({
  icon: 'chevron-left',
  size: 25,
  color: colors.shadeMedium,
})`
  cursor: pointer;
  margin-right: 10px;
  user-select: none;
`;

const RightArrow = styled(Icon).attrs({
  icon: 'chevron-right',
  size: 25,
  color: colors.shadeMedium,
})`
  cursor: pointer;
  margin-left: 10px;
  user-select: none;
`;

const MonthSelector = ({ selectedMonth, setSelectedMonth, t }) => {
  const months = babyMonths.map(m => ({ ...m, label: t('common:X_MONTH', { months: m.label, count: m.value }) }));
  return (
    <StyledMonthSelector>
      {(selectedMonth !== null) &&
        (<SegmentedSlider
          value={selectedMonth}
          options={months}
          responsive={{
            0: 3,
            480: 7,
          }}
          leftButton={<LeftArrow />}
          rightButton={<RightArrow />}
          theme={Rounded}
          onItemSelected={({ value }) => setSelectedMonth(value)}
        />)}
    </StyledMonthSelector>
  );
};

MonthSelector.propTypes = {
  selectedMonth: PropTypes.number,
  setSelectedMonth: PropTypes.func,
  t: PropTypes.func,
};

MonthSelector.defaultProps = {
  selectedMonth: 0,
  setSelectedMonth: () => { },
  t: () => {},
};

export default MonthSelector;
