import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import Modal from './NeoModal';
import { breakPoints } from '../../styles';

import RedeemGiftView from '../views/RedeemGift';

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;

  ${breakPoints.smallTablet} {
    margin: auto;
    height: auto;
  }
`;


/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'RedeemModal',
  data: {
  },
}

*/

const RedeemModal = () => (
  <Wrapper width={900} closeButtonColor="white" roundedCorners>
    {({ closeModal }) => (
      <RedeemGiftView closeModal={closeModal} />
    )}
  </Wrapper>
);

RedeemModal.propTypes = {
  onClose: PropTypes.func,
};

RedeemModal.defaultProps = {
  onClose: () => { },
};

export default withTranslation([Namespaces.MODALS])(RedeemModal);
