import { PAYMENT_CARD_THEMES } from 'components/premiumProcessModal/values';
import styled, { css } from 'styled-components';

const themeThreeCardStyles = css``;

const themeTwoCardStyles = css`
  margin-bottom: 28px;

  .PlanCardContent {
    padding: 21px 20px;
    .PlanCardBody {
      grid-template-columns: minmax(100px, max-content) 1fr 25px;
      grid-gap: 0 17px;
      grid-template-areas: "PlanType PlanPrice IconSelected";
      padding-top: 17px;

      .PlanType,
      .PlanPrice {
        font-size: 20px;
        font-weight: 600;
        line-height: 31px;
      }

      .PlanPrice {
        line-height: 28px;
        text-align: center;
        .PlanMonthlyPrice {
          margin-top: 13px;
        }
      }

      .IconSelected {
        width: 25px;
        height: 25px;

        & > svg {
          height: 13px !important;
          width: 13px !important;
        }
      }


    }
  }

  ${({ theme }) => theme.breakPoints.largeTablet} {
    margin-bottom: 40px;
    padding: 5px;

    .PlanCardContent {
      padding-top: 27px;
      padding-bottom: 23px;

      .PlanCardHeader {
        font-size: 14px;
        line-height: 28px;
      }

      .PlanCardBody {
        grid-template-columns: minmax(100px, max-content) 1fr 25px;
        justify-items: baseline;
        grid-gap: 0;
        padding: ${({ showHeader }) => (showHeader ? '15px 0 2px 0' : '7px 0 10px 0')};

        .PlanType {
          font-size: 16px;
        }
        .PlanPrice {
          font-size: 20px;

          .PlanMonthlyPrice {
            font-size: 14px;
            margin-top: 10px;
          }
        }
      }
    }
  }
`;

const themeOneCardStyles = css`
  .PlanCardContent {
    padding: 35px 10px;

    .PlanCardHeader {
      font-size: 14px;
      line-height: 26px;

      & + .PlanCardBody {
        padding-top: 22px;
      }
    }

    .PlanCardBody {
      grid-template-columns: 50% 1fr;
      grid-template-areas: "PlanType PlanPrice";

      .PlanType {
        font-size: 20px;
        line-height: 31px;
      }

      .PlanPrice {
        line-height: 28px;
        text-align: center;
        font-size: 24px;
        line-height: 28px;

        .PlanMonthlyPrice {
          margin-top: 25px;
          font-size: 14px;
          line-height: 13px;
        }
      }

      .IconSelected {
        display: none;
      }
    }
  }

  ${({ theme }) => theme.breakPoints.largeTablet} {
    .PlanCardContent {
      padding: 40px 10px 44px 10px;

      .PlanCardHeader {
        font-size: 14px;
        line-height: 28px;
        padding: 6px 10px;

        & + .PlanCardBody {
          padding-top: 30px;
        }
      }

      .PlanCardBody {
        grid-template-columns: 1fr;
        grid-template-areas: "PlanType"
                             "PlanPrice";
        grid-gap: 40px 0;



        /* justify-items: baseline;
        grid-gap: 0;
        padding: ${({ showHeader }) => (showHeader ? '15px 0 2px 0' : '7px 0 10px 0')}; */

        .PlanType {
          text-align: center;
          font-size: 24px;
        }
        .PlanPrice {
          font-size: 40px;

          .PlanMonthlyPrice {
            font-size: 18px;
            margin-top: 32px;
          }
        }
      }
    }
  }
`;

const themeThreeDesktopCardStyles = css`
  margin-bottom: 28px;

  .PlanCardContent {
    padding: 21px 20px;
    .PlanCardBody {
      grid-template-columns: minmax(100px, max-content) 1fr 25px;
      grid-gap: 0 17px;
      grid-template-areas: "PlanType PlanPrice IconSelected";
      padding-top: 17px;

      .PlanType,
      .PlanPrice {
        font-size: 20px;
        font-weight: 600;
        line-height: 31px;
      }

      .PlanPrice {
        line-height: 28px;
        text-align: center;
        .PlanMonthlyPrice {
          margin-top: 13px;
        }
      }

      .IconSelected {
        width: 25px;
        height: 25px;

        & > svg {
          height: 13px !important;
          width: 13px !important;
        }
      }


    }
  }

  ${({ theme }) => theme.breakPoints.largeTablet} {
    margin-bottom: 30px;
    padding: 5px;

    .PlanCardContent {
      padding-top: 8px;
      padding-bottom: 8px;

      .PlanCardBody {
        grid-template-columns: minmax(95px, max-content) 1fr 25px;
        justify-items: baseline;
        grid-gap: 0;
        padding: ${({ showHeader }) => (showHeader ? '15px 0 2px 0' : '7px 0 10px 0')};

        .PlanType {
          font-size: 14px;
        }
        .PlanPrice {
          font-size: 18px;

          .PlanMonthlyPrice {
            font-size: 12px;
            margin-top: 0px;
          }
        }
      }
    }
  }
`;

const themesStyles = {
  [PAYMENT_CARD_THEMES.THREE]: themeThreeCardStyles,
  [PAYMENT_CARD_THEMES.TWO]: themeTwoCardStyles,
  [PAYMENT_CARD_THEMES.ONE]: themeOneCardStyles,
  [PAYMENT_CARD_THEMES.THREE_DESKTOP]: themeThreeDesktopCardStyles,
};

const PlanCardSelectedStyles = css`
  border-color: #3eb646;

  .PlanType {
    color: #3eb646;
    opacity: 1;
  }

  .IconSelected {
    background-color: #3eb646;

    svg {
      display: block;
    }
  }
`;

const PlanCardWrapper = styled.div.attrs({
  className: 'PlanCardWrapper',
})`
  display: block;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  padding: 4px;
  border: 2px solid transparent;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0px;
  }

  ${({ isSelected }) => isSelected && PlanCardSelectedStyles};


  ${({ cardTheme }) => cardTheme && themesStyles[cardTheme]};

  ${({ theme }) => theme.breakPoints.largeTablet} {
  }
`;

const PlanCardContent = styled.div.attrs({
  className: 'PlanCardContent',
})`
  display: block;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e6e7e8;
  padding: 16px 20px;
  position: relative;
`;
const PlanCardHeader = styled.span.attrs({
  className: 'PlanCardHeader',
})`
  display: block;
  width: 100%;
  border-radius: 8px 8px 0 0;
  padding: 2px 10px;

  color: #fff;
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  background-color: #3eb646;

  position: absolute;
  left: 0;
  top: 0;

  & + .PlanCardBody {
    padding-top: 12px;
  }

  ${({ theme }) => theme.breakPoints.largeTablet} {
    font-size: 12px;
    padding: 2.5px 10px;
  }
`;

const PlanCardBody = styled.div.attrs({
  className: 'PlanCardBody',
})`
  display: grid;
  grid-template-columns: 77px max-content 66px 20px;
  grid-template-areas: "PlanType PlanPrice PlanMonthlyPrice IconSelected";

  width: 100%;
  color: #404040;
  font-family: "Proxima Nova";
  font-style: normal;
  align-items: center;
  justify-content: space-between;
`;

const TextDetail = styled.p.attrs({
  className: 'TextDetail',
})`
  display: block;
  margin: 0;
  padding: 0;
`;

const PlanType = styled.div.attrs({
  className: 'PlanType',
})`
  display: block;
  color: #404040;
  font-family: "Proxima Nova";
  font-size: 12px;

  font-weight: 600;
  line-height: 31px;
  opacity: 0.5;
  text-align: left;

  grid-area: PlanType;
`;

const PlanPrice = styled.div.attrs({
  className: 'PlanPrice',
})`
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  grid-area: PlanPrice;

  .TextDetail {
    align-items: center;
  }
`;

const PlanMonthlyPrice = styled.div.attrs({
  className: 'PlanMonthlyPrice',
})`
  display: block;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  grid-area: PlanMonthlyPrice;

  .TextDetail {
    text-align: center;
  }
`;

const IconSelected = styled.div.attrs({
  className: 'IconSelected',
})`
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #e6e7e8;
  position: relative;
  grid-area: IconSelected;

  svg {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default PlanCardWrapper;
export {
  PlanCardContent,
  PlanCardHeader,
  PlanCardBody,
  TextDetail,
  PlanType,
  PlanPrice,
  PlanMonthlyPrice,
  IconSelected,
};
