import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import Baby from '../../../images/BabyWinner.png';
import Title from '../../shared/Title';
import { colors } from '../../../styles';
import MainButton from '../../shared/buttons/MainButton';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img.attrs({ src: props => props.src })`
  width: 205px;
  margin: 10px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  color: ${colors.kinedu};
  margin-bottom: 25px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: ${colors.kinedu};
  font-size: 24px;
  font-weight: bold;
  margin: 30px 0 50px;

  p {
    margin: 0;
    font-weight: bold;
  }
`;

const Description = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 300px;

  color: ${colors.kinedu};
  font-size: 20px;
  text-align: center;
`;

const titleStyles = { margin: '60px 0px 30px', textTransform: 'uppercase' };

const OnTrack = ({
  t, baby, closeModal, activeMilestones, completedMilestones,
}) => (
  <Wrapper>
    <Title size={36} color={colors.kinedu} style={titleStyles} bold>
      {t('common:AWESOME')}
    </Title>
    <Content>
      <Image src={Baby} />
      <Description>
        {t('DOPAMINE.ON_TRACK.DESCRIPTION', { babyName: baby.name })}
      </Description>
      <Info>
        <p>{t('DOPAMINE.MILESTONE_STATUS', { current: completedMilestones, total: activeMilestones })}</p>
        <p>{t('DOPAMINE.MILESTONES_COMPLETED')}</p>
      </Info>
    </Content>
    <MainButton color="green" onClick={closeModal} maxWidth={300}>{t('common:CONTINUE')}</MainButton>
  </Wrapper>
);

OnTrack.propTypes = {
  baby: PropTypes.object,
  t: PropTypes.func,
};

OnTrack.defaultProps = {
  baby: {},
  t: () => {},
};

export default withTranslation([Namespaces.MILESTONES, Namespaces.COMMON])(OnTrack);
