const sizeInt = {
  largePhone: 480,
  tablet: 600,
  largeTablet: 900,
  desktop: 1024,
  largeDesktop: 1400,
  biggerScreens: 1920,
};


const size = {
  largePhone: `${sizeInt.largePhone}px`,
  tablet: `${sizeInt.tablet}px`,
  largeTablet: `${sizeInt.largeTablet}px`,
  desktop: `${sizeInt.desktop}px`,
  largeDesktop: `${sizeInt.largeDesktop}px`,
  biggerScreens: `${sizeInt.biggerScreens}px`,
};


const breakPoints = {
  largePhone: `@media (min-width: ${size.largePhone})`,
  tablet: `@media (min-width: ${size.tablet})`,
  largeTablet: `@media (min-width: ${size.largeTablet})`,
  desktop: `@media (min-width: ${size.desktop})`,
  largeDesktop: `@media (min-width: ${size.largeDesktop})`,
  biggerScreens: `@media (min-width: ${size.biggerScreens})`,
};

export { breakPoints, size, sizeInt };
