/* eslint-disable import/prefer-default-export */
import { Observable } from 'rxjs';
import { toast } from 'react-toastify';
import * as FamiliesApi from '../../api/families';
import * as InvitesApi from '../../api/invites';
import EventReporter, { Events } from '../../lib/EventReporter';
import { User } from '../ducks';
import { Creators as CreatorFamilies} from '../ducks/babiesAndFamilies';

const { Creators, Types } = require('../ducks/invites');
const Modals = require('../ducks/modals');

export const updateInvitationStatus = action$ => action$.ofType(Types.UPDATE_INVITE_STATUS_REQUESTED)
  .mergeMap(({ familyId, membershipId, status }) => FamiliesApi.putFamilyMembershipStatus({ membershipId, familyId, status })
    .then(res => res.data)
    .then(res => Creators.updateInviteStatusSuccess(res))
    .catch((error) => {
      toast.error('There was an error');
      Creators.updateInviteStatusError(error);
    }));

export const updateInvitationError = action$ => action$.ofType(Types.UPDATE_INVITE_STATUS_ERROR)
  .mergeMap(() => [
    Modals.Creators.closeModal({ name: 'CLOSE_ALL_MODALS' }),
  ]);

export const updateInvitationSuccess = action$ => action$.ofType(Types.UPDATE_INVITE_STATUS_SUCCESS)
  .mergeMap(() => [
    Modals.Creators.closeModal({ name: 'CLOSE_ALL_MODALS' }),
    User.Creators.fetchPendingInvitesRequested(),
  ]);

export const fetchInvitedClassroom = action$ => action$.ofType(Types.FETCH_INVITED_CLASSROOM_REQUESTED)
  .mergeMap(({ code, onSuccess }) => InvitesApi.getInvitedClassroomData({ code })
    .then(res => res.data.data)
    .then((invitedClassroomData) => {
      onSuccess();
      EventReporter.action(Events.VERIFY_CODE(code, 'yes'));
      return Creators.fetchInvitedClassroomSuccess({ ...invitedClassroomData, code });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        EventReporter.action(Events.VERIFY_CODE(code, 'no'));
        return Creators.fetchInvitedClassroomError('CLASSROOM_CODE.INVALID_ID');
      }
      return Creators.fetchInvitedClassroomError('validations:RESPONSE_ERRORS.GENERAL_ERROR');
    }));

export const addClassroom = (action$) => action$.ofType(Types.JOIN_CLASSROOM_REQUESTED)
  .mergeMap(({ babyId, onSuccess, code, onError }) => 
    Observable.fromPromise(
      InvitesApi.addStudentToClassroom({ babyId, code })
        .then(res => res.data)
        .then((data) => {
          onSuccess();
          return Creators.joinClassroomSuccess(data);
        })
    )       
    .concatMap(() => {
      return ([
        User.Creators.fetchUserDetailRequested(),
        CreatorFamilies.fetchFamiliesRequested(),
        CreatorFamilies.getBabyClassroomRequested(babyId),
        Creators.joinClassroomLoading(false)
      ]);
    })
    .catch((error) => {
      onError();
      return [(Creators.joinClassroomLoading(false))];
    }));


export const sendLead = action$ => action$.ofType(Types.SEND_LEAD_REQUESTED)
  .mergeMap(({ name, email, onSuccess, onError }) => InvitesApi.sendInviteToEducator({ name, email })
    .then(() => {
      if (onSuccess) onSuccess();
      return Creators.sendLeadLoading(false);
    })
    .catch(() => {
      if (onError) onError();
      return Creators.sendLeadLoading(false);
    }));
