import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';
import { parse } from 'query-string';

import { withTranslation } from 'react-i18next';
import { Namespaces } from 'values/i18n';

import { Creators } from 'data/ducks/modals';

import TextInput from 'components/shared/TextInput';
import { Flat } from 'components/themes/TextInput';
import Button from 'components/shared/buttons/MainButton';

import { Providers, Types as AuthTypes } from 'values/auth';
import withAuth, { propTypes as withAuthProptypes } from 'hoc/withAuth';
import { VALIDATIONS_MESSAGES } from 'values/validations';
import EventReporter, { Events } from 'lib/EventReporter';
import { colors } from 'styles';
import i18n from 'i18n';
import { KEY_LOCALE } from 'shared-values';
import ViewWrapper, {
  Form, InlineButtons, OptionButton, Note, Separator, Subtitle, Title, LogoImage, FormContent, FormContentBody, FormContentFoot,
} from '../../styles';

i18n.changeLanguage(window.localStorage.getItem(KEY_LOCALE) || 'en');

const authTypes = {
  [AuthTypes.SIGNUP]: {
    title: 'WELCOME_TO_KINEDU',
    subtitle: 'LETS_BEGIN',
    buttonLabel: 'SIGNUP',
  },
  [AuthTypes.LOGIN]: {
    title: 'WELCOME_BACK',
    subtitle: 'LOGIN_EXISTING_ACC',
    buttonLabel: 'LOGIN',
  },
};

class FormLoginComponent extends Component {
  constructor(props) {
    super(props);
    this.handleOnValid = this.handleOnValid.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.state = {
      values: {
        password: '',
        email: '',
      },
      formIsValid: false,
    };
  }

  handleOnValid(formIsValid) {
    this.setState({ formIsValid });
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.keyPress, false);
    EventReporter.view(Events.LOGIN_SCREEN());
    const { section } = parse(window.location.search);
    if (section === 'forgot_password') {
      this.onForgotPasswordClicked();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    // EventReporter.view(Events.LOGIN_SCREEN());
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.keyPress, false);
  }

  onForgotPasswordClicked = () => {
    this.props.openModal({ name: 'ForgotPasswordModal' });
    EventReporter.action(Events.FORGOT_PASSWORD());
  }

  handleOnSubmit = () => {
    if(this.props.onLogin)
      this.props.onLogin({ provider: Providers.EMAIL, ...this.state.values });
  };

  toggleSubmit = val => this.setState({ submitEnabled: val });

  handleOnClickGoSignup = () => {
    const { onAuthTypeChange } = this.props;
    if(onAuthTypeChange)
      onAuthTypeChange(AuthTypes.SIGNUP);
  }

  onChangeValues = (values) => {
    this.setState({ values: { ...values } });
  }

  render() {
    const { t, error } = this.props;

    return (
      <ViewWrapper>
        <Form
          onValid={() => this.handleOnValid(true)}
          onInvalid={() => this.handleOnValid(false)}
          onChange={values => this.onChangeValues(values)}
          onValidSubmit={() => this.handleOnSubmit()}
        >
          <FormContent>
            <FormContentBody>
              <LogoImage />
              <Title
                size={30}
                color={colors.kinedu}
                align="center"
                bold
              >
                {t(`common:AUTH.${authTypes.signup.title}`)}
              </Title>
              <Subtitle>{t(`common:AUTH.${authTypes.login.subtitle}`)}</Subtitle>
              <Separator />

              <TextInput
                className="new-input"
                type="email"
                name="email"
                label={t('common:PLACEHOLDERS.EMAIL')}
                placeholder={t('common:PLACEHOLDERS.EXAMPLE_EMAIL')}
                theme={Flat}
                errorText={error && t(`validations:${error}`)}
                validationErrors={{
                  isDefaultRequiredValue: t(`validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`),
                  isEmail: t(`validations:${VALIDATIONS_MESSAGES.EMAIL_INVALID}`),
                }}
                validations="isEmail"
                value={this.state.values.email}
                required
              />
              <TextInput
                className="new-input"
                type="password"
                name="password"
                label={t('common:PLACEHOLDERS.PASSWORD')}
                placeholder={t('common:PLACEHOLDERS.TYPE_YOUR_PASSWORD')}
                theme={Flat}
                validationErrors={{
                  isDefaultRequiredValue: t(`validations:${VALIDATIONS_MESSAGES.IS_REQUIRED}`),
                }}
                autoComplete="new-password"
                value={this.state.values.password}
                required
              />

              <OptionButton
                type="button"
                style={{ margin: '0px 0px 20px auto', display: 'block' }}
                onClick={this.onForgotPasswordClicked}
                underlined
              >
                {t('common:AUTH.FORGOT_PASSWORD')}
              </OptionButton>
            </FormContentBody>
            <FormContentFoot>
              <InlineButtons>
                <Button
                  className="btnSubmit"
                  type="submit"
                  color="green"
                  disabled={!this.state.formIsValid}
                  rounded
                  small
                  maxWidth={300}
                >
                  {t(`common:AUTH.${authTypes.login.buttonLabel}`)}
                </Button>

              </InlineButtons>
              <Note>
                <span style={{ marginRight: '5px' }}>{t('ob:AUTH.DONT_HAVE_ACC_YET')}</span>
                <OptionButton type="button" onClick={() => this.handleOnClickGoSignup()} underlined>{t('ob:AUTH.SIGN_UP')}</OptionButton>
              </Note>

            </FormContentFoot>
          </FormContent>
        </Form>
      </ViewWrapper>
    );
  }
}

FormLoginComponent.propTypes = {
  ...withAuthProptypes,
  onAuthTypeChange: PropTypes.func,
};

// TODO: Check why is making this value undefined first
FormLoginComponent.defaultProps = {
  formIsValid: false,
  values: {
    password: '',
    email: ''
  },
  onAuthTypeChange: () => {},
};


const mapDispatchToProps = dispatch => bind({
  openModal: Creators.openModal,
}, dispatch);

export default compose(
  connect(() => ({}), mapDispatchToProps),
  withTranslation([Namespaces.MODALS, Namespaces.COMMON, Namespaces.VALIDATIONS]),
  withAuth,
)(FormLoginComponent);
