import styled, { css } from 'styled-components';



const OvalSelectorContent = styled.div.attrs({
  className: 'OvalSelectorContent',
})`
  display: block;
`;

const OvalSelectorLabel = styled.label.attrs({
  className: 'OvalSelectorLabel',
})`
  display: block;
  margin-bottom: 10px;
  font-family: "Proxima Nova";
  color: #333;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;

  ${({ theme }) => theme.breakPoints.desktop} {
    color: #404040;
    font-size: 20px;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 20px;
  }
`;

const OvalSelectorInput = styled.input.attrs({ type: 'number' })`
  display: none;
  width: 0;
  height: 0;
  opacity: 0;
`;

const OvalSelectorContentOptions = styled.div.attrs({
  className: 'OvalSelectorContentOptions',
})`
  display: grid;
  grid-template-columns: ${({ mobileColumns }) => mobileColumns || 'repeat(auto-fit, minmax(90px, 90px))'};
  grid-gap: 20px;
  justify-content: center;
  justify-items: center;
  margin: 0 auto;

  ${({ theme }) => theme.breakPoints.desktop} {
    grid-template-columns: ${({ columns }) => columns || 'repeat(auto-fit, minmax(90px, 185px))'};
  }
`;

const OvalSelectorOptionSelectedStyles = css`
  color: #FFF;
  border: 1px solid #0086D8;
  background-color: #0086D8;
`;

const OvalSelectorOption = styled.div.attrs({
  className: 'OvalSelectorOption',
})`
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: "Proxima Nova";
  font-weight: 600;
  border-radius: 56px;
  padding: 9.5px 5px;
  color: #A0B3BE;
  border: 1px solid #E6E7E8;
  background: #FFF;
  cursor: pointer;
  ${({ selected }) => selected && OvalSelectorOptionSelectedStyles};

  ${({ theme }) => theme.breakPoints.desktop} {
    padding: 12.5px 5px;
    font-size: 16px;
  }

`;

const OvalSelectorWrapper = styled.div.attrs(({ className }) => ({
  className: `OvalSelectorWrapper ${className || ''}`,
}))`
  display: block;
`;

export default OvalSelectorWrapper;
export {
  OvalSelectorContent,
  OvalSelectorLabel,
  OvalSelectorInput,
  OvalSelectorContentOptions,
  OvalSelectorOption,
};
