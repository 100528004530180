/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable arrow-parens */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { path } from 'ramda';

import base64 from 'base-64';
import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';
import { camelizeKeys } from 'humps';
import Leanplum from 'leanplum-sdk';

import { Types } from '../ducks/auth';
import * as Api from '../../api/auth';
import * as UserApi from '../../api/users';
import { setAuthToken } from '../../api/BaseApi';
import { LOCALSTORAGE_API_KEY, LOCALSTORAGE_USER_ID, LOCALSTORAGE_GA_SIGNUP_EVENT_SENT } from '../../shared-values';
import { Providers } from '../../values/auth';

import * as FB from '../../lib/fb';
import EventReporter, { Events, EventProviders, setUserAttributes } from '../../lib/EventReporter';

import { VARIABLES_DEFAULT } from 'hooks/leanplum/values';

// import history from '../../history';
// const { push } = history;

const error = (type, err) => Observable.of({ type, err });
const encodeCredentials = ({ email, password }) => base64.encode(`${email}:${password}`);

const setAuth = ({ token, user }) => {
  setAuthToken(token);
  window.localStorage.setItem(LOCALSTORAGE_API_KEY, token);
  window.localStorage.setItem(LOCALSTORAGE_USER_ID, user.id);
  Leanplum.setUserId(`${user.id}`);
  Leanplum.setVariables(VARIABLES_DEFAULT);
};

// TODO: Ver como sacar el flatmap y dejar el concat desde fuera y manejar error mejor.
const federatedLogin = () => Observable.fromPromise(
  FB.signIn()
    .then(({
      thirdPartyToken, thirdPartyuserId, userData, provider,
    }) => Api.thirdParty({ accessToken: thirdPartyToken, provider, userId: thirdPartyuserId })
      .then(res => res.data.data)
      .then(({ token, user }) => {
        setAuth({ token, user });
        EventReporter.action(Events.LOGIN(), [EventProviders.MIXPANEL, EventProviders.GTM]);
        return [
          { type: Types.setThirdPartyUserData, userData },
          { type: Types.loginSuccess, userId: user.id, userData },
        ];
      }))
    .catch(err => Observable.of({ type: Types.loginError, err })),
)
  .flatMap(x => Observable.concat(Observable.from(x)));

const emailLogin = ({ email, password }) => Api.login(encodeCredentials({ email, password }))
  .then(res => res.data.data)
  .then(({ token, user }) => {
    setAuth({ token, user });
    EventReporter.action(Events.LOGIN(), [EventProviders.MIXPANEL, EventProviders.GTM]);
    return { type: Types.loginSuccess, userId: user.id };
  }).catch(() => ({ type: Types.loginError, error: 'WRONG_PARAMS_ERROR' }));

const emulatedLogin = ({ token, userId }) => UserApi.getUserWithToken(userId, token)
  .then(res => camelizeKeys(res.data.data.user))
  .then((user) => {
    setAuth({ token, user });
    EventReporter.action(Events.LOGIN(), [EventProviders.MIXPANEL, EventProviders.GTM]);
    return { type: Types.loginSuccess, userId: user.id };
  }).catch(() => ({ type: Types.loginError, error: 'WRONG_PARAMS_ERROR' }));

const login = action$ => action$.ofType(Types.loginRequested)
  .mergeMap((action) => {
    const { provider } = action;
    if (provider === Providers.EMULATED) {
      return emulatedLogin(action);
    }
    if (provider === Providers.EMAIL) {
      return emailLogin(action);
    }

    return federatedLogin(action);
  });

const checkAuthStatus = action$ => action$.ofType(Types.checkAuthStatus)
  .map(() => {
    const token = window.localStorage.getItem(LOCALSTORAGE_API_KEY);
    const userId = window.localStorage.getItem(LOCALSTORAGE_USER_ID);

    if (token && userId) {
      setAuthToken(token);
    }

    return {
      type: Types.setAuthStatus,
      authenticated: Boolean(token && userId),
      userId,
    };
  });

const createSession = action$ => action$.ofType(Types.createSessionRequested)
  .mergeMap(() => Api.createSession()
    .then(res => res.data.data.session_token)
    .then(session => session))
  .map(session => ({ type: Types.createSessionSuccess, session }))
  .catch(err => error(Types.createSessionSuccess, err));

const signup = action$ => action$.ofType(Types.signupRequested)
  .mergeMap(({ email, password, cohort, lang }) => Api.signup({ email, password, cohort, lang })
    .then(res => res.data.data)
    .then(({ token, user }) => {
      setAuth({ token, user });
      setUserAttributes({ source: 'KIN' }); // Here is the source that was commented in appinit file
      EventReporter.view(Events.COMPLETE_REGISTRATION(), [EventProviders.PIXEL]);
      return user.id;
    })
    .then((userId) => {
      mixpanel.alias(userId);
      if (!localStorage.getItem(LOCALSTORAGE_GA_SIGNUP_EVENT_SENT)) {
        EventReporter.action(Events.SIGNUP(), [EventProviders.MIXPANEL, EventProviders.GTM]);
        localStorage.setItem(LOCALSTORAGE_GA_SIGNUP_EVENT_SENT, true);
      }
      UserApi.setAlias(userId);
      return ({ type: Types.signupSuccess, userId });
    })
    .catch((err) => {
      if (path(err, 'response.data.error_data_errors.email')) {
        // TODO: SACAR ESTO
        return { type: Types.signupError, error: 'EMAIL_ERROR' };
      }
      return { type: Types.signupError, error: 'UNKNOWN_ERROR' };
    }));

const sendRestoreEmail = action$ => action$.ofType(Types.sendRestoreEmailRequested)
  .mergeMap(({ email }) => Api.postResetPasswordEmail(email)
    .then(() => ({ type: Types.sendRestoreEmailSuccess }))
    .catch(err => ({ type: Types.sendRestoreEmailError, err })));

const restorePassword = (action$) =>
  action$
    .ofType(Types.restorePasswordRequested)
    .mergeMap(({ password, token }) =>
      Observable.fromPromise(
        Api.postResetPassword({ password, token })
          .then((res) => {
            if (res?.data?.data) {
              return res?.data?.data;
            }
            return Observable.throw(error);
          })
          .then((data) => {
            if (data && data?.user?.id && data?.token) {
              if (data?.user?.id) {
                setAuth({ token: data.token, user: data.user });
                return data?.user?.id;
              }
            }
            return Observable.throw(error);
          })
          .then((userId) => [
            { type: Types.loginSuccess, userId },
            // push('/dap/current'),
          ])
          .then((rest) => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          })
          .catch((error) => {
            return Observable.throw(error);
          })
      )
        .catch((err) => {
          // toast.error('An error has occurred');
          return { type: Types.restorePasswordError, err };
        })
        .flatMap((flating) => flating)
    );

const logout = action$ => action$.ofType(Types.logout)
  .map(() => ({ type: 'LOGOUT_SUCCESS' }));

export const observers = {
  login,
  checkAuthStatus,
  createSession,
  signup,
  sendRestoreEmail,
  restorePassword,
  logout,
};

export default combineEpics(...Object.values(observers));
export {
  setAuth,
};
