import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { Creators } from '../../data/ducks/modals';

import NeoModal from '../../components/modals/NeoModal';
import CreditCard from '../../components/shared/CreditCard';
import Button from '../../components/shared/buttons/MainButton';
import { colors } from '../../styles';

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const SubTitle = styled.h4`
   color: ${colors.darkGray};
   font-size: 24px;
   font-weight: 500;
   margin: 20px 0;
 `;

const Content = styled.div`
  width: 100%;
  max-width: 670px;
  margin-bottom: 30px;
  text-align: center;
`;

const BottomContent = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0px 15px;
  box-sizing: border-box;

  margin-top: 30px;
`;

class Payment extends React.Component {
  constructor() {
    super();
    this.state = {
      editing: false,
      sent: false,
    };

    this.edit = this.edit.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  edit(key, value) {
    this.setState({ [key]: value, editing: true });
  }

  saveChanges() {
    this.setState({ sent: true });
  }

  render() {
    const { t } = this.props;
    return (
      <Modal title={t('PAYMENT')} width={900}>
        {({ closeModal }) => (
          <Content>
            <SubTitle>{t('CREDIT_CARD_DETAILS')}</SubTitle>
            <CreditCard isValid={(isIt) => { console.log(isIt); }} />
            <BottomContent>
              <Button
                maxWidth={300}
                onClick={this.saveChanges}
                color={this.state.editing ? 'green' : 'disabled'}
                disabled={!this.state.editing}
                raised
              >
                {t('UPDATE_CARD_INFO')}
              </Button>
              <Button
                maxWidth={300}
                onClick={closeModal}
                color="clear"
              >
                {t('common:GO_BACK')}
              </Button>
            </BottomContent>
          </Content>
        )}
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  closeModal: bindActionCreators(Creators.closeModal, dispatch),
  openModal: bindActionCreators(Creators.openModal, dispatch),
});

Payment.propTypes = {
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
};

Payment.defaultProps = {
  openModal: () => { },
  closeModal: () => { },
};

export default compose(
  withTranslation([Namespaces.MENU, Namespaces.COMMON]),
  connect(() => {}, mapDispatchToProps),
  withRouter,
)(Payment);
