import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import { colors, breakPoints } from '../../../styles';

import CheckList from '../../shared/CheckList';
import Button from '../../shared/buttons/MainButton';

import BabyWatch from '../../../images/BabySeriousWithWatch.svg';
import { QUERY_PARAM_TO_SHOW_MODAL } from 'components/premiumProcessModal/values';

const Image = styled.img.attrs({ src: BabyWatch })`
  width: 150px;
`;

const PassExpiredViewContainer = styled.div`
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-image: radial-gradient(50% 90%, #59D1F4 36%, #00B9FF 91%);

  ${breakPoints.smallTablet} {
    height: auto;
  }
`;

const Title = styled.h2`
  color: ${colors.white};
  font-weight: 500;
  font-size: 34px;
  letter-spacing: -1.09px;
  text-align: center;

  margin: 30px 0 0;
`;

const Subtitle = styled.p`
  color: ${colors.white};
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.65px;
  text-align: center;

  ${breakPoints.smallTablet} {
    font-weight: 400;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${breakPoints.smallTablet} {
    flex-direction: row;
  }
`;

const ListTitle = styled.h3`
  color: ${colors.white};
  font-weight: 500;
  font-size: 24px;
  letter-spacing: -0.77px;
  margin: 10px 0;
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 50%;
  padding-right: 20px;
  box-sizing: border-box;

  ${breakPoints.smallTablet} {
    padding-right: 70px;
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${breakPoints.smallTablet} {
    width: 50%;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px;
`;

const Highlight = styled.strong`
  color: #332527;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: -0.76px;
`;

const PassExpiredView = ({ t, onClose }) => (
  <PassExpiredViewContainer>
    <Title>{t('FREE_PASS.TITLE')}</Title>
    <Subtitle>
      {t('FREE_PASS.SUBTITLE')}
      <Highlight>{t('FREE_PASS.FREE_USER')}</Highlight>
    </Subtitle>
    <Body>
      <LeftSide>
        <Image />
      </LeftSide>
      <RightSide>
        <ListTitle>{t('FREE_PASS.LIST_TITLE')}</ListTitle>
        <CheckList items={[
          { title: t('BENEFITS.FULL_PERSONALIZED_PLAN') },
          { title: t('BENEFITS.CATALOG') },
          { title: t('BENEFITS.UNLOCK_PROGRESS') },
          { title: t('BENEFITS.UNLIMITED_MEMBERS_ACC') },
        ]}
        />
      </RightSide>
    </Body>
    <Footer>
      <Link to={`?${QUERY_PARAM_TO_SHOW_MODAL}=true`} style={{ textDecoration: 'none' }}>
        <Button onClick={onClose} color="green" maxWidth="340">{t('FREE_PASS.KEEP_BENEFITS')}</Button>
      </Link>
      <Button color="clear" maxWidth="340" style={{ color: colors.white }} onClick={onClose}>
        {t('FREE_PASS.CONTINUE_AS_FREE')}
      </Button>
    </Footer>
  </PassExpiredViewContainer>
);

PassExpiredView.propTypes = {
  t: PropTypes.func,
};
PassExpiredView.defaultProps = {
  t: () => {},
};

export default withTranslation([Namespaces.PRICINGS])(PassExpiredView);
