import { Creators, Types } from '../ducks/prices';
import { getDefaultPrices } from '../../api/prices';
// import { setDefaultPlans } from '../../values/prices/products';

// eslint-disable-next-line
export const fetchDefaultPrice = action$ => action$
  .ofType(Types.FETCH_DEFAULT_PRICES_REQUESTED)
  .mergeMap(() => getDefaultPrices()
    .then(res => res.data.data.skus)
    .then((skus) => {
      // const {
      //   premium_lifetime: lifetime,
      //   premium_weekly: weekly,
      //   premium_monthly: monthly,
      //   premium_semester: semesterly,
      //   premium_yearly: yearly,
      // } = skus;

      // setDefaultPlans({
      //   weekly, monthly, semesterly, yearly, lifetime,
      // });
      return Creators.fetchDefaultPricesSuccess(skus);
    })
    .catch(err => Creators.fetchDefaultPricesError(err)));
