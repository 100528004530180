import styled from 'styled-components';

const PaywallViewWrapper = styled.div.attrs({
  className: 'PaywallViewWrapper',
})`
  * {
    box-sizing: border-box;
  }
  display: block;
  width: 100vw;
  margin: 0 auto;
  background-color: #FFFFFF;
  padding: 0;
  min-height: 100vh;
  height: max-content;
`;

const PaywallViewContent = styled.div.attrs({
  className: 'PaywallViewContent',
})`
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width: 1340px;
`;

export default PaywallViewWrapper;
export { PaywallViewContent };
