/* eslint-disable implicit-arrow-linebreak */
import BaseApi, { localeFlag } from './BaseApi';
import { formatRequest } from '../lib/utils';

export const getFamilies = () => BaseApi.get('/families');
export const postFamily = () => BaseApi.post('/families/');
export const createFamily = (values) => BaseApi.post('/families', values);
export const putFamilies = ({ id, name, avatar }) =>
  BaseApi.put(`/families/${id}`, ...formatRequest({ name, avatar }, 'avatar'));
export const getFamilyMember = (familyId, memberId) => BaseApi.get(`/families/${familyId}/memberships/${memberId}?${localeFlag()}`);
export const getFamilyMembers = id => BaseApi.get(`/families/${id}/memberships`);
export const postFamilyMembers = ({
  familyId, email, relationship, role,
}) =>
  BaseApi.post(`/families/${familyId}/memberships`, { email, relationship, role });
export const putFamilyMembershipStatus = ({ familyId, status, membershipId }) =>
  BaseApi.put(`/families/${familyId}/memberships/${membershipId}`, { status });
export const postDefaultFamily = familyId => BaseApi.post(`/families/${familyId}/default`);
export const deleteFamily = familyId => BaseApi.delete(`/families/${familyId}/`);
export const deleteFamilyMember = (familyId, memberId) => BaseApi.delete(`/families/${familyId}/memberships/${memberId}`);
export const putMembershipPermission = (familyId, membershipId, role) =>
  BaseApi.put(`/families/${familyId}/memberships/${membershipId}`, {}, { params: { role } });
