import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators as bind } from 'redux';
import { compose, find, path } from 'ramda';
import { BabiesAndFamilies, Content, OnBoarding } from '../data/ducks';
import '../values/prices/products';
import Load from '../hoc/withLoadable';
import ProgressMaster from '../components/progress/ProgressMaster';
import { LANGS } from '../shared-values';
// import PricesContainer from '../containers/pricing/PricesContainer';
import PremiumProcessContainer from '../containers/pricing/PremiumProcessContainer';
import Paywall from 'components/views/Paywall'
// import InitialAssessmentsComponent from 'components/initialAssessments';

// const OnBoardingContainer = Load({ loader: () => import('../containers/OnBoardingContainer') });
const OnBoardingContainer = Load({ loader: () => import('../containers/OnBoarding') });
const AssesmentContainer = Load({ loader: () => import('../containers/assesmentsContainers/AssesmentContainer') });
// const IAHome = Load({ loader: () => import('../containers/assesmentsContainers/IAHome') });
const InitialAssessmentsComponent = Load({ loader: () => import('components/initialAssessments') });
const TodayView = Load({ loader: () => import('../components/views/TodayView') });
const DAPContainer = Load({ loader: () => import('../containers/DAPContainer') });
const WapView = Load({ loader: () => import('../components/views/WapView') });
const SettingsView = Load({ loader: () => import('../components/views/Settings/Settings') });

const MyClassroomView = Load({ loader: () => import('../components/views/MyClassroom/MyClassroom') });

const PastPlansContainer = Load({ loader: () => import('../containers/PastPlansContainer') });
const FamilyProfile = Load({ loader: () => import('../containers/babiesAndFamilies/FamilyProfile') });
const FamilyPicker = Load({ loader: () => import('../containers/FamilyPicker') });
const DefaultFamilyPicker = Load({ loader: () => import('../components/families/DefaultFamilyPicker') });
const AddMember = Load({ loader: () => import('../containers/menus/AddMember') });
// const CatalogHome = Load({ loader: () => import('../components/catalog/views/CatalogHomeView') });
const CatalogViewComponent = Load({ loader: () => import('../components/views/Catalog/') });
const CatalogSearchActivitiesViewComponent = Load({ loader: () => import('../components/views/Catalog/Search') });
const CatalogSearchClassesViewComponent = Load({ loader: () => import('../components/views/Catalog/SearchClasses') });
const BrowseBySkillView = Load({ loader: () => import('../components/views/Catalog/BrowseBySkill') });
const BrowseBySubjectView = Load({ loader: () => import('../components/views/Catalog/BrowseBySubject') });
const BrowseByAgeView = Load({ loader: () => import('../components/views/Catalog/BrowseByAge') });
const BrowseByAgeActivitiesView = Load({ loader: () => import('../components/views/Catalog/BrowseByAge/activities') });
const BrowseBySkillActivitiesView = Load({ loader: () => import('../components/views/Catalog/BrowseBySkill/activities') });
const BrowseBySubjectActivitiesView = Load({ loader: () => import('../components/views/Catalog/BrowseBySubject/activities') });
const FeaturedCollectionActivitiesView = Load({ loader: () => import('../components/views/Catalog/FeaturedCollection/activities') });
const BrowseByCenterView = Load({ loader: () => import('../components/views/Catalog/BrowseByCenter') });
const BrowseByCenterActivitiesView = Load({ loader: () => import('../components/views/Catalog/BrowseByCenter/activities') });
const BrowseByInterestView = Load({ loader: () => import('../components/views/Catalog/BrowseByInterest') });
const BrowseByInterestActivitiesView = Load({ loader: () => import('../components/views/Catalog/BrowseByInterest/activities') });
const ExploreOurArticlesView = Load({ loader: () => import('../components/views/Catalog/ExploreOurArticles') });
const ExploreOurArticleItemsView = Load({ loader: () => import('../components/views/Catalog/ExploreOurArticles/articles') });
const BrowseByExpertView = Load({ loader: () => import('../components/views/Catalog/BrowseByExpert') });
const BrowseByExpertClassesView = Load({ loader: () => import('../components/views/Catalog/BrowseByExpert/classes') });
const FavoritesView = Load({ loader: () => import('../components/views/Catalog/Favorites') });
const MasterClassesExpertView = Load({ loader: () => import('../components/views/Catalog/MasterClassesExpert') });

// const CatalogHome = Load({ loader: () => import('../components/catalog/CatalogHome') });
// const ExpertCollections = Load({ loader: () => import('../components/catalog/views/ExpertCollections')});
// const CatalogAllCollections = Load({ loader: () => import('../components/catalog/views/AllCollectionsView') });
// const CatalogSearchResults = Load({ loader: () => import('../components/catalog/views/SearchResultsView') });
// const ActivitiesView = Load({ loader: () => import('../components/catalog/views/ActivitiesView') });

const ActivityContainer = Load({ loader: () => import('../containers/ActivityContainer') });
const ArticleContainer = Load({ loader: () => import('../containers/ArticleView') });
const SlideshowContainer = Load({ loader: () => import('../containers/SlideshowContainer') });
const Error404 = Load({ loader: () => import('../components/views/Error404_') });

// const SandboxView = Load({ loader: () => import('../components/views/SandboxView') });
const MilestonesHome = Load({ loader: () => import('../components/milestones/MilestonesHome') });
const ClassroomPlanContainer = Load({ loader: () => import('../containers/ClassroomPlanContainer') });

const PricesView = Load({ loader: () => import('../components/views/Prices') });

export const APP_SECTIONS_PATHS = {
  ROOT: '/app',
  TAP: '/tap',
  IA: '/ia',
  FAMILIES: '/family',
  ACTIVITY: '/activity',
  ARTICLE: '/article',
  ARTICLE_DETAIL: '/article/:id',
  PREMIUM_PROCESS: '/premium_process',
  PROMO_PREMIUM_PROCESS_DYNAMIC: '/promo/premium_process_dynamic',
  PAYWALL: '/paywall',
  PRICES: '/prices',
  CLASSROOMS: '/classrooms',
  CLASSROOMS_WEEKLYPLAN: '/classrooms7weeklyplan',
  SETTINGS: '/settings',
  MILESTONES: '/milestones',
  SANDBOX: '/sandbox',
  PROGRESS: '/progress',
  CATALOG: '/catalog',
  CATALOG_SEARCH: '/catalog/search',
  CATALOG_BROWSE_BY_SKILL: '/catalog/browse-by-skill',
  CATALOG_BROWSE_BY_SKILL_ACTIVITIES: '/catalog/browse-by-skill/:skillId/activities',
  CATALOG_BROWSE_BY_SUBJECT: '/catalog/browse-by-subject',
  CATALOG_BROWSE_BY_SUBJECT_ACTIVITIES: '/catalog/browse-by-subject/:subjectId/activities',
  CATALOG_BROWSE_BY_AGE: '/catalog/browse-by-age',
  CATALOG_BROWSE_BY_AGE_ACTIVITIES: '/catalog/browse-by-age/:ageId/activities',
  CATALOG_FEATURED_COLLECTION_ACTIVITIES: '/catalog/featured-collection/:featureId/activities',
  CATALOG_CLASSES_SEARCH: '/catalog/classes/search',
  CATALOG_CLASSES_BROWSE_BY_CENTER: '/catalog/classes/browse-by-center',
  CATALOG_CLASSES_BROWSE_BY_CENTER_CLASSES: '/catalog/classes/browse-by-center/:classId/classes',
  CATALOG_CLASSES_BROWSE_BY_INTEREST: '/catalog/classes/browse-by-interest',
  CATALOG_CLASSES_BROWSE_BY_INTEREST_CLASSES: '/catalog/classes/browse-by-interest/:interestId/classes',
  CATALOG_EXPLORE_OUR_ARTICLES: '/catalog/explore-our-articles',
  CATALOG_EXPLORE_OUR_ARTICLES_ARTICLES: '/catalog/explore-our-articles/:articleCollectionId/articles',
  CATALOG_CLASSES_BROWSE_BY_EXPERT: '/catalog/browse-by-expert',
  CATALOG_CLASSES_BROWSE_BY_EXPERT_CLASSES: '/catalog/browse-by-expert/:expertId/classes',
  CATALOG_FAVORITES: '/catalog/favorites',
  CATALOG_MATER_CLASSES_EXPERT: '/catalog/master-classes/:masterClassId',
};

const babyRequiredRoutes = [
  { path: '/tap', component: TodayView },
  { path: '/dap/:planId/:day?', component: DAPContainer },
  { path: '/wap', component: WapView },
  { path: '/past-plans', component: PastPlansContainer },
  { path: '/add-member', component: AddMember },
  { path: `${APP_SECTIONS_PATHS.FAMILIES}/:id`, component: FamilyProfile, exact: true },
];

const routes = [
  { path: APP_SECTIONS_PATHS.SETTINGS, component: SettingsView },
  { path: APP_SECTIONS_PATHS.CLASSROOMS, component: MyClassroomView },
  { path: APP_SECTIONS_PATHS.PREMIUM_PROCESS, component: PremiumProcessContainer },
  { path: APP_SECTIONS_PATHS.PROMO_PREMIUM_PROCESS_DYNAMIC, component: PremiumProcessContainer },
  { path: `/:lng(${LANGS})?${APP_SECTIONS_PATHS.PRICES}`, component: PricesView },
  // { path: '/catalog', component: CatalogHome, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG, component: CatalogViewComponent, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_SEARCH, component: CatalogSearchActivitiesViewComponent, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_BROWSE_BY_SKILL, component: BrowseBySkillView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_BROWSE_BY_SKILL_ACTIVITIES, component: BrowseBySkillActivitiesView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_BROWSE_BY_SUBJECT_ACTIVITIES, component: BrowseBySubjectActivitiesView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_BROWSE_BY_SUBJECT, component: BrowseBySubjectView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_BROWSE_BY_AGE, component: BrowseByAgeView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_BROWSE_BY_AGE_ACTIVITIES, component: BrowseByAgeActivitiesView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_FEATURED_COLLECTION_ACTIVITIES, component: FeaturedCollectionActivitiesView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_CLASSES_SEARCH, component: CatalogSearchClassesViewComponent, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_CLASSES_BROWSE_BY_CENTER, component: BrowseByCenterView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_CLASSES_BROWSE_BY_CENTER_CLASSES, component: BrowseByCenterActivitiesView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_CLASSES_BROWSE_BY_INTEREST, component: BrowseByInterestView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_CLASSES_BROWSE_BY_INTEREST_CLASSES, component: BrowseByInterestActivitiesView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_EXPLORE_OUR_ARTICLES, component: ExploreOurArticlesView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_EXPLORE_OUR_ARTICLES_ARTICLES, component: ExploreOurArticleItemsView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_CLASSES_BROWSE_BY_EXPERT, component: BrowseByExpertView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_CLASSES_BROWSE_BY_EXPERT_CLASSES, component: BrowseByExpertClassesView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_FAVORITES, component: FavoritesView, exact: true },
  { path: APP_SECTIONS_PATHS.CATALOG_MATER_CLASSES_EXPERT, component: MasterClassesExpertView, exact: true },
  // { path: '/catalog/learn', component: CatalogHome, exact: true },
  // { path: '/catalog/expert-collections/:customCollectionId', component: ExpertCollections, exact: true },
  // { path: '/catalog/all-collections', component: CatalogAllCollections, exact: true },
  // { path: '/catalog/collections/:collectionId', component: ActivitiesView, exact: true },
  { path: APP_SECTIONS_PATHS.PROGRESS, component: ProgressMaster },
  { path: APP_SECTIONS_PATHS.MILESTONES, component: MilestonesHome },
  // { path: APP_SECTIONS_PATHS.SANDBOX, component: SandboxView },
  { path: APP_SECTIONS_PATHS.CLASSROOMS_WEEKLYPLAN, component: ClassroomPlanContainer },
];

const contentRoutes = [
  { path: '/activity/:id/', component: ActivityContainer },
  { path: '/slideshow/:id/', component: SlideshowContainer },
  { path: '/article/:id/', component: ArticleContainer },
];

const AuthGuardedRoutes = (prop) => {

  const contentGuard = (props, RouteComponent) => {
    const type = props.match.path.split('/')[1];
    const contentId = props.match.params.id;

    if (prop.contentIdIsAllowed(type, contentId)) {
      return <RouteComponent {...props} />;
    }
    return <Redirect to="/404" />;
  }

  const {
    activeBaby,
    activeIA,
    activeOB,
    isAssessmentActive,
    families,
    activeFamilyId,
    startOB2IA,
  } = prop;

  const noDefaultFamily = Boolean(find(family => path(['membership', 'default_account'], family), families));
  const onlyFamilyIsNotMine = families.length === 1 && !families[0].self_owner;

  return (
    <Switch>
      {
        routes.map(x => <Route { ...x } key={ x.path } />)
      }
      <Route
        path="/select-family"
        render={() =>
          <DefaultFamilyPicker startOB2IA={ startOB2IA } />
        }
      />

      {
        (!noDefaultFamily && families.length > 1) && <Redirect to="/select-family" />
      }
      {
        activeBaby && babyRequiredRoutes.map(r => <Route { ...r } key={ r.path } />)
      }
      {
        // RECIBE LOS PARAMETROS DE LA COMPRA DE STRIPE
        <Route path="/ia/:section" component={ InitialAssessmentsComponent } />
        // activeIA && <Route path="/ia/:section" component={ InitialAssessmentsComponent } />
        // activeIA && <Route path="/ia/:section" component={ IAHome } />
      }
      {
         <Route path="/ia" component={ InitialAssessmentsComponent } />
        //  activeIA && <Route path="/ia" component={ InitialAssessmentsComponent } />
        // activeIA && <Route path="/ia" component={ IAHome } />
      }
      {
        contentRoutes.map(r => (
          <Route
            path={ r.path }
            key={ r.path }
            render={props => contentGuard(props, r.component)}
          />
        ))
      }
      <Route
        path={APP_SECTIONS_PATHS.FAMILIES}
        render={() => (
          (families.length > 1 || onlyFamilyIsNotMine)
            ? <FamilyPicker startOB2IA={startOB2IA} />
            : <Redirect to={`${APP_SECTIONS_PATHS.FAMILIES}/${activeFamilyId}`} />
        )}
      />
      <Route path="/invites" render={() => (<div />)} />
      {
        (activeIA || isAssessmentActive) &&
          <Route path="/assessment/:areaId" component={ AssesmentContainer } />
      }
      {
        activeOB && <Route path="/ob" component={OnBoardingContainer} />
      }
      <Route path={ APP_SECTIONS_PATHS.PAYWALL } component={ Paywall } />
      {activeBaby && <Route path="/materials" render={() => <Redirect to={{ pathname: '/wap', search: '?section=play', state: { deeplink: 'materials' } }} />} />}
      {activeBaby && (
        <Route
          path="/feed"
          render={({ location }) => <Redirect to={{ pathname: '/classrooms', search: location.search }} />}
        />
      )}
      {activeBaby && (
        /* Catch any no registered path & redirect to /tap keeping query params */
        <Route
          path={`/:lng(${LANGS})?`}
          render={({ location }) => <Redirect to={`/tap${location.search}`} />}
        />
      )}
      <Route path="/404" component={Error404} />
      <Route render={() => <Redirect to="/404" />} />
    </Switch>
  );
}

const mapStateToProps = state => ({
  activeBaby: BabiesAndFamilies.Selectors.activeBaby(state),
  activeIA: state.IA.type,
  activeOB: state.OB.OBType,
  isAssessmentActive: state.progress.isAssessmentActive,
  families: state.families.families,
  activeFamilyId: state.families.activeFamilyId,
  contentIdIsAllowed: (type, id) => Content.Selectors.contentIdIsAllowed(state, type, Number(id)),
});

const mapDispatchToProps = dispatch => bind({
  startOB: OnBoarding.Creators.start,
  startOB2IA: OnBoarding.Creators.startOb2ia,
}, dispatch);

AuthGuardedRoutes.propTypes = {
  contentIdIsAllowed: PropTypes.func,
  activeBaby: PropTypes.object,
  activeIA: PropTypes.string,
  activeOB: PropTypes.string,
  families: PropTypes.array,
  activeFamilyId: PropTypes.number,
  isAssessmentActive: PropTypes.bool,
  startOB2IA: PropTypes.func,
};
AuthGuardedRoutes.defaultProps = {
  contentIdIsAllowed: () => {},
  activeBaby: {},
  activeIA: '',
  activeOB: '',
  families: [],
  activeFamilyId: 0,
  isAssessmentActive: false,
  startOB2IA: () => {},
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AuthGuardedRoutes);