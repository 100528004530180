import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import Formsy from 'formsy-react-2';
import PropTypes from 'prop-types';
import { toPairs } from 'ramda';
import Moment from 'moment';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import Avatar from '../../shared/AvatarDropzone';
import SegmentedBoxes from '../../shared/SegmentedBoxes';
import TextInput from '../../shared/TextInput';
import Select from '../../shared/NewSelect';
import Button from '../../shared/buttons/MainButton';

import { Neo } from '../../themes/TextInput';
import { Neo as NeoBoxes } from '../../themes/SegmentedBoxes';
import { Neo as NeoAvatar } from '../../themes/AvatarDropzone';
import { Neo as NeoSelect } from '../../themes/NewSelect';
import Label from '../../shared/Label';

import ConfirmModal from '../../modals/ConfirmModal';
import {
  MonthsList, YearList, DayList, WeeksList,
} from '../../../shared-values';
import AlertMessage from '../../shared/AlertMessageC';
import Icon from '../../shared/Icon';
import { colors } from '../../../styles';
import { noFutureDatesValidation, genderNameByValue, genderAdposition } from '../../../lib/utils';
import { VALIDATIONS_MESSAGES } from '../../../values/validations';
import { BabyAvatar } from '../../../values/families';

const EditBabyViewContainer = styled.div`
  padding: 30px;
`;

const Form = styled(Formsy.Form)`
  width: 300px;
  margin: 10px auto;
  box-sizing: border-box;

  & > div {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

const InlineInputs = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: auto;
  }
`;

const BareValue = styled.span`
  display: block;
  margin: auto;
  margin-bottom: 30px;

  font-size: 16px;
  font-weight: 500;
  color: ${colors.kinedu};
  letter-spacing: -0.45px;
  text-align: center;
`;

class EditBabyView extends Component {
  state = {
    submitEnabled: false,
    babyAgeHasChanged: false,
    isPremature: false,
    birthdayYear: 0,
    birthdayMonth: 0,
    birthdayDay: 0,
  };

  componentDidMount = () => {
    const { activeBaby, t } = this.props;
    const {
      gender, name, avatar, weeks_before_birth: weeks, birthday, age_in_months,
    } = activeBaby;
    const birthdayParts = birthday ? birthday.split('-') : [];
    const [year = 1990, month = 1, day = 1] = birthdayParts.map(b => Number(b));
    const months = toPairs(MonthsList).map(([value, label]) => ({ label: t(`common:${label}`), value }));
    this.setState({
      yearList: YearList,
      dayList: DayList,
      weeksList: WeeksList,
      name,
      gender,
      birthdayDay: day,
      birthdayMonth: month,
      birthdayYear: year,
      fullBirthday: birthday,
      avatar,
      weeks,
      months,
      age_in_months,
    });
  }

  setBirthdayYear = e => this.setState({ birthdayYear: e.value });

  setBirthdayMonth = (e) => {
    this.setState({ birthdayMonth: e.value });
  }

  setBirthdayDay = e => this.setState({ birthdayDay: e.value });

  toggleSubmit = val => this.setState({ ...this.state, submitEnabled: val });

  babyAgeChange = () => {
    this.setState({ babyAgeHasChanged: true });
  };

  showConfirmModal = () => {
    const { activeBaby, t } = this.props;
    return (
      <ConfirmModal
        width="610"
        title={t('DELETE_BABY.QST_DELETE_BABYS_PROFILE', {
          babyName: activeBaby.name,
          adpos: genderAdposition(activeBaby.gender),
        })}
        body={`
          ${t('DELETE_BABY.BY_COMPLETING_THIS_ACTION', {
          babyName: activeBaby.name,
          adpos: genderAdposition(activeBaby.gender),
        })}
          ${t('ACTION_CANT_BE_UNDONE')}`}
        acceptText={t('common:THATS_FINE')}
        cancelText={t('common:NEVERMIND')}
        onAccept={() => {
          this.props.deleteBaby();
          this.setState({ showSavePopup: false });
        }}
        onCancel={() => {
          this.setState({ showSavePopup: false });
        }}
      />
    );
  }

  render() {
    const { t, saveChanges, myRole, showDelete } = this.props;
    const {
      gender,
      name,
      avatar = {},
      weeks,
      birthdayDay,
      birthdayMonth,
      birthdayYear,
      dayList,
      months,
      yearList,
    } = this.state;
    const currentYear = (new Date()).getFullYear();
    const { givenMonth, givenDay } = noFutureDatesValidation(
      birthdayDay,
      birthdayMonth,
      birthdayYear,
    );

    return (
      <EditBabyViewContainer>
        <Form
          onSubmit={(values) => {
            saveChanges(values, this.state.babyAgeHasChanged);
          }}
          onValid={() => this.toggleSubmit(true)}
          onInvalid={() => this.toggleSubmit(false)}
        >
          <Avatar
            name="avatar"
            label={t('MY_BABYS_PICTURE')}
            theme={NeoAvatar}
            defaultImage={avatar.size1 === 'missing' ? BabyAvatar[gender] : avatar.size1}
            circle
          />
          {myRole === 'ADMIN' ? (
            <SegmentedBoxes
              name="gender"
              label={t('GENDER')}
              theme={NeoBoxes}
              value={gender}
              boxes={[{ label: t('common:BOY'), value: 'male' }, { label: t('common:GIRL'), value: 'female' }]}
              radio
              required
            />
          )
            : (
              <Fragment>
                <Label type="neo">{t('GENDER')}</Label>
                <BareValue>{t(`common:${genderNameByValue(gender)}`)}</BareValue>
              </Fragment>
            )
          }
          <TextInput
            name="name"
            label={t('BABYS_NAME')}
            theme={Neo}
            value={name}
            validations={{ minLength: 1 }}
            required
          />
          <div>
            <Label type="neo">{t('BIRTHDAY')}</Label>
            {myRole === 'ADMIN'
              ? (
                <InlineInputs>
                  <Select
                    name="month"
                    theme={NeoSelect}
                    containerStyle={css`flex-grow: 0.7;`}
                    options={months}
                    value={birthdayMonth}
                    required
                    onChange={(e) => {
                      this.setBirthdayMonth(e);
                      this.babyAgeChange();
                    }}
                    showErrorMessage
                    validations={{ validRange: [0, givenMonth] }}
                    validationErrors={{
                      validRange: t(`validations:${VALIDATIONS_MESSAGES.NO_FUTURE_DATES}`),
                    }}
                  />
                  <Select
                    name="day"
                    theme={NeoSelect}
                    containerStyle={css`flex-grow: 0.05;`}
                    options={dayList}
                    value={birthdayDay}
                    required
                    onChange={e => this.setBirthdayDay(e)}
                    showErrorMessage
                    validations={{ isNumeric: true, validRange: [1, givenDay] }}
                    validationErrors={{
                      // isNumeric: t(`validations:${VALIDATIONS_MESSAGES.IS_NUMERIC}`),
                      // validRange: t(`validations:${VALIDATIONS_MESSAGES.VALID_DATE}`),
                    }}
                  />
                  <Select
                    name="year"
                    theme={NeoSelect}
                    containerStyle={css`flex-grow: 0.15;`}
                    options={yearList}
                    value={birthdayYear}
                    required
                    onChange={(e) => { this.setBirthdayYear(e); this.babyAgeChange(); }}
                    validations={{ isNumeric: true, validRange: [1900, currentYear] }}
                    validationErrors={{
                      isNumeric: t(`validations:${VALIDATIONS_MESSAGES.IS_NUMERIC}`),
                      validRange: t(`validations:${VALIDATIONS_MESSAGES.VALID_DATE}`),
                    }}
                  />
                </InlineInputs>
              )
              : <BareValue>{Moment(this.state.fullBirthday).format('DD MMMM YYYY')}</BareValue>
            }
          </div>
          {myRole === 'ADMIN'
            ? (
              <Select
                name="weeks"
                label={t('WEEKS_OF_GESTATION')}
                theme={NeoSelect}
                styles={css`width: 70px;`}
                options={this.state.weeksList}
                onChange={() => this.babyAgeChange()}
                value={weeks}
                openOnTop
                required
              />
            )
            : (
              <Fragment>
                <Label type="neo">{t('WEEKS_OF_GESTATION')}</Label>
                <BareValue>{weeks}</BareValue>
              </Fragment>
            )
          }
          {this.state.babyAgeHasChanged
            && (
            <AlertMessage icon={<Icon icon="warning" size={24} color={colors.kinedu} />}>
              {t('EDIT_BABY_AGE_WARNING')}
            </AlertMessage>
            )
          }
          <div style={{ marginBottom: '0px' }}>
            <Button
              type="submit"
              color={this.state.submitEnabled ? 'green' : 'disabled'}
              disabled={!this.state.submitEnabled}
              raised
            >
              {t('common:SAVE_CHANGES')}
            </Button>
            {(myRole === 'ADMIN' && showDelete)
              && (
              <Button
                type="button"
                color="danger"
                onClick={() => this.setState({ showSavePopup: true })}
              >
                {t('DELETE_PROFILE')}
              </Button>
              )
            }
          </div>
          {this.state.showSavePopup && this.showConfirmModal()}
        </Form>
      </EditBabyViewContainer>
    );
  }
}

EditBabyView.propTypes = {
  activeBaby: PropTypes.object,
  deleteBaby: PropTypes.func,
  saveChanges: PropTypes.func,
  myRole: PropTypes.string,
  t: PropTypes.func,
  showDelete: PropTypes.bool,
};
EditBabyView.defaultProps = {
  activeBaby: {},
  deleteBaby: () => { },
  saveChanges: () => { },
  myRole: '',
  t: () => { },
  showDelete: false,
};

export default withTranslation([Namespaces.MENU, Namespaces.COMMON])(EditBabyView);
