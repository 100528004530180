import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import EmptySchoolImg from '../../images/invites/EmptySchoolImg.svg';
import { Creators } from '../../data/ducks/invites';
import { Creators as BabyCreators } from '../../data/ducks/babiesAndFamilies';
import EventReporter, { Events } from '../../lib/EventReporter';
import { Families } from '../../data/ducks';


const ModalContent = styled.div`
  width: 100%;
  text-align: center;

  .spinner {
    margin-top: 10px;
    margin-bottom: 28px;
  }
`;

const ClassroomLogo = styled.div`
  width: 255px;
  height: 130px;
  margin: 73px auto 31px auto;
  background: url(${({ src }) => src}) no-repeat center;
  background-size: contain;
`;

const ConfirmText = styled.div`
  margin: 0 auto 15px auto;
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  text-align: left;
  color: #4A4A4A;
  width: 270px;
`;

const ConfirmItems = styled.div`
  margin: 0 auto 25px auto;
  font-size: 16px;
  font-weight: normal;
  line-height: 21px;
  text-align: left;
  color: #4A4A4A;
  width: 270px;
`;

const ConfirmClassroomModal = ({
  t,
  classroomData: data,
  renderButtons,
  handleContinue,
  babyResponse,
}) => {
  const activeBaby = useSelector(state => Families.Selectors.activeBaby(state)) || { name: undefined };
  const tempBaby = JSON.parse(localStorage.getItem('temporaryClassroomsBaby'));
  const baby = babyResponse || tempBaby || activeBaby;
  const dispatch = useDispatch();
  const invitesState = useSelector(state => state.invites);
  const {
    handleSubmit,
  } = useForm();
  const classroomData = invitesState.invitedClassroomData || data;

  const joinClassroom = (bbyId) => {
    dispatch(Creators.joinClassroomLoading(true));
    dispatch(Creators.joinClassroomRequested(
      bbyId,
      invitesState.invitedClassroomData.code,
      () => handleContinue(),
      () => toast.error(t('validations:RESPONSE_ERRORS.GENERAL_ERROR')),
    ));
  };

  const onSubmit = () => {
    if (localStorage.getItem('CREATE_BABY_AFTER_JOIN')) {
      const classroomBaby = JSON.parse(localStorage.getItem('temporaryClassroomsBaby'));
      dispatch(BabyCreators.addBabyRequested({
        ...classroomBaby,
        postAction: (bbyId) => {
          joinClassroom(bbyId);
          localStorage.removeItem('temporaryClassroomsBaby');
        },
      }));
    } else {
      joinClassroom(baby.id);
    }
    EventReporter.action(Events.CODE_CONFIRMATION_CLICKED());
  };

  useEffect(() => {
    dispatch(Creators.joinClassroomLoading(false));
    EventReporter.view(Events.CODE_CONFIRMATION());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ClassroomLogo src={(classroomData && classroomData.logo) || EmptySchoolImg} />
        <ConfirmText>
          {`${t('CONFIRM_CLASSROOM.TITLE')}:`}
        </ConfirmText>
        <ConfirmItems>
          <div>
            {`${t('CONFIRM_CLASSROOM.NAME')}:`}
            {' '}
            {baby && baby.name}
          </div>
          <div>
            {`${t('CONFIRM_CLASSROOM.CENTER')}:`}
            {' '}
            {classroomData.center && classroomData.center.name}
          </div>
          <div>
            {`${t('CONFIRM_CLASSROOM.CLASSROOM')}:`}
            {' '}
            {classroomData.group && classroomData.group.name}
          </div>
        </ConfirmItems>
        {renderButtons(invitesState.joinClassroomLoading)}
      </form>
    </ModalContent>
  );
};

export default withTranslation(['classroom_invite'])(ConfirmClassroomModal);
