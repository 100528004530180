export const devToolsInitialIsOpen = false;
// default options for QueryClient
export const defaultOptions = {
  queries: {
    staleTime: Infinity,
    enabled: false,
    retry: false,
    retryDelay: 30000,
    refetchOnWindowFocus: true,
  },
};
