import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import styled from 'styled-components';
import { Namespaces } from '../../values/i18n';
import BabyMaintenance from '../../images/baby-maintenance.svg';

const StyleContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color:#E5E5E5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyleContainerPhone = styled.div`
  display: flex;
  margin-bottom: 1rem;

  & img {
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

const StyleTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 700;
  width: 200px;
  text-align: center;
`;

const StyleBody = styled.p`
  font-size: .95rem;
  font-weight: 400;
  width: 250px;
  text-align: center;
  margin-top: 0;
`;

const Maintenance = ({ t, showMaintenance }) => {

  if (!showMaintenance) {
    return null;
  }
  return (
    <StyleContainer>
      <StyleContainerPhone>
        <img src={ BabyMaintenance } alt="img-baby" />          
      </StyleContainerPhone>
      <StyleTitle>
        { t('maintenance:TITLE') }
      </StyleTitle>
      <StyleBody>
      { t('maintenance:BODY') }
      </StyleBody>
    </StyleContainer>
  )
}

export default compose(
  withTranslation([Namespaces.MAINTENANCE]),
)(Maintenance);