import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { colors } from '../../styles';

export const Flat = {
  container: css`
  `,
  openOnTopStyle: css`
  .Select-menu-outer {
    position: absolute !important;
    top: auto !important;
    bottom: calc(100% - 1px) !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    z-index: 999999;
  }

  .is-open .Select-control {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  `,
  control: css`
    height: 45px;
    background-color: #EEE;
    border-color: #C8C8C8;
  `,
  input: css``,
  label: css`
    display: block;

    color: ${colors.darkGray};
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 3.6px;
    text-transform: uppercase;
    text-align: center;

    margin-bottom: 10px;
  `,
  menu: css`
    text-align: center;
  `,
  option: css`
    color: ${colors.kinedu};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.45;
  `,
  value: css`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    text-align: center;

    .Select-value-label {
      color: ${colors.kinedu};
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.45;
    }
  `,
  arrow: css`
    border-color: ${colors.kinedu} transparent transparent;
  `,
  clearZone: css`
    display: none;
`,
};

export const Underlined = {
  container: css`
    margin: 0 10px 20px;

    .is-open > .Select-control,
    .is-focused > .Select-control,
    .is-focused:not(.is-open) > .Select-control {
      background-color: transparent !important;
      border-color: none !important;
      outline: none !important;
    }
  `,
  control: css`
    display: flex;
    align-items: flex-end;
    height: 45px;

    background-color: transparent;
    border: none;
    border-bottom: 2px solid;
    border-color: white;
    border-radius: 0;
  `,
  input: css`
    height: auto;
    padding: 0;

    input {
      color: ${colors.white};
      font-size: 36px;
      font-weight: bold;
      letter-spacing: normal;
      background-color: transparent;
      padding: 8px 0 0 0;
    }
  `,
  menu: css`
    background-color: transparent;
    border: none;
    height: 45px;
    overflow: hidden;

    .Select-noresults {
      box-sizing: border-box;
      color: rgba(255,255,255,0.7);
      font-size: 18px;
      background-color: rgba(255,255,255,0.2);
      cursor: default;
      display: block;
      padding: 8px 10px;
      text-align: center;
    }
  `,
  option: css`
    color: rgba(255,255,255,0.7);
    background-color: transparent;
    text-align: left;
    font-size: 24px;
    font-weight: 500;

    &:hover {
      color: #FFF;
      background-color: rgba(255,255,255,0.2);
    }
  `,
  value: css`
    text-align: left;
    text-overflow: initial;
    top: initial;
    padding: 0;

    .Select-value-label {
      color: #FFF !important;
      font-size: 36px;
      font-weight: 600;
      line-height: initial;
    }
  `,
  label: css`
    color: ${colors.white};
    text-transform: uppercase;
    text-align: left;

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;

    display: block;
    margin-bottom: 10px;
  `,
  placeholder: css`
    display: flex;
    align-items: flex-end;
    padding: 0 0 4px 0;

    color: rgba(255,255,255,0.5);
    text-align: left;
    font-size: 36px;
    font-weight: bold;
  `,
  arrow: css`
    display: none;
    border-color: transparent transparent transparent;
  `,
  clearZone: css`
    display: none;
`,
  inputError: css`
  .Select-value {
    background-color: rgba(255,255,255,0.3);
  }
`,
  error: css`
    position: absolute;
    bottom: -35px;
    display: block;
    color: rgba(0,0,0,0.4);
    font-weight: 500;
    font-size: 16px;
  `,
};

export const Ghost = {
  container: css`
    margin: 0;

    .is-open > .Select-control {
      background-color: transparent;
      border-color: ${colors.white} !important;
      outline: none !important;
      box-shadow: none !important;
    }

    .is-focused:not(.is-open) > .Select-control {
      border-color: ${colors.white} !important;
      outline: none !important;
      box-shadow: none !important;
    }

    .is-focused.is-pseudo-focused.Select--single > .Select-control {
      border-radius: 4px;
    }
  `,
  control: css`
    background-color: transparent;
    border: none;
    border-radius: 0;
  `,
  controlFocused: css`
    background-color: rgba(255,255,255,0.3) !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 1px solid #FFF;
  `,
  input: css`
    height: auto;
    padding: 0;

    input {
      color: ${colors.white} !important;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: normal;
      background-color: transparent;
      padding: 3px 0 0 0;
    }
  `,
  menu: css`
    background-color: transparent;
    border: none;

    .Select-noresults {
      box-sizing: border-box;
      color: rgba(255,255,255,0.7) !important;
      font-size: 20px;
      background-color: rgba(255,255,255,0.2);
      cursor: default;
      display: block;
      padding: 8px 10px;
      text-align: center;
    }
  `,
  option: css`
    color: #444;
    background-color: rgba(255,255,255,0.8);
    text-align: left;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      background-color: rgba(255,255,255,1);
    }
  `,
  value: css`
    display: flex;
    align-items: center;
    text-align: left;
    top: 0;
    padding: 0;

    .Select-value-label {
      color: #FFF !important;
      font-size: 24px;
      font-weight: bold;
      line-height: initial;
    }
  `,
  valueFocused: css`
  text-align: left;
  top: 0;

  .Select-value-label {
    color: #FFF !important;
    font-size: 24px;
    font-weight: bold;
    line-height: initial;
  }
`,
  label: css`
    color: ${colors.white};
    text-transform: uppercase;
    text-align: left;

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;

    display: block;
    margin-bottom: 0;
  `,
  arrow: css`
    display: none;
    border-color: transparent transparent transparent;
  `,
  clearZone: css`
    display: none;
  `,
  inputError: css`
  .Select-value {
    background-color: rgba(255,255,255,0.3);
  }
`,
  error: css`
    position: absolute;
    bottom: -45px;
    display: none;
    margin-top: 8px;
    color: rgba(0,0,0,0.4);
    font-weight: 500;
    font-size: 16px;
  `,
};

export const Neo = {
  container: css`
    width: 100%;
    .Select {
      margin: auto;
    }
  `,
  openOnTopStyle: css`
  .Select-menu-outer {
    position: absolute !important;
    top: auto !important;
    bottom: calc(100% - 1px) !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    z-index: 999999;
  }

  .is-open .Select-control {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  `,
  control: css`
    height: 45px;
    background-color: ${colors.shadeLighter};
    border-color: ${colors.shadeMedium};
  `,
  controlFocused: css`
    background-color: ${colors.shadeLighter};
    box-shadow: 0 0 8px ${colors.shadeMedium} !important;
    border-color: ${colors.shadeMedium} !important;
  `,
  valueFocused: css`
    /* background: red; */
  `,
  input: css``,
  label: css`
    color: ${colors.shadeMediumDark};
    text-transform: uppercase;
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4.57px;

    display: block;
    margin-bottom: 10px;
  `,
  menu: css`
    text-align: center;
  `,
  option: css`
    color: ${colors.shadeMediumDark};
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.45;
    background-color: ${colors.shadeLighter};
    text-align: left;

    &:hover {
      color: ${colors.kinedu};
      background-color: #F9F9F9;
    }
  `,
  value: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 100%;
    text-align: left;
    background-color: ${colors.shadeLighter};

    .Select-value-label {
      color: ${colors.kinedu} !important;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.45;
      text-align: left;
    }
  `,
  inputError: css`
    border: 1px solid ${colors.errorNeo} !important;
    &:focus {
      border: 1px solid ${colors.errorNeo} !important;
    }
  `,
  error: css`
    position: absolute;
    white-space: nowrap;
    margin-top: 5px;
    font-size: 15px;
    color: ${colors.errorNeo};
  `,
  arrow: css`
    .Select-arrow {
      border-color: ${colors.shadeMedium} transparent transparent;
    }

    &:hover {
      .Select-arrow {
        border-color: ${colors.shadeMedium} transparent transparent;
      }
    }
  `,
  clearZone: css`
    display: none;
`,
};

export const Rounded = {
  container: css`
    width: 100%;

    .Select {
      margin: auto;

        .Select-menu-outer {
          border-bottom-left-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
          border-top-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
          border-color: transparent !important;
          border-top-color: ${colors.shadeMediumLight} !important;
          overflow: hidden;
          box-shadow: 0px 10px 20px -10px ${colors.blackAlpha};
          z-index: 999999;
      }
    }

    .is-open > .Select-control {
      background-color: transparent;
      border-color: ${colors.white} !important;
      outline: none !important;
      box-shadow: none !important;
    }

    .is-focused:not(.is-open) > .Select-control {
      border-color: ${colors.white} !important;
      outline: none !important;
      box-shadow: none !important;
    }

  `,
  openOnTopStyle: css`
  .Select-menu-outer {
    position: absolute !important;
    top: auto !important;
    bottom: calc(100% - 1px) !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    z-index: 999999;
  }

  .is-open .Select-control {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  `,
  control: css`
    height: 33px;
    background-color: ${colors.shadeLighter};
    border-color: transparent;
    border-radius: 20px;
  `,
  controlFocused: css`
    background-color: ${colors.shadeLighter};
    /* box-shadow: 0 0 8px ${colors.shadeMedium} !important; */
    border-color: transparent !important;
  `,
  valueFocused: css`
    /* background: red; */
  `,
  input: css`
    color: ${colors.shadeDark} !important;
    padding: 0px 20px;
  `,
  label: css`
    color: ${colors.shadeMediumDark};
    text-transform: uppercase;
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4.57px;

    display: block;
    margin-bottom: 10px;
  `,
  menu: css`
    text-align: left;
  `,
  option: css`
    color: ${colors.shadeDark};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.4px;
    border-color: transparent;
    background-color: ${colors.white};
    text-align: left;

    padding: 10px 20px;

    &:hover {
      color: ${colors.kinedu};
      font-weight: 500;
      background-color: ${colors.shadeLight};
    }
  `,
  value: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 100%;
    text-align: left;
    background-color: ${colors.shadeLighter};

    padding: 0 20px;

    .Select-value-label {
      color: ${colors.shadeMediumDark} !important;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.07px;
      text-align: left;
      line-height: 28px;
    }

    ${({ className }) => className?.includes('select-months-wb') && `
      .Select-value-label {
        font-family: Gotham Rounded;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #2C343A !important;
      }
    `}

  `,
  inputError: css`
    border: 1px solid ${colors.errorNeo} !important;
    &:focus {
      border: 1px solid ${colors.errorNeo} !important;
    }
  `,
  error: css`
    position: absolute;
    white-space: nowrap;
    margin-top: 5px;
    font-size: 15px;
    color: ${colors.errorNeo};
  `,
  arrow: css`
    padding-right: 15px;

    .Select-arrow {
      border-color: ${colors.shadeMediumDark} transparent transparent;
    }

    &:hover {
      .Select-arrow {
        border-color: ${colors.shadeMediumDark} transparent transparent;
      }
    }
  `,
  clearZone: css`
    display: none;
`,
};

export const CalendarViewSelecter = {
  container: css`
    width: 100px;

    .Select {
      margin: auto;

        .Select-menu-outer {
          border-bottom-left-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
          border-top-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
          border-color: transparent !important;
          border-top-color: ${colors.shadeMediumLight} !important;
          overflow: hidden;
          box-shadow: 0px 10px 20px -10px ${colors.blackAlpha};
          z-index: 999999;
      }
    }

    .is-open > .Select-control {
      background-color: transparent;
      border-color: ${colors.white} !important;
      outline: none !important;
      box-shadow: none !important;
    }

    .is-focused:not(.is-open) > .Select-control {
      border-color: ${colors.white} !important;
      outline: none !important;
      box-shadow: none !important;
    }

  `,
  openOnTopStyle: css`
  .Select-menu-outer {
    position: absolute !important;
    top: auto !important;
    bottom: calc(100% - 1px) !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    z-index: 999999;
  }

  .is-open .Select-control {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  `,
  control: css`
    height: 33px;
    background-color: ${colors.shadeLighter};
    border-color: transparent;
    border-radius: 20px;
  `,
  controlFocused: css`
    background-color: ${colors.shadeLighter};
    /* box-shadow: 0 0 8px ${colors.shadeMedium} !important; */
    border-color: transparent !important;
  `,
  valueFocused: css`
    /* background: red; */
  `,
  input: css`
    color: ${colors.shadeDark} !important;
    padding: 0px 20px;
  `,
  label: css`
    color: ${colors.shadeMediumDark};
    text-transform: uppercase;
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4.57px;

    display: block;
    margin-bottom: 10px;
  `,
  menu: css`
    text-align: left;
  `,
  option: css`
    color: ${colors.shadeDark};
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.4px;
    border-color: transparent;
    background-color: ${colors.white};
    text-align: left;

    padding: 10px 20px;

    &:hover {
      color: ${colors.kinedu};
      font-weight: 500;
      background-color: ${colors.shadeLight};
    }
  `,
  value: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 100%;
    text-align: left;
    background-color: ${colors.white};

    padding: 0 20px;

    .Select-value-label {
      color: #404040 !important;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.07px;
      text-align: left;
      line-height: 26px;
    }
  `,
  inputError: css`
    border: 1px solid ${colors.errorNeo} !important;
    &:focus {
      border: 1px solid ${colors.errorNeo} !important;
    }
  `,
  error: css`
    position: absolute;
    white-space: nowrap;
    margin-top: 5px;
    font-size: 15px;
    color: ${colors.errorNeo};
  `,
  arrow: css`
    padding-right: 15px;

    .Select-arrow {
      border-color: ${colors.shadeMediumDark} transparent transparent;
    }

    &:hover {
      .Select-arrow {
        border-color: ${colors.shadeMediumDark} transparent transparent;
      }
    }
  `,
  clearZone: css`
    display: none;
`,
};

export const BlueUnderlined = {
  container: css`
    width: 100%;
    .Select {
      margin: auto;

      .Select-menu-outer {
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-color: transparent !important;
        border-top-color: ${colors.kinedu} !important;
        overflow: hidden;
        box-shadow: 0px 10px 20px -10px ${colors.blackAlpha};
        z-index: 999999;
      }
    }

    .is-open > .Select-control {
      background-color: transparent;
      /* border-color: ${colors.white} !important; */
      outline: none !important;
      box-shadow: none !important;
    }

    .is-focused:not(.is-open) > .Select-control {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
      border-bottom: 1px solid ${colors.kinedu} !important;
      border-color: ${colors.kinedu} !important;
    }
  `,
  openOnTopStyle: css`
  .Select-menu-outer {
    position: absolute !important;
    top: auto !important;
    bottom: calc(100% - 1px) !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    z-index: 999999;
  }

  .is-open .Select-control {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  `,
  control: css`
    height: 45px;
    background-color: ${colors.white};
    border-color: transparent;
    border-bottom: 1px solid ${colors.kinedu};
    border-radius: 0;
  `,
  controlFocused: css`
    background-color: transparent;
    /* box-shadow: 0 0 8px ${colors.shadeMedium} !important; */
    border: none !important;
    border-bottom: 1px solid ${colors.kinedu} !important;
    border-color: ${colors.kinedu} !important;
  `,
  valueFocused: css`
    /* background: red; */
  `,
  input: css``,
  label: css`
    color: ${colors.shadeMediumDark};
    text-transform: uppercase;
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2.67px;

    display: block;
    margin-bottom: 10px;
  `,
  menu: css`
    text-align: center;
  `,
  option: css`
    color: ${colors.shadeDark};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.4px;
    background-color: ${colors.shadeLighter};
    text-align: left;

    &:hover {
      color: ${colors.kinedu};
      font-weight: 500;
      background-color: #F9F9F9;
    }
  `,
  value: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 100%;
    text-align: left;
    background-color: ${colors.white};

    padding: 0;

    .Select-value-label {
      color: ${colors.kinedu} !important;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: -0.45px;
      text-align: left;
      text-transform: uppercase;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `,
  inputError: css`
    border: 1px solid ${colors.errorNeo} !important;
    &:focus {
      border: 1px solid ${colors.errorNeo} !important;
    }
  `,
  error: css`
    position: absolute;
    white-space: nowrap;
    margin-top: 5px;
    font-size: 15px;
    color: ${colors.errorNeo};
  `,
  arrow: css`
    .Select-arrow {
      border-color: ${colors.shadeMediumDark} transparent transparent;
    }

    &:hover {
      .Select-arrow {
        border-color: ${colors.shadeMediumDark} transparent transparent;
      }
    }
  `,
  clearZone: css`
    display: none;
`,
};

export const RoundedAlt = {
  container: css`
    width: 100%;

    .Select {
      margin: auto;

        .Select-menu-outer {
          border-bottom-left-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
          border-top-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
          border-color: transparent !important;
          border-top-color: ${colors.shadeMediumLight} !important;
          overflow: hidden;
          box-shadow: 0px 10px 20px -10px ${colors.blackAlpha};
          z-index: 999999;
      }
    }

    .is-open > .Select-control {
      background-color: transparent;
      border-color: ${colors.white} !important;
      outline: none !important;
      box-shadow: none !important;
      border-bottom-right-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    .is-focused:not(.is-open) > .Select-control {
      border-color: ${colors.white} !important;
      outline: none !important;
      box-shadow: none !important;
    }
  `,
  openOnTopStyle: css`
  .Select-menu-outer {
    position: absolute !important;
    top: auto !important;
    bottom: calc(100% - 1px) !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    z-index: 999999;
  }

  .is-open .Select-control {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  `,
  control: css`
    height: 40px;
    background-color: ${colors.white};
    border-color: transparent;
    border-radius: 20px;
  `,
  controlFocused: css`
    background-color: ${colors.white};
    /* box-shadow: 0 0 8px ${colors.shadeMedium} !important; */
    border-color: transparent !important;
  `,
  valueFocused: css`
    /* background: red; */
  `,
  input: css`
    color: ${colors.shadeDark} !important;
    padding: 0px 20px;
  `,
  label: css`
    color: ${colors.shadeMediumDark};
    text-transform: uppercase;
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4.57px;

    display: block;
    margin-bottom: 10px;
  `,
  menu: css`
    text-align: left;
  `,
  option: css`
    color: ${colors.shadeMedium};
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.4px;
    border-color: transparent;
    border-bottom: 1px solid ${colors.shadeLight};
    background-color: ${colors.white};
    text-align: left;

    padding: 10px 20px;

    &:hover {
      color: ${colors.kinedu};
    }
  `,
  value: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 100%;
    text-align: left;
    background-color: ${colors.white};

    padding: 0 30px;

    .Select-value-label {
      color: ${colors.kinedu} !important;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.07px;
      text-align: left;
      line-height: 28px;
    }
  `,
  inputError: css`
    border: 1px solid ${colors.errorNeo} !important;
    &:focus {
      border: 1px solid ${colors.errorNeo} !important;
    }
  `,
  error: css`
    position: absolute;
    white-space: nowrap;
    margin-top: 5px;
    font-size: 15px;
    color: ${colors.errorNeo};
  `,
  arrow: css`
    padding-right: 15px;

    .Select-arrow {
      border-color: ${colors.shadeMediumDark} transparent transparent;
    }

    &:hover {
      .Select-arrow {
        border-color: ${colors.shadeMediumDark} transparent transparent;
      }
    }
  `,
  clearZone: css`
    display: none;
`,
};

export const ThemeShape = PropTypes.shape({
  container: PropTypes.array,
});
