import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import Formsy from 'formsy-react-2';

import { Flat, ThemeShape } from './../themes/TextArea';

const Container = styled.div`
  ${theme => theme.container}
`;

const Label = styled.label`${({ theme }) => theme.label}`;

/* Lo de textarea tiene que estar con la sintaxis separada y no la junta, si no no funciona. */
const Input = styled.textarea`
  ${({ theme }) => theme.input}
  ${({ theme, valid }) => !valid && theme.inputError}

  &::-webkit-input-placeholder { ${({ theme }) => theme.placeholder} }
  &:-moz-placeholder { ${({ theme }) => theme.placeholder} }
  &::-moz-placeholder { ${({ theme }) => theme.placeholder} }
  &:-ms-input-placeholder { ${({ theme }) => theme.placeholder} }
`;

class TextArea extends React.Component {
  updateValue = (event) => {
    this.props.setValue(event.target.value);
  }

  render() {
    const {
      label,
      type,
      placeholder,
      theme,
      getValue,
      isValid,
      isPristine,
      onChange,
      width,
      className,
      ...rest
    } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Container className={className} width={width}>
          {label &&
          <Label>{label}</Label>}
          <Input
            {...rest}
            type={type}
            placeholder={placeholder}
            value={getValue()}
            onChange={(e) => {
              if (onChange) onChange(e);
              this.updateValue(e);
            }}
            valid={isPristine() || isValid()}
          />
        </Container>
      </ThemeProvider>
    );
  }
}

TextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.node,
  setValue: PropTypes.func,
  getValue: PropTypes.func,
  isValid: PropTypes.func,
  isPristine: PropTypes.func,
  onChange: PropTypes.func,
  theme: ThemeShape,
};

TextArea.defaultProps = {
  label: '',
  placeholder: '',
  type: 'text',
  value: '',
  width: '',
  className: null,
  setValue: () => {},
  getValue: () => {},
  isValid: () => {},
  isPristine: () => {},
  onChange: () => {},
  theme: Flat,
};

export default Formsy.HOC(TextArea);
