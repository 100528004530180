import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Types as AuthTypes } from '../../../values/auth';
import WebPreview from '../../../images/authView/web_app_preview.png';
import YellowHalfCircle from '../../../images/authView/half_circle.svg';
import BlueRectangle from '../../../images/authView/horizontal_rectangle.svg';
import GreenSquare from '../../../images/authView/green_square.svg';
import { compose } from 'ramda';
import { withTranslation } from 'react-i18next';

const StyledAuthLeftSide = styled.div`
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  grid-row: 2;

  ${({ theme }) => theme.breakPoints.desktop} {
    display: block;
  }
`;

const Image = styled.img.attrs({
  src: WebPreview,
})`
  position: absolute;
  top: calc(50% - (445px / 2));
  right: -40px;
  height: 445px;
`;

const BenefitsContainer = styled.div`
  position: relative;
  top: calc(50% - (445px / 2));
  right: 0px;
  margin: auto;
  width: 550px;
  height: 500px;
`;

const Title = styled.div`
  font-size: 36px;
  color: #FFFFFF;
  letter-spacing: 0;
`;

const BenefitsList = styled.ul``;

const BenefitItem = styled.li`
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: -0.2px;
  line-height: 44px;
`;

const Note = styled.div`
  font-size: 24px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 44px;
`;

const ShapesArea = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const HalfCircle = styled.img.attrs({ src: YellowHalfCircle })``;

const Rectangle = styled.img.attrs({ src: BlueRectangle })``;

const Square = styled.img.attrs({ src: GreenSquare })`
  position: absolute;
  bottom: 30px;
  right: 0;
`;

const rect1Styles = {
  position: 'absolute',
  top: '55px',
  right: 0,
};

const rect2Styles = {
  position: 'absolute',
  bottom: '220px',
  right: '130px',
};

const AuthLeftSide = ({ authType, t }) => (
  <StyledAuthLeftSide>
    {authType === 'signup'
      ? (
        <>
          <ShapesArea>
            <HalfCircle />
            <Rectangle style={rect1Styles} />
            <Rectangle style={rect2Styles} />
            <Square />
          </ShapesArea>
          <BenefitsContainer>
            <Title>
              {t('common:AUTH.KINEDU_BENEFITS_TITLE')}
            </Title>
            <BenefitsList>
              <BenefitItem>{t('common:AUTH.KINEDU_BENEFITS_ITEM_1')}</BenefitItem>
              <BenefitItem>{t('common:AUTH.KINEDU_BENEFITS_ITEM_2')}</BenefitItem>
              <BenefitItem>{t('common:AUTH.KINEDU_BENEFITS_ITEM_3')}</BenefitItem>
            </BenefitsList>
            <Note>{t('common:AUTH.KINEDU_BENEFITS_NOTE')}</Note>
          </BenefitsContainer>
        </>
      )
      : <Image />
    }
  </StyledAuthLeftSide>
);

AuthLeftSide.propTypes = {
  authType: PropTypes.string,
  t: PropTypes.func,
};

AuthLeftSide.defaultProps = {
  authType: AuthTypes.SIGNUP,
  t: () => {},
};

export default compose(
  withTranslation([]),
)(AuthLeftSide);
