import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react-2';
import styled from 'styled-components';
import { compose } from 'redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import Button from '../../shared/buttons/MainButton';
import TextInput from '../../shared/TextInput';
import { breakPoints } from '../../../styles';
// import Giftcards from '../../../values/prices/giftcards';
import PremiumPlanButton from '../../shared/PremiumPlanButton';
import ProductsList, { COUNTRIES } from '../../../values/prices/products';

const ChooseGiftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  padding: 20px 18px;
  box-sizing: border-box;

  ${breakPoints.smallTablet} {
    padding: 56px 60px;
  }
`;

const Description = styled.p`
  color: #4A4A4A;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 30px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;

  ${breakPoints.smallTablet} {
    font-size: 28px;
    letter-spacing: -0.58px;
    line-height: 33px;
    margin-bottom: 0px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: auto;

  .next-btn {
    margin-left: 8px;
  }
`;

const PricingButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 19px;
  /* width: 620px; */
  margin-bottom: 40px;

  .plan-selected {
    border: 4px #75B753 solid;
    box-shadow: 0 4px 15px -4px #75B753;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    padding: 0px 0px 10px;
    box-sizing: border-box;
    margin: 0;
  }
`;

const Form = styled(Formsy.Form)`
  width: 100%;
`;

const InputContainer = styled.div`
  width: 100%;
  margin: 0 0 30px;
  text-align: center;

  ${breakPoints.smallTablet} {
    width: 400px;
    margin: 20px auto 40px;
  }

  input {
    background-color: transparent;
    padding: 10px;
    text-align: left;
  }
`;

const Note = styled.p`
  color: #4A4A4A;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;

  ${breakPoints.smallTablet} {
    font-size: 22px;
    letter-spacing: -1.1px;
    line-height: 25px;
    margin-top: 18px;
    margin-bottom: 43px;
  }
`;

class ChooseGift extends React.Component {
  state = { selectedPlan: null, submitEnabled: true };

  selectPlan = (selectedPlan) => {
    this.setState({ selectedPlan });
  }

  toggleSubmit = val => this.setState({ ...this.state, submitEnabled: val });

  render() {
    const {
      onSubmit, onPrevious, isForMe, userEmail, t, i18n,
    } = this.props;
    const lang = i18n.language;
    const countryScope = lang === 'pt' ? COUNTRIES.BR : COUNTRIES.WW;

    return (
      <ChooseGiftContainer>
        <Description>{t('SEND_GIFTCARD.DESCRIPTION')}</Description>
        <Note>{t('CHOOSE_GIFT.DESCRIPTION')}</Note>
        <PricingButtonContainer>
          <PremiumPlanButton
            giftcardSKU={ProductsList.gc_monthly.SKU}
            isSelected={this.state.selectedPlan === ProductsList.gc_monthly.localizedSKU[countryScope]}
            onClick={() => this.selectPlan(ProductsList.gc_monthly.localizedSKU[countryScope])}
            lang={lang}
          />
          <PremiumPlanButton
            giftcardSKU={ProductsList.gc_yearly.SKU}
            isSelected={this.state.selectedPlan === ProductsList.gc_yearly.localizedSKU[countryScope]}
            onClick={() => this.selectPlan(ProductsList.gc_yearly.localizedSKU[countryScope])}
            lang={lang}
          />
          <PremiumPlanButton
            giftcardSKU={ProductsList.gc_lifetime.SKU}
            isSelected={this.state.selectedPlan === ProductsList.gc_lifetime.localizedSKU[countryScope]}
            onClick={() => this.selectPlan(ProductsList.gc_lifetime.localizedSKU[countryScope])}
            lang={lang}
          />
          {/* <PremiumPlanButton
            giftcardType={Giftcards.lt}
            isSelected={this.state.selectedPlan === Giftcards.lt}
            onClick={() => this.selectPlan(Giftcards.lt)}
          /> */}
        </PricingButtonContainer>
        <Form
          onSubmit={values => onSubmit({ sku: this.state.selectedPlan, ...values })}
          onValid={() => this.toggleSubmit(true)}
          onInvalid={() => this.toggleSubmit(false)}
        >
          <InputContainer>
            {isForMe && (
              <TextInput
                name="email"
                style={{ marginBottom: '10px' }}
                placeholder="email"
                validations={{
                  isEmail: true,
                }}
                validationError={t('validations:IS_EMAIL')}
                required
                value={userEmail}
              />
            )}
          </InputContainer>
          <ButtonsContainer>
            <Button
              type="button"
              onClick={() => onPrevious()}
              color="outlinedGray"
              maxWidth={300}
            >
              {t('common:GO_BACK')}
            </Button>

            <Button
              className="next-btn"
              type="submit"
              color={this.state.submitEnabled && this.state.selectedPlan ? 'green' : 'disabled'}
              maxWidth={300}
              disabled={!this.state.submitEnabled || !this.state.selectedPlan}
            >
              {t('common:NEXT')}
            </Button>
          </ButtonsContainer>
        </Form>
      </ChooseGiftContainer>
    );
  }
}

ChooseGift.propTypes = {
  /* callback when form is submited */
  onSubmit: PropTypes.func,
  /* callback when "go back" button is clicked */
  onPrevious: PropTypes.func,
  /* flag to know is the gift is for me or not */
  isForMe: PropTypes.bool,
  userEmail: PropTypes.string,
  t: PropTypes.func,
  i18n: PropTypes.object,
};
ChooseGift.defaultProps = {
  onSubmit: () => {},
  onPrevious: () => {},
  isForMe: false,
  userEmail: undefined,
  t: () => {},
  i18n: {},
};

export default compose(
  withTranslation([Namespaces.MODALS, Namespaces.COMMON, Namespaces.VALIDATIONS]),
)(ChooseGift);
