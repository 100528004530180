import React from 'react';
import { useHistory } from 'react-router-dom';
import FormSignupComponent from './form';

const SignupComponent = () => {
  const history = useHistory();
  const setAuthParam = (type) => {
    history.push({
      pathname: `/${type}`,
    });
  };

  const onAuthTypeChange = (type) => {
    setAuthParam(type);
  };

  return (
    <>
      <FormSignupComponent onAuthTypeChange={onAuthTypeChange} />
    </>
  );
};

SignupComponent.propTypes = {};
export default SignupComponent;
