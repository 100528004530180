import { css } from 'styled-components';
import { breakPoints } from '../../breakPoints';

const HeaderModal = {
  DropdownButton: css` `,
  Left: css` `,
  Content: css` `,
  Center: css` `,
  Title: css`
    font-weight: bold;
    font-size: 20px;
    `,
  Description: css`
    font-size: 14px;
    color: #657480;
    `,
  ModalCalendarHeaderContainer: css` `,
  Span: css` `,
  ImageContainer: css`
    ${({ eventColor }) => `
    background-color: ${eventColor}30;
    `}
    align-items: flex-end;
    overflow: hidden;
    Width: 56px;
    height: 56px;
    padding: 0px;
    ${({ eventType }) => `
    align-items: ${eventType === 'CLASSROOM' ? 'flex-end' : 'center'};
    `}

    ${breakPoints.largePhone} {
      padding: 0px;
  }
    `,
  ImageEvent: css`

    ${({ eventType }) => `
    position: relative;
    top: ${eventType === 'CLASSROOM' ? '8px' : '0px'};
    height: ${eventType === 'CLASSROOM' ? '53px' : '39px'};
    `}

    ${breakPoints.largePhone} {
        ${({ eventType }) => `
        height: ${eventType === 'CLASSROOM' ? '90px' : eventType === 'CENTER' ? '56px' : '65px'};
        top: ${eventType === 'CLASSROOM' ? '16px' : '0px'};
        `}
    }
    
    `,
  Modal: css` `,
};

export default HeaderModal;
