import { CONEKTA_API_KEY } from '../config';

let Conekta;

export const init = () => {
  Conekta = window.Conekta;
  Conekta.setPublicKey(CONEKTA_API_KEY);
  Conekta.setLanguage('es');
};

((d, s, id) => {
  const fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) { return; }
  const js = d.createElement(s);
  js.id = id;
  js.src = 'https://cdn.conekta.io/js/v1.0.1/conekta.js';
  js.onload = () => {
    init();
  };
  fjs.parentNode.insertBefore(js, fjs);
})(document, 'script', 'conekta-jssdk');

if (window.Conekta) {
  init();
}

export const installConekta = () => {
  const script = document.createElement('script');
  script.src = 'https://cdn.conekta.io/js/v1.0.1/conekta.js';
  script.async = true;
  document.body.appendChild(script);
};

export const initConekta = () => {
  window.Conekta.setPublicKey(CONEKTA_API_KEY);
};

export const ErrorCodes = {
  INVALID_CARD: 'invalid_card',
  WRONG_PARAMETERS: 'wrong_parameters',
  DECLINED_CARD: 'declined_credit_card',
};

export const validateCard = ({ number, cvc, expDate }) => Conekta.card.validateNumber(number)
  && Conekta.card.validateExpirationDate(expDate.month, expDate.year)
  && Conekta.card.validateCVC(cvc);

export const tokenizeCard = ({
  number, name, cvc, expDate,
} = {}) => new Promise((resolve, reject) => {
  const substrings = expDate.split('/');
  const { expMonth, expYear } = { expMonth: substrings[0], expYear: substrings[1] };

  if (!validateCard({ number, cvc, expDate: { month: expMonth, year: expYear } })) {
    reject(ErrorCodes.INVALID_CARD);
  }
  Conekta.Token.create({
    card: {
      number,
      name,
      cvc,
      exp_month: expMonth,
      exp_year: expYear,
    },
  },
  ({ id }) => resolve(id),
  ({ code }) => reject(code));
});
