// @flow
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Transition from 'react-transition-group/Transition';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import { fullBleed } from '../../styles/layout';
import { fade } from '../../styles/transitions';
import { colors } from '../../styles';

import Texture from '../../images/Texture2.png';
import BabyCrawlingSequence from '../../images/babyCrawlingSequence.png';

const BabyAnimated = styled.div`
  width: 210px;
  height: 160px;
  background-image: url(${BabyCrawlingSequence});
  background-position: left center;
  background-size: cover;

  @keyframes babyCrawling {
    100% {
      background-position: -12800px;
    }
  }

  animation: babyCrawling 3s steps(30) infinite;
`;

const Screen = styled.div`
  background-color: ${colors.physical};
  background-image: url(${Texture});
  z-index: 3;
  ${fullBleed('fixed')};
  flex-direction: column;
  opacity: 1;
  ${({ show }) => fade(show, 1.5)}
`;

const Title = styled.h1`
  color: ${colors.white};
  font-size: 20px;
  font-weight: bold;
`;

/* ::
type Props = {
  loading: boolean,
};
*/

const SplashScreen = ({ loading, t } /* : Props */) => (
  <Transition in={loading} timeout={1800} unmountOnExit>
    {state => (
      <Screen show={state === 'entered'}>
        <BabyAnimated />
        <Title>{t('LOADING')}</Title>
      </Screen>
    )
    }
  </Transition>
);

SplashScreen.propTypes = {
  loading: PropTypes.bool,
  t: PropTypes.func,
};
SplashScreen.defaultProps = {
  loading: false,
  t: () => {},
};

export default withTranslation([Namespaces.COMMON])(SplashScreen);
