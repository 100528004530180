import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from './Icon';
import { applyColorByAreaId, applyAreaColorByActivityType } from '../../styles/mixins';
import { ActivityType } from '../../values/areas';

const IconNames = [
  '',
  'physical',
  'cognitive',
  'linguistic',
  'socialEmotional',
  'health',
];

export const IconNamesByActivityType = {
  [ActivityType.WEWBINAR]: 'webinar',
  [ActivityType.INTERACTIVE]: 'interactive',
};

const AreaIcon = styled(Icon)
  .attrs(({ areaId, activityType }) => ({ icon: IconNames[areaId] || IconNamesByActivityType[activityType] }))`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 7px;
    border-radius: 100%;
    fill: #FFFFFF;
    flex: 0 0 auto;
    background-color: ${applyAreaColorByActivityType};
    background-color: ${applyColorByAreaId};
  `;

AreaIcon.propTypes = {
  areaId: PropTypes.oneOf([1, 2, 3, 4, 5]),
  activityType: PropTypes.oneOf([ActivityType.WEWBINAR, ActivityType.INTERACTIVE]),
};

export default AreaIcon;
