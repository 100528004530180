import React, { Fragment } from 'react';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import NeoModal from './NeoModal';
import { colors } from '../../styles';

import BabySerious from '../../images/BabySerious.svg';

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'PremiumBrokeModal',
  data: {
    familyName: 'Lopez'
  },
}

*/

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  color: ${colors.kinedu};
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  line-height: 33px;

  margin: 30px 0px 10px 0;
`;

const Subtitle = styled.p`
  color: ${colors.kinedu};
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -0.55px;
  text-align: center;
  line-height: 21px;
  margin: 0 0 20px 0px;

  max-width: 211px;
`;

const Description = styled.p`
  font-size: 16px;
  color: ${colors.shadeDark};
  letter-spacing: -0.36px;
  text-align: center;
  line-height: 21px;
  margin: 30px 0;

  max-width: 263px;
`;

const Image = styled.img.attrs({
  src: props => props.src,
})`
  width: 120px;
`;

const PremiumBrokeModal = ({ familyName, t }) => (
  <Modal roundedCorners>
    {() => (
      <Fragment>
        <Title>{t('MODAL_PREMIUM_BROKE.TITLE')}</Title>
        <Subtitle>{t('MODAL_PREMIUM_BROKE.SUBTITLE')}</Subtitle>
        <Image src={BabySerious} />
        <Description>
          {t('MODAL_PREMIUM_BROKE.DESCRIPTION', { familyName })}
        </Description>
      </Fragment>
    )}
  </Modal>
);

export default withTranslation([Namespaces.MODALS])(PremiumBrokeModal);
