import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FullModal from './FullModal';
import PrematureMessage from '../onBoarding/PrematureMessage';

const Wrapper = styled(FullModal)`
  display: flex;
  flex-direction: column;
  background-color: #E0E0E0;
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'PrematureModal',
  data: {
    baby1: {
      name: 'Johnny',
      gender: 'male',
      age_in_months: 9,
      developmental_age_in_months: 8,
    }
  },
}

*/

class PrematureModal extends Component {
  state = {};

  render() {
    const { baby1, baby2 } = this.props;
    return (
      <Wrapper closeOnBackdrop={false} roundedCorners>
        {({ closeModal }) => (
          <PrematureMessage
            onFinishClicked={closeModal}
            devAge={baby1.developmental_age_in_months}
            baby1={baby1}
            baby2={baby2}
            twins={!!baby2}
          />
        )}
      </Wrapper>
    );
  }
}

PrematureModal.propTypes = {
  baby1: PropTypes.object,
  baby2: PropTypes.object,
};

PrematureModal.defaultProps = {
  baby1: {},
  baby2: null,
};

export default PrematureModal;
