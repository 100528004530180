import styled from 'styled-components';
import { breakPoints, fillAbsoluteSpace } from '../../../styles';

const Container = styled.div`
  ${fillAbsoluteSpace}
  .hot-keys {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    ${fillAbsoluteSpace}

    ${breakPoints.smallTablet} {
      display: flex;
      flex-direction: row;
    }
  }

* {
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    background-color: transparent;
  }
`;

const Body = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const MainContainerWrapper = styled.div`
  .Toastify__toast-container {
    z-index: 10001;
  }

  .Toastify__toast-container .savedEvent{
    min-height: 36px !important;
    height: 36px !important;
  }

  .Toastify__toast-container .savedEvent > div{
    padding: 0 !important;
  }

  .toastReportedMessage{
    font-family: ProximaNova-Semibold;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 18px;
    width: 372px;

    ${breakPoints.smallTablet} {
      font-size: 14px;
    }
  }
`;

export default Container;

export {
  Body,
  MainContainerWrapper,
};
