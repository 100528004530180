import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Title from '../../shared/Title';
import { colors, breakPoints } from '../../../styles';
import SpecialActivityCard from '../SpecialActivityCard/SpecialActivityCard';

const StyledSpecialCase = styled.div`
`;

const Header = styled.div`
  background-color: ${colors.physical};
  padding: 10px;
  text-align: center;
  margin: 30px 0;
`;

const Subtitle = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: -0.17px;
  text-transform: uppercase;
  opacity: 0.5;
`;

const CardsGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  margin: 30px 20px;

  ${breakPoints.smallTablet} {
    grid-template-columns: repeat(3, 1fr);
    margin: 30px 0;
  }
`;

const SpecialCase = ({ show, title, subSkills, ageRange, t }) => (show ? (
  <StyledSpecialCase>
    <Header>
      <Title color="white" size={18} spacing="-0.29px" medium>{title}</Title>
      <Subtitle>{t('SKILL_DETAIL.INTENTED_AGE_RANGE', { ageRange })}</Subtitle>
    </Header>
    <CardsGrid>
      {subSkills.map(skill => (
        <SpecialActivityCard
          key={skill.id}
          id={skill.id}
          title={skill.title}
          currentMilestones={skill.completed_milestones}
          totalMilestones={skill.active_milestones}
          image=""
          t={t}
        />
      ))}
    </CardsGrid>
  </StyledSpecialCase>
) : null);

SpecialCase.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  subSkills: PropTypes.array,
  ageRange: PropTypes.string,
  t: PropTypes.func,
};

SpecialCase.defaultProps = {
  show: false,
  title: '',
  subSkills: [],
  ageRange: '',
  t: () => {},
};

export default SpecialCase;
