import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import { colors, breakPoints } from '../../../styles';

import Button from '../../shared/buttons/MainButton';
import BgStars from '../../../images/BgStars.svg';
import BabyChampion from '../../../images/BabyChampion.svg';
import BabyTrophy from '../../../images/BabyTrophy.svg';
import BabyHooray from '../../../images/BabyHooray.svg';
import Roulette from '../../../images/Roulette.svg';
import Badge from './Badge';
import Light from './Light';
import { genderAdposition } from '../../../lib/utils';

const FinishDAPContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;

  ${({ type }) => type === 'A' && css`
    background-color: ${({ bgColor }) => bgColor || colors.kinedu};
    background-image: url(${BgStars});
    background-position-y: 20px;
    background-repeat: no-repeat;
  `}
`;

const Message = styled.div`
  margin-top: 300px;

  ${breakPoints.smallTablet} {
    margin-top: 200px;
  }
`;

const TextUp = styled.p`
  color: rgba(0,0,0,0.4);
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0;

  margin: 10px 0 0;
  text-transform: uppercase;
  text-align: center;
  opacity: 0.6;

  ${({ type }) => type === 'B' && css`
    color: ${colors.white};
    font-size: 36px;
    text-shadow: 0 2px 3px rgba(0,0,0,0.30);
    opacity: 1;
  `}
`;

const TextDown = styled.p`
  color: ${colors.white};
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 52.5px;
  text-shadow: 0 2px 3px rgba(0,0,0,0.30);
  text-align: center;

  width: 375px;
  margin: 10px 30px 0px;

  ${({ type }) => type === 'B' && css`
    font-weight: 500;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 36.7px;
    width: 100%;
    max-width: 315px;
    margin: 20px 0;

    ${breakPoints.smallTablet} {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      max-width: none;
    }
  `}
`;

const TextBelow = styled.p`
  color: ${colors.white};
  font-weight: bold;
  font-size: 32px;
  letter-spacing: -1.81px;
  line-height: 36px;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0,0,0,0.30);

  width: 380px;
  margin: 0px auto 0;
  transform: rotate(2deg);

  ${breakPoints.smallTablet} {
    width: 440px;
    margin: 0px 0px 50px;
    font-size: 50px;
    line-height: 40px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;

  width: 460px;
  height: 120px;
  margin: auto 0px 30px;

  ${breakPoints.smallTablet} {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const versions = [
  null,
  {
    type: 'A',
    image: BabyChampion,
    textUp: 'DOPAMINE.IMPRESSIVE',
    textDown: 'DOPAMINE.YOU_AND_BABY',
    color: colors.physical,
    ctaBtnText: 'DOPAMINE.VIEW_PAST_ACT',
  },
  {
    type: 'A',
    image: BabyTrophy,
    textUp: 'DOPAMINE.GREAT_JOB',
    textDown: 'DOPAMINE.YOU_AND_BABY',
    color: colors.linguistic,
    ctaBtnText: 'DOPAMINE.VIEW_PAST_ACT',
  },
  {
    type: 'A',
    image: BabyHooray,
    textUp: 'DOPAMINE.MISSION_ACCOMPLISHED',
    textDown: 'DOPAMINE.YOU_AND_BABY',
    color: colors.physical,
    ctaBtnText: 'DOPAMINE.VIEW_PAST_ACT',
  },
  {
    type: 'B',
    image: Roulette,
    textUp: 'DOPAMINE.GREAT_JOB',
    textDown: 'DOPAMINE.COMPLETED_PLAN',
    ctaBtnText: 'DOPAMINE.AWESOME',
  },
];

const FinishDAP = ({ version, baby, onClose, onPastPlans, t }) => (
  <FinishDAPContainer type={versions[version].type} bgColor={versions[version].color}>
    <Light disabled={versions[version].type === 'B'}>
      <Badge image={versions[version].image} />
      <Message>
        <TextUp type={versions[version].type}>{t(versions[version].textUp)}</TextUp>
        <TextDown type={versions[version].type}>
          {t(versions[version].textDown, { name: baby.name, adpos: genderAdposition(baby.name) })}
        </TextDown>
        {versions[version].type === 'A' && <TextBelow>{t('DOPAMINE.COMPLETED_TODAYS_PLAN')}</TextBelow>}
      </Message>
      <ButtonsContainer>
        {versions[version].type === 'A' &&
          <Button onClick={onClose} color="traslucid" maxWidth="200">
            {t('DOPAMINE.BACK_TO_PLAN')}
          </Button>
        }
        <Button
          onClick={version === 4 ? onClose : onPastPlans}
          color="green"
          maxWidth={240}
        >
          {t(versions[version].ctaBtnText)}
        </Button>
      </ButtonsContainer>
    </Light>
  </FinishDAPContainer>
);

FinishDAP.propTypes = {
  /* Number between 1 and 4, versions of the view */
  version: PropTypes.number,
  /* Baby's info */
  baby: PropTypes.object,
  /* Translation function */
  onClose: PropTypes.func,
  onPastPlans: PropTypes.func,
  t: PropTypes.func,
};
FinishDAP.defaultProps = {
  version: 1,
  baby: {},
  onClose: () => {},
  onPastPlans: () => {},
  t: () => { },
};

export default withTranslation([Namespaces.DAP])(FinishDAP);

