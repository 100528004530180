import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { parse } from 'query-string';
import { useHistory } from 'react-router-dom';

import { PAYMENT_PATH_PP_KEY, QUERY_PARAM_TO_SHOW_MODAL } from 'components/premiumProcessModal/values';

import EventReporter, { Events } from '../../lib/EventReporter';
import { Selectors } from '../../data/ducks/babiesAndFamilies';
import NeoModal from './NeoModal';
import BabyImg from '../../images/invites/BabyBornFeedback.svg';
import SchoolImg from '../../images/feed/school_feed.svg';
import RoundedButton from '../shared/buttons/RoundedButton';
import { NeoFilled } from '../themes/RoundedButton';
import { colors } from '../../styles';
import { Creators } from '../../data/ducks/modals';
import PaymentSources from '../../values/prices/paymentSources';

const Modal = styled(NeoModal)`
  display: flex;
  width: 460px !important;
  overflow: hidden;
  border-radius: 4px;
`;

const ModalContent = styled.div`
  width: 100%;
  text-align: center;
  padding: 30px 20px;

  button {
    margin: 30px auto 0;

    &.disabled-button {
      background-color: ${colors.shadeMediumDark};
    }
  }

  ${({ theme }) => theme.breakPoints.tablet} {
    padding: 30px;
  }

`;

const Title = styled.div`
  color: #0093DD;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin: 11px 0 22px;

  ${({ theme }) => theme.breakPoints.tablet} {
    font-size: 24px;
    line-height: 31px;
  }
`;


const FeedbackOption = styled.div`
  border: 1px solid #CFD5DA;
  background: ${({ isSelected }) => (isSelected ? '#ECF8FC' : '#F8F8F8')} ;
  border-radius: 6px;
  width: 100%;
  height: 110px;
  cursor: pointer;
  max-width: 400px;
  margin-bottom: 21px;
  display: flex;
  text-align: left;
  align-items: center;

  span {
    color: #0082C3;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    padding-right: 10px;
    letter-spacing: -0.3px;

    ${({ theme }) => theme.breakPoints.tablet} {
      line-height: 26px;
      padding-right: 20px;
    }
  }
`;

const ImageContent = styled.div`
  overflow: hidden;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: ${({ position }) => position};
  background-size: ${({ size }) => `${size}px`};
  width: 114px;
  height: 110px;
  min-width: 114px;
  margin-right: 10px;
  border-radius: 6px 0 0 6px;

  ${({ theme }) => theme.breakPoints.tablet} {
    margin-right: 17px;
  }
`;


const FeedbackSelectionNewBabyModal = ({ t }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeFamily = useSelector(state => Selectors.activeFamily(state));
  const [selectedOption, setSelectedOption] = useState('');

  const options = [
    {
      text: t('FEEDBACK_NEW_BABY.BABY_BORN'),
      image: BabyImg,
      key: 'babyBorn',
      position: '15px 12px',
      size: 100,
    },
    {
      text: t('FEEDBACK_NEW_BABY.BABY_LINKED_EDUCATOR'),
      image: SchoolImg,
      key: 'educators',
      position: '-5px 11px',
      size: 110,
    },
  ];

  const openPremiumFeature = (source) => {
    if (activeFamily.self_owner) {
      EventReporter.action(Events.TAP_CALL_TO_ACTION({ source }));
      // dispatch(push(APP_SECTIONS_PATHS.PREMIUM_PROCESS, { source }));

      window.localStorage.setItem(PAYMENT_PATH_PP_KEY, source);
      const queryParams = parse(window.location.search);
      queryParams[QUERY_PARAM_TO_SHOW_MODAL] = true;

      history.push({
        search: new URLSearchParams({
          ...queryParams,
        }).toString(),
      }, { source });
    } else {
      const familyName = activeFamily.name;
      dispatch(Creators.openModal({ name: 'PremiumBrokeModal', data: { familyName } }));
    }
  };

  const handleClick = () => {
    if (selectedOption !== '') {
      if (selectedOption === 'educators') {
        // localStorage.setItem('OPEN_CLASSROOM_ID_AFTER_SAVE_BABY', 'true');
        // localStorage.setItem('REDIRECT_TO_MY_CLASSROOM_AFTER_JOIN', 'true');
        // localStorage.setItem(LOCALSTORAGE_BABY_STATUS, 'classroomsBaby');
        localStorage.setItem('CREATE_BABY_AFTER_JOIN', 'true');
        dispatch(Creators.openModal({ name: 'AddBaby' }));
      } else {
        localStorage.removeItem('OPEN_CLASSROOM_ID_AFTER_SAVE_BABY');
        localStorage.removeItem('CREATE_BABY_AFTER_JOIN');
        if (!activeFamily.is_premium) {
          dispatch(Creators.closeModal({ name: 'CLOSE_ALL_MODALS' }));
          openPremiumFeature(PaymentSources.ADD_BABY);
        } else {
          dispatch(Creators.openModal({ name: 'AddBaby' }));
        }
      }
    }
  };

  return (
    <Modal>
      {() => (
        <ModalContent>
          <Title>
            {t('FEEDBACK_NEW_BABY.TITLE')}
          </Title>

          {options.map(opt => (
            <FeedbackOption
              key={opt.key}
              isSelected={selectedOption === opt.key}
              onClick={() => setSelectedOption(opt.key)}
            >
              <ImageContent image={opt.image} position={opt.position} size={opt.size} />
              <span>
                {opt.text}
              </span>
            </FeedbackOption>
          ))}

          <RoundedButton
            theme={NeoFilled}
            width={255}
            className={selectedOption === '' && 'disabled-button'}
            onClick={() => handleClick()}
          >
            {t('COMMON:CONTINUE')}
          </RoundedButton>
        </ModalContent>
      )}
    </Modal>
  );
};

export default withTranslation(['classroom_invite', 'common'])(FeedbackSelectionNewBabyModal);
