import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../styles';


const AlertMessageContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 55px;
  background: ${({ color }) => color};
  border-radius: 4px;

  padding: 10px;
  box-sizing: border-box;

  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: -0.5px;
  line-height: 16px;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  height: 100%;
`;

const AlertMessage = ({ children, icon, color }) => (
  <AlertMessageContainer color={color}>
    {icon && <Icon>{icon}</Icon>}
    <span>{children}</span>
  </AlertMessageContainer>
);

AlertMessage.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  color: PropTypes.string,
};
AlertMessage.defaultProps = {
  children: null,
  icon: null,
  color: colors.shadeMedium,
};

export default AlertMessage;
