import Immutable from 'seamless-immutable';
import { path } from 'ramda';
import { createActions, createReducer } from 'reduxsauce';

import { tU, asyncTypeAndActionCreators as aTC } from '../../lib/utils';
import { DEEP } from '../redux-sauce';

const INITIAL_STATE = Immutable({
  activeSkill: 0,
  isAssessmentActive: false,
  loading: false,
  skills: [],
});

export const { Creators, Types } = createActions({
  fetchBabyProgressRequested: ['babyId', 'current'],
  fetchBabyProgressSuccess: ['progress'],
  fetchBabyProgressError: null,
  startAnAssessment: ['babyId', 'areaId'],
  endAssessment: null,
  progressPendingSkill: tU('babyId', 'areaId', 'skillId', 'context'),
  ...aTC('assessment', {
    requested: tU('babyId', 'areaId'),
    success: tU('babyId', 'skills', 'areas'),
    error: null,
  }),
  ...aTC('skill', {
    requested: tU('babyId', 'skillId'),
    success: tU('babyId', 'skill'),
    error: null,
  }),
  setSkillMastered: tU('assessmentId', 'skill', 'baby', 'showDS', 'activeMilestones', 'completedMilestones'),
  answerAssessmentMilestonesRequested: tU('skill', 'baby', 'answers', 'showDS'),
  answerAssessmentMilestonesSuccess: tU('percentile', 'baby', 'activeMilestones', 'completedMilestones', 'skill', 'showDS'),
  answerAssessmentMilestonesError: null,
  fetchSkillDetailRequested: ['babyId', 'skillId'],
  fetchSkillDetailSuccess: ['skillData', 'babyId'],
  fetchSkillDetailError: null,
  fetchMasterMilestonesRequested: ['skillId', 'babyId'],
  fetchMasterMilestonesSuccess: ['skillInfo', 'babyId'],
  fetchMasterMilestonesError: null,
});

const getSkills = state => path(['progress', 'skills'], state) || [];
const getSkillsByArea = (state, areaId) => {
  if (areaId > 0) {
    return getSkills(state).filter(s => s.area_id === areaId) || [];
  }
  return getSkills(state).filter(s => s?.progress_status === 'current') || [];
};
const getSkillById = (state, skillId) => ({ ...getSkills(state).filter(s => s.id == skillId)[0] }); // eslint-disable-line
const getPendingSkillsByArea = (state, areaId) =>
  getSkillsByArea(state, areaId).filter(s => s.completed_milestones === null) || [];
const getActiveSkill = state => path(['progress', 'activeSkill'], state);
const getLoading = state => path(['progress', 'loading'], state);
const getActiveBabyId = state => path(['progress', 'babyId'], state);
const getMilestones = ({ progress }, skillId) => {
  const { skills = [] } = progress;
  const masterSkill = skills.find(skill => skill.id == skillId); // eslint-disable-line
  return path(['masterMilestones'], masterSkill) || [];
};
export const Selectors = {
  getSkills,
  getSkillsByArea,
  getSkillById,
  getPendingSkillsByArea,
  getActiveSkill,
  getLoading,
  getActiveBabyId,
  getMilestones,
};

export const Reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_BABY_PROGRESS_REQUESTED]: state => state.merge({ loading: true, activeSkill: 0 }),
  [Types.FETCH_BABY_PROGRESS_SUCCESS]: (state, { progress }) => {
    const { skills } = progress;
    return state.merge({
      loading: false,
      skills,
    });
  },
  [Types.FETCH_BABY_PROGRESS_ERROR]: state => state.merge({ loading: false }),
  [Types.START_AN_ASSESSMENT]: (state, { babyId, areaId }) =>
    state.merge({ isAssessmentActive: true, babyId, areaId }),
  [Types.FETCH_SKILL_REQUESTED]: state => state.merge({ loading: true }),
  [Types.FETCH_SKILL_SUCCESS]: (state, { babyId, skill }) =>
    state.merge({
      loading: false,
      [babyId]: { skills: { [skill.id]: skill } },
    }, DEEP),
  [Types.FETCH_MASTER_MILESTONES_REQUESTED]: state =>
    state.merge({
      loading: true,
    }, DEEP),
  [Types.FETCH_MASTER_MILESTONES_SUCCESS]: (state, { skillInfo, babyId }) => {
    const { id: skillId, milestones } = skillInfo;
    const skill = state.skills.find(({ id }) => id === skillId);
    const skillIndex = state.skills.findIndex(({ id }) => id === skillId);
    if (skillIndex === -1) {
      return state;
    }
    return state.merge({
      [babyId]: { skills: { [skillId]: { ...skill, masterMilestones: milestones } } },
      skills: state.skills.setIn([skillIndex], {
        ...state.skills[skillIndex],
        masterMilestones: milestones,
      }),
      loading: false,
    }, DEEP,
    );
  },
  [Types.FETCH_MASTER_MILESTONES_ERROR]: state => state.merge({ loading: false }),
  [Types.SET_SKILL_MASTERED]: state => state.merge({ loading: true }),
  [Types.ANSWER_ASSESSMENT_MILESTONES_REQUESTED]: state => state.merge({ loading: true }),
  [Types.ANSWER_ASSESSMENT_MILESTONES_SUCCESS]: (state, { skill, completedMilestones }) => {
    const skillIndex = state.skills.findIndex(({ id }) => id === skill.id);
    return state.merge({
      loading: false,
      skills: state.skills.setIn([skillIndex], {
        ...state.skills[skillIndex],
        completed_milestones: completedMilestones,
      }),
    });
  },
  [Types.FETCH_SKILL_DETAIL_REQUESTED]: state => state.merge({ loading: true }),
  [Types.FETCH_SKILL_DETAIL_SUCCESS]: (state, { skillData, babyId }) => {
    const { id: skillId, progress, activities, articles } = skillData;
    const skill = state.skills.find(({ id }) => id === skillId);
    const skillIndex = state.skills.findIndex(({ id }) => id === skillId);
    if (skillIndex === -1) {
      return state;
    }
    return state.merge({
      activeSkill: skillId,
      [babyId]: {
        skills: {
          ...state[babyId].skills,
          [skillId]: {
            ...skill,
            progress,
            activities,
            articles,
          },
        },
      },
      skills: state.skills.setIn([skillIndex], {
        ...state.skills[skillIndex],
        progress,
        activities,
        articles,
      }),
      loading: false,
    });
  },
  // TODO: Basate en los reducers de families, fijate que se usa un setIn en lugar de un merge
  // y primero se debe de obtener el index del skill
});
