// import Leanplum from 'leanplum-sdk';
import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';

import { store } from '../App';
import * as Events from '../values/events';
import { postEvent } from '../api/events-and-messages';
import { IS_DEVELOPMENT_MODE } from '../config';
import {
  memberRolesForEvents,
  userRelationshipsForEvents,
} from '../values/families';
import ReactGA from './analytics';

const EventType = {
  ACTION: 'action',
  VIEW: 'view',
  STATE: 'state',
};

export const EventProviders = {
  KINEDU: 'KINEDU',
  // LEANPLUM: 'LEANPLUM',
  MIXPANEL: 'MIXPANEL',
  GA: 'GA',
  GTM: 'TAG_MANAGER',
  PIXEL: 'FB_PIXEL',
};

// Map Kinedu Backend action format to each provider.
const ProviderAdapters = {
  [EventProviders.KINEDU]: (type, { tag, params }) => postEvent({ tag, type, params }),
  // [EventProviders.LEANPLUM]: (type, { tag, params }) =>
  //   (type === 'state' ? Leanplum.advanceTo(tag, params) : Leanplum.track(tag, params)),
  [EventProviders.MIXPANEL]: (type, { tag, params }) => mixpanel.track(tag, params),
  [EventProviders.GTM]: (type, { tag, params }) => TagManager.dataLayer({
    dataLayer: { event: tag, ...params },
  }),
  [EventProviders.GA]: (type, { tag, params }) => ReactGA.pageview(tag),
  [EventProviders.PIXEL]: (type, { tag, params }) => ReactPixel.trackCustom(tag, params),
};

export const defaultProviders = [EventProviders.MIXPANEL];

// General report method
const report = (type, event, providers = defaultProviders) => {
  try {
    if (IS_DEVELOPMENT_MODE) {
      console.info(`%cEVENT [${event?.tag}]`, 'background: #1e4628; color: #95d477;', {
        type,
        event,
        providers,
      });
    }
    providers.map(provider => ProviderAdapters[provider](type, event));
    return null;
  } catch (error) {
    console.error('report::catch::error', error);
    return null;
  }
};
const action = (event, providers) => report(EventType.ACTION, event, providers);
const view = (event, providers) => report(EventType.VIEW, event, providers);
const state = (event, providers) => report(EventType.STATE, event, providers);

// Utility method that presets event type.
const EventReporter = {
  action,
  view,
  state,
  report,
};

export default EventReporter;
export { Events };

export const setUserAttributes = (user) => {
  mixpanel.people.set(user);
  // Leanplum.setUserAttributes(user);
  // Leanplum.forceContentUpdate();

  store.dispatch({
    type: 'ATTRIBUTES',
    infoType: 'user',
    attrs: {
      ...user,
    },
  });
};

export const setFamilyAttributes = (family = {}, activeBaby = {}) => {
  const {
    babies = [],
    expires_on: expiresOn = '',
    is_premium: isPremium = false,
    membership,
    sku = '',
  } = family;

  const { relationship = 0, role: userRoleId = 0 } = membership || {};

  const planType = isPremium ? 'premium' : 'freemium';

  const userAttrs = {
    userRelationship: userRelationshipsForEvents[relationship],
    userRole: memberRolesForEvents[userRoleId],
  };

  const familyAttrs = {
    BabiesCount: babies.length,
    membershipExpirationDate: expiresOn,
    planType,
    sku,
  };

  const babyAttrs = {
    babyAge: activeBaby.age_in_months,
    babyBirthday: activeBaby.birthday,
    // babyDueDate: activeBaby.
    babyGender: activeBaby.gender,
    // babyLength: activeBaby.
    babyName: activeBaby.name,
    // babyPrenatal: 'No',
    // babyWeight: activeBaby.
    // healthAttributes: activeBaby.
  };

  store.dispatch({
    type: 'ATTRIBUTES',
    infoType: 'user',
    attrs: {
      ...userAttrs,
    },
  });

  store.dispatch({
    type: 'ATTRIBUTES',
    infoType: 'family',
    attrs: {
      ...familyAttrs,
    },
  });

  store.dispatch({
    type: 'ATTRIBUTES',
    infoType: 'baby',
    attrs: {
      ...babyAttrs,
    },
  });

  setUserAttributes({
    ...userAttrs,
    ...familyAttrs,
    ...babyAttrs,
  });
};
