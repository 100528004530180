import styled from 'styled-components';
import BabyImage from '../assets/images/baby.svg';
import BgImage from '../assets/images/bg.svg';
// import { ReactComponent as BgImage } from '../assets/images/bg.svg';

const FavoriteBannerUpdatePremiumWrapper = styled.div.attrs({
  className: 'FavoriteBannerUpdatePremiumWrapper',
})`
  display: block;
  width: 100%;
  margin: 0 auto;
`;

const FavoriteBannerUpdatePremiumContent = styled.div.attrs({
  className: 'FavoriteBannerUpdatePremiumContent',
})`
  display: grid;
  width: 100%;
  margin: 0 auto;
  background-image: url(${BgImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  grid-template-columns: 71px 1fr;
  grid-gap: 28px 24px;
  grid-template-areas: "Image Text"
                         "Image Btn";
  text-align: center;
  align-items: center;
  padding: 20px 20px 10px 20px;
  border-radius: 8px;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));

  ${({ theme }) => theme.breakPoints.tablet} {
    padding: 10px 50px 10px 72px;
    grid-template-columns: 85px 1fr 185px;
    grid-template-areas: "Image Text Btn";
  }


`;
const FavoriteBannerUpdatePremiumContentImage = styled.div.attrs({
  className: 'FavoriteBannerUpdatePremiumContentImage',
})`
  display: block;
  width: 100%;
  margin: 0 auto;
  grid-area: Image;
`;

const FavoriteBannerUpdatePremiumImage = styled.img.attrs({
  className: 'FavoriteBannerUpdatePremiumImage',
  src: BabyImage,
})`
  display: block;
  width: 100%;
  margin: 0 auto;
`;

const FavoriteBannerUpdatePremiumContentText = styled.div.attrs({
  className: 'FavoriteBannerUpdatePremiumContentText',
})`
  display: block;
  width: 100%;
  margin: 0 auto;
  grid-area: Text;
`;

const FavoriteBannerUpdatePremiumText = styled.div.attrs({
  className: 'FavoriteBannerUpdatePremiumText',
})`
  display: block;
  width: 100%;
  margin: 0 auto;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;

  ${({ theme }) => theme.breakPoints.tablet} {
    font-size: 20px;
    line-height: 20px;
  }


`;
const FavoriteBannerUpdatePremiumContentBtn = styled.div.attrs({
  className: 'FavoriteBannerUpdatePremiumContentBtn',
})`
  display: block;
  width: 100%;
  margin: 0 auto;
  grid-area: Btn;
`;


export default FavoriteBannerUpdatePremiumWrapper;
export {
  FavoriteBannerUpdatePremiumContent,
  FavoriteBannerUpdatePremiumContentImage,
  FavoriteBannerUpdatePremiumContentText,
  FavoriteBannerUpdatePremiumContentBtn,
  FavoriteBannerUpdatePremiumImage,
  FavoriteBannerUpdatePremiumText,
};
