import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NeoModal from './NeoModal';
import { breakPoints } from '../../styles';
import UnmarkedMilestonesView from '../views/UnmarkedMilestonesView';

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  margin: auto 20px;

  ${breakPoints.smallTablet} {
    margin: auto;
  }
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'UnmarkedMilestonesModal',
  data: {
    baby: {
      name: 'Johnny',
      gender: 'male',
    }
  },
}

*/

const UnmarkedMilestonesModal = ({ onSubmit, onClose, baby }) => (
  <Modal width={460} onCloseModal={onClose} roundedCorners>
    {({ closeModal }) => (
      <Fragment>
        <UnmarkedMilestonesView onSubmit={onSubmit} closeModal={closeModal} baby={baby} />
      </Fragment>
    )}
  </Modal>
);

UnmarkedMilestonesModal.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  baby: PropTypes.object,
};

UnmarkedMilestonesModal.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
  baby: {},
};

export default UnmarkedMilestonesModal;
