import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { path, prop } from 'ramda';
import BaseCard from '../BaseCard/BaseCard';
import Icon from '../../shared/Icon';
import AreaIcon from '../../shared/AreaIcon';

import { Namespaces } from '../../../values/i18n';
import { AreaNameById } from '../../../values/areas';

import { colors } from '../../../styles';
import CardOverlay from '../CardOverlay/CardOverlay';

const ChangedIcon = styled(Icon).attrs({
  icon: 'changeActivity',
  size: 45,
  color: '#FECD10',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
`;

const ChangedActivityOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 150px;
  height: 40px;
  background-color: rgba(0, 0, 0, .5);

  color: white;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.58px;
`;

AreaIcon.propTypes = {
  areaId: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
};


const Background = styled.div`
  position: relative;
  background-size: cover;
  flex-grow: 1;
  background-image: ${props => `url(${props.src})`};
`;

const Footer = styled.div.attrs({
  className: 'card-footer',
})`
  align-items: center;
  background-color: ${colors.shadeLighter};
  box-sizing: border-box;
  display: flex;
  max-height: 25%;
  padding: 1.25em 0.625em;

  font-size: 1rem;

  .locked-icon {
    fill: #F6A623;
    height: 18px;
    flex: 0 0 18px;
  }
`;

const FooterContent = styled.div`
  flex-grow: 1;
  text-align: left;
  padding: 0 0.5em;
  overflow: hidden;

  h1 {
    color: ${colors.shadeDark};
    font-size: 1em;
    font-weight: 500;
    letter-spacing: -0.02em;
    margin: 0;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  h2 {
    display: inline;
    letter-spacing: -0.03em;
    color: ${colors.shadeMediumDark};
    font-size: 0.75em;
    font-weight: 500;
    margin: 0;
  }

  .repeated {
    fill: ${colors.gold};
    height: 14px;
    width: 14px;
    margin-right: 3px;
    vertical-align: sub;
  }
`;

const AreaTitle = styled.h2`
  text-transform: capitalize;
`;

const ArticleMarker = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 85%;
  width: 50px;
  height: 40px;
  z-index: 2;
`;

const ActivityCard = (props) => {
  const {
    item = {},
    onOpen,
    onShareToUnlock,
    onMarkAsComplete,
    goToPrices,
    cardType,
    width,
    show,
    context,
    style,
    t,
  } = props;
  const {
    activityIndex,
    completed,
    shareable,
    touched,
    reinforced,
    area_id: areaId,
    name,
    changed,
  } = item;

  const locked = prop('locked', item) || props.locked;

  return (
    <BaseCard
      onClick={!touched && !locked ? onOpen : null}
      width={width}
      style={style}
      show={show}
    >
      {cardType === 'article' && <ArticleMarker />}
      <Background src={path(['thumbnails', 'size2'], item) || item.thumbnail}>
        <CardOverlay
          locked={locked}
          completed={completed}
          shareable={shareable}
          touched={touched}
          onOpen={onOpen}
          onShareToUnlock={onShareToUnlock}
          onMarkAsComplete={onMarkAsComplete}
          goToPrices={goToPrices}
          context={context}
          activityIndex={activityIndex}
          t={t}
        />
        {changed
          && <ChangedActivityOverlay>{t('CHANGED_ACTIVITY')}</ChangedActivityOverlay>}
      </Background>
      <Footer>
        <AreaIcon areaId={areaId} />
        <FooterContent onClick={!locked ? onOpen : null}>
          <h1>{name}</h1>
          {reinforced
            ? (
              <h2>
                <Icon icon="star" size={20} className="repeated" />
                {t('REPEATED_ACTIVITY')}
              </h2>
            )
            : <AreaTitle>{t(`common:AREA_${AreaNameById[areaId]}`)}</AreaTitle>}
        </FooterContent>
        {locked ? <Icon icon="padlock" className="locked-icon" /> : null}
        {changed && <ChangedIcon />}
      </Footer>
    </BaseCard>
  );
};

ActivityCard.propTypes = {
  width: PropTypes.number,
  show: PropTypes.bool,
  locked: PropTypes.bool,
  cardType: PropTypes.string,
  item: PropTypes.shape({
    locked: PropTypes.bool,
    completed: PropTypes.bool,
    shareable: PropTypes.bool,
    touched: PropTypes.bool,
    reinforced: PropTypes.bool,
  }),
  context: PropTypes.string,
  onOpen: PropTypes.func.isRequired,
  style: PropTypes.object,
  onShareToUnlock: PropTypes.func,
  goToPrices: PropTypes.func,
  onMarkAsComplete: PropTypes.func,
  t: PropTypes.func,
};

ActivityCard.defaultProps = {
  width: null,
  show: false,
  cardType: '',
  locked: false,
  style: {},
  item: {},
  context: '',
  onShareToUnlock: () => { },
  goToPrices: () => { },
  onMarkAsComplete: () => {},
  t: () => { },
};

export default withTranslation([Namespaces.DAP, Namespaces.COMMON, Namespaces.CONTENT])(ActivityCard);
