import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Formsy from 'formsy-react-2';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import HealthInterestsGrid from '../shared/HealthInterestsGrid';
import Button from '../shared/buttons/MainButton';

import Modal from './NeoModal';
import { colors, breakPoints } from '../../styles';
import Icon from '../shared/Icon';
import withHealthInterests from '../../containers/HOCs/HealthInterestHOC';
import { Families } from '../../data/ducks';
import { formatHealthInterests } from '../../lib/utils';
import EventReporter, { Events } from '../../lib/EventReporter';


const Form = styled(Formsy.Form)`
  max-width: 620px;
`;

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;

  margin: auto;

  fill: ${colors.white};

    #icon {
      height: 24px;
      width: 24px;
      box-sizing: border-box;
      margin-right: 6px;
      vertical-align: middle;
    }

`;

const Header = styled.div`
  display: flex;
  justify-content: center;

  font-size: 18px;
  background-color: ${colors.health};

  min-height: 75px;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;

  color: #FFFFFF;
  text-align: center;
  font-weight: 500;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  h3, h6 {
    margin: 0;
  }

  h3 {
    font-size: 24;
  }

  h6 {
    font-size: 16px;
    opacity: 0.5;
  }
`;

const Description = styled.div`
  color: ${colors.shadeDark};
  font-weight: 500;
  font-size: 18px;
  max-width: 680px;
  text-align: center;
  margin: 16px;

  ${breakPoints.qLargeTablet} {
    margin: 16px auto;
  }
`;

const InterestContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  max-width: 850px;
  margin: auto;
  padding: 10px 30px;

  overflow-y: scroll;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;

  margin: 40px 0;

  ${breakPoints.qLargeTablet} {

  }
`;

const Title = styled.h3`
  text-transform: capitalize;
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'HealthInterestsModal',
  data: {
  },
}

*/

class HealthInterestsModal extends React.Component {
  state = { submitEnabled: false };

  componentDidMount = () => {
    const source = this.props.context;
    EventReporter.view(Events.HEALTH_INTEREST({ source }));
  }

  onSubmit = ({ interests }) => {
    const formatedInterests = formatHealthInterests(interests);
    this.props.updateHealthInterest(this.props.babyId, formatedInterests);
    this.props.onClose();
  }

  setValue = ({ interests }) => {
    const selectedInterests = interests ? interests.filter(i => i.selected === true) : [];
    if (selectedInterests.length >= 4) {
      this.setState({ submitEnabled: true });
    } else {
      this.setState({ submitEnabled: false });
    }
  }

  render() {
    const { activeBaby, interests, t } = this.props;
    return (
      <Wrapper width={760} closeButtonColor="white" roundedCorners>
        {() => (
          <Fragment>
            <Header className="flex-center">
              <Icon id="icon" size={40} icon="health" className="icon-big" />
              <TitleContainer>
                <Title>{t('common:AREA_HEALTH')}</Title>
                <h6 className="opacity-mid">{activeBaby.age_group} {t('common:MONTH', { count: 2 })}</h6>
              </TitleContainer>
            </Header>
            <Description>{t('MODAL_HEALTH.PERSONALIZE_HEALTH')}</Description>
            <InterestContainer>
              <Form
                onChange={val => this.setValue(val)}
                onSubmit={values => this.onSubmit(values)}
              >
                <HealthInterestsGrid interests={interests} />
                <Footer>
                  <Button
                    type="submit"
                    color={this.state.submitEnabled ? 'green' : 'disabled'}
                    disabled={!this.state.submitEnabled}
                    maxWidth={300}
                  >
                    {t('common:SAVE')}
                  </Button>
                </Footer>
              </Form>
            </InterestContainer>
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

HealthInterestsModal.propTypes = {
  updateHealthInterest: PropTypes.func,
  babyId: PropTypes.number,
  activeBaby: PropTypes.object,
  interests: PropTypes.array,
  t: PropTypes.func,
  onClose: PropTypes.func,
};

HealthInterestsModal.defaultProps = {
  updateHealthInterest: () => {},
  babyId: 0,
  activeBaby: {},
  interests: [],
  t: () => {},
  onClose: () => { },
};

const mapStateToProps = state => ({
  activeBaby: Families.Selectors.activeBaby(state),
});

const mapDispatchToProps = dispatch => ({
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation([Namespaces.MODALS, Namespaces.COMMON]),
  withHealthInterests,
)(HealthInterestsModal);

