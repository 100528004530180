import BaseApi, { BaseApiV3, localeFlag } from './BaseApi';

export const getActivity = ({ activityId, babyId, planId }) => BaseApi
  .get(`/activities/${activityId}?baby_id=${babyId}&programme_id=${planId}&${localeFlag()}`);
export const getCustomActivity = ({ activityId, babyId }) => BaseApi
  .get(`/babies/${babyId}/classroom_feeds/custom_activity?activity_id=${activityId}&${localeFlag()}`);

export const getArticle = ({ id, babyId }) => BaseApi.get(`/articles/${id}?${localeFlag()}&baby_id=${babyId}`);
export const getUnTokenArticle = id => BaseApi.get(`/articles/${id}/external_show?${localeFlag()}`);
export const postArticle = (articleId, instanceId) => BaseApi.post(`/articles/${articleId}/read?instance_id=${instanceId}`);
export const postArticleMarkAsRead = ({ articleId, instanceId, babyId }) => BaseApiV3.post(`/articles/${articleId}/read?instance_id=${instanceId}&baby_id=${babyId}`);
export const getSlideshow = id => BaseApi.get(`/slideshows/${id}?${localeFlag()}`);

export const fav = {
  activities: id => BaseApi.post(`/activities/${id}/fav`),
  articles: id => BaseApi.post(`/articles/${id}/fav`),
  slideshows: id => BaseApi.post(`/slideshows/${id}/fav`),
};

export const unfav = {
  activities: id => BaseApi.post(`/activities/${id}/unfav`),
  articles: id => BaseApi.post(`/articles/${id}/unfav`),
  slideshows: id => BaseApi.post(`/slideshows/${id}/unfav`),
};

export default {
  getActivity,
  getCustomActivity,
  getUnTokenArticle,
  getArticle,
  getSlideshow,
  fav,
  unfav,
  postArticleMarkAsRead,
};
