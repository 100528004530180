import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';

import FullModal from './FullModal';
import { colors, breakPoints } from '../../styles';
import bebeImage from '../../images/BabySerious.svg';
import Texture from '../../images/Texture2.png';
import Button from '../shared/buttons/MainButton';

const ModalContainer = styled(FullModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-direction: row;
  flex-wrap: wrap;

  padding: 20px;
  background-color: ${colors.physical};
  background-image: url(${Texture});

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${breakPoints.smallTablet} {
    flex-direction: column;
  }
`;

const Title = styled.span`
  color: ${colors.white};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  line-height: 33px;
  margin: 20px 0;
`;

const Description = styled.p`
  color: ${colors.white};
  font-size: 16px;
  letter-spacing: -0.36px;
  text-align: center;
  line-height: 21px;
  margin: 20px 0;
  max-width: 620px;
`;

const BebeImg = styled.img.attrs({ src: bebeImage })`
  width: 170px;
  height: auto;
  margin: 20px 0;

  ${breakPoints.smallTablet} {
    width: 270px;
  }
`;

/*
  Dispatch example:

  {
    type: 'MODAL_OPENED',
    name: 'NoFamilyModal',
    data: {
    },
  }

  */

const NoFamilyModal = ({ t }) => (
  <ModalContainer showCloseButton={false}>
    {({ closeModal }) => (
      <Fragment>
        <Title>{t('NO_FAMILY_MODAL.TITLE')}</Title>
        <BebeImg />
        <Description>{t('NO_FAMILY_MODAL.DESCRIPTION')}</Description>
        <Button color="green" maxWidth={300} onClick={closeModal}>{t('common:CONTINUE')}</Button>
      </Fragment>
    )}
  </ModalContainer>
);

NoFamilyModal.propTypes = {
  t: PropTypes.func,
};

NoFamilyModal.defaultProps = {
  t: () => { },
};

export default withTranslation([Namespaces.MODALS, Namespaces.COMMON])(NoFamilyModal);
