import styled from 'styled-components';
import Formsy from 'formsy-react-2';
import { Logo } from '../components/LeftInfoContent/styles';

const ViewWrapper = styled.div.attrs({ className: 'auth-view' })`
  display: grid;
  height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
  min-height: calc(100vh - 100px);
  width: 100%;
  padding: 0;
  margin: auto;
  z-index: 1;
  overflow-y: scroll;

  .new-input {
    label {
      display: block;
      margin-bottom: 10px;
      font-family: "Proxima Nova";


      color: #333;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;

      ${({ theme }) => theme.breakPoints.desktop} {
        color: #404040;
        font-size: 20px;
        font-weight: 600;
        line-height: 21px;
        margin-bottom: 20px;
      }

    }

    input {
      border: 1px solid #E6E7E8;
      border-radius: 4px;
      height: 50px;
      background: transparent;
      outline: none;
      color: #000000;
      font-family: "Proxima Nova";
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 35px;

      &::placeholder {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
      }

      &:focus {
        box-shadow: none;
        background-color: transparent;
      }

      ${({ theme }) => theme.breakPoints.desktop} {
        font-size: 18px;
        line-height: 21px;
      }
    }

    .input-error {
      background: no-repeat;
      color: red;
      margin: 10px 0px 0;
      padding: 0;
      font-size: 12px;
    }
  }
`;

const LogoImage = styled(Logo)`
  width: 90px;
  height: auto;
  margin-bottom: 53px;
  display: block;

  ${({ theme }) => theme.breakPoints.desktop} {
    display: none;
  }
`;

const Title = styled.span`
  display: block;
  color:#0086D8;
  text-align: center;
  font-size: 20px;
  font-family: "Proxima Nova";
  font-weight: 700;
  line-height: 46px;

  ${({ theme }) => theme.breakPoints.desktop} {
    font-size: 32px;
  }
`;

const Subtitle = styled.span`
  display: block;
  color: #404040;
  text-align: center;
  font-size: 14px;
  font-family: "Proxima Nova";
  line-height: 21px;
  margin-top: 10px;

  ${({ theme }) => theme.breakPoints.desktop} {
    font-size: 20px;
  }
`;

const Form = styled(Formsy.Form)`
  width: 100%;
  margin-top: 20px;
`;

const InlineButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  margin-top: 0;
  justify-items: center;

  .btnSubmit {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  ${({ theme }) => theme.breakPoints.desktop} {
    margin-top: 100px;
    .btnSubmit {
      width: 320px;
      max-width: 320px;
      min-width: 320px;
    }
  }
`;

const Note = styled.span`
  display: block;
  margin: 2px 10px 10px 10px;

  color: #A0B3BE;
  font-size: 16px;
  font-family: "Proxima Nova";
  line-height: 21px;
  text-align: center;

  button,
  a {
    font-size: 16px;
    margin-top: 5px;
  }

  ${({ theme }) => theme.breakPoints.desktop} {
    font-size: 18px;
    button,
    a {
      font-size: 18px;
    }
  }
`;

const OptionButton = styled.button`
  font-size: 14px;
  color: #0086d8;
  letter-spacing: 0;
  border: none;
  background: none;
  text-decoration-line: underline;
  margin-top: 20px;
  cursor: pointer;
  font-family: "Proxima Nova";
  font-weight: 600;
  line-height: 21px;

  ${({ theme }) => theme.breakPoints.desktop} {
    font-size: 18px;
  }

`;

const Link = styled.button`
  font-size: 14px;
  color: #0086d8;
  letter-spacing: 0;
  border: none;
  background: none;
  text-decoration-line: underline;
  margin-top: 20px;
`;

const Separator = styled.hr`
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
  border: 0;
  margin: 20px auto 30px auto;
`;


const FormContent = styled.div.attrs({
  className: 'FormContent',
})`
  min-height: 100%;
  width: 100%;
  max-width: 610px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  grid-template-areas: "body"
                        "foot";
  grid-gap: 10px;
  /* max-height: 100%; */
  overflow-y: scroll;

  ${({ theme }) => theme.breakPoints.desktop} {
    grid-template-rows: max-content max-content;
    max-height: initial;
  }
`;

const FormContentBody = styled.div`
  grid-area: body;
`;

const FormContentFoot = styled.div`
  grid-area: foot;
`;

const Label = styled.label.attrs({
  className: 'InputLabel',
})`
  display: block;
  margin-bottom: 10px;
  font-family: "Proxima Nova";


  color: #333;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;

  ${({ theme }) => theme.breakPoints.desktop} {
    color: #404040;
    font-size: 20px;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 20px;
  }
`;


export default ViewWrapper;
export {
  LogoImage,
  Title,
  Subtitle,
  Form,
  InlineButtons,
  Note,
  OptionButton,
  Link,
  Separator,
  FormContent,
  FormContentBody,
  FormContentFoot,
  Label,
};
