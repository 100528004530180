import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { parse } from 'query-string';
import { bindActionCreators as bind } from 'redux';
import { PAYMENT_PATH_PP_KEY, QUERY_PARAM_TO_SHOW_MODAL } from 'components/premiumProcessModal/values';
import { path, find } from 'ramda';
import { Creators, Selectors } from '../data/ducks/babiesAndFamilies';
import { Creators as ModalsCreators } from '../data/ducks/modals';
import EventReporter, { Events } from '../lib/EventReporter';
import { APP_SECTIONS_PATHS } from '../router/AuthGuardedRoutes';
import { LOCALSTORAGE_BABY_STATUS } from '../shared-values';

class Families extends Component {
  state = { selectedMember: {} };

  componentDidMount = () => {
    const familyId = path(['match', 'params', 'id'], this.props);
    // eslint-disable-next-line
    const familyExists = find(f => f.id == familyId, this.props.families);
    if (familyExists) {
      this.setFamily(familyId);
    } else if (!familyExists && familyId) {
      this.props.push(APP_SECTIONS_PATHS.FAMILIES);
    }

    if (localStorage.getItem(LOCALSTORAGE_BABY_STATUS) === 'classroomsBaby') {
      this.props.openModal({ name: 'ClassroomsInviteCodeModal' });
    }
  }

  setFamily = (familyId) => {
    this.props.familySelected(Number(familyId));
    this.setState({ selectedMember: this.props.members[0] });
  }

  setMember = (memberIndex) => {
    this.setState({ selectedMember: this.props.members[memberIndex] });
  }

  selectFamily = (familyId) => {
    this.props.push(`${APP_SECTIONS_PATHS.FAMILIES}/${familyId}`);
  }

  openPremiumFeature = (callback, source) => {
    if (this.props.activeFamily.is_premium) {
      callback();
    } else if (this.props.activeFamily.self_owner) {
      EventReporter.action(Events.TAP_CALL_TO_ACTION({ source }));
      window.localStorage.setItem(PAYMENT_PATH_PP_KEY, source);

      const queryParams = parse(window.location.search);
      queryParams[QUERY_PARAM_TO_SHOW_MODAL] = true;

      this.props.push({
        search: new URLSearchParams({
          ...queryParams,
        }).toString(),
      }, { source });

    } else {
      const familyName = this.props.activeFamily.name;
      this.props.openModal({ name: 'PremiumBrokeModal', data: { familyName } });
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.children({
          ...this.props,
          ...this.state,
          selectFamily: this.selectFamily,
          setMember: this.setMember,
          openPremiumFeature: this.openPremiumFeature,
        })}
      </Fragment>
    );
  }
}

Families.propTypes = {
  children: PropTypes.func,
  members: PropTypes.array,
};
Families.defaultProps = {
  children: () => null,
  members: [],
};

const mapStateToProps = state => ({
  families: Selectors.getFamilies(state),
  activeFamily: Selectors.activeFamily(state),
  defaultFamily: Selectors.defaultFamily(state),
  activeFamilyId: state.families.activeFamilyId,
  activeFamilyIsDefault: Selectors.activeFamilyIsDefault(state),
  members: Selectors.membersByFamilyId(state, state.families.activeFamilyId),
  activeFamilyMembership: Selectors.activeFamilyMembership(state),
  iOwnFamily: Selectors.iOwnFamily(state),
  myRole: Selectors.myRole(state),
  familyPhotoLoading: state.families.UI.familyPhoto ? state.families.UI.familyPhoto.loading : false,
  activeBabyId: state.families.activeBabyId,
});

const mapDispatchToProps = dispatch => bind({
  openModal: ModalsCreators.openModal,
  setDefaultFamily: Creators.setDefaultFamilyRequested,
  deleteFamily: Creators.deleteFamilyRequested,
  deleteMember: Creators.deleteFamilyMemberRequested,
  familySelected: Creators.selectFamilyAndDefaultBaby,
  setMemberPermissions: Creators.setMemberPermissionsRequested,
  updateFamily: Creators.updateFamilyRequested,
  push,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Families);
