import { Reduxsauce, DEEP } from '../redux-sauce';

export const Types = Reduxsauce.createTypes(`
  modalOpened
  modalClosed
  modalSubmited
`);

export const Creators = {
  /** @param{String} name */
  openModal: ({ name, data, source }) => ({ type: Types.modalOpened, name, data, source }),
  closeModal: payload => ({ type: Types.modalClosed, name: payload ? payload.name : '' }),
  submitModal: ({ name, data }) => ({ type: Types.modalSubmited, name, data }),
};

// ------- State -------- //
export const InitialState = Reduxsauce.createState({
  name: '',
  data: {},
  source: '',
});

export const Reducer = Reduxsauce.createReducer(InitialState, {
  [Types.modalOpened]: (state, action) =>
    state.merge({ name: action.name, data: action.data, source: action.source }, DEEP),
  [Types.modalClosed]: (state, action) =>
    state.merge({ name: undefined, data: undefined }, DEEP),
  [Types.modalSubmited]: (state, action) =>
    state.merge({ name: undefined, data: action.data }, DEEP),
});

