import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bindActionCreators as bind, compose } from 'redux';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../../values/i18n';

import { Creators } from '../../../data/ducks/payments';

import GiftcardType from './GiftcardType';
import ChooseGift from './ChooseGift';
import PaymentForm from './PaymentForm';
import CongratulationsView from './Congratulations';
import LoadingOverlay from '../../shared/LoadingOverlay';

import { colors, breakPoints } from '../../../styles';
// import { getVisitorIPCountry } from '../../../lib/utils';
import ForSomeoneElseView from '../ForSomeoneElseView/ForSomeoneElseToView';
import ForSomeoneElseFromView from '../ForSomeoneElseView/ForSomeoneElseFromView';
import { getVisitorIPCountry } from '../../../lib/utils';

const GiftCardsContainer = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 25px;

  color: ${colors.white};
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0;
  text-align: left;

  height: 55px;
  background: ${colors.newKinedu};
`;

const Content = styled.div`
  height: calc(100vh - 55px);
  box-sizing: border-box;

  ${breakPoints.smallTablet} {
    height: auto;
  }

  button {
    border-radius: 30px;
  }
`;

const firstSteps = [
  {
    header: 'SEND_GIFTCARD.HEADER',
    view: GiftcardType,
  },
  {
    header: 'CHOOSE_GIFT.HEADER',
    view: ChooseGift,
  },
];

const otherSteps = [
  {
    header: 'PERSONALIZE_GIFT.HEADER',
    view: ForSomeoneElseView,
  },
  {
    header: 'MODAL_PAYMENT.TITLE',
    view: PaymentForm,
  },
];

const GIFTCARD_STEPS = {
  self: [
    ...firstSteps,
    {
      header: 'MODAL_PAYMENT.TITLE',
      view: PaymentForm,
    },
  ],
  other: [
    ...firstSteps,
    ...otherSteps,
  ],
};

class GiftCardsView extends React.Component {
  state = {
    recipientData: {
      isForMe: false,
      name: '',
      email: '',
      message: '',
      plan: null,
    },
    currentStep: 0,
    steps: GIFTCARD_STEPS,
  };

  componentDidMount = () => {
    this.props.startGiftcardFlow();

    const { authenticated } = this.props;
    if (!authenticated) {
      const stepsUpdated = {
        self: GIFTCARD_STEPS.self,
        other: [
          ...firstSteps,
          {
            header: 'PERSONALIZE_GIFT.HEADER',
            view: ForSomeoneElseFromView,
          },
          ...otherSteps,
        ],
      };

      this.setState({ steps: stepsUpdated });
    }
    getVisitorIPCountry(); // pre-load user's IP country
  }

  onSubmit = () => {
    const { card, sku, isForMe, message } = this.state.recipientData;
    const { userName, userEmail } = this.props;

    let args = {
      productType: 'giftcard',
      sku,
      card,
      forSelf: isForMe,
      price: sku === 'giftcard_kinedu_premium_12' ? 3999 : 999,
    };

    const { email: toEmail, name: toName, fromName, fromEmail } = this.state.recipientData;
    if (isForMe) {
      args = { ...args, toEmail, toName: userName };
    } else {
      args = {
        ...args, toEmail, toName, fromEmail: userEmail || fromEmail, fromName: userName || fromName, message,
      };
    }

    this.props.postPaymentRequested(args);
  }

  prevStep = () => {
    this.setState({ ...this.state, currentStep: this.state.currentStep - 1 });
  }

  nextStep = (values, cb) => {
    const stepCount = this.state.steps[this.state.recipientData.isForMe ? 'self' : 'other'].length;
    this.setState({
      recipientData: { ...this.state.recipientData, ...values },
      currentStep: this.state.currentStep + (this.state.currentStep + 1 < stepCount),
    }, cb);
  }

  render() {
    const stepsByReceiver = this.state.steps[this.state.recipientData.isForMe ? 'self' : 'other'];
    const CurrentStep = stepsByReceiver[this.state.currentStep].view;
    const { loading, success, t, i18n } = this.props;
    const lang = i18n.language;

    return (
      <GiftCardsContainer>
        <Header>{t(stepsByReceiver[this.state.currentStep].header)}</Header>
        <LoadingOverlay loading={loading} />
        <Content>
          {success
            ? <CongratulationsView onClose={this.props.onClose} />
            : (
              <CurrentStep
                onSubmit={this.nextStep}
                onPrevious={this.prevStep}
                onClose={this.props.onClose}
                isForMe={this.state.recipientData.isForMe}
                recipientData={this.state.recipientData}
                userEmail={this.props.userEmail}
                onPurchase={this.onSubmit}
                t={t}
                lang={lang}
              />
            )}
        </Content>
      </GiftCardsContainer>
    );
  }
}

GiftCardsView.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
};

GiftCardsView.defaultProps = {
  t: () => {},
  i18n: {},
};

const mapStateToProps = state => ({
  userEmail: state.user.email,
  userName: state.user.name,
  loading: state.payments.loading,
  success: state.payments.success,
  authenticated: state.auth.authenticated,
});

const mapDispatchToProps = dispatch => bind({
  postPaymentRequested: Creators.postStripePaymentRequested,
  startGiftcardFlow: Creators.startGiftcardFlow,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation([Namespaces.MODALS]),
)(GiftCardsView);
