import React from 'react';
import RestorePasswordComponent from 'components/Auth/components/RestorePassword';
import AuthViewWrapper, {
  AuthViewContent,
  LeftSideCircle,
  LeftSideCircle2,
  LeftSideContent,
  LeftSideImage,
  LeftSideSquare,
  LeftSideSquare2,
  RightSideContent,
} from '../styles';



const AuthRestorePasswordView = () => (
  <AuthViewWrapper>
    <AuthViewContent>
      <LeftSideContent>
        <LeftSideImage />
        <LeftSideCircle />
        <LeftSideCircle2 />
        <LeftSideSquare />
        <LeftSideSquare2 />
      </LeftSideContent>
      <RightSideContent>
        <RestorePasswordComponent />
      </RightSideContent>
    </AuthViewContent>
  </AuthViewWrapper>
);

AuthRestorePasswordView.propTypes = {};

AuthRestorePasswordView.defaultProps = {};

export default AuthRestorePasswordView;
