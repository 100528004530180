import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Namespaces } from '../../values/i18n';
import { genderAdposition, genderPronoun } from '../../lib/utils';

import { colors, breakPoints } from '../../styles';
import Button from '../shared/buttons/MainButton';

import prematureImage from '../../images/PrematureBaby.svg';
import texture from '../../images/Texture1_t.png';

const PrematureImg = styled.img.attrs({ src: prematureImage })`
  display: block;
  height: 150px;
  width: auto;
  margin: auto;
`;

const ImagesContainer = styled.div``;

// const GreenButton = {
//   color: colors.white,
//   backgroundColor: colors.cognitive,
//   minHeight: '40px',
// };

const Title = styled.h2`
  color: ${colors.white};
  font-size: 30px;
  font-weight: bold;
  text-align: center;

  margin: 20px;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background-image: url(${texture});

  padding: 30px;
  box-sizing: border-box;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${breakPoints.smallTablet} {
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;

const Description = styled.p`
  color: ${colors.white};
  font-size: 18px;
  font-weight: 500;
  text-align: center;

  margin: 0 auto;
`;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;

  max-height: 768px;
  max-width: 1024px;
  overflow: scroll;
  background-color: ${colors.kinedu};
`;

const Header = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 55px;

  margin: 0;
  background-color: ${colors.physical};

  color: ${colors.white};
  font-size: 22px;
  font-weight: 500;
`;

const PrematureMessage = ({
  onFinishClicked, baby1, baby2, devAge, t, twins,
}) => {
  const validatedDevAge = devAge < 1 ? '0 - 1' : devAge;
  return (
    <Frame>
      <Header>{t('PREMATURE_VIEW.HEADER')}</Header>
      <Content>
        <Title>
          {t('PREMATURE_VIEW.TITLE', {
            context: twins ? 'twins' : '',
            baby1: baby1.name,
            baby2: baby2 ? baby2.name : '',
          })}
        </Title>
        <ImagesContainer>
          <PrematureImg />
        </ImagesContainer>
        <Description>
          {t('PREMATURE_VIEW.DESCRIPTION', {
            context: twins ? 'twins' : '',
            baby1: baby1.name,
            baby2: baby2 ? baby2.name : '',
            adpos: genderAdposition(baby1.gender),
          })}
        </Description>
        <Title>{t('PREMATURE_VIEW.DEV_AGE', { devAge: validatedDevAge, baby1: baby1.name, count: devAge })}</Title>
        <Description>
          {t('PREMATURE_VIEW.FINAL_NOTE', { context: twins && 'twins', pronoun: genderPronoun(baby1.gender) })}
        </Description>
        <Button onClick={onFinishClicked} color="green" maxWidth={300} style={{ margin: '20px 0' }}>
          {t('common:FINISH')}
        </Button>
      </Content>
    </Frame>
  );
};

PrematureMessage.propTypes = {
  baby1: PropTypes.object,
  baby2: PropTypes.object,
  devAge: PropTypes.number,
  twins: PropTypes.bool,
  onFinishClicked: PropTypes.func,
  t: PropTypes.func,
};

PrematureMessage.defaultProps = {
  baby1: {},
  baby2: {},
  devAge: 0,
  twins: false,
  onFinishClicked: () => { },
  t: () => { },
};

export default withTranslation([Namespaces.OB, Namespaces.COMMON])(PrematureMessage);
