import React from 'react';
import styled from 'styled-components';

import Modal from '../../components/modals/NeoModal';
import { colors } from '../../styles';

import ForgotPasswordView from '../../components/landing/ForgotPasswordView';

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  background-color: ${colors.shadeLight};
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'AuthModal',
  data: { authType: <string> },
}

*/

const ForgotPasswordModal = () => (
  <Wrapper width={620} roundedCorners>
    {() => (
      <ForgotPasswordView />
    )}
  </Wrapper>
);

export default ForgotPasswordModal;
