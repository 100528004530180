import Immutable from 'seamless-immutable';
import { path } from 'ramda';
import { createActions, createReducer } from 'reduxsauce';
import { ascendentOrderByProperty } from '../../lib/utils';

const INITIAL_STATE = Immutable({
  activeArea: 1,
  content: [],
  loading: false,
  catalogFilters: {},
});

export const { Creators, Types } = createActions({
  fetchTagsRequested: ['areaId', 'forAge'],
  fetchTagsSuccess: ['tags'],
  fetchTagsError: null,
  setActiveArea: ['areaId'],
  fetchActivitiesRequested: ['tagId', 'forAge', 'fromAge'],
  fetchActivitiesSuccess: ['activities'],
  fetchActivitiesError: null,
  fetchContentRequested: ['tagId', 'forAge', 'fromAge', 'page', 'perPage'],
  fetchContentSuccess: ['content', 'meta'],
  fetchContentError: null,
  fetchArticlesRequested: ['tagId', 'forAge', 'fromAge'],
  fetchArticlesSuccess: ['articles'],
  fetchArticlesError: null,
  // catalog filters
  fetchCatalogFiltersRequested: null,
  fetchCatalogFiltersSuccess: ['catalogFilters'],
  fetchCatalogFiltersError: null,
});

export const Selectors = {
  getContent: (state) => {
    const mappedContent = state.catalog.content.map(c => ({ ...c, type: c.type.toLowerCase() }));
    return mappedContent;
  },
  getMetaDataValue: (state, value) => path(['catalog', 'meta', value], state),
};

export const Reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_TAGS_REQUESTED]: state => state.merge({ loading: true }),
  [Types.FETCH_TAGS_SUCCESS]: (state, { tags }) => {
    const { skills = [], interests = [], id } = tags;
    const sortedSkills = skills.sort((a, b) => ascendentOrderByProperty(a, b, 'title'));
    const sortedInterests = interests.sort((a, b) => ascendentOrderByProperty(a, b, 'title'));
    return state.merge({
      skills: sortedSkills,
      interests: sortedInterests,
      activeArea: id,
      loading: false,
    });
  },
  [Types.SET_ACTIVE_AREA]: (state, { areaId }) => state.merge({ activeArea: areaId }),
  [Types.FETCH_CONTENT_REQUESTED]: state => state.merge({ loading: true }),
  [Types.FETCH_CONTENT_SUCCESS]: (state, { content, meta }) =>
    state.merge({ content, meta, loading: false }),
  // catalog filters
  [Types.FETCH_CATALOG_FILTERS_REQUESTED]: (state => state),
  [Types.FETCH_CATALOG_FILTERS_SUCCESS]: (state, { catalogFilters }) => state
    .merge({ catalogFilters }),
  [Types.FETCH_CATALOG_FILTERS_ERROR]: (state => state.merge({ catalogFilters: {} })),
});
