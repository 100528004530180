/* eslint-disable import/no-cycle */
import { useState } from 'react';
import { postResetPassword } from 'api/auth';
import { setAuth } from 'data/epics/auth';

function useRestorePassword() {
  const [response, setResponse] = useState(null);
  const [isRestoring, setIsRestoring] = useState(false);
  const [errors, setErrors] = useState(null);
  const [succesfull, setSuccesfull] = useState(null);
  const [finished, setFinished] = useState(null);

  const restore = (password, token) => {
    setIsRestoring(true);
    setSuccesfull(null);
    setErrors(null);
    setFinished(null);
    return postResetPassword({
      password,
      token,
    })
      .then(({ data: responseData }) => {
        if (responseData?.data?.token && responseData?.data?.user?.id) {
          setAuth(responseData?.data);
          setSuccesfull(true);
          setResponse(responseData);
        }

        return responseData;
      })
      .catch((error) => {
        setErrors(error.response || { message: 'There were an error.' });
        return error;
      })
      .finally(() => {
        setFinished(true);
        setIsRestoring(false);
      });
  };

  return {
    succesfull,
    response,
    isRestoring,
    errors,
    restore,
    finished,
  };
}

export default useRestorePassword;
