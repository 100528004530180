import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { breakPoints } from '../../../styles';

const Percentage = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;

  ${breakPoints.smallTablet} {
    font-size: 14px;
  }
`;

const InfoSquare = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ color }) => color};
  margin-right: 3px;
`;

const GraphInfo = ({ className, children, color }) => (
  <Percentage className={className}>
    <InfoSquare color={color} />
    {children}
  </Percentage>
);

GraphInfo.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  color: PropTypes.string,
};
GraphInfo.defaultProps = {
  className: '',
  children: null,
  color: '',
};

export default GraphInfo;
