import { push } from 'connected-react-router';
import { Observable } from 'rxjs/Observable';
import { Modals, BabiesAndFamilies, Payments } from '../../ducks';

const resetStateOnPremium = [
  { type: 'RESET_DAP' },
  { type: 'RESET_CONTENT' },
  { type: 'RESET_PAST_PLANS' },
  BabiesAndFamilies.Creators.fetchFamiliesRequested(),
];

export const subscriptionPaymentSuccess = action$ =>
  action$
    .ofType(Payments.Types.POST_PAYMENT_SUCCESS)
    .pluck('productType')
    .filter(p => p === 'product')
    .mergeMap(() => Observable.concat(
      resetStateOnPremium,
      Observable.of('')
        .delay(2500)
        .mergeMap(() => [
          Modals.Creators.closeModal({ name: 'ThankYouPurchaseModal' }),
          push('/dap/current/1'),
        ]),
    ));

export const giftcardRedeemSuccess = action$ =>
  action$
    .ofType(Payments.Types.REDEEM_GIFTCARD_SUCCESS)
    .mergeMap(() => ([
      ...resetStateOnPremium,
      Modals.Creators.closeModal({ name: 'RedeemModal' }),
      push('/dap/current/1'),
    ]));
