// REACT
import React from 'react';
import { PropTypes } from 'prop-types';

// EVENT REPORT
// import EventReporter, { Events } from 'lib/EventReporter';

// LEGO
import { Modal } from 'lego-dist';

// ReactPlayer
import ReactPlayer from 'react-player';

// RESOURCES
import Icon from 'components/shared/Icon';

// STYLES
import styles from './welcome-play-modal.module.scss';

// TRANSLATIONS
import { Namespaces } from 'values/i18n';
import { useTranslation } from 'react-i18next';

const WelcomePlayModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation(Namespaces.MODALS);

  const handleEvent = () => {
    // EventReporter.action(Events.CLICK_PLAY_WELCOME_VIDEO());
  }

  const handleClose = () => {
    // EventReporter.action(Events.CLICK_DISMISS_WELCOME_VIDEO());
    onClose();
  }

  return (
    <Modal
      openModal={ isOpen }
      className={ styles.modal }
      roundedCorners
      onClosedModal={ onClose }
    >
      {
        () => (
          <div className={ styles['modal-container'] }>
            <button className={ styles['btn-close'] } onClick={ handleClose }>
              <Icon icon="close" size={16} color="white" />
            </button>

            <header className={ styles['modal-header']}>{ t('MODAL_PLAY.TITLE') } </header>

            <div className={ styles['modal-body'] }>
              <div className={ styles['img-kinedu'] } >
                <img src={ t('MODAL_PLAY.URL_IMG') } alt="plan-title-img" />
              </div>

              <div className={ styles['media'] } >
                <ReactPlayer
                  controls
                  url={ t('MODAL_PLAY.URL_VIDEO') }
                  height="100%"
                  width="100%"
                  style={{ display: 'flex' }}
                  config={{ file: { attributes: { controlsList: 'nodownload', poster: '', preload: 'none' } } }}
                  onStart={ () => handleEvent() }
                />
              </div>

              <div className={ styles['subheader'] }>
               { t('MODAL_PLAY.SUBTITLE_START') }
                <span className={ styles['subheader-badge'] } >
                { t('MODAL_PLAY.SUBTITLE_BADGE') }
                </span>
                { t('MODAL_PLAY.SUBTITLE_END') }
              </div>
            </div>

            <div className={ styles['modal-actions']}>
              <button
                className={ styles['modal-btn-primary'] }
                onClick={ handleClose }
              >
                { t('MODAL_PLAY.ACTIONS.START') }
              </button>
            </div>
          </div>
        )
      }
    </Modal>
  )
}

WelcomePlayModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default WelcomePlayModal;