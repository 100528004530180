import { path, difference } from 'ramda';
import Immutable from 'seamless-immutable';
import { createActions, createReducer } from 'reduxsauce';
import { Selectors as FamilySelectors } from './babiesAndFamilies';
import { tU, asyncTypeAndActionCreators as aTC } from '../../lib/utils';
import { DEEP } from '../redux-sauce';

/** EXPERIMENT
  * TODO: FIXME: Hay que poner un queue de pasos, esta muy opaco el step,
  * estaria mejor una fila state.pop('steps') o algo asi. Con esto se arma mucho mejor.
  * Pero por lo pronto, si se inicia en reminder, este redireccionara a ready etc.
  * Con el otro approach la vista es indistinta a esto, y solo se ve
  * queue = reminder -> ready -> etc, y el queue se puede persistir.
*/

export const {
  Types,
  Creators,
} = createActions({
  /** Starts the IA - use either baby or twin ids' */
  startIa: tU('babyId', 'twinIds'),
  endIa: null,
  ...aTC('initialAssessment', {
    requested: tU('babyId', 'withPercentiles'),
    success: tU('babyId', 'skills', 'areas', 'id'),
    error: null,
  }),
  ...aTC('skill', {
    requested: tU('babyId', 'skillId'),
    success: tU('babyId', 'skill'),
  }),
  ...aTC('masteredMilestones', {
    requested: ['skillId'],
    success: ['milestones'],
  }),

  setActiveTwinId: ['activeTwinId'],
  setAssessmentStep: ['step'],
  setAreaAnswered: ['areaId'],

  answerInitialMilestones: tU('assessmentId', 'skillId', 'answers', 'babyId'),
  answerInitialMilestonesSuccess: tU('skillId', 'activeMilestones', 'completedMilestones', 'percentile', 'babyId'),
  answerInitialMilestonesError: null,

  answerTwinInitialMilestones: tU('answers', 'twins', 'skillId'),
  answerTwinInitialMilestonesSuccess: tU('twinA', 'twinB', 'skillId'),

  setInitialSkillMastered: tU('assessmentId', 'skillId', 'baby'),

  ...aTC('healthInterests', {
    requested: ['babyId'],
    success: ['interests'],
  }),
  postHealthInterests: ['babyId', 'interests'],

  setUpdateFlag: null,
}, {
  prefix: '@IA/',
});

const INITIAL_STATE = Immutable({
  /** @property{reminder|welcome|about|ready|skills|healthInterests}  */
  step: 'welcome',
  /** @property{baby|twins} - OB type */
  type: undefined,

  /** Undefined if type !== twin */
  /** @property{array} - twin id's */
  twinIds: undefined,
  /** @property{array} - twin id's */
  activeTwinId: undefined,

  /** @property{number} - baby id's */
  babyId: undefined,
  /** @property{array} - anwered area id queue. */
  answeredAreaOrder: [],

  healthInterests: [],
});

const twins = (state) => {
  const {
    twinIds,
  } = state.IA;
  if (twinIds) {
    const [a, b] = twinIds;
    if (a && b) {
      return [{
        ...FamilySelectors.babyById(state, a),
        assessmentId: path(['IA', a, 'assessmentId'], state),
      },
      {
        ...FamilySelectors.babyById(state, b),
        assessmentId: path(['IA', b, 'assessmentId'], state),
      },
      ];
    }
  }
  return null;
};

// Extra ID is appended in case of families not being fetch yet. See issue.
const activeBaby = (state) => {
  const {
    activeTwinId,
    babyId,
    type,
  } = state.IA;
  if (type === 'twins') {
    return {
      ...FamilySelectors.babyById(state, activeTwinId),
      id: activeTwinId,
      assessmentId: path(['IA', activeTwinId, 'assessmentId'], state),
    };
  }
  return {
    ...FamilySelectors.babyById(state, babyId),
    id: babyId,
    assessmentId: path(['IA', babyId, 'assessmentId'], state),
  };
};


const areasDetails = (state) => {
  const {
    id: babyId,
  } = activeBaby(state);

  if (!state.IA[babyId]) {
    return null;
  }

  const {
    areas,
    skills,
  } = state.IA[babyId];
  return areas.map(area => ({
    ...area,
    skills: area.skills.map(id => skills[id]),
  }));
};

const getPendingSkillByArea = (state, areaId, babyId) => {
  const skills = Object.values(path(['IA', babyId, 'skills'], state));
  return skills.filter(skill => skill.area_id === areaId);
};

const assessmentFinished = (state) => {
  const {
    id: babyId,
  } = activeBaby(state);

  if (!state.IA[babyId]) {
    return undefined;
  }

  return Object.values(state.IA[babyId].skills)
    .filter(s => s.completed_milestones === null)
    .length === 0;
};

export const Selectors = {
  areasDetails,
  twins,
  activeBaby,
  assessmentFinished,
  getPendingSkillByArea,
};

const skillProgressReducer = ({
  babyId,
  skillId,
  percentile,
  completedMilestones,
}) => ({
  [babyId]: {
    skills: {
      [skillId]: {
        percentile,
        completed_milestones: completedMilestones,
        loading: false,
      },
    },
  },
});

const Handlers = {
  [Types.FETCH_INITIAL_ASSESSMENT_SUCCESS]: (state, {
    skills,
    areas,
    babyId,
    id: assessmentId,
  }) =>
    state.merge({
      [babyId]: {
        areas,
        assessmentId,
        skills,
      },
    }, DEEP),
  [Types.FETCH_SKILL_SUCCESS]: (state, {
    babyId,
    skill,
  }) =>
    state.merge({
      [babyId]: {
        skills: {
          [skill.id]: skill,
        },
      },
    }, DEEP),
  [Types.ANSWER_INITIAL_MILESTONES]: state => state.merge({
    loading: true,
  }, DEEP),
  [Types.ANSWER_TWIN_INITIAL_MILESTONES]: state => state.merge({
    loading: true,
  }, DEEP),
  [Types.ANSWER_INITIAL_MILESTONES_SUCCESS]: (state, action) => state.merge({
    ...skillProgressReducer(action),
    loading: false,
  }, DEEP),
  [Types.ANSWER_TWIN_INITIAL_MILESTONES_SUCCESS]: (state, {
    twinA,
    twinB,
    skillId,
  }) =>
    state.merge({
      ...skillProgressReducer({ ...twinA,
        skillId,
      }),
      ...skillProgressReducer({ ...twinB,
        skillId,
      }),
      loading: false,
    }, DEEP),
  [Types.START_IA]: (state, {
    babyId,
    twinIds,
  }) => state.merge({
    babyId,
    twinIds,
    activeTwinId: twinIds && twinIds[0],
    type: twinIds ? 'twins' : 'baby',
    step: 'welcome',
  }),
  [Types.SET_ACTIVE_TWIN_ID]: (state, {
    activeTwinId,
  }) => state.merge({
    activeTwinId,
  }),
  [Types.FETCH_MASTERED_MILESTONES_REQUESTED]: state =>
    state.merge({
      loading: true,
      masteredMilestones: {
        loading: true,
      },
    }, DEEP),
  [Types.FETCH_MASTERED_MILESTONES_SUCCESS]: (state, {
    milestones,
  }) =>
    state.merge({
      loading: false,
      masteredMilestones: {
        milestones,
        loading: false,
      },
    }, DEEP),
  [Types.SET_ASSESSMENT_STEP]: (state, {
    step,
  }) => state.merge({
    step,
  }),
  [Types.SET_AREA_ANSWERED]: (state, {
    areaId,
  }) => {
    const order = difference(state.answeredAreaOrder.asMutable(), [areaId]);
    order.unshift(areaId);
    return state.setIn(['answeredAreaOrder'], order);
  },
  [Types.FETCH_HEALTH_INTERESTS_SUCCESS]: (state, {
    interests,
  }) =>
    state.merge({
      healthInterests: interests,
    }),
  [Types.SET_UPDATE_FLAG]: state => state.merge({
    isUpdate: true,
  }, DEEP),
  [Types.END_IA]: state => state.merge({
    isUpdate: false,
  }, DEEP),
  [Types.SET_INITIAL_SKILL_MASTERED]: state => state.merge({ loading: true }),
};

export const Reducer = createReducer(INITIAL_STATE, Handlers);
