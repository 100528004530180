import { BaseApiV4 } from './BaseApi';

const getStripeUser = email => BaseApiV4.get('/handle_stripe_users', {
  params: {
    'filter[email]': email,
  },
});
const createSession = ({
  priceId,
  email,
  successUrl,
  cancelUrl,
  source = 'Paywall',
}) => BaseApiV4.post('/handle_stripe_users/create_session', {
  session: {
    email,
    price_id: priceId,
    success_url: successUrl,
    cancel_url: cancelUrl,
    payment_source: source,
  },
});

export default {};
export { getStripeUser, createSession };