import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { InfoBadge, BadgeButton, ListContainer } from './Logger';
import ExpandableBadge from './ExpandableBadge';

const ListBadge = ({
  on,
  badgeName,
  list,
  show,
  toggleShow,
  badgeStyles,
  titlesName,
  paramsName,
  DEBUG,
}) => (
  <Fragment>
    <InfoBadge styles={badgeStyles} isOn={on}>
      {DEBUG ? (<Fragment>
        {`${badgeName}:`}
        <BadgeButton onClick={toggleShow}>
          {show ? '▲' : '▼'}
        </BadgeButton>
      </Fragment>) : `${badgeName} hidden`}
    </InfoBadge>
    <ListContainer show={show}>
      {list.map((item, i) => (
        <ExpandableBadge
          key={i} // eslint-disable-line
          title={item[titlesName]}
          styles={badgeStyles}
          params={item[paramsName]}
          list={item}
        />
      ))}
    </ListContainer>
  </Fragment>
);

ListBadge.propTypes = {
  on: PropTypes.bool,
  badgeName: PropTypes.string,
  list: PropTypes.array,
  show: PropTypes.bool,
  toggleShow: PropTypes.func,
  badgeStyles: PropTypes.array,
  titlesName: PropTypes.string,
  paramsName: PropTypes.string,
  DEBUG: PropTypes.bool,
};

ListBadge.defaultProps = {
  on: false,
  badgeName: '',
  list: [],
  show: false,
  toggleShow: () => {},
  badgeStyles: [],
  titlesName: 'name',
  paramsName: 'params',
  DEBUG: false,
};

export default ListBadge;
