import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators as bind, compose } from 'redux';

import {
  APP_VERSION,
  DEBUG_EVENTS,
  FORCED_MODE,
  FORCE_API_TO_PROD,
  API_WARNING,
  SHOW_LOGGER,
  OTHER_API,
  EMULATED_SESSION,
  APP_VERSION_DESCRIPTION,
} from '../../../config';
import ListBadge from './ListBadge';
import ActionBadge from './ActionBadge';
import { Auth } from '../../../data/ducks';
import Icon from '../Icon';
import { Providers } from '../../../values/auth';
import { QUERY_PARAM_TO_SHOW_MODAL } from 'components/premiumProcessModal/values';

const NeutralColors = css`
  color: #a3e1ff;
  background-color: #0078b1;
  box-shadow: 0 0 5px #00557d;
`;

const InfoColors = css`
  color: #76ffd5;
  background-color: #00776d;
  box-shadow: 0 0 5px #00506f;
`;

const SuccessColors = css`
  color: #76ff81;
  background-color: #007725;
  box-shadow: 0 0 5px #006f23;
`;

const DangerColors = css`
  color: #ff8576;
  background-color: #b10000;
  box-shadow: 0 0 5px #b10000;
`;

const NoteColors = css`
  color: #fff1a3;
  background-color: #8c6f00;
  box-shadow: 0 0 5px #7d5700;
`;

const WarningColors = css`
  color: #ffca76;
  background-color: #b13b00;
  box-shadow: 0 0 5px #b13d00;
`;

const OtherColors = css`
  color: #dfc2ff;
  background-color: #673f92;
  box-shadow: 0 0 5px #2a0056;
`;

const BadgeContainer = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;

  display: flex;
  flex-direction: column;

  pointer-events: none;
  z-index: 9999999999999;
  opacity: 0.9;

  ${({ show }) => !show
    && css`
      display: none;
    `}
`;

export const ListContainer = styled.div`
  width: 100vw;
  max-height: 170px;
  overflow: hidden;
  mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0.5))
  );
  mask-size: 100% 120%;
  overflow-y: scroll;

  ${({ show }) => !show
    && css`
      display: none;
    `}
`;

export const BadgeButton = styled.button`
  background-color: #ffffff2e;
  margin-left: 3px;
  padding: 1px 4px;
  border-radius: 3px;
  border: none;
  outline: none;

  color: white;
  font-size: 8px;
  pointer-events: all;
  cursor: pointer;
`;

export const InfoBadge = styled.div`
  position: relative;
  transition: all 0.3s ease;
  padding: 2px 5px;
  margin: 3px 0 3px auto;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  pointer-events: none;
  user-select: none;
  opacity: 0.6;

  ${({ top }) => `top: ${top}px;`}
  ${({ styles }) => styles}
  ${({ animation }) => animation}

  @keyframes moveIn { from{opacity: 0;} to{opacity: 0.6;} }
  @keyframes moveOut { from{opacity: 0.6;} to{opacity: 0;} }

  ${({ isOn }) => isOn
    && css`
      animation: moveIn 0.5s ease 0s 1 normal;
    `}

  ${({ isOn }) => !isOn
    && css`
      animation: moveOut 0.5s ease 0s 1 normal;
    `}
`;

const CircularBadge = styled.div`
  position: relative;
  transition: all 0.3s ease;
  padding: 9px 8px;
  margin: 3px 0 3px auto;
  border-radius: 100px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  pointer-events: none;
  user-select: none;
  opacity: 0.6;

  ${({ styles }) => styles}

  ${({ isOn }) => isOn
    && css`
      padding: 1px;
      border-radius: 4px;
    `}
`;
const setLanguage = lang => i18n.changeLanguage(lang);

class Logger extends Component {
  state = {
    existsLogger: false,
    showLogger: true,
    showEvents: false,
    showAttrs: false,
    showSettings: false,
  };

  handleToggleLogger = () => {
    // this.toggleLogger();
    this.toggleShowLogger(!this.state.existsLogger);
    this.toggleLogger(!this.state.existsLogger);
  };

  toggleLogger = val => this.setState({ existsLogger: val });

  toggleShowLogger = val => this.setState({ showLogger: val });

  toggleShowevents = () => this.setState({ showEvents: !this.state.showEvents });

  toggleShowAttrs = () => this.setState({ showAttrs: !this.state.showAttrs });

  toggleLang = lang => setLanguage(lang);

  toggleShowSettings = () => this.setState({ showSettings: !this.state.showSettings });

  clearSession = () => {
    const { logout, history } = this.props;
    logout();
    history.push();
    localStorage.clear();
    sessionStorage.clear();
  };

  emulateSession = () => {
    const { login } = this.props;
    const payload = {
      type: 'LOGIN_REQUESTED',
      provider: Providers.EMULATED,
      token: EMULATED_SESSION.HARDCODED_TOKEN,
      userId: EMULATED_SESSION.USER_ID,
    };
    login(payload);
  };

  handleOnClickShowPPModal = () => {
    const { history } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set([QUERY_PARAM_TO_SHOW_MODAL], true);
    history.push({
      search: urlParams.toString(),
    });
  };

  render() {
    const {
      isStaging, isQA, logs, attrs,
    } = this.props;
    const {
      existsLogger,
      showLogger,
      showEvents,
      showAttrs,
      showSettings,
    } = this.state;
    const attrsList = [
      { name: 'User', params: attrs.user, providers: ['LEANPLUM', 'MIXPANEL'] },
      {
        name: 'Family',
        params: attrs.family,
        providers: ['LEANPLUM', 'MIXPANEL'],
      },
      { name: 'Baby', params: attrs.baby, providers: ['LEANPLUM', 'MIXPANEL'] },
    ];
    return (
      <BadgeContainer show={SHOW_LOGGER}>
        {(isStaging || isQA) && (
          <CircularBadge styles={OtherColors} isOn={false}>
            <BadgeButton
              onClick={this.handleToggleLogger}
              // on={showLogger}
              style={{ marginLeft: '0px', backgroundColor: 'transparent' }}
            >
              <Icon icon="burger" size={14} color="white" />
            </BadgeButton>
          </CircularBadge>
        )}

        {FORCED_MODE && existsLogger && (
          <InfoBadge isOn={showLogger} styles={DangerColors}>
            FORCED MODE
          </InfoBadge>
        )}
        {API_WARNING && (
          <InfoBadge isOn={showLogger} styles={DangerColors}>
            API IS ON STAGING
          </InfoBadge>
        )}
        {FORCE_API_TO_PROD && (
          <InfoBadge isOn={showLogger} styles={DangerColors}>
            API IS ON PRODUCTION
          </InfoBadge>
        )}
        {OTHER_API !== '' && (
          <InfoBadge isOn={showLogger} styles={DangerColors}>
            API IS ON
            {' '}
            {OTHER_API}
          </InfoBadge>
        )}
        {FORCED_MODE && !isStaging && !isQA && (
          <InfoBadge isOn={showLogger} styles={WarningColors}>
            PRODUCTION
          </InfoBadge>
        )}
        {(isStaging || isQA) && existsLogger && (
          <Fragment>
            <InfoBadge isOn={showLogger} styles={WarningColors}>
              {isStaging ? 'STAGING' : 'QA'}
            </InfoBadge>
            <InfoBadge isOn={showLogger} styles={NoteColors}>
              {`v${APP_VERSION}`}
            </InfoBadge>
            <InfoBadge isOn={showLogger} styles={NoteColors}>
              {`${APP_VERSION_DESCRIPTION}`}
            </InfoBadge>
            <ListBadge
              on={showLogger}
              badgeName="Events"
              list={logs}
              show={showEvents}
              toggleShow={this.toggleShowevents}
              badgeStyles={SuccessColors}
              DEBUG={DEBUG_EVENTS}
            />
            <ListBadge
              on={showLogger}
              badgeName="Attributes"
              list={attrsList}
              show={showAttrs}
              toggleShow={this.toggleShowAttrs}
              badgeStyles={InfoColors}
              DEBUG={DEBUG_EVENTS}
            />
          </Fragment>
        )}
        {((FORCED_MODE && existsLogger)
          || ((isStaging || isQA) && existsLogger)) && (
          <>
            <InfoBadge isOn={showLogger} styles={NeutralColors}>
              <BadgeButton
                onClick={this.toggleShowSettings}
                // on={showSettings}
                style={{
                  marginLeft: '0px',
                  backgroundColor: 'transparent',
                  padding: '0',
                }}
              >
                <Icon icon="settings" size={16} color="white" />
              </BadgeButton>
            </InfoBadge>
            <ListContainer show={showSettings}>
              <ActionBadge
                on={showLogger}
                title="Lang:EN"
                action={() => this.toggleLang('en')}
                styles={NeutralColors}
              />
              <ActionBadge
                on={showLogger}
                title="Lang:ES"
                action={() => this.toggleLang('es')}
                styles={NeutralColors}
              />
              <ActionBadge
                on={showLogger}
                title="Lang:PT"
                action={() => this.toggleLang('pt')}
                styles={NeutralColors}
              />
              <ActionBadge
                on={showLogger}
                title="Clean session"
                action={this.clearSession}
                styles={DangerColors}
              />
              <ActionBadge
                on={showLogger}
                title="Emulate session"
                action={this.emulateSession}
                styles={OtherColors}
              />
              <ActionBadge
                on={showLogger}
                title="Show PP Modal"
                action={this.handleOnClickShowPPModal}
                styles={SuccessColors}
              />
            </ListContainer>
          </>
        )}
      </BadgeContainer>
    );
  }
}

Logger.propTypes = {
  logs: PropTypes.array,
  attrs: PropTypes.object,
  isStaging: PropTypes.bool,
  isQA: PropTypes.bool,
  history: PropTypes.object,
  logout: PropTypes.func,
  login: PropTypes.func,
};

Logger.defaultProps = {
  logs: [],
  attrs: {},
  isStaging: false,
  isQA: false,
  history: {},
  logout: () => {},
  login: () => {},
};

const mapStateToProps = state => ({
  logs: state.appContext.events,
  attrs: state.appContext.attributes,
});

const mapDispatchToProps = dispatch => bind(
  {
    login: Auth.Creators.login,
    logout: Auth.Creators.logout,
  },
  dispatch,
);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(Logger);
