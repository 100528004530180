import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
// import { asyncTypeAndActionCreators as aTC } from '../../lib/utils';
import { DEEP } from '../redux-sauce';

export const { Types, Creators } = createActions({
  fetchPendingInvitesRequested: null,
  fetchPendingInvitesSuccess: ['invites'],

  updateInviteStatusRequested: ['status', 'familyId', 'membershipId'],
  updateInviteStatusSuccess: null,
  updateInviteStatusError: ['error'],

  fetchInvitedClassroomRequested: ['code', 'onSuccess'],
  fetchInvitedClassroomSuccess: ['invitedClassroomData'],
  fetchInvitedClassroomError: ['invitedClassroomError'],
  clearInvitedClassroom: ['action'],
  setInvitedClassroomLoading: ['isLoading'],

  joinClassroomRequested: ['babyId', 'code', 'onSuccess', 'onError'],
  joinClassroomSuccess: ['data'],
  joinClassroomError: ['error'],
  joinClassroomLoading: ['isLoading'],

  sendLeadRequested: ['name', 'email', 'onSuccess', 'onError'],
  sendLeadLoading: ['isLoading'],
});

const invitedClassroomDataInit = {
  organization: { name: undefined },
  center: { name: undefined },
  group: { name: undefined },
  logo: '',
  code: undefined,
};

const INITIAL_STATE = Immutable({
  invites: [],
  loading: false,
  error: '',
  invitedClassroomData: invitedClassroomDataInit,
  invitedClassroomError: '',
  invitedClassroomLoading: false,
  joinClassroomError: '',
  joinClassroomLoading: ['isLoading'],
  sendingInvite: false,
});

export const Selectors = {
  getPendingInvites: state => state.invites.invites || [],
};

const Handlers = {
  [Types.FETCH_PENDING_INVITES_SUCCESS]: (state, { invites }) => state.merge({ invites }, DEEP),
  [Types.UPDATE_INVITE_STATUS_REQUESTED]: state => state.merge({ loading: true }),
  [Types.UPDATE_INVITE_STATUS_SUCCESS]: state => state.merge({ loading: false }),
  [Types.UPDATE_INVITE_STATUS_ERROR]: (state, action) => state.merge({ loading: false, error: action.err }),
  [Types.SET_INVITED_CLASSROOM_LOADING]: (state, { isLoading }) => state.merge({ invitedClassroomLoading: isLoading }),
  [Types.FETCH_INVITED_CLASSROOM_SUCCESS]: (state, { invitedClassroomData }) => state.merge({
    invitedClassroomData,
    invitedClassroomError: '',
    invitedClassroomLoading: false,
  }),
  [Types.FETCH_INVITED_CLASSROOM_ERROR]: (state, { invitedClassroomError }) => state.merge({
    invitedClassroomError,
    invitedClassroomLoading: false,
  }),
  [Types.CLEAR_INVITED_CLASSROOM]: state => (
    state.merge({
      invitedClassroomData: invitedClassroomDataInit,
      invitedClassroomError: '',
      invitedClassroomLoading: false,
    })
  ),
  [Types.JOIN_CLASSROOM_LOADING]: (state, { isLoading }) => state.merge({ joinClassroomLoading: isLoading }),
  [Types.JOIN_CLASSROOM_SUCCESS]: (state, { data }) => state.merge({}),
  [Types.SEND_LEAD_LOADING]: (state, { isLoading }) => state.merge({
    sendingInvite: isLoading,
  }),
};

export const Reducer = createReducer(INITIAL_STATE, Handlers);
