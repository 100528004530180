import { combineEpics } from 'redux-observable';

import { Creators, Types } from '../ducks/health-interests';
import * as BabiesApi from '../../api/babies';

const fetchHealthInterests = action$ =>
  action$.ofType(Types.FETCH_HEALTH_INTERESTS_REQUESTED)
    .mergeMap(
      ({ babyId }) => BabiesApi.getHealthInterests(babyId)
        .then(res => res.data.data.interests)
        .then(healthInterests => Creators.fetchHealthInterestsSuccess(healthInterests)));

const postHealthInterests = action$ =>
  action$.ofType(Types.POST_HEALTH_INTERESTS)
    .do(({ babyId, interests }) => BabiesApi.postHealthInterests(babyId, interests))
    .catch(err => err)
    .ignoreElements();

const observers = {
  fetchHealthInterests,
  postHealthInterests,
};

export default combineEpics(...Object.values(observers));
