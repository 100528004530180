import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RendererIf, Spinner } from 'lego-dist';
import Icon from 'components/shared/Icon';
import PlanCardWrapper, {
  PlanCardContent,
  PlanCardHeader,
  PlanCardBody,
  TextDetail,
  PlanType,
  PlanPrice,
  PlanMonthlyPrice,
  IconSelected,
} from './styles/planCard';
import { PAYMENT_CARD_THEMES } from '../values';

const PlanCardComponent = ({
  isSelected, data, onSelected, theme,
}) => {
  const { t: translate } = useTranslation();
  const {
    complete, discount, title, price, monthlyPrice,
  } = data;

  const handleOnSelected = () => {
    if (onSelected) onSelected(data);
  };

  const getMonthlyPriceText = () => {
    let text = monthlyPrice;

    switch (monthlyPrice) {
      case 0:
        text = translate('pp:ONE_TIME_PAYMENT');
        break;
      case 1:
        text = '';
        break;

      default: {
        const calcMonthlyPrice = parseFloat(
          (`${price?.unit_amount_decimal}`.replace(',', '') / monthlyPrice).toFixed(3).slice(0, -1),
        );
        text = `${price?.currency?.toUpperCase()} ${
          price?.currency_symbol
        } ${calcMonthlyPrice}/ ${translate('common:MONTH')}`;

        if (`${price?.country}`.toLowerCase() === 'br' || `${price?.currency}`.toLowerCase() === 'brl') {
          text = `${text}`.replace('.', ',');
        }

        break;
      }
    }

    return text;
  };

  return (
    <PlanCardWrapper isSelected={isSelected} onClick={handleOnSelected} cardTheme={theme} showHeader={discount}>
      <PlanCardContent>
        <RendererIf
          condition={complete}
          alternative={(
            <Spinner
              loading
              name="threedots"
              color="#0B86DB"
              position="relative"
              bgColor="transparent"
            />
          )}
        >
          <RendererIf condition={discount}>
            <PlanCardHeader>{discount}</PlanCardHeader>
          </RendererIf>

          <PlanCardBody>
            <PlanType>
              <TextDetail>{title}</TextDetail>
            </PlanType>
            <PlanPrice>
              <TextDetail>{`${price?.currency_symbol}${price?.unit_amount_decimal}`}</TextDetail>
              <RendererIf condition={theme !== PAYMENT_CARD_THEMES.THREE}>
                <PlanMonthlyPrice>
                  <TextDetail>{getMonthlyPriceText()}</TextDetail>
                </PlanMonthlyPrice>
              </RendererIf>
            </PlanPrice>
            <RendererIf condition={theme === PAYMENT_CARD_THEMES.THREE}>
              <PlanMonthlyPrice>
                <TextDetail>{getMonthlyPriceText()}</TextDetail>
              </PlanMonthlyPrice>
            </RendererIf>
            <IconSelected>
              <Icon icon="check2" color="#fff" size={10} />
            </IconSelected>
          </PlanCardBody>
        </RendererIf>
      </PlanCardContent>
    </PlanCardWrapper>
  );
};

PlanCardComponent.propTypes = {
  theme: PropTypes.oneOf([
    PAYMENT_CARD_THEMES.THREE,
    PAYMENT_CARD_THEMES.TWO,
    PAYMENT_CARD_THEMES.ONE,
    PAYMENT_CARD_THEMES.THREE_DESKTOP,
  ]),
  isSelected: PropTypes.bool,
  data: PropTypes.object,
  onSelected: PropTypes.func,
};
PlanCardComponent.defaultProps = {
  theme: PAYMENT_CARD_THEMES.THREE,
  isSelected: false,
  data: {},
  onSelected: () => {},
};

export default PlanCardComponent;
