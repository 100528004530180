import { Reduxsauce, DEEP } from '../redux-sauce';
import { DEFAULT_LANG, Settings } from '../../values/settings';

export const Types = Reduxsauce.createTypes(`
  settingChanged
`);

export const Creators = {
  /**
   * @param{String} name
   * @param{Any} value
   */
  settingChanged: ({ name, value }) => ({ type: Types.settingChanged, name, value }),
};

// ------- State -------- //
export const InitialState = Reduxsauce.createState({
  [Settings.AUTOPLAY_ENABLED]: false,
  [Settings.TUTORIALS_ENABLED]: false,
  [Settings.LANG]: DEFAULT_LANG,
});

export const Reducer = Reduxsauce.createReducer(InitialState, {
  [Types.settingChanged]: (state, action) =>
    state.merge({ [action.name]: action.value }, DEEP),
});
