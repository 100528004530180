import styled, { css } from 'styled-components';

const FavoritesActivitiesWrapper = styled.div.attrs({
  className: 'FavoritesActivitiesWrapper',
})`
  display: block;
  width: 100%;
  margin: 0 auto;
`;

const FavoritesActivitiesHeader = styled.div.attrs({
  className: 'FavoritesActivitiesHeader',
})`
  display: block;
  width: 100%;
  margin: 0 auto;
`;

const FavoritesActivitiesHeaderTitleIconStyles = css`
  padding-left: 70px;
  margin: 23px 0 40px 0;

  &::before {
    content: '';
    display: block;
    width: 60px;
    height: 60px;
    background-image: url('${({icon}) => icon && icon}');
    background-position: center;
    background-size: 24px 31px;
    background-repeat: no-repeat;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: ${({ hasDesciption }) => (hasDesciption ? '-7%' : '50%')};
    transform: ${({ hasDesciption }) => (hasDesciption ? 'none' : 'translateY(-50%)')};
    z-index: 1;
    box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.1);
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
  }

  ${({ theme }) => theme.breakPoints.tablet} {
    padding-left: 118px;
    margin: 65px 0;
    &::before {
      width: 100px;
      height: 100px;
      background-size: 41px 52px;
    }
  }
`;

const FavoritesActivitiesHeaderTitle = styled.h2.attrs({
  className: 'FavoritesActivitiesHeaderTitle',
})`
  display: block;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #2c343a;
  margin: 30px auto 20px auto;
  position: relative;

  ${({ theme }) => theme.breakPoints.tablet} {
    font-size: 26px;
  }

  ${({icon}) => icon && FavoritesActivitiesHeaderTitleIconStyles};
`;

const FavoritesActivitiesHeaderDescription = styled.span.attrs({
  className: 'FavoritesActivitiesHeaderDescription',
})`
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #404040;
  margin-top: 5px;

  ${({ theme }) => theme.breakPoints.tablet} {
    font-size: 16px;
    line-height: 21px;
    margin-top: 20px;
  }
`;

const FavoritesActivitiesBody = styled.div.attrs({
  className: 'FavoritesActivitiesBody',
})`
  display: block;
  width: 100%;
  margin: 20px auto 70px auto;
`;

const FavoritesActivitiesContentTab = styled.div.attrs({
  className: 'FavoritesActivitiesContentTab',
})`
  display: block;
  width: 100%;
`;


const FavoritesActivitiesFoot = styled.div.attrs({
  className: 'FavoritesActivitiesFoot',
})`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
`;

const FavoritesContentActivities = styled.div.attrs({
  className: 'FavoritesContentActivities',
})`
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;

  ${({ theme }) => theme.breakPoints.tablet} {
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }

`;

const FavoritesContentTabViewWrapper = styled.div.attrs({
  className: 'FavoritesContentTabViewWrapper',
})`
  display: block;
  width: 100%;
  margin: 17px auto 0 auto;

  .FavoritesActivitiesFoot {
    margin-top: 70px;
  }
`;
const FavoritesToysTabViewWrapper = styled.div.attrs({
  className: 'FavoritesToysTabViewWrapper',
})`
  display: block;
  width: 100%;
  margin: 17px auto 0 auto;

  .FavoritesActivitiesFoot {
    margin-top: 70px;
  }
`;

const FavoritesContentBanner = styled.div.attrs({
  className: 'FavoritesContentBanner',
})`
  display: block;
  width: 90%;
  margin: 30px auto;
`;

export default FavoritesActivitiesWrapper;
export {
  FavoritesActivitiesHeader,
  FavoritesActivitiesHeaderTitle,
  FavoritesActivitiesHeaderDescription,
  FavoritesActivitiesBody,
  FavoritesActivitiesContentTab,
  FavoritesActivitiesFoot,
  FavoritesContentActivities,
  FavoritesContentTabViewWrapper,
  FavoritesToysTabViewWrapper,
  FavoritesContentBanner,
};
