import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NeoModal from './NeoModal';
import { breakPoints } from '../../styles';
import MilestonesScience from '../views/MilestonesScience';

const Modal = styled(NeoModal)`
  display: flex;
  flex-direction: column;
  margin: auto 20px;

  ${breakPoints.smallTablet} {
    margin: auto;
  }
`;

/*
Dispatch example:

{
  type: 'MODAL_OPENED',
  name: 'MilestonesScienceModal',
  data: {
  },
}

*/

const MilestonesScienceModal = ({ t }) => (
  <Modal width={460} roundedCorners>
    {({ closeModal }) => (
      <Fragment>
        <MilestonesScience closeModal={closeModal} />
      </Fragment>
    )}
  </Modal>
);

MilestonesScienceModal.propTypes = {
  t: PropTypes.func,
};

MilestonesScienceModal.defaultProps = {
  t: () => {},
};

export default MilestonesScienceModal;
