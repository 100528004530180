const TYPES = {
  ACTIVITIES: 'activities',
  CLASSES: 'classes',
  ARTICLES: 'articles',
};

const idsViews = {
  ACTIVITIES: 'ACTIVITIES',
  CLASSES: 'CLASSES',
  MASTER_CLASS: 'MASTERCLASS',
};

const VIEW_COLORS = {
  ACTIVITIES: '#0086D8',
  CLASSES: '#D1296D',
  MASTER_CLASS: '#1C374B',
  ARTICLES: '#3EB646',
};


const ACTIVITY_TYPES = {
  ACTIVITY: 'activity',
  WEBINAR_SESSION: 'webinar_session',
  WEBINAR: 'Webinar',
  INTERACTIVE: 'Interactive',
  INTERACTIVE_SESSION: 'interactive_session',
  PLAN_ARTICLE: 'PlanArticle',
  ARTICLE: 'Article',
};

const DEEPLINKS_PARAMS = {
  SKILL: 'skill',
  SUBJECT: 'subject',
  CENTER: 'center',
  INTEREST: 'interest',
  ARTICLES: 'articles',
  AGE: 'age',
  EXPERT: 'expert',
  FAVORITES: 'open_favorites',
};

const SOURCE_EVENTS = {
  ACTIVITIES: 'activities',
  CLASSES: 'classes',
};

const SOURCE_EVENTS_PAYMENT_PATH = {
  ACTIVITIES: 'CATcollectionView',
  CLASSES: 'educatorsCollections',
  FAVORITES_BANNER: 'favoritesBanner',
  FAVORITE: 'favorite',
  RECENTLY_ADDED_ACTIVITY: 'CATCollectionView',
  RECENTLY_ADDED_CLASS: 'educatorsCollection',
  MASTER_CLASS: 'masterClass',
  CALENDAR_EVENTS: 'events',
};

const KIND_OF_ITEMS = {
  ALL: 0,
  ACTIVITIES: 1,
  ARTICLES: 2,
  PRODUCTS: 3,
};

const KIND_OF_ITEMS_PATH = {
  0: '',
  1: '/activities',
  2: '/articles',
  3: '/products',
};

const VALUES_PARAMS_BY_SECTION = {
  RECENTLY: {
    ID: 'recently',
    SOURCE: 'catNew',
    AREA_FOR_CLASS: 'parenting',
    TYPE: 'classes',
  },
};

const SOCIAL_NETWORKS = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
};

const featuredCollectionsForceUnlocked = [126];


export default {};
export {
  idsViews,
  VIEW_COLORS,
  ACTIVITY_TYPES,
  DEEPLINKS_PARAMS,
  SOURCE_EVENTS,
  SOURCE_EVENTS_PAYMENT_PATH,
  KIND_OF_ITEMS,
  KIND_OF_ITEMS_PATH,
  featuredCollectionsForceUnlocked,
  VALUES_PARAMS_BY_SECTION,
  TYPES,
  SOCIAL_NETWORKS,
};
